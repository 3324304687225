import { colors } from "../../../../assets/colors";
import warningIcon from "../../../../assets/images/warning-yellow.svg";
import { GothamBold, GothamRegular } from "../../../../components/Text";

const WarningLimit = ({ show, isSmall, customText }) => {
  return (
    <div
      style={{
        backgroundColor: colors.lightYellowFC,
        borderRadius: "8px",
        padding: show ? "14px 12px 14px 12px" : "0",
        height: show ? "auto" : "0",
        opacity: show ? "1" : "0",
        display: "flex",
        gap: "14px",
        alignItems: "flex-start",
        transition: "all 0.25s linear",
      }}
    >
      <img
        src={warningIcon}
        style={{
          visibility: show ? "visible" : "hidden",
        }}
      />
      <div
        style={{
          visibility: show ? "visible" : "hidden",
        }}
      >
        <GothamBold style={{ margin: 0, fontSize: isSmall ? 14 : 16 }}>
          Card limit exceed the company's remaining limit
        </GothamBold>
        <GothamRegular style={{ fontSize: isSmall ? 12 : 14, marginTop: 4 }}>
          {customText
            ? customText
            : `Please consider changing this card limit. You can still request this amount by clicking continue. However, it will take longer to issue a card this way.`}
        </GothamRegular>
      </div>
    </div>
  );
};

export default WarningLimit;
