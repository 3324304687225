import { get } from "lodash";
import { useRouter } from "next/router";
import React, { useContext, useEffect, useState } from "react";
import { Modal, Table } from "reactstrap";
import { colors } from "../assets/colors";
import xIcon from "../assets/images/x_calculator.svg";
import checkSquareIcon from "../assets/images/check-square-icon.svg";
import emptySquareIcon from "../assets/images/empty-square-icon.svg";
import { formatCurrency } from "../components/tools";
import { TablePagination } from "../pageComponents/pagination";
import { Tbody, TdHeader } from "../pageComponents/tableComponents";
import { apiBusiness } from "../tools/api";
import { GothamMedium, GothamRegular } from "./Text";
import { windowDimension } from "./tools";
import { CustomButton } from "./Buttons";
import rightArrow from "../assets/images/right-arrow.svg";
import { ToasterContext } from "../contexts/ToasterContext";

const RefundModalTable = ({ toggle, isOpen, onSubmit }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const getAllIds = data.map(({ id }) => id);
  const isAllChecked = getAllIds.length == selectedIds.length;
  const { figmaWidth, figmaHeight, height, width } = windowDimension();
  const { errorToasterApi } = useContext(ToasterContext);
  const { query } = useRouter();

  const getTotalPage = 1;
  const { page = 1, per_page = 10, id, status } = query;

  const dataThead = [
    {
      title: (
        <img
          src={isAllChecked ? checkSquareIcon : emptySquareIcon}
          className="darkhover"
          onClick={() => {
            if (isAllChecked) {
              setSelectedIds([]);
              return;
            }
            setSelectedIds(getAllIds);
          }}
        />
      ),
      handle: "reference_id",
    },
    {
      title: "Transaction ID",
      handle: "reference_id",
    },

    {
      title: "Recipient Name",
      handle: "recipient_name",
    },

    {
      title: "Destination Amount",
      handle: "destination_amount",
    },

    {
      title: "Amount",
      handle: "total_amount",
    },
  ];
  const handleClick = () => {};
  const getData = async () => {
    try {
      setLoading(true);
      const url = `/business_transactions/${id}?page=${page}&per_page=${per_page}&q[state_eq]=${status}`;
      const { data } = await apiBusiness.get(url);
      const getData = get(data, "trxb_data", []);
      setData(getData);
    } catch (err) {
      errorToasterApi(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (status == "failed") {
      getData();
    }
  }, [query]);

  const handleAddId = (id) => {
    setSelectedIds((prev) => [...prev, id]);
  };
  const handleRemoveId = (id) => {
    setSelectedIds((prev) => prev.filter((item) => item !== id));
  };
  const [getWidth, setGetWidth] = useState(0);
  return (
    <Modal
      toggle={toggle}
      isOpen={isOpen}
      centered
      contentClassName="modal-transfez"
    >
      <div
        style={{
          backgroundColor: "white",
          borderRadius: 12,
          width: 856,
        }}
        className="pt-4 pr-4 pl-4 pb-0 modal-transfez"
      >
        <div className="d-flex justify-content-between align-items-center">
          <GothamMedium
            style={{ color: colors.teal, fontSize: 24 }}
            className="my-0"
          >
            Refund
          </GothamMedium>
          <img src={xIcon} className="darkhover" onClick={toggle} />
        </div>
        <GothamRegular style={{ fontSize: 16 }} className="mt-5 ">
          Select which transaction to be refunded
        </GothamRegular>
        <div
          className="table-responsive mt-5"
          style={{
            border: "1px solid rgba(194, 194, 194,.5)",
            maxHeight: figmaHeight(200),
          }}
        >
          <Table className="table-transfez w-100 mb-0">
            <>
              <thead>
                <tr>
                  {dataThead.map(({ title, handle }, index) => {
                    const isFirst = index === 0;
                    if (isFirst) {
                      return <td key={index}>{title}</td>;
                    }
                    return (
                      <TdHeader
                        isCustomStyle
                        key={index}
                        onClick={() => {
                          handleClick(handle);
                        }}
                      >
                        {title}
                      </TdHeader>
                    );
                  })}
                </tr>
              </thead>

              <Tbody
                loading={loading}
                totalHeader={dataThead.length}
                totalItem={per_page}
              >
                {data.map(
                  (
                    {
                      reference_id,
                      sent_amount,
                      beneficiary,
                      destination_amount,
                      destination_currency,
                      source_currency,
                      id,
                    },
                    index
                  ) => {
                    const getBene = (string) => get(beneficiary, string, "");
                    const getName =
                      getBene("receiving_registered_name") ||
                      getBene("receiving_first_name") +
                        getBene("receiving_last_name");
                    const safeSentAmount = formatCurrency(sent_amount || 0);
                    const isActive = selectedIds.includes(id);
                    return (
                      <tr
                        key={index}
                        style={{ backgroundColor: isActive ? "#C1EFF4" : "" }}
                      >
                        <td>
                          <img
                            src={isActive ? checkSquareIcon : emptySquareIcon}
                            className="darkhover"
                            onClick={() => {
                              if (isActive) {
                                handleRemoveId(id);
                                return;
                              }
                              handleAddId(id);
                            }}
                          />
                        </td>
                        <td>{reference_id}</td>

                        <td>{getName}</td>
                        <td>
                          {destination_amount} {destination_currency}
                        </td>
                        <td>
                          {safeSentAmount} {source_currency}
                        </td>
                      </tr>
                    );
                  }
                )}
              </Tbody>
            </>
          </Table>
        </div>
        <TablePagination total={getTotalPage} query />
        <div
          className="d-flex justify-content-end mt-5"
          ref={(e) => setGetWidth(e && e.clientWidth)}
        >
          <CustomButton
            onClick={() => onSubmit(selectedIds)}
            className="mr-4  pl-4 pr-3"
            style={{ fontSize: 20, height: 48, marginBottom: figmaHeight(32) }}
            disabled={!selectedIds.length}
            rightIcon={<img src={rightArrow} className="ml-2" />}
          >
            Next
          </CustomButton>
        </div>
      </div>
    </Modal>
  );
};

export default RefundModalTable;
