import {
  TypeFezNeueMedium,
  TypeFezNeueRegular,
} from "../../../components/Text";
import { formatCurrencyNoDecimal } from "../../../components/tools";
import plusIconCircle from "../../../assets/images/plus-circle.svg";
import { colors } from "../../../assets/colors";
export const TransactionDetailsBox = ({
  fee = "",
  totalAmount = 0,
  defaultFee = 0,
}) => {
  const isFreeFee = Number(fee) <= 0;
  const defaultFeeToNumber = Number(defaultFee);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 12,
        padding: 12,
        backgroundColor: "#FFFFFF",
        border: "1px solid #E6E6E8",
        borderRadius: 12,
      }}
    >
      <div className="d-flex justify-content-between">
        <TypeFezNeueMedium style={{ color: "#102C57" }}>
          Total Amount
        </TypeFezNeueMedium>
        <TypeFezNeueMedium style={{ color: "#102C57" }}>
          IDR {formatCurrencyNoDecimal(totalAmount || 0)}
        </TypeFezNeueMedium>
      </div>
      <div className="d-flex justify-content-between">
        <div className="d-flex" style={{ width: "50%" }}>
          <img src={plusIconCircle} style={{ marginRight: 8 }} />
          <TypeFezNeueRegular>Transfez fee</TypeFezNeueRegular>
        </div>
        {isFreeFee ? (
          <TypeFezNeueMedium style={{ color: colors.pink }}>
            Free{" "}
            {defaultFeeToNumber > 0 ? (
              <span
                style={{
                  fontFamily: "GothamBook",
                  color: colors.greyd1,
                  textDecoration: "line-through",
                  marginLeft: "2px",
                }}
              >
                IDR {formatCurrencyNoDecimal(Number(defaultFeeToNumber))}
              </span>
            ) : (
              ""
            )}
          </TypeFezNeueMedium>
        ) : (
          <TypeFezNeueRegular>
            IDR {formatCurrencyNoDecimal(Number(fee))}
          </TypeFezNeueRegular>
        )}
      </div>
      <div style={{ borderBottom: "2px dashed #C2C2C2" }} />
      <div className="d-flex justify-content-between">
        <TypeFezNeueRegular>Amount paid</TypeFezNeueRegular>
        <TypeFezNeueRegular>
          IDR {formatCurrencyNoDecimal((totalAmount || 0) + Number(fee))}
        </TypeFezNeueRegular>
      </div>
    </div>
  );
};
