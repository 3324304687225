import {
  customDateFormatter,
  teamManagerTextDecider,
} from "../../../components/tools";
import { useGetAuth } from "../../../contexts/AuthContext";

const useCardWorkflow = (cardDetail) => {
  const {
    current_workflow_process: current_workflow_process_type,
    is_adjusting_limit,
    notes,
    progress_summary,
    workflow_process: workflow_processRaw = {},
    user: cardOwner,
  } = cardDetail || {};
  const {
    value: isRequestAdjustingLimit,
    timestamp: requestAdjustLimitTimestamp,
  } = is_adjusting_limit || {};

  const { rejected_by_id, rejection_note } = notes || {};

  const { user } = useGetAuth();

  const youOrNameFunc = ({ name, currentUser }) => {
    const { name: userName, id } = currentUser || {};
    if (!name) return "";
    if (userName == name || id === name) return "You";
    return name;
  };

  let approverLineArray = [];
  let isActiveArray = [];
  let rejectChecker = false;

  const workflowProcessFormatter = ({
    workflow_process = {},
    current_workflow_process_type = "",
  }) => {
    let obj = {};
    const currentWorkflowProcess = workflow_process.find(
      ({ type }) => type === current_workflow_process_type
    ).data;
    Object.keys(currentWorkflowProcess).forEach((key) => {
      const array = currentWorkflowProcess[key] || [];

      const filterer = (roleName) =>
        array.filter(({ user_role_name }) => user_role_name === roleName);

      const includesAdmin = filterer("partner_admin");

      const includesSuperAdmin = filterer("partner_super_admin");

      const includesEmployee = filterer("partner_maker");

      const includesBookkeeper = filterer("partner_viewer");

      const includesManager = array.filter((user) => user.team_manager);

      const arrayDecider = () => {
        if (!includesAdmin.length) return array;
        if (includesSuperAdmin.length)
          return [
            ...includesManager,
            ...includesSuperAdmin,
            ...includesAdmin,
            ...includesBookkeeper,
            ...includesEmployee,
          ];
        return [
          ...includesManager,
          ...includesAdmin,
          ...includesBookkeeper,
          ...includesEmployee,
        ];
      };

      const cleanArray = arrayDecider();
      const filteredApproved = array.filter(({ approved_at }) => approved_at);
      const newArray = filteredApproved.length ? filteredApproved : cleanArray;
      obj[key] = newArray;
    });

    return { obj, currentWorkflowProcess };
  };

  const { obj: workflow_process, currentWorkflowProcess } =
    workflowProcessFormatter({
      workflow_process: workflow_processRaw,
      current_workflow_process_type,
    });

  Object.keys(workflow_process).forEach((key, indexParent) => {
    const array = workflow_process[key];
    const result = array
      .map((item, index) => {
        if (rejectChecker) return null;

        const {
          user_role_name,
          user_name,
          user_email,
          approved_at,
          approved_by_user_id,
          approved_by_user_name,
          rejected_at,
          rejected_by_user_id,
          rejected_by_user_name,
          team_manager,
          bypassed,
        } = item;

        const hasApproved = Boolean(approved_at);

        const date = hasApproved
          ? customDateFormatter(approved_at)
          : rejected_at
          ? customDateFormatter(rejected_at)
          : "";

        const nameDecider = () => {
          const isAdmin = user_role_name == "partner_admin";
          const isSuperAdmin = user_role_name === "partner_super_admin";
          const isBookkeeper = user_role_name === "partner_viewer";
          const isEmployee = user_role_name === "partner_maker";

          const haveUserName = user_name === "" || user_name;
          const userName = user_name === "" ? user_email : user_name;

          const isTeamManager = team_manager && haveUserName && cardOwner?.id;
          const { id } = user;

          const teamManagerTextSimplifiedDecider = (text = "") =>
            teamManagerTextDecider({ isTeamManager, text });

          if (approved_at) {
            if (id === approved_by_user_id) {
              return teamManagerTextSimplifiedDecider(
                bypassed ? "your" : "You"
              );
            }

            return teamManagerTextSimplifiedDecider(approved_by_user_name);
          }

          if (rejected_at && rejected_by_id) {
            if (id === rejected_by_user_id) {
              return teamManagerTextSimplifiedDecider("You");
            }

            return teamManagerTextSimplifiedDecider(rejected_by_user_name);
          }

          if (isAdmin) return "Any Admin";
          if (isBookkeeper) return "Any Bookkeeper";
          if (isSuperAdmin) return "Business Owner";
          if (isEmployee) return "Any Employee";

          return teamManagerTextSimplifiedDecider(
            youOrNameFunc({
              name: userName,
              currentUser: user,
            })
          );
        };

        const name = nameDecider();

        const textDecider = () => {
          if (index != 0) return `Or by ${name}`;
          if (hasApproved)
            return bypassed
              ? `System bypassed ${name} approval step.`
              : `Approved by ${name}`;
          if (rejected_at && !rejected_by_id) return `Rejected`;
          if (rejected_at) return `Rejected by ${name}`;
          return `Required by ${name} to approve`;
        };

        const text = textDecider();

        return {
          ...item,
          date,
          text,
          isOrange: !hasApproved,
          isGreen: hasApproved && !bypassed,
          isRed: Boolean(rejected_at),
        };
      })
      .filter((array) => {
        return array;
      });

    const obj = { data: result };

    approverLineArray.push(obj);
    isActiveArray.push(Boolean(result.filter(({ date }) => date).length));
  });

  approverLineArray = approverLineArray
    .map((item, index) => {
      const isActive = isActiveArray[index - 1] || index == 0;
      return { ...item, isActive };
    })
    .filter(({ data }) => data.length > 0);

  const progressArrayDecider = (isRequestAdjustingLimit) => {
    if (isRequestAdjustingLimit) {
      const limitRequesterArray = [
        {
          text: `${youOrNameFunc({
            name: cardOwner?.name,
            currentUser: user,
          })} requested to adjust the card limit`,
          isActive: true,
          date: customDateFormatter(requestAdjustLimitTimestamp),
        },
      ];

      const completedLimitArray = [
        {
          text: "Adjustment is in progress",
          isActive: false,
        },
        {
          text: "Completed",
          isActive: false,
        },
      ];

      const progressArray = [
        ...limitRequesterArray,
        ...approverLineArray,
        ...(rejection_note ? [] : completedLimitArray),
      ];

      return progressArray;
    }

    const cardRequesterArray = [
      {
        text: `${youOrNameFunc({
          name: progress_summary[0]?.user_name,
          currentUser: user,
        })} requested to issue the card`,
        isActive: true,
        date: customDateFormatter(progress_summary[0].timestamp),
      },
    ];

    const isWorkflowDoneChecker = (approverLineArray) => {
      const lastItem = approverLineArray[approverLineArray.length - 1];
      const lastItemData = lastItem.data[lastItem.data.length - 1];
      const lastDataWidthDate = lastItemData.date;
      return Boolean(lastDataWidthDate);
    };

    const isWorkflowDone = isWorkflowDoneChecker(approverLineArray);

    const completedVirtualArray = [
      {
        text: progress_summary[progress_summary.length - 2].text,
        isActive: isWorkflowDone ? true : false,
        date: progress_summary[progress_summary.length - 2].timestamp,
      },
      {
        text: progress_summary[progress_summary.length - 1].text,
        isActive: progress_summary[progress_summary.length - 1].is_active,
        date: progress_summary[progress_summary.length - 1].timestamp,
      },
    ];

    const progressArray = [
      ...cardRequesterArray,
      ...approverLineArray,
      ...(rejection_note ? [] : completedVirtualArray),
    ];

    return progressArray;
  };

  const progressArray = progressArrayDecider(isRequestAdjustingLimit);
  const filteredProgressArray = progressArray.filter(
    ({ isActive }) => isActive !== undefined
  );

  return {
    progressArray: filteredProgressArray,
    rejection_note,
    currentWorkflowProcess,
  };
};

export default useCardWorkflow;
