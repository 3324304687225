import { useGetAuth } from "../../../contexts/AuthContext";

export const useCrossBorderSingleWorkflowApproverLogics = (transaction) => {
  const { user } = useGetAuth();
  const { approval_tasks, workflow_approval_tasks } = transaction || {};

  const currentTaskId = (
    workflow_approval_tasks ||
    approval_tasks ||
    []
  ).filter(
    ({ user_id, state, current_order }) =>
      state == "requested" && user_id == user?.id && current_order
  )[0]?.id;

  const canApproveDecider = () => {
    if (!approval_tasks) return true;

    approval_tasks.sort((prev, curr) => {
      const roleOrderDecider = (item) => item.role.split("-")[2];

      const prevRoleOrder = roleOrderDecider(prev);
      const currRoleOrder = roleOrderDecider(curr);

      if (Number(prevRoleOrder) > Number(currRoleOrder)) return 1;
      if (Number(prevRoleOrder) < Number(currRoleOrder)) return -1;
      return 0;
    });

    // find currentOrder

    // create orderObj
    const orderObj = {};

    approval_tasks.forEach((approval_task) => {
      const { role } = approval_task || {};
      if (!orderObj[role]) return (orderObj[role] = [approval_task]);
      orderObj[role].push(approval_task);
    });
    // create orderObj

    let currentOrder = "";

    const orderObjKeys = Object.keys(orderObj);

    orderObjKeys.forEach((key) => {
      if (currentOrder) return;

      const array = orderObj[key];

      const requestedArr = [];

      array.forEach((approval_task) => {
        if (approval_task.state !== "requested") return;
        requestedArr.push(approval_task.state);
      });

      if (requestedArr.length !== array.length) return;

      currentOrder = key;
    });
    // find currentOrder

    const currentApprovalTasks = approval_tasks.filter(
      ({ role }) => role === currentOrder
    );

    const foundApprover = currentApprovalTasks.find(
      ({ user_id }) => user_id === user?.id
    );

    if (foundApprover) return true;
    return false;
  };

  const canApprove = currentTaskId && canApproveDecider();

  return { canApprove, taskId: currentTaskId };
};
