import React, { useEffect, useRef, useState } from "react";
import { FaSpinner } from "react-icons/fa";
import { Modal } from "reactstrap";
import downIcon from "../assets/images/chevron-down.svg";
import tooltipPng from "../assets/images/tooltip-payroll.svg";
import { useClickOutside } from "../universalFunctions/useClickOutside";
import { colors } from "../assets/colors";
import plusIcon from "../assets/images/plus-pink-icon.svg";
import caretDownIcon from "../assets/images/caret-down-pink.svg";
import { useModalHook, useModalWidth } from "./Modals";
import { GothamMedium, GothamRegular } from "./Text";
import { TooltipCustom } from "./Tooltip";
import { EWalletFilterNumber } from "../pageComponents/eWalletComponents/home/main/filter/parent";

export const PlusButton = ({ children, onClick }) => (
  <OutlineButton
    onClick={onClick}
    style={{
      width: "100%",
    }}
    icon={<img src={plusIcon} style={{ width: 20 }} className="mr-2" />}
  >
    {children}
  </OutlineButton>
);

export const WhiteButton = ({ onClick, children, number, style, ...props }) => {
  const ref = useRef();
  const { clientHeight = 0, clientWidth = 0 } = ref?.current || {};

  return (
    <div className="d-flex">
      <CustomButton
        style={{
          height: 32,
          backgroundColor: "white",
          color: colors.pink,
          border: `1px solid #E6E6E8`,
          position: "relative",
          fontSize: 14,
          ...style,
        }}
        onClick={onClick}
        {...props}
      >
        {Boolean(number) && (
          <div
            style={{
              position: "absolute",
              top: -(clientHeight / 2),
              right: -(clientWidth / 2),
            }}
            ref={ref}
          >
            <EWalletFilterNumber
              style={{ color: "white", backgroundColor: colors.pink }}
            >
              {number}
            </EWalletFilterNumber>
          </div>
        )}
        {children}
      </CustomButton>
      <div style={{ width: clientWidth / 2 }}></div>
    </div>
  );
};

export const CustomButton = ({
  children,
  className = "",
  woClassName = false,
  woHeight = false,
  icon = null,
  isLoading,
  disabled,
  disabledGrey,
  style,
  leftIcon = null,
  rightIcon = null,
  opacityControl = 0.75,
  loadingColor = "",
  alertIcon = null,
  ...props
}) => {
  const isDisabled = isLoading || disabled || disabledGrey;
  return (
    <button
      className={`${
        woClassName
          ? ""
          : "btn d-flex align-items-center justify-content-center"
      } ${className}`}
      {...props}
      style={{
        fontSize: 16,
        height: !woHeight && 48,
        backgroundColor: disabledGrey
          ? "#e6e6e8"
          : isDisabled
          ? "#e184b6"
          : !woClassName && colors.pink,
        opacity: isDisabled ? opacityControl : 1,
        position: "relative",
        ...style,
      }}
      disabled={isDisabled}
    >
      {alertIcon && (
        <div
          style={{
            position: "absolute",
            left: -8,
            top: -8,
          }}
        >
          {alertIcon}
        </div>
      )}
      {!isLoading && leftIcon}
      {isLoading ? (
        <FaSpinner
          className="icon-spin mr-2 mb-1 "
          color={loadingColor ? loadingColor : ""}
        />
      ) : (
        icon
      )}
      {children}
      {!isLoading && rightIcon}
    </button>
  );
};

export const GreyButton = ({ children, className = "", ...props }) => (
  <button className={`greybtn ${className}`} {...props}>
    {children}
  </button>
);

export const OutlineButton = ({
  children,
  className = "",
  icon = null,
  style,
  isLoading,
  disabled,
  leftIcon = null,
  rightIcon = null,
  ...props
}) => {
  const color = disabled ? colors.greyd1 : colors.pink;
  return (
    <button
      className={`outline ${className}`}
      style={{
        borderColor: color,
        color,
        ...style,
      }}
      disabled={isLoading || disabled}
      {...props}
    >
      {!isLoading && leftIcon}
      {isLoading ? <FaSpinner className="icon-spin mr-2 mb-1 " /> : icon}
      {children}
      {!isLoading && rightIcon}
    </button>
  );
};

export const DialogButton = ({
  children,
  className = "",
  woClassName = false,
  icon = null,
  isLoading,
  disabled,
  style,
  rightIcon = null,
  text = "Are you sure?",
  onClick = () => {},
  notButton,
  alignLeft,
  title,
  cancelText,
  columnAlign,
  deleteButton,
  ...props
}) => {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal((p) => !p);

  const DefaultModal = () => (
    <ConfirmationModal
      toggle={toggle}
      modal={modal}
      onClick={onClick}
      text={text}
      alignLeft={alignLeft}
      title={title}
      cancelText={cancelText}
      columnAlign={columnAlign}
      deleteButton={deleteButton}
    />
  );

  if (notButton)
    return (
      <>
        <div
          onClick={(e) => {
            if (disabled) return;
            if (isLoading) return;
            e && e.preventDefault();
            e && e.stopPropagation();
            toggle();
          }}
        >
          {children}
        </div>
        <DefaultModal />
      </>
    );

  return (
    <>
      <CustomButton
        className={className}
        woClassName={woClassName}
        icon={icon}
        onClick={() => {
          toggle();
        }}
        isLoading={isLoading}
        disabled={disabled}
        style={style}
        rightIcon={rightIcon}
        {...props}
      >
        {children}
      </CustomButton>
      <DefaultModal />
    </>
  );
};

export const TableDialogButton = ({
  textModal,
  onClick,
  textStyle,
  children,
  noHover,
}) => (
  <DialogButton
    text={textModal}
    className="fake-button"
    notButton
    onClick={onClick}
  >
    <GothamMedium
      style={{ color: colors.pink, textAlign: "center", ...textStyle }}
    >
      <span className={`${noHover ? "" : "darkhover"} p-1`}>{children}</span>
    </GothamMedium>
  </DialogButton>
);

export const ConfirmationModal = ({
  toggle,
  modal,
  onClick,
  text = "Are you sure?",
  title,
  alignLeft,
  cancelText,
  columnAlign = false,
  simpleCancel,
  deleteButton,
  customButton,
  reverseButton,
  buttonTextLeft,
  buttonTextRight,
}) => {
  const modalWidth = useModalWidth(528);

  const leftButtonDecider = () => {
    if (customButton) return buttonTextLeft;
    if (cancelText) return cancelText;
    else return "Cancel";
  };

  const rightButtonDecider = () => {
    if (customButton) return buttonTextRight;
    if (deleteButton) return "Delete";
    else return "Confirm";
  };

  return (
    <Modal
      toggle={toggle}
      isOpen={modal}
      className="my-0"
      centered
      contentClassName="modal-transfez"
    >
      <div
        style={{
          backgroundColor: "white",
          width: modalWidth,
          borderRadius: 12,
        }}
        className="px-4 pt-4 pb-4"
      >
        <GothamMedium className="font24" style={{ color: colors.blue10 }}>
          {title ? title : "Confirmation"}
        </GothamMedium>
        <GothamRegular
          className="font14"
          style={{
            textAlign: alignLeft ? "left" : "center",
            marginTop: 24,
            marginBottom: 24,
          }}
        >
          {text}
        </GothamRegular>
        <div
          className={`d-flex ${
            columnAlign && "flex-column-reverse"
          } justify-content-between ${reverseButton && "flex-row-reverse"}`}
          style={{
            alignItems: simpleCancel && columnAlign ? "center" : "flex-start",
            gap: simpleCancel && columnAlign ? "20px" : "0px",
          }}
        >
          {simpleCancel ? (
            <GothamMedium
              style={{ color: "#909098", fontSize: "16px", cursor: "pointer" }}
              onClick={toggle}
            >
              {leftButtonDecider()}
            </GothamMedium>
          ) : (
            <OutlineButton
              onClick={toggle}
              style={{
                width: columnAlign ? "100%" : "48%",
                marginTop: columnAlign && 16,
                height: 48,
                fontSize: 16,
              }}
            >
              {leftButtonDecider()}
            </OutlineButton>
          )}

          <CustomButton
            style={{ width: columnAlign ? "100%" : "48%" }}
            onClick={() => {
              onClick();
              toggle();
            }}
          >
            {rightButtonDecider()}
          </CustomButton>
        </div>
      </div>
    </Modal>
  );
};

export const DropdownButton = ({
  children,
  style,
  array = [],
  onClick,
  isCustomButton,
  dropDownStyle,
  fontStyle,
  leftIcon,
  rightIcon,
  woClassName,
  className,
  onClickDropdown = () => {},
  withLabelIcon,
  disabled = false,
}) => {
  const [buttonHeight, setButtonHeight] = useState(0);

  const ref = useRef();
  const { isOpen, close, toggle } = useModalHook();
  useClickOutside({
    clickOutside: () => {
      close();
    },
    ref,
  });

  useEffect(() => {
    if (!isOpen) return onClickDropdown(false);
    if (isOpen) return onClickDropdown(true);
  }, [isOpen]);

  const Button = () => {
    const props = {
      onClick: () => {
        toggle();
      },
      style,
      leftIcon,
      rightIcon,
      woClassName,
      className,
      disabled,
    };
    if (isCustomButton)
      return <CustomButton {...props}>{children}</CustomButton>;
    return (
      <OutlineButton {...props}>
        {children}
        <img src={downIcon} className="ml-2" />
      </OutlineButton>
    );
  };

  return (
    <div ref={ref} style={{ position: "relative" }}>
      <div
        ref={(e) => {
          if (!e) return;
          setButtonHeight(e.clientHeight);
        }}
      >
        <Button />
      </div>
      <div
        style={{
          position: "absolute",
          top: buttonHeight + 5,
          width: 250,
          backgroundColor: "white",
          border: "1px solid #eaeaea",
          borderRadius: 5,
          display: isOpen ? "" : "none",
          ...dropDownStyle,
        }}
        className={isOpen ? "fade-in" : "fade-out"}
      >
        {array.map((item, index) => {
          const { label, value, icon } = item || {};
          if (!item) return null;
          return (
            <div
              key={index}
              style={{
                display: withLabelIcon && "flex",
                paddingLeft: withLabelIcon && "12px",
              }}
              className={withLabelIcon && "darkhover"}
            >
              {icon}
              <GothamMedium
                style={{ padding: 12, color: colors.grey72, ...fontStyle }}
                className={`${!withLabelIcon && "darkhover"}`}
                onClick={() => {
                  onClick(value);
                  onClickDropdown();
                  close();
                }}
              >
                {label}
              </GothamMedium>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export const DropdownBorderless = ({
  children,
  style,
  array = [],
  onClick,
  isCustomButton,
  dropDownStyle,
  tooltipText = "",
  isFirst = false,
  noToolTip = false,
}) => {
  const ref = useRef();
  const { isOpen, close, toggle } = useModalHook();
  useClickOutside({ clickOutside: close, ref });
  const [buttonHeight, setButtonHeight] = useState(0);

  const Button = ({ useTooltip }) => {
    const props = { onClick: toggle, style };
    return (
      <div className="d-flex" style={{ position: "relative" }} {...props}>
        <GothamRegular className="font14 mr-2">{children}</GothamRegular>
        <img src={caretDownIcon} />
        {!noToolTip && useTooltip && (
          <img
            src={tooltipPng}
            style={{ position: "absolute", top: -15, right: -220 }}
          />
        )}
      </div>
    );
  };

  const Wrapper = ({ useTooltip = false, tooltipText = "" }) => {
    return (
      <>
        {useTooltip && tooltipText ? (
          <TooltipCustom textInside={tooltipText}>
            <Button />
          </TooltipCustom>
        ) : useTooltip ? (
          <Button useTooltip={useTooltip} />
        ) : (
          <Button />
        )}
      </>
    );
  };

  return (
    <div ref={ref} style={{ position: "relative" }}>
      <div
        ref={(e) => {
          if (!e) return;
          setButtonHeight(e.clientHeight);
        }}
      >
        <Wrapper useTooltip={isFirst} tooltipText={tooltipText} />
      </div>
      <div
        style={{
          position: "absolute",
          zIndex: 99,
          top: buttonHeight + 5,
          right: 0,
          width: 263,
          backgroundColor: "white",
          borderRadius: 8,
          border: "none",
          boxShadow: "none",
          filter: "drop-shadow(0px 8px 20px rgba(88, 88, 88, 0.1))",
          display: isOpen ? "" : "none",
          ...dropDownStyle,
        }}
        className={isOpen ? "fade-in" : "fade-out"}
      >
        {array.map((item, index) => {
          const isLast = array.length - 1 === index;
          const { title, label, value } = item || {};
          return (
            <div
              key={index}
              className="pinkhover"
              style={{ padding: "8px 12px" }}
              onClick={() => {
                onClick(value);
                close();
              }}
            >
              {isLast ? (
                <GothamMedium
                  style={{ color: colors.redE7 }}
                  className="font14"
                >
                  {title}
                </GothamMedium>
              ) : (
                <>
                  <GothamMedium className="font14">{title}</GothamMedium>
                  <GothamRegular className="font12">{label}</GothamRegular>
                </>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};
