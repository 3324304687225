import React from "react";
import { colors } from "../assets/colors";
import Link from "next/link";

export const OldGothamRegular = ({
  children,
  style,
  className = "",
  ...props
}) => {
  const defaultObj = {
    fontFamily: "GothamBook",
    fontSize: 14,
  };
  return (
    <h1
      style={
        className
          ? {
              ...defaultObj,
              ...style,
            }
          : {
              ...defaultObj,
              color: colors.grey25,
              ...style,
            }
      }
      className={className}
      {...props}
    >
      {children}
    </h1>
  );
};

export const OldGothamMedium = ({
  className = "",
  children,
  style,
  ...props
}) => {
  const DefaultText = ({ children }) => (
    <h1
      className={className}
      style={{
        fontFamily: "GothamMedium",
        fontSize: 14,
        ...style,
      }}
      {...props}
    >
      {children}
    </h1>
  );

  const hasNewLine = String(children).includes("\n");
  if (hasNewLine) {
    const splittedStrings = children.split("\n");
    return (
      <>
        {splittedStrings.map((string, index) => (
          <DefaultText key={index}>{string}</DefaultText>
        ))}
      </>
    );
  }

  return <DefaultText>{children}</DefaultText>;
};

export const GothamRegular = ({
  children,
  style,
  className = "",
  woClassName,
  woFontColor = false,
  ...props
}) => {
  const isFontClassName = className.includes("font");
  const DefaultText = ({ children }) => (
    <h1
      className={isFontClassName ? className : `font14 ${className}`}
      style={{
        fontFamily: "GothamBook",
        color: !woFontColor && colors.grey25,
        marginBottom: 0,
        ...style,
      }}
      {...props}
    >
      {children}
    </h1>
  );
  const hasNewLine = String(children).includes("\n");
  if (hasNewLine) {
    const splittedStrings = children.split("\n");
    return (
      <>
        {splittedStrings.map((string, index) => (
          <DefaultText key={index}>{string}</DefaultText>
        ))}
      </>
    );
  }

  return <DefaultText>{children}</DefaultText>;
};

export const GothamBold = ({
  className = "",
  children,
  style,
  woFontSize,
  ...props
}) => (
  <h1
    className={className}
    style={{
      fontFamily: "GothamBold",
      fontSize: !woFontSize && 14,
      ...style,
    }}
    {...props}
  >
    {children}
  </h1>
);

export const GothamMedium = ({
  woClassName,
  className = "",
  children,
  style,
  ...props
}) => {
  const isFontClassName = className.includes("font");

  const DefaultText = ({ children }) => (
    <h1
      style={{
        fontFamily: "GothamMedium",
        marginBottom: 0,
        ...style,
      }}
      className={isFontClassName ? className : `font14 ${className}`}
      {...props}
    >
      {children}
    </h1>
  );

  const hasNewLine = String(children).includes("\n");
  if (hasNewLine) {
    const splittedStrings = children.split("\n");
    return (
      <>
        {splittedStrings.map((string, index) => (
          <DefaultText key={index}>{string}</DefaultText>
        ))}
      </>
    );
  }

  return <DefaultText>{children}</DefaultText>;
};

export const GothamLight = ({ className = "", children, style, ...props }) => (
  <h1
    className={className}
    style={{
      fontFamily: "GothamLight",
      fontSize: 14,
      marginBottom: 0,
      ...style,
    }}
    {...props}
  >
    {children}
  </h1>
);

export const TypeFezNeueBold = ({
  children,
  style,
  className = "",
  woClassName,
  woFontColor = false,
  ...props
}) => {
  const isFontClassName = className.includes("font");
  const DefaultText = ({ children }) => (
    <h1
      className={isFontClassName ? className : `font14 ${className}`}
      style={{
        fontFamily: "TypeFezNeueBold",
        color: !woFontColor && colors.grey25,
        marginBottom: 0,
        ...style,
      }}
      {...props}
    >
      {children}
    </h1>
  );

  const hasNewLine = String(children).includes("\n");

  if (hasNewLine) {
    const splittedStrings = children.split("\n");
    return (
      <>
        {splittedStrings.map((string, index) => (
          <DefaultText key={index}>{string}</DefaultText>
        ))}
      </>
    );
  }

  return <DefaultText>{children}</DefaultText>;
};

export const TypeFezNeueMedium = ({
  children,
  style,
  className = "",
  woClassName,
  woFontColor = false,
  ...props
}) => {
  const isFontClassName = className.includes("font");
  const DefaultText = ({ children }) => (
    <h1
      className={isFontClassName ? className : `font14 ${className}`}
      style={{
        fontFamily: "TypeFezNeueMedium",
        color: !woFontColor && colors.grey25,
        marginBottom: 0,
        ...style,
      }}
      {...props}
    >
      {children}
    </h1>
  );

  const hasNewLine = String(children).includes("\n");

  if (hasNewLine) {
    const splittedStrings = children.split("\n");
    return (
      <>
        {splittedStrings.map((string, index) => (
          <DefaultText key={index}>{string}</DefaultText>
        ))}
      </>
    );
  }

  return <DefaultText>{children}</DefaultText>;
};

export const TypeFezNeueLight = ({
  children,
  style,
  className = "",
  woClassName,
  woFontColor = false,
  ...props
}) => {
  const isFontClassName = className.includes("font");
  const DefaultText = ({ children }) => (
    <h1
      className={isFontClassName ? className : `font14 ${className}`}
      style={{
        fontFamily: "TypeFezNeueLight",
        color: !woFontColor && colors.grey25,
        marginBottom: 0,
        ...style,
      }}
      {...props}
    >
      {children}
    </h1>
  );

  const hasNewLine = String(children).includes("\n");

  if (hasNewLine) {
    const splittedStrings = children.split("\n");
    return (
      <>
        {splittedStrings.map((string, index) => (
          <DefaultText key={index}>{string}</DefaultText>
        ))}
      </>
    );
  }

  return <DefaultText>{children}</DefaultText>;
};

export const TypeFezNeueRegular = ({
  children,
  style,
  className = "",
  woClassName,
  woFontColor = false,
  ...props
}) => {
  const isFontClassName = className.includes("font");
  const DefaultText = ({ children }) => (
    <h1
      className={isFontClassName ? className : `font14 ${className}`}
      style={{
        fontFamily: "TypeFezNeueRegular",
        color: !woFontColor && colors.grey25,
        marginBottom: 0,
        ...style,
      }}
      {...props}
    >
      {children}
    </h1>
  );

  const hasNewLine = String(children).includes("\n");

  if (hasNewLine) {
    const splittedStrings = children.split("\n");
    return (
      <>
        {splittedStrings.map((string, index) => (
          <DefaultText key={index}>{string}</DefaultText>
        ))}
      </>
    );
  }

  return <DefaultText>{children}</DefaultText>;
};

export const CustomLink = ({ href = "/", style, children }) => (
  <Link href={href}>
    <a
      className="link"
      style={{
        fontFamily: "GothamBook",
        fontSize: 14,
        ...style,
      }}
    >
      {children}
    </a>
  </Link>
);

export const TextInlineRegular = ({ children, className = "", style }) => (
  <span
    style={{
      fontFamily: "GothamBook",
      display: "inline",
      ...style,
    }}
    className={`my-0 ${className}`}
  >
    {children}
  </span>
);
export const TextInlineMedium = ({
  children,
  className = "",
  style,
  ...props
}) => (
  <span
    style={{ fontFamily: "GothamMedium", display: "inline", ...style }}
    className={`my-0 ${className}`}
    {...props}
  >
    {children}
  </span>
);
export const TextInlineBold = ({ children, className = "", style }) => (
  <span
    style={{ fontFamily: "GothamBold", display: "inline", ...style }}
    className={`my-0 ${className}`}
  >
    {children}
  </span>
);

export const SpanColor = ({ children, style, color = "black" }) => {
  return <span style={{ color, ...style }}>{children}</span>;
};
