import { paymentInstructionBooleans } from "../../../pageComponents/paymentInstructionComponents/logics";
import { eventsTracker } from "../../../universalFunctions/events";

export const paymentInstructionTitleAndOnClick = ({ push, back, query }) => {
  const {
    isCrossBorder,
    isTopUpIndoWallet,
    isLocalTransfer,
    isSinglePayment,
    isBatchPayment,
  } = paymentInstructionBooleans();
  const { id } = query || {};

  const onClick = () => {
    if (isSinglePayment)
      return push({
        pathname: "/history",
        query: {
          id,
          page_type_details: "batch_payment",
          page_type: "single",
        },
      });

    if (isBatchPayment)
      return push({
        pathname: "/history",
        query: {
          id,
          page_type_details: "batch_payment",
          page_type: "batch",
        },
      });

    if (isTopUpIndoWallet) {
      eventsTracker("topup_payment_instruction_to_wallet_history", { id });
      return push({
        pathname: "/account/IDR",
        query: { id, type: "indonesian_wallet" },
      });
    }

    if (isLocalTransfer)
      return push({
        pathname: "/history",
        query: {
          id,
          page_type_details: "batch_payment",
          page_type: "local_batch",
        },
      });

    back();
  };

  return { onClick, title: "Payment Instructions" };
};
