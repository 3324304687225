import React, { useEffect, useRef, useState } from "react";
import { colors } from "../../../assets/colors";
import downloadBulkBlack from "../../../assets/images/download-bulk-black.svg";
import downloadBulkPink from "../../../assets/images/download-bulk-pink.svg";
import downloadIcon from "../../../assets/images/download-history-white.svg";
import downloadXlsBlack from "../../../assets/images/download-xls-black.svg";
import downloadXlsPink from "../../../assets/images/download-xls-pink.svg";
import payrollIcons from "../../../assets/images/payroll-icon-40.svg";
import { NameAvatar } from "../../../components/Avatar";
import { CustomButton } from "../../../components/Buttons";
import {
  MainCardHeaderMolecule,
  MiddleWrapper,
  RightCardModalAtomic,
} from "../../../components/CardModal";
import { CheckBox, deepUseIdsTable } from "../../../components/Checkbox";
import { NakedSearchInput } from "../../../components/inputs";
import { useModalHook } from "../../../components/Modals";
import { GothamMedium, GothamRegular } from "../../../components/Text";
import {
  formatCurrencyNoDecimal,
  windowDimension,
} from "../../../components/tools";
import { useClickOutside } from "../../../universalFunctions/useClickOutside";
import { CenterendIconLocalTransfer } from "../localDisbursementBatchModal/transactionModal";

export const DownloadBulkAndSingleButton = ({ onClick, isLoading = false }) => {
  const { isOpen, toggle, close } = useModalHook();
  const myRef = useRef(null);

  useClickOutside({ clickOutside: close, ref: myRef });

  const array = [
    {
      title: "Batch Receipt",
      array: [
        {
          msg: "Download PDF",
          src: downloadBulkBlack,
          srcHover: downloadBulkPink,
          type: "bulk",
        },
        {
          msg: "Download XLS",
          src: downloadXlsBlack,
          srcHover: downloadXlsPink,
          type: "bulk_xls",
        },
      ],
    },
    {
      title: "Transaction Receipt",
      array: [
        {
          msg: "Download PDF",
          type: "single",
          src: downloadBulkBlack,
          srcHover: downloadBulkPink,
        },
      ],
    },
  ];

  useEffect(() => {
    if (isLoading) close();
  }, [isLoading]);

  const [isHoveredIndex, setIsHoveredIndex] = useState(false);

  return (
    <div style={{ position: "relative" }}>
      <CustomButton
        onClick={toggle}
        style={{ height: 32, paddingLeft: 24, paddingRight: 24 }}
        leftIcon={<img src={downloadIcon} className="mr-2" />}
        isLoading={isLoading}
      >
        Download Receipt
      </CustomButton>
      {isOpen && (
        <div
          ref={myRef}
          style={{
            position: "absolute",
            width: "100%",
            marginTop: 8,
            backgroundColor: "white",
            border: "1px solid #F8F8F8",
            boxShadow: "0px 8px 20px rgba(88, 88, 88, 0.1)",
            borderRadius: 8,
            padding: 12,
          }}
        >
          {array.map((item, index) => {
            const { array, title } = item || {};
            return (
              <div key={index} style={{ marginBottom: 12 }}>
                <GothamMedium
                  className="font12"
                  style={{
                    paddingBottom: 12,
                    borderBottom: `1px solid ${colors.greye6}`,
                    color: colors.blue10,
                  }}
                >
                  {title}
                </GothamMedium>
                {array.map((item, childIndex) => {
                  const { msg, src, srcHover } = item;
                  const isFirst = childIndex == 0;
                  const isHovered =
                    isHoveredIndex?.index == index &&
                    isHoveredIndex?.childIndex == childIndex;
                  return (
                    <div
                      className="d-flex"
                      key={childIndex}
                      onClick={() => onClick(item)}
                      onMouseEnter={() =>
                        setIsHoveredIndex({ index, childIndex })
                      }
                      onMouseLeave={() => setIsHoveredIndex(false)}
                      style={{ marginTop: isFirst ? 14 : 8, cursor: "pointer" }}
                    >
                      <img src={isHovered ? srcHover : src} className="mr-2" />
                      <GothamRegular
                        className="font12"
                        style={{
                          color: isHovered ? colors.pink : colors.blue10,
                        }}
                      >
                        {msg}
                      </GothamRegular>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export const DownloadSingleModal = ({
  array = [],
  isOpen,
  toggle,
  setArrayIdDownload,
  loading,
}) => {
  const [search, setSearch] = useState("");
  const [middleHeight, setMiddleHeight] = useState(0);
  const { screenRealHeight } = windowDimension();

  const arrayOnlyComplete = array?.filter(({ state }) => state === "completed");
  const {
    handleClickItem,
    handleUncheckedAll,
    handleClickAll,
    isAllChecked,
    selectedIds,
  } = deepUseIdsTable(arrayOnlyComplete);

  const totalTrx = selectedIds.length;
  const isNotChecked = selectedIds.length === 0;

  const filteredArray = array
    ?.filter(({ leftTop }) =>
      leftTop.toLowerCase().includes(search.toLowerCase())
    )
    .filter(({ state }) => state === "completed");

  const GetRealIdsFunc = () => {
    let paramsArray = "";

    filteredArray?.map((item, index) => {
      selectedIds?.map((selectedId) => {
        if (selectedId === index) {
          if (selectedId === 0) {
            paramsArray += `single_ids[]=${item?.id}`;
          } else {
            paramsArray += `&single_ids[]=${item?.id}`;
          }
        }
      });
    });
    return paramsArray;
  };

  const getParamsId = GetRealIdsFunc();

  const handleDownloadSingle = () => {
    setArrayIdDownload({ type: "id_download", value: getParamsId });
  };

  // useEffect(() => {}, [selectedIds]);

  return (
    <RightCardModalAtomic
      isOpen={isOpen}
      toggle={toggle}
      header={
        <MainCardHeaderMolecule
          toggle={toggle}
          customComponent={
            <div
              style={{ width: "100%", height: 100 }}
              className="d-flex align-items-start flex-column"
            >
              <GothamMedium
                style={{ fontSize: 24, color: "white", marginBottom: 8 }}
              >
                Transaction receipt
              </GothamMedium>
              <GothamRegular style={{ color: "white" }}>
                To download payroll transaction, please select employee
                transaction you want to download.
              </GothamRegular>
            </div>
          }
        />
      }
      middleHeight={middleHeight}
      middle={
        <MiddleWrapper setHeight={setMiddleHeight} height={middleHeight}>
          <CenterendIconLocalTransfer icon={payrollIcons} />
        </MiddleWrapper>
      }
      bodyStyle={{
        maxHeight: screenRealHeight - 150,
      }}
      body={
        <div>
          <NakedSearchInput
            placeholder="Search employee name"
            onChange={(e) => setSearch(e)}
          />
          <div
            className="d-flex justify-content-between"
            style={{
              padding: "12px 0px",
              borderBottom: "1px solid #E6E6E8",
            }}
          >
            <div className="d-flex align-items-center" style={{ gap: 12 }}>
              <CheckBox
                isActive={isAllChecked}
                onClick={handleClickAll}
                //   style={{ marginTop: -5 }}
              />
              <GothamMedium>Recipient</GothamMedium>
            </div>
            <div>
              <GothamMedium>Amount</GothamMedium>
            </div>
          </div>
          <div
            style={{
              minHeight: screenRealHeight - 325,
              maxHeight: screenRealHeight - 325,
              overflowY: "scroll",
            }}
          >
            {filteredArray?.map((item, index) => {
              const isActive = (selectedIds || []).includes(index);
              const { leftTop, rightTop, leftBot, amount } = item || {};
              const pureAmount = formatCurrencyNoDecimal(Number(amount));
              return (
                <div
                  className="d-flex justify-content-between align-items-center"
                  style={{ padding: "6px 0px", margin: "12px 0px" }}
                >
                  <div
                    className="d-flex align-items-center"
                    style={{ gap: 12 }}
                  >
                    <CheckBox
                      isActive={isActive}
                      onClick={() => handleClickItem(index, isActive)}
                    />
                    <NameAvatar>{leftTop}</NameAvatar>
                    <div>
                      <GothamMedium style={{ lineHeight: "20px" }}>
                        {leftTop}
                      </GothamMedium>
                      <GothamRegular
                        style={{
                          fontSize: 12,
                          color: colors.grey6c,
                          lineHeight: "14px",
                        }}
                      >
                        {leftBot}
                      </GothamRegular>
                    </div>
                  </div>
                  <div>
                    <GothamRegular>{rightTop}</GothamRegular>
                  </div>
                </div>
              );
            })}
          </div>
          <div
            style={{ padding: "16px 0px" }}
            className="d-flex justify-content-between align-items-center"
          >
            <GothamMedium>{totalTrx} transactions selected</GothamMedium>
            <CustomButton
              style={{ height: 32 }}
              disabled={isNotChecked}
              onClick={handleDownloadSingle}
              isLoading={loading}
            >
              Download
            </CustomButton>
          </div>
        </div>
      }
    />
  );
};
