import { booleanState } from "../../../components/Status";

export const getUserRolePayroll = ({ data, user, updates }) => {
  const {
    drafter,
    state_summary = [],
    workflow_approval_tasks = [],
    state,
  } = data || {};
  const { id: userId, roles } = user || {};

  const { isDraft } = booleanState(state);

  const isCurrentDrafter = roles?.some(
    (item) => item?.name === "payroll_drafter"
  );

  const isDrafter = drafter?.id === userId;

  const isOldTransaction = !!state_summary?.length;

  const isApproverOld = state_summary?.some(
    (item) => item.role === "payroll_approver" && item.user_id === userId
  );

  const isApproverNew = workflow_approval_tasks?.some(
    (item) =>
      item.role.includes("payroll_approval-approval") && item.user_id === userId
  );

  const isApproverNeedApprove = state_summary?.some(
    (item) =>
      item.role === "payroll_approver" &&
      item.user_id === userId &&
      item.state === "requested"
  );

  const isApproverDoneApprove = state_summary?.some(
    (item) =>
      item.role === "payroll_approver" &&
      item.user_id === userId &&
      item.state === "approved"
  );

  const isReleaser = workflow_approval_tasks?.some(
    (item) =>
      item?.role.includes("payroll_release") &&
      item?.user_id === userId &&
      item?.state === "requested" &&
      item?.current_order
  );

  // FOR HANDLE CAN SEE DETAILS TRX
  const releaserRole =
    workflow_approval_tasks[workflow_approval_tasks.length - 1]?.role;

  const isApproverArr = workflow_approval_tasks?.filter(
    ({ role }) => role !== releaserRole
  );
  const isReleaserArr = workflow_approval_tasks?.filter(
    ({ role }) => role === releaserRole
  );
  const isApproverPayroll = isApproverArr.some(
    ({ user_id }) => user_id === userId
  );
  const isReleaserPayroll = isReleaserArr.some(
    ({ user_id }) => user_id === userId
  );
  // FOR HANDLE CAN SEE DETAILS TRX

  const isApprovedByAll =
    !isDraft &&
    isReleaser &&
    state_summary.every(({ state }) => state === "approved");

  const isApprover = isOldTransaction ? isApproverOld : isApproverNew;

  const taskId = updates?.data?.filter(({ current_order }) => current_order)[0]
    ?.id;

  return {
    taskId,
    isDrafter,
    isCurrentDrafter,
    isReleaser,
    isReleaserPayroll,
    isApprover,
    isApproverPayroll,
    isApproverNeedApprove,
    isApproverDoneApprove,
    isOldTransaction,
    isApprovedByAll,
  };
};
