import React from "react";
import { FaChevronRight } from "react-icons/fa";
import { colors } from "../../assets/colors";
import userIcon from "../../assets/images/user-logo-small.svg";
import {
  useDropdownData,
  useDropdownHooks,
} from "../../components/ComponentWithSidebar";
import { BlueStatus, StatusBadge } from "../../components/Status";
import { GothamMedium, GothamRegular } from "../../components/Text";
import { useGetAuth } from "../../contexts/AuthContext";
import { removePartnerString } from "../tools";

export const UserBurger = ({ close, isOpen, top }) => {
  const { user } = useGetAuth();
  const { partner, email, business_role } = user || {};
  const {
    document_state = "",
    name: partnerName = "",
    roles = [],
  } = partner || {};
  const { name } = roles[0] || "";
  const roleName = removePartnerString(name || business_role);
  const { handleClick, height, width } = useDropdownHooks(close);

  const dropdownData = useDropdownData();

  if (!isOpen) return null;
  return (
    <div
      style={{
        padding: 16,
        flexDirection: "column",
        position: "absolute",
        width,
        height: height - top,
        top,
        left: 0,
        backgroundColor: "white",
        zIndex: 2,
      }}
      className={`d-flex align-items-center ${isOpen ? "fade-in" : "fade-out"}`}
    >
      <div className="d-flex w-100 align-items-center justify-content-between">
        <div className="d-flex align-items-center">
          <img src={userIcon} style={{ width: 32, marginRight: 12 }} />
          <div>
            <GothamMedium>{partnerName}</GothamMedium>
            <GothamRegular
              className="font12"
              style={{ marginBottom: 4, color: colors.grey72 }}
            >
              {email}
            </GothamRegular>
          </div>
        </div>
        <div
          className="d-flex "
          style={{ flexDirection: "column", marginTop: roleName ? -5 : 0 }}
        >
          {roleName && (
            <BlueStatus
              text={roleName}
              style={{ padding: 2, marginBottom: 4 }}
            />
          )}
          {document_state && (
            <StatusBadge
              state={document_state}
              style={{ height: 20, fontSize: 10 }}
              shortBg
            />
          )}
        </div>
      </div>

      <div style={{ height: 16 }} />
      <div className="w-100">
        {dropdownData.map((item, index) => {
          const { value, title, src } = item;
          return (
            <div
              key={index}
              className="d-flex align-items-center justify-content-between"
              style={{
                marginBottom: 12,
                borderBottom: `1px solid ${colors.greyC2}`,
                height: 64,
              }}
              onClick={(e) => {
                if (!e) return;
                e.preventDefault();
                e.stopPropagation();
                handleClick(value);
              }}
            >
              <div className="d-flex align-items-center">
                <img
                  src={src}
                  style={{ width: 24, marginRight: 16, marginLeft: 4 }}
                />
                <GothamMedium
                  style={{ color: colors.grey72 }}
                  className="font16"
                >
                  {title}
                </GothamMedium>
              </div>
              <FaChevronRight size={20} color={colors.blue} />
            </div>
          );
        })}
      </div>
    </div>
  );
};
