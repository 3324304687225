import { titleCase } from "change-case";
import { useEffect, useState } from "react";
import chevronRight from "../../../assets/images/chevron-grey-right.svg";
import jpegIcon from "../../../assets/images/jpg.svg";
import reimbursementIcon from "../../../assets/images/reimbursement-icon.svg";
import {
  MiddleWrapper,
  RightCardModalAtomic,
} from "../../../components/CardModal";
import { LeftRightList } from "../../../components/Lists";
import { PDFOrImageViewer } from "../../../components/PDFPreview";
import { StatusLabel } from "../../../components/Status";
import {
  GothamMedium,
  GothamRegular,
  TypeFezNeueMedium,
} from "../../../components/Text";
import { useConstants } from "../../../contexts/ConstantsContext/parent";
import { useGdrivehook } from "../../../contexts/GoogleDrivePreviewContext/parent";
import { attachmentDataFormatter } from "../../../pageComponents/invoiceComponents/data/formatter";
import { reimbursementFormatter } from "../../../pageComponents/reimbursementComponents/data/formatter";
import { fetch } from "../../../tools/api";
import { ActivityDetailsHeader } from "../activityModalComponents";
import { crossBorderSingleWorkflowComponents } from "../crossBorderSingleModal/components";
import { InvoicePaymentStatusLine } from "../invoicePayment/status";
import { TransactionDetailsBox } from "../invoicePayment/transactionDetails";
import { CenterendIconLocalTransfer } from "../localDisbursementBatchModal/transactionModal";
import { RecipientMinimalist } from "../transactionList";

export const ReimbursementModal = ({ isOpen, toggle, data }) => {
  if (!isOpen) return null;
  const { id: idRaw, originator_id } = data || {};

  const id = originator_id || idRaw;

  const [middleHeight, setMiddleHeight] = useState(0);

  const {
    data: details,
    refetch,
    loading,
  } = fetch({
    url: `/reimbursements/${id}`,
    woInit: true,
    defaultValue: {},
    formatter: ({ data, attachment_data }) => {
      // const [formattedData] = reimbursementFormatter([data]);
      // return { ...formattedData };
      if (data) {
        const formattedReimbursementData = reimbursementFormatter([data]);
        const [newData] = formattedReimbursementData;

        const newAttachmentData = attachmentDataFormatter(
          (attachment_data || []).map((item, index) => {
            const url =
              formattedReimbursementData[0].supporting_documents_files[index];
            return { ...item, url };
          })
        );
        return { ...newData, newAttachmentData };
      }
    },
  });

  const { buttons, rejectModal, isRejection, openPinModal } =
    crossBorderSingleWorkflowComponents({
      transaction: details,
      afterSuccess: refetch,
      cancelUrlFunc: (id) => `/reimbursements/${id}/change_state`,
      modelName: "reimbursement",
    });

  useEffect(() => {
    if (!id) return;
    refetch();
  }, [id]);

  const {
    createdDate,
    purchasedDate,
    merchant,
    memo,
    amountIDR,
    status,
    file_url,
    local_recipient_data,
    newAttachmentData,
    local_transaction,
  } = details || {};
  const { name, payer_id, account_number, registered_name } =
    local_recipient_data || {};
  const { idBanks, localTransferBanks } = useConstants();
  const bankObj = localTransferBanks.filter(
    ({ value }) => value == payer_id
  )[0];

  const { fee, amount, default_fee } = local_transaction || {};

  const showTrxDetails = ["cancelled", "canceled", "completed"].includes(
    status
  );

  const bank = bankObj?.label.toUpperCase();
  const headerData = {
    type: `Reimbursement ${merchant}`,
    icon: false,
    title: amountIDR,
  };
  const dataRecipient = {
    name: registered_name,
    bank,
    bankAccount: account_number,
  };
  const { show } = useGdrivehook();
  return (
    <>
      <RightCardModalAtomic
        isOpen={isOpen}
        toggle={toggle}
        middleHeight={middleHeight}
        hideModal={isRejection || openPinModal}
        header={
          <ActivityDetailsHeader
            toggle={toggle}
            data={headerData}
            middleHeight={middleHeight}
          />
        }
        middle={
          <MiddleWrapper setHeight={setMiddleHeight} height={middleHeight}>
            <CenterendIconLocalTransfer icon={reimbursementIcon} />
          </MiddleWrapper>
        }
        body={
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <StatusLabel status={status} />
            {buttons()}
            <div className="w-100" style={{ marginTop: 24 }}>
              <LeftRightList
                array={[
                  { left: "Created date", right: createdDate },
                  {
                    left: "Purchased date",
                    right: purchasedDate,
                  },
                  {
                    left: "Merchant",
                    right: merchant,
                  },
                ]}
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 12,
                  marginBottom: 12,
                }}
              >
                <GothamMedium style={{ color: "#6C6C71" }}>Memo</GothamMedium>
                <GothamRegular style={{ color: "#6C6C71" }}>
                  {memo}
                </GothamRegular>
              </div>
              <div style={{ borderBottom: "1px solid #E6E6E8" }} />
            </div>
            {showTrxDetails && (
              <div className="w-100" style={{ marginTop: 24 }}>
                <TypeFezNeueMedium
                  className="font16"
                  style={{ marginBottom: 12 }}
                >
                  Transaction Details
                </TypeFezNeueMedium>
                <TransactionDetailsBox
                  totalAmount={Number(amount)}
                  fee={fee}
                  defaultFee={default_fee}
                />
              </div>
            )}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 16,
                width: "100%",
                marginBottom: 24,
                marginTop: 24,
              }}
            >
              <GothamMedium style={{ fontSize: 16 }}>
                Supporting Document
              </GothamMedium>
              <div
                style={{
                  padding: 8,
                  display: "flex",
                  borderRadius: 8,
                  border: "1px solid #E6E6E8",
                  justifyContent: "space-between",
                  cursor: "pointer",
                }}
                onClick={() => {
                  if (!newAttachmentData?.length) return;
                  show({ files: newAttachmentData });
                }}
              >
                <div style={{ display: "flex", gap: 8 }} className="hover">
                  <div
                    style={{
                      height: 44,
                      width: 44,
                      borderRadius: 6,
                      backgroundColor: "#E1EEFF",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img src={jpegIcon} />
                  </div>
                  <div>
                    <GothamMedium>
                      {newAttachmentData?.length} Supporting Documents
                    </GothamMedium>
                    <GothamRegular style={{ color: "#909098" }}>
                      See all supporting documents
                    </GothamRegular>
                  </div>
                </div>
                <img src={chevronRight} style={{ marginRight: 8 }} />
              </div>
            </div>
            <div className="w-100" style={{}}>
              <GothamMedium style={{ fontSize: 16 }}>Recipient</GothamMedium>
              <RecipientMinimalist data={dataRecipient} />
            </div>
            <InvoicePaymentStatusLine transaction={details} />
          </div>
        }
        wingComponent={
          <div
            style={{ paddingRight: 20, height: "100%", overflowY: "scroll" }}
          >
            <PDFOrImageViewer url={file_url} />
          </div>
        }
      />
      {rejectModal()}
    </>
  );
};
