import React from "react";
import { useRouter } from "next/router";
import { CenteredModalWithHeader } from "./smartKYBUploadDocsModal/components";
import Hello from "../assets/images/hello.svg";
import { GothamRegular } from "../components/Text";
import { NumberedList } from "../pageComponents/kyb/KYBSupportingDocsComponents/components";
import { colors } from "../assets/colors";
import { CustomButton } from "../components/Buttons";
import { getUserRole } from "../contexts/AuthContext";
import { eventsTracker } from "../universalFunctions/events";

const StartOnboardingModal = ({ isOpen }) => {
  const { push } = useRouter();
  const { isEmployee } = getUserRole();

  const setupSteps = ["Start with create PIN", "Invite Users"];

  const onClickStart = () => {
    push("/account/create-pin");
    eventsTracker("to_create_pin");
  };

  if (!isOpen) return null;

  return (
    <CenteredModalWithHeader
      woClose
      title="Welcome to Transfez!"
      isOpen={isOpen}
      titleStyle={{
        textAlign: "center",
        color: colors.primaryBlue,
      }}
      style={{}}
    >
      {isEmployee && (
        <GothamRegular
          style={{
            width: 314,
            marginTop: 8,
            textAlign: "center",
            marginLeft: "auto",
            marginRight: "auto",
            marginBottom: 24,
          }}
        >
          Transfez PIN is used to access and view all your confidential
          information.
        </GothamRegular>
      )}
      <div style={{ display: "flex", justifyContent: "center" }}>
        <img src={Hello} width={480} height={200} />
      </div>

      {!isEmployee && (
        <>
          <GothamRegular
            style={{ fontSize: 14, marginTop: 20, marginBottom: 20 }}
          >
            To start using Transfez, you have to do the following steps:
          </GothamRegular>
          <NumberedList
            array={setupSteps}
            isRegular
            textStyle={{ color: colors.grey33 }}
          />
        </>
      )}
      <CustomButton
        onClick={onClickStart}
        style={{
          width: "100%",
          marginTop: 20,
          paddingTop: 12,
          paddingBottom: 12,
        }}
      >
        <span style={{ marginBottom: 0, lineHeight: "24px", fontSize: 16 }}>
          Start Now
        </span>
      </CustomButton>
    </CenteredModalWithHeader>
  );
};

export default StartOnboardingModal;
