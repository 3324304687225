import React from "react";
import { colors } from "../assets/colors";
import radioActive from "../assets/images/radio-active.svg";
import radio from "../assets/images/radio.svg";
import pencil from "../assets/images/thin-pencil.svg";
import trash from "../assets/images/trash-teal-clean.svg";
import { ConfirmationModal } from "../components/Buttons";
import LoadingSpinner from "../components/Loading";
import { useModalHook } from "../components/Modals";
import { GothamMedium, GothamRegular } from "../components/Text";

export const BankCard = ({
  isActive,
  item,
  style,
  onClick,
  onDelete,
  onEdit,
  isUploadReceipt,
  onChangeSource,
  loading,
  woDelete,
}) => {
  const { top, middle, bottom, id } = item;

  const defaultOnClick = (e, customFunction) => {
    if (!e) return;
    e.preventDefault();
    e.stopPropagation();
    customFunction && customFunction(id);
  };

  const { isOpen: isButtonModalOpen, toggle: toggleButtonModal } =
    useModalHook();

  return (
    <div
      style={{
        boxShadow: "0px 8px 20px rgba(88, 88, 88, 0.1)",
        backgroundColor: "white",
        borderRadius: 8,
        ...style,
      }}
    >
      <ConfirmationModal
        toggle={toggleButtonModal}
        modal={isButtonModalOpen}
        onClick={() => {
          onDelete && onDelete(id);
        }}
      />
      <div
        className={`d-flex align-items-center justify-content-between ${
          isUploadReceipt ? "" : "darkhover"
        }`}
        onClick={() => onClick && onClick(item)}
        style={{
          padding: 12,
          borderRadius: 8,
        }}
      >
        {!isUploadReceipt && <img src={isActive ? radioActive : radio} />}

        {loading ? (
          <LoadingSpinner style={{ width: "100%", height: 100 }} />
        ) : (
          <div style={{ width: isUploadReceipt ? "100%" : 300 }}>
            <div className="d-flex justify-content-between">
              <GothamMedium
                style={{ fontSize: 16, lineHeight: "24px" }}
                className="m-0"
              >
                {top}
              </GothamMedium>
              <div className="d-flex align-items-center">
                {isUploadReceipt ? (
                  <GothamMedium
                    className="m-0 darkhover"
                    style={{ fontSize: 12, color: colors.pink }}
                    onClick={(e) => defaultOnClick(e, onChangeSource)}
                  >
                    Change
                  </GothamMedium>
                ) : (
                  <>
                    <img
                      src={pencil}
                      style={{ height: 20 }}
                      className="darkhover"
                      onClick={(e) => defaultOnClick(e, onEdit)}
                    />
                    {!woDelete && (
                      <img
                        onClick={(e) => defaultOnClick(e, toggleButtonModal)}
                        src={trash}
                        className="darkhover"
                        style={{ marginLeft: 8 }}
                      />
                    )}
                  </>
                )}
              </div>
            </div>
            <GothamRegular
              style={{ marginTop: 4, marginBottom: 4, lineHeight: "22px" }}
            >
              {middle}
            </GothamRegular>
            <GothamRegular
              className="m-0"
              style={{ color: colors.grey72, lineHeight: "22px" }}
            >
              {bottom}
            </GothamRegular>
          </div>
        )}
      </div>
    </div>
  );
};
