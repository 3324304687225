import { LabelPayrollMasking } from "../../../components/Status";
import { formatCurrency } from "../../../components/tools";

export const payrollFormatter = (object) => {
  const { batch, name: leftTop, state } = object || {};
  const { total_amount: total_amountRaw, total_fee } = batch || {};
  const total_amount = Number(total_amountRaw) + Number(total_fee);
  const rightTop = `IDR ${formatCurrency(total_amount)}`;

  const leftStatus = LabelPayrollMasking(state);

  return { ...object, leftStatus, leftTop, rightTop, category: "payroll" };
};
