import React, { useRef } from "react";
import { Table } from "reactstrap";
import { colors } from "../assets/colors";
import checkSquareIcon from "../assets/images/check-square-icon.svg";
import ThreeDotsClicked from "../assets/images/DotsThree-clicked.svg";
import ThreeDots from "../assets/images/DotsThree-unclicked.svg";
import emptySquareIcon from "../assets/images/empty-square-icon.svg";
import refreshIcon from "../assets/images/refresh-table.svg";
import { useModalHook } from "../components/Modals";
import { MappedTableShimmer } from "../components/Shimmer";
import { GothamMedium, GothamRegular } from "../components/Text";
import { TransitionTr } from "../components/Transition";
import { useClickOutside } from "../universalFunctions/useClickOutside";

export const TableRadio = ({ isActive, onClick }) => (
  <img
    src={isActive ? checkSquareIcon : emptySquareIcon}
    className="darkhover"
    onClick={onClick}
  />
);

export const CustomTable = ({ headerData, items, loading, style }) => {
  const TDConstructor = ({ item }) => {
    const td = [];
    headerData.map(({ title }, index) =>
      td.push(<td key={index}>{item[title]}</td>)
    );
    return td;
  };

  const maskingTitle = (title) => {
    if (title === "Bank Id") return "Bank Name";
    return title;
  };

  return (
    <div
      className="table-responsive mt-1"
      style={{ border: "1px solid rgba(194, 194, 194,.5)", ...style }}
    >
      <Table className="table-transfez w-100">
        <>
          <thead>
            <tr>
              {headerData.map((item, index) => {
                const { title, onClick, style, ...props } = item;
                if (onClick) {
                  return (
                    <TdHeader key={index} {...item}>
                      {maskingTitle(title)}
                    </TdHeader>
                  );
                }
                return (
                  <td key={index}>
                    <div style={{ width: 200, ...style }} {...props}>
                      {maskingTitle(title)}
                    </div>
                  </td>
                );
              })}
            </tr>
          </thead>
          <Tbody
            loading={loading}
            totalItem={3}
            totalHeader={headerData.length}
          >
            {items.map((item, index) => (
              <tr key={index}>
                <TDConstructor item={item} />
              </tr>
            ))}
          </Tbody>
        </>
      </Table>
    </div>
  );
};

export const TdHeader = ({
  children,
  onClick,
  textStyle,
  style,
  noSortButton,
  isCustomStyle = false,
  headerStyle,
  ...rest
}) => (
  <td {...rest} style={headerStyle}>
    <div
      className="mx-0 justify-content-between align-items-center d-flex"
      style={isCustomStyle ? style : { minWidth: 200 }}
    >
      <GothamMedium
        style={
          isCustomStyle
            ? { fontSize: 14, ...textStyle }
            : { width: 200 - 40, fontSize: 14 }
        }
        className="my-0"
      >
        {children}
      </GothamMedium>
      {noSortButton ? (
        <></>
      ) : (
        <img
          onClick={onClick}
          src={refreshIcon}
          className="darkhover"
          style={{ borderRadius: 1000 }}
        />
      )}
    </div>
  </td>
);

export const ThHeader = ({
  children,
  onClick,
  textStyle,
  style,
  noSortButton,
  isCustomStyle = false,
  ...rest
}) => (
  <th {...rest}>
    <div
      className="mx-0 justify-content-between align-items-center d-flex"
      style={isCustomStyle ? style : { minWidth: 200 }}
    >
      <GothamMedium
        style={
          isCustomStyle
            ? { fontSize: 14, ...textStyle }
            : { width: 200 - 40, fontSize: 14 }
        }
        className="my-0"
      >
        {children}
      </GothamMedium>
      {noSortButton ? (
        <></>
      ) : (
        <img
          onClick={onClick}
          src={refreshIcon}
          className="darkhover"
          style={{ borderRadius: 1000 }}
        />
      )}
    </div>
  </th>
);

export const Tbody = ({ children, loading, totalHeader, totalItem = 10 }) => {
  if (loading) {
    return (
      <MappedTableShimmer totalHeader={totalHeader} totalItem={totalItem} />
    );
  }
  return <tbody>{children}</tbody>;
};

export const TdText = ({ children, ...props }) => (
  <td style={{ cursor: "default" }} {...props}>
    <GothamRegular style={{ fontSize: 14, marginBottom: 0 }}>
      {children}
    </GothamRegular>
  </td>
);

export const TableRowDots = ({
  children,
  data = [],
  isLast,
  onClick,
  style,
  counter,
  className,
  trOnClick = () => {},
  woTd,
  ...props
}) => {
  const ref = useRef(null);

  const { isOpen, toggle, close } = useModalHook();

  useClickOutside({
    ref,
    clickOutside: close,
  });

  const noData = Boolean(!data.length);

  const trProps = { counter, style, className, ...props };

  if (noData)
    return (
      <TransitionTr {...trProps} onClick={() => trOnClick && trOnClick()}>
        {children}
        {!woTd && (
          <td
            style={{
              position: "relative",
              border: "none",
              cursor: "default",
            }}
          />
        )}
      </TransitionTr>
    );
  return (
    <TransitionTr {...trProps} onClick={() => trOnClick && trOnClick()}>
      {children}
      <td
        style={{
          position: "relative",
          border: "none",
        }}
        ref={ref}
      >
        <img
          src={isOpen ? ThreeDotsClicked : ThreeDots}
          onClick={(e) => {
            if (!e) return;
            e.preventDefault();
            e.stopPropagation();
            toggle();
          }}
          className="pinkhover"
        />
        {isOpen && (
          <div
            style={{
              borderRadius: 8,
              position: "absolute",
              backgroundColor: "white",
              right: 10,
              top: isLast ? -25 : 45,
              border: "1px solid #EAEAEA",
              width: 130,
              zIndex: 2,
            }}
          >
            {data.map((item, index) => {
              const { label, icon, style } = item;
              return (
                <GothamMedium
                  key={index}
                  onClick={(e) => {
                    if (!e) return;
                    e.preventDefault();
                    e.stopPropagation();
                    onClick(item);
                    toggle();
                  }}
                  className="darkhover"
                  style={{
                    color: colors.grey72,
                    padding: 12,
                    fontSize: 14,
                    marginBottom: 0,
                    ...style,
                  }}
                >
                  {icon ? (
                    <img
                      src={icon}
                      style={{ marginRight: 8, marginBottom: 4 }}
                    />
                  ) : null}
                  {label}
                </GothamMedium>
              );
            })}
          </div>
        )}
      </td>
    </TransitionTr>
  );
};
