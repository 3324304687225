import { customDateFormatter, formatCurrency } from "../../../components/tools";

export const topupFormatter = (object) => {
  const {
    category: type,
    team,
    created_at,
    amount,
    currency,
    reference_number,
    status,
    user,
  } = object;
  const leftTag = team?.name;
  const { name: userName, email: userEmail } = user || {};

  const userInfoDecider = () => {
    const userInfo = userName || userEmail;
    if (userInfo) return `• ${userInfo}`;
    return "";
  };
  const leftTop = "Top Up";
  const leftBottom = `• ${reference_number} • ${customDateFormatter(
    created_at
  )} ${userInfoDecider()}`;

  const leftStatus = status;
  const rightTop = `+${currency} ${formatCurrency(amount || 0)}`;

  return {
    ...object,
    leftTag,
    leftTop,
    leftBottom,
    leftStatus,
    rightTop,
    type,
  };
};
