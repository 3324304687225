import {
  createTheme,
  TextareaAutosize,
  TextField,
  ThemeProvider,
} from "@material-ui/core";
import { noCase, sentenceCase, titleCase, upperCase } from "change-case";
import { getMonth, getYear } from "date-fns";
import { get, isEmpty, range } from "lodash";
import { useRouter } from "next/router";
import * as Papa from "papaparse";
import React, {
  forwardRef,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Search } from "react-bootstrap-icons";
import DatePicker from "react-datepicker";
import { Controller, useForm } from "react-hook-form";
import { FaSpinner } from "react-icons/fa";
import { Document, Page, pdfjs } from "react-pdf";
import Select, { components } from "react-select";
import CreatableSelect from "react-select/creatable";
import { Col, Modal, Row } from "reactstrap";
import styled from "styled-components";
import { colors } from "../assets/colors";
import caretDown from "../assets/images/caret-down-pink.svg";
import checkSquareIcon from "../assets/images/check-square-icon.svg";
import dateIcon from "../assets/images/date.svg";
import downIcon from "../assets/images/down-icon.svg";
import emptySquareIcon from "../assets/images/empty-square-icon.svg";
import eyeIcon from "../assets/images/eye-icon.svg";
import leftArrow from "../assets/images/left-arrow-pagination.svg";
import mailBlueIcon from "../assets/images/mail-blue-rounded.svg";
import noOptionImg from "../assets/images/no-option.svg";
import rightArrow from "../assets/images/right-arrow-pagination.svg";
import searchIcon from "../assets/images/search-icon.svg";
import trashIcon from "../assets/images/trash-upload.svg";
import uploadBlueIcon from "../assets/images/upload-icon-b19.svg";
import xIconPink from "../assets/images/x-icon-pink.svg";
import xIcon from "../assets/images/x-upload.svg";
import { phoneSimpleLabelSplitter } from "../assets/phoneCode";
import LoadingSpinner from "../components/Loading";
import { applyRegexOnlyNumbers, dataSeparator } from "../components/tools";
import { ToasterContext } from "../contexts/ToasterContext";
import useSubscriptionUsage from "../tools/hooks/useSubscriptionUsage";
import { eventsTracker } from "../universalFunctions/events";
import { NameAvatar } from "./Avatar";
import { DialogButton } from "./Buttons";
import { FileInputCustomizeReceipt } from "./FileInputs";
import { MultipleInputTable } from "./MultipleInputTable";
import {
  GothamBold,
  GothamMedium,
  GothamRegular,
  OldGothamRegular,
  TextInlineMedium,
  TextInlineRegular,
} from "./Text";
import {
  formatCurrency,
  formatCurrencyNoDecimal,
  toBase64,
  useDebounce,
  windowDimension,
} from "./tools";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const ErrorInputText = ({ children }) => (
  <h5
    style={{
      marginTop: 4,
      fontFamily: "GothamBook",
      color: colors.errorRed,
      fontSize: 14,
      lineHeight: "20px",
    }}
  >
    {children}
  </h5>
);

const useMissingValueInTextInput = (currValue) => {
  const [missingValue, setMissingValue] = useState(false);
  const [testing, setTesting] = useState("");
  const [text, setText] = useState("");
  useEffect(() => {
    setText((p) => {
      setTesting((prev) => {
        if (prev && !p) {
          setMissingValue(true);
          return p;
        }
        return p;
      });
      setMissingValue(false);
      return currValue;
    });
  }, [currValue]);
  return missingValue;
};

export const labelColorDecider = ({ isActive, isError }) => {
  if (isError) return { border: colors.errorRed, text: colors.errorRed };
  if (isActive) return { border: colors.pink, text: colors.pink };
  return { text: colors.grey72, border: colors.greyC2 };
};

const CustomTextFieldPrefix = ({ prefix }) => {
  if (!prefix) return null;
  return (
    <div className="d-flex align-items-center mr-1">
      <GothamMedium style={{ color: colors.greyC2, marginBottom: 0 }}>
        {prefix}
      </GothamMedium>
    </div>
  );
};
const CustomTextFieldHelp = ({ help }) => {
  if (!help) return null;
  return (
    <h5
      style={{
        color: colors.grey72,
        fontSize: 12,
        lineHeight: "20px",
        marginBottom: 0,
      }}
    >
      {help}
    </h5>
  );
};
export const CustomTextFieldError = ({
  show,
  message,
  errorStyle,
  chillError,
}) => {
  if (!show) return null;
  return (
    <h5
      style={{
        fontFamily: "GothamBook",
        color: chillError ? "black" : colors.errorRed,
        fontSize: 12,
        lineHeight: "20px",
        marginBottom: 0,
        ...errorStyle,
      }}
    >
      {message}
    </h5>
  );
};

// CustomTextFieldRef IS THE SAME AS CustomTextField (but with forwardRef)
export const CustomTextFieldRef = forwardRef(
  (
    {
      name = "",
      label = "",
      useFormObj: { register, watch, errors },
      error,
      help,
      className = "",
      disabled: disabledRaw, // same
      isDisabled, // same (used for datepicker)
      containerStyle,
      required = true,
      shrink = false,
      emptyField = "",
      fieldInfo,
      style,
      labelStyle,
      defaultValue,
      fromVA = false,
      prefix = "",
      errorStyle,
      ...props
    },
    ref
  ) => {
    const [isFocused, setIsFocused] = useState(false);

    const disabled = disabledRaw || isDisabled;

    const currValue = watch(name);

    const hasValue = Boolean(currValue);

    const missingValue = useMissingValueInTextInput(currValue);

    const isActive = isFocused || hasValue || prefix;

    const errorMessage = errors[name]?.message;

    const isError = Boolean(error || errorMessage);

    const shrinkObj = shrink ? { shrink } : {};

    const getLabel = label || titleCase(name);

    const { border: borderColor } = labelColorDecider({
      isActive: isFocused,
      isError,
    });

    const { text: labelColor } = labelColorDecider({
      isActive: isFocused || hasValue,
      isError,
    });
    const errorString = () => {
      if (typeof error == "string") return error;
      if (emptyField) return emptyField;
      if (errorMessage) return sentenceCase(errorMessage);
      return getLabel + " cannot be empty";
    };
    return (
      <div
        style={{ marginBottom: 20, ...containerStyle }}
        className={className}
      >
        <div>
          <TextField
            ref={ref}
            name={name}
            disabled={disabled}
            label={getLabel}
            defaultValue={defaultValue}
            inputRef={register({ required })}
            variant="standard"
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            InputProps={{
              disableUnderline: true,
              startAdornment: prefix && (
                <CustomTextFieldPrefix prefix={prefix} />
              ),
              style: {
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
                marginLeft: 8,
                marginRight: 8,
                color: "black",
                fontFamily: "GothamBook",
                fontSize: 14,
                marginTop: 12,
              },
            }}
            style={{
              height: 44,
              backgroundColor: disabled ? colors.greyea : "transparent",
              borderRadius: 8,
              borderWidth: ".5px",
              width: "100%",
              border: `1px solid ${borderColor}`,
              ...style,
            }}
            InputLabelProps={{
              ...shrinkObj,
              style: {
                position: "absolute",
                fontFamily: "GothamBook",
                fontSize: isActive ? 12 : 14,
                left: 8,
                top: missingValue || isActive ? 2 : -10,
                color: labelColor,
                ...labelStyle,
              },
            }}
            {...props}
          />
        </div>
        <CustomTextFieldHelp help={help} />
        <CustomTextFieldError
          show={isError}
          message={errorString()}
          errorStyle={errorStyle}
        />
        <CustomTextFieldError
          show={fieldInfo}
          message={String(fieldInfo)}
          errorStyle={{ color: colors.grey72 }}
        />
      </div>
    );
  }
);
// CustomTextFieldRef IS THE SAME AS CustomTextField (but with forwardRef)

// OVERRIDE MUI

// this small component costs me 5 hrs+.
// we can OVERRIDE MUI's classNames by using theme.
// how to implement:
// 1. Go to your browser and pick the element you want to modify
// 2. See the absurd classes there? Pick the first letters and pray that it is defined by MUI.
// 3. auto complete is your best friend. Check if you can override it.
// 4. Trial and error which key is the correct one. In my case it is `shrink` and `filled`
// 5. enjoy
const theme = createTheme({
  overrides: {
    MuiInputLabel: {
      shrink: {
        marginTop: 14,
      },
    },
    MuiFormLabel: {
      filled: {
        marginTop: 14,
      },
    },
  },
});
// OVERRIDE MUI

export const CustomTextField = ({
  name = "",
  label = "",
  useFormObj: { register, watch, errors },
  error,
  help,
  className = "",
  disabled: disabledRaw, // same
  isDisabled, // same (used for datepicker)
  containerStyle,
  required = true,
  shrink = false,
  emptyField = "",
  fieldInfo = "",
  style,
  labelStyle,
  defaultValue,
  fromVA = false,
  prefix = "",
  errorStyle,
  inputProps,
  chillError = false, // its error but not red (weird? ikr),
  autoComplete,
  maxLength = null,
  ...props
}) => {
  const [isFocused, setIsFocused] = useState(false);

  const disabled = disabledRaw || isDisabled;

  const currValue = watch(name);

  const hasValue = Boolean(currValue);

  const isActive = isFocused || hasValue || prefix;

  const errorMessage = errors[name]?.message;

  const isError = Boolean(error || errorMessage);

  const shrinkObj = shrink ? { shrink } : hasValue ? { shrink: true } : {};

  const shrinkStyle = shrink ? { fontSize: 12 } : {};

  const getLabel = label || titleCase(name);

  const { border: borderColor, text: labelColor } = labelColorDecider({
    isActive: isFocused,
    isError: chillError ? false : isError,
  });

  const errorString = () => {
    if (typeof error == "string" && error) return error;
    if (emptyField) return emptyField;
    if (errorMessage) return sentenceCase(errorMessage);
    return getLabel + " cannot be empty";
  };

  return (
    <div style={{ marginBottom: 20, ...containerStyle }} className={className}>
      <div>
        <ThemeProvider theme={theme}>
          <TextField
            name={name}
            disabled={disabled}
            label={required ? `${getLabel}*` : getLabel}
            defaultValue={defaultValue}
            inputRef={register({ required })}
            variant="standard"
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            autoComplete={autoComplete}
            inputProps={{ maxLength }}
            InputProps={{
              disableUnderline: true,
              startAdornment: prefix && (
                <CustomTextFieldPrefix prefix={prefix} />
              ),
              style: {
                marginLeft: 8,
                marginRight: 8,
                color: "black",
                fontFamily: "GothamBook",
                fontSize: 14,
                marginTop: 14,
              },
              ...inputProps,
            }}
            style={{
              height: 44,
              backgroundColor: disabled ? colors.greyea : "transparent",
              borderRadius: 8,
              borderWidth: ".5px",
              width: "100%",
              border: `1px solid ${borderColor}`,
              ...style,
            }}
            InputLabelProps={{
              ...shrinkObj,
              style: {
                position: "absolute",
                fontFamily: "GothamBook",
                fontSize: isActive ? 12 : 14,
                left: 8,
                top: -10,
                color: labelColor,
                ...shrinkStyle,
                ...labelStyle,
              },
            }}
            {...props}
          />
        </ThemeProvider>
      </div>
      <CustomTextFieldHelp help={help} />
      <CustomTextFieldError
        show={isError}
        message={errorString()}
        errorStyle={errorStyle}
        chillError={chillError}
      />
      <CustomTextFieldError
        show={fieldInfo}
        message={String(fieldInfo)}
        errorStyle={{ color: colors.grey72 }}
      />
    </div>
  );
};

export const CustomLimitTextField = ({
  useFormObj,
  name,
  limit,
  style,
  error: errorprops,
  required = true,
  ...props
}) => {
  const [charCount, setCharCount] = useState(0);
  const [error, setError] = useState(false);
  const [text, setText] = useState(null);
  const { watch, setValue } = useFormObj || {};
  const value = watch(name);
  const pattern = /^[a-zA-Z0-9 ]*$/;

  useEffect(() => {
    if (!pattern.test(value)) return setValue(name, text);
    setText(value);
  }, [value]);

  const handleCharCount = (e) => {
    const charCount = e.target.value.length;
    setCharCount(charCount);
    if (charCount > limit) {
      setError(
        `${titleCase(name)} must be shorter than ${limit + 1} characters`
      );
    } else {
      setError(false);
    }
  };

  const handleTextChange = (e) => {
    setValue(name, e.target.value.slice(0, limit));
  };

  return (
    <div style={{ position: "relative", ...style }}>
      <CustomTextField
        onChange={(e) => {
          handleCharCount(e);
          handleTextChange(e);
        }}
        useFormObj={useFormObj}
        name={name}
        error={errorprops || error}
        required={required}
        {...props}
      />
      <div
        style={{
          textAlign: "right",
          position: "absolute",
          top: 12,
          right: 8,
          pointerEvents: "none",
        }}
      >
        <GothamRegular style={{ color: colors.grey72, fontSize: 10 }}>
          {charCount > limit ? limit : charCount}/{limit}
        </GothamRegular>
      </div>
    </div>
  );
};

export const CustomNumField = (props) => {
  const [numValue, setNumValue] = useState(null);
  const { useFormObj, name } = props || {};
  const { watch, setValue } = useFormObj || {};
  const value = watch(name);
  useEffect(() => {
    if (isNaN(Number(value))) return setValue(name, numValue);
    setNumValue(value);
  }, [value]);

  return <CustomTextField {...props} />;
};

export const NakedTextField = ({
  containerStyle,
  className = "",
  prefix,
  inputProps,
  disabled,
  error,
  style,
  labelStyle,
  value = "",
  label = "",
  ...props
}) => {
  const isError = Boolean(error);

  const [isFocused, setIsFocused] = useState(false);

  const isActive = isFocused || prefix;

  const { border: borderColor, text: labelColor } = labelColorDecider({
    isActive: isFocused,
    isError,
  });

  return (
    <div style={{ marginBottom: 20, ...containerStyle }} className={className}>
      <TextField
        value={value}
        label={label}
        disabled={disabled}
        variant="standard"
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        InputProps={{
          disableUnderline: true,
          startAdornment: prefix && <CustomTextFieldPrefix prefix={prefix} />,
          style: {
            marginLeft: 8,
            marginRight: 8,
            color: "black",
            fontFamily: "GothamBook",
            fontSize: 14,
            marginTop: 14,
          },
          ...inputProps,
        }}
        style={{
          height: 44,
          backgroundColor: disabled ? colors.greyea : "transparent",
          borderRadius: 8,
          borderWidth: ".5px",
          width: "100%",
          border: `1px solid ${borderColor}`,
          ...style,
        }}
        InputLabelProps={{
          style: {
            position: "absolute",
            fontFamily: "GothamBook",
            fontSize: isActive ? 12 : 14,
            left: 8,
            top: isActive || value ? 4 : -10,
            color: labelColor,
            ...labelStyle,
          },
        }}
        {...props}
      />
    </div>
  );
};

export const CurrencyField = (props) => {
  const { useFormObj, name } = props;
  const { setValue, watch } = useFormObj;

  const currentValue = watch(name);

  useEffect(() => {
    setValue(name, formatCurrency(currentValue || 0));
  }, [currentValue]);

  return <CustomTextField {...props} />;
};

export const PasswordField = (props) => {
  const { className, ...rest } = props;
  const [hide, setHide] = useState(true);
  const toggle = () => {
    setHide((p) => !p);
  };
  return (
    <Col className={` w-100 d-flex p-0 ${className}`}>
      <CustomTextField
        className="w-100"
        {...rest}
        type={hide ? "password" : ""}
        autoComplete="new-password"
      />
      <img
        src={eyeIcon}
        onClick={toggle}
        className="darkhover"
        style={{
          position: "absolute",
          right: 20,
          top: 11,
        }}
      />
    </Col>
  );
};

export const CustomSelect = ({
  options,
  containerStyle,
  className = "",
  inputStyle,
  iconLeft,
  indicatorStyle,
  ...props
}) => (
  <Select
    {...props}
    className={className}
    instanceId="custom-react-select"
    styles={{
      control: (style, { isFocused }) => ({
        ...style,
        borderRadius: 8,
        height: 48,
        fontSize: 16,
        fontFamily: "GothamBook",
        borderColor: isFocused ? colors.pink : colors.greyC2,
        boxShadow: "0 0 0 0 black",
        ...containerStyle,
      }),
      input: (style) => ({
        ...style,
        fontFamily: "GothamBook",
        ...inputStyle,
        ...iconLeft,
      }),
      option: (style, { isSelected, isFocused }) => ({
        ...style,
        fontSize: 16,
        fontFamily: "GothamBook",
        backgroundColor: isSelected
          ? colors.pink
          : isFocused
          ? colors.lightPink
          : "transparent",
      }),
      menu: (style) => ({
        ...style,
        border: `1px solid ${colors.pink}`,
        borderRadius: 8,
      }),
      indicatorSeparator: () => ({}),
      dropdownIndicator: (style) => ({
        ...style,
        ...indicatorStyle,
      }),
    }}
    options={options}
    name="date"
  />
);

export const CustomSelectForm = ({
  options,
  name,
  placeholder,
  useFormObj,
  containerStyle,
  ...props
}) => {
  const { register, watch, setValue } = useFormObj;
  const [item, setItem] = useState({});
  useEffect(() => {
    register({ name });
  }, []);

  useEffect(() => {
    if (!isEmpty(item)) {
      setValue(name, item);
    }
  }, [item]);

  const watchValue = watch(name);
  return (
    <CustomSelect
      options={options}
      containerStyle={{ height: 56, ...containerStyle }}
      placeholder={placeholder}
      onChange={(e) => setItem(e)}
      value={watchValue}
      {...props}
    />
  );
};

export const CustomSelectFormRegister = ({
  options,
  name,
  placeholder,
  useFormObj,
  containerStyle,
  ...props
}) => {
  const { register, watch, setValue } = useFormObj;
  const [item, setItem] = useState({});
  useEffect(() => {
    register({ name });
  }, []);

  useEffect(() => {
    if (!isEmpty(item)) {
      setValue(name, item);
    }
  }, [item]);

  const watchValue = watch(name);
  return (
    <CustomSelect
      options={options}
      containerStyle={{ ...containerStyle }}
      placeholder={placeholder}
      onChange={(e) => setItem(e)}
      value={watchValue}
      {...props}
    />
  );
};

const MinimalistTextFieldPrefix = ({ prefix }) => {
  if (!prefix) return null;
  return (
    <div className="d-flex align-items-center mr-1" style={{ width: 35 }}>
      <GothamRegular style={{ color: colors.grey33, marginBottom: 0 }}>
        {prefix}
      </GothamRegular>
    </div>
  );
};

export const MinimalistTextField = ({
  label = "",
  name = "",
  value = "",
  style,
  prefix = "",
  required = false,
  onSubmit = () => {},
  error,
  errorMsg,
  setValue,
  isNumber = false,
  inputPropsStyle,
  ...props
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const isEmptyValue = !Boolean(value);

  const LabelDecider = required ? (
    <>
      {label}
      <span style={{ color: "red" }}>*</span>
    </>
  ) : (
    label
  );

  const topPositionDecider = () => {
    if (!isFocused && isEmptyValue && Boolean(prefix)) return -3;
    if (!isFocused && isEmptyValue) return -10;
    return -3;
  };
  const topPosition = topPositionDecider();

  return (
    <div>
      <TextField
        name={name}
        label={LabelDecider}
        value={value}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        onChange={(event) => {
          const { value } = event?.target || {};
          if (isNumber) {
            const numberValue = applyRegexOnlyNumbers(value || "");
            return setValue(numberValue);
          } else {
            setValue(value);
          }
        }}
        onKeyPress={(event) => {
          if (event.key == "Enter") {
            onSubmit();
          }
        }}
        InputProps={{
          "aria-errormessage": <MinimalistTextField prefix={"hehe"} />,
          disableUnderline: true,
          startAdornment: prefix && (
            <MinimalistTextFieldPrefix prefix={prefix} />
          ),
          style: {
            fontSize: 14,
            fontFamily: "GothamBook",
            lineHeight: "20px",
            marginTop: 4,
            paddingTop: 4,
            paddingBottom: 0,
            borderBottom: "1px solid #E6E6E8",
            ...inputPropsStyle,
          },
        }}
        InputLabelProps={{
          style: {
            fontFamily: "GothamBook",
            fontSize: 14,
            top: topPosition,
            color: "#BBBBC0",
          },
        }}
        style={{ width: "100%", ...style }}
        {...props}
      />
      <CustomTextFieldError
        errorStyle={{ position: "absolute", fontSize: 10 }}
        message={errorMsg}
        show={error}
      />
    </div>
  );
};

const CustomMultiSelectOptions = ({ data, innerProps, selectProps }) => {
  const { value, label } = data || {};
  const { options } = selectProps;
  const isFirst = data === options[0];
  return (
    <div {...innerProps}>
      {isFirst && (
        <GothamMedium className="mx-3 font12" style={{ color: colors.blue10 }}>
          Select bank
        </GothamMedium>
      )}
      <div
        className="option mx-3"
        style={{
          padding: "16px 8px",
          borderBottom: "1px solid #E6E6E8",
        }}
      >
        <GothamRegular style={{ fontSize: 14, color: colors.grey33 }}>
          {label}
        </GothamRegular>
      </div>
    </div>
  );
};

const DropdownIndicator = (props) => {
  const { noArrowDropDown } = props;
  return (
    <div style={{ position: "absolute", right: 15 }}>
      {!noArrowDropDown && <img src={caretDown} style={{ width: 12 }} />}
    </div>
  );
};

const CustomValueContainer = ({ children, ...props }) => {
  const { required } = props || {};
  return (
    <ValueContainer {...props}>
      <Placeholder {...props} isFocused={props.isFocused}>
        {props.selectProps.placeholder}
        {required && <span style={{ color: colors.redE7 }}>*</span>}
      </Placeholder>
      {React.Children.map(children, (child) =>
        child && child.type !== Placeholder ? child : null
      )}
    </ValueContainer>
  );
};

const NoOptionsMessageMinimalist = (props) => {
  const { msg } = props;
  return (
    <components.NoOptionsMessage {...props}>
      <div className="text-center">
        <img src={noOptionImg} />
        <GothamRegular style={{ fontSize: 12, color: colors.grey90 }}>
          {msg}
        </GothamRegular>
      </div>
    </components.NoOptionsMessage>
  );
};

const { ValueContainer, Placeholder } = components;

export const MinimalistSelect = ({
  value,
  options,
  onChange,
  defaultValue,
  noArrowDropDown = false,
  required,
  placeholder = "Bank",
  noOptionsMsg = "Your bank not found",
  error,
  errorMsg,
  ...props
}) => {
  const getValue = options.filter((item) => item.value == value)[0] || "";

  const componentsDecider = () => {
    return {
      Option: CustomMultiSelectOptions,
      DropdownIndicator: (props) => (
        <DropdownIndicator {...props} noArrowDropDown={noArrowDropDown} />
      ),
      ValueContainer: (props) => (
        <CustomValueContainer {...props} required={required} />
      ),
      NoOptionsMessage: (props) => (
        <NoOptionsMessageMinimalist {...props} msg={noOptionsMsg} />
      ),
    };
  };

  const components = componentsDecider();

  return (
    <div>
      <Select
        {...props}
        options={options}
        onChange={onChange}
        value={getValue}
        components={components}
        defaultValue={defaultValue}
        placeholder={placeholder}
        noOptionsMessage={() => `${noOptionsMsg}`}
        menuPortalTarget={document.body}
        styles={{
          container: (style) => ({
            ...style,
          }),
          input: (style) => ({
            ...style,
            fontFamily: "GothamBook",
            borderBottom: "1px solid #e6e6e8",
            padding: "11px 0px 2px 0px",
            fontSize: 14,
            margin: 0,
            cursor: "pointer",
          }),
          valueContainer: (provided, state) => ({
            ...provided,
            overflow: "visible",
            padding: 0,
          }),
          control: (style) => ({
            ...style,
            border: "none",
            fontFamily: "GothamBook",
            fontSize: 14,
            boxShadow: "none",
            position: "relative",
            "&:hover": {
              border: `none`,
            },
          }),
          option: (style, { isSelected, isFocused }) => ({
            ...style,
            fontSize: 14,
            fontFamily: "GothamBook",
            margin: 14,
            backgroundColor: isSelected
              ? colors.pink
              : isFocused
              ? colors.lightPink
              : "transparent",
            boxShadow: "none",
          }),
          singleValue: (style) => ({
            ...style,
            margin: "0px 0px -5.5px 0px",
            paddingRight: 20,
          }),
          menu: (style) => ({
            ...style,
            boxShadow: "0px 8px 20px rgba(88, 88, 88, 0.1)",
            border: "none",
            width: 266,
            zIndex: 999,
            marginLeft: -12,
          }),
          menuPortal: (style) => ({
            ...style,
            zIndex: 999999,
          }),
          indicatorSeparator: () => ({}),
          dropdownIndicator: (style) => ({
            ...style,
            display: "none",
          }),
          placeholder: (provided, state) => ({
            ...provided,
            position: "absolute",
            zIndex: 20,
            fontSize: 14,
            fontFamily: "GothamBook",
            color: "#BBBBC0",
            top: state.hasValue || state.selectProps.inputValue ? -5 : "27%",
            left: -2,
            transition: "top 0.1s, font-size 0.1s",
            fontSize: (state.hasValue || state.selectProps.inputValue) && 10,
          }),
        }}
      />
      <CustomTextFieldError
        errorStyle={{ position: "absolute", fontSize: 10 }}
        message={errorMsg}
        show={error}
      />
    </div>
  );
};

export const CustomTextArea = ({
  name = "",
  label = "",
  style,
  placeholder = "Placeholder",
  useFormObj: { setValue, register, watch, errors },
  className = "",
  defaultValue,
  disabled,
  maxLength = 0,
}) => {
  const value = watch(name) || "";

  useEffect(() => register({ name }), []);

  useEffect(() => {
    if (!defaultValue) return;
    setValue(name, defaultValue);
  }, [defaultValue]);

  const onChange = (val) => {
    const text = val?.target?.value;
    if (maxLength) {
      const canType = maxLength >= text.length;
      if (canType) return setValue(name, text);
      return;
    }
    setValue(name, text);
  };

  return (
    <div style={{ position: "relative" }}>
      {maxLength ? (
        <div style={{ position: "absolute", right: 8, bottom: 8 }}>
          <GothamRegular className="font10" style={{ color: colors.greyBB }}>
            {value.length} / {maxLength}
          </GothamRegular>
        </div>
      ) : null}
      <TextareaAutosize
        disabled={disabled}
        defaultValue={defaultValue}
        minRows={3}
        name={name}
        label={label}
        className={className}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        style={{
          resize: "none",
          width: "100%",
          color: colors.grey72,
          border: "1px solid #C2C2C2",
          borderRadius: 8,
          padding: "13px 8px",
          outline: "none",
          ...style,
        }}
      />
    </div>
  );
};

export const BankSelection = ({
  loading,
  useFormObj: { register, watch, setValue },
  options,
  deleteBank,
  name,
}) => {
  const [userBankId, setUserBankId] = useState();
  const [loadingDelete, setLoadingDelete] = useState(false);
  const watchUserBank = watch(name);
  const handlePickBank = (id) => {
    setUserBankId(id);
  };
  const handleDeleteBank = async (id) => {
    try {
      setLoadingDelete(true);
      await deleteBank(id);
      const isSelected = watchUserBank == id;
      if (isSelected) {
        setValue(name, undefined);
      }
    } catch (err) {
      console.log("err:", err);
    } finally {
      setLoadingDelete(false);
    }
  };
  useEffect(() => {
    register({ name });
  }, []);

  useEffect(() => {
    if (userBankId) {
      setValue(name, userBankId);
    }
  }, [userBankId]);
  return (
    <>
      {loading || loadingDelete ? (
        <LoadingSpinner style={{ height: 100 }} />
      ) : (
        options.map(({ middle, bottom, top, id }, index) => {
          const isActive = id === watchUserBank;
          return (
            <div
              onClick={() => handlePickBank(id)}
              key={index}
              style={{
                borderRadius: 8,
                padding: 12,
                border: "1px solid rgb(194,194,194)",
              }}
              className="d-flex mx-0 mb-3 darkhover"
            >
              <img
                src={isActive ? checkSquareIcon : emptySquareIcon}
                className="mr-2"
                style={{ width: 24 }}
              />
              <div
                className="d-flex align-items-center"
                style={{ width: "85%" }}
              >
                <div style={{ width: "100%" }}>
                  <Row
                    className="mx-0 align-items-center justify-content-between"
                    style={{ width: "100%", marginBottom: 4 }}
                  >
                    <GothamMedium
                      style={{ marginBottom: 0, lineHeight: "22px" }}
                    >
                      {top}
                    </GothamMedium>
                    <DialogButton
                      className="fake-button"
                      woClassName
                      style={{
                        fontSize: 20,
                        height: 24,
                        width: 24,
                        marginRight: -8,
                      }}
                      onClick={(e) => {
                        e && e.preventDefault();
                        e && e.stopPropagation();
                        handleDeleteBank(id);
                      }}
                      text="Are you sure you want to delete this bank account?"
                    >
                      <img
                        src={trashIcon}
                        className="darkhover"
                        style={{ height: 24, width: 24 }}
                      />
                    </DialogButton>
                  </Row>
                  <GothamRegular
                    style={{
                      fontSize: 12,
                      lineHeight: "18px",
                      marginBottom: 4,
                      color: colors.grey72,
                    }}
                  >
                    {middle}
                  </GothamRegular>
                  <GothamRegular
                    style={{
                      fontSize: 12,
                      lineHeight: "18px",
                      marginBottom: 0,
                      color: colors.grey72,
                    }}
                  >
                    {bottom}
                  </GothamRegular>
                </div>
              </div>
            </div>
          );
        })
      )}
    </>
  );
};

export const CalculatorSelection = ({
  useFormObj,
  options,
  selectionName,
  fieldName,
  defaultValue,
  noDecimal,
  singleTransaction,
  onChangeField = () => {},
  isLoading,
  ...props
}) => {
  const [showDropdown, setShowDropdown] = useState(false);

  const toggle = () => {
    setShowDropdown((p) => !p);
  };

  const { register, setValue, watch } = useFormObj;
  const getSelectionValue = watch(selectionName) || defaultValue;
  const watchFieldName = watch(fieldName) || "";
  const debouncedWatch = useDebounce(watchFieldName, 750);

  const safeValue = (string) => get(getSelectionValue, string, "");
  const getCurr = safeValue("currency");
  const getIso = noCase(safeValue("country_iso"));
  const getDefaultFieldValue = get(defaultValue, "amount", "0");

  useEffect(() => {
    if (!watchFieldName) {
      setValue(fieldName, "0");
      return;
    }
    const lastString = watchFieldName.slice(-1);
    const lastStringDot = lastString == ".";

    if (!noDecimal && lastStringDot) {
      setTimeout(() => {
        const value = formatCurrency(watchFieldName);
        setValue(fieldName, value);
      }, 750);
      return;
    }

    const value = noDecimal
      ? formatCurrencyNoDecimal(watchFieldName)
      : formatCurrency(watchFieldName);
    setValue(fieldName, value);
  }, [debouncedWatch]);

  useEffect(() => {
    register({ name: selectionName });
    setValue(fieldName, formatCurrency(getDefaultFieldValue));
  }, []);

  const onChange = (val) => {
    setValue(selectionName, val);
    toggle();
  };

  return (
    <Col className="px-0">
      <CustomTextField
        shrink
        name={fieldName}
        useFormObj={useFormObj}
        {...props}
      />
      <div
        style={{
          position: "absolute",
          top: 0,
          right: 0,
          width: 150,
        }}
        className="d-flex justify-content-end"
      >
        <div
          style={{
            width: "100%",
            borderTopRightRadius: 12,
            borderBottomRightRadius: 12,
            backgroundColor: showDropdown ? colors.teal21 : colors.pink,
            height: 44,
          }}
        >
          <div
            onClick={toggle}
            style={{
              height: "100%",
              width: "100%",
              borderTopRightRadius: 12,
              borderBottomRightRadius: 12,
            }}
            className="d-flex justify-content-center align-items-center btn-dark-blue"
          >
            <GothamMedium
              className="m-0 mt-1 p-0"
              style={{ fontSize: 16, color: "white" }}
            >
              {getCurr}
            </GothamMedium>
            <GetFlag iso={getIso} />
            <img src={downIcon} />
          </div>
          {showDropdown && (
            <div
              onBlur={toggle}
              style={{
                position: "absolute",
                width: "250%",
                bottom: -66,
                right: 0,
              }}
            >
              <Selection
                onChange={(val) => {
                  onChangeField(val);
                  onChange(val);
                }}
                isLoading={isLoading}
                containerStyle={{ paddingLeft: 30 }}
                woTitleCase
                isCalculator
                value={getSelectionValue}
                options={options}
                name={selectionName}
                label="Search currency / country"
                menuIsOpen={true}
                icon={<img src={searchIcon} className="mr-2" />}
              />
            </div>
          )}
        </div>
      </div>
    </Col>
  );
};

const BatchSelection = ({
  data,
  innerProps,
  style,
  isSelected,
  notSelection,
}) => {
  const { label, currency, country_iso } = data || {};
  const fontColor = isSelected ? "white" : "black";

  return (
    <div
      className={`d-flex justify-content-between align-items-center ${
        notSelection ? "" : "pinkhover"
      }`}
      style={{
        position: "absolute",
        bottom: 0,
        left: -2,
        width: "100%",
        height: "100%",
        backgroundColor: isSelected ? colors.pink : "",
        ...style,
      }}
      {...innerProps}
    >
      <div style={{ marginLeft: 10, marginBottom: notSelection ? -8 : 0 }}>
        <GothamRegular style={{ marginBottom: 0, color: fontColor }}>
          {label}
        </GothamRegular>
      </div>
      <div className="d-flex align-items-center">
        <GothamMedium
          className="mt-1"
          style={{ marginBottom: 0, color: fontColor }}
        >
          {currency}
        </GothamMedium>
        <GetFlag iso={noCase(country_iso)} />
      </div>
    </div>
  );
};

const BatchSingleValue = ({ selectProps }) => {
  const { value } = selectProps || {};
  return <BatchSelection data={value} notSelection />;
};

const BatchOptions = ({ data, innerProps, isSelected }) => (
  <BatchSelection
    data={data}
    innerProps={innerProps}
    style={{ position: "relative", height: 44 }}
    isSelected={isSelected}
  />
);

const BusinessTypeOptions = ({ data, innerProps, isSelected }) => {
  const [hover, setHover] = useState(false);
  const { label, iconGrey, iconPink, id } = data || {};
  const isHover = hover === id;
  return (
    <div
      className="pink-hover d-flex"
      style={{
        border: "none",
        padding: "12px 8px",
        gap: 8,
        backgroundColor: isHover && colors.lightPink,
      }}
      onMouseEnter={() => setHover(id)}
      onMouseLeave={() => setHover(false)}
      {...innerProps}
    >
      <img src={isHover ? iconPink : iconGrey} />
      <GothamMedium>{label}</GothamMedium>
    </div>
  );
};

export const Selection = ({
  options,
  label: labelProps = "",
  onChange = () => {},
  name = "",
  woTitleCase,
  isCalculator = false,
  icon = null,
  error,
  isLoading,
  containerStyle,
  inputStyle,
  labelStyle,
  optionStyle,
  menuStyle,
  className = "",
  isPhoneCode,
  isBatchCountry,
  isBusinessType,
  isTopBottom,
  isSearch,
  isPhoneSimple,
  isFlag,
  isCentered,
  placeholder,
  ...props
}) => {
  const height = 44;
  const { value, disabled } = props;
  const ref = useRef();
  const hasValue = Boolean((ref?.current?.state?.selectValue || []).length);

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const label = isMenuOpen ? "" : labelProps;

  useEffect(() => {
    if (value) return;
    ref?.current?.clearValue();
  }, [value]);

  const optionsWLoading = isLoading ? [] : options;

  const IndicatorSeparator = (props) => {
    const { isFocused, hasValue } = props;
    const isActive = isFocused || hasValue;
    return (
      <div
        className="d-flex align-items-center"
        style={{
          position: "absolute",
          left: isFlag ? 0 : 8,
          top: isFlag ? -2 : 0,
          height,
          width: "80%",
        }}
      >
        {icon}
        <OldGothamRegular
          style={{
            color: colors.grey72,
            fontSize: isActive ? 10 : 14,
            ...labelStyle,
          }}
          className={`my-0 ${isActive ? "align-self-start pt-1" : ""}`}
        >
          {woTitleCase ? label : titleCase(label)}
        </OldGothamRegular>
      </div>
    );
  };

  const TopBottomSingleValue = ({ selectProps, innerProps }) => {
    const { value } = selectProps;
    const { label, top } = value || {};
    return (
      <div
        className={`d-flex justify-content-between align-items-center px-2`}
        style={{
          position: "absolute",
          bottom: -7,
          width: "100%",
          height: "100%",
        }}
        {...innerProps}
      >
        <GothamRegular style={{ marginBottom: 0 }}>
          {top || label}
        </GothamRegular>
      </div>
    );
  };

  const PhoneCodeSingleValue = ({ selectProps }) => {
    const { iso, number } = phoneSimpleLabelSplitter(selectProps?.value?.label);

    return (
      <div
        className="d-flex align-items-center"
        style={{ position: "absolute", bottom: 10, left: -2 }}
      >
        {iso && (
          <GetFlag
            iso={noCase(iso)}
            width="16px"
            height="16px"
            style={{ filter: "brightness(92%)" }}
          />
        )}
        {number && (
          <span
            style={{
              color: colors.grey25,
              fontFamily: "GothamMedium",
              fontSize: 14,
              marginBottom: -3,
            }}
          >
            {number}
          </span>
        )}
      </div>
    );
  };

  const PhoneSimpleSingleValue = ({ selectProps }) => {
    const { number } = phoneSimpleLabelSplitter(selectProps?.value?.label);

    return (
      <div
        className="d-flex align-items-center"
        style={{ position: "absolute", bottom: 10, left: 8, maxWidth: "15px" }}
      >
        <span
          style={{
            color: colors.grey25,
            fontFamily: "GothamBook",
            fontSize: 14,
            marginBottom: -3,
            marginLeft: isFlag ? 36 : 0,
          }}
        >
          {number}
        </span>
      </div>
    );
  };

  const PhoneSimpleOptions = (props) => {
    const { innerProps, isSelected, data } = props || {};
    const { number } = phoneSimpleLabelSplitter(data?.label);

    const fontColor = isSelected ? "white" : "black";
    return (
      <div
        className="d-flex justify-content-between align-items-center pinkhover"
        style={{
          width: "100%",
          height: "100%",
          backgroundColor: isSelected ? colors.pink : "",
          paddingTop: 8,
          paddingBottom: 8,
          paddingLeft: 8,
        }}
        {...innerProps}
      >
        <GothamRegular style={{ color: fontColor }}>{number}</GothamRegular>
      </div>
    );
  };
  const componentsDecider = () => {
    if (isPhoneSimple)
      return {
        IndicatorSeparator,
        SingleValue: PhoneSimpleSingleValue,
        Option: PhoneSimpleOptions,
      };

    if (isBatchCountry)
      return {
        IndicatorSeparator,
        SingleValue: BatchSingleValue,
        Option: BatchOptions,
      };

    if (isBusinessType)
      return {
        IndicatorSeparator,
        Option: BusinessTypeOptions,
      };

    const calculatorComponents = {
      Option: CustomOptions,
      IndicatorSeparator,
    };

    if (isPhoneCode)
      return {
        ...calculatorComponents,
        SingleValue: PhoneCodeSingleValue,
      };

    if (isCalculator) return calculatorComponents;

    if (isTopBottom)
      return {
        IndicatorSeparator,
        Option: TopBottomOption,
        SingleValue: TopBottomSingleValue,
      };

    return {
      IndicatorSeparator,
    };
  };

  const components = componentsDecider();
  return (
    <Select
      ref={ref}
      isPhoneCode={isPhoneCode}
      className={className}
      instanceId={"custom-react-selection" + name}
      isCalculator={isCalculator}
      isDisabled={disabled}
      components={components}
      styles={{
        dropdownIndicator: (style, { isFocused }) => {
          return {
            ...style,
            color: error ? "#E95757" : isFocused ? colors.pink : colors.greyC2,
          };
        },
        control: (style, { isFocused, isDisabled }) => ({
          ...style,
          borderRadius: 8,
          height,
          fontSize: 14,
          fontFamily: "GothamBook",
          color: "transparent",
          backgroundColor: isDisabled ? "#eaeaea" : "white",
          borderColor: error
            ? "#E95757"
            : isFocused
            ? colors.pink
            : colors.greyC2,
          boxShadow: "0 0 0 0 black",
          "&:hover": {
            border: `1px solid ${colors.pink}`,
          },
          ...containerStyle,
        }),
        singleValue: (style) => ({
          ...style,
          fontFamily: "GothamBook",
          fontSize: 14,
          marginTop: isCentered ? -2 : 12,
          marginLeft: 0,
        }),
        input: (style) => ({
          ...style,
          fontFamily: "GothamBook",
          marginLeft: isFlag ? 36 : 0,
          fontSize: 14,
          marginTop: isFlag || isCentered ? 2 : isMenuOpen ? 4 : 15,
          ...inputStyle,
        }),
        option: (style, { isSelected, isFocused }) => ({
          ...style,
          fontSize: 14,
          fontFamily: "GothamBook",
          backgroundColor: isSelected
            ? colors.pink
            : isFocused
            ? colors.lightPink
            : "transparent",
          ...optionStyle,
        }),
        menu: (style) => ({
          ...style,
          border: `none`,
          // border: `1px solid ${colors.pink}`,
          borderRadius: 8,
          ...menuStyle,
        }),
        indicatorSeparator: () => ({}),
      }}
      onMenuOpen={() => {
        if (!isSearch) return;
        if (hasValue) return;
        setIsMenuOpen(true);
      }}
      onMenuClose={() => setIsMenuOpen(false)}
      options={optionsWLoading}
      name={name}
      onChange={onChange}
      isLoading={isLoading}
      {...props}
      placeholder={isMenuOpen ? "Search..." : placeholder ? placeholder : ""}
    />
  );
};

export const defaultIconStyleQueryInput = {
  style: {
    position: "absolute",
    left: "9px",
    top: "10px",
    color: "#727272",
  },
};

const ColWrapper = ({ isCol, columnProps, children }) => {
  if (isCol)
    return (
      <Col className="d-flex align-items-center px-1" {...columnProps}>
        {children}
      </Col>
    );
  return children;
};

export const defaultDateDecider = (currentValue) => {
  if (currentValue) {
    const getDate = new Date(currentValue);
    const dateString = String(getDate);
    const invalid = dateString.includes("Invalid");
    if (invalid) {
      return null;
    }
    return getDate;
  }
  return null;
};

export const QueryDatePicker = ({
  columnProps,
  name,
  label,
  isRangeDate,
  defaultDate,
  defaultEnd,
  woClearButton,
  containerStyle,
  hideClear = false,
  showClear,
  maxDate,
  minDate,
  disabled,
  isMinimalist = false, // No Icon Date & Label Text Grey
  woPage,
  ...rest
}) => {
  const { push, query } = useRouter();

  const currentValue = query[name] || query["start_date"];
  const currentValueEnd = query["end_date"];
  const defaultValue = defaultDateDecider(currentValue || defaultDate);
  const defaultValueEnd = defaultDateDecider(currentValueEnd || defaultEnd);

  const [date, setDate] = useState(defaultValue);
  const [endDate, setEndDate] = useState(defaultValueEnd);

  const useFormObj = useForm();
  const ref = useRef();

  const onClickIcon = () => {
    ref?.current?.setOpen(true);
  };
  const [isFirstRender, setIsFirstRender] = useState(true);

  useEffect(() => {
    // needs setTimeout, caused by inconsistency of `defaultValue`
    if (isFirstRender)
      return setTimeout(() => {
        setIsFirstRender(false);
      }, 100);
    setDate(defaultValue);
    setEndDate(defaultValueEnd);
  }, [isFirstRender]);

  const defaultPush = (query) => {
    if (woPage) return push({ query });
    push({ query: { ...query, page: 1 } });
  };

  // run this when its not date range
  useEffect(() => {
    const stopper = isRangeDate || !date || isFirstRender;
    if (stopper) return;

    let newObj = {};
    const currentDate = new Date(date);
    newObj[name] = String(currentDate);
    const queries = { ...query, ...newObj };
    defaultPush(queries);
  }, [date, isFirstRender]);
  // run this when its not date range

  // run this when its date range
  useEffect(() => {
    const stopper = !isRangeDate || isFirstRender || !endDate;

    if (stopper) return;

    const newObj = {
      start_date: String(new Date(date)),
      end_date: String(new Date(endDate)),
    };
    const queries = { ...query, ...newObj };

    defaultPush(queries);
  }, [endDate, isFirstRender]);
  // run this when its date range

  const handleClear = () => {
    if (isRangeDate) {
      delete query["start_date"];
      delete query["end_date"];
    }

    delete query[name];
    defaultPush(query);

    setDate(null);
    setEndDate(null);
  };

  const propsDecider = () => {
    if (isRangeDate) {
      const onChange = (dates) => {
        const [start, end] = dates;
        setDate(start);
        setEndDate(end);
      };

      const onCalendarClose = () => {
        // if it has defaultValue, run this
        if (defaultEnd) {
          if (!endDate) return setEndDate(new Date());
          return;
        }
        // if it has defaultValue, run this
        if (!endDate) {
          setDate(null);
          setEndDate(null);
          return;
        }
      };

      return {
        startDate: date,
        endDate,
        selectsRange: true,
        onChange,
        maxDate: new Date(),
        onCalendarClose,
      };
    }

    return {
      onSelect: (date) => setDate(date),
    };
  };

  const getProps = propsDecider();
  const showClearButton =
    !woClearButton &&
    ((Boolean(date) && !isRangeDate && !hideClear) ||
      (isRangeDate && showClear && endDate));

  const getLabel = isRangeDate
    ? "Start Date - End Date"
    : label
    ? label
    : titleCase(name);

  const { isTabOrPhone, isTabOrPhoneStyleMain } = tabOrPhoneStyles();
  const labelStyle = isMinimalist ? { color: colors.grey6c } : {};

  return (
    <ColWrapper isCol={!isTabOrPhone} columnProps={columnProps}>
      <div style={{ position: "relative", width: "100%" }}>
        <DatePicker
          {...getProps}
          dateFormat="yyyy/MM/dd"
          name={name}
          selected={date}
          ref={ref}
          maxDate={maxDate}
          minDate={minDate}
          disabled={disabled}
          customInput={
            <CustomTextFieldRef
              name={name}
              label={getLabel}
              isDisabled
              useFormObj={useFormObj}
              style={{ backgroundColor: "white" }}
              containerStyle={{ ...containerStyle, ...isTabOrPhoneStyleMain }}
              labelStyle={labelStyle}
            />
          }
          {...rest}
        />
        {!isMinimalist && (
          <img
            onClick={onClickIcon}
            src={dateIcon}
            className="darkhover"
            style={{
              position: "absolute",
              right: 10,
              top: 12.5,
            }}
          />
        )}
        {showClearButton && (
          <button
            className="fake-button"
            style={{
              position: "absolute",
              bottom: -3,
              left: 0,
              textDecoration: "underline",
            }}
            onClick={handleClear}
          >
            Clear
          </button>
        )}
      </div>
    </ColWrapper>
  );
};
//start
export const CustomDateInput = ({
  name,
  label,
  useFormObj,
  defaultValue,
  columnProps,
  disabled,
  minDate,
  containerStyle,
  labelStyle,
}) => {
  const ref = useRef();
  const onClickIcon = () => {
    ref?.current?.setOpen(true);
  };
  const [date, setDate] = useState();

  useEffect(() => {
    setDate(defaultValue);
  }, []);

  return (
    <ColWrapper columnProps={columnProps}>
      <div style={{ position: "relative", width: "100%" }}>
        <DatePicker
          onSelect={(date) => {
            setDate(date);
          }}
          minDate={minDate}
          dateFormat="dd/MM/yyyy"
          name={name}
          selected={date}
          ref={ref}
          disabled={disabled}
          customInput={
            <CustomTextFieldRef
              name={name}
              label={label}
              useFormObj={useFormObj}
              style={{ backgroundColor: "white" }}
              containerStyle={{ ...containerStyle }}
              labelStyle={labelStyle}
            />
          }
        />
        <img
          onClick={onClickIcon}
          src={dateIcon}
          className="darkhover"
          style={{
            position: "absolute",
            right: 10,
            top: 12.5,
          }}
        />
      </div>
    </ColWrapper>
  );
};

const DatePickerCustomHeader = ({
  date,
  changeYear,
  changeMonth,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
  customRangeYears = null,
}) => {
  const { minYear, maxYear } = customRangeYears || {};
  const years = customRangeYears
    ? range(minYear, maxYear + 1, 1)
    : range(1990, getYear(new Date()) + 3, 1);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const ArrowButton = ({ disabled, onClick, isLeft }) => (
    <GothamMedium
      className={`${disabled ? "" : "darkhover"} h-100 font16 ${
        isLeft ? "ml-1" : "mr-1"
      }`}
      style={{
        color: disabled ? colors.greyC2 : colors.grey72,
        width: 20,
      }}
      onClick={() => {
        if (disabled) return;
        onClick();
      }}
    >
      {isLeft ? "<" : ">"}
    </GothamMedium>
  );

  return (
    <div className=" d-flex justify-content-between align-items-center">
      <ArrowButton
        isLeft
        disabled={prevMonthButtonDisabled}
        onClick={decreaseMonth}
      />
      <div
        className="d-flex"
        style={{
          marginTop: 10,
          marginBottom: 10,
        }}
      >
        <select
          value={months[getMonth(date)]}
          onChange={({ target: { value } }) =>
            changeMonth(months.indexOf(value))
          }
        >
          {months.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
        <div style={{ width: 4 }}></div>
        <select
          value={getYear(date)}
          onChange={({ target: { value } }) => changeYear(value)}
        >
          {years.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>
      <ArrowButton disabled={nextMonthButtonDisabled} onClick={increaseMonth} />
    </div>
  );
};
export const CustomDateInputv2 = ({
  name,
  label,
  useFormObj: { watch, errors, control },
  defaultValue = "",
  error,
  disabled,
  required = true,
  emptyField = "",
  prefix = "",
  minDate,
  maxDate,
  inputProps,
  style,
  errorStyle,
  containerStyle,
  chillError = false,
  labelStyle,
  showError = false,
  customRangeYears = null,
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const currValue = watch(name);
  const hasValue = Boolean(currValue);
  const errorMessage = errors[name]?.message;
  const isError = Boolean(error || errorMessage);
  const ref = useRef();
  const getLabel = label || titleCase(name);
  const onClickIcon = () => {
    ref?.current?.setOpen(true);
  };
  const { border: borderColor, text: labelColor } = labelColorDecider({
    isActive: isFocused,
    isError: chillError ? false : isError,
  });
  const errorString = () => {
    if (typeof error == "string" && error) return error;
    if (emptyField) return emptyField;
    if (errorMessage) return sentenceCase(errorMessage);
    return getLabel + " cannot be empty";
  };
  return (
    <div style={{ position: "relative", ...containerStyle }}>
      <Controller
        style={{ position: "relative", width: "100%" }}
        control={control}
        name={name}
        defaultValue={defaultValue}
        render={(field) => {
          return (
            <DatePicker
              dateFormat="dd/MM/yyyy"
              renderCustomHeader={(args) =>
                DatePickerCustomHeader({ ...args, customRangeYears })
              }
              selected={field.value}
              ref={ref}
              minDate={minDate}
              maxDate={maxDate}
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
              onChange={(e) => field?.onChange(e)}
              disabled={disabled}
              required={required}
              onCalendarClose={() => setIsFocused(false)}
              customInput={
                <TextField
                  label={getLabel}
                  variant="standard"
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      marginLeft: 8,
                      marginRight: 8,
                      color: "black",
                      fontFamily: "GothamBook",
                      fontSize: 14,
                      marginTop: 14,
                    },
                    ...inputProps,
                  }}
                  InputLabelProps={{
                    style: {
                      position: "absolute",
                      fontFamily: "GothamBook",
                      fontSize: 14,
                      left: 8,
                      top: -10,
                      color: labelColor,
                      ...labelStyle,
                    },
                  }}
                  value={field.value}
                  inputRef={field.ref}
                  style={{
                    height: 44,
                    backgroundColor: disabled ? colors.greyea : "transparent",
                    borderRadius: 8,
                    width: "100%",
                    borderWidth: 0.5,
                    border: `1px solid ${borderColor}`,
                    ...style,
                  }}
                />
              }
            />
          );
        }}
      />
      <CustomTextFieldError
        show={isError}
        message={errorString()}
        errorStyle={errorStyle}
        chillError={chillError}
      />
      <img
        onClick={onClickIcon}
        src={dateIcon}
        className="darkhover"
        style={{
          position: "absolute",
          right: 10,
          top: 12.5,
        }}
      />
    </div>
  );
};

//end
const tabOrPhoneStyles = () => {
  const { isTabOrPhone } = windowDimension();
  const isTabOrPhoneStyleMain = isTabOrPhone ? { marginBottom: 12 } : {};
  const isTabOrPhoneStyle = isTabOrPhone ? { width: "100%" } : {};

  return { isTabOrPhoneStyleMain, isTabOrPhoneStyle, isTabOrPhone };
};

export const QueryInput = ({
  name,
  placeholder,
  icon,
  columnProps,
  isCol,
  isMargin = true,
  containerStyle,
  inputStyle,
  woQuery,
  onChange,
  woDebounce,
  disabled = false,
  isNumber = false,
  defaultValue,
  stickyPlaceholder,
  maxLength,
  woClearButton = true,
  xIcon,
  autoFocus,
}) => {
  const { query, push } = useRouter();
  const value = query[name] || "";
  const [search, setSearch] = useState(query[name] || defaultValue || "");

  const debouncedSearch = useDebounce(search, woDebounce ? 0 : 750);

  const [isFirstRender, setIsFirstRender] = useState(true);

  useEffect(() => {
    if (!value) setSearch("");

    const numberValue = String(search)
      .split("")
      .filter((number) => !isNaN(number) && number !== " ")
      .join("");

    const cappedNumberValue = maxLength
      ? numberValue.slice(0, maxLength)
      : numberValue;

    setSearch(isNumber ? formatCurrency(cappedNumberValue) : search);
  }, [value, search]);

  useEffect(() => {
    if (defaultValue) setSearch(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    if (isFirstRender) return setIsFirstRender(false);
    if (woQuery) {
      onChange(search);
      return;
    }

    let obj = {};
    obj[name] = search;
    push({ query: { ...query, page: 1, ...obj } });
  }, [debouncedSearch]);

  const { isTabOrPhoneStyle, isTabOrPhoneStyleMain } = tabOrPhoneStyles();

  return (
    <ColWrapper isCol={isCol} columnProps={columnProps}>
      <div
        className={isMargin ? "form-group has-search d-flex" : ""}
        style={{
          width: "100%",
          marginTop: isCol ? 18 : 0,
          ...isTabOrPhoneStyleMain,
          ...containerStyle,
        }}
      >
        {icon}
        {!woClearButton && (
          <div onClick={() => setSearch("")} style={{ cursor: "pointer" }}>
            {xIcon}
          </div>
        )}
        {stickyPlaceholder && (
          <div
            style={{
              position: "absolute",
              left: "9px",
              top: "11px",
              color: "#727272",
            }}
          >
            <GothamMedium style={{ color: "#333334" }}>
              {stickyPlaceholder}
            </GothamMedium>
          </div>
        )}
        <input
          autoFocus={autoFocus}
          type="text"
          value={search}
          onChange={(e) => e && setSearch(e.target.value)}
          placeholder={placeholder}
          className="searchBatch"
          disabled={disabled}
          style={
            icon
              ? {
                  height: 42,
                  paddingTop: 10,
                  ...isTabOrPhoneStyle,
                  ...inputStyle,
                }
              : {
                  padding: 16,
                  paddingTop: 10,
                  height: 42,
                  width: "100%",
                  paddingLeft: "40px",
                  ...isTabOrPhoneStyle,
                  ...inputStyle,
                }
          }
        />
      </div>
    </ColWrapper>
  );
};

export const QuerySelection = ({
  options: optionsRaw = [],
  containerStyle,
  mainContainerStyle,
  inputStyle,
  className = "",
  height = 56,
  label = "",
  name,
  onChange = () => {},
  style,
  columnProps,
  woClearButton,
  ...props
}) => {
  const defaultQueryOption = { label: "All", value: "all_hidden" };
  const options = [defaultQueryOption, ...optionsRaw];

  const { push, query } = useRouter();
  const valueFromQuery = query[name];

  const [getValue, setGetValue] = useState(null);

  const noOptions = options.length == 1;

  const hideClear =
    getValue?.value == defaultQueryOption.value || woClearButton || noOptions;

  useEffect(() => {
    if (noOptions) return;

    if (!valueFromQuery) return setGetValue(defaultQueryOption);

    const isAll = valueFromQuery == defaultQueryOption.value;
    if (isAll) return handleClear();

    const result = options.filter(({ value }) => valueFromQuery == value)[0];
    setGetValue(result || null);

    // clear query if not found
    const isNoResult = !result && valueFromQuery;
    if (isNoResult) handleClear();
    // clear query if not found
  }, [query, noOptions]);

  const handleClear = () => {
    delete query[name];
    push({ query });
  };

  const defaultOnChange = (val) => {
    if (!val) return;
    let obj = {};
    const safeValue = get(val, "value", "");
    if (safeValue) {
      obj[name] = safeValue;
    }
    push({ query: { ...query, ...obj, page: 1 } });
  };

  const { isTabOrPhone, isTabOrPhoneStyle, isTabOrPhoneStyleMain } =
    tabOrPhoneStyles();

  return (
    <ColWrapper isCol={!isTabOrPhone}>
      <div
        style={{
          width: "100%",
          // overflowX: "hidden",
          ...mainContainerStyle,
          ...isTabOrPhoneStyleMain,
        }}
      >
        <Selection
          {...props}
          options={options}
          containerStyle={{ ...containerStyle, ...isTabOrPhoneStyle }}
          className={className}
          inputStyle={inputStyle}
          height={height}
          label={label}
          name={name}
          value={getValue}
          onChange={(val) => {
            defaultOnChange(val);
            onChange(val);
          }}
        />
        {!hideClear && (
          <button
            className="fake-button"
            style={{ position: "absolute", textDecoration: "underline" }}
            onClick={handleClear}
          >
            Clear
          </button>
        )}
      </div>
    </ColWrapper>
  );
};

export const SelectionForm = ({
  useFormObj: { watch, setValue, register, errors },
  name,
  label,
  options,
  required = "Please select an option",
  defaultValue = undefined,
  error,
  woSpacer,
  errorStyle,
  onChange,
  alwaysShow = false,
  valueListener = true,
  containerStyle,
  customErrorMessage,
  ...props
}) => {
  useEffect(() => {
    register({ name, required });
  }, [name, register]);

  const value = watch(name) || defaultValue;

  const isSingleOptions = options.length == 1;

  useEffect(() => {
    const stopper = options.length == 0;
    const isObject = typeof value == "object";
    if (stopper) return;
    if (isObject) return;

    const result = options.filter(
      ({ value: itemValue }) => itemValue == value
    )[0];

    if (isSingleOptions) return setValue(name, options[0]);

    if (!result) return setValue(name, undefined);

    setValue(name, result);
  }, [value, options.length]);

  const handleChange = (item) => {
    onChange && onChange(item);
    setValue(name, item);
  };

  const errorMsgFunc = () => {
    if (valueListener)
      return Boolean(value?.value) ? "" : errors[name]?.message;
    return errors[name]?.message;
  };

  const errorMessage = errorMsgFunc();
  const isErrorMsgTooLong = (errorMessage || "").length > 50;

  const getErrorMsg = isErrorMsgTooLong ? `${label} is invalid` : errorMessage;

  const showError = Boolean(error || errorMessage);

  const isError = Boolean(error || errorMessage);

  return (
    <div style={{ display: isSingleOptions && !alwaysShow && "none" }}>
      <Selection
        containerStyle={containerStyle}
        options={options}
        label={label}
        labelStyle={{ color: isError ? colors.errorRed : colors.grey72 }}
        name={name}
        value={value}
        onChange={handleChange}
        error={showError}
        {...props}
      />
      <CustomTextFieldError
        errorStyle={errorStyle}
        message={
          customErrorMessage ||
          sentenceCase(getErrorMsg || `${label} cannot be empty`)
        }
        show={showError}
      />
      {!woSpacer && <div style={{ height: 16 }}></div>}
    </div>
  );
};

export const CountrySelection = ({
  options,
  containerStyle,
  className = "",
  inputStyle,
  height = 44,
  label = "",
  useFormObj: { register, watch, setValue },
  name,
  isLoading = false,
  defaultValue,
  disabled,
  onChange = () => {},
  style,
  labelStyle,
  ...props
}) => {
  const currentVal = watch(name);
  const [selectedCountryId, setSelectedCountryId] = useState(false);

  useEffect(() => {
    setSelectedCountryId(currentVal?.country_id);
  }, [currentVal?.country_id]);

  const isEmpty = !currentVal && !defaultValue;
  useEffect(() => {
    register({ name });
  }, [name, register]);

  const defaultFont = { fontSize: 14, fontFamily: "GothamBook" };
  const [showCurr, setShowCurr] = useState(true);

  return (
    <div style={style}>
      <GothamRegular
        style={{
          color: colors.grey72,
          lineHeight: "14px",
          ...labelStyle,
        }}
      >
        {label}
      </GothamRegular>
      <Select
        {...props}
        onMenuOpen={() => setShowCurr(false)}
        onMenuClose={() => setShowCurr(true)}
        isDisabled={disabled}
        defaultValue={currentVal ? currentVal : defaultValue}
        ref={register}
        isLoading={isLoading}
        name={name}
        className={className}
        instanceId={"custom-react-selection" + name}
        components={{
          Option: (props) => (
            <CustomOptions selectedCountryId={selectedCountryId} {...props} />
          ),
          IndicatorSeparator: (props) => {
            const { country_iso, currency } = currentVal || defaultValue || {};
            return (
              <Row
                style={{
                  height,
                  top: 0,
                  left: 1,
                  position: "absolute",
                }}
                className="mx-0 "
              >
                {!isEmpty && <GetFlag iso={noCase(country_iso)} />}

                {showCurr && (
                  <GothamMedium
                    style={{
                      color: colors.pink,
                      fontSize: 14,
                    }}
                    className="mt-1"
                  >
                    {currency}
                  </GothamMedium>
                )}
              </Row>
            );
          },
        }}
        styles={{
          dropdownIndicator: (style, { isFocused }) => ({
            ...style,
            color: isFocused ? colors.pink : colors.greyC2,
          }),
          control: (style, { isFocused }) => ({
            ...style,
            borderRadius: 8,
            height,
            ...defaultFont,
            color: "transparent",
            borderColor: isFocused ? colors.pink : colors.greyC2,
            boxShadow: "0 0 0 0 black",
            paddingLeft: 32,
            ...containerStyle,
          }),
          singleValue: (style) => ({
            ...style,
            ...defaultFont,
            marginTop: !showCurr ? 0 : 15,
            marginLeft: 4,
            color: colors.grey72,
          }),
          input: (style) => ({
            ...style,
            ...defaultFont,
            marginLeft: 5,
            color: colors.grey72,
            ...inputStyle,
          }),
          option: (style, { isSelected, isFocused }) => ({
            ...style,
            ...defaultFont,
            backgroundColor: isSelected
              ? colors.pink
              : isFocused
              ? colors.lightPink
              : "transparent",
          }),
          placeholder: (style) => ({
            ...style,
            display: isEmpty ? "" : "none",
          }),
          menu: (style) => ({
            ...style,
            border: `1px solid ${colors.pink}`,
            borderRadius: 8,
          }),
          indicatorSeparator: () => ({}),
        }}
        options={options}
        onChange={(e) => {
          setValue(name, Boolean(e) && e);
          onChange(e);
          setShowCurr(true);
        }}
      />
    </div>
  );
};

const TopBottomOption = ({ data, innerProps, isSelected }) => {
  const { label, top, bottom } = data;
  const defaultFontStyle = {
    color: isSelected ? "white" : "black",
    marginBottom: 0,
  };
  if (top && bottom)
    return (
      <Row
        className="option mx-0  align-items-center px-2"
        {...innerProps}
        style={
          isSelected
            ? { backgroundColor: colors.pink, color: "white", height: 56 }
            : { height: 56 }
        }
      >
        <div>
          <GothamRegular
            style={{ fontSize: 14, lineHeight: "22px", ...defaultFontStyle }}
          >
            {top}
          </GothamRegular>
          <GothamRegular
            style={{ fontSize: 10, lineHeight: "16px", ...defaultFontStyle }}
          >
            {bottom}
          </GothamRegular>
        </div>
      </Row>
    );
  if (label)
    return (
      <Row
        className="option mx-0  align-items-center px-2"
        {...innerProps}
        style={
          isSelected
            ? { backgroundColor: colors.pink, color: "white", height: 56 }
            : { height: 56 }
        }
      >
        <GothamRegular
          style={{ fontSize: 14, lineHeight: "22px", ...defaultFontStyle }}
        >
          {label}
        </GothamRegular>
      </Row>
    );
  return null;
};

const CustomOptions = ({
  data,
  innerProps,
  isSelected: isSelectedRaw,
  selectProps,
  selectedCountryId,
}) => {
  const { isCalculator, isPhoneCode } = selectProps || {};

  const getData = () => {
    if (isPhoneCode) {
      const { iso: country_iso, number: label } = phoneSimpleLabelSplitter(
        data?.label
      );
      return { label, country_iso };
    }
    return data;
  };

  const { currency, label, country_iso, country_id } = getData() || {};

  const isSelected = selectedCountryId
    ? country_id == selectedCountryId
    : isSelectedRaw;

  const safeLabel = label || "";
  const getLabel = isCalculator ? safeLabel.split("/")[0] : safeLabel;

  return (
    <Row
      className="option mx-0  align-items-center px-3"
      {...innerProps}
      style={
        isSelected
          ? { backgroundColor: colors.pink, color: "white", height: 56 }
          : { height: 56 }
      }
    >
      <GetFlag iso={noCase(country_iso)} style={{ margin: 0 }} />
      <div className="ml-3 pt-2">
        <GothamRegular style={{ color: isSelected ? "white" : colors.grey25 }}>
          {getLabel}
        </GothamRegular>
        <GothamRegular style={{ color: isSelected ? "white" : colors.grey25 }}>
          {currency}
        </GothamRegular>
      </div>
    </Row>
  );
};
export const GetFlag = ({
  iso,
  width = 24,
  height = 24,
  isRectangle,
  style,
}) => (
  <img
    src={`https://flagcdn.com/h80/${iso}.png`}
    width={width}
    height={height}
    className="align-self-center"
    style={{
      filter: "brightness(95%)",
      marginLeft: 12,
      marginRight: 7,
      borderRadius: isRectangle ? 4 : 10000,
      ...style,
    }}
  />
);

const papaParseFormatter = (data, file) => {
  const getKeys = data[0];
  const getValues = data
    .filter((_, index) => index !== 0)
    .filter((item) => item.length > 1);
  const result = getValues.map((item, index) => {
    let object = {};
    for (let i = 0; i < item.length; i++) {
      object[getKeys[i]] = item[i];
    }
    object["file_name"] = file.name;
    object["index"] = index;
    return object;
  });
  return result;
};

const fileTooLargeDecider = (files) => {
  let getSizeArr = [];
  for (let i = 0; i < files.length; i++) {
    const file = files[i];

    getSizeArr.push(file.size);
  }
  const totalFileSize = getSizeArr.reduce((acc, curr) => acc + curr, 0);
  const allowedFileSize = 100000000;
  const invalidSize = allowedFileSize < totalFileSize;
  return invalidSize;
};

const isValidDecider = ({ fileTypes, file, maxSize }) => {
  const { getSize, getType } = fileProps(file);
  const isValid = fileTypes.includes(getType) && getSize < maxSize;
  const validButLimit = fileTypes.includes(getType) && getSize > maxSize;
  return { isValid, validButLimit };
};

const fileProps = (file, type, multiple) => {
  const isNotMultiGeneral = type === "general" && !multiple;
  const isMultiGeneral = type === "general" && multiple;
  const getType = get(file, "type", "");
  const getName = get(file, "name", "");
  const getSize = get(file, "size", 0);
  const isNameCsv = getName.split(".").pop() === "csv";
  const isCsv =
    (getType === "text/csv" ||
      getType === "application/csv" ||
      getType === "application/vnd.ms-excel") &&
    isNameCsv;

  return {
    getType,
    getName,
    getSize,
    isCsv,
    isNotMultiGeneral,
    isMultiGeneral,
  };
};

const checkDuplicate = (prev, file, string, fileKey = "name") => {
  const currentNames = prev.map((item) => item[string]);
  if (currentNames.includes(file[fileKey])) {
    return prev;
  }
  return [...prev, file];
};

const notMultiGeneralFunc = async ({
  fileTypes,
  file,
  setError,
  errorToaster,
  setValue,
  name,
  maxSize,
}) => {
  if (fileTypes.length) {
    const { isValid, validButLimit } = isValidDecider({
      file,
      fileTypes,
      maxSize,
    });
    if (isValid) {
      const label = file.name;
      const value = String(await toBase64(file));
      setValue(name, { label, value, realFile: file });
      return;
    }
    if (validButLimit) {
      errorToaster(
        "Error",
        `File size exceeds maximum limit (${toMBString(maxSize)} MB)`
      );
      setError(false);
      return;
    }
    setError(true);
    return;
  }
  const label = file.name;
  const value = String(await toBase64(file));
  setValue(name, { label, value });
  return;
};
export const toKBString = (number) => {
  const divided = Math.ceil(Number(number) / 1000);
  return `${divided} KB`;
};
export const toMBString = (number) => {
  const divided = Number(number) / 1000000;
  return `${divided} MB`;
};
const multiGeneralFunc = async ({
  fileTypes,
  file,
  setError,
  errorToaster,
  setData,
  setFile,
  formData,
  maxSize,
}) => {
  if (fileTypes.length) {
    const { isValid, validButLimit } = isValidDecider({
      file,
      fileTypes,
      maxSize,
    });
    const label = file.name;
    if (isValid) {
      const value = String(await toBase64(file));
      setData((prev) => {
        if (formData) {
          return checkDuplicate(prev, file, "name");
        }
        const result = { label, value };
        return checkDuplicate(prev, result, "label", "label");
      });

      setFile((prev) => checkDuplicate(prev, file, "name"));
      setError(false);
      return;
    }
    if (validButLimit) {
      errorToaster(
        "Error",
        `${label} exceeds maximum limit of ${toMBString(maxSize)}`
      );
      setError(false);
      return;
    }
    setError(true);
    return;
  }

  const label = file.name;
  const value = String(await toBase64(file));
  const result = { label, value };
  setData((prev) => checkDuplicate(prev, result, "label", "label"));
  setFile((prev) => checkDuplicate(prev, result, "label", "label"));
  return;
};

const uploadCsvFunc = async ({ file, setError, setFile, setData }) => {
  const { isCsv, getName } = fileProps(file);
  const label = getName;
  const value = String(await toBase64(file));
  if (isCsv) {
    Papa.parse(file, {
      complete: ({ data }) => {
        const result = papaParseFormatter(data, file) || [];
        setData((prev) => {
          const resultFileNames = result.map(({ file_name }) => file_name);
          const isDuplicate = prev.filter(({ file_name }) =>
            resultFileNames.includes(file_name)
          ).length;
          if (isDuplicate) {
            return prev;
          }
          return [...prev, ...result];
        });
      },
    });

    setFile((prev) => {
      const result = { label, value };
      return checkDuplicate(prev, result, "label", "label");
    });
    setError(false);
    return;
  }
  setError(true);
  return;
};

const deleteFormatter = (currValue, deletedItem) => {
  const { data, file } = currValue;

  const newData = data.filter(({ file_name, name, label }) => {
    if (file_name == deletedItem) return false;
    if (label == deletedItem) return false;
    if (name == deletedItem) return false;
    return true;
  });

  const newFile = file.filter(({ label, name }) => {
    if (label == deletedItem) return false;
    if (name == deletedItem) return false;
    return true;
  });
  return { newFile, newData };
};

export const fileTypes = (typeRaw) => {
  const type = String(typeRaw || "");
  const isPDF = type.includes("pdf");
  const isJPEG = type.includes("jpeg") || type.includes("jpg");
  const isPNG = type.includes("png");
  const isJPEGOrPNG = isJPEG || isPNG;
  return { isPDF, isJPEG, isPNG, isJPEGOrPNG };
};

export const showPDFOrJPEGHooks = (filesRaw) => {
  const files = filesRaw || [];
  const [file, setFile] = useState({});
  const getFile = file?.file || file || {};
  const { type, name, url } = getFile;
  const { isJPEGOrPNG } = fileTypes(type || url);
  const { height } = windowDimension();
  // PDF PROPS
  const [pageNumber, setPageNumber] = useState(1);
  const [numPages, setNumPages] = useState(null);
  // PDF PROPS

  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setPageNumber(1);
    setIsOpen((p) => !p);
  };

  const handleShow = async (label) => {
    try {
      const currentFile = files.filter(({ name }) => name == label).pop();
      const { type, url } = currentFile || {};
      const { isPDF, isJPEGOrPNG } = fileTypes(type || url);
      if (!(isPDF || isJPEGOrPNG)) return;

      if (isJPEGOrPNG) {
        const base64 = url ? "" : String(await toBase64(currentFile));
        setFile(
          url
            ? {
                url:
                  process.env.NEXT_PUBLIC_TRANSFEZ_BUSINESS_WO_VERSION_URL +
                  url,
              }
            : { file: currentFile, base64 }
        );
        toggle();
        return;
      }
      setFile(
        url
          ? {
              url:
                process.env.NEXT_PUBLIC_TRANSFEZ_BUSINESS_WO_VERSION_URL + url,
            }
          : currentFile
      );
      toggle();
    } catch (error) {
      console.log("error:", error);
    }
  };
  const modalComponent = (
    <Modal
      toggle={toggle}
      isOpen={isOpen}
      centered
      contentClassName="modal-transfez"
    >
      <div>
        {isJPEGOrPNG ? (
          <img
            src={url ? url : file?.base64}
            style={{ height: height * 0.85, width: "auto" }}
          />
        ) : (
          <Document
            file={file}
            onLoadSuccess={({ numPages }) => setNumPages(numPages)}
          >
            <Page pageNumber={pageNumber} />
          </Document>
        )}
        <div
          className="d-flex justify-content-between align-items-center px-2"
          style={{ marginTop: 10 }}
        >
          {isJPEGOrPNG ? (
            <GothamRegular>{name}</GothamRegular>
          ) : (
            <>
              <GothamRegular
                style={{
                  width: "33%",
                  textAlign: "center",
                }}
              >
                {name}
              </GothamRegular>
              <div
                className="d-flex justify-content-center"
                style={{ width: "33%" }}
              >
                <img
                  src={leftArrow}
                  className={"darkhover"}
                  onClick={() =>
                    setPageNumber((p) => {
                      if (p > 1) return p - 1;
                      return p;
                    })
                  }
                />
                <img
                  src={rightArrow}
                  className="darkhover"
                  onClick={() =>
                    setPageNumber((p) => {
                      if (p < numPages) return p + 1;
                      return p;
                    })
                  }
                />
              </div>
              <GothamRegular
                style={{
                  width: "33%",
                  textAlign: "center",
                }}
              >
                Page {pageNumber} of {numPages}
              </GothamRegular>
            </>
          )}
        </div>
      </div>
    </Modal>
  );
  return { handleShow, modalComponent };
};

const formattedCurrValue = (data) => {
  const { url, name: label } = data || {};
  if (url)
    return {
      ...data,
      label,
    };
  return data;
};

export const FileInput = ({
  useFormObj,
  multiple = true,
  fileTypes = [],
  style,
  type = "",
  name,
  customText = "",
  height = 88,
  className = "",
  noButtonWhenSubmitted = false,
  formData,
  isError = useFormObj?.errors[name],
  trashAndShow,
  isDottedBorder = false,
  longText = false,
  isDottedThumbnail = false,
  defaultValue = [],
  hideField,
  maxSize = 10000000,
  maxSizeInfo = false,
  maxSizeInfoInside = false,
  woPadding = false,
  maxFileName,
  noFileErrorName = false,
  noDelete,
  fontStyle,
  uploadIcon,
  loading = false,
  canDelete,
  simpleTable,
  isPinkButton,
  isReimbursementDraft = false,
  invoiceUploadLoading = false,
  customFileSizeText = false,
  isCustomizeReceipt = false,
}) => {
  const { watch, setValue } = useFormObj;
  const currValue = formattedCurrValue(watch(name));

  const isThereData = Boolean(
    currValue?.data?.length || currValue?.size || currValue?.label || []?.length
  );

  useEffect(() => {
    if (!isThereData) setValue(name, false);
  }, [isThereData]);

  const { isNotMultiGeneral, isMultiGeneral } = fileProps(
    undefined,
    type,
    multiple
  );

  useRegisterField(useFormObj, name);

  const [isDragging, setIsDragging] = useState(false);
  const [error, setError] = useState(false);
  // data => only used in upload batch.
  // data = values of rows in csv files
  // file => real files
  const [data, setData] = useState(defaultValue);
  const [file, setFile] = useState(defaultValue);
  const { handleShow, modalComponent } = showPDFOrJPEGHooks(
    isNotMultiGeneral ? [currValue?.realFile || watch(name)] : file
  );
  const { errorToaster } = useContext(ToasterContext);
  const supportedFiles = fileTypes.map((type) => {
    const text = (type || "").split("/")[1];
    const isDocx =
      text == "vnd.openxmlformats-officedocument.wordprocessingml.document";
    const isXlsx =
      text == "vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    if (isXlsx) return "XLSX";
    if (isDocx) return "DOCX";
    return text;
  });
  const hasSupportedFiles = Boolean(supportedFiles.length);

  useEffect(() => {
    if (data.length && file.length) {
      // needs set time out for updates defaultValue
      setTimeout(() => {
        setValue(name, { data, file });
      }, 100);
    }
  }, [data, file]);

  const handleDelete = (deletedItem) => {
    const { newData, newFile } = deleteFormatter(currValue, deletedItem);
    setData(newData);
    setFile(newFile);
    setValue(name, { data: newData, file: newFile });
  };

  const getFileLabels = get(currValue, "file", []).map(
    ({ label, name }) => label || name
  );
  const isSubmitted = getFileLabels.length;
  const defaultAction = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const onDragEnter = (e) => {
    if (e) {
      defaultAction(e);
      if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
        setIsDragging(true);
      }
    }
  };

  const defaultSetValue = async (files) => {
    if (isNotMultiGeneral) {
      setData([]);
      setFile([]);
    }

    const invalidSize = fileTooLargeDecider(files);

    if (invalidSize) {
      errorToaster(
        "Error",
        `File size exceeds maximum limit (${toMBString(maxSize)} MB)`
      );
      return;
    }
    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      const defaultProps = {
        fileTypes,
        file,
        setError,
        errorToaster,
        setData,
        setFile,
        maxSize,
      };

      if (isNotMultiGeneral) {
        await notMultiGeneralFunc({
          name,
          setValue,
          isSingle: !multiple,
          formData,
          ...defaultProps,
        });
        return;
      }
      if (isMultiGeneral) {
        await multiGeneralFunc({ formData, ...defaultProps });
      }
      if (name === "upload_csv") {
        await uploadCsvFunc(defaultProps);
      }
    }
  };

  const onDrop = async (e) => {
    if (e) {
      defaultAction(e);
      e.persist();
      const getFiles = get(e, "dataTransfer.files", []);
      defaultSetValue(getFiles);
      setIsDragging(false);
    }
  };

  const onDragLeave = () => setIsDragging(false);

  const onDragOver = (e) => {
    if (e) {
      defaultAction(e);
    }
  };

  const onChange = async (e) => {
    const getFiles = get(e, "target.files", []);
    defaultSetValue(getFiles);
  };
  const { label = "" } = currValue || {};

  const getCurrLabel = maxFileName
    ? label.substring(0, maxFileName) + "..."
    : label;

  const notMultipleAndHasValue = Boolean(!multiple && label);

  const textDecider = () => {
    if (customText) {
      if (error) return "Invalid File";
      return customText;
    }
    if (!isDragging && isDottedBorder) {
      if (error) return ["File type is invalid. Please upload again"];
      if (isDottedThumbnail) return ["Browse or drag files here to upload"];
      if (longText) return ["To upload files, drag them here or browse"];
      return ["To upload files,", "drag them here or browse"];
    }

    if (isDragging && isDottedBorder) return ["Drop here"];
    if (isDragging) return "Drop here";
    if (error) return "File type is invalid. Please upload again";
    return "To upload files, drag them here or browse";
  };

  const text = textDecider();

  useEffect(() => {
    if (!error) return;
    errorToaster("Error", "File type invalid");
    setError(false);
  }, [error]);

  const hideButton = notMultipleAndHasValue && noButtonWhenSubmitted;

  const tableProps = {
    data: getFileLabels,
    dataPinkButton: get(currValue, "file", []),
    handleDelete,
    handleShow,
    trashAndShow,
    canDelete,
    simpleTable,
    isPinkButton,
    isReimbursementDraft,
    invoiceUploadLoading,
  };

  if (hideField)
    return (
      <>
        <MultipleInputTable {...tableProps} hideField />
        {modalComponent}
      </>
    );

  const ErrorAndTable = () => (
    <>
      {Boolean(isError && !isThereData) && (
        <GothamRegular className="font12" style={{ color: "#E95757" }}>
          {noFileErrorName ? "" : titleCase(name)} cannot be empty
        </GothamRegular>
      )}
      {Boolean(isSubmitted) && <MultipleInputTable {...tableProps} />}
      {modalComponent}
    </>
  );

  if (isCustomizeReceipt)
    return (
      <FileInputCustomizeReceipt
        name={name}
        onDrop={onDrop}
        loading={loading}
        value={currValue}
        maxSize={maxSize}
        onChange={onChange}
        onDragOver={onDragOver}
        onDragEnter={onDragEnter}
        onDragLeave={onDragLeave}
        supportedFiles={supportedFiles}
        onRemoveFile={() => setValue(name, {})}
      />
    );

  return (
    <>
      {isDottedBorder ? (
        <>
          <label
            onDragEnter={onDragEnter}
            onDrop={onDrop}
            onDragLeave={onDragLeave}
            onDragOver={onDragOver}
            className={`${
              hideButton ? "" : "btn-fileinput"
            } text-center hover ${className}`}
            style={{
              width: "100%",
              height: 124,
              backgroundColor: "white",
              border: "1px dashed #DA649F",
              padding: 12,
              ...style,
            }}
          >
            {loading && (
              <div
                style={{
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <FaSpinner className="icon-spin mr-2" />
                <GothamRegular>Uploading..</GothamRegular>
              </div>
            )}
            {!loading && (
              <>
                <Row
                  style={{ height }}
                  className="mx-0 align-items-center justify-content-center"
                >
                  {notMultipleAndHasValue ? (
                    <div className="d-flex justify-content-between align-items-center">
                      <GothamMedium
                        style={{ color: colors.blue19 }}
                        className="my-0"
                      >
                        {label}
                      </GothamMedium>
                      <img
                        src={xIcon}
                        className="ml-2 darkhover"
                        style={{ zIndex: 1 }}
                        onClick={(e) => {
                          if (e) {
                            e.preventDefault();
                            e.stopPropagation();
                            setValue(name, {});
                          }
                        }}
                      />
                    </div>
                  ) : (
                    <div>
                      <img src={uploadIcon ? uploadIcon : uploadBlueIcon} />
                      <GothamMedium
                        style={{ color: colors.pink, ...fontStyle }}
                        className="mt-1"
                      >
                        {text[0]}
                        <br />
                        {maxSizeInfoInside && (
                          <GothamRegular
                            style={{
                              color: colors.grey72,
                              fontSize: 12,
                              marginBottom: 0,
                              lineHeight: "18px",
                            }}
                          >
                            {`Maximum upload per file size : ${toMBString(
                              maxSize
                            )}.`}
                          </GothamRegular>
                        )}
                        {customFileSizeText ? (
                          <TextInlineRegular
                            style={{ fontSize: 12, color: "#6C6C71" }}
                          >
                            Max. size for each file:{" "}
                            <TextInlineMedium style={{ color: "#6C6C71" }}>
                              10 MB
                            </TextInlineMedium>
                          </TextInlineRegular>
                        ) : null}
                        {isReimbursementDraft ? <br /> : null}
                        {text[1]}
                      </GothamMedium>
                      {hasSupportedFiles && !noButtonWhenSubmitted && (
                        <>
                          {isDottedThumbnail ? (
                            <>
                              <GothamRegular
                                style={{
                                  color: colors.grey6c,
                                  fontSize: 10,
                                  lineHeight: "12px",
                                }}
                              >
                                Max. size for each file:{" "}
                                <span style={{ fontFamily: "GothamMedium" }}>
                                  {toMBString(maxSize)}
                                </span>
                              </GothamRegular>
                              <GothamRegular
                                style={{
                                  color: colors.grey6c,
                                  fontSize: 12,
                                  lineHeight: "12px",
                                  marginBottom: 0,
                                }}
                              >
                                Supported file types:{" "}
                                <span style={{ fontFamily: "GothamMedium" }}>
                                  {supportedFiles
                                    .map((item) => upperCase(item))
                                    .join(", ")}
                                </span>
                              </GothamRegular>
                            </>
                          ) : (
                            <GothamRegular
                              style={{ color: colors.grey72, fontSize: 10 }}
                            >
                              Supported File(s) :{" "}
                              {supportedFiles
                                .map((item) => upperCase(item))
                                .join(", ")}
                            </GothamRegular>
                          )}
                        </>
                      )}
                    </div>
                  )}
                </Row>

                {/* hidden input */}
                <input
                  name="testing"
                  type="file"
                  multiple={multiple}
                  style={{ display: "none" }}
                  onClick={(e) => {
                    e.target.value = null;
                  }}
                  onChange={onChange}
                />
                {/* hidden input */}
              </>
            )}
          </label>
          {maxSizeInfo && (
            <GothamRegular
              style={{
                color: colors.grey72,
                fontSize: 12,
                marginBottom: 0,
                lineHeight: "18px",
              }}
            >
              {`Maximum upload per file size : ${toMBString(maxSize)}.`}
            </GothamRegular>
          )}
          <ErrorAndTable />
        </>
      ) : (
        <>
          <label
            onDragEnter={onDragEnter}
            onDrop={onDrop}
            onDragLeave={onDragLeave}
            onDragOver={onDragOver}
            className={`${
              hideButton ? "" : "btn-fileinput"
            } text-center hover ${className}`}
            style={{
              width: "100%",
              height,
              border: notMultipleAndHasValue ? "" : "2px solid #DA649F",
              padding: woPadding ? 0 : "0px 65px",
              ...style,
            }}
          >
            <Row
              style={{ height }}
              className="mx-0 align-items-center justify-content-center"
            >
              {notMultipleAndHasValue ? (
                <div className="d-flex justify-content-between align-items-center">
                  <GothamMedium
                    style={{
                      color: colors.primaryBlue,
                      borderBottom: `${
                        woPadding ? "0px" : "1px"
                      } solid #2C4A77`,
                      textDecoration: woPadding ? "underline" : "",
                    }}
                    onClick={(e) => {
                      if (!e) return;
                      e.preventDefault();
                      e.stopPropagation();
                      handleShow(label);
                    }}
                    className="my-0 darkhover"
                  >
                    {getCurrLabel}
                  </GothamMedium>
                  {!noDelete && (
                    <img
                      src={xIcon}
                      className="ml-2 darkhover"
                      style={{ zIndex: 1 }}
                      onClick={(e) => {
                        if (e) {
                          e.preventDefault();
                          e.stopPropagation();
                          setValue(name, {});
                        }
                      }}
                    />
                  )}
                  {modalComponent}
                </div>
              ) : (
                <div className="d-flex justify-content-center align-items-center">
                  <img src={uploadIcon} className="mr-2 mb-2 " />
                  <GothamRegular
                    style={{
                      color: colors.pink,
                      fontWeight: "bold",
                      lineHeight: "22px",
                      textAlign: "center",
                      marginBottom: 0,
                    }}
                  >
                    {text}
                  </GothamRegular>
                </div>
              )}
            </Row>
            {/* hidden input */}
            <input
              name="testing"
              type="file"
              multiple={multiple}
              style={{ display: "none" }}
              onClick={(e) => {
                e.target.value = null;
              }}
              onChange={onChange}
            />
            {/* hidden input */}
          </label>
          {hasSupportedFiles && !noButtonWhenSubmitted && (
            <>
              <GothamRegular
                style={{
                  color: colors.grey72,
                  fontSize: 12,
                  marginBottom: 0,
                  lineHeight: "18px",
                }}
              >
                Supported File(s) :{" "}
                {supportedFiles.map((item) => upperCase(item)).join(", ")}
              </GothamRegular>
              <GothamRegular
                style={{
                  color: colors.grey72,
                  fontSize: 12,
                  marginBottom: 0,
                  lineHeight: "18px",
                }}
              >
                {`Maximum upload file size : ${toMBString(maxSize)}.`}
              </GothamRegular>
            </>
          )}
          <ErrorAndTable />
        </>
      )}
    </>
  );
};

export const FileInputDotted = ({
  fileTypes = ["application/pdf", "image/jpeg"],
  useFormObj,
  name = "upload_file",
}) => (
  <FileInput
    isDottedBorder
    fileTypes={fileTypes}
    useFormObj={useFormObj}
    name={name}
    formData
    type="general"
    multiple={false}
    maxSize={25000000}
  />
);

export const FileInputDottedThumbnail = ({
  fileTypes = ["application/pdf", "image/jpeg"],
  useFormObj,
  name = "upload_file",
  maxSize = 25000000,
  multiple = false,
  ...props
}) => (
  <FileInput
    isDottedBorder
    fileTypes={fileTypes}
    useFormObj={useFormObj}
    name={name}
    formData
    type="general"
    multiple={multiple}
    maxSize={maxSize}
    isPinkButton
    isDottedThumbnail
    style={{ backgroundColor: "#FFF5FD", border: "2px dashed #DA649F" }}
    {...props}
  />
);

//start
export const PinkTextFileInput = ({ text, textStyle, name }) => {
  return (
    <label>
      <GothamMedium style={textStyle}>{text}</GothamMedium>
      <input
        name={name}
        type="file"
        // multiple={multiple}
        style={{ display: "none" }}
        // onClick={(e) => {
        //   e.target.value = null;
        // }}
        // onChange={onChange}
      />
    </label>
  );
};
//end
export const SearchInput = ({
  placeholder = "",
  style,
  queryKey = "search_box",
}) => {
  const { push, query } = useRouter();
  const search_box = query[queryKey];
  const [text, setText] = useState(search_box);
  const onChange = (e) => {
    const getText = e && e.target.value;
    setText(getText);
  };

  useEffect(() => {
    let obj = query;
    obj[queryKey] = text;
    push({ query: obj });
  }, [text]);

  return (
    <Col className="ml-0 px-0">
      <div className="form-group has-search" style={{ ...style }}>
        <Search
          style={{
            position: "absolute",
            left: 13,
            top: 12,
            color: "#C2C2C2",
          }}
        />
        <input
          type="text"
          value={text}
          onChange={onChange}
          placeholder={placeholder}
          className="searchBatch"
          style={{ width: "100%", height: 42, paddingTop: 10 }}
        />
      </div>
    </Col>
  );
};

export const InlineSelection = ({ useFormObj, name, data = [] }) => {
  const { watch, register, setValue } = useFormObj;

  const currentItem = watch(name);

  useEffect(() => {
    register({ name });
    if (currentItem) return;
    setValue(name, data[0]);
  }, []);

  const onClick = (item) => {
    setValue(name, item);
  };

  return (
    <div className="d-flex">
      {data.map((item, index) => {
        const { label, value } = item;
        const isActive = currentItem?.value == value;
        return (
          <button
            key={index}
            className="fake-button py-2 px-3 darkhover"
            style={{
              backgroundColor: isActive ? colors.lightPink : "",
              borderRadius: 4,
              border: `1px solid ${colors.greyC2}`,
              borderColor: isActive ? "transparent" : colors.greyC2,
              marginRight: 6,
            }}
            onClick={() => onClick(item)}
          >
            <GothamMedium
              className="my-0 py-0 "
              style={{ color: isActive ? colors.pink : colors.greyC2 }}
            >
              {label}
            </GothamMedium>
          </button>
        );
      })}
    </div>
  );
};

export const RadioInput = ({ name, useFormObj, onClick }) => {
  const { register, setValue, watch } = useFormObj;
  useEffect(() => {
    register({ name });
  }, []);
  const currentValue = watch(name);
  const handleClick = () => {
    onClick && onClick(!currentValue);
    setValue(name, !currentValue);
  };
  return (
    <div className="d-flex align-items-center" style={{ marginBottom: 15 }}>
      <img
        onClick={handleClick}
        src={currentValue ? checkSquareIcon : emptySquareIcon}
        className="darkhover"
        style={{ marginRight: 8 }}
      />
      <GothamRegular style={{ color: colors.grey72 }} className="py-0 my-0 ">
        Allow All
      </GothamRegular>
    </div>
  );
};

export const ArraySelection = ({ useFormObj, name, data, number = 4 }) => {
  const { register, setValue, watch } = useFormObj;
  const setFieldValue = (value) => setValue(name, value);

  useEffect(() => {
    register({ name });
  }, []);
  const currentValue = watch(name) || [];

  const handleClick = (item) => {
    const isExisted = currentValue.filter(
      ({ value }) => value == item.value
    ).length;
    if (isExisted) {
      const filteredValue =
        currentValue.filter(({ value }) => value != item.value) || [];
      setValue("select_all_array", false);
      return setFieldValue(filteredValue);
    }
    setFieldValue([...currentValue, item]);
  };

  const arrayOfArray = dataSeparator(data || [], number);

  const handleClickRadio = (value) => {
    if (value) return setFieldValue(data);
    setFieldValue([]);
  };

  return (
    <>
      <RadioInput
        name="select_all_array"
        useFormObj={useFormObj}
        onClick={handleClickRadio}
      />
      {arrayOfArray.map((array, index) => (
        <div className="d-flex justify-content-between mb-2" key={index}>
          {array.map((item, index) => {
            const { label, img, value } = item;
            const width = `${(100 - (number - 1)) / number}%`;
            const isActive = currentValue
              .map(({ value }) => value)
              .includes(value);
            return (
              <div
                onClick={() => handleClick(item)}
                key={index}
                style={{
                  width,
                  borderRadius: 8,
                  border: `1px solid ${colors.greyC2}`,
                  flexDirection: "column",
                  padding: 12,
                  backgroundColor: isActive ? colors.lightPink : "",
                }}
                className="darkhover d-flex align-items-center"
              >
                {img}
                <GothamMedium
                  style={{
                    fontSize: 12,
                    lineHeight: "18px",
                    textAlign: "center",
                  }}
                >
                  {label}
                </GothamMedium>
              </div>
            );
          })}
        </div>
      ))}
    </>
  );
};

export const PaymentSelection = ({ title, data = [], useFormObj, name }) => {
  const { register, setValue, watch } = useFormObj;

  const currentValue = watch(name);

  useEffect(() => {
    register({ name });
  }, []);

  const handleClick = (obj) => {
    const { value } = obj || {};
    setValue(name, value);
  };

  return (
    <div style={{ marginTop: 16 }}>
      <GothamMedium className="m-0">{title}</GothamMedium>
      {data.map((item, index) => {
        const { iconActive, iconInactive, title, msg, value } = item;
        const isActive = value == currentValue;
        return (
          <div
            key={index}
            onClick={() => handleClick(item)}
            className="d-flex align-items-center pinkhover"
            style={{
              border: `1px solid ${colors.greyea}`,
              borderColor: isActive ? colors.darkTeal : colors.greyea,
              backgroundColor: isActive ? colors.lightPink : "",
              borderRadius: 12,
              marginTop: 16,
              padding: 12,
              paddingLeft: 15,
              paddingRight: 15,
            }}
          >
            <img
              src={isActive ? iconActive : iconInactive}
              style={{ marginRight: 18 }}
            />
            <div>
              <GothamRegular className="m-0 mb-1">{title}</GothamRegular>
              <GothamRegular
                className="m-0"
                style={{ color: colors.grey72, fontSize: 10 }}
              >
                {msg}
              </GothamRegular>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export const useRegisterField = (useFormObj, name) => {
  const { register, watch, setValue } = useFormObj;
  const currValue = watch(name);

  useEffect(() => register(name), []);

  return { currValue, setValue };
};

export const SelectionFormIcon = ({ useFormObj, options, name, label }) => {
  const { setValue } = useFormObj;

  const { currValue } = useRegisterField(useFormObj, name);
  const selectedObj = options.filter(({ value }) => currValue == value)[0];

  useEffect(() => {
    if (!currValue) return;
    setIcon(selectedObj.icon);
  }, [currValue]);

  const [icon, setIcon] = useState("");

  const handleChange = (values) => {
    const { value } = values;
    setValue(name, value);
  };

  const showIcon = icon && currValue;

  const ContainerStyle = {
    width: "100%",
    marginBottom: 16,
    fontSize: 14,
    paddingTop: !currValue ? 0 : 14,
    paddingLeft: !currValue ? 0 : 36,
  };

  const indicatorStyles = {
    marginTop: !currValue ? 0 : -12,
  };

  return (
    <>
      {showIcon && <ImgIconBank src={icon} />}
      {currValue && <SelectBankText>{label}</SelectBankText>}
      <CustomSelect
        containerStyle={ContainerStyle}
        options={options}
        placeholder={label}
        onChange={handleChange}
        indicatorStyle={indicatorStyles}
        value={selectedObj}
      />
    </>
  );
};

const ImgIconBank = styled.img`
  position: absolute;
  z-index: 99;
  left: 11px;
  margin-top: 10px;
  width: 26px;
  height: 26px;
`;

const SelectBankText = styled.p`
  position: absolute;
  font-family: "GothamBook";
  font-size: 12px;
  color: #727272;
  z-index: 99;
  left: 46px;
  margin-top: 3px;
`;

export const NakedSearchInput = ({
  onChange,
  placeholder = "Search Transactions",
  style,
  styleInput,
  woSearch,
  ...props
}) => (
  <div style={{ position: "relative", ...style }}>
    {!woSearch && (
      <Search
        style={{
          position: "absolute",
          left: 13,
          top: 12,
          color: "#C2C2C2",
        }}
      />
    )}
    <input
      type="text"
      onChange={(e) => onChange(e && e.target.value)}
      placeholder={placeholder}
      className="searchBatch"
      style={{
        height: 42,
        paddingTop: 10,
        width: "100%",
        marginBottom: 16,
        ...styleInput,
      }}
      {...props}
    />
  </div>
);

const ReachedLimit = () => {
  const { pathname, push } = useRouter();

  const isInviteUserPage = pathname === "/account/invite-users";

  const handleExplorePlan = () => {
    eventsTracker("explore_plan_from_adding_user");
    push({ pathname: "settings", query: { page: "subscription" } });
  };

  if (isInviteUserPage)
    return (
      <div className="w-100">
        <GothamRegular style={{ color: colors.grey6c }} className="p-2">
          Invite max. 4 users.
        </GothamRegular>
      </div>
    );

  return (
    <div className="w-100">
      <GothamRegular
        style={{ color: colors.grey6c, display: "inline" }}
        className="p-2"
      >
        You reached your user limit. Upgrade your plan and invite more employee.{" "}
      </GothamRegular>
      <GothamMedium
        style={{
          color: colors.pink,
          cursor: "pointer",
          display: "inline",
          marginLeft: "8px",
        }}
        onClick={handleExplorePlan}
      >
        Explore plan
      </GothamMedium>
    </div>
  );
};

const AdditionalUser = () => {
  return (
    <div className="w-100">
      <GothamRegular style={{ color: colors.grey6c }} className="p-2">
        You will be charged IDR 40,000 per additional user for the next payment.
        Press "Enter" to add user.
      </GothamRegular>
    </div>
  );
};

export const MultiSelectOptions = ({
  data,
  innerProps,
  selectProps,
  isError,
  isReachedLimit,
  additionalUser,
  isSuspended,
  msg,
}) => {
  if (isSuspended)
    return (
      <div className="w-100">
        <GothamRegular style={{ color: colors.grey6c }} className="p-2">
          Sorry, you can't assign a user while your account is suspended.
        </GothamRegular>
      </div>
    );
  if (isReachedLimit) return <ReachedLimit />;

  if (additionalUser) return <AdditionalUser />;

  if (isError)
    return (
      <div className="w-100">
        <GothamRegular style={{ color: colors.grey6c }} className="p-2">
          {msg}
        </GothamRegular>
      </div>
    );

  const { hideEmailOption } = selectProps;
  const {
    realLabel,
    label: labelRaw,
    email,
    rightLabel,
    rightmostLabel,
  } = data || [];

  const label = realLabel || labelRaw;
  const labelIsEmail = label === `${email} `;

  const Structure = ({ left, right }) => (
    <Row
      className="option mx-0 align-items-center"
      style={{
        padding: "8px 12px",
      }}
      {...innerProps}
    >
      <div>{left}</div>
      <div className="ml-2 mr-0 d-flex flex-grow-1 flex-column">
        {right}
        {Boolean(!labelIsEmail) && (
          <GothamRegular
            className="font12"
            style={{
              color: colors.grey33,
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
              maxWidth: 250,
            }}
          >
            {email}
          </GothamRegular>
        )}
      </div>
    </Row>
  );

  if (email)
    return (
      <Structure
        left={<NameAvatar is36>{labelIsEmail ? email : label}</NameAvatar>}
        right={
          <div
            className="d-flex"
            style={{
              justifyContent: rightLabel ? "flex-start" : "space-between",
            }}
          >
            <GothamBold
              className="font14"
              style={{
                color: colors.grey33,
                marginBottom: 0,
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
                maxWidth: 200,
              }}
            >
              {label}
            </GothamBold>
            {rightLabel ? (
              <div
                style={{
                  backgroundColor: colors.bluee1,
                  borderRadius: 4,
                  marginLeft: 4,
                }}
              >
                <GothamMedium
                  style={{
                    fontSize: 12,
                    color: colors.blue10,
                    padding: "0px 4px",
                  }}
                >
                  {rightLabel}
                </GothamMedium>
              </div>
            ) : rightmostLabel ? (
              <div
                style={{
                  marginLeft: 4,
                }}
              >
                <GothamRegular
                  style={{
                    fontSize: 12,
                    color: colors.grey6c,
                    padding: "0px 4px",
                  }}
                >
                  {rightmostLabel}
                </GothamRegular>
              </div>
            ) : (
              <div />
            )}
          </div>
        }
      />
    );

  if (hideEmailOption) return null;

  return (
    <Structure
      left={<img src={mailBlueIcon} />}
      right={
        <GothamRegular
          className="font14"
          style={{ color: colors.grey33, marginBottom: 0 }}
        >
          {label}
        </GothamRegular>
      }
    />
  );
};

export const RemoveLabelIcon = (props) => (
  <components.MultiValueRemove {...props}>
    <img src={xIconPink} style={{ cursor: "pointer" }} />
  </components.MultiValueRemove>
);

export const MultiValueLabel = (props) => {
  const { realLabel, label } = props?.data || {};
  const children = realLabel || label;
  return <components.MultiValueLabel {...props} children={children} />;
};

export const optionsFormatter = (options) => {
  const result = (options || []).map((item) => {
    const { label, email } = item;
    const newLabel = `${email} ${label}`;
    return { ...item, label: newLabel, realLabel: label };
  });

  return result;
};

export const NoOptionsMessage = (props) => {
  const { msg, isError, isInviteByEmail, isReachedLimit, isSuspended } =
    props || {};
  const { inputValue } = props?.selectProps || {};

  if (isReachedLimit) return <ReachedLimit />;

  if (isSuspended)
    return (
      <div className="w-100">
        <GothamRegular style={{ color: colors.grey6c }} className="p-2">
          Sorry, you can't assign a user while your account is suspended.
        </GothamRegular>
      </div>
    );

  if (isInviteByEmail)
    return (
      <div className="w-100">
        <GothamRegular style={{ color: colors.grey6c }} className="p-2">
          Let's invite your employee. Add their email address to invite them.
          Make sure to input a valid email address
        </GothamRegular>
      </div>
    );

  if (!inputValue)
    return (
      <div className="w-100">
        <GothamRegular style={{ color: colors.grey6c }} className="p-2">
          Hit "Enter" key after you type invited user's email
        </GothamRegular>
      </div>
    );

  if (isError)
    return (
      <div className="w-100">
        <GothamRegular style={{ color: colors.grey6c }} className="p-2">
          {msg}
        </GothamRegular>
      </div>
    );

  return (
    <div className="w-100">
      <GothamRegular style={{ color: colors.redE9 }} className="p-2">
        Please enter a valid email
      </GothamRegular>
    </div>
  );
};

export const MultiSelect = ({
  options,
  containerStyle,
  className = "",
  inputStyle,
  iconLeft,
  indicatorStyle,
  labelStyle,
  useFormObj,
  placeholder = "Select",
  name,
  hideEmail = false,
  onChange = () => {},
  data = [], // data is used so there is no more duplication
  noData,
  ...props
}) => {
  const { pathname } = useRouter();

  const {
    isSeedPlan,
    thereIsNoUsage,
    isGrowthPlanStrict,
    isLongTermPlanStrict,
    loadingSubscriptionUsage,
  } = useSubscriptionUsage();

  const { register, watch, setValue, required } = useFormObj;
  const getOptions = optionsFormatter(options);

  const canReachLimit = thereIsNoUsage;
  const isReachedLimit = (data ?? []).length >= 4;
  const isUnlimitedInvitePlan = isSeedPlan || isLongTermPlanStrict;

  const additionalUser = isGrowthPlanStrict;

  const isInviteUserPage = pathname === "/account/invite-users";

  const componentsDecider = () => {
    const defaultOptions = {
      Option: MultiSelectOptions,
      MultiValueRemove: RemoveLabelIcon,
      MultiValueLabel,
    };

    if (isInviteUserPage) {
      if (additionalUser) {
        return {
          ...defaultOptions,
          NoOptionsMessage: (props) => (
            <NoOptionsMessage {...props} isInviteByEmail />
          ),
          Option: (props) => (
            <MultiSelectOptions {...props} additionalUser={additionalUser} />
          ),
        };
      }

      if (isReachedLimit && canReachLimit && !isUnlimitedInvitePlan)
        return {
          ...defaultOptions,
          NoOptionsMessage: (props) => (
            <NoOptionsMessage {...props} isReachedLimit={isReachedLimit} />
          ),
          Option: (props) => (
            <MultiSelectOptions {...props} isReachedLimit={isReachedLimit} />
          ),
        };
    }

    if (noData) return { ...defaultOptions, NoOptionsMessage };
    return defaultOptions;
  };

  const components = componentsDecider();

  useEffect(() => {
    register({ name, required });
  }, [name, register]);

  const value = watch(name);

  // for the sake of hides email when user types

  // for some context. This code prevent this flow
  // add user (locally OR saved) => add user with the same email => yup you can do it
  const [text, setText] = useState("");
  const optionEmails = options.map(({ email }) => email);
  const dataEmails = data.map(({ email }) => email);
  const hideEmailOption =
    hideEmail || optionEmails.includes(text) || dataEmails.includes(text);
  // for the sake of hides email when user types

  const onCreateOption = (value) => {
    if (
      isInviteUserPage &&
      isReachedLimit &&
      canReachLimit &&
      !isUnlimitedInvitePlan
    )
      return;
    if (loadingSubscriptionUsage) return;

    return props.onCreateOption(value);
  };

  return (
    <CreatableSelect
      {...props}
      text={text}
      onInputChange={(e) => setText(e)}
      value={value}
      placeholder={placeholder}
      options={getOptions}
      isMulti
      className={className}
      components={components}
      hideEmailOption={hideEmailOption}
      onCreateOption={onCreateOption}
      styles={{
        container: (style) => ({
          ...style,
          width: "100%",
        }),
        multiValue: (style) => ({
          ...style,
          borderRadius: 2,
          backgroundColor: colors.bluee1,
        }),
        multiValueLabel: (style) => ({
          ...style,
          fontFamily: "GothamBold",
          color: colors.blue10,
          fontSize: 14,
        }),
        multiValueRemove: (style) => ({
          ...style,
          color: colors.pink,
          backgroundColor: "transparent",
          fontSize: 20,
          "&:hover": {
            backgroundColor: "transparent",
            color: colors.pink,
          },
        }),
        control: (style, { isFocused }) => ({
          ...style,
          borderRadius: 8,
          padding: "6px 8px",
          fontSize: 14,
          lineHeight: "20px",
          fontFamily: "GothamBook",
          border: `1px solid ${colors.greye6}`,
          boxShadow: '"0 0 0 0 black"',
          ...containerStyle,
          "&:hover": {
            border: `1px solid ${colors.greye6}`,
          },
        }),
        input: (style) => ({
          ...style,
          fontFamily: "GothamBook",
          ...inputStyle,
          ...iconLeft,
        }),
        option: (style, { isSelected, isFocused }) => ({
          ...style,
          fontSize: 16,
          fontFamily: "GothamBook",
          backgroundColor: isSelected
            ? colors.pink
            : isFocused
            ? colors.lightPink
            : "transparent",
          boxShadow: "none",
        }),
        menu: (style) => ({
          ...style,
          borderRadius: 8,
          border: "none",
          boxShadow: "none",
          filter: "drop-shadow(0px 8px 20px rgba(88, 88, 88, 0.1))",
        }),
        menuList: (style) => ({
          ...style,
          borderRadius: 8,
          paddingTop: 0,
          paddingBottom: 0,
        }),
        indicatorSeparator: () => ({}),
        dropdownIndicator: (style) => ({
          ...style,
          ...indicatorStyle,
          display: "none",
        }),
        clearIndicator: (style) => ({
          ...style,
          display: "none",
        }),
        placeholder: (style) => ({
          ...style,
          color: colors.grey33,
        }),
      }}
      onChange={(array) => {
        const result = array.map((item) => {
          const { realLabel, label } = item || {};
          return { ...item, label: realLabel || label };
        });
        onChange(result);
      }}
    />
  );
};

export const CustomLabeledTextArea = ({
  name = "",
  label = "",
  style,
  placeholder = "Placeholder",
  useFormObj,
  className = "",
  defaultValue,
  disabled,
  maxLength = 0,
  error: errorProps,
  woShowCounter = false,
  rows = 2,
  maxRows = 4,
  ...props
}) => {
  const [charCount, setCharCount] = useState(0);
  const [error, setError] = useState(false);
  const { register, setValue } = useFormObj;

  const handleCharCount = (e) => {
    const charCount = e.target.value.length;
    setCharCount(charCount);
    if (charCount > maxLength) {
      setError(`${titleCase(name)} max length is ${maxLength}`);
    } else {
      setError(false);
    }
  };

  const handleTextChange = (e) => {
    setValue(name, e.target.value.slice(0, maxLength));
  };

  useEffect(() => register({ name }), []);

  useEffect(() => {
    if (!defaultValue) return;
    setValue(name, defaultValue);
  }, [defaultValue]);

  return (
    <div style={{ position: "relative", ...style }}>
      <CustomTextField
        onChange={(e) => {
          handleCharCount(e);
          handleTextChange(e);
        }}
        useFormObj={useFormObj}
        disabled={disabled}
        defaultValue={defaultValue}
        name={name}
        label={label}
        className={className}
        placeholder={placeholder}
        error={errorProps || error}
        multiline
        rows={rows}
        maxRows={maxRows}
        style={{
          resize: "none",
          height: "100%",
          borderRadius: 8,
          overflow: "hidden",
          ...style,
        }}
        {...props}
      />
      {!woShowCounter && (
        <div
          style={{
            textAlign: "right",
            position: "absolute",
            bottom: 12,
            right: 8,
            pointerEvents: "none",
          }}
        >
          <GothamRegular style={{ color: colors.grey72, fontSize: 10 }}>
            {charCount > maxLength ? maxLength : charCount}/{maxLength}
          </GothamRegular>
        </div>
      )}
    </div>
  );
};
