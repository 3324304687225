import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { colors } from "../../../assets/colors";
import arrowFoward from "../../../assets/images/arrow-foward-white.svg";
import checkIconWhite from "../../../assets/images/check-icon-white.svg";
import payrollIcons from "../../../assets/images/payroll-icon-40.svg";
import plus from "../../../assets/images/plus-circle.svg";
import xIconPink from "../../../assets/images/x-icon-pink.svg";
import downloadIcon from "../../../assets/images/download-history-white.svg";
import { CustomButton, OutlineButton } from "../../../components/Buttons";
import snakeCase from "snake-case";
import {
  BackHeader,
  CloseHeader,
  MiddleWrapper,
  RightCardModalAtomic,
} from "../../../components/CardModal";
import { LeftRightList } from "../../../components/Lists";
import { useModalHook } from "../../../components/Modals";
import { booleanState, StatusLabel } from "../../../components/Status";
import { GothamMedium, GothamRegular } from "../../../components/Text";
import {
  customDateFormatter,
  formatCurrency,
  formatCurrencyNoDecimal,
} from "../../../components/tools";
import { getUserRole, useGetAuth } from "../../../contexts/AuthContext";
import { ToasterHook } from "../../../contexts/ToasterContext";
import { arrayDecider } from "../../../pageComponents/payrollCreateComponents/loop/success/decider";
import {
  ReasonNotes,
  StatusPayrollLine,
} from "../../../pageComponents/payrollCreateComponents/loop/success/status";
import { fetch, useDownload, useMutation } from "../../../tools/api";
import { ActivityDetailsHeader } from "../activityModalComponents";
import { CenterendIconLocalTransfer } from "../localDisbursementBatchModal/transactionModal";
import { WingsComponents } from "../wingsComponents";
import { PayrollPinModal } from "./pinModal";
import { PayrollRejectModal } from "./rejectModal";
import { crossBorderSingleWorkflowComponents } from "../crossBorderSingleModal/components";
import SuspendModal from "../../../components/SubscriptionComponents/SuspendModal/SuspendModal";
import useSubscriptionUsage from "../../../tools/hooks/useSubscriptionUsage";
import { CrossBorderSingleStatusLine } from "../crossBorderSingleModal/status";
import { getUserRolePayroll } from "./logic";

export const batchFeeArrayDecider = ({
  totalFee = 0,
  totalTrx = 0,
  defaultFee = 0,
}) => {
  const allTrxFeeAreFree = Number(totalFee) === 0;
  const someTrxFeeAreFree =
    Number(totalFee) < Number(defaultFee) * totalTrx && !allTrxFeeAreFree;
  const allTrxFeeAreNotFree =
    Number(totalFee) === Number(defaultFee) * totalTrx;

  if (allTrxFeeAreNotFree)
    return [
      {
        left: (
          <>
            <img src={plus} className="mr-2" />
            Total fee{" "}
            {defaultFee > 0
              ? `= IDR ${formatCurrencyNoDecimal(defaultFee)} X ${totalTrx}`
              : ""}
          </>
        ),
        right: `IDR ${formatCurrencyNoDecimal(totalFee)}`,
        styleLeft: { width: "75%" },
        styleRight: { width: "25%" },
      },
    ];

  if (someTrxFeeAreFree) {
    const totalFreeFeeAmount =
      Number(defaultFee) * Number(totalTrx) - Number(totalFee);
    const totalFreeFeeTrx = totalFreeFeeAmount / Number(defaultFee);
    const totalPaidFeeTrx = Number(totalTrx) - totalFreeFeeTrx;

    const shouldBePaying = Number(defaultFee) * Number(totalFreeFeeTrx);

    return [
      {
        left: (
          <GothamMedium
            style={{
              color: colors.grey25,
            }}
          >
            Transfez fee
          </GothamMedium>
        ),
        right: (
          <GothamMedium
            style={{
              color: colors.grey25,
            }}
          >
            {`IDR ${formatCurrencyNoDecimal(totalFee)}`}
          </GothamMedium>
        ),
        styleLeft: { width: "75%" },
        styleRight: { width: "25%" },
      },
      {
        left: (
          <GothamRegular
            style={{
              color: colors.grey6c,
            }}
            className="ml-4"
          >
            {totalFreeFeeTrx} Transaction{totalFreeFeeTrx > 1 ? "s" : ""}
          </GothamRegular>
        ),
        right: (
          <GothamMedium
            style={{
              color: colors.pink,
            }}
          >
            Free
            {defaultFee > 0 ? (
              <span
                style={{
                  fontFamily: "GothamBook",
                  color: colors.greyd1,
                  textDecoration: "line-through",
                  marginLeft: "4px",
                }}
              >
                IDR {formatCurrencyNoDecimal(Number(shouldBePaying))}
              </span>
            ) : (
              ""
            )}
          </GothamMedium>
        ),
        styleLeft: { width: "60%" },
        styleRight: { width: "40%" },
      },
      {
        left: (
          <GothamRegular
            style={{
              color: colors.grey6c,
            }}
            className="ml-4"
          >
            {totalPaidFeeTrx} Transaction{totalPaidFeeTrx > 1 ? "s" : ""}
          </GothamRegular>
        ),
        right: (
          <GothamRegular
            style={{
              color: colors.grey6c,
            }}
          >
            IDR {formatCurrencyNoDecimal(Number(totalFee))}
          </GothamRegular>
        ),
        styleLeft: { width: "75%" },
        styleRight: { width: "25%" },
      },
    ];
  }

  if (allTrxFeeAreFree) {
    const shouldBePaying = Number(defaultFee) * Number(totalTrx);

    return [
      {
        left: (
          <>
            <img src={plus} className="mr-2" />
            Total fee = IDR {formatCurrencyNoDecimal(Number(defaultFee))} x{" "}
            {Number(totalTrx)}
          </>
        ),
        right: (
          <GothamMedium
            style={{
              color: colors.pink,
            }}
          >
            Free{" "}
            {defaultFee > 0 ? (
              <span
                style={{
                  fontFamily: "GothamBook",
                  color: colors.greyd1,
                  textDecoration: "line-through",
                  marginLeft: "2px",
                }}
              >
                IDR {formatCurrencyNoDecimal(Number(shouldBePaying))}
              </span>
            ) : (
              ""
            )}
          </GothamMedium>
        ),
        styleLeft: { width: "70%" },
        styleRight: { width: "30%" },
      },
    ];
  }

  return [
    {
      left: (
        <>
          <img src={plus} className="mr-2" />
          Total fee = IDR {formatCurrencyNoDecimal(defaultFee)} X {totalTrx}
        </>
      ),
      right: `IDR ${formatCurrencyNoDecimal(totalFee)}`,
      styleLeft: { width: "75%" },
      styleRight: { width: "25%" },
    },
  ];
};

export const singleFeeArrayDecider = ({ fee = 0, defaultFee = 0 }) => {
  const isFreeFee = Number(fee) <= 0;
  const defaultFeeToNumber = Number(defaultFee);

  if (isFreeFee)
    return [
      {
        left: (
          <>
            <img src={plus} className="mr-2" />
            Transfer fee
          </>
        ),
        right: (
          <GothamMedium
            style={{
              color: colors.pink,
            }}
          >
            Free{" "}
            {defaultFeeToNumber > 0 ? (
              <span
                style={{
                  fontFamily: "GothamBook",
                  color: colors.greyd1,
                  textDecoration: "line-through",
                  marginLeft: "2px",
                }}
              >
                IDR {formatCurrencyNoDecimal(Number(defaultFeeToNumber))}
              </span>
            ) : (
              ""
            )}
          </GothamMedium>
        ),
      },
    ];

  return [
    {
      left: (
        <>
          <img src={plus} className="mr-2" />
          Transfer fee
        </>
      ),
      right: `IDR ${formatCurrencyNoDecimal(fee)}`,
    },
  ];
};

export const PayrollModal = ({
  isOpen,
  toggle,
  data: dataFromModal,
  afterSuccess: afterSuccessProps,
}) => {
  const { originator_id: idFromModalRaw, id: idData } = dataFromModal || {};
  const idFromModal = Number(idFromModalRaw);
  const { query, push } = useRouter();
  const { user } = useGetAuth();
  const { id: idQuery, payroll_modal_search_box } = query;
  const id = idQuery || idFromModalRaw || idData;
  const {
    mainBalance,
    id: idUserLogin,
    name: nameUserLogin,
    email: emailUserLogin,
  } = user || {};
  const { balance } = mainBalance || {};
  const { errorToaster } = ToasterHook();
  const [wingsSelectedItem, setWingsSelectedItem] = useState(false);

  const { isSuspended, loadingSubscriptionUsage } = useSubscriptionUsage();

  const { isOpen: isOpenSuspendModal, toggle: toggleSuspendModal } =
    useModalHook();

  const {
    created_at: createdAtSingle,
    reference_id: referenceIdSingle,
    rightTop: TotalAmountSingle,
    status: statusSingle,
    amount: amountSingle,
    state: stateSingle,
    fee: feeSingle,
    id: idSelected,
    leftTop: recipientNameSelected,
  } = wingsSelectedItem || {};

  const isPendingSingle = statusSingle === "pending";

  const { isAdminOrSuperAdmin, isBookKeeper } = getUserRole();

  const { data, refetch, loading } = fetch({
    url: `/payrolls/${idFromModal || id}`,
    formatter: (res) => res?.data || {},
    woInit: true,
  });

  const { data: updates, refetch: refetchUpdates } = fetch({
    url: `/approval_tasks/${id}/index_payroll`,
    woInit: true,
  });

  const {
    taskId,
    isDrafter,
    isApprover,
    isApproverPayroll,
    isCurrentDrafter,
    isApproverNeedApprove,
    isApproverDoneApprove,
    isOldTransaction,
    isApprovedByAll,
    isReleaserPayroll,
  } = getUserRolePayroll({
    data,
    updates,
    user,
  });

  const { batch, state_summary = [] } = data || {};
  const { isCancelled } = booleanState(batch?.state);
  const {
    isRejectedByApproval,
    isDraft,
    isWaitingForRelease,
    isOnProgress,
    isCompleted,
    isCancelledPayroll,
    isFailed,
  } = booleanState(data?.state);

  const canSeeWings = isCurrentDrafter;
  const showSummary =
    isCompleted || isOnProgress || isCancelledPayroll || isFailed;

  useEffect(() => {
    if (!id && !idFromModal) return;
    refetch();
    refetchUpdates();
  }, [id, idFromModal]);

  const { state, rejection_note } = data || {};
  const {
    id: batch_id,
    batch_number,
    created_at,
    name,
    total_amount: total_amountRaw,
    // state,
    local_transactions = [],
    total_fee,
  } = data?.batch || {};

  const stateForStatusLabel = Boolean(wingsSelectedItem)
    ? isPendingSingle
      ? null
      : stateSingle
    : state;

  const individualDefaultFee = Number(local_transactions[0]?.default_fee);
  const total_amount = Number(total_amountRaw) + Number(total_fee);
  const isNotEnoughBalance = balance < total_amount;

  const count = local_transactions.length;
  const countMoreThanOne = count > 1 ? "Transactions" : "Transaction";

  const leftRightArray = [
    {
      left: "Date",
      right: customDateFormatter(created_at),
      styleRight: { width: 240 },
    },
    {
      left: "Reference",
      right: batch_number,
      styleLeft: { width: 240 },
    },
    { spacer: true },
    {
      left: "Count of transaction(s)",
      right: `${count} ${countMoreThanOne}`,
      styleLeft: { width: 240 },
    },
    {
      left: "Amount",
      right: `IDR ${formatCurrencyNoDecimal(total_amountRaw)}`,
      styleLeft: { width: 240 },
    },
    ...batchFeeArrayDecider({
      defaultFee: individualDefaultFee,
      totalFee: total_fee,
      totalTrx: local_transactions.length,
    }),
    {
      left: "Total",
      right: `IDR ${formatCurrencyNoDecimal(total_amount)}`,
      rightBold: true,
    },
  ];

  const leftRightSingle = [
    {
      left: "Date",
      right: customDateFormatter(createdAtSingle),
      styleLeft: { width: 240 },
    },
    {
      left: "Reference",
      right: referenceIdSingle,
      styleLeft: { width: 240 },
    },
    { spacer: true },
    {
      left: "Recipient gets",
      right: `IDR ${formatCurrencyNoDecimal(amountSingle)}`,
      styleLeft: { width: 240 },
    },
    ...singleFeeArrayDecider({
      fee: feeSingle,
      defaultFee: individualDefaultFee,
    }),
    {
      left: "You pay",
      right: TotalAmountSingle,
      styleLeft: { width: 240 },
    },
  ];

  const headerData = {
    type: name,
    icon: false,
    title: `IDR ${formatCurrency(total_amount)}`,
  };

  const array = local_transactions.map((item) => {
    const { local_recipient, state, amount, fee } = item || {};
    const { name: leftTop, payroll_recipient } = local_recipient || {};
    const { department_name: leftBot, salary } = payroll_recipient || {};
    const right = `IDR ${formatCurrency(salary)}`;
    const totalAmount = `IDR ${formatCurrencyNoDecimal(
      Number(amount) + Number(fee)
    )}`;
    const result = {
      leftTop,
      leftBot,
      right,
      rightTop: totalAmount,
      status: state,
      state,
      ...item,
    };
    return result;
  });

  const handleSelectedWingsItem = (values) => {
    setWingsSelectedItem(values);
  };

  const [middleHeight, setMiddleHeight] = useState(0);

  const [rejection, setRejection] = useState(false);
  const toggleRejection = () => setRejection(false);

  const { isOpen: isOpenPin, toggle: togglePin } = useModalHook();

  const afterSuccess = async () => {
    await push({
      pathname: `/payroll/create/${id}`,
      query: { state: "success" },
    });
    afterSuccessProps && afterSuccessProps();
    toggleRejection();
  };

  const {
    buttons: buttonsWorkflow,
    isRejection,
    rejectModal,
  } = crossBorderSingleWorkflowComponents({
    transaction: data,
    cancelUrlFunc: (id) => `/payrolls/${id}/cancel`,
    afterSuccess,
  });

  const { mutation: mutationApprove, loading: loadingApprove } = useMutation({
    url: `/approval_tasks/${taskId}/approve`,
    afterSuccess,
  });

  const { mutation: mutationReject, loading: loadingReject } = useMutation({
    url: `/approval_tasks/${taskId}/reject`,
    afterSuccess,
  });

  const handleError = (error) => {
    const errorObj = error?.response?.data?.error || {};
    errorToaster("Error", errorObj);
  };

  const { mutation: mutationReleaseFund, loading: loadingReleaseFund } =
    useMutation({
      url: `/payrolls/${id}/payment`,
      afterSuccess,
      handleError,
      withError: false,
    });

  const { mutation: mutationCancel, loading: loadingCancel } = useMutation({
    url: `/payrolls/${id}/cancel`,
    afterSuccess,
  });

  const onSubmit = (values, type) => {
    const isCancel = type === "cancel";
    const isReject = type === "reject";
    const isPin = type === "pin";
    const { reason } = values || {};

    if (isReject) return mutationReject({ rejection_note: reason });
    if (isCancel) return mutationCancel({ rejection_note: reason });
    if (isPin) return mutationApprove(values);
  };

  const loadingMulti = loadingReject || loadingApprove || loadingCancel;

  const statusArray = arrayDecider({ data });
  const statusArraySingle = arrayDecider({
    data: wingsSelectedItem,
    isSingle: true,
  });

  const numberFunc = (stateCheck) =>
    (local_transactions || []).filter(({ state }) => state == stateCheck)
      .length;

  const completed = numberFunc("completed");
  const failed = numberFunc("failed") + numberFunc("declined");
  const onProgress = numberFunc("on_progress") + numberFunc("pending");

  const overviewArray = [
    { color: colors.green06, number: completed, title: "Success" },
    {
      color: colors.blue2C,
      number: onProgress,
      title: "In Progress",
    },
    {
      color: colors.redE7,
      number: failed,
      title: "Failed",
    },
  ];

  const defaultStyle = {
    height: 32,
    fontSize: 14,
  };

  const handlePay = () => {
    if (isSuspended) return toggleSuspendModal();

    if (isNotEnoughBalance) {
      return errorToaster("Error", "Insufficient balance");
    }
    togglePin();
  };

  const CancelButton = () => (
    <OutlineButton
      style={{
        backgroundColor: "white",
        padding: "0px 24px",
        ...defaultStyle,
      }}
      onClick={() => {
        if (isSuspended) return toggleSuspendModal();
        setRejection("cancellation");
      }}
      leftIcon={<img src={xIconPink} className="mr-1" />}
      isLoading={loadingSubscriptionUsage}
    >
      Cancel Transaction
    </OutlineButton>
  );

  const PayButton = () => (
    <div className="d-flex justify-content-between" style={{ gap: 12 }}>
      <OutlineButton
        style={{
          width: "100%",
          backgroundColor: "white",
          ...defaultStyle,
        }}
        onClick={() => {
          if (isSuspended) return toggleSuspendModal();
          setRejection("rejection");
        }}
        leftIcon={<img src={xIconPink} className="mr-1" />}
        isLoading={loadingSubscriptionUsage}
      >
        Reject
      </OutlineButton>
      <CustomButton
        style={{ width: "100%", ...defaultStyle }}
        onClick={handlePay}
        isLoading={loadingReleaseFund || loadingSubscriptionUsage}
        leftIcon={<img src={checkIconWhite} className="mr-1" />}
      >
        Pay
      </CustomButton>
    </div>
  );

  const DownloadBulkButton = () => (
    <div className="d-flex justify-content-center">
      <CustomButton
        style={{ ...defaultStyle }}
        onClick={() =>
          handleDownload({
            url: `/local_transaction_batches/${batch_id}.pdf`,
            fileName: `Transfez-Payroll-${name}.pdf`,
          })
        }
        isLoading={downloadLoading}
        leftIcon={<img src={downloadIcon} className="mr-2" />}
      >
        Download Receipt
      </CustomButton>
    </div>
  );

  const ContinueTransaction = () => {
    const handleClick = () => {
      if (isSuspended) return toggleSuspendModal();

      push({ pathname: `/payroll/create/${id}` });
    };

    return (
      <div className="d-flex justify-content-center">
        <CustomButton
          style={{ ...defaultStyle }}
          onClick={handleClick}
          rightIcon={<img src={arrowFoward} className="ml-1" />}
          isLoading={loadingSubscriptionUsage}
        >
          Continue Transaction
        </CustomButton>
      </div>
    );
  };

  const { handleDownload, loading: downloadLoading } = useDownload();

  const showDownload = isApprover || isBookKeeper || isDrafter;

  const buttonsWings = () => {
    if (!Boolean(wingsSelectedItem)) return null;

    const isCompletedSingle = stateSingle === "completed";

    if (!isCompletedSingle) return null;

    if (!showDownload) return null;

    return (
      <div className="d-flex justify-content-center">
        <CustomButton
          style={{ ...defaultStyle }}
          onClick={() =>
            handleDownload({
              url: `/payrolls/${id}/transactions/${idSelected}.pdf`,
              fileName: `Transfez-Payroll-${name}-${snakeCase(
                recipientNameSelected
              )}.pdf`,
            })
          }
          isLoading={downloadLoading}
          leftIcon={<img src={downloadIcon} className="mr-2" />}
        >
          Download Receipt
        </CustomButton>
      </div>
    );
  };

  const buttons = () => {
    if (isRejectedByApproval || isCancelled || isOnProgress) return null;

    if (isCompleted) {
      if (!showDownload) return null;
      return <DownloadBulkButton />;
    }

    if (isApprover) {
      // if (isApproverDoneApprove) return <CancelButton />;
      if (isApproverNeedApprove) {
        return (
          <div className="d-flex justify-content-between" style={{ gap: 12 }}>
            <OutlineButton
              onClick={() => {
                if (isSuspended) return toggleSuspendModal;
                setRejection("rejection");
              }}
              style={{
                width: "100%",
                backgroundColor: "white",
                ...defaultStyle,
              }}
              leftIcon={<img src={xIconPink} className="mr-1" />}
              isLoading={loadingSubscriptionUsage}
            >
              Reject
            </OutlineButton>
            <CustomButton
              style={{ width: "100%", ...defaultStyle }}
              onClick={() => {
                if (isSuspended) return toggleSuspendModal();
                togglePin();
              }}
              loading={loadingApprove}
              leftIcon={<img src={checkIconWhite} className="mr-1" />}
              isLoading={loadingSubscriptionUsage}
            >
              Approve
            </CustomButton>
          </div>
        );
      }
    }

    if (isDrafter) {
      if (isDraft) return <ContinueTransaction />;
      if (!isCompleted) return <CancelButton />;
      return null;
    }

    return null;
  };

  const buttonsNew = () => {
    if (isRejectedByApproval || isCancelled || isOnProgress || isFailed)
      return null;

    if (isCompleted) {
      if (!showDownload) return null;
      return <DownloadBulkButton />;
    }

    if (isDraft && isDrafter && isCurrentDrafter)
      return <ContinueTransaction />;

    return buttonsWorkflow();
  };

  const buttonRender = () => {
    if (!!wingsSelectedItem) return buttonsWings();
    if (isOldTransaction) {
      if (isApprovedByAll) return buttonsNew();
      return buttons();
    }
    return buttonsNew();
  };

  return (
    <>
      <RightCardModalAtomic
        loading={loading}
        isOpen={isOpen}
        isBothToggle={true}
        isWhite={Boolean(wingsSelectedItem)}
        idWings={idSelected}
        hideModal={Boolean(rejection) || isOpenPin || isRejection}
        header={
          <ActivityDetailsHeader
            toggle={toggle}
            data={headerData}
            middleHeight={middleHeight}
            isWhite={Boolean(wingsSelectedItem)}
            close={
              Boolean(wingsSelectedItem) && (
                <>
                  <BackHeader
                    color={colors.grey33}
                    isMedium
                    text={"Payroll Details"}
                    toggle={() => setWingsSelectedItem(false)}
                  />
                  <CloseHeader color={colors.grey33} isMedium toggle={toggle} />
                </>
              )
            }
          />
        }
        middleHeight={middleHeight}
        middle={
          <MiddleWrapper setHeight={setMiddleHeight} height={middleHeight}>
            <CenterendIconLocalTransfer icon={payrollIcons} />
          </MiddleWrapper>
        }
        paddingTop={10}
        wingComponent={
          canSeeWings ? (
            <WingsComponents
              data={array}
              setSelectedWingsItem={handleSelectedWingsItem}
              selectedWingsItem={wingsSelectedItem}
              isShowSummary={showSummary}
              overviewRaw={overviewArray}
              type={"payroll"}
            />
          ) : null
        }
        body={
          <div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <StatusLabel status={stateForStatusLabel} />
              <div className="mt-3 w-100 text-center">{buttonRender()}</div>
            </div>
            <div style={{ marginTop: 26 }}>
              <LeftRightList
                array={
                  Boolean(wingsSelectedItem) ? leftRightSingle : leftRightArray
                }
              />
            </div>
            {!isDraft && (
              <GothamMedium className="font16">Updates</GothamMedium>
            )}
            {!!wingsSelectedItem || isOldTransaction ? (
              <StatusPayrollLine
                array={isOldTransaction ? statusArray : statusArraySingle}
                style={{ marginTop: 20 }}
                isModal
              />
            ) : (
              <CrossBorderSingleStatusLine transaction={data} isModal />
            )}
            {/* {rejection_note && !Boolean(wingsSelectedItem) && (
              <ReasonNotes text={rejection_note} />
            )} */}
          </div>
        }
      />
      {rejectModal()}
      <PayrollRejectModal
        text={rejection || "rejection"}
        loading={loadingMulti}
        isOpen={Boolean(rejection)}
        toggle={toggleRejection}
        onSubmit={onSubmit}
      />
      <PayrollPinModal
        isOpen={isOpenPin}
        toggle={togglePin}
        onSubmit={onSubmit}
        totalAmount={total_amount}
        buttonText={isApprover && "Continue"}
        loading={loadingReleaseFund}
        isEmployeeAllowed
      />
      <SuspendModal
        state="suspended"
        isOpen={isOpenSuspendModal}
        toggle={toggleSuspendModal}
      />
    </>
  );
};
