import * as Sentry from "@sentry/nextjs";
import { filter, get, isEmpty, pickBy } from "lodash";
import { useRouter } from "next/router";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useModalHook, WelcomeModal } from "../components/Modals";
import { booleanState } from "../components/Status";
import { isProduction, removeDuplicates } from "../components/tools";
import {
  accountPathnames,
  activitiesORTransactionsPathnames,
  canBeAccessedAllTheTimePathnames,
  createTransactionPathnames,
  // recipientPathnames,
  userPathnames,
  VAPathnames,
} from "../constants/pathnames";
import { apiBusiness, encryptToken, fetch } from "../tools/api";
import { logoutEvents, setUserEvents } from "../universalFunctions/events";
import { usePageRedirectMaster } from "./logics/authContext/pageRedirect";
import { ToasterHook } from "./ToasterContext";
import StartOnboardingModal from "../modals/StartOnboardingModal";
import { noCase } from "change-case";

export const userStatusBoolean = (status) => {
  const isInReview = status == "in_review";
  const isPending = status == "pending";
  const isNotSubmitted = status == "not_submitted";
  const isWaitingVerification = status == "waiting_verification";
  const showSubmitVerificationButton = isNotSubmitted || isPending;
  const isCompleted =
    !isPending && !isInReview && !isNotSubmitted && !isWaitingVerification;
  return {
    isCompleted,
    isInReview,
    isPending,
    isNotSubmitted,
    isWaitingVerification,
    showSubmitVerificationButton,
    status,
  };
};

export const localUserStatusBoolean = (user) => {
  const status = get(user, "partner.document_state", "");
  const booleans = userStatusBoolean(status);
  return booleans;
};

export const AuthContext = createContext({});

export const defaultButtons = {
  canViewAccount: false,
  canViewActivity: false,
  canSendMoney: false,
  canViewRecipients: false,
  canSeeVA: false,
  canInviteUser: false,
  canCreateCard: false,
  canUpdateCard: false,
  canBlockCard: false,
  canFreezeCard: false,
  canUnfreezeCard: false,
  canUnmaskCard: false,
  canViewAllCards: false,
};

export const defaultButtonsReversed = {
  canViewAccount: true,
  canViewActivity: true,
  canSendMoney: true,
  canViewRecipients: true,
  canSeeVA: true,
  canInviteUser: true,
  canCreateCard: true,
  canUpdateCard: true,
  canBlockCard: true,
  canFreezeCard: true,
  canUnfreezeCard: true,
  canUnmaskCard: true,
  canViewAllCards: true,
};

const userFormatter = (user) => {
  const {
    all_permissions = [],
    business_role = "",
    pin = "",
    roles = [],
    email,
    id,
  } = user || {};

  const isAdminStrict = Boolean(
    roles.filter(({ name }) => {
      const result = ["partner_admin", "partner_super_admin"].includes(name);
      return result;
    }).length
  );

  const filterer = (roleName) =>
    Boolean(roles.filter(({ name }) => name.includes(roleName)).length);

  const isBookKeeper = filterer("viewer");

  const isPayrollApprover = filterer("payroll_approver");

  const isDrafterCard = filterer("card_drafter");

  const {
    payroll_active,
    hide_beta_feature = [],
    all_flags = [],
    invoice_active,
    reimbursement_active,
    id: partner_id,
    card_business,
  } = user?.partner || {};

  const permissionsArray = all_permissions
    .map(({ all_action_types, module_code }) => {
      const array = all_action_types.map((item) => ({
        ...item,
        module_code,
      }));
      return array;
    })
    .flat(1);

  let buttonBooleans = {};

  const isDurianPayDecider = () => {
    if (!email.includes("@durianpay")) return false;
    if (partner_id == 76) return false;
    return true;
  };

  const isDurianPay = isDurianPayDecider();
  const isAPIOnly = email.includes("sandboxdemo@disbursement.qa.team");

  const canViewCards = true;
  const canViewAllCards = canViewCards && true;
  const canCreateCard = card_business
    ? canViewCards && isDrafterCard
    : canViewCards;

  const canCreatePin = !pin?.length;
  const canViewReport = isBookKeeper || isAdminStrict;
  const canViewEWallet = all_flags.includes("E_WALLET_ACTIVE") || isAdminStrict;
  const canViewApiDisbursement = all_flags.includes(
    "ACTIVITY_API_DISBURSEMENT"
  );
  const canViewInvoicePayment = invoice_active || isAdminStrict;
  const canViewReimbursement = reimbursement_active || isAdminStrict;

  const canViewWorkflow = isAdminStrict;

  const checkerOnlyModuleCode = ({ module_code_params }) =>
    !!all_permissions.filter(
      ({ module_code }) => module_code === module_code_params
    )[0];

  const canCustomReceipt = checkerOnlyModuleCode({
    module_code_params: "CUSTOM_PREFERENCE",
  });

  const dynamicPathnames = permissionsArray
    .map(({ module_code, action, model_name }) => {
      // NOTES: this code has duality function.
      // 1. builds buttonBooleans so it can be used anywhere
      // 2. returns pathnames so it can check if user is in the right path or not
      // so for e.g you want to change `canViewPayroll`
      // and you do it like this: `const canViewPayroll = isAdmin`
      // the result is if you are the admin, you can ONLY ACCESS `/payroll` and cant go anywhere coz u stopped the logic tree
      // BUT if you can somehow turn it to FALSE or MODULE it will work just fine, cause the tree logic will keep running

      // In a nutshell: Please just use MODULES (booleanChecker) in this code OR just return false e.g: `canViewActivityFunc`

      const booleanChecker = (
        module_code_params,
        action_params,
        model_name_params
      ) =>
        noCase(module_code) == noCase(module_code_params) &&
        noCase(action) == noCase(action_params) &&
        noCase(model_name) == noCase(model_name_params);

      const canViewActivityFunc = () => {
        // if (isPayrollApprover && !isAdminStrict) return false;

        return (
          booleanChecker("ACTIVITY", "view_all_activities", "Job") ||
          booleanChecker("ACTIVITY", "view_transactions", "BusinessTransaction")
        );
      };

      const canViewActivity = canViewActivityFunc();

      const canSendMoney = booleanChecker(
        "SEND_MONEY",
        "initiate_transaction",
        "BusinessTransaction"
      );
      const canViewRecipients = booleanChecker(
        "SEND_MONEY",
        "view_recipients",
        "UserRecipient"
      );
      const canSeeVA = false;
      // booleanChecker("COLLECT_MONEY", "view_va", "User");

      const canSeeUsers = booleanChecker("USER_MANAGEMENT", "update", "User");
      const canInviteUser = booleanChecker("USER_MANAGEMENT", "invite", "User");
      const canViewAccount = booleanChecker(
        "WALLET_BALANCE",
        "view_balance",
        "User"
      );

      // const canCreateCard = booleanChecker("CARD", "issue_card", "UserCard");
      const canUpdateCard = booleanChecker("CARD", "assign_limit", "UserCard");
      const canBlockCard = booleanChecker("CARD", "block", "UserCard");
      const canFreezeCard = booleanChecker("CARD", "freeze", "UserCard");
      const canUnfreezeCard = booleanChecker("CARD", "unblock", "UserCard");
      const canUnmaskCard =
        booleanChecker("CARD", "unmasked_card", "UserCard") ||
        booleanChecker("CARD", "unmask_details", "UserCard");
      const canViewCardsExpenses = booleanChecker(
        "CARD",
        "view_expenses",
        "UserCard"
      );

      const isAdmin =
        isAdminStrict || String(business_role).includes("approver");

      const canViewMainBalance =
        isAdminStrict &&
        booleanChecker("WALLET_BALANCE", "view_balance", "User");

      const canTopup = booleanChecker(
        "WALLET_BALANCE",
        "topup",
        "TransactionActivity"
      );
      const canWithdraw = booleanChecker(
        "WALLET_BALANCE",
        "topup",
        "TransactionActivity"
      );
      const canAllocate = booleanChecker(
        "WALLET_BALANCE",
        "allocate_fund",
        "TransactionActivity"
      );
      const canViewMainBalanceHistory =
        isAdminStrict &&
        booleanChecker(
          "WALLET_BALANCE",
          "balance_history",
          "TransactionActivity"
        );
      const canViewCardBalance = booleanChecker(
        "MULTICURRENCY_BALANCE",
        "view_balance",
        "User"
      );
      const canReallocate = booleanChecker(
        "MULTICURRENCY_BALANCE",
        "reallocate",
        "TransactionActivity"
      );
      const canViewCardBalanceHistory = booleanChecker(
        "MULTICURRENCY_BALANCE",
        "balance_history",
        "TransactionActivity"
      );

      const viewPayrollDecider = () => {
        const canViewPayroll = booleanChecker(
          "PAYROLL",
          "view_payroll",
          "Payroll"
        );

        if (isAdminStrict || payroll_active) return canViewPayroll;
        return false;
      };

      const canShowPayroll = !hide_beta_feature.includes("payroll");
      const canViewPayroll = canShowPayroll && viewPayrollDecider();

      const canApprovePayroll =
        canShowPayroll &&
        booleanChecker("PAYROLL", "approve_payroll", "Payroll");
      const canRejectPayroll =
        canShowPayroll &&
        booleanChecker("PAYROLL", "reject_payroll", "Payroll");
      const canCancelPayroll =
        canShowPayroll &&
        booleanChecker("PAYROLL", "cancel_payroll", "Payroll");
      const canCreatePayroll =
        canShowPayroll &&
        booleanChecker("PAYROLL", "create_payroll", "Payroll");

      // buttonsPreparer
      const object = {
        canViewAccount,
        canViewActivity,
        canSendMoney,
        canViewRecipients,
        canSeeVA,
        canSeeUsers,
        canInviteUser,
        canCreatePin,
        //cards
        canViewCardsExpenses,
        canCreateCard,
        canUpdateCard,
        canBlockCard,
        canFreezeCard,
        canUnfreezeCard,
        canUnmaskCard,
        canViewCards,
        canViewAllCards,
        //cards
        // home props
        canViewReport,
        canViewWorkflow,
        canViewMainBalance,
        canTopup,
        canWithdraw,
        canAllocate,
        canViewMainBalanceHistory,
        canViewCardBalance,
        canReallocate,
        canViewCardBalanceHistory,
        // home props
        // payrolls
        canViewPayroll,
        canCreatePayroll,
        canApprovePayroll,
        canRejectPayroll,
        canCancelPayroll,
        // payrolls
        //e-wallet
        canViewEWallet,
        //e-wallet
        canViewInvoicePayment,
        canViewReimbursement,
        canViewApiDisbursement,
        canViewDevTools: true,
        canViewSettings: true,
        canViewHistory: true,
        canViewTasks: true,
        canCustomReceipt,
      };

      const keys = Object.keys(object);

      keys.map((key) => {
        const value = object[key];
        if (!value) return;
        buttonBooleans[key] = value;
      });

      // payroll
      if (canViewPayroll) return ["/payroll"];
      // strict urls
      if (canCreatePayroll) return ["/payroll/create"];
      if (canCreatePayroll && payroll_active) return ["/payroll/invite"];
      // strict urls
      // payroll

      // strict urls
      // if (canViewCards) return ["/cards"];
      // if (canCreateCard) return ["/cards/create"];
      if (canUpdateCard) return ["/cards/[id]/edit"];
      // strict urls

      // from home / accounts
      if (canTopup) return ["/account/topup"];
      if (canWithdraw) return ["/account/withdraw"];
      if (canAllocate) return ["/account/allocate"];
      if (canReallocate) return ["/account/reallocate"];
      // from home / accounts

      // buttonsPreparer
      if (canViewAccount) return accountPathnames;
      if (canViewActivity) return activitiesORTransactionsPathnames;
      if (canSendMoney) return createTransactionPathnames;
      // if (canViewRecipients) return recipientPathnames;
      if (canSeeVA) return VAPathnames;
      if (canSeeUsers) return userPathnames;

      if (!isProduction) return VAPathnames;
    })
    .filter((val) => val)
    .flat(1);

  const canViewLocalTransfer = !hide_beta_feature.includes("local_transfer");

  const createPin = canCreatePin ? ["/account/create-pin"] : [];
  const viewReport = canViewReport ? ["/report-download"] : [];
  const eWallet = canViewEWallet ? ["/e-wallet"] : [];
  const workflow = canViewWorkflow ? ["/workflow"] : [];
  const invoicePayment = canViewInvoicePayment ? ["/invoice-payment"] : [];
  const apiDisbursement = canViewApiDisbursement ? ["/histories-api"] : [];
  const reimbursement = canViewReimbursement ? ["/reimbursement"] : [];

  const viewCard = canViewCards ? ["/cards"] : [];
  const createCard = canCreateCard ? ["/cards/create"] : [];
  const customReceipt = canCustomReceipt ? ["/customize-receipt"] : [];

  const pathnamesDecider = () => {
    const activePathnames = removeDuplicates([
      ...canBeAccessedAllTheTimePathnames,
      ...dynamicPathnames,
      ...createPin,
      ...viewReport,
      ...eWallet,
      ...workflow,
      ...invoicePayment,
      ...apiDisbursement,
      ...reimbursement,
      ...viewCard,
      ...createCard,
      ...customReceipt,
    ]);
    buttonBooleans = { ...buttonBooleans, canViewLocalTransfer };

    const defaultHiddenPathnames = [
      "/cards",
      "/e-wallet",
      "/payroll",
      "/invoice-payment",
      "/users",
      "/users/edit-workflow",
      "/workflow",
      "/reimbursement",
      "/cards/create",
    ];

    const defaultHiddenButtonBooleans = [
      "canAllocate",
      "canApprovePayroll",
      "canCancelPayroll",
      "canInviteUser",
      "canRejectPayroll",
      "canSeeUsers",
      "canViewPayroll",
      "canViewCards",
      "canViewEWallet",
      "canViewInvoicePayment",
      "canViewAllCards",
      "canViewWorkflow",
      "canViewReimbursement",
    ];

    if (isDurianPay) {
      buttonBooleans = pickBy(
        buttonBooleans,
        (_, key) =>
          ![
            ...defaultHiddenButtonBooleans,
            "canViewTasks",
            "canViewHistory",
            "canSendMoney",
          ].includes(key)
      );
      const filteredPathnames = activePathnames.filter(
        (key) => !defaultHiddenPathnames.includes(key)
      );

      return filteredPathnames;
    }

    if (isAPIOnly) {
      const hiddenPathnames = [
        ...defaultHiddenPathnames,
        "/menu-api",
        "/local-transfer",
        "/local-disbursement",
        "/settings",
      ];
      const hiddenButtonBooleans = [
        ...defaultHiddenButtonBooleans,
        "canViewDevTools",
        "canViewSettings",
        "canSendMoney",
        "canViewHistory",
        "canViewTasks",
      ];
      buttonBooleans = {
        ...pickBy(
          buttonBooleans,
          (_, key) => !hiddenButtonBooleans.includes(key)
        ),
        canViewApiDisbursement: true,
      };
      const filteredPathnames = [
        ...activePathnames.filter((key) => !hiddenPathnames.includes(key)),
        "/histories-api",
      ];
      return filteredPathnames;
    }

    return activePathnames;
  };

  const activePathnames = pathnamesDecider();

  // customize user props
  const globalRoles = get(user, "roles", []).filter((role) => {
    const roleName = get(role, "name", "");
    const beOnlyRoles = ["admin", "checker", "maker"];
    return !beOnlyRoles.includes(roleName);
  });

  const role = globalRoles[0]?.name || "";
  const isPunyUser = ["partner_viewer", "partner_card_user"].includes(role);
  const isAdmin = !isPunyUser;
  const mainBalance = (user?.partner?.disbursement_balance || [])[0] || {};

  const { partner } = user || {};
  const {
    document_state: document_stateRaw,
    director_basis_id,
    business_type,
  } = partner || {};

  const isIndividual = business_type == "individual";

  const { isWaitingVerification } = booleanState(document_stateRaw);

  const document_state =
    isWaitingVerification && !director_basis_id && !isIndividual
      ? "not_submitted"
      : document_stateRaw;
  // customize user props

  const logoutTime = new Date().getTime() + 55 * 60 * 1000; // in 55 min, auto kick
  return {
    isDurianPay,
    isAPIOnly,
    activePathnames,
    buttonBooleans,
    role,
    isAdmin,
    mainBalance,
    logoutTime,
    ...user,
    partner: {
      ...partner,
      // document_state,
      real_document_state: document_stateRaw,
    },
  };
};

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState({});
  const [token, setToken] = useState("");
  const { pathname, push, query } = useRouter();
  const { isFirstpayroll, isCardRequest, isCardPayBills } = query;

  const { pageRedirect } = usePageRedirectMaster({ user, token });
  const { is_agreed = true, email, partner, pin } = user || {};

  const userHasApiKey = Boolean(partner?.live_api_key);

  const isWelcomeModalOpen = !is_agreed;

  const isDemoUser = email == "transfezdemo@gmail.com";

  const setUserHook = (user) => {
    setUser(user);
    localStorage.setItem("user", JSON.stringify(user));
  };

  const authorizeAgreed = async ({ is_agreed }) => {
    const result = { ...user, is_agreed };
    setUserHook(result);
  };

  const unauthorize = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("showed_suspend_or_overdue_modal");
    setUser({});
    setToken("");
    Sentry.configureScope((scope) => scope.setUser(null));
    logoutEvents(user);
  };
  const { errorToaster, warningToaster } = ToasterHook();

  const authorize = async ({ token }) => {
    try {
      //sets token
      const encryptedToken = encryptToken(token);
      localStorage.setItem("token", encryptedToken);
      setToken(encryptedToken);
      //sets token

      //sets user
      const { data } = await apiBusiness.get("/my_account");
      const user = userFormatter(data?.data || {});
      const { email, id, partner, is_verified } = user;
      if (!is_verified) {
        unauthorize();
        return errorToaster(
          "Error!",
          `Please check your email ${email} to access Transfez Business`
        );
      }
      Sentry.setUser({ email, id, username: get(partner, "name") });
      setUserEvents(user);
      setUserHook(user);

      //sets user
      if (isFirstpayroll) return push({ pathname: "/payroll", query });
      if (isCardRequest || isCardPayBills)
        return push({ pathname: "/cards", query });
      push("/home");
    } catch (err) {
      console.log("err:", err);
    }
  };

  useEffect(() => {
    setToken(localStorage.getItem("token"));
    const user = JSON.parse(localStorage.getItem("user"));
    setUser(user);
  }, []);

  const [isFirstRender, setIsFirstRender] = useState(true);

  const {
    data: userData,
    setData: setUserData,
    refetch,
    loading: userLoading,
  } = fetch({
    url: "/my_account",
    woInit: true,
    formatter: (data) => userFormatter(data?.data || {}),
    defaultValue: {},
    noToaster: false,
  });

  const refetchUser = () => {
    setUserData({});
    refetch();
  };
  const { isWaitingVerification } = userStatusBoolean(
    user?.partner?.document_state
  );

  const stopperPathnames = ["/", "/login", "/create-password"];
  const noRefetch = stopperPathnames.includes(pathname);
  // used in KYB so it can updates user state after user submits data

  useEffect(() => {
    if (!isWaitingVerification) return;
    if (noRefetch) return;

    let interval = setInterval(async () => refetchUser(), 5000);
    return () => clearInterval(interval);
  }, [isWaitingVerification]);
  // used in KYB

  // auto logout timeout handler
  const { logoutTime } = user || {};

  useEffect(() => {
    if (!logoutTime) return;

    let interval = setInterval(async () => {
      const now = new Date().getTime();
      if (now > logoutTime) {
        unauthorize();
        push("/login");
        warningToaster({ msg: "Your session is expired, please login again" });
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [logoutTime]);
  // auto logout timeout handler

  // refetch every change page
  useEffect(() => {
    if (isEmpty(user)) return;

    if (noRefetch) return;

    refetchUser();
  }, [pathname]);

  const isEmptyUser = isEmpty(userData);

  useEffect(() => {
    if (isEmptyUser) return;
    setUserHook(userData);
  }, [isEmptyUser]);
  // refetch every change page

  // redirect page master
  useEffect(() => {
    if (isFirstRender) return setTimeout(() => setIsFirstRender(false), 200);
    pageRedirect();
  }, [pathname, isFirstRender, query]);
  // redirect page master

  const values = {
    authorize,
    authorizeAgreed,
    unauthorize,
    token,
    user,
    userHasApiKey,
    isDemoUser,
    setUserHook,
    userLoading,
    refetchUser,
  };

  const excludedUrlPin = [
    "/account/create-pin",
    "/account/invite-users",
    "/login",
    "/register",
    "create-password",
  ];
  // pathname === "/account/create-pin" || pathname === "/account/invite-users";

  const canCreatePin =
    !isEmpty(user) && !pin && !excludedUrlPin.includes(pathname);

  const modals = () => (
    <>
      <WelcomeModal isOpen={isWelcomeModalOpen} />
      <StartOnboardingModal isOpen={canCreatePin} />
    </>
  );

  return (
    <AuthContext.Provider value={values}>
      {children}
      {modals()}
    </AuthContext.Provider>
  );
};

export const useGetAuth = () => {
  const {
    authorize,
    authorizeAgreed,
    unauthorize,
    token,
    user,
    userHasApiKey,
    isDemoUser,
    setUserHook,
    userLoading,
    refetchUser,
  } = useContext(AuthContext) || {};
  return {
    authorize,
    authorizeAgreed,
    unauthorize,
    token,
    user,
    userHasApiKey,
    isDemoUser,
    setUserHook,
    userLoading,
    refetchUser,
  };
};

export const getBusinessFlags = () => {
  const { user } = useGetAuth();
  const { all_flags = [] } = user?.partner || {};
  const isEWalletActivated = all_flags.includes("E_WALLET_ACTIVE");

  return { isEWalletActivated };
};

export const getUserStatusBoolean = () => {
  const { user } = useContext(AuthContext);
  const status = get(user, "partner.document_state", "");
  const booleans = userStatusBoolean(status);
  return booleans;
};

export const getUserButtonBooleans = () => {
  // how to use?
  // first, just use defaultButtonsReversed or defaultButtons

  // example:
  // const {canSeeVA} = defaultButtonsReversed

  // then after you pick the buttons
  // just swap it with this function

  // example:
  // const {canSeeVA} = getUserButtonBooleans()

  const { user } = useContext(AuthContext);
  const buttonBooleans = user?.buttonBooleans || {};
  return buttonBooleans;
};

export const useInvalidStatus = () => {
  const { isCompleted } = getUserStatusBoolean();
  const { isOpen, toggle } = useModalHook();

  const invalidUserStatus = () => {
    if (!isCompleted) {
      toggle && toggle();
      return true;
    }
  };

  return { invalidUserStatus, isOpen, toggle };
};

export const getRolesUserLogin = (roles) => {
  if (!roles) return {};

  const filterer = (string) =>
    roles.filter(({ name }) => name.includes(string)).length > 0;

  const isDrafterInvoice = filterer("invoice_drafter");
  const isDrafter = filterer("payroll_drafter");
  const isApprover = filterer("payroll_approver");
  const isAdmin = filterer("partner_admin");
  const isSuperAdmin = filterer("partner_super_admin");
  const isNotified = filterer("payroll_notified");
  const isPayroll = filterer("payroll");
  const isDrafterReimbursement = filterer("reimbursement_drafter");
  const isDrafterCard = filterer("card_drafter");
  const isDrafterSendMoney = filterer("sendmoney_drafter");

  return {
    isDrafterReimbursement,
    isDrafterSendMoney,
    isDrafterInvoice,
    isDrafterCard,
    isDrafter,
    isApprover,
    isAdmin,
    isNotified,
    isSuperAdmin,
    isPayroll,
  };
};

export const getUserRoleBooleans = ({ roles, role, pin, managed_teams }) => {
  const {
    isAdmin: isAdminFromRoles,
    isSuperAdmin: isSuperAdminFromRoles,
    isApprover,
    isDrafter,
    isNotified,
    isDrafterInvoice,
    isPayroll,
    isDrafterReimbursement,
    isDrafterCard,
    isDrafterSendMoney,
  } = getRolesUserLogin(roles || []);

  const isSuperAdmin = role === "partner_super_admin" || isSuperAdminFromRoles;
  const isAdmin = role === "partner_admin" || isAdminFromRoles;
  const isBookKeeper = role === "partner_viewer";
  const isEmployee = role === "partner_maker";
  const roleName = isSuperAdmin
    ? "Business Owner"
    : isAdmin
    ? "Admin"
    : isBookKeeper
    ? "Bookkeeper"
    : "Employee";
  const isHasPin = Boolean(pin);

  const isAdminOrSuperAdmin = isAdmin || isSuperAdmin;

  const isManagingTeams = (managed_teams ?? []).length > 0;

  const isEmployeeManager = isEmployee && isManagingTeams;
  const isBookKeeperManager = isBookKeeper && isManagingTeams;

  return {
    isDrafterInvoice,
    isDrafterSendMoney,
    isDrafterReimbursement,
    isDrafterCard,
    isSuperAdmin,
    isAdmin,
    isAdminOrSuperAdmin,
    isBookKeeper,
    isEmployee,
    isApprover,
    isDrafter,
    roleName,
    isHasPin,
    isNotified,
    isPayroll,
    isManagingTeams,
    isEmployeeManager,
    isBookKeeperManager,
  };
};

export const getUserRole = () => {
  const { user } = useContext(AuthContext);

  return getUserRoleBooleans(user || {});
};
