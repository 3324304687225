import React, { useState, useRef } from "react";
import bcaIcon from "../../../../assets/images/bca-icon.svg";
import bniIcon from "../../../../assets/images/bni-icon.svg";
import briIcon from "../../../../assets/images/bri-icon.svg";
import danamonIcon from "../../../../assets/images/danamon-icon.svg";
import mandiriIcon from "../../../../assets/images/mandiri-icon.svg";
import caretDown from "../../../../assets/images/caret-down-pink.svg";
import copyIcon from "../../../../assets/images/copy-icon.svg";
import { bankLists } from "../../../../assets/data";
import {
  TypeFezNeueMedium,
  TypeFezNeueRegular,
} from "../../../../components/Text";
import { colors } from "../../../../assets/colors";
import { Tooltip } from "reactstrap";
import { useModalHook } from "../../../../components/Modals";
import { useClickOutside } from "../../../../universalFunctions/useClickOutside";
import { formatCurrencyNoDecimal } from "../../../../components/tools";

export const BankDetailsSection = ({ data, bank, selectedBank }) => {
  const bankOptions = [
    { label: "BCA", value: "BCA", icon: bcaIcon },
    { label: "BNI", value: "BNI", icon: bniIcon },
    { label: "BRI", value: "BRI", icon: briIcon },
    { label: "Danamon", value: "Danamon", icon: danamonIcon },
    { label: "Mandiri", value: "Mandiri", icon: mandiriIcon },
  ];

  return (
    <div
      style={{
        padding: 16,
        boxShadow: "0px 4px 8px rgba(88, 88, 88, 0.06)",
        borderRadius: 12,
      }}
    >
      <BankSelection
        option={bankLists}
        bank={bank}
        setActiveBank={(val) => selectedBank(val)}
      />
      <BankDetailsData bank={bank} data={data} />
    </div>
  );
};

const BankSelection = ({ option, bank, setActiveBank }) => {
  const [isHover, setIsHover] = useState(false);
  const {
    isOpen: openSelection,
    toggle: toggleSelection,
    close,
  } = useModalHook();

  const { bank: bankName, icon } = bank || {};

  return (
    <div>
      <div
        style={{
          position: "relative",
          border: "1px solid #E6E6E8",
          borderRadius: 8,
          padding: 8,
          cursor: "pointer",
          backgroundColor: isHover && "rgba(234, 234, 234, 0.4)",
          marginBottom: 16,
        }}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
        onClick={toggleSelection}
      >
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <img src={icon} className="mr-2" />
            <div>
              <TypeFezNeueRegular
                style={{
                  fontSize: 12,
                  color: colors.grey6c,
                  lineHeight: "16px",
                }}
              >
                Select Bank
              </TypeFezNeueRegular>
              <TypeFezNeueMedium>{bankName}</TypeFezNeueMedium>
            </div>
          </div>
          <img src={caretDown} />
        </div>
      </div>
      {openSelection && (
        <ModalBankSelection
          close={close}
          option={option}
          setActiveBank={setActiveBank}
        />
      )}
    </div>
  );
};

const ModalBankSelection = ({ close, option, setActiveBank }) => {
  const [indexHover, setIndexHover] = useState(false);
  const ref = useRef();
  useClickOutside({ clickOutside: close, ref });
  return (
    <div
      style={{
        position: "absolute",
        border: `1px solid ${colors.greye6}`,
        backgroundColor: "white",
        borderRadius: 12,
        padding: "8px 0px",
        width: "82%",
        zIndex: 3,
      }}
      ref={ref}
    >
      {option?.map((item, index) => {
        const { bank } = item || {};
        const isHover = index === indexHover;
        return (
          <div
            key={index}
            style={{
              padding: 12,
              backgroundColor: isHover && colors.lightPink,
              cursor: "pointer",
            }}
            onMouseEnter={() => setIndexHover(index)}
            onMouseLeave={() => setIndexHover(false)}
            onClick={() => {
              setActiveBank(item);
              close();
            }}
          >
            <TypeFezNeueMedium>{bank}</TypeFezNeueMedium>
          </div>
        );
      })}
    </div>
  );
};

const BankDetailsData = ({ bank, data }) => {
  const { total_trx_amount } = data || {};
  const { name, number } = bank || {};

  const dataRendered = [
    {
      title: "Account Name",
      value: name,
      copyValue: name,
    },
    {
      title: "Account Number",
      value: number,
      copyValue: number,
    },
    {
      title: "Total Payment",
      value: `IDR ${formatCurrencyNoDecimal(total_trx_amount)}`,
      copyValue: Math.ceil(total_trx_amount || 0),
    },
  ];

  return (
    <div>
      <div>
        {dataRendered?.map((item, index) => {
          const [tooltipOpen, setTooltipOpen] = useState(false);
          const toggleTooltip = () => setTooltipOpen(!tooltipOpen);
          const { title, value, copyValue, icon } = item || {};
          return (
            <div
              key={index}
              className="d-flex justify-content-between align-items-center"
              style={{ marginBottom: 12 }}
            >
              <div>
                <div className="d-flex align-items-center">
                  <TypeFezNeueRegular
                    style={{
                      color: colors.grey6c,
                      fontSize: 12,
                      lineHeight: "14px",
                    }}
                  >
                    {title}
                  </TypeFezNeueRegular>
                  {icon && (
                    <div style={{ position: "relative", height: 14 }}>
                      <img
                        src={icon}
                        style={{ width: 10, marginLeft: 4, marginTop: -12 }}
                      />
                    </div>
                  )}
                </div>
                <TypeFezNeueMedium>{value}</TypeFezNeueMedium>
              </div>
              <div
                id={"Tooltip-" + (index + 1)}
                style={{ cursor: "pointer" }}
                onClick={(e) => {
                  e.preventDefault();
                  navigator.clipboard.writeText(copyValue);
                }}
              >
                <img src={copyIcon} />
              </div>
              <Tooltip
                target={"Tooltip-" + (index + 1)}
                isOpen={tooltipOpen}
                toggle={toggleTooltip}
                style={{ padding: "0px 12px 2px 12px" }}
              >
                <span
                  style={{
                    fontSize: 10,
                    lineHeight: "12px",
                    fontFamily: "TypefezNeueRegular",
                  }}
                >
                  Copy
                </span>
              </Tooltip>
            </div>
          );
        })}
      </div>
    </div>
  );
};
