import getState from "./getState";

const titleTextDecider = (state = "") => {
  const { isOverdue, isSuspended, isSuspendTransaction, isSuspendCard } =
    getState(state);

  if (isOverdue) return "Subscription payment overdue";
  if (isSuspendCard) return "Your account is suspended";
  if (isSuspended) return "Your subscription has been suspended";
  if (isSuspendTransaction) return "You can’t create any transactions";
  return "Invalid state!";
};

export default titleTextDecider;
