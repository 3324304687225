import { useMutation } from "../../../tools/api";
import { ToasterHook } from "../../../contexts/ToasterContext";

const useSunlight = ({ id, sunlight_external_id }) => {
  const { errorToasterApi } = ToasterHook();

  const { SunlightConnect } = window;

  let sunlightConnect = null;

  const initSunlight = (config) => {
    if (sunlightConnect != null) sunlightConnect.close();
    sunlightConnect = SunlightConnect.initialize(config);
    sunlightConnect.open();
  };

  const { mutation: createSunlightUser, loading: loadingCreateSunlightUser } =
    useMutation({
      url: `/card/sunlight/${id}/create_user`,
      method: "post",
    });

  const {
    mutation: generateSunlightToken,
    loading: loadingGenerateSunlightToken,
  } = useMutation({
    url: `/card/sunlight/${id}/generate_token`,
    method: "post",
    resultFormatter: ({ data }) => data.data,
    afterSuccess: ({ card_identifier, token, user_external_id }) => {
      const sunlightConfig = {
        cardIdentifier: card_identifier,
        userId: user_external_id,
        customizationId: "1ZH7YSJTP9D9",
        token,
      };
      initSunlight(sunlightConfig);
    },
    withError: false,
  });

  const init = async () => {
    if (!SunlightConnect)
      return errorToasterApi({ message: "Can't connect to Sunlight" });

    if (!sunlight_external_id) await createSunlightUser();
    generateSunlightToken();
  };

  const loading = loadingCreateSunlightUser || loadingGenerateSunlightToken;

  return {
    init,
    loading,
  };
};

export default useSunlight;
