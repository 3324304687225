import { upperCase } from "lodash";

export const localBanksFormatter = (res) => {
  const array = res?.data || [];
  const result = array.map((item) => {
    const {
      full_name: bank_name,
      swift: swift_code,
      name,
      id: value,
      alias,
    } = item;
    const label = alias ? upperCase(alias) : upperCase(name);
    return { ...item, value, bank_name, swift_code, label };
  });
  return result;
};
