import React, { useState } from "react";
import {
  MainCardHeaderMolecule,
  MiddleWrapper,
  RightCardModalAtomic,
} from "../../components/CardModal";
import { AccountDetailsList } from "../../pageComponents/accountDashboardComponents";

export const VAModal = ({ isOpen, toggle, data }) => {
  const { curSymbol, vaDetailsArr } = data;
  const isUSD = curSymbol == "USD";
  const [middleHeight, setMiddleHeight] = useState(0);
  return (
    <RightCardModalAtomic
      isOpen={isOpen}
      middleHeight={middleHeight}
      header={
        <MainCardHeaderMolecule
          title="Account Details"
          subTitle="For domestic transfer only"
          toggle={toggle}
          alignLeft
          isClose={false}
          middleHeight={middleHeight}
        />
      }
      middle={
        <MiddleWrapper
          setHeight={setMiddleHeight}
          height={middleHeight}
          style={{ zIndex: 1 }}
        >
          <AccountDetailsList data={vaDetailsArr} isUsd={isUSD} />
        </MiddleWrapper>
      }
    />
  );
};
