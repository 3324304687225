import {
  customDateFormatter,
  formatCurrency,
  formatCurrencyNoDecimal,
} from "../../../components/tools";
import { defaultAccountDetailsLogic } from "./defaultLogic";

export const refundFormatter = (object) => {
  const defaultObj = defaultAccountDetailsLogic(object);
  const { amount, currency, status, reference_number, user, created_at } =
    object || {};
  const transaction = object?.details?.transaction || {};
  const { reference_id, batch_id, sent_amount, source_currency, fee } =
    transaction;
  const { name: userName, email: userEmail } = user || {};

  const isBatch = Boolean(batch_id);
  const leftBottom = ` • ${reference_number} • ${customDateFormatter(
    created_at
  )} • ${userName || userEmail} •`;
  const leftTop = `Refund - From ${batch_id || reference_id}`;
  const leftStatus = status;
  const rightTop = isBatch
    ? `+${currency} ${formatCurrency(amount)}`
    : `+${source_currency} ${formatCurrencyNoDecimal(
        Number(sent_amount) + Number(fee)
      )}`;

  return { ...object, ...defaultObj, leftTop, leftBottom, leftStatus, rightTop };
};
