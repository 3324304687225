import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { CustomButton } from "../../components/Buttons";
import {
  MainCardHeaderMolecule,
  MiddleWrapper,
  RightCardModalAtomic,
} from "../../components/CardModal";
import { CustomTextField, SelectionForm } from "../../components/inputs";
import { useModalHook } from "../../components/Modals";
import { schemaGenerator } from "../../components/tools";
import { useConstants } from "../../contexts/ConstantsContext/parent";
import { ToasterHook } from "../../contexts/ToasterContext";
import { useMutation } from "../../tools/api";
import { eventsTracker } from "../../universalFunctions/events";
import { banksFormatter } from "./finalUploadReceiptModal";
import { useUploadReceiptScreenType } from "./logics";

export const getUploadProps = () => {
  const { isSinglePayment, isBatchPayment } = useUploadReceiptScreenType();
  const { query } = useRouter();
  const { id: idRaw, transactionId } = query;

  const id = idRaw || transactionId;

  const formatter = (data) => ({
    partner_bank_account_attributes: data,
  });

  const method = "put";

  if (isSinglePayment)
    return { url: `/update_single_transaction/${id}`, formatter, method };

  if (isBatchPayment)
    return { url: `/business_transactions/${id}`, formatter, method };

  return {
    url: "/partner_bank_accounts",
    formatter: (data) => data,
    method: "post",
  };
};

export const useAddBank = ({ setData, setActiveId, toFinal }) => {
  const { successToaster } = ToasterHook();
  const { isOpen: isOpenAddBank, toggle: toggleAddBank } = useModalHook();

  // its a bit of mess caused by afterSuccessAddBank's `res` data doesnt includes bank_name, account_name, account_number
  // so change of plan: call `setAddedObject` when user hits `onSubmitAddBank`.
  // if its error, then abort it with `handleError`
  // if its success then `afterSuccessAddBank` will be called, we need to take bank account's `id`
  // then run it in useEffect. now we have access to res via `addedObject`

  // SEQUENCE TO ADD BANK: fires `onSubmitAddBank` =>  if success fires `afterSuccessAddBank` => triggers useEffect

  const [addedObject, setAddedObject] = useState(false);

  const afterSuccessAddBank = (res, payload) => {
    successToaster({ msg: "Bank account added" });
    eventsTracker("add_bank", payload);
    const { data = {} } = res?.data || {};
    const { id: idRaw, partner_bank_account_id } = data;
    const id = partner_bank_account_id || idRaw;

    setAddedObject((prev) => ({ ...prev, id }));
  };

  const { url, formatter, method } = getUploadProps();

  const { mutation, loading: loadingAddBank } = useMutation({
    url,
    method,
    afterSuccess: afterSuccessAddBank,
    handleError: () => setAddedObject(false),
  });

  const onSubmitAddBank = (val) => {
    // val is {bank_name, account_name, account_number}
    setAddedObject(val);
    const result = formatter(val);
    mutation(result);
  };

  useEffect(() => {
    const { id } = addedObject || {};

    if (!id) return;

    setActiveId && setActiveId(id);
    setData((prev) => banksFormatter({ data: [addedObject, ...prev] }));

    toggleAddBank();

    toFinal &&
      setTimeout(() => {
        toFinal(id);
      }, 200);

    setAddedObject(false);
  }, [addedObject?.id]);

  return { isOpenAddBank, toggleAddBank, onSubmitAddBank, loadingAddBank };
};

export const BankAccountFormModal = ({
  isOpen,
  toggle,
  onSubmit: onSubmitProps = () => {},
  defaultValues = {},
  isEdit,
  loading,
  isClose = true,
  title = "",
  buttonMsg = "",
}) => {
  if (!isOpen) return null;
  const resolver = schemaGenerator({
    stringArr: ["account_name", "account_number"],
    objectArr: ["bank_name"],
  });

  const useFormObj = useForm({ defaultValues, resolver });
  const { errors, handleSubmit, setValue } = useFormObj;
  const { bank_name } = errors;

  const middleHeight = 44;

  const editOrAdd = isEdit ? "Edit" : "Add";

  const { idBanks, idBanksLoading } = useConstants();

  const onSubmit = (val) => {
    const { bank_name: bank_nameRaw, account_number: accNumber } = val || {};
    const bank_name = bank_nameRaw?.label;
    const account_number = accNumber + ""
    const result = { ...val, bank_name, account_number };
    onSubmitProps(result);
  };

  useEffect(() => {
    if (!defaultValues) return;

    const { bank_name } = defaultValues || {};
    const value = idBanks.filter(({ label }) => label == bank_name)[0];

    setTimeout(() => setValue("bank_name", value), 100);
  }, []);

  return (
    <RightCardModalAtomic
      isOpen={isOpen}
      middleHeight={middleHeight}
      paddingTop={Boolean(bank_name) ? 20 : 0}
      header={
        <MainCardHeaderMolecule
          isClose={isClose}
          toggle={toggle}
          title={title || `${editOrAdd} Bank Account`}
          alignLeft
          middleHeight={middleHeight}
        />
      }
      middle={
        <MiddleWrapper height={middleHeight} style={{ zIndex: 1 }}>
          <div style={{ height: 44 }}>
            <SelectionForm
              name="bank_name"
              label="Your Bank Name"
              options={idBanks}
              useFormObj={useFormObj}
              isLoading={idBanksLoading}
            />
          </div>
        </MiddleWrapper>
      }
      body={
        <>
          <CustomTextField
            useFormObj={useFormObj}
            className="mt-3"
            label="Your Account Name"
            name="account_name"
            inputProps={{ maxLength: 51 }}
          />
          <CustomTextField
            useFormObj={useFormObj}
            className="mt-3"
            label="Your Account Number"
            name="account_number"
            inputProps={{ maxLength: 17 }}
          />
          <CustomButton
            className="w-100"
            isLoading={loading}
            onClick={handleSubmit(onSubmit)}
          >
            {buttonMsg || editOrAdd}
          </CustomButton>
        </>
      }
    />
  );
};
