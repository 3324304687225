import React, { useEffect, useRef, useState } from "react";
import { colors } from "../assets/colors";
import Skeleton from "react-loading-skeleton";
import ChecklistIcon from "../assets/images/checklist-nonbg.svg";
import SendIcon from "../assets/images/send-icon.svg";
import emptyTrxImg from "../assets/images/transaction.svg";
import { CustomButton } from "../components/Buttons";
import { GothamBold, GothamMedium, GothamRegular } from "../components/Text";
import { formatCurrency, windowDimension } from "../components/tools";
import caretDownIcon from "../assets/images/caret-down-pink.svg";
import swiftBankIcon from "../assets/images/swift-bank-icon.svg";
import swiftShaIcon from "../assets/images/swift-sha-icon.svg";
import swiftOurIcon from "../assets/images/swift-our-icon.svg";
import xIcon from "../assets/images/x-mark-business-type.svg";
import checkIcon from "../assets/images/checklist-business-type.svg";
import infoIcon from "../assets/images/info-icon.svg";
import { useModalHook } from "../components/Modals";
import { useClickOutside } from "../universalFunctions/useClickOutside";
import { CrossborderTrxDetailsModal } from "../modals/crossborderTrxDetailsModal";
import { SelectDeliveryMethod } from "./crossBorder/selection/modal";
import { SelectionBusinessType } from "./crossBorder/selection/business";

export const InformationDetails = ({ data, handleDetail }) => {
  return (
    <>
      {data.map((item, index) => {
        const { firstText, secondText, boldText, isButton } = item;
        return (
          <div
            key={index}
            className="d-flex align-items-center"
            style={{ marginBottom: 8 }}
          >
            <img src={ChecklistIcon} className="mr-3" />
            <GothamRegular style={{ color: colors.grey72, marginBottom: 0 }}>
              {firstText}{" "}
              <span
                style={{ fontFamily: "GothamMedium", color: colors.grey25 }}
              >
                {boldText}
              </span>{" "}
              {secondText}
              {/* {isButton && (
                <DetailButton onClick={handleDetail}>Detail</DetailButton>
              )} */}
            </GothamRegular>
          </div>
        );
      })}
    </>
  );
};

export const TotalPaymentAndSend = ({
  data,
  country,
  totalAmount,
  useFormObj,
  loadingCalculator,
  handleSend,
  disabled,
}) => {
  const { currency } = country || {};
  const { destination_currency } = data || {};

  const isMatch = currency === destination_currency;
  const loading = loadingCalculator || !isMatch;

  return (
    <div
      className="d-flex justify-content-between align-items-center"
      style={{ marginTop: 24 }}
    >
      <div style={{ height: 48 }}>
        <GothamRegular style={{ fontSize: 12 }}>Total Payment</GothamRegular>
        <GothamMedium style={{ color: colors.primaryBlue, fontSize: 16 }}>
          {totalAmount}
        </GothamMedium>
      </div>
      <CustomButton
        style={{
          // width: `calc(100% - ${width * 1.1}px)`,
          height: 40,
          width: 226,
          color: "white",
          fontSize: 12,
        }}
        onClick={useFormObj.handleSubmit(handleSend)}
        isLoading={loading}
        // icon={
        //   !isTabOrPhone && (
        //     <img
        //       src={SendIcon}
        //       className="mr-2"
        //       style={{ width: 20, height: 20 }}
        //     />
        //   )
        // }
        disabled={disabled}
      >
        Send Money
      </CustomButton>
    </div>
  );
};

export const CrossBorderTrxDetails = ({
  data,
  loading,
  isConfirmation = false,
}) => {
  const [show, setShow] = useState(false);
  const handleExpand = () => {
    setShow((p) => !p);
  };
  return (
    <div
      style={{
        marginLeft: isConfirmation ? 8 : 20,
        marginRight: isConfirmation ? 0 : 20,
        borderLeft: !isConfirmation && "2px solid #e6e6e8",
      }}
    >
      {data.map((item, index) => {
        const { left, right, icon, spacer, expand, horizontal } = item || {};
        const indexHide = index < 4 && index != 0;
        const hideComponents = !show && indexHide && !isConfirmation;
        const animation = !show && !indexHide;
        const style = {
          color: colors.grey6c,
        };
        const classAnimation = animation ? "slide-down" : "slide-up";
        if (isConfirmation && index === 0) return null;
        if (hideComponents) return null;
        if (horizontal)
          return (
            <hr
              className={classAnimation}
              style={{
                margin: "0px 0px 12px 12px",
                borderTop: "2px dashed #C2C2C2",
              }}
            />
          );
        if (spacer) return <div style={{ height: 12 }}></div>;
        return (
          <div
            key={index}
            className={`d-flex justify-content-between ${classAnimation}`}
            style={{
              position: "relative",
              paddingBottom: 12,
              paddingLeft: 15,
              zIndex: indexHide ? 0 : 1,
              backgroundColor: "white",
            }}
          >
            <>
              {icon ? (
                <img
                  src={icon}
                  alt="icon"
                  style={{ position: "absolute", left: -8, top: 3 }}
                />
              ) : (
                <div
                  style={{
                    position: "absolute",
                    left: -4,
                    top: 8,
                    width: 6,
                    height: 6,
                    borderRadius: "50%",
                    backgroundColor: "#e6e6e8",
                  }}
                ></div>
              )}
            </>
            <GothamRegular style={style}>{left}</GothamRegular>
            {loading ? (
              <Skeleton width={125} height={16} />
            ) : (
              <GothamRegular style={style}>{right}</GothamRegular>
            )}
            {expand && !isConfirmation && (
              <img
                src={caretDownIcon}
                style={{
                  position: "absolute",
                  right: -20,
                  top: 2,
                  cursor: "pointer",
                }}
                onClick={handleExpand}
                className={`rotate-icon ${show ? "down" : ""}`}
              />
            )}
          </div>
        );
      })}
    </div>
  );
};

export const CrossBorderEstimate = ({ data, loading }) => {
  return (
    <div className="mt-3">
      {data?.map((item, index) => {
        const isLast = index === data.length - 1;
        const { textLeft, bold, textRight } = item || {};
        return (
          <div
            className="d-flex justify-content-center"
            style={{ marginBottom: isLast ? 0 : 6 }}
          >
            {loading ? (
              <Skeleton width={320} height={20} />
            ) : (
              <GothamRegular
                style={{
                  color: colors.grey6c,
                  textAlign: "center",
                  lineHeight: "20px",
                }}
              >
                {textLeft}
                <span
                  style={{ color: colors.blue10, fontFamily: "GothamBold" }}
                >
                  {bold}
                </span>
                {textRight}
              </GothamRegular>
            )}
            {/* <CrossborderTrxDetailsModal isOpen={isOpen} toggle={toggle} /> */}
          </div>
        );
      })}
    </div>
  );
};

export const CrossBorderAlert = ({ data, bankFee }) => {
  const { isLocal, isSwiftSha, isSwiftOur } = useFeeLabelDecider(data);
  const dataRender = () => {
    if (isSwiftOur)
      return {
        title: "SWIFT OUR is advised if",
        details: [
          "You require a MT-103 (globally accepted Proof of Payment)",
          "You want to trace or recall your payments",
          "You will pay the entire fee and beneficiary will receive in full amount",
          `Includes additional bank fees of ${bankFee}`,
        ],
      };
    if (isSwiftSha)
      return {
        title: "When using SWIFT SHA:",
        details: [
          "You can request a MT-103 (globally accepted proof of payment)",
          "You can trace or recall your payments",
          "Beneficiary will not receive the full amount as the charges (from intermediary bank) will be deducted from the transfer amount",
        ],
      };

    return {
      title: "When using Local Transfer:",
      details: [
        "Beneficiary will receive the full amount",
        "You pay the smallest fees possible",
      ],
    };
  };
  const alertData = dataRender();
  const { title, details } = alertData;
  return (
    <div style={{ padding: 16, backgroundColor: "#E5F9FC", borderRadius: 8 }}>
      <div className="d-flex justify-content-between">
        <GothamMedium style={{ color: colors.blue10, fontSize: 12 }}>
          {title}
        </GothamMedium>
        <img src={infoIcon} alt="info" />
      </div>
      <ul style={{ paddingInlineStart: 20, fontSize: 12, marginBottom: 0 }}>
        {details?.map((item, index) => (
          <li key={index} style={{ lineHeight: "12px" }}>
            <GothamRegular style={{ color: colors.grey33, fontSize: 12 }}>
              {item}
            </GothamRegular>
          </li>
        ))}
      </ul>
    </div>
  );
};

export const ErrorTextMinMaxAmount = ({
  minAmount,
  maxAmount,
  destAmount,
  destCurr,
}) => {
  if (Number(destAmount) > Number(maxAmount))
    return `The maximum amount you can send is ${destCurr} ${formatCurrency(
      maxAmount
    )}`;
  if (Number(destAmount) < Number(minAmount))
    return `The minimum amount you can send is ${destCurr} ${formatCurrency(
      minAmount
    )}`;
  return ``;
};

export const useMaxMinAmountDecider = ({ data, isB2B, deliveryMethod }) => {
  const { swift_amount } = data || {};
  const {
    local_b2b_max,
    local_b2c_max,
    local_b2b_min,
    local_b2c_min,
    swift_sha_b2b_max,
    swift_sha_b2b_min,
    swift_sha_b2c_max,
    swift_sha_b2c_min,
    swift_our_b2b_max,
    swift_our_b2b_min,
    swift_our_b2c_max,
    swift_our_b2c_min,
  } = swift_amount || {};
  const { isLocal, isSwiftOur, isSwiftSha } =
    useFeeLabelDecider(deliveryMethod);

  const Decider = () => {
    if (isSwiftSha)
      return {
        maxAmount: isB2B
          ? Number(swift_sha_b2b_max)
          : Number(swift_sha_b2c_max),
        minAmount: isB2B
          ? Number(swift_sha_b2b_min)
          : Number(swift_sha_b2c_min),
      };
    if (isSwiftOur)
      return {
        maxAmount: isB2B
          ? Number(swift_our_b2b_max)
          : Number(swift_our_b2c_max),
        minAmount: isB2B
          ? Number(swift_our_b2b_min)
          : Number(swift_our_b2c_min),
      };
    return {
      maxAmount: isB2B ? Number(local_b2b_max) : Number(local_b2c_max),
      minAmount: isB2B ? Number(local_b2b_min) : Number(local_b2c_min),
    };
  };

  const { maxAmount, minAmount } = Decider();
  return { maxAmount, minAmount };
};

export const defaultValue = ({ data }) => {
  const { fee, swift_our_fee, swift_sha_fee } = data || {};
  if (Boolean(fee) || fee === 0) return "Local Bank Network";
  if (Boolean(swift_our_fee) || swift_our_fee === 0) return "SWIFT OUR";
  if (Boolean(swift_sha_fee) || swift_sha_fee === 0) return "SWIFT SHA";
};

export const useFeeLabelDecider = (data) => {
  const isLocal = data === "Local Bank Network";
  const isSwiftSha = data === "SWIFT SHA";
  const isSwiftOur = data === "SWIFT OUR";
  const PaymentChannel = () => {
    if (isSwiftOur) return `swift_our`;
    if (isSwiftSha) return `swift_sha`;
    return `local`;
  };
  const payment_channel = PaymentChannel();
  return { isLocal, isSwiftOur, isSwiftSha, payment_channel };
};

export const useTimeDescDecider = ({ deliveryMethod, deliverySla }) => {
  const { isLocal, isSwiftOur, isSwiftSha } =
    useFeeLabelDecider(deliveryMethod);
  const { localSla, shaSla, ourSla } = deliverySla || {};
  if (isSwiftSha) return shaSla;
  if (isSwiftOur) return ourSla;
  return localSla;
};

export const useFeeDecider = ({ deliveryFee, deliveryMethod }) => {
  const { isLocal, isSwiftOur, isSwiftSha } =
    useFeeLabelDecider(deliveryMethod);
  const { fee, swift_our_fee, swift_sha_fee } = deliveryFee || {};

  const bankFeeDecider = () => {
    if (isSwiftOur) return Number(swift_our_fee) - Number(swift_sha_fee);
    return 0;
  };

  const bankFee = bankFeeDecider();

  const feeDecider = (isMinusBankFee) => {
    if (isSwiftSha) return Number(swift_sha_fee);
    if (isMinusBankFee && isSwiftOur) return Number(swift_our_fee) - bankFee;
    if (isSwiftOur) return Number(swift_our_fee);
    return fee;
  };

  const transfezFee = feeDecider(true);
  const transfezFeeRaw = feeDecider();

  return { bankFee, transfezFee, transfezFeeRaw };
};

export const CrossBorderDeliveryMethod = ({
  deliveryMethod,
  deliverySla,
  setDeliveryMethod,
  activeOption,
  loading,
}) => {
  const { localSla, ourSla, shaSla } = deliverySla || {};
  const [selectedData, setSelectedData] = useState(false);
  const { swift_our_fee, swift_sha_fee, fee } = activeOption || {};
  const isLocalActive = Boolean(fee) || fee === 0;
  const isSwiftShaActive = Boolean(swift_sha_fee) || swift_sha_fee === 0;
  const isSwiftOurActive = Boolean(swift_our_fee) || swift_our_fee === 0;

  useEffect(() => {
    setSelectedData(deliveryMethod);
  }, [deliveryMethod]);
  console.log("deliveryMethod:", deliveryMethod);

  const { isOpen, toggle, close } = useModalHook();

  const handleClick = (val) => {
    const { title } = val || {};
    setSelectedData(val);
    setDeliveryMethod(title);
    close();
  };

  const globalProof = `Global proof of payment (MT-103)`;
  const customNotes = `Customize notes`;

  const defaultStyle = {
    fontSize: 10,
    lineHeight: "12px",
    color: colors.grey6c,
  };

  const dataModal = [
    ...(isLocalActive
      ? [
          {
            title: "Local Bank Network",
            desc: `Should arrive `,
            descBold: localSla,
            fee: `Fee IDR ${formatCurrency(Number(fee))}`,
            icon: swiftBankIcon,
            items: [
              { point: globalProof, xicon: true },
              { point: customNotes, xicon: true },
              { point: "Beneficiary will receive in full amount" },
            ],
          },
        ]
      : []),
    ...(isSwiftShaActive
      ? [
          {
            title: "SWIFT SHA",
            desc: `Should arrive within `,
            descBold: shaSla,
            fee: `Fee IDR ${formatCurrency(Number(swift_sha_fee))}`,
            icon: swiftShaIcon,
            items: [
              { point: globalProof },
              { point: customNotes },
              {
                customPoint: (
                  <GothamRegular style={defaultStyle}>
                    You will pay fees charged by the sending bank while the
                    recipient pays the fees charged by the receiving bank.{" "}
                    <span style={{ fontFamily: "GothamMedium" }}>
                      Hence, the recipient will not receive in full amount
                    </span>
                  </GothamRegular>
                ),
              },
            ],
          },
        ]
      : []),
    ...(isSwiftOurActive
      ? [
          {
            title: "SWIFT OUR",
            desc: `Should arrive within `,
            descBold: ourSla,
            fee: `Fee IDR ${formatCurrency(Number(swift_our_fee))}`,
            icon: swiftOurIcon,
            items: [
              { point: globalProof },
              { point: customNotes },
              {
                customPoint: (
                  <GothamRegular style={defaultStyle}>
                    You will pay all transaction fees and{" "}
                    <span style={{ fontFamily: "GothamMedium" }}>
                      the recipient will receive the full amount
                    </span>
                  </GothamRegular>
                ),
              },
            ],
          },
        ]
      : []),
  ];

  const getFullData = dataModal?.filter(
    ({ title }) => title === selectedData
  )[0];

  const { title: titleDefault, icon: iconDefault } = getFullData || {};
  const { title: titleSelected, icon: iconSelected } = selectedData || {};
  const title = titleDefault || titleSelected;
  const icon = iconDefault || iconSelected;

  return (
    <div>
      <GothamRegular
        style={{
          marginBottom: 12,
          color: colors.grey6c,
          fontSize: 12,
          lineHeight: "16px",
        }}
      >
        Select delivery method
      </GothamRegular>
      <div
        className="d-flex align-items-center justify-content-between"
        style={{ cursor: "pointer" }}
        onClick={toggle}
      >
        <div className="d-flex align-items-center">
          <img src={icon} alt="icon" className="mr-2" />
          <GothamBold style={{ marginBottom: 0 }}>{title}</GothamBold>
        </div>
        <img src={caretDownIcon} alt="bottom arrow" />
      </div>
      <SelectDeliveryMethod
        isOpen={isOpen}
        toggle={toggle}
        data={dataModal}
        handleClick={handleClick}
      />
    </div>
  );
};

export const EmptyScreenSearchCountry = ({
  title = "Country still unavailable",
  desc = "Sorry, money transfer to this country are still unavailable. Please wait for the following update.",
  smallPadding = false,
}) => {
  const padding = smallPadding ? "0px 20px" : "0px 50px";
  return (
    <div className="text-center">
      <img
        src={emptyTrxImg}
        alt="option empty"
        style={{ marginBottom: 24, marginTop: 24 }}
      />
      <div style={{ padding }}>
        <GothamMedium className="mb-1">{title}</GothamMedium>
        <GothamRegular>{desc}</GothamRegular>
      </div>
    </div>
  );
};

export const SelectionBusinessAndDelivery = ({
  deliveryMethod,
  deliverySla,
  setDeliveryMethod,
  activeOption,
  loading,
  data,
  setBusinessType,
}) => {
  return (
    <div
      style={{
        background: "#FFF",
        position: "relative",
        borderRadius: 8,
        padding: "16px 24px",
        boxShadow: "0px 8px 20px rgba(88, 88, 88, 0.1)",
        marginBottom: 24,
      }}
    >
      {loading ? (
        <Skeleton width={"100%"} height={50} />
      ) : (
        <SelectionBusinessType data={data} setBusinessType={setBusinessType} />
      )}
      <hr style={{ margin: "12px 0px" }} />
      {loading ? (
        <Skeleton width={"100%"} height={50} />
      ) : (
        <CrossBorderDeliveryMethod
          loading={loading}
          setDeliveryMethod={setDeliveryMethod}
          deliveryMethod={deliveryMethod}
          deliverySla={deliverySla}
          activeOption={activeOption}
        />
      )}
    </div>
  );
};
