import {
  ReasonNotes,
  StatusPayrollLine,
} from "../../../pageComponents/payrollCreateComponents/loop/success/status";
import { TransactionStatusFlow } from "../../../pageComponents/transactionDetailsComponents";
import { useCrossBorderSingleWorkflow } from "./hooks/workflow";

export const CrossBorderSingleStatusLine = ({ transaction, isModal }) => {
  const {
    firstLineObj,
    completedLineObj,
    cancelledLineObj,
    approverLineArray,
    isWorkflow,
    isCancelled,
    isValidating,
    isCompleted,
    isRefunded,
    isAwaitingRefundStrict,
    validatingObj,
    rejection_note,
  } = useCrossBorderSingleWorkflow(transaction);
  const { invoice_file: isInvoice, local_recipient } = transaction || {};

  const { name } = local_recipient || {};
  // Transferring money to recipients
  const nameDecider = () => {
    if (name && name != "-") return name;
    return "recipients";
  };
  if (isWorkflow) {
    const arrayDecider = () => {
      if (isRefunded)
        return [
          firstLineObj,
          cancelledLineObj,
          {
            isActive: true,
            text: isAwaitingRefundStrict
              ? "Your refund request has been completed, you should have the funds in your designated account."
              : "Sit tight. The refund process has been initiated. It may take up to 2x24 hours for your funds to be returned",
          },
        ];
      if (isCompleted)
        return [firstLineObj, ...approverLineArray, completedLineObj];
      if (isValidating)
        return [
          firstLineObj,
          ...approverLineArray,
          validatingObj,
          completedLineObj,
        ];

      if (isCancelled) return [firstLineObj, cancelledLineObj];

      return [
        firstLineObj,
        ...approverLineArray,
        isInvoice
          ? { ...validatingObj, text: `Transferring money to ${nameDecider()}` }
          : validatingObj,
        completedLineObj,
      ];
    };

    return (
      <div style={{ marginTop: 12 }}>
        <StatusPayrollLine array={arrayDecider()} isModal={isModal} />
        {rejection_note && <ReasonNotes text={rejection_note} />}
      </div>
    );
  }
  return (
    <TransactionStatusFlow item={transaction} status={transaction?.state} />
  );
};
