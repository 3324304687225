import React, { useEffect, useState } from "react";
import { BankCard } from "../../components/bankCards";
import { CustomButton, OutlineButton } from "../../components/Buttons";
import {
  MainCardHeaderMolecule,
  MiddleWrapper,
  RightCardModalAtomic,
} from "../../components/CardModal";
import { arrayToObjByID } from "../../components/tools";
import { ToasterHook } from "../../contexts/ToasterContext";
import { useMutation } from "../../tools/api";
import { eventsTracker } from "../../universalFunctions/events";
import { BankAccountFormModal, useAddBank } from "./bankAccountFormModal";
import { banksFormatter } from "./finalUploadReceiptModal";

export const BankSelectionModal = ({
  toggle,
  isOpen,
  data,
  setData,
  isClose = true,
  onSubmit,
  selectedId,
}) => {
  const [middleHeight, setMiddleHeight] = useState(0);
  const [activeId, setActiveId] = useState(selectedId);

  useEffect(() => {
    if (data.length !== 1) return;
    setActiveId(data[0]?.id);
  }, [data.length]);

  const { successToaster } = ToasterHook();

  const middleData = data[0];
  const bodyData = data.filter((_, index) => index !== 0);

  const onClick = ({ id }) => {
    const isReselect = id == activeId;
    if (isReselect) return setActiveId(false);
    setActiveId(id);
  };

  // handleDelete
  const [deleteId, setDeleteId] = useState(false);

  const onDelete = async (id) => setDeleteId(id);

  const afterSuccessDelete = () => {
    // resets deleteId and updates setData
    setData((prev) => prev.filter(({ id: prevId }) => prevId != deleteId));
    if (deleteId == activeId) setActiveId(false);

    eventsTracker("delete_bank", { id: deleteId });
    setDeleteId(false);
  };

  const { mutation: handleDelete } = useMutation({
    url: `/partner_bank_accounts/${deleteId}`,
    method: "delete",
    afterSuccess: afterSuccessDelete,
  });

  useEffect(() => {
    if (!deleteId) return;
    handleDelete();
  }, [deleteId]);
  // handleDelete

  const handleChoose = (idRaw) => {
    const id = idRaw || activeId;
    eventsTracker("choose_bank", { id });
    onSubmit && onSubmit(id);
  };

  // edit bank
  const [defaultValues, setDefaultValues] = useState(null);
  const [editId, setEditId] = useState(0);

  const openEditModal = (id) => {
    const object = arrayToObjByID(data, id);
    const { bank_name, account_name, account_number } = object;
    const result = { bank_name, account_name, account_number };
    eventsTracker("to_edit_bank", { id });
    setDefaultValues(result);
    setEditId(id);
  };

  const afterSuccessEdit = (res) => {
    successToaster({ msg: "Bank account updated" });

    const { data = {} } = res?.data || {};
    const { id } = data;

    setActiveId(id);

    setData((prev) => {
      const newData = prev.map((item) => {
        const { id: itemId } = item;
        if (itemId == id) return data;
        return item;
      });
      const result = banksFormatter({ data: newData });
      return result;
    });
    eventsTracker("edit_bank", { id: editId });
    setDefaultValues(null);
    // handleChoose(id);
  };

  const { mutation: handleSubmitEdit, loading: loadingEdit } = useMutation({
    url: `/partner_bank_accounts/${editId}`,
    afterSuccess: afterSuccessEdit,
  });
  // edit bank

  // adds bank
  const { isOpenAddBank, loadingAddBank, onSubmitAddBank, toggleAddBank } =
    useAddBank({
      setActiveId,
      setData,
      toFinal: handleChoose,
    });

  // adds bank
  const cardProps = (item) => ({
    item,
    isActive: activeId == item?.id,
    onClick,
    onDelete,
    onEdit: openEditModal,
    woDelete: selectedId == item?.id,
  });

  const hideModal = isOpenAddBank || Boolean(defaultValues);

  return (
    <>
      <RightCardModalAtomic
        isOpen={isOpen}
        hideModal={hideModal}
        middleHeight={middleHeight}
        header={
          <MainCardHeaderMolecule
            isClose={isClose}
            middleHeight={middleHeight}
            toggle={toggle}
            title="Where Do You Send Your Money From"
          />
        }
        middle={
          <MiddleWrapper
            setHeight={setMiddleHeight}
            height={middleHeight}
            style={{ zIndex: 1 }}
          >
            <BankCard {...cardProps(middleData)} />
          </MiddleWrapper>
        }
        body={
          <>
            {bodyData.map((item, index) => (
              <BankCard
                key={index}
                {...cardProps(item)}
                style={{ marginBottom: 24 }}
              />
            ))}
            <OutlineButton
              className="w-100"
              style={{ fontSize: 16, marginBottom: 16 }}
              onClick={() => {
                eventsTracker("to_add_bank");
                toggleAddBank();
              }}
            >
              Add Bank Account
            </OutlineButton>
            <CustomButton
              className="w-100"
              disabled={!activeId}
              style={{ fontSize: 16 }}
              onClick={() => handleChoose(activeId)}
            >
              Choose Bank Account
            </CustomButton>
          </>
        }
      />
      {/* Edits Bank Account */}
      {Boolean(defaultValues) && (
        <BankAccountFormModal
          isClose={false}
          isEdit
          loading={loadingEdit}
          isOpen={Boolean(defaultValues)}
          toggle={() => setDefaultValues(null)}
          defaultValues={defaultValues}
          onSubmit={handleSubmitEdit}
        />
      )}
      {/* Edits Bank Account */}
      {/* Create New Bank Account */}
      <BankAccountFormModal
        isClose={false}
        isOpen={isOpenAddBank}
        toggle={toggleAddBank}
        onSubmit={onSubmitAddBank}
        loading={loadingAddBank}
      />
      {/* Create New Bank Account */}
    </>
  );
};
