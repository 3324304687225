import { isEmpty } from "lodash";
import React, { createContext, useContext, useState, useEffect } from "react";
import arrowBack from "../../assets/images/arrow-back.svg";
import downloadIcon from "../../assets/images/download-white-ribet.svg";
import gdJpgIcon from "../../assets/images/gd-jpg.svg";
import gdPdfIcon from "../../assets/images/gd-pdf.svg";
import { CustomButton } from "../../components/Buttons";
import { fileTypes } from "../../components/inputs";
import { GothamRegular } from "../../components/Text";
import { windowDimension } from "../../components/tools";
import { downloadApi } from "../../tools/api";
import { PDFOrImageGdriveViewer } from "./viewer";
import { urlDecider } from "../../tools/api";

const GoogleDrivePreviewContext = createContext();

const arrowProps = (array, activeIndex) => {
  const maxIndex = array.length - 1;
  const isActiveLeft = activeIndex > 0;
  const isActiveRight = activeIndex < maxIndex;

  return { isActiveLeft, isActiveRight };
};

export const GoogleDrivePreviewProvider = ({ children }) => {
  const [array, setArray] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);

  const { isActiveLeft, isActiveRight } = arrowProps(array, activeIndex);

  const file = array[activeIndex] || {};

  const { name, type, url } = file || {};
  const { isPDF } = fileTypes(type || url);

  const src = url
    ? `${urlDecider(
        process.env.NEXT_PUBLIC_TRANSFEZ_BUSINESS_WO_VERSION_URL
      )}${url}`
    : "";

  const close = () => {
    setArray([]);
  };

  const show = ({ files, index = 0 }) => {
    setActiveIndex(index);
    setArray(files);
  };

  const { height, width } = windowDimension();

  const isOpen = !!array.length;

  const isMultipleFile = array.length > 1;

  const handleClick = (type) => {
    if (type == "left") setActiveIndex((p) => p - 1);
    if (type == "right") setActiveIndex((p) => p + 1);
  };

  const ArrowButton = ({ onClick, isActive, children }) => (
    <CustomButton
      style={{
        backgroundColor: isActive ? "rgba(0, 0, 0, 0.9)" : "rgba(0, 0, 0, 0.4)",
        color: isActive ? "white" : "rgba(255,255,255,.8)",
        height: 40,
        width: 40,
        marginLeft: 3,
        marginRight: 3,
        transition: "all 0.3s linear",
        zIndex: 500002,
      }}
      onClick={() => isActive && onClick("left")}
    >
      {children}
    </CustomButton>
  );

  return (
    <GoogleDrivePreviewContext.Provider value={{ show }}>
      <>
        {children}
        <div
          style={{
            display: isOpen ? "" : "none",
            position: "absolute",
            left: 0,
            top: 0,
            backgroundColor: "rgba(0,0,0,.8)",
            height,
            width: "100%",
            zIndex: 500000, // stronk zIndex
          }}
        >
          <div
            style={{
              height: "5%",
              backgroundColor:
                "linear-gradient(180deg, rgba(37, 37, 37, 0.93) 0%, rgba(37, 37, 37, 0.52) 54.13%, rgba(37, 37, 37, 0.27) 80.99%, rgba(37, 37, 37, 0) 100%)",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "16px 20px",
            }}
          >
            <div
              style={{
                display: "flex",
                gap: 16,
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={close}
            >
              <img src={arrowBack} style={{ height: 16 }} />
              <img src={isPDF ? gdPdfIcon : gdJpgIcon} />
              <GothamRegular style={{ color: "white", fontSize: 16 }}>
                {name}
              </GothamRegular>
            </div>
            <div className="align-items-center">
              <img
                src={downloadIcon}
                style={{ cursor: "pointer" }}
                onClick={() =>
                  downloadApi({
                    url: src,
                    fileName: name,
                    isAxios: true,
                  })
                }
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "95%",
            }}
          >
            <PDFOrImageGdriveViewer isPDF={isPDF} src={src} />
          </div>
          <div
            style={{
              display: "flex",
              width: width,
              position: "absolute",
              bottom: "50%",
              justifyContent: "space-between",
            }}
          >
            {isMultipleFile && (
              <>
                <ArrowButton
                  onClick={() => handleClick("left")}
                  isActive={isActiveLeft}
                >
                  {"<"}
                </ArrowButton>
                <ArrowButton
                  onClick={() => handleClick("right")}
                  isActive={isActiveRight}
                >
                  {">"}
                </ArrowButton>
              </>
            )}
          </div>
        </div>
      </>
    </GoogleDrivePreviewContext.Provider>
  );
};

export const useGdrivehook = () => {
  const { show } = useContext(GoogleDrivePreviewContext);

  return { show };
};
