import React from "react";
import { colors } from "../../assets/colors";
import { BackHeader, CloseHeader } from "../../components/CardModal";
import {
  GothamMedium,
  GothamRegular,
  TypeFezNeueMedium,
} from "../../components/Text";
import { CenteredModal } from "../centeredComponents";

export const CenteredModalWithHeader = ({
  hideModal,
  isOpen,
  toggle,
  title,
  titlePrefixNumber,
  children,
  style,
  titleStyle,
  isBack,
  isNavButton,
  woClose,
  scrollable,
  closeModal,
  isBackAndClose,
  backModal,
  isBackOnly,
  isBlueTitle,
  managerInfo,
}) => {
  const CloseHeaderDecider = () => {
    if (woClose) return null;
    if (isBack) return null;
    if (isBackOnly)
      return <BackHeader color={colors.pink} toggle={backModal} />;
    if (isBackAndClose)
      return (
        <>
          <BackHeader color={colors.pink} toggle={backModal} />
          <CloseHeader color={colors.pink} toggle={toggle || closeModal} />
        </>
      );
    return <CloseHeader color={colors.pink} toggle={toggle || closeModal} />;
  };
  return (
    <CenteredModal
      hideModal={hideModal}
      isOpen={isOpen}
      toggle={toggle}
      style={style}
      scrollable={scrollable}
    >
      <div className="d-flex justify-content-between ">
        {isBack && isNavButton && (
          <BackHeader color={colors.pink} toggle={toggle} />
        )}
        <CloseHeaderDecider />
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "8px",
          position: "relative",
        }}
      >
        {titlePrefixNumber && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "32px",
              height: "32px",
              borderRadius: "32px",
              background: "#2C4A77",
            }}
          >
            <TypeFezNeueMedium
              woFontColor
              style={{ color: "white", fontSize: "16px", lineHeight: "24px" }}
            >
              {titlePrefixNumber}
            </TypeFezNeueMedium>
          </div>
        )}
        <GothamMedium
          className="font24"
          style={{
            marginTop: 8,
            marginBottom: 8,
            ...titleStyle,
            color: isBlueTitle ? "#102C57" : null,
          }}
        >
          {title}
        </GothamMedium>
        {managerInfo && managerInfo}
      </div>
      {children}
    </CenteredModal>
  );
};

export const KYBUploadNotes = ({ notes }) => {
  if (!notes) return null;
  return (
    <div
      style={{
        padding: 12,
        border: "1px solid #C2C2C2",
        borderRadius: 8,
        marginBottom: 24,
        maxHeight: 100,
        overflowY: "scroll",
      }}
    >
      <GothamRegular style={{ color: colors.grey25 }}>Notes:</GothamRegular>
      <GothamRegular style={{ color: colors.grey25 }}>{notes}</GothamRegular>
    </div>
  );
};
