import { titleCase } from "change-case";
import React, { useRef } from "react";
import xIcon from "../../assets/images/x-icon.svg";
import { useClickOutside } from "../../universalFunctions/useClickOutside";
import { useModalHook } from "../Modals";
import { DefaultQueryInput } from "../SearchAndFilterCard";
import { GothamMedium, GothamRegular } from "../Text";
import { customDateFormatter, formatCurrency } from "../tools";
import { DropdownFilterFields } from "./dropdown";
import { FilterFieldsGenerator } from "./fieldsGenerator";

export const DefaultFilterFields = ({
  placeholder,
  data,
  woSearch,
  isModal,
  queryName,
  woQuery,
  onChange,
  inputStyle,
  withNewFilter: activityFilter = false,
  buttons,
  filterList,
  woSearchIcon = false,
  deleteFilter = () => {},
  resetFilter = () => {},
  woDebounce,
  disabled,
  isNumber,
  containerHeight,
  containerStyle,
  defaultValue,
  stickyPlaceholder,
  maxLength,
  autoFocus,
}) => {
  const isLongPlaceholder = placeholder?.length > 33;
  const flattenedData = (data || []).flat(1);
  const moreFilterData = flattenedData.slice(3, flattenedData.length) || [];

  const hasDots = Boolean(moreFilterData.length);

  const ref = useRef();
  const { isOpen, open, close } = useModalHook();

  useClickOutside({ ref, clickOutside: close });

  let dateArray = [];

  const filterKeys =
    activityFilter &&
    Boolean(filterList.length) &&
    filterList.map((filter) => {
      const key = Object.keys(filter)[0];
      return key;
    });

  return (
    <div>
      <div
        className={`w-100 d-flex ${
          woSearch
            ? "justify-content-start"
            : activityFilter
            ? "justify-content-start"
            : "justify-content-between"
        }`}
      >
        {!woSearch && (
          <div
            style={{
              width: isModal ? "100%" : isLongPlaceholder ? 375 : 264,
              position: "relative",
              height: containerHeight,
              ...containerStyle,
            }}
          >
            <DefaultQueryInput
              placeholder={placeholder}
              queryName={queryName}
              woQuery={woQuery}
              onChange={onChange}
              inputStyle={inputStyle}
              woSearchIcon={woSearchIcon}
              woDebounce={woDebounce}
              disabled={disabled}
              isNumber={isNumber}
              defaultValue={defaultValue}
              stickyPlaceholder={stickyPlaceholder}
              maxLength={maxLength}
              autoFocus={autoFocus}
            />
          </div>
        )}
        {!activityFilter && (
          <div className="d-flex" style={{ position: "relative", zIndex: 3 }}>
            <FilterFieldsGenerator data={flattenedData} />
            {hasDots && (
              <DropdownFilterFields
                ref={ref}
                toggle={open}
                data={moreFilterData}
                isOpen={isOpen}
              />
            )}
          </div>
        )}
        {activityFilter && (
          <div
            style={{
              paddingTop: "5px",
              display: "flex",
              gap: "8px",
              marginLeft: "8px",
            }}
          >
            {buttons}
          </div>
        )}
      </div>
      {activityFilter && Boolean(filterList.length) && (
        <div
          style={{
            display: "flex",
            gap: "8px",
            flexWrap: "wrap",
          }}
        >
          {filterList.map((filter, index) => {
            const key = Object.keys(filter)[0];
            let isArray = Array.isArray(filter[Object.keys(filter)[0]]);
            let text = Object.values(filter)[0];

            const realText = Array.isArray(text) ? text : [0];

            const isKeywords = key === "search_box_keywords";
            const isProduct = key === "product";
            const isStatus = key === "status";
            const isSourceAccount = key === "source_account";
            const isTeam = key === "team";
            const isFromDate = key === "from_date";
            const isToDate = key === "to_date";
            const isDirection = key === "direction";
            const isCreatedBy = key === "created_by";
            const isCard = key === "card";
            const isEWallet = key === "e_wallet_user";
            const isMinimumAmount = key === "minimum_amount";
            const isMaximumAmount = key === "maximum_amount";
            const isSpecificAmount = key === "specific_amount";
            const isRecentKeywords = key === "recent_keywords";

            const year = (isFromDate || isToDate) && text.split(" ")[3];

            const shortMonth = (isFromDate || isToDate) && text.split(" ")[1];
            const shortDate =
              (isFromDate || isToDate) &&
              customDateFormatter(text, false, true).split(" ")[0];

            if (isFromDate) {
              dateArray.push(`${shortMonth} ${shortDate} ${year}`);
              dateArray.push(`Now`);

              if (filterKeys.includes("to_date")) return null;
            }

            if (isToDate) {
              dateArray[1] = `${shortMonth} ${shortDate} ${year}`;
            }

            if (isKeywords) {
              text = `"${text}"`;
            }

            if (isMinimumAmount) {
              text = `Minimum Amount ${formatCurrency(text)}`;
            }

            if (isMaximumAmount) {
              text = `Maximum Amount ${formatCurrency(text)}`;
            }

            if (isSpecificAmount) {
              text = `Specific Amount ${formatCurrency(text)}`;
            }

            if (
              isProduct ||
              isStatus ||
              isStatus ||
              isSourceAccount ||
              isTeam ||
              isCreatedBy ||
              isCard ||
              isEWallet
            ) {
              isArray = true;
              text = realText;
            }

            if (isDirection && text === "Any") return null;

            if (isDirection && text === "In (e.g. top up, refund)")
              text = "Money in";
            if (isDirection && text === "Out (e.g. send money, payment)")
              text = "Money out";

            if (isRecentKeywords) {
              const keywordArr = Array.isArray(text) ? text : [text];

              const keywordJoined =
                keywordArr.length === 1
                  ? keywordArr.join("")
                  : keywordArr.join(" ");

              text = `"${keywordJoined}"`;
            }

            const arrayText = `${titleCase(Object.keys(filter)[0])} (${
              text.length
            })`;

            const isDirectionText =
              text.includes("Money in") || text.includes("Money out");

            const isHasAttachmentText =
              text.includes("Has attachment") || text.includes("No attachment");

            const isKeywordsText = text.includes(`"`);

            const isAmountText = text.includes("Amount");

            const textOutputDecider = () => {
              if (isArray && !isRecentKeywords) return arrayText;
              if (
                isDirectionText ||
                isHasAttachmentText ||
                isKeywordsText ||
                isAmountText
              )
                return text;
              if (Boolean(dateArray.length))
                return `${dateArray[0]} - ${dateArray[1]}`;
              return text;
            };

            return (
              <div
                key={index}
                style={{
                  height: "32px",
                  padding: "4px 8px 4px 8px",
                  borderRadius: "8px",
                  backgroundColor: "#EAEAEA",
                  marginBottom: "12px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: "4px",
                }}
              >
                <GothamRegular style={{ fontSize: "12px" }}>
                  {textOutputDecider()}
                </GothamRegular>
                <img
                  onClick={() => {
                    deleteFilter(filter, index);
                  }}
                  style={{ cursor: "pointer" }}
                  src={xIcon}
                />
              </div>
            );
          })}
          <div
            style={{
              width: "100px",
              height: "32px",
              display: "flex",
              padding: "8px",
              alignItems: "center",
            }}
          >
            <GothamMedium
              onClick={resetFilter}
              style={{ color: "#DA649F", cursor: "pointer" }}
            >
              Reset Filter
            </GothamMedium>
          </div>
        </div>
      )}
    </div>
  );
};
