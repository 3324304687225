import React, { useEffect, useState } from "react";
import {
  MiddleWrapper,
  RightCardModalAtomic,
} from "../../components/CardModal";
import { ActivityDetailsHeader } from "./activityModalComponents";
import refundIcon from "../../assets/images/refund-icon-40.svg";
import refundIcon32 from "../../assets/images/refund-icon-32-world.svg";
import {
  customDateFormatter,
  formatCurrencyNoDecimal,
} from "../../components/tools";
import { LeftRightList } from "../../components/Lists";
import { TransactionActivities } from "../../pageComponents/accountCardDetailsComponents/lists";
import { singleFormatter } from "../../pageComponents/accountCardDetailsComponents/logics/single";
import { useModalHook } from "../../components/Modals";
import { CrossBorderSingleDetailsModal } from "./crossBorderSingleModal/parent";
import { fetch } from "../../tools/api";
import { batchFormatter } from "../../pageComponents/accountCardDetailsComponents/logics/batch";
import { CrossBorderBatchModal } from "./crossBorderBatchModal/parent";
import { LabelDeciderModal, StatusLabel } from "../../components/Status";
import { CenterendIconLocalTransfer } from "./localDisbursementBatchModal/transactionModal";
import { TransactionListMinimalist } from "./transactionList";
import { LocalDisbursementModal } from "./localDisbursementModal/parent";

const formatter = (data, isBatch) => {
  const { created_at } = data || {};

  const formattedData = singleFormatter(data);
  const leftBottom = customDateFormatter(created_at, true);
  const batchData = batchFormatter(data);

  const leftTopFormatter = (object) => {
    const safeString = object?.leftTop || "";
    const result = safeString.includes("- ")
      ? safeString.split("- ")[1]
      : safeString;
    return result;
  };

  const result = isBatch
    ? {
        ...batchData,
        leftTop: leftTopFormatter(batchData),
        leftBottom,
        leftTag: "",
        category: "batch_cross_border",
        icon: refundIcon32,
      }
    : {
        ...formattedData,
        leftTop: leftTopFormatter(formattedData),
        leftBottom,
        leftTag: "",
        category: "single_cross_border",
        icon: refundIcon32,
      };
  return result;
};

export const RefundDetailsModal = ({ isOpen, toggle, data }) => {
  const {
    reference_number,
    created_at,
    amount,
    description,
    details,
    status,
    originator_id,
    originator_type,
    category,
  } = data || {};
  const isLocalRefund =
    category === "refund_transaction" && originator_type === "Hub::Transaction";

  const [middleHeight, setMiddleHeight] = useState(0);

  const { transaction } = details || {};
  const { batch_id, id: idTransaction } = transaction || {};
  const id = idTransaction || originator_id;
  const isBatch = Boolean(batch_id);

  const { data: mixedTransaction, refetch } = fetch({
    url: isBatch
      ? `/business_transactions/${id}`
      : `/partner_transactions/${id}`,
    defaultValue: transaction,
    formatter: (res) => res?.data,
    woInit: true,
  });

  const { state, reference_id } = mixedTransaction;
  const reference = isLocalRefund ? reference_number : reference_id;

  useEffect(() => {
    if (!id) return;
    if (!isOpen) return;
    if (isLocalRefund) return;
    refetch();
  }, [id, isOpen]);

  const transactionItem = formatter(data, isBatch);

  const headerData = {
    // headerLeft: reference_number,
    // headerRight: customDateFormatter(created_at),
    type: description,
    icon: false,
    title: `IDR ${formatCurrencyNoDecimal(amount)}`,
    // text: "Indonesian Account",
  };
  const { isOpen: isOpenTransaction, toggle: toggleTransaction } =
    useModalHook();

  const maskingStatus = (state) => {
    if (state === "refunded") return "success";
    if (state === "awaiting_refund") return "on_progress";
    return state;
  };

  const maskedState = maskingStatus(state || status);

  return (
    <>
      <RightCardModalAtomic
        isOpen={isOpen}
        hideModal={isOpenTransaction}
        header={<ActivityDetailsHeader toggle={toggle} data={headerData} />}
        headerStyle={{ paddingBottom: 48 }}
        middleHeight={middleHeight}
        middle={
          <MiddleWrapper setHeight={setMiddleHeight} height={middleHeight}>
            <CenterendIconLocalTransfer icon={refundIcon} />
          </MiddleWrapper>
        }
        body={
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <StatusLabel status={maskedState} />
            </div>
            <div style={{ marginTop: 24 }}>
              <LeftRightList
                array={[
                  {
                    left: "Date",
                    right: customDateFormatter(created_at),
                  },
                  {
                    left: "Reference",
                    right: `${reference}`,
                  },
                  { spacer: true },
                ]}
              />
            </div>
            <TransactionListMinimalist
              data={[transactionItem]}
              onClick={toggleTransaction}
            />
            {/* <TransactionActivities
              style={{ marginBottom: 8 }}
              data={[transactionItem]}
              onClick={toggleTransaction}
            /> */}
          </>
        }
      />
      {isLocalRefund ? (
        <LocalDisbursementModal
          isClose={false}
          isOpen={isOpenTransaction}
          toggle={toggleTransaction}
          data={data}
        />
      ) : isBatch ? (
        <CrossBorderBatchModal
          isOpen={isOpenTransaction}
          toggle={toggleTransaction}
          data={transactionItem}
        />
      ) : (
        <CrossBorderSingleDetailsModal
          isClose={false}
          isOpen={isOpenTransaction}
          toggle={toggleTransaction}
          data={transactionItem}
        />
      )}
    </>
  );
};
