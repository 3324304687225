import { useState } from "react";
import { RightCardModalAtomic } from "../../../components/CardModal";
import { MainCardHeaderMolecule } from "../../../components/CardModal";
import { CustomButton } from "../../../components/Buttons";
import {
  GothamMedium,
  GothamRegular,
  GothamBold,
} from "../../../components/Text";
import crossBorderIcon from "../../../assets/images/crossborder-icon-40.svg";
import {
  useGetAuth,
  refetchUser,
  getUserRole,
} from "../../../contexts/AuthContext";
import rightArrow from "../../../assets/images/caret-right-white-icon.svg";
import { colors } from "../../../assets/colors";
import {
  formatCurrency,
  formatCurrencyNoDecimal,
} from "../../../components/tools";
import { MiddleWrapper } from "../../../components/CardModal";
import { CenterendIconLocalTransfer } from "../localDisbursementBatchModal/transactionModal";
import refreshIcon from "../../../assets/images/replace-card-icon-pink.svg";
import warningCircleIcon from "../../../assets/images/warning-circle-red.svg";

export const ReleasePaymentModal = ({
  isOpen,
  toggle,
  data,
  onClick,
  onClickTopUp,
}) => {
  if (!isOpen) return null;
  const { user, refetchUser } = useGetAuth();
  const { mainBalance } = user;
  const { balance } = mainBalance;

  const { isEmployee } = getUserRole();

  const {
    total_trx_amount: transferAmount,
    batch,
    total_amount: total_amountParent,
    total_fee: total_feeParent,
    fee: feeParent,
    amount,
    local_transactions: local_transactionsParent = [],
    local_transaction,
    transaction_type: transaction_typeParent,
    sent_amount,
  } = data;

  const {
    transaction_type,
    total_amount,
    total_fee,
    local_transactions = [],
  } = batch || {};

  const originatorName = local_transaction?.originator_name ?? "";

  const isPayroll = transaction_type === "payroll";
  const isLocalTransfer = transaction_typeParent === "transfer";
  const isCrossBorderSingle = transaction_typeParent === "Single";
  const isInvoice = originatorName === "InvoiceTransaction";
  const isReimbursement = originatorName === "Reimbursement";

  const amountDecider = () => {
    if (batch && transaction_type === "payroll")
      return Number(total_amount || total_amountParent);

    if (isCrossBorderSingle) return Number(sent_amount);
    if (transferAmount) return transferAmount;

    const defaultAmount = Number(total_amountParent);

    return defaultAmount || amount;
  };

  const feeDecider = () => {
    if (isPayroll) return total_fee;
    if (isLocalTransfer) return total_feeParent;
    if (isCrossBorderSingle) return Number(feeParent);
    if (isInvoice) return Number(local_transaction?.fee);
    if (isReimbursement) return Number(local_transaction?.fee);

    if (total_feeParent) return total_feeParent;
    if (local_transactionsParent?.fee) return local_transactionsParent.fee;
  };

  const isBatchTransfer =
    local_transactionsParent.length > 0 || local_transactions.length > 0;

  const individualDefaultFeeDecider = () => {
    if (isPayroll) return local_transactions[0]?.default_fee;
    if (isLocalTransfer) return local_transactionsParent[0]?.default_fee;
    if (isInvoice) return local_transaction.default_fee;
    if (isReimbursement) return local_transaction.default_fee;

    return 0;
  };

  const individualDefaultFee = Number(individualDefaultFeeDecider());

  const totalTrxDecider = () => {
    if (isPayroll) return local_transactions.length;
    if (isLocalTransfer) return local_transactionsParent.length;

    return 0;
  };

  const totalTrx = Number(totalTrxDecider());

  const totalFeeDecider = () => {
    if (isPayroll) return total_fee;
    if (isLocalTransfer) return total_feeParent;
  };

  const totalFee = Number(totalFeeDecider());

  const allTrxFeeAreFree = totalFee === 0;
  const someTrxFeeAreFree =
    totalFee < individualDefaultFee * totalTrx && !allTrxFeeAreFree;
  const allTrxFeeAreNotFree = totalFee === individualDefaultFee * totalTrx;

  const isFreeFee = Number(feeDecider()) === 0;
  const fee = isFreeFee ? "Free" : `IDR ${formatCurrency(feeDecider())}`;

  const totalPayment = Number(feeDecider()) + Number(amountDecider());

  const remainingBalance =
    balance - Number(amountDecider()) - Number(feeDecider());
  const isSufficient = remainingBalance >= 0 ? true : false;

  const showTopup = !isSufficient && !isEmployee;

  const [middleHeight, setMiddleHeight] = useState(53);

  const defaultStyle = {
    height: 32,
    fontSize: 14,
  };

  const feeSectionDecider = () => {
    if (!isBatchTransfer)
      return (
        <tr>
          <td className="pb-2">
            <GothamRegular>Total fee</GothamRegular>
          </td>
          <td className="pb-2" style={{ textAlign: "right" }}>
            {isFreeFee ? (
              <GothamMedium style={{ color: colors.pink }}>
                {fee}{" "}
                {individualDefaultFee > 0 ? (
                  <span
                    style={{
                      fontFamily: "GothamBook",
                      color: colors.greyd1,
                      textDecoration: "line-through",
                      marginLeft: "2px",
                    }}
                  >
                    IDR {formatCurrencyNoDecimal(Number(individualDefaultFee))}
                  </span>
                ) : (
                  ""
                )}
              </GothamMedium>
            ) : (
              <GothamRegular>{fee}</GothamRegular>
            )}
          </td>
        </tr>
      );

    if (allTrxFeeAreFree) {
      return (
        <tr>
          <td className="pb-2">
            <GothamRegular>
              Total fee{" "}
              {individualDefaultFee > 0
                ? `= IDR ${formatCurrencyNoDecimal(
                    Number(individualDefaultFee)
                  )} x ${totalTrx}`
                : ""}
            </GothamRegular>
          </td>
          <td className="pb-2" style={{ textAlign: "right" }}>
            {isFreeFee ? (
              <GothamMedium style={{ color: colors.pink }}>
                {fee}{" "}
                {individualDefaultFee > 0 ? (
                  <span
                    style={{
                      fontFamily: "GothamBook",
                      color: colors.greyd1,
                      textDecoration: "line-through",
                      marginLeft: "2px",
                    }}
                  >
                    IDR{" "}
                    {formatCurrencyNoDecimal(
                      Number(individualDefaultFee * totalTrx)
                    )}
                  </span>
                ) : (
                  ""
                )}
              </GothamMedium>
            ) : (
              <GothamRegular>{fee}</GothamRegular>
            )}
          </td>
        </tr>
      );
    }

    if (someTrxFeeAreFree) {
      const totalFreeFeeAmount =
        Number(individualDefaultFee) * Number(totalTrx) - Number(totalFee);
      const totalFreeFeeTrx = totalFreeFeeAmount / Number(individualDefaultFee);
      const totalPaidFeeTrx = Number(totalTrx) - totalFreeFeeTrx;

      return (
        <>
          <tr>
            <td className="pb-2">
              <GothamMedium style={{ color: colors.grey25 }}>
                Transfez fee
              </GothamMedium>
            </td>
            <td className="pb-2" style={{ textAlign: "right" }}>
              <GothamMedium style={{ color: colors.grey25 }}>
                IDR {formatCurrencyNoDecimal(Number(totalFee))}
              </GothamMedium>
            </td>
          </tr>
          <tr>
            <td className="pb-2">
              <GothamRegular
                style={{
                  color: colors.grey6c,
                }}
                className="ml-4"
              >
                {totalFreeFeeTrx} Transaction(s)
              </GothamRegular>
            </td>
            <td className="pb-2" style={{ textAlign: "right" }}>
              <GothamMedium
                style={{
                  color: colors.pink,
                }}
              >
                Free
                {individualDefaultFee > 0 ? (
                  <span
                    style={{
                      fontFamily: "GothamBook",
                      color: colors.greyd1,
                      textDecoration: "line-through",
                      marginLeft: "4px",
                    }}
                  >
                    IDR{" "}
                    {formatCurrencyNoDecimal(
                      Number(individualDefaultFee * totalTrx)
                    )}
                  </span>
                ) : (
                  ""
                )}
              </GothamMedium>
            </td>
          </tr>
          <tr>
            <td className="pb-2">
              <GothamRegular
                style={{
                  color: colors.grey6c,
                }}
                className="ml-4"
              >
                {totalPaidFeeTrx} Transaction{totalPaidFeeTrx > 1 ? "s" : ""}
              </GothamRegular>
            </td>
            <td className="pb-2" style={{ textAlign: "right" }}>
              <GothamRegular
                style={{
                  color: colors.grey6c,
                }}
              >
                IDR {formatCurrencyNoDecimal(Number(totalFee))}
              </GothamRegular>
            </td>
          </tr>
        </>
      );
    }

    if (allTrxFeeAreNotFree) {
      return (
        <tr>
          <td className="pb-2">
            <GothamRegular>
              Total fee{" "}
              {`= IDR ${formatCurrencyNoDecimal(
                Number(individualDefaultFee)
              )} x ${totalTrx}`}
            </GothamRegular>
          </td>
          <td className="pb-2" style={{ textAlign: "right" }}>
            <GothamRegular>{fee}</GothamRegular>
          </td>
        </tr>
      );
    }
  };

  return (
    <RightCardModalAtomic
      isOpen={isOpen}
      toggle={toggle}
      middleHeight={middleHeight}
      header={
        <MainCardHeaderMolecule
          isClose={false}
          toggle={toggle}
          middleHeight={middleHeight}
          title={"Release payment"}
          alignLeft
        />
      }
      middle={
        <MiddleWrapper height={middleHeight} setHeight={setMiddleHeight}>
          <CenterendIconLocalTransfer icon={crossBorderIcon} />
        </MiddleWrapper>
      }
      body={
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            {showTopup && (
              <div
                style={{
                  display: "flex",
                  backgroundColor: colors.lightYellowFF,
                  padding: 12,
                  borderRadius: 8,
                  border: `1px solid ${colors.yellowFC}`,
                  marginBottom: 12,
                  alignItems: "center",
                }}
              >
                <GothamRegular style={{ color: colors.grey6c }}>
                  You don't have enough amount
                </GothamRegular>
                <CustomButton
                  style={{
                    width: 80,
                    marginLeft: "auto",
                    height: 24,
                    fontSize: 12,
                  }}
                  onClick={onClickTopUp}
                >
                  Top Up
                </CustomButton>
              </div>
            )}
            <GothamMedium className="font16">Payment Summary</GothamMedium>
            <table
              className="font14"
              style={{
                color: colors.grey72,
                marginTop: 12,
              }}
            >
              {!isSufficient ? (
                <tr>
                  <td className="pb-2">
                    <GothamRegular className="d-inline-flex">
                      Available balance
                    </GothamRegular>
                    <img
                      className="d-inline-flex"
                      src={warningCircleIcon}
                      style={{ marginLeft: 6 }}
                    />
                  </td>
                  <td className="pb-2" style={{ textAlign: "right" }}>
                    <GothamRegular className="d-inline-flex">
                      IDR {formatCurrency(balance)}
                    </GothamRegular>
                    <img
                      className="d-inline-flex"
                      src={refreshIcon}
                      style={{ cursor: "pointer", marginLeft: 6 }}
                      onClick={refetchUser}
                    />
                  </td>
                </tr>
              ) : (
                <tr>
                  <td className="pb-2">
                    <GothamRegular>Available balance</GothamRegular>
                  </td>
                  <td className="pb-2" style={{ textAlign: "right" }}>
                    <GothamRegular>IDR {formatCurrency(balance)}</GothamRegular>
                  </td>
                </tr>
              )}
              <tr>
                <td className="pb-2">
                  <GothamRegular>Total amount</GothamRegular>
                </td>
                <td className="pb-2" style={{ textAlign: "right" }}>
                  <GothamRegular>
                    IDR {formatCurrency(amountDecider())}
                  </GothamRegular>
                </td>
              </tr>
              {feeSectionDecider()}
              <tr
                style={{
                  borderTop: "1.5px dashed",
                  borderColor: colors.greyC2,
                }}
              >
                <td className="pt-2">
                  <GothamMedium
                    className="font16"
                    style={{ color: colors.grey33 }}
                  >
                    Total Payment
                  </GothamMedium>
                </td>
                <td className="pt-2" style={{ textAlign: "right" }}>
                  <GothamBold style={{ color: colors.grey33 }}>
                    IDR {formatCurrency(totalPayment)}
                  </GothamBold>
                </td>
              </tr>
              <tr>
                <td>
                  <GothamRegular>Remaining balance</GothamRegular>
                </td>
                <td style={{ textAlign: "right" }}>
                  <GothamRegular>
                    IDR {formatCurrency(remainingBalance)}
                  </GothamRegular>
                </td>
              </tr>
            </table>
            {!isSufficient ? (
              <CustomButton
                rightIcon={<img src={rightArrow} />}
                style={{
                  marginTop: 16,
                  width: "100%",
                  ...defaultStyle,
                }}
                disabled
              >
                Continue
              </CustomButton>
            ) : (
              <CustomButton
                rightIcon={<img src={rightArrow} />}
                style={{
                  marginTop: 16,
                  width: "100%",
                  ...defaultStyle,
                }}
                onClick={onClick}
              >
                Continue
              </CustomButton>
            )}
          </div>
        </>
      }
    />
  );
};
