import React, { createContext, useState, useContext, useEffect } from "react";
import { Slide, toast, ToastContainer } from "react-toastify";
import warningIcon from "../assets/images/warning-circle-white.svg";
import successIcon from "../assets/images/success-circle.svg";
import { OldGothamMedium, OldGothamRegular } from "../components/Text";
import { windowDimension } from "../components/tools";
import { useRouter } from "next/router";
import { errorApiDecider } from "../tools/api";
import { titleCase } from "change-case";
import * as Sentry from "@sentry/nextjs";

export const ToasterContext = createContext();

export const ToasterProvider = ({ children }) => {
  const { figmaWidth } = windowDimension();
  const { push } = useRouter();
  const [timer, setTimer] = useState(3000);
  const [errorType, setErrorType] = useState(false);
  const [showToast, setShowToast] = useState(false);

  useEffect(() => setTimeout(() => setShowToast(true), 2000), []);

  const Toaster = ({ msg, title, isSuccess }) => (
    <div className="p-2 pt-3 d-flex align-items-center">
      <div
        style={{ width: "18%" }}
        className="d-flex justify-content-center align-items-center "
      >
        <img src={isSuccess ? successIcon : warningIcon} />
      </div>
      <div>
        <OldGothamMedium style={{ fontSize: 16 }} className="my-0">
          {title}
        </OldGothamMedium>
        {Boolean(msg) && (
          <OldGothamRegular
            className="mt-3"
            style={{ fontSize: 14, color: "white", lineHeight: "20px" }}
          >
            {msg}
          </OldGothamRegular>
        )}
      </div>
    </div>
  );

  const defaultSetToasters = (errorType, timeout) => {
    setErrorType(errorType);
    setTimer(timeout);
  };

  const errorToaster = (title, msg, timeOut = 3000) => {
    defaultSetToasters("error-toaster", timeOut);

    if (!showToast) return;

    toast.error(<Toaster title={title} msg={msg} />);
  };

  const successToaster = ({ title = "Success!", msg, timeOut = 2000 }) => {
    defaultSetToasters("success-toaster", timeOut);

    if (!showToast) return;

    toast.success(<Toaster isSuccess={true} title={title} msg={msg} />);
  };

  const warningToaster = ({ title = "Alert!", msg, timeOut = 2000 }) => {
    defaultSetToasters("warning-toaster", timeOut);

    if (!showToast) return;

    toast.warning(<Toaster title={title} msg={msg} />);
  };

  const errorToasterApi = (error, timeOut = 3000, woInternetChecker) => {
    const { invalidData, isNoInternet, isServerError, isUnauthorized } =
      errorApiDecider(error);

    if (isNoInternet && !woInternetChecker) return;

    const hasLoggedIn = JSON.parse(localStorage.getItem("user"));

    const msgDecider = () => {
      const { data, config, status } = error?.response || {};
      const { url, method, data: payload } = config || {};
      const errorString = data?.error?.message || String(data?.message) || "";
      const errorAnotherString = error?.message || "";
      const isArrayString =
        Array.isArray(data?.error) &&
        (data?.error || []).every((msg) => typeof msg == "string");

      const registerError =
        typeof data?.message == "object" && !Array.isArray(data?.message);

      const isDataErrorString = typeof data?.error == "string";

      const errorCaptureString = `Error API\nurl: ${url}\nmethod:${method} \nstatus: ${status}\npayload: ${payload} `;

      hasLoggedIn && Sentry.captureException(errorCaptureString);

      if (isDataErrorString) return data?.error;

      if (isArrayString) return (data?.error || []).join(" ");

      if (registerError) {
        const object = data?.message;
        const keys = Object.keys(object);

        const messages = keys.map((key) => {
          const value = object[key];
          return `${titleCase(key)} ${value}`;
        });
        const message = messages.flat(1)[0];
        return message;
      }

      if (isNoInternet) return "No Connection";

      if (isServerError)
        return "Oops, We're sorry there is a slight hiccup on our system";

      if (errorString) {
        const isUndefined = errorString == "undefined";
        if (!isUndefined) return errorString;
      }

      if (errorAnotherString) return errorAnotherString;

      if (invalidData)
        return "System cannot process your request. Please contact our Account Manager";

      return "Oops, We're sorry there is a slight hiccup on our system(s)";
    };

    const timeOutDecider = () => {
      if (isNoInternet || isServerError || invalidData) return 10000;
      return timeOut;
    };

    const msg = msgDecider();

    if (msg) {
      if (!showToast) return;
      if (isUnauthorized && hasLoggedIn) {
        warningToaster({ msg: "Your session is expired, please login again" });
      } else {
        errorToaster("Error!", msg, timeOutDecider());
      }
    }

    if (isUnauthorized) {
      const noToken = !localStorage.getItem("token");
      if (noToken) return;
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      push("/login");
      return;
    }
  };

  return (
    <ToasterContext.Provider
      value={{ errorToasterApi, errorToaster, successToaster, warningToaster }}
    >
      {children}
      <ToastContainer
        hideProgressBar
        autoClose={timer}
        position="top-right"
        transition={Slide}
        closeButton={false}
        style={{ minWidth: figmaWidth(380) }}
        toastClassName={`p-0 ${errorType}`}
      />
    </ToasterContext.Provider>
  );
};

export const ToasterHook = () => {
  const { errorToasterApi, errorToaster, successToaster, warningToaster } =
    useContext(ToasterContext);

  return { errorToasterApi, errorToaster, successToaster, warningToaster };
};
