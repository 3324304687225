import { colors } from "../../../../assets/colors";
import warningIcon from "../../../../assets/images/warning-yellow.svg";
import { GothamBold, GothamRegular } from "../../../../components/Text";

const Warning = ({ isSmall = false, title, text, icon, style }) => {
  const isTextString = typeof text === "string";

  return (
    <div
      style={{
        backgroundColor: colors.lightYellowFC,
        borderRadius: "8px",
        padding: "14px 12px 14px 12px",
        height: "auto",
        opacity: "1",
        display: "flex",
        gap: "14px",
        alignItems: "flex-start",
        transition: "all 0.25s linear",
        ...style,
      }}
    >
      <img src={icon ? icon : warningIcon} />
      <div>
        {title && (
          <GothamBold
            style={{ margin: 0, fontSize: isSmall ? 14 : 16, marginBottom: 4 }}
          >
            {title}
          </GothamBold>
        )}
        {isTextString ? (
          <GothamRegular style={{ fontSize: isSmall ? 12 : 14 }}>
            {text}
          </GothamRegular>
        ) : (
          text
        )}
      </div>
    </div>
  );
};

export default Warning;
