import { times } from "lodash";
import { useRouter } from "next/router";
import React, { useRef } from "react";
import Skeleton from "react-loading-skeleton";
import { colors } from "../../assets/colors";
import check from "../../assets/images/checklist-pink-circle-outline.svg";
import resendIcon from "../../assets/images/resend-callback-icon.svg";
import { DialogButton } from "../../components/Buttons";
import { CheckBox, useIdsTable } from "../../components/Checkbox";
import { booleanState, StatusLabel } from "../../components/Status";
import {
  GothamMedium,
  GothamRegular,
  TypeFezNeueRegular,
} from "../../components/Text";
import {
  canRefetchDecider,
  customSum,
  dateOnlyGetDayAndMonth,
  dateOnlyGetHour,
  formatCurrencyNoDecimal,
  widthOrHeight,
} from "../../components/tools";
import CustomTooltip from "../../components/Tooltip";
import { useGetAuth } from "../../contexts/AuthContext";
import { ToasterHook } from "../../contexts/ToasterContext";
import { useConfirmApi, useResendCallbackApi } from "./logics";

const TdStyled = ({ children }) => (
  <td style={{ padding: "12px 8px" }}>
    <GothamRegular style={{ color: colors.grey33 }}>{children}</GothamRegular>
  </td>
);

export const MaskingState = (state) => {
  const { isSubmitted, isConfirmed } = booleanState(state);
  if (isSubmitted || isConfirmed) return `on_progress`;
  return state;
};

const TdMultiLine = ({ date }) => {
  const top = dateOnlyGetDayAndMonth(date);
  const bottom = dateOnlyGetHour(date);
  return (
    <td style={{ padding: "10px 8px" }}>
      <GothamRegular style={{ color: colors.grey33 }}>{top}</GothamRegular>
      <GothamRegular
        style={{ color: colors.grey6c, fontSize: 12, lineHeight: "16px" }}
      >
        {bottom}
      </GothamRegular>
    </td>
  );
};

export const renderedName = (obj) => {
  const { registered_name, firstname, lastname } = obj || {};
  return registered_name || `${firstname || ""} ${lastname || ""}` || "";
};

export const TableApiDisbursement = ({
  data,
  refetch,
  loading,
  setSelectedItem,
  onScroll: onScrollProps,
  tableHeight: tableHeightProps,
  setData,
}) => {
  const tableBodyRef = useRef();

  const { query, push } = useRouter();
  const { confirm_transactions, resend_callback } = query;

  const { clientWidth } = widthOrHeight(tableBodyRef);

  const figmaRatio = (number) => (number / 988) * clientWidth;

  const { handleClickItem, handleClickAll, isAllChecked, selectedIds } =
    useIdsTable(data);

  const actionActive = !!confirm_transactions || !!resend_callback;

  const showFloatingButton = !!(actionActive && selectedIds.length);

  const tableHeight = showFloatingButton
    ? tableHeightProps - 80
    : tableHeightProps;

  const tableHeads = [
    !!actionActive && {
      title: <CheckBox isActive={isAllChecked} onClick={handleClickAll} />,
      width: figmaRatio(48),
    },
    { title: "Date", width: figmaRatio(80) },
    { title: "Reference ID", width: figmaRatio(133.6) },
    { title: "Status", width: figmaRatio(92) },
    { title: "Sender", width: figmaRatio(133.6) },
    { title: "Recipient", width: figmaRatio(133.6) },
    { title: "Amount", width: figmaRatio(133.6) },
    { title: "Fee", width: figmaRatio(100) },
    { title: "Total Amount", width: figmaRatio(133.6) },
    !actionActive && { title: "", width: figmaRatio(48) },
  ];

  const onScroll = () => {
    const canRefetch = canRefetchDecider(tableBodyRef, loading);
    if (!canRefetch) return;
    onScrollProps && onScrollProps();
  };

  const afterSuccess = (ids) =>
    setData((prev) => {
      const { array: arrayRaw } = prev;
      const array = arrayRaw.map((item) => {
        const { id } = item;
        if (ids.includes(id)) return { ...item, state: "confirmed" };
        return item;
      });
      return { ...prev, array };
    });

  const afterSuccessCallback = (ids, arr) => {
    setData((prev) => {
      const { array: arrayRaw } = prev;
      const array = arrayRaw.map((item) => {
        const { id } = item;
        const getIndex = ids.indexOf(id);
        const foundIndex = getIndex !== -1;
        if (foundIndex) return { ...arr[getIndex] };
        return item;
      });
      return { ...prev, array };
    });
    push({ query: { runQuery: true } });
  };

  const { loading: loadingMutation, mutation } = useConfirmApi({
    afterSuccess,
  });
  const { loading: loadingCallback, mutation: mutationCallback } =
    useResendCallbackApi({
      afterSuccess: afterSuccessCallback,
    });

  const loadingUseMutation = loadingMutation || loadingCallback;

  const { balance } = useGetAuth()?.user?.mainBalance || {};

  const { errorToaster } = ToasterHook();

  const onSubmit = ({ ids, type }) => {
    const isCallback = type === "resend_callback";
    const isConfirmed = type === "confirm_transaction";

    const amounts = data
      .filter(({ id }) => ids.includes(id))
      .map(({ amount }) => Number(amount));

    const getAmount = customSum(amounts);

    const isNotEnough = balance < getAmount;

    if (isNotEnough)
      return errorToaster("Error!", "Insufficient balance", 5000);

    if (resend_callback || isCallback) return mutationCallback(ids);
    if (confirm_transactions || isConfirmed) return mutation(ids);
  };

  return (
    <div style={{ position: "relative" }}>
      <div
        ref={tableBodyRef}
        onScroll={() => onScroll()}
        style={{
          height: tableHeight,
          overflowY: "scroll",
        }}
      >
        <table
          className="table-seamless mb-0"
          style={{
            position: "relative",
            tableLayout: "fixed",
            wordBreak: "break-word",
            overflow: "scroll",
          }}
        >
          <thead
            style={{
              position: "sticky",
              top: 0,
              boxShadow: `0px 0px 4px #E6E6E8`,
            }}
          >
            <tr>
              {tableHeads.map((item, index) => {
                if (!item) return null;
                const { title, width } = item;
                return (
                  <td
                    key={index}
                    style={{
                      padding: "12px 8px",
                      width,
                    }}
                  >
                    <GothamRegular>{title}</GothamRegular>
                  </td>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {data?.map((item, index) => {
              const {
                created_at,
                reference_id,
                state,
                fee,
                amount,
                sender,
                beneficiary,
                id,
                callback_url,
              } = item || {};
              const totalAmount = `IDR ${formatCurrencyNoDecimal(
                Number(amount) + Number(fee) || 0
              )}`;

              const isCreated = state == "created";

              const checkLeftDecider = () => {
                if (!actionActive) return null;
                if (resend_callback && isCreated && !callback_url)
                  return <td></td>;
                if (confirm_transactions && !isCreated) return <td></td>;
                const isActive = selectedIds.includes(id);
                return (
                  <td>
                    <div style={{ paddingLeft: 8 }}>
                      <CheckBox
                        isActive={isActive}
                        onClick={(e) => {
                          if (!e) return;
                          e.preventDefault();
                          e.stopPropagation();
                          handleClickItem(id, isActive);
                        }}
                      />
                    </div>
                  </td>
                );
              };

              const checkRightDecider = () => {
                if (actionActive || !callback_url) return <td></td>;
                if (!isCreated)
                  return (
                    <td>
                      <CustomTooltip
                        text={<span>Resend Callback</span>}
                        style={{
                          fontSize: 8,
                          lineHeight: "12px",
                          fontFamily: "TypefezNeueMedium",
                        }}
                      >
                        <DialogButton
                          notButton
                          onClick={() =>
                            onSubmit({ ids: [id], type: "resend_callback" })
                          }
                          text={`Are you sure you want to resend callback ${reference_id}?`}
                        >
                          <img
                            src={resendIcon}
                            className="hover"
                            style={{ width: 24 }}
                          />
                        </DialogButton>
                      </CustomTooltip>
                    </td>
                  );
                return (
                  <td>
                    <CustomTooltip
                      text={<span>Confirm Transaction</span>}
                      style={{
                        fontSize: 8,
                        lineHeight: "12px",
                        fontFamily: "TypefezNeueMedium",
                      }}
                    >
                      <DialogButton
                        notButton
                        onClick={() =>
                          onSubmit({ ids: [id], type: "confirm_transaction" })
                        }
                        text={`Are you sure you want to confirm ${reference_id}?`}
                      >
                        <img
                          src={check}
                          className="hover"
                          style={{ width: 24 }}
                        />
                      </DialogButton>
                    </CustomTooltip>
                  </td>
                );
              };

              return (
                <tr key={index} onClick={() => setSelectedItem(item)}>
                  {checkLeftDecider()}
                  <TdMultiLine date={created_at} />
                  <TdStyled>{reference_id}</TdStyled>
                  <td>
                    <StatusLabel status={MaskingState(state)} />
                  </td>
                  <TdStyled>{renderedName(sender)}</TdStyled>
                  <TdStyled>{renderedName(beneficiary)}</TdStyled>
                  <TdStyled>{`IDR ${formatCurrencyNoDecimal(
                    amount || 0
                  )}`}</TdStyled>
                  <TdStyled>{`IDR ${formatCurrencyNoDecimal(
                    fee || 0
                  )}`}</TdStyled>
                  <TdStyled>{totalAmount}</TdStyled>
                  {checkRightDecider()}
                </tr>
              );
            })}
            {loading && <ShimmerTable />}
          </tbody>
        </table>
      </div>
      {showFloatingButton && (
        <FloatingButton
          selectedIds={selectedIds}
          loading={loadingUseMutation}
          onSubmit={onSubmit}
          isConfirm={confirm_transactions}
        />
      )}
    </div>
  );
};

const ShimmerTable = ({ isScroll }) =>
  times(isScroll ? 5 : 10, (index) => (
    <tr key={index} className={"darkhover"}>
      {times(9, (index) => (
        <TdStyled key={index}>
          <Skeleton width={"100%"} height={20} />
        </TdStyled>
      ))}
    </tr>
  ));

const FloatingButton = ({ selectedIds, loading, onSubmit, isConfirm }) => {
  const title = isConfirm ? "Confirm Transactions" : "Resend Callback";
  const titleModal = isConfirm
    ? `Confirm ${selectedIds?.length} transaction?`
    : "Confirm to resend callback?";
  const descModal = isConfirm
    ? `You will confirm for ${selectedIds?.length} selected transactions.`
    : `You will resend callback for ${selectedIds?.length} selected transactions.`;

  return (
    <div
      style={{
        position: "absolute",
        bottom: -74,
      }}
      className="d-flex w-100 justify-content-center"
    >
      <div
        style={{
          width: 387,
          height: 80,
          boxShadow: "0px 8px 20px rgba(88, 88, 88, 0.1)",
          backgroundColor: "white",
          borderRadius: 8,
          padding: 16,
        }}
        className="d-flex justify-content-between align-items-center fade-in"
      >
        <div>
          <GothamRegular>Selected Transactions</GothamRegular>
          <GothamMedium style={{ marginTop: 4 }}>
            {selectedIds.length} Transaction(s)
          </GothamMedium>
        </div>
        <DialogButton
          title={titleModal}
          text={descModal}
          style={{ height: 32, fontSize: 14 }}
          isLoading={loading}
          onClick={() => onSubmit({ ids: selectedIds })}
        >
          {title}
        </DialogButton>
      </div>
    </div>
  );
};
