import { useEffect } from "react";
import { colors } from "../../../../assets/colors";
import { booleanState } from "../../../../components/Status";
import { GothamRegular, TextInlineRegular } from "../../../../components/Text";
import { customDateFormatter } from "../../../../components/tools";
import {
  getRolesUserLogin,
  useGetAuth,
} from "../../../../contexts/AuthContext";

export const useEWalletStatusProps = (details) => {
  const {
    created_by_user,
    created_by_id,
    user,
    wallet_transaction_state_transitions = [],
    admin_decision,
  } = details || {};
  const { name: execName, email: execEmail } = admin_decision || {};
  const executorName = execName || execEmail || "Admin";

  const { name, email } = user || {};
  const cardHolderName = name || email;

  const { user: currentUser } = useGetAuth();
  const { id } = currentUser || {};

  const {
    roles,
    name: creatorName,
    email: creatorEmail,
  } = created_by_user || {};

  const creatorNameOrEmail = creatorName || creatorEmail;

  const isCreatedByMe = id == created_by_id;
  const requesterName = isCreatedByMe ? "You" : creatorNameOrEmail || "Admin";

  const { isAdmin, isSuperAdmin } = getRolesUserLogin(roles);

  const isRequestedByAdmin = isSuperAdmin || isAdmin;

  const getTime = (state) =>
    wallet_transaction_state_transitions.filter(
      ({ event }) => event == state
    )[0]?.created_at;

  return {
    isRequestedByAdmin,
    getTime,
    requesterName,
    cardHolderName,
    executorName,
  };
};

export const useAllocationStatusArray = ({ details }) => {
  const { created_at, status, admin_decision } = details || {};
  const { email, name } = admin_decision || {};
  const nameOrEmail = name || email || "Admin";

  const { isCancelled, isPending } = booleanState(status);

  const { isRequestedByAdmin, getTime, requesterName } =
    useEWalletStatusProps(details);

  const allocatingTime = getTime("process");
  const completeTime = getTime("complete");
  const approveTime = getTime("approve");
  const cancelledTime = getTime("reject");

  const firstObj = {
    text: `${requesterName} requested a balance allocation`,
    isActive: true,
    date: customDateFormatter(created_at),
  };

  const allocatingObj = {
    text: "Allocating money to recipient wallet",
    date: allocatingTime ? customDateFormatter(allocatingTime) : "",
    isActive: Boolean(allocatingTime),
  };

  const approveObj = {
    text: approveTime ? (
      <>
        {nameOrEmail} has{" "}
        <TextInlineRegular style={{ color: colors.green06 }}>
          approved
        </TextInlineRegular>{" "}
        to allocate the fund
      </>
    ) : (
      `${nameOrEmail} has approved to allocate the fund`
    ),
    date: approveTime ? customDateFormatter(approveTime) : "",
    isActive: Boolean(approveTime),
  };

  const needApprover = {
    text: (
      <>
        <TextInlineRegular style={{ color: colors.orangeFF }}>
          Require
        </TextInlineRegular>{" "}
        Admin to allocate the fund
      </>
    ),
    date: approveTime ? customDateFormatter(approveTime) : "",
    isActive: true,
  };

  const completeObj = {
    text: "Completed",
    date: completeTime ? customDateFormatter(completeTime) : "",
    isActive: Boolean(completeTime),
    isGreenButton: Boolean(completeTime),
  };

  const cancelledObj = {
    text: "Rejected by Admin",
    customComponents: (
      <GothamRegular>
        <span style={{ color: colors.redE7 }}>Rejected</span> by {nameOrEmail}
      </GothamRegular>
    ),
    isActive: true,
    date: cancelledTime ? customDateFormatter(cancelledTime) : "",
    isRedButton: true,
  };

  if (isCancelled) return [firstObj, cancelledObj];

  if (isRequestedByAdmin) return [firstObj, allocatingObj, completeObj];

  if (isPending) {
    if (approveTime) return [firstObj, approveObj, allocatingObj, completeObj];
    return [firstObj, needApprover, allocatingObj, completeObj];
  }

  return [firstObj, approveObj, allocatingObj, completeObj];
};
