import { useEffect, useState } from "react";
import { GothamMedium, GothamRegular } from "../../../../components/Text";
import rightArrowIcon from "../../../../assets/images/right-arrow-icon.svg";
import { FilterFields } from "../../../../components/FilterFields/parent";
import {
  customDateFormatter,
  formatCurrencyNoDecimal,
  windowDimension,
  formatCurrency,
} from "../../../../components/tools";
import { StatusActivities } from "../../../../components/Status";
import cardTransactionIcon from "../../../../assets/images/card-icon-32.svg";
import emptyCardTransaction from "../../../../assets/images/empty-card-transaction.svg";
import { fetch } from "../../../../tools/api";
import { useRouter } from "next/router";
import { times } from "lodash";
import Skeleton from "react-loading-skeleton";
import CustomPagination from "../../../pagination";
import { SelectionForm } from "../../../../components/inputs";
import { cardTypeDecider } from "../../../newCardComponents/tools";
import { colors } from "../../../../assets/colors";

const CardShimmer = () => {
  return times(10, (index) => (
    <div
      key={index}
      style={{
        display: "flex",
        alignItems: "center",
        gap: "16px",
        height: "60px",
        width: "100%",
        marginBottom: index !== 9 && "24px",
      }}
    >
      <div>
        <Skeleton width={28} height={28} />
      </div>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <Skeleton width={200} height={15} />
        <Skeleton width={150} height={15} />
      </div>
    </div>
  ));
};

const CardModalWing = ({
  setIsMinimize,
  dataDetail,
  setSelectedTransactionId,
  useFormObj,
  mutationNotes,
}) => {
  const [selectedId, setSelectedId] = useState(null);

  const { height } = windowDimension();

  const { id } = dataDetail || {};

  const { query } = useRouter();

  const { search_box_transaction = "", page = 1 } = query;

  const { watch } = useFormObj;
  const notesForm = watch("notes");
  const { value: sort_by } = watch("sort_by") || {};

  const {
    data: dataFetch,
    refetch,
    loading,
  } = fetch({
    url: `/card_transactions?q[user_card_id_eq]=${id}&q[merchant_name_cont]=${search_box_transaction}&page=${page}&q[sorts]=${sort_by}`,
    formatter: (res) => {
      const filteredTransactions = res.data.filter(
        ({ transaction_type }) => transaction_type !== "CREDIT_PAYMENT"
      );
      res.data = filteredTransactions;
      return res;
    },
    woInit: true,
  });

  const { data: cardTransactions = [], total_page } = dataFetch;

  useEffect(() => {
    refetch();
  }, [String(Object.values(query)), sort_by, id]);

  const onClick = async (id) => {
    if (selectedId) await mutationNotes({ notes: notesForm });
    setSelectedId(id);
    setSelectedTransactionId(id);
  };

  const sortOptions = [
    { label: "Newest", value: "date desc" },
    { label: "Oldest", value: "date asc" },
  ];

  return (
    <div
      style={{
        marginRight: 32,
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "16px",
        }}
      >
        <GothamMedium style={{ color: "#102C57", fontSize: "16px" }}>
          All Transactions
        </GothamMedium>
        <div
          style={{ display: "flex", cursor: "pointer" }}
          onClick={() => setIsMinimize(true)}
        >
          <GothamMedium style={{ color: "#DA649F" }}>Minimize</GothamMedium>
          <img src={rightArrowIcon} />
        </div>
      </div>
      <div
        style={{
          marginBottom: "16px",
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <FilterFields
          placeholder="Search transactions"
          inputStyle={{ width: "220px" }}
          queryName="search_box_transaction"
          isModal
        />
        <SelectionForm
          label="Sort by"
          useFormObj={useFormObj}
          options={sortOptions}
          containerStyle={{
            width: "120px",
            height: "42px",
          }}
          name="sort_by"
          defaultValue={sortOptions[0].value}
        />
      </div>
      <div
        style={{
          overflowY: "auto",
          height: height - 200,
        }}
      >
        {loading && <CardShimmer />}
        {!loading &&
          Boolean(cardTransactions.length) &&
          cardTransactions.map((item, index) => {
            return (
              <IndividualTransaction
                key={index}
                item={item}
                index={index}
                dataLength={cardTransactions.length}
                selectedId={selectedId}
                onClick={onClick}
                dataDetail={dataDetail}
              />
            );
          })}
        {!Boolean(cardTransactions.length) && !loading ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              margin: "auto",
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
              gap: "16px",
            }}
          >
            <div>
              <img src={emptyCardTransaction} />
            </div>
            <div>
              <GothamMedium
                style={{
                  color: "#102C57",
                  fontSize: "24px",
                  textAlign: "center",
                  lineHeight: "32px",
                }}
              >
                This card doesn't have any transaction
              </GothamMedium>
            </div>
          </div>
        ) : (
          total_page > 1 && (
            <CustomPagination limit={10} total={total_page * 10} />
          )
        )}
      </div>
    </div>
  );
};

export default CardModalWing;

const IndividualTransaction = ({
  item,
  index,
  dataLength,
  selectedId,
  onClick,
  dataDetail,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const {
    state,
    date,
    merchant_name,
    amount: totalAmount,
    id,
    transaction_amount: transactionAmount,
    transaction_currency: transactionCurrency,
    transaction_type: transactionType,
  } = item;
  const isCancelled = state === "cancelled";
  const isReturned = state === "returned";
  const isTransactionIDR = transactionCurrency === "IDR";
  const isTopUpPrepaid = transactionType === "CREDIT_TOPUP";
  const isTopUp = transactionType === "CREDIT" || isTopUpPrepaid;

  const isLast = dataLength - 1 === index;

  const formattedDate = customDateFormatter(date).split(",")[0];

  const backgroundColorDecider = () => {
    if (selectedId === id) return "#FDD7F5";
    if (isHovered) return "rgba(0, 0, 0, 0.12)";
  };

  const { width: windowWidth } = windowDimension();

  return (
    <div
      key={index}
      style={{
        height: "60px",
        width: "100%",
        padding: "8px",
        display: "flex",
        alignItems: "center",
        backgroundColor: backgroundColorDecider(),
        cursor: "pointer",
        marginBottom: !isLast && "8px",
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={() => {
        onClick(id);
      }}
    >
      <div
        style={{
          marginRight: "12px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <img src={cardTransactionIcon} />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "4px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              maxWidth: "50%",
            }}
          >
            <GothamMedium
              style={{
                height: "22px",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
            >
              {merchant_name}
            </GothamMedium>
            <div style={{ display: "flex" }}>
              <StatusActivities
                state={state}
                color={(isCancelled || isReturned) && colors.yellowFC}
                style={{
                  fontSize: "12px",
                  lineHeight: "16px",
                  marginRight: "6px",
                }}
              />
              <GothamRegular style={{ color: "#6C6C71", fontSize: "12px" }}>
                • {formattedDate}
              </GothamRegular>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
              alignItems: "flex-end",
            }}
          >
            <GothamMedium
              style={{
                color: isTopUp ? "#06AC4E" : "#333334",
              }}
            >
              {isTopUp ? "+" : "-"} IDR {formatCurrencyNoDecimal(totalAmount)}
            </GothamMedium>
            {!isTransactionIDR && (
              <GothamRegular>
                <GothamRegular
                  style={{
                    color: colors.grey90,
                  }}
                >
                  {transactionCurrency} {formatCurrency(transactionAmount)}
                </GothamRegular>
              </GothamRegular>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
