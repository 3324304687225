import { get } from "lodash";
import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import { Table } from "reactstrap";
import upperCase from "upper-case";
import { colors } from "../assets/colors";
import downloadIcon from "../assets/images/download-icon.svg";
import uploadIcon from "../assets/images/upload-icon.svg";
import Alert, { SuccessAlert, WarningAlert } from "../components/Alerts";
import { CustomButton } from "../components/Buttons";
import DownloadReceiptModalTable from "../components/DownloadReceiptModalTable";
import LoadingSpinner from "../components/Loading";
import { RefundModal } from "../components/Modals";
import RefundModalTable from "../components/RefundModalTable";
import { booleanState, StatusBadge } from "../components/Status";
import { GothamRegular, TextInlineBold } from "../components/Text";
import { asyncDownload, formatCurrency } from "../components/tools";
import { Tbody, TdHeader } from "../pageComponents/tableComponents";
import { eventsTracker } from "../universalFunctions/events";
import { TablePagination } from "./pagination";

export const Summary = ({ handleClickSummary, data, loading }) => {
  const dataThead = [
    {
      title: "Source Currency",
      width: 142,
      handle: "source_currency",
    },
    {
      title: "Destination Currency",
      width: 162,
      handle: "destination_currency",
    },
    {
      title: "No. of Transactions",
      width: 162,
      handle: "total_transactions",
    },
    {
      title: "Rate",
      width: 120,
      handle: "rate",
    },
    {
      title: "Fee (IDR)",
      width: 154,
      handle: "fee",
    },
    {
      title: "Amount (IDR)",
      width: 196,
      handle: "total_amount",
    },
  ];

  return (
    <div
      className="table-responsive mt-1"
      style={{ border: "1px solid rgba(194, 194, 194,.5)" }}
    >
      <Table className="table-transfez w-100 mb-0">
        <>
          <thead>
            <tr>
              {dataThead.map(({ title, width, handle }, index) => {
                return (
                  <TdHeader
                    key={index}
                    isCustomStyle
                    style={{ width }}
                    onClick={() => {
                      handleClickSummary(handle);
                    }}
                  >
                    {title}
                  </TdHeader>
                );
              })}
            </tr>
          </thead>
          <Tbody loading={loading} totalHeader={dataThead.length} totalItem={5}>
            {data.map(
              (
                {
                  source_currency,
                  destination_currency,
                  trxb_total,
                  total_fee,
                  total_amount,
                  rate,
                },
                index
              ) => {
                const safeFee = formatCurrency(total_fee || 0);
                const safeTotalAmount = formatCurrency(total_amount || 0);
                return (
                  <tr key={index}>
                    <td>{source_currency}</td>
                    <td>{destination_currency}</td>
                    <td>{trxb_total}</td>
                    <td>{rate}</td>
                    <td>{safeFee}</td>
                    <td>{safeTotalAmount}</td>
                  </tr>
                );
              }
            )}
          </Tbody>
        </>
      </Table>
    </div>
  );
};

export const Transactions = ({ data, totalData, loading, handleClick }) => {
  const { query, push } = useRouter();
  const { per_page = 10, id } = query;
  const dataThead = [
    {
      title: "Transaction ID",
      width: 180,
      handle: "reference_id",
    },
    {
      title: "Status",
      width: 200,
      handle: "status",
    },
    {
      title: "Disbursement Type",
      width: 200,
      handle: "disbursement_type",
    },
    {
      title: "Recipient Name",
      width: 200,
      handle: "recipient_name",
    },
    {
      title: "Recipient Email",
      width: 240,
      handle: "recipient_email",
    },
    {
      title: "Source Currency",
      width: 148,
      handle: "source_currency",
    },
    {
      title: "Destination Currency",
      width: 148,
      handle: "destination_currency",
    },
    {
      title: "Destination Amount",
      width: 162,
      handle: "destination_amount",
    },
    {
      title: "Rate",
      width: 120,
      handle: "rate",
    },
    {
      title: "Fee (IDR)",
      width: 148,
      handle: "fee",
    },
    {
      title: "Amount (IDR)",
      width: 200,
      handle: "total_amount",
    },
  ];
  const toTransactionDetails = (transactionId) => {
    eventsTracker("batch_details_to_transaction_detail", { transactionId });
    const url = `/activity/batch-payment/${id}/transaction-details/${transactionId}`;
    push(url);
  };
  return (
    <>
      <div
        className="table-responsive mt-1"
        style={{ border: "1px solid rgba(194, 194, 194,.5)" }}
      >
        <Table
          className="table-transfez w-100 mb-0"
          style={{
            width: 100,
          }}
        >
          <>
            <thead>
              <tr>
                {dataThead.map(({ title, width, handle }, index) => {
                  return (
                    <TdHeader
                      key={index}
                      isCustomStyle
                      style={{ width: width }}
                      onClick={() => {
                        handleClick(handle);
                      }}
                    >
                      {title}
                    </TdHeader>
                  );
                })}
              </tr>
            </thead>

            <Tbody
              loading={loading}
              totalHeader={dataThead.length}
              totalItem={per_page}
            >
              {data.map(
                (
                  {
                    reference_id,
                    state,
                    fee,
                    sent_amount,
                    beneficiary,
                    id,
                    rate,
                    source_currency,
                    destination_currency,
                    destination_amount,
                    disbursement_type,
                  },
                  index
                ) => {
                  const safeDisbursementType = upperCase(
                    disbursement_type || ""
                  );
                  const getBene = (string) => get(beneficiary, string, "");
                  const getName =
                    getBene("receiving_registered_name") ||
                    getBene("receiving_first_name") +
                      getBene("receiving_last_name");
                  const safeFee = formatCurrency(fee || 0);
                  const safeSentAmount = formatCurrency(sent_amount || 0);
                  const safeRate =
                    formatCurrency(getBene("rate") || 0) ||
                    formatCurrency(rate || 0);

                  return (
                    <tr key={index}>
                      <td
                        className="link"
                        onClick={() => toTransactionDetails(id)}
                      >
                        {reference_id}
                      </td>
                      <td>
                        <StatusBadge
                          state={state}
                          style={{ marginLeft: "5%", width: "90%" }}
                        />
                      </td>
                      <td>{safeDisbursementType}</td>
                      <td>{getName}</td>
                      <td>{getBene("recipient_email")}</td>
                      <td>{source_currency}</td>
                      <td>{destination_currency}</td>
                      <td>
                        {destination_amount} {destination_currency}
                      </td>
                      <td>{safeRate}</td>
                      <td>{safeFee}</td>
                      <td>{safeSentAmount}</td>
                    </tr>
                  );
                }
              )}
            </Tbody>
          </>
        </Table>
      </div>
      <TablePagination total={totalData} query />
    </>
  );
};

export const BatchHeader = ({
  batchData,
  paymentOnClick,
  loading,
  transactionData,
}) => {
  const { batch_id, state } = batchData;
  const [isFirstRender, setIsFirstRender] = useState(true);

  useEffect(() => {
    if (!loading && isFirstRender) {
      setIsFirstRender(false);
    }
  }, [loading]);

  if (loading && isFirstRender) {
    return <LoadingSpinner />;
  }
  return (
    <div className="mb-3">
      <div className="d-flex justify-content-between align-items-center mb-2">
        <GothamRegular
          style={{
            fontSize: 24,
            color: colors.grey25,
            lineHeight: "32px",
            marginBottom: 0,
          }}
        >
          {batch_id}
        </GothamRegular>
        <Buttons
          paymentOnClick={paymentOnClick}
          data={batchData}
          transactionData={transactionData}
        />
      </div>
      <StatusBadge state={state} className="mb-3" />
      <ShowAlert state={state} />
    </div>
  );
};

export const arrayQuery = (array, string = "ids[]=") =>
  array.map((id) => `${string}${id}`).join("&");

const Buttons = ({
  paymentOnClick,
  data: { state, id, partner_bank_account, total_state, batch_id },
  transactionData,
}) => {
  const hasFailedTrx = Boolean(get(total_state, "failed", 0));
  const hasCompletedTrx = Boolean(get(total_state, "completed", 0));
  const { isWaitingForPayment, isRejected, isPaid } = booleanState(state);

  const [showModal, setShowModal] = useState(false);
  const [refundModal, setRefundModal] = useState(false);
  const [refundModalTable, setRefundModalTable] = useState(false);
  const [downloadReceiptTable, setDownloadReceiptTable] = useState(false);
  const [trxIds, setTrxIds] = useState([]);
  const { push, query } = useRouter();
  const beneficiary = partner_bank_account || {};

  const refundToggle = () => {
    eventsTracker("batch_details_to_refund_modal", { batch_id, id });
    setRefundModal((p) => !p);
  };

  if (isWaitingForPayment) {
    const toggle = () => {
      eventsTracker("batch_details_to_upload_receipt", { batch_id, id });
      setShowModal((p) => !p);
    };
    return (
      <div className="d-flex">
        <CustomButton
          style={{ height: 34 }}
          className="btn-blue mr-2 px-4"
          onClick={() => {
            eventsTracker("batch_details_to_payment_instruction", {
              batch_id,
              id,
            });
            paymentOnClick(id);
          }}
        >
          Payment Instructions
        </CustomButton>
        <CustomButton
          style={{ height: 34 }}
          className="px-4"
          onClick={toggle}
          icon={
            <img src={uploadIcon} className="mr-2 " style={{ width: 20 }} />
          }
        >
          Upload Receipt
        </CustomButton>
        {/* <UploadModal toggle={toggle} showModal={showModal} /> */}
      </div>
    );
  }

  if (isRejected) {
    return (
      <div className="d-flex">
        <CustomButton
          onClick={refundToggle}
          style={{ padding: "10px 74px 10px 74px" }}
        >
          Refund
        </CustomButton>
        <RefundModal
          isOpen={refundModal}
          toggle={refundToggle}
          item={beneficiary}
        />
      </div>
    );
  }

  if (hasCompletedTrx) {
    const toggle = ({ specialQuery = false }) => {
      setDownloadReceiptTable((p) => !p);
      const { id, total_page, ...rest } = query;
      if (specialQuery) {
        push({ query: { id, status: "completed", total_page } });
        return;
      }
      push({ query: { id, total_page } });
    };
    const [loading, setLoading] = useState(false);

    const handleNextButton = async (ids) => {
      try {
        setLoading(true);
        // weird url to insert ids
        const url = `/prints/bulk_transaction_receipt?${arrayQuery(ids)}`;

        await asyncDownload({
          url,
          filename: `Transfez-Invoice-${batch_id}.zip`,
        });
        eventsTracker("batch_details_download_receipt", { batch_id, id });
      } catch (err) {
        console.log("err:", err);
      } finally {
        setLoading(false);
      }
    };

    return (
      <div className="d-flex">
        <CustomButton
          className="px-4 btn-blue"
          onClick={() => {
            eventsTracker("batch_details_to_receipt_modal", { batch_id, id });
            toggle({ specialQuery: true });
          }}
        >
          <img src={downloadIcon} className="mr-2" />
          Download Reciept
        </CustomButton>
        <DownloadReceiptModalTable
          isOpen={downloadReceiptTable}
          toggle={toggle}
          data={transactionData}
          onSubmit={handleNextButton}
          isLoading={loading}
        />
      </div>
    );
  }

  if (hasFailedTrx) {
    const toggle = ({ specialQuery = false }) => {
      setRefundModalTable((p) => !p);
      const { id, total_page, ...rest } = query;
      if (specialQuery) {
        push({ query: { id, status: "failed", total_page } });
        return;
      }
      push({ query: { id, total_page } });
    };

    const handleNextButton = (data) => {
      toggle({});
      refundToggle();
      setTrxIds(data);
      eventsTracker("batch_details_selected_refund", {
        id,
        batch_id,
        transaction_ids: data,
      });
    };

    return (
      <div className="d-flex">
        <CustomButton
          style={{ paddingLeft: 74, paddingRight: 74 }}
          onClick={() => {
            eventsTracker("batch_details_to_refund_modal", { batch_id, id });
            toggle({ specialQuery: true });
          }}
        >
          Refund
        </CustomButton>
        <RefundModalTable
          isOpen={refundModalTable}
          toggle={toggle}
          data={transactionData}
          onSubmit={handleNextButton}
        />
        <RefundModal
          isOpen={refundModal}
          toggle={refundToggle}
          item={{ ...beneficiary, trxIds }}
        />
      </div>
    );
  }

  return null;
};

const ShowAlert = ({ state }) => {
  const { isWaitingForPayment, isRejected, isCancelled } = booleanState(state);

  const { query, push } = useRouter();
  const { validatingAlert, ...rest } = query;
  if (validatingAlert) {
    return (
      <SuccessAlert
        regularText="Your payment is being verified. You can track your transaction status in Batch History Menu"
        handleClose={() => {
          push({ query: { ...rest } });
        }}
      />
    );
  }
  if (isWaitingForPayment) {
    return (
      <Alert
        boldText="You must upload your receipt"
        regularText="so the transaction can be processed."
      />
    );
  }
  if (isCancelled) {
    return (
      <WarningAlert regularText="We were unable to process your transaction. Please contact our account manager" />
    );
  }
  if (isRejected) {
    return (
      <WarningAlert regularText="Your transaction is rejected. Please contact our account manager." />
    );
  }
  return null;
};

export const TotalToBePaid = ({ value, isPaid }) => (
  <GothamRegular
    className="mx-0"
    style={{
      fontSize: 20,
      lineHeight: "28px",
      padding: 16,
      textAlign: "right",
      border: "1px solid rgba(194, 194, 194,.5)",
      borderTop: "none",
      color: colors.grey72,
    }}
  >
    {isPaid ? "Total paid (fee included)" : "Total to be paid (fee included)"}
    <TextInlineBold style={{ color: "#252525" }}> IDR {value}</TextInlineBold>
  </GothamRegular>
);
