import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { colors } from "../../../assets/colors";
import arrow from "../../../assets/images/arrow.svg";
import downloadIcon from "../../../assets/images/download-history-white.svg";
import localBatchIcon from "../../../assets/images/local-batch-icon-40.svg";
import plus from "../../../assets/images/plus-circle.svg";
import { CustomButton } from "../../../components/Buttons";
import {
  BackHeader,
  CloseHeader,
  MiddleWrapper,
  RightCardModalAtomic,
} from "../../../components/CardModal";
import { LeftRightList } from "../../../components/Lists";
import { booleanState, StatusLabel } from "../../../components/Status";
import { GothamMedium, GothamRegular } from "../../../components/Text";
import {
  customDateFormatter,
  formatCurrencyNoDecimal,
} from "../../../components/tools";
import { getUserRole, useGetAuth } from "../../../contexts/AuthContext";
import {
  ReasonNotes,
  StatusPayrollLine,
} from "../../../pageComponents/payrollCreateComponents/loop/success/status";
import { fetch, useDownload } from "../../../tools/api";
import { eventsTracker } from "../../../universalFunctions/events";
import { ActivityDetailsHeader } from "../activityModalComponents";
import { RecipientMinimalist } from "../transactionList";
import { WingsComponents } from "../wingsComponents";
import {
  ArrayDeciderTransactionFlow,
  useLocalDisbursementBatchTransactions,
  useLocalDisbursementDetails,
} from "./logics";
import { LocalDisbursementProgressModal } from "./progress";
import { CenterendIconLocalTransfer } from "./transactionModal";
import { crossBorderSingleWorkflowComponents } from "../crossBorderSingleModal/components";
import {
  DownloadBulkAndSingleButton,
  DownloadSingleModal,
} from "./modalDownload";
import { ToasterHook } from "../../../contexts/ToasterContext";
import { useModalHook } from "../../../components/Modals";
import { batchFeeArrayDecider, singleFeeArrayDecider } from "../payroll/parent";
import useSubscriptionUsage from "../../../tools/hooks/useSubscriptionUsage";
import SuspendModal from "../../../components/SubscriptionComponents/SuspendModal/SuspendModal";

export const LocalDisbursementBatch = ({
  isOpen,
  data: dataProps,
  toggle,
  hideButton,
  setData,
}) => {
  if (!isOpen) return null;
  const {
    id,
    description,
    amount,
    state: stateFromDataProps,
    status,
    created_at,
    reference_number,
    originator_id,
  } = dataProps || {};

  const [wingsSelectedItem, setWingsSelectedItem] = useState(false);
  const { push } = useRouter();
  const { user } = useGetAuth();
  const { id: userLoggedInId } = user || {};
  const defaultId = originator_id || id;

  const { errorToaster, successToaster } = ToasterHook();

  const { data: dataFetch } = useLocalDisbursementBatchTransactions(defaultId);
  const { data: dataLoop } = dataFetch || {};
  const arrayFlow = ArrayDeciderTransactionFlow({ data: wingsSelectedItem });

  const { isSuspended, loadingSubscriptionUsage } = useSubscriptionUsage();

  const { isOpen: isOpenSuspendModal, toggle: toggleSuspendModal } =
    useModalHook();

  const { isOpen: isOpenDownloadSingle, toggle: toggleDownloadSingle } =
    useModalHook();

  const array = dataLoop?.map(({ state, ...rest }) => {
    return {
      state,
      status: state,
      ...rest,
    };
  });
  const {
    isAdmin,
    isSuperAdmin,
    isBookKeeper,
    isEmployee,
    isDrafterSendMoney,
  } = getUserRole();

  const {
    local_recipient: localRecipientSingle,
    beneficiary_bank_name: beneBankSingle,
    reference_id: referenceIdSingle,
    default_fee: defaultFeeSingle,
    created_at: createdAtSingle,
    recipient: recipientSingle,
    leftTop: descriptionSingle,
    amount: amountRawSingle,
    rightTop: amountSingle,
    leftTop: beneBankName,
    status: statusSingle,
    state: stateSingle,
    fee: feeSingle,
    id: idSingle,
  } = wingsSelectedItem || {};

  const amountSinglePlusFee = formatCurrencyNoDecimal(
    Number(amountRawSingle) + Number(feeSingle)
  );

  // LOCAL RECIPIENT ITU SELALU UPDATE
  const {
    name: nameLocalRecipientSingle,
    email: emailLocalRecipientSingle,
    account_number: accNumberLocalRecipientSingle,
  } = localRecipientSingle || {};
  // LOCAL RECIPIENT ITU SELALU UPDATE

  // RECIPIENT  ITU NEMPEL KE TRANSAKSI
  const { name: nameRecipientSingle, email: emailRecipientSingle } =
    recipientSingle || {};
  // RECIPIENT  ITU NEMPEL KE TRANSAKSI

  const nameWingsSelected = nameRecipientSingle
    ? nameRecipientSingle
    : nameLocalRecipientSingle;

  const isPendingSingle = statusSingle === "pending";

  const bankAccountNumber = accNumberLocalRecipientSingle;

  const recipientDataWings = {
    iso: "id",
    bankAccount: bankAccountNumber,
    name: nameWingsSelected,
    bank: beneBankSingle,
  };

  const { details, refetch, statusFlow, loading } = useLocalDisbursementDetails(
    {
      id: defaultId,
      setData,
    }
  );

  useEffect(() => {
    if (!isOpen) return;
    refetch();
  }, [isOpen, defaultId]);

  const { data, total_trx } = details;
  const { buttons, rejectModal, isRejection, isWorkflow, openPinModal } =
    crossBorderSingleWorkflowComponents({
      transaction: data,
      cancelUrlFunc: (id) => `/local_transaction_batches/${id}/change_state`,
      afterSuccess: refetch,
    });

  const {
    created_at: createdAtBatch,
    total_batch_amount,
    batch_number,
    total_amount,
    id: idBatch,
    total_fee,
    state,
    name,
    user_id,
    rejection_note,
  } = data || {};
  const isWaitingForApproval = state == "waiting_for_approval_tasks";
  const isUserTrxSameUserLogin = user_id === userLoggedInId;
  const feePerTrx = formatCurrencyNoDecimal(
    Number(total_fee) / Number(total_trx)
  );

  const individualDefaultFee = array ? array[0]?.default_fee : 0;

  const {
    isComplete,
    isCompleted,
    isDraft,
    isOnProgress,
    isCancelled,
    isFailed,
    isRejected,
    isPartialFailed,
    isPaid,
    isWaitingApprovalTask,
  } = booleanState(state);

  const stateDecider = () => {
    if (wingsSelectedItem) {
      if (isRejected && isPendingSingle) return state;
      if (isPendingSingle) return null;
      return statusSingle;
    }
    if (status) return status;
    if (state === "validate_recipient") return "draft";
    return isWaitingForApproval ? "pending" : state;
  };

  const stateForStatusLabel = stateDecider();

  const handleSelectedWingsItem = (values) => setWingsSelectedItem(values);

  const headerData = {
    icon: null,
    title: `IDR ${formatCurrencyNoDecimal(total_batch_amount)}`,
    type: description || `Batch Transfer ${name}`,
  };

  const headerDataSingle = {
    icon: null,
    title: `IDR ${amountSinglePlusFee}`,
    type: nameWingsSelected,
  };

  const isDraftState = isDraft || state == "validate_recipient";
  const isShowSummary =
    isComplete || isOnProgress || isCompleted || isPartialFailed || isPaid;
  const isShowTimeline =
    isShowSummary ||
    isCancelled ||
    isFailed ||
    isRejected ||
    isWaitingApprovalTask;

  const handleClick = async (val) => {
    const { type, value } = val || {};
    const isBulk = type === "bulk";
    const isBulkXls = type == "bulk_xls";
    const isSingle = type === "single";
    const isDownloadSingle = type === "id_download";
    if (isSingle) {
      toggleDownloadSingle();
    }

    if (isBulk || isBulkXls) {
      await handleDownload({
        url: `/local_transaction_batches/${idBatch}.${
          isBulkXls ? "xlsx" : "pdf"
        }`,
        fileName: `Transfez-Local-Transfer-Batch-${name}.${
          isBulkXls ? "xlsx" : "pdf"
        }`,
      });
      if (!loadingDownload) {
        successToaster({
          title: "Success",
          msg: "You have successfully downloaded batch receipt transactions",
        });
      }
    }

    if (isDownloadSingle) {
      const stringValue = String(value);
      toggleDownloadSingle();
      await handleDownload({
        type: "application/zip",
        url: `/local_transaction_batches/${idBatch}/bulk_transaction_receipt?${stringValue}`,
        fileName: `Transfez-Local-Transfer-${name}.zip`,
      });
      if (!loadingDownload) {
        successToaster({
          title: "Success",
          msg: "You have successfully downloaded local transactions",
        });
      }
    }
  };

  const leftRightArray = [
    { left: "Date", right: customDateFormatter(created_at || createdAtBatch) },
    { left: "Reference", right: reference_number || batch_number },
    { spacer: true },
    {
      left: `Count of transaction(s)`,
      styleLeft: { width: "65%" },
      styleRight: { width: "35%" },
      right: `${total_trx} Transaction(s)`,
    },
    {
      left: "Total Amount",
      right: `IDR ${formatCurrencyNoDecimal(total_amount)}`,
    },
    ...batchFeeArrayDecider({
      defaultFee: individualDefaultFee,
      totalFee: total_fee,
      totalTrx: total_trx,
    }),
    { spacer: true },
    {
      left: "Total",
      right: `IDR ${formatCurrencyNoDecimal(Number(total_batch_amount))}`,
      rightBold: true,
    },
  ];

  const leftRightArraySingle = [
    {
      left: "Date",
      right: customDateFormatter(createdAtSingle),
      styleLeft: { width: "40%" },
      styleRight: { width: "60%" },
    },
    { left: "Reference", right: referenceIdSingle },
    { spacer: true },
    {
      left: "Recipient gets",
      right: `IDR ${formatCurrencyNoDecimal(amountRawSingle)}`,
      rightBold: true,
    },
    ...singleFeeArrayDecider({ fee: feeSingle, defaultFee: defaultFeeSingle }),
    {
      left: "You pay",
      right: `IDR ${formatCurrencyNoDecimal(amountSinglePlusFee)}`,
      rightBold: true,
    },
  ];

  const { handleDownload, loading: loadingDownload } = useDownload();

  const ButtonDecider = () => {
    const isDraftButtons = isDraftState || state == "pending";
    const isWaitingPayment = state == "waiting_for_payment";
    const isOnProgress = state == "on_progress";
    const isFailed = state == "failed";
    const isPartialFailed = state == "partial_failed";

    if (isFailed || isOnProgress) return null;

    const style = {
      paddingRight: 24,
      paddingLeft: 24,
      height: 32,
      fontSize: 14,
      // marginBottom: 20,
    };

    if (hideButton) return null;

    if (Boolean(wingsSelectedItem)) {
      const isCompletedSingle = stateSingle === "completed";
      if (isCompletedSingle) {
        const isShowDownloadSingle =
          isAdmin || isSuperAdmin || isBookKeeper || isEmployee;

        if (isShowDownloadSingle) {
          return (
            <div className="d-flex justify-content-center">
              <CustomButton
                style={{ ...style }}
                onClick={() => {
                  handleDownload({
                    url: `/local_transaction_batches/${idBatch}/show_transaction/${idSingle}.pdf`,
                    fileName: `Transfez-Local-Transaction-${idSingle}.pdf`,
                  });
                  eventsTracker("download_receipt_local_transfer_single");
                }}
                isLoading={loadingDownload}
                leftIcon={<img src={downloadIcon} className="mr-2" />}
              >
                Download Receipt
              </CustomButton>
            </div>
          );
        }
        return null;
      }
      return null;
    }

    if (isComplete || isCompleted || isPartialFailed)
      return (
        <div className="d-flex justify-content-center">
          <DownloadBulkAndSingleButton
            onClick={handleClick}
            isLoading={loadingDownload}
          />
          <DownloadSingleModal
            toggle={toggleDownloadSingle}
            isOpen={isOpenDownloadSingle}
            array={array}
            setArrayIdDownload={handleClick}
            loading={loadingDownload}
          />
        </div>
      );

    if (isDraftButtons && isUserTrxSameUserLogin) {
      const handleClick = () => {
        if (isSuspended) return toggleSuspendModal();

        push(`/local-transfer/create/${idBatch}?state=validating`);
        eventsTracker("continue_transaction_to_local_transfer_batch");
      };

      if (isDrafterSendMoney) {
        return (
          <div className="d-flex justify-content-center">
            <CustomButton
              style={style}
              onClick={handleClick}
              isLoading={loadingSubscriptionUsage}
            >
              Continue Transaction <img src={arrow} className="ml-2" />
            </CustomButton>
          </div>
        );
      }
      return null;
    }

    if (isWaitingPayment)
      return (
        <div className="d-flex justify-content-center">
          <CustomButton
            style={style}
            onClick={() => {
              if (isSuspended) return toggleSuspendModal();

              push(
                `/local-transfer/${idBatch}/payment-instruction/?type=local_transfer`
              );
            }}
            isLoading={loadingSubscriptionUsage}
          >
            Proceed to payment
          </CustomButton>
        </div>
      );

    return buttons();
  };

  const [middleHeight, setMiddleHeight] = useState(0);

  const numberFunc = (stateCheck) =>
    (array || []).filter(({ state }) => state == stateCheck).length;

  const completed = numberFunc("completed");
  const failed =
    numberFunc("failed") + numberFunc("refunded") + numberFunc("declined");
  const onProgress = numberFunc("on_progress") + numberFunc("pending");

  const overviewArray = [
    { color: colors.green06, number: completed, title: "Success" },
    {
      color: colors.blue2C,
      number: onProgress,
      title: "In Progress",
    },
    {
      color: colors.redE7,
      number: failed,
      title: "Failed",
    },
  ];

  return (
    <>
      <RightCardModalAtomic
        isBothToggle={true}
        loading={loading}
        middleHeight={middleHeight}
        hideModal={isRejection || openPinModal || isOpenDownloadSingle}
        isOpen={isOpen}
        isWhite={Boolean(wingsSelectedItem)}
        idWings={idSingle}
        wingComponent={
          <WingsComponents
            type={"local-batch"}
            data={array}
            setSelectedWingsItem={handleSelectedWingsItem}
            selectedWingsItem={wingsSelectedItem}
            isShowSummary={isShowSummary}
            overviewRaw={overviewArray}
          />
        }
        header={
          <ActivityDetailsHeader
            toggle={toggle}
            data={Boolean(wingsSelectedItem) ? headerDataSingle : headerData}
            isWhite={Boolean(wingsSelectedItem)}
            close={
              Boolean(wingsSelectedItem) && (
                <>
                  <BackHeader
                    color={colors.grey33}
                    isMedium
                    text={"Batch Details"}
                    toggle={() => setWingsSelectedItem(false)}
                  />
                  <CloseHeader color={colors.grey33} isMedium toggle={toggle} />
                </>
              )
            }
          />
        }
        middle={
          <MiddleWrapper height={middleHeight} setHeight={setMiddleHeight}>
            <CenterendIconLocalTransfer icon={localBatchIcon} />
          </MiddleWrapper>
        }
        headerStyle={{ paddingBottom: 42 }}
        body={
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <StatusLabel status={stateForStatusLabel} />
              <div
                style={{ marginTop: ButtonDecider() ? 16 : 0 }}
                className={isWorkflow ? "w-100" : ""}
              >
                {ButtonDecider()}
              </div>
            </div>
            <div>
              <div style={{ marginTop: 24 }}>
                <LeftRightList
                  array={
                    Boolean(wingsSelectedItem)
                      ? leftRightArraySingle
                      : leftRightArray
                  }
                />
              </div>
              {Boolean(wingsSelectedItem) && (
                <>
                  <GothamMedium style={{ marginTop: 18 }} className="font16">
                    Recipient
                  </GothamMedium>
                  <RecipientMinimalist data={recipientDataWings} />
                </>
              )}
              <GothamMedium style={{ marginTop: 18 }} className="font16">
                Updates
              </GothamMedium>
              {Boolean(wingsSelectedItem) ? (
                <>
                  <StatusPayrollLine
                    array={arrayFlow}
                    isModal
                    style={{ marginTop: 18 }}
                  />
                </>
              ) : (
                statusFlow
              )}
            </div>
          </>
        }
      />
      <SuspendModal
        state="suspended"
        isOpen={isOpenSuspendModal}
        toggle={toggleSuspendModal}
      />
      {rejectModal()}
    </>
  );
};
