import React, { useState } from "react";
import {
  MiddleWrapper,
  RightCardModalAtomic,
} from "../../../components/CardModal";
import localBatchIcon from "../../../assets/images/local-batch-icon-40.svg";
import { StatusLabel } from "../../../components/Status";
import { useConstants } from "../../../contexts/ConstantsContext/parent";
import { ActivityDetailsHeader } from "../activityModalComponents";
import {
  CardWithTitleInModal,
  SimpleCardWithTitleInModal,
} from "../cardComponents";
import {
  CenterendIconLocalTransfer,
  LocalTransferTransactionModal,
} from "../localDisbursementBatchModal/transactionModal";
import { useLocalDisbursementLogic } from "./logics";
import { LeftRightList } from "../../../components/Lists";
import { RecipientMinimalist } from "../transactionList";
import { GothamMedium } from "../../../components/Text";
import { ArrayDeciderTransactionFlow } from "../localDisbursementBatchModal/logics";
import { StatusPayrollLine } from "../../../pageComponents/payrollCreateComponents/loop/success/status";

export const LocalDisbursementModal = ({ isOpen, toggle, data, isClose }) => {
  const [middleHeight, setMiddleHeight] = useState(0);
  const {
    beneDetails,
    detailsArray,
    headerData,
    senderDetails,
    isIdrToIdr,
    getData,
  } = useLocalDisbursementLogic({ data, isOpen });

  const { status: statusData, originator_type, details, category } = data || {};
  const {
    firstname: firstNameBene,
    lastname: lastNameBene,
    bank_name: bankNameBene,
    account: AccountNumberBene,
  } = details?.originator?.beneficiary || {};

  const isLocalRefund =
    category === "refund_transaction" && originator_type === "Hub::Transaction";

  const { beneficiary, reference_id } = getData || {};
  const isLocal =
    String(reference_id).charAt(0) == "T" &&
    String(reference_id).charAt(1) == "L";

  const { localTransferBanks } = useConstants();
  const arrayFlow = ArrayDeciderTransactionFlow({ data: getData });
  const { firstname: name, account: account_number, bank } = beneficiary || {};

  // if (isLocal) {
  //   const {
  //     firstname: name,
  //     account: account_number,
  //     bank,
  //   } = beneficiary || {};

  //   const payer_id = localTransferBanks.filter(({ label }) => label == bank)[0]
  //     ?.value;

  //   const local_recipient = { name, account_number, payer_id };

  //   return (
  //     <LocalTransferTransactionModal
  //       data={{ ...getData, local_recipient }}
  //       isOpen={isOpen}
  //       toggle={toggle}
  //     />
  //   );
  // }

  const recipientDataLocal = {
    name: name,
    bank: bank,
    bankAccount: account_number,
  };

  const recipientDataRefund = {
    name: `${firstNameBene || ""} ${lastNameBene || ""}`,
    bank: bankNameBene,
    bankAccount: AccountNumberBene,
  };

  const recipientData = isLocalRefund
    ? recipientDataRefund
    : recipientDataLocal;

  return (
    <RightCardModalAtomic
      isOpen={isOpen}
      middleHeight={middleHeight - 20}
      header={
        <ActivityDetailsHeader
          toggle={toggle}
          data={headerData}
          isClose={isClose}
        />
      }
      headerStyle={{ paddingBottom: 42 }}
      middle={
        <MiddleWrapper height={middleHeight} setHeight={setMiddleHeight}>
          <CenterendIconLocalTransfer icon={localBatchIcon} />
        </MiddleWrapper>
      }
      body={
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <StatusLabel status={statusData} />
          </div>
          <div style={{ marginTop: 24 }}>
            <LeftRightList array={detailsArray} />
          </div>
          <GothamMedium style={{ marginTop: 18 }} className="font16">
            Recipient
          </GothamMedium>
          <RecipientMinimalist data={recipientData} />
          {!isLocalRefund && (
            <>
              <GothamMedium style={{ marginTop: 18 }} className="font16">
                Updates
              </GothamMedium>
              <StatusPayrollLine
                array={arrayFlow}
                isModal
                style={{ marginTop: 18 }}
              />
            </>
          )}
        </div>
      }
    />
  );
};
