import { useRouter } from "next/router";
import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { windowDimension } from "../components/tools";

const InfiniteScrollDeciderContext = createContext();

const defaultOnScrollInfiniteScroll = ({
  event,
  setPage,
  total_page = 0,
  loading,
}) => {
  if (!event) return;
  const { scrollHeight, scrollTop, clientHeight } = event?.target || {};
  const reachBottom = scrollHeight - scrollTop - clientHeight < 20;

  const nextPage = reachBottom && !loading;
  if (!nextPage) return;

  setPage &&
    setTimeout(
      () =>
        setPage((p) => {
          if (!total_page) return p + 1;
          const canSubmit = total_page > p;
          if (canSubmit) return p + 1;
          return p;
        }),
      500
    );
};

export const useDefaultRefInfiniteScroll = () => {
  const { height } = windowDimension();

  const defaultRefInfiniteScroll = ({ event, total_page, setPage }) => {
    if (!event) return;
    const { clientHeight } = event;
    setPage((p) => {
      if (!total_page) return p;
      const isAutoRefetch = clientHeight <= height;
      const canSubmit = total_page > p && isAutoRefetch;
      if (canSubmit) return p + 1;
      return p;
    });
  };
  return { defaultRefInfiniteScroll };
};

export const InfiniteScrollDeciderProvider = ({ children }) => {
  const [onScroll, setOnScroll] = useState(() => () => {});
  const { navbarHeight, sidebarGap, height, isTabOrPhone, width } =
    windowDimension();
  const { pathname, query } = useRouter();
  const isUser = !isTabOrPhone && pathname === "/users";
  const isEWalletSuccessPage =
    pathname === "/e-wallet" && query?.type === "success";

  const woInfiniteScrollPathnames = [
    "/history",
    "/e-wallet",
    "/invoice-payment",
    "/invoice-payment/detail",
    "/invoice-payment/create",
    "/payroll/create",
    "/payroll/create/[id]",
    "/task",
    "/api-history",
    "/settings",
    "/reimbursement",
    "/reimbursement/edit",
    "/histories-api",
    "/local-transfer",
    "/local-transfer/[id]",
    "/local-transfer/create",
    "/local-transfer/create/[id]",
  ];
  const woInfiniteScroll =
    (woInfiniteScrollPathnames.includes(pathname) && !isEWalletSuccessPage) ||
    isUser;

  const { page } = query;
  const ref = useRef();

  useEffect(() => {
    ref.current.scrollTo(0, 0);
  }, [pathname, page]);

  const scrollToBottom = () =>
    ref.current?.scrollTo({
      behaviour: "smooth",
      top: 999999,
    });

  return (
    <InfiniteScrollDeciderContext.Provider
      value={{ setOnScroll, scrollToBottom }}
    >
      <div
        ref={ref}
        onScroll={(e) => {
          onScroll(e);
        }}
        className="d-flex justify-content-center"
        style={{
          paddingTop: isTabOrPhone ? 0 : navbarHeight,
          height: isTabOrPhone ? height - 65 : height - sidebarGap,
          overflowY: woInfiniteScroll ? "hidden" : "auto",
          overflowX: "hidden",
          // backgroundColor: "blue",
        }}
      >
        <div
          style={
            {
              // width: "100%",
              // alignItems: "center",
              // backgroundColor: "blue",
              // marginTop: 20,
            }
          }
          // className="d-flex"
        >
          {children}
          <div style={{ height: sidebarGap }}></div>
        </div>
      </div>
    </InfiniteScrollDeciderContext.Provider>
  );
};

export const useInfiniteScroll = () => {
  const { setOnScroll, scrollToBottom } = useContext(
    InfiniteScrollDeciderContext
  );
  return { setOnScroll, scrollToBottom };
};

export const useActivateInfiniteScroll = (total_page, loading) => {
  const [page, setPage] = useState(1);
  const { setOnScroll } = useInfiniteScroll();

  useEffect(() => {
    if (!total_page) return;
    setOnScroll(
      () => (event) =>
        defaultOnScrollInfiniteScroll({ event, setPage, total_page, loading })
    );
  }, [loading, total_page]);

  return { page, setPage };
};
