import { createContext, useEffect } from "react";
import { useModalHook } from "../components/Modals";
import { useRouter } from "next/router";
import { getUserRole, useGetAuth } from "./AuthContext";
import TncCard from "../pageComponents/cardsPageComponent/MainCardsScreen/Modals/TncCard";
import { fetch } from "../tools/api";
import { isEmpty } from "lodash";
import { isProduction } from "../components/tools";

export const TncContext = createContext();

export const TncContextProvider = ({ children }) => {
  const { pathname } = useRouter();
  const isCards = pathname.includes("/cards");

  const { isAdminOrSuperAdmin } = getUserRole();

  const { user } = useGetAuth();
  const { business_all_tncs = {} } = user?.partner || {};
  const urlProduction = "/terms-of-service-b-2-bs";
  const urlStaging = "/terms-of-service-b-2-b-stagings";
  const url = isProduction ? urlProduction : urlStaging;

  const { data: tncs } = fetch({
    url,
    type: "strapi",
  });
  const needToApproveTncs = tncs?.filter((tnc) => {
    //check is business tncs latest version
    const { version, type } = tnc;
    if (Number(business_all_tncs[type]) < version || !business_all_tncs[type])
      return tnc;
  });

  const {
    isOpen: isOpenTnc,
    toggle: toggleTnc,
    open: openTnc,
  } = useModalHook();

  const canApproveTnc = Boolean(
    isAdminOrSuperAdmin &&
      needToApproveTncs?.length &&
      isCards &&
      !isEmpty(user)
  );

  useEffect(() => {
    if (canApproveTnc) openTnc();
  }, [canApproveTnc]);

  return (
    <TncContext.Provider>
      {canApproveTnc && (
        <TncCard
          data={needToApproveTncs}
          isOpen={isOpenTnc}
          toggle={toggleTnc}
        />
      )}
      {children}
    </TncContext.Provider>
  );
};
