import { useEffect, useState } from "react";
import { GothamRegular } from "../../../../components/Text";
import fourDotsMask from "../../../../assets/images/four-dots-mask.svg";
import threeDotsMask from "../../../../assets/images/three-dots-mask.svg";
import expDotMask from "../../../../assets/images/exp-dot-mask.svg";
import HoverInfo from "../../../../components/HoverInfo";
import physicalCard from "../../../../assets/images/transfez-card-physical.svg";
import virtualCardGreen from "../../../../assets/images/virtual-card-green.svg";
import virtualCardGrey from "../../../../assets/images/virtual-card-grey.svg";
import prepaidCard from "../../../../assets/images/prepaid-card-big.svg";
import virtualCardRed from "../../../../assets/images/virtual-card-red.svg";
import virtualCardBlue from "../../../../assets/images/virtual-card-blue.svg";
import darkGradientCard from "../../../../assets/images/dark-gradient-card.svg";
import { useGetAuth } from "../../../../contexts/AuthContext";
import { LoadingCard } from "../loadingCard";
import { cardTypeDecider } from "../../../newCardComponents/tools";

const CardDisplay = ({
  dataDetail,
  left = 0,
  top = 0,
  canRevealData,
  unmaskedDataDetail,
  revealHandler,
}) => {
  const [isCardNumberShowed, setIsCardNumberShowed] = useState(false);
  const [isCardDataHovered, setIsCardDataHovered] = useState(false);
  const [isCardNumberHovered, setIsCardNumberHovered] = useState(false);
  const [isExpDateHovered, setIsExpDateHovered] = useState(false);
  const [isCvvHovered, setIsCvvHovered] = useState(false);
  const [isClicked, setIsClicked] = useState(false);

  useEffect(() => {
    if (canRevealData) {
      setIsCardNumberShowed(true);
      setIsCardDataHovered(false);
    }
  }, [canRevealData]);

  const { user } = useGetAuth();
  const {
    state,
    name,
    nickname,
    masked_card_number,
    is_suspended,
    card_type,
    user_id,
    color_code,
  } = dataDetail || {};

  const { card_number, expiry_date, cvv } = unmaskedDataDetail || {};
  const lastFourDigitCard = masked_card_number && masked_card_number.slice(-4);
  const cardNumberFormatted =
    card_number &&
    card_number
      .split("")
      .map((number, index) =>
        index === 3 || index === 7 || index === 11 ? `${number} ` : `${number}`
      );
  const { isPhysical, isVirtual, isPrepaid } = cardTypeDecider(card_type);
  const isBlocked = state === "TEMP_BLOCK" || state === "P_BLOCK";
  const isReqUnblock = state === "REQUEST_UNBLOCK";
  const cardDecider = () => {
    if (!card_type) return;

    const isRed = color_code == "red";
    const isGrey = color_code == "grey";
    const isGreen = color_code == "green";

    if (isPrepaid) return prepaidCard;

    if (isVirtual) {
      if (isRed) return virtualCardRed;
      if (isGrey) return virtualCardGrey;
      if (isGreen) return virtualCardGreen;
      return virtualCardBlue;
    }
    return physicalCard;
  };
  const cardImage = cardDecider();

  const userId = user?.id;
  const isUserCard = user_id == userId;

  const formatCardDate = () => {
    if (expiry_date) {
      const dateArray = expiry_date.split("-");
      dateArray[0] = dateArray[0].slice(2);
      return dateArray.reverse().join("/");
    }
  };
  const formattedDate = formatCardDate();

  const textTopDecider = () => {
    if (!masked_card_number || isBlocked) return top + 170;
    if (isVirtual || isPrepaid) return top + 120;
    return top + 150;
  };

  const unrevealedCardInfo = isUserCard & !isCardNumberShowed;

  const defaultHoverInfoCopyProps = {
    info: isClicked ? "Copied" : "Click to copy!",
    height: "32px",
    width: isClicked ? "80px" : "120px",
    fontSize: "12px",
    color: "#333334",
  };

  return (
    <div>
      <LoadingCard
        src={cardImage}
        width={364}
        height={228}
        style={{
          borderRadius: 12,
          position: "absolute",
          zIndex: 1,
          left,
          top,
        }}
      />
      {(is_suspended || isBlocked || isReqUnblock) && (
        <img
          src={darkGradientCard}
          style={{
            position: "absolute",
            zIndex: 3,
            left,
            top,
          }}
        />
      )}
      <div
        style={{
          position: "absolute",
          zIndex: 2,
          left: 40,
          top: textTopDecider(),
        }}
      >
        <GothamRegular
          style={{
            fontSize: "16px",
            color: "white",
            marginBottom:
              is_suspended || !masked_card_number || isBlocked
                ? "20px"
                : "5.5px",
            lineHeight: "24px",
          }}
        >
          {nickname || name}
        </GothamRegular>
        {!is_suspended && !isBlocked && !isReqUnblock && masked_card_number && (
          <div
            style={{
              position: "relative",
              display: "flex",
              flexDirection: "column",
              cursor: isUserCard && "pointer",
            }}
            onMouseEnter={() =>
              unrevealedCardInfo && setIsCardDataHovered(true)
            }
            onMouseLeave={() =>
              unrevealedCardInfo && setIsCardDataHovered(false)
            }
            onClick={() => {
              if (unrevealedCardInfo) {
                revealHandler();
              }
            }}
          >
            <div
              style={{
                display: "flex",
                gap: "8px",
              }}
            >
              {!isCardNumberShowed &&
                [1, 2, 3].map((index) => (
                  <img key={index} src={fourDotsMask} />
                ))}
              {!isCardNumberShowed && (
                <GothamRegular style={{ fontSize: "20px", color: "white" }}>
                  {lastFourDigitCard}
                </GothamRegular>
              )}
            </div>
            {isCardNumberShowed && (
              <GothamRegular
                style={{ fontSize: "20px", color: "white" }}
                onClick={() => {
                  setIsClicked(true);
                  navigator.clipboard.writeText(card_number);
                }}
                onMouseEnter={() => setIsCardNumberHovered(true)}
                onMouseLeave={() => {
                  setTimeout(() => setIsClicked(false), 200);
                  setIsCardNumberHovered(false);
                }}
              >
                {cardNumberFormatted}
              </GothamRegular>
            )}
            {(isVirtual || isPrepaid) && (
              <div style={{ display: "flex", gap: 26, marginTop: 16 }}>
                <div style={{ display: "flex", gap: 13 }}>
                  <GothamRegular style={{ color: "white", cursor: "auto" }}>
                    EXP
                  </GothamRegular>
                  {!isCardNumberShowed && <img src={expDotMask} />}
                  {isCardNumberShowed && (
                    <GothamRegular
                      style={{ color: "white" }}
                      onMouseEnter={() => setIsExpDateHovered(true)}
                      onMouseLeave={() => {
                        setTimeout(() => setIsClicked(false), 200);
                        setIsExpDateHovered(false);
                      }}
                      onClick={() => {
                        setIsClicked(true);
                        navigator.clipboard.writeText(formattedDate);
                      }}
                    >
                      {formattedDate}
                    </GothamRegular>
                  )}
                </div>
                <div style={{ display: "flex", gap: 13 }}>
                  <GothamRegular
                    style={{
                      color: "white",
                      cursor: "auto",
                    }}
                  >
                    CVV
                  </GothamRegular>
                  {!isCardNumberShowed && <img src={threeDotsMask} />}
                  {isCardNumberShowed && (
                    <GothamRegular
                      style={{ color: "white", letterSpacing: "1px" }}
                      onMouseEnter={() => setIsCvvHovered(true)}
                      onMouseLeave={() => {
                        setTimeout(() => setIsClicked(false), 200);
                        setIsCvvHovered(false);
                      }}
                      onClick={() => {
                        setIsClicked(true);
                        navigator.clipboard.writeText(cvv);
                      }}
                    >
                      {cvv}
                    </GothamRegular>
                  )}
                </div>
              </div>
            )}
            <div
              className={
                isCardDataHovered || isCardNumberHovered
                  ? "hovered-info"
                  : "unhovered-info"
              }
              style={{
                width: isCardNumberShowed ? "210px" : "175px",
                height: "1px",
                backgroundColor: "white",
                position: "absolute",
                top: "22px",
              }}
            />
            {(isVirtual || isPrepaid) && (
              <>
                <div
                  className={
                    isCardDataHovered || isExpDateHovered
                      ? "hovered-info"
                      : "unhovered-info"
                  }
                  style={{
                    width: isCardNumberShowed ? "54px" : "50px",
                    height: "1px",
                    backgroundColor: "white",
                    position: "absolute",
                    top: "59px",
                    left: "37px",
                  }}
                />
                <div
                  className={
                    isCardDataHovered || isCvvHovered
                      ? "hovered-info"
                      : "unhovered-info"
                  }
                  style={{
                    width: isCardNumberShowed ? "40px" : "36px",
                    height: "1px",
                    backgroundColor: "white",
                    position: "absolute",
                    top: "59px",
                    left: "145px",
                  }}
                />
              </>
            )}
            <div
              style={{
                position: "relative",
                top: isVirtual || isPrepaid ? "-65px" : "-25px",
              }}
            >
              <HoverInfo
                isShow={isCardDataHovered}
                left="188px"
                top="10px"
                info="Click to Reveal"
                height="32px"
                width="120px"
                fontSize="12px"
                color="#333334"
              />
            </div>
            <div
              style={{
                position: "relative",
                top: isVirtual || isPrepaid ? "-65px" : "-45px",
              }}
            >
              <HoverInfo
                isShow={isCardNumberHovered}
                left="210px"
                top="10px"
                {...defaultHoverInfoCopyProps}
              />
            </div>
          </div>
        )}
        <div
          style={{
            position: "relative",
            top: "10px",
          }}
        >
          <HoverInfo
            isShow={isExpDateHovered}
            left={isClicked ? "15px" : "-5px"}
            top="10px"
            pointerTop
            {...defaultHoverInfoCopyProps}
          />
        </div>
        <div
          style={{
            position: "relative",
            top: "10px",
          }}
        >
          <HoverInfo
            isShow={isCvvHovered}
            left={isClicked ? "115px" : "95px"}
            top="10px"
            pointerTop
            {...defaultHoverInfoCopyProps}
          />
        </div>
      </div>
    </div>
  );
};
export default CardDisplay;
