import { yupResolver } from "@hookform/resolvers/yup";
import { noCase } from "change-case";
import countries from "i18n-iso-countries";
import { upperCase } from "lodash";
import numeral from "numeral";
import { useEffect, useState } from "react";
import titleCase from "title-case";
import * as yup from "yup";
import { apiBusiness } from "../tools/api";

export const toFormData = (obj, keyFormatter = (key) => key) => {
  const formData = new FormData();
  const keys = Object.keys(obj);
  keys.map((key) => {
    const value = obj[key];
    const isArray = typeof value == "object" && value.length;

    if (isArray)
      return value.map((value) =>
        formData.append(`${keyFormatter(key)}[]`, value)
      );

    if (value) return formData.append(keyFormatter(key), value);
  });
  return formData;
};
export const arraySplitter = (array, divideBy) => {
  const result = [];
  let temp = [];

  array.forEach((button, index) => {
    temp.push(button);
    if (index % divideBy === divideBy - 1 || index === array.length - 1) {
      result.push(temp);
      temp = [];
    }
  });
  return result;
};

export const stringSplitter = (string, splittedBy, joinedBy = "-") => {
  const toArr = arraySplitter((string || "").split(""), splittedBy);
  const toString = toArr.map((letters) => letters.join("")).join(joinedBy);
  return toString;
};

export const schemaGenerator = ({
  stringArr = [],
  objectArr = [],
  arrayArr = [],
  numbersArr = [],
}) => {
  // EXAMPLE ARRAYS
  // {
  //   stringArr: [{name:'email',yup: yup.email().required()},"full_name", "password", ],
  //   objectArr: ["role", "team"],
  // }

  // stringArr can be just string (name of the field)
  // or an object (must input name and custom yup)

  const defaultText = (field_name) => `${field_name} cannot be empty`;

  const mappedItems = (array, yupType, obj) => {
    array.map((item) => {
      const defaultYup = (name) => yupType.required(defaultText(name));

      const isObject = typeof item == "object";
      if (!item) return;
      if (isObject) {
        const { name, yup: yupParams, woName } = item;

        if (yupParams) return (obj[name] = yupParams);
        if (woName) return (obj[name] = defaultYup(""));

        return (obj[name] = defaultYup(item));
      }

      return (obj[item] = defaultYup(item));
    });
  };

  let strings = {};
  mappedItems(stringArr, yup.string(), strings);

  let objects = {};
  mappedItems(objectArr, yup.object(), objects);

  let arrays = {};
  mappedItems(arrayArr, yup.object(), arrays);

  let numbers = {};
  mappedItems(numbersArr, yup.number().typeError("must be a number"), numbers);

  const result = yupResolver(
    yup.object().shape({
      ...strings,
      ...objects,
      ...arrays,
      ...numbers,
    })
  );
  return result;
};

export const canRefetchDecider = (ref, loading) => {
  if (loading) return false;
  const { current } = ref || {};
  if (!current) return false;
  const { scrollTop, scrollHeight, clientHeight } = current;
  const canRefetch = scrollTop + clientHeight > scrollHeight - 20;
  return canRefetch;
};

export const teamManagerTextDecider = ({
  isTeamManager = false,
  text = "",
}) => {
  if (isTeamManager) return `${text} (Team Manager)`;
  return text;
};

export const getUsersFromSpecificTeams = ({ users = [], teams = [] }) => {
  const userTeamIdsRaw = users.map(({ team_id }) => team_id);
  const teamIds = teams.map(({ id }) => id);

  const userTeamIds = userTeamIdsRaw.filter((team_id) =>
    teamIds.includes(team_id)
  );
  const userObjs = users.filter(({ team_id }) => userTeamIds.includes(team_id));
  const userIds = userObjs.map(({ id }) => id);
  return { userTeamIds, userObjs, userIds };
};

export const widthOrHeight = (ref) => {
  const { clientHeight = 0, clientWidth = 0 } = ref?.current || {};
  return { clientHeight, clientWidth };
};

export const arrayToObjByID = (array, id) =>
  (array || []).filter(({ id: itemId }) => itemId == id)[0] || {};

export const windowDimension = () => {
  const [dimension, setDimension] = useState({ height: 0, width: 0 });
  const { height, width } = dimension;
  const [isFirstRender, setIsFirstRender] = useState(true);
  // const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const handleResize = () =>
      setDimension({ width: window.innerWidth, height: window.innerHeight });

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // useEffect(() => {
  //   const handleScroll = () => setScrollPosition(window.pageYOffset);

  //   window.addEventListener("scroll", handleScroll);

  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);

  useEffect(() => {
    if (isFirstRender) return setIsFirstRender(false);

    setDimension({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  }, [isFirstRender]);

  const isSmall = width < 768;
  const isMedium = width >= 992;
  const isLarge = width >= 1200;
  const isTabOrPhone = !(isMedium || isLarge);
  const isSmallLaptop = width <= 1100;
  const is1280 = width >= 1280;
  const is1440 = width >= 1440;

  const figmaWidth = (number) => {
    if (is1440) return (number * 1440) / 1440;
    return (number * width) / 1280;
  };
  const figmaHeight = (number) => (number * height) / 720;

  const sidebarWidth = figmaWidth(248);
  const sidebarTotalWidth = sidebarWidth + figmaWidth(16);

  const mainComponentWidth = is1440
    ? 1440 - sidebarTotalWidth
    : width - sidebarTotalWidth;

  const rowWidth = is1440 ? 1440 : width;

  const margin = (width - rowWidth) / 2;

  const sidebarHeight = figmaHeight(616);
  const navbarHeight = 72;
  const burgerHeight = 65;
  const screenHeight = height - navbarHeight;
  const sidebarGap = (screenHeight - sidebarHeight) / 2;
  const screenRealHeight = screenHeight - sidebarGap * 2;
  return {
    burgerHeight,
    sidebarGap,
    navbarHeight,
    screenHeight,
    isSmall,
    isMedium,
    figmaWidth,
    figmaHeight,
    is1440,
    is1280,
    mainComponentWidth,
    margin,
    sidebarTotalWidth,
    sidebarHeight,
    sidebarWidth,
    isLarge,
    isTabOrPhone,
    screenRealHeight,
    isSmallLaptop,
    // scrollPosition,
    ...dimension,
  };
};

export function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value]);

  return debouncedValue;
}

export const nameInitial = (name) => {
  if (!name) return "";
  const splittedName = String(name).split(" ");
  const firstName = splittedName[0];
  const lastname = splittedName[splittedName.length - 1];

  const firstLetter = String(firstName).charAt(0);
  const secondLetter = String(lastname).charAt(0);

  const isSame = firstName == lastname;
  if (isSame) return upperCase(firstLetter);
  return `${firstLetter}${secondLetter}`;
};

export const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const aDay = 86400000;
export const aWeek = 604800000;
export const aMonth = 2592000000;

export const dateString = (dateRaw) => {
  const today = new Date();
  const yesterday = new Date(new Date().getTime() - aDay).getTime();
  const thisWeek = new Date(new Date().getTime() - aWeek).getTime();
  const date = new Date(dateRaw);
  const dateTime = date.getTime();

  const isToday =
    date.getDate() == today.getDate() &&
    date.getMonth() == today.getMonth() &&
    date.getFullYear() == today.getFullYear();

  const isYesterday = !isToday && dateTime > yesterday;
  const isThisWeek = !isYesterday && dateTime > thisWeek;

  if (isToday) return "Today";
  if (isYesterday) return "Yesterday";
  if (isThisWeek) return "This Week";
  return customDateFormatter(dateRaw, true, true);
};

const presentDate = new Date().getTime();

export const dateFilterConstructor = (substraction) =>
  new Date(presentDate - substraction).toUTCString();

export const dateFilterConstructorCustom = (date, substraction) => {
  const dateNumber = new Date(date).getTime();
  return new Date(dateNumber - substraction).toUTCString();
};

export const dateOptions = [
  { label: "Today", value: dateFilterConstructor(aDay) },
  { label: "Last 7 Days", value: dateFilterConstructor(aWeek) },
  { label: "Last 30 Days", value: dateFilterConstructor(aMonth) },
];

export const stateOptions = [
  { value: "initial", label: "Created" },
  { value: "approved", label: "Completed" },
  { value: "declined", label: "Declined" },
];

export const flagIso3 = (iso_3) =>
  noCase(countries.alpha3ToAlpha2(upperCase(iso_3 || "")) || "");

export const flagIsoCode = (iso_3) =>
  noCase(countries.numericToAlpha2(iso_3) || "");

countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

export const getCountryName = (iso_2) =>
  countries.getName(iso_2, "en", { select: "alias" });

export const getIso3 = (iso_2) =>
  countries.alpha2ToAlpha3(upperCase(iso_2 || ""));

export const transactionStates = [
  { label: "Created", value: "created" },
  { label: "Confirmed", value: "confirmed" },
  { label: "Submitted", value: "submitted" },
  { label: "Completed", value: "completed" },
  { label: "Rejected", value: "rejected" },
  { label: "Declined", value: "declined" },
  { label: "Canceled", value: "canceled" },
  { label: "Refunded", value: "refunded" },
];

export const virtualAccountStates = [
  { value: "WAITING_PAYMENT", label: "Waiting Payment" },
  { value: "PAYMENT_DETECTED", label: "Payment Detected" },
  { value: "EXPIRED	", label: "Expired" },
  { value: "STATIC_TRX_EXPIRED", label: "Static Trx Expired" },
  { value: "COMPLETE", label: "Complete" },
];

export const incomingPaymentStates = [{ value: "SUCCESS", label: "Success" }];

export const enumOptions = [
  { label: "Advertising expenses", value: "Advertising expenses" },
  { label: "Advisory fees", value: "Advisory fees" },
  { label: "Business insurance", value: "Business insurance" },
  { label: "Construction expenses", value: "Construction expenses" },
  { label: "Delivery fees", value: "Delivery fees" },
  { label: "Education", value: "Education" },
  { label: "Exported goods", value: "Exported goods" },
  { label: "Family support", value: "Family support" },
  { label: "Fund investment", value: "Fund investment" },
  { label: "Gift and donation", value: "Gift and donation" },
  { label: "Hotel accommodation", value: "Hotel accommodation" },
  { label: "Insurance claims", value: "Insurance claims" },
  { label: "Liberalized remittance", value: "Liberalized remittance" },
  { label: "Loan payment", value: "Loan payment" },
  { label: "Maintenance expenses", value: "Maintenance expenses" },
  { label: "Medical treatment", value: "Medical treatment" },
  { label: "Office expenses", value: "Office expenses" },
  { label: "Other fees", value: "Other fees" },
];

export const relationshipOptions = [
  { value: "SELF", label: "Myself" },
  { value: "WIFE", label: "Spouse" },
  { value: "SON", label: "Children" },
  { value: "MOTHER", label: "Parent" },
  { value: "SISTER", label: "Sister" },
  { value: "COUSIN", label: "Cousin" },
  { value: "FRIEND", label: "Friend" },
  { value: "OTHER", label: "Business" },
  { value: "OTHER", label: "Customer" },
  { value: "OTHER", label: "Supplier" },
  { value: "OTHER", label: "Other" },
];

export const sourceOfFundsOptions = [
  { value: "SALARY", label: "Salary/Income" },
  { value: "BUSINESS", label: "Business/Investment" },
  { value: "SAVINGS", label: "Savings" },
  { value: "GIFT", label: "Gift" },
  { value: "OTHER", label: "Loan Facility" },
  { value: "OTHER", label: "Retirement Funds" },
  { value: "OTHER", label: "Third party" },
];

export const removeDuplicates = (array) => {
  const result = array.reduce((acc, current) => {
    const x = acc.find((item) => item === current);
    if (!x) {
      return acc.concat([current]);
    } else {
      return acc;
    }
  }, []);

  return result;
};

export const deepRemoveDuplicates = (array, key) => {
  const result = array.reduce((acc, current) => {
    const x = acc.find((item) => item[key] === current[key]);
    if (!x) {
      return acc.concat([current]);
    } else {
      return acc;
    }
  }, []);

  return result;
};

export const dataSorter = (data, key) =>
  data.sort((a, b) => (a[key] < b[key] ? -1 : a[key] > b[key] ? 1 : 0));

export const simpleDataSorter = (data) =>
  data.sort((a, b) => (a < b ? -1 : a > b ? 1 : 0));

export const calculatorItemsPreparer = (data) =>
  data.map(
    ({ country_iso, country_iso_3, country_name, currency, ...rest }) => ({
      label: titleCase(country_name),
      value: country_iso_3,
      currency,
      country_iso,
      ...rest,
    })
  );

export const newCalculatorItemsPreparer = (data) =>
  data.map((item) => {
    const { country_iso_3, country_name, currency } = item;
    return {
      ...item,
      label: `${titleCase(country_name)}/ ${currency}`,
      value: country_iso_3,
    };
  });

export const toLabelValue = (data) =>
  data.map((item) => {
    const {
      name,
      iso_3,
      code,
      branch_code,
      branch_name,
      id,
      readable_partner_roles,
      label: labelRaw,
      value: valueRaw,
      email,
    } = item;
    const hasBranchCode = Boolean(branch_code);
    const branchNameLabel = hasBranchCode
      ? `${branch_code} - ${titleCase(branch_name)}`
      : "";
    const label =
      email || labelRaw || readable_partner_roles || name || branchNameLabel;
    const value = valueRaw || iso_3 || code || branch_code || id;
    return {
      ...item,
      label,
      value,
    };
  });

export const toLabelValueId = (data, woTitleCase) =>
  data.map(({ name, id, country_name, country_id, ...rest }) => {
    const text = name || country_name || "";
    const label = woTitleCase ? text : titleCase(text);
    const value = id || country_id;
    return {
      label,
      value,
      ...rest,
    };
  });
export const removeNullValues = (obj) => {
  const keys = Object.keys(obj);
  const values = Object.values(obj);
  let result = {};
  values.map((item, index) => {
    if (item) {
      result[keys[index]] = item;
    }
  });
  return result;
};

export const formatterForm = (obj) => {
  const keys = Object.keys(obj);
  const values = Object.values(obj);
  let result = {};
  values.map((item, index) => {
    const isObject = typeof item == "object";
    if (isObject) {
      return (result[keys[index]] = item.value);
    }
    return (result[keys[index]] = item);
  });
  return result;
};

export const formatCurrency = (string) => {
  if (string) {
    const number = String(string).split(".");
    const decimal = number[1];
    if (decimal)
      if (decimal.length > 1) {
        return numeral(string).format("0,0[.]00");
      } else {
        return numeral(string).format("0,0[.]0");
      }
    else {
      return numeral(string).format("0,0[.]00");
    }
  }
  return string;
};
export const formatCurrencyNoDecimal = (string) =>
  formatCurrency(unformatCurrency(string));

export const unformatCurrency = (amount) => {
  const newAmount = numeral(amount).format("0,0");
  return newAmount.split(",").join("");
};
export const unformatCurrencyDecimal = (amount) => {
  const newAmount = numeral(amount).format("0,0[.]00");
  return newAmount.split(",").join("");
};
export const formatCurrencyFixed = (string) => {
  if (string) {
    return numeral(string).format("0,0.00");
  }
  return string;
};

export const removePartnerString = (string, payroll) => {
  if (!string) return;
  if (payroll) return titleCase(string.split("payroll_").join(""));
  return titleCase(string.split("partner_").join(""));
};

// export const formatCurrencyNoDecimal = string => {
//   if (string) {
//     return numeral(string).format('0,0');
//   }
//   return string;
// };
export const toNumber = (rawNumber) => Number(unformatCurrency(rawNumber));

export const simpleDate = (date) => {
  if (!date) return "";
  const getDate = new Date(date);
  const to2Digit = (number) => {
    if (Number(number) < 10) return `0${number}`;
    return number;
  };

  const day = to2Digit(getDate.getDate());

  const month = to2Digit(getDate.getMonth() + 1);
  const year = getDate.getFullYear();
  return `${year}-${month}-${day}`;
};

export const customSum = (array) => array.reduce((prev, curr) => prev + curr);

export const dateNow = () => {
  const date = new Date().getDate();
  const month = new Date().getMonth() + 1;
  const year = new Date().getFullYear();
  return { date, month, year };
};

export const getMonthEN = (number) => {
  let result = "";
  if (number == 1) result = "January";
  if (number == 2) result = "February";
  if (number == 3) result = "March";
  if (number == 4) result = "April";
  if (number == 5) result = "May";
  if (number == 6) result = "June";
  if (number == 7) result = "July";
  if (number == 8) result = "August";
  if (number == 9) result = "September";
  if (number == 10) result = "October";
  if (number == 11) result = "November";
  if (number == 12) result = "December";
  return result;
};

export const getDayName = (numberRaw) => {
  let days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  let d = new Date(numberRaw);
  let dayName = days[d.getDay()];
  return dayName;
};

const monthInBahasa = (value) => {
  if (value === "01") {
    return (value = "Januari");
  } else if (value === "02") {
    return (value = "Februari");
  } else if (value === "03") {
    return (value = "Maret");
  } else if (value === "04") {
    return (value = "April");
  } else if (value === "05") {
    return (value = "Mei");
  } else if (value === "06") {
    return (value = "Juni");
  } else if (value === "07") {
    return (value = "Juli");
  } else if (value === "08") {
    return (value = "Agustus");
  } else if (value === "09") {
    return (value = "September");
  } else if (value === "10") {
    return (value = "Oktober");
  } else if (value === "11") {
    return (value = "November");
  } else {
    return (value = "Desember");
  }
};

export const shortMonth = (value) => {
  if (value === "01" || value == 1) {
    return (value = "Jan");
  } else if (value === "02" || value == 2) {
    return (value = "Feb");
  } else if (value === "03" || value == 3) {
    return (value = "Mar");
  } else if (value === "04" || value == 4) {
    return (value = "Apr");
  } else if (value === "05" || value == 5) {
    return (value = "May");
  } else if (value === "06" || value == 6) {
    return (value = "Jun");
  } else if (value === "07" || value == 7) {
    return (value = "July");
  } else if (value === "08" || value == 8) {
    return (value = "Aug");
  } else if (value === "09" || value == 9) {
    return (value = "Sep");
  } else if (value === "10" || value == 10) {
    return (value = "Oct");
  } else if (value === "11" || value == 11) {
    return (value = "Nov");
  } else {
    return (value = "Dec");
  }
};

const with0Decider = (number) => {
  if (String(number).length == 1) return `0${number}`;
  return number;
};

export const startDateFormatter = (dateRaw) => {
  const date = new Date(dateRaw);
  const toStart = date.setUTCHours(-7, 0, 0, 0);
  const result = new Date(new Date(toStart).getTime() + aDay);
  return result;
};

export const endDateFormatter = (dateRaw) => {
  const date = new Date(dateRaw);
  const toEnd = date.setUTCHours(16, 59, 59, 999);
  const result = new Date(new Date(toEnd).getTime() + aDay);
  return result;
};

const moment = require("moment");
export const customDateFormatter = (
  dateRaw,
  isShortMonth = false,
  woClock = false,
  MMddFormat = false,
  getDataRaw = false,
  YYYYMMdd = false
) => {
  if (!dateRaw) return "";
  const date = moment.utc(dateRaw).toDate();
  const day = date.getDate();
  const monthNumber = date.getMonth();
  const monthIndex = monthNumber + 1;

  const month = isShortMonth
    ? shortMonth(with0Decider(monthIndex))
    : getMonthEN(monthIndex);

  const year = date.getFullYear();

  if (YYYYMMdd)
    return `${year}-${
      (monthIndex + "").length === 1 ? `0${monthIndex + ""}` : monthIndex + ""
    }-${(day + "").length === 1 ? `0${day + ""}` : day + ""}`;

  if (getDataRaw) return `${day} ${monthIndex} ${year}`;

  const minutes = with0Decider(date.getMinutes());

  const hour = date.getHours();

  let defaultTime = `${day} ${month} ${year}`;

  if (MMddFormat) defaultTime = `${month} ${day}`;

  if (woClock) return defaultTime;
  const result = `${defaultTime}, ${hour}:${minutes}`;

  return result;
};

export const dateFormatter = (value) => {
  var newValue = value.split("T");
  var newHour = newValue[1].split(".0");
  var firstDate = newValue[0].split("-");
  var bulan = monthInBahasa(firstDate[1]);
  var newDate = `${firstDate[2]} ${bulan} ${firstDate[0]} ${newHour[0]}`;
  return newDate;
};

export const dateAndHoursFormatter = (value) => {
  if (!value) {
    return value;
  }
  var newValue = value.split("T");
  var newHour = newValue[1].split(".");
  var newDate = `${newValue[0]} ${newHour[0]}`;
  return newDate;
};

export const LongDateFormatter = (value) => {
  if (!value) {
    return value;
  }
  var newValue = value.split("T");
  var newHour = newValue[1].split(".0");
  var firstDate = newValue[0].split("-");
  var bulan = monthInBahasa(firstDate[1]);
  var newDate = `${firstDate[2]} ${bulan} ${firstDate[0]}`;
  return newDate;
};

export const ShortDateFormatter = (value) => {
  if (!value) {
    return value;
  }
  var newValue = value.split("T");
  var newHour = newValue[1].split(".0");
  var firstDate = newValue[0].split("-");
  var bulan = shortMonth(firstDate[1]);
  var newDate = `${firstDate[2]} ${bulan} ${firstDate[0]}`;
  return newDate;
};

export const hourDateGMT = (value) => {
  if (!value) {
    return value;
  }
  var firstSplit = value.split("T");
  var firstGMT = firstSplit[1].split("+");
  var secondGMT = firstGMT[1].split(":");
  var firstHour = firstSplit[1].split(":");
  var newDate = `${firstHour[0]}:${firstHour[1]} (GMT +${secondGMT[0]})`;
  return newDate;
};

export const digitDateFormatter = (dateRaw) => {
  const date = dateRaw || "";
  if (!date) return date;
  const isoDate = new Date(date).toISOString();
  const splittedDate = isoDate.split("-");
  const year = splittedDate[0] || "";
  const month = splittedDate[1] || "";
  const dirtyDay = splittedDate[2] || "";
  const day = dirtyDay.split("T")[0] || "";

  return `${year}/${month}/${day}`;
};

export const digitDateWithDigitHourFormatter = (dateRaw) => {
  if (!dateRaw) return dateRaw;
  const splitDate = dateRaw.split("T");
  const getOnlyDate = splitDate[0].split("-");
  const day = getOnlyDate[2] || "";
  const month = getOnlyDate[1] || "";
  const year = getOnlyDate[0] || "";
  const getOnlyTime = splitDate[1].split(".")[0].split(":");
  const hour = getOnlyTime[0] || "";
  const minute = getOnlyTime[1] || "";
  return `${day}/${month}/${year} ${hour}:${minute}`;
  // 2022-03-07T16:36:05.000+07:00
};

export const dateOnlyGetDayAndMonth = (dateRaw) => {
  if (!dateRaw) return dateRaw;

  const getFullDate = dateRaw.split("T")[0];
  const getDateRaw = getFullDate.split("-")[2];
  const getDateMonthRaw = shortMonth(getFullDate.split("-")[1]);
  const final = `${getDateRaw} ${getDateMonthRaw}`;
  return final;
  // output : 04 Oct
};

export const dateOnlyGetHour = (dateRaw) => {
  if (!dateRaw) return dateRaw;

  const getFullDate = dateRaw.split("T")[1].split(".")[0];
  const getHour = getFullDate.split(":")[0];
  const getMinute = getFullDate.split(":")[1];
  const text = Number(getHour >= 12) ? "PM" : "AM";
  const final = `${getHour}:${getMinute} ${text}`;
  return final;
  // output : 12:03 PM
};

export const FullDateFormatter = (date) => {
  if (!date) return;
  const getNewDate = new Date(date);
  const getName = getDayName(date);
  const getHour = dateOnlyGetHour(date);
  const getDateAndMonth = dateOnlyGetDayAndMonth(date);
  const getYear = getNewDate.getFullYear();
  const finalDate = `${getName}, ${getDateAndMonth} ${getYear} ${getHour}`;
  return finalDate;
  // output : Thursday, 22 Dec 2022 00:52 PM
};

export const formatBatch = (value) => {
  if (value === "waiting_for_payment") {
    return (value = "waiting payment");
  }
};

export const numberOfFilterQueriesApplied = (query) => {
  const queryKeys = Object.keys(query);
  const bannedQueries = [
    "search_box",
    "page",
    "per_page",
    "isB2C",
    "end_date",
    "id",
    "pageType",
    "from",
    "to",
    "type",
    "page_type_details",
    "page_type",
    "defaultId",
  ];

  const result = queryKeys.filter((key) => {
    const filtered = bannedQueries.includes(key);
    if (filtered) return false;
    const invalidValue = !query[key];
    if (invalidValue) return false;
    return true;
  });

  return result.length;
};

export const dataSeparator = (data, number) => {
  const result = [];
  let temp = [];
  data.forEach((item, index) => {
    temp.push(item);
    if (index % number === number - 1 || index === data.length - 1) {
      result.push(temp);
      temp = [];
    }
  });
  return result;
};

export const submitToLabelValue = (object) => {
  const keys = Object.keys(object || {});
  let obj = {};

  keys.map((key) => {
    const value = object[key];

    const isArray = Array.isArray(value);
    const isObject = typeof value == "object";

    if (isObject && !isArray) {
      const getValue = value?.value;
      if (getValue) return (obj[key] = value?.value);
      return (obj[key] = value);
    }

    return (obj[key] = value);
  });

  return obj;
};

export const numberOfFilteredQueriesForEmptyScreen = (query) =>
  Object.keys(query).length;

export const applyRegexNumbers = (value) => {
  const regex = /[0-9]/g;
  return value.replace(regex, "");
};
export const applyRegexNumbersAndLength = (value) => {
  const regex = /[0-9]{9}/g;
  return value.replace(regex, "");
};

export const applyRegexNumbersAndLength12 = (value) => {
  const regex = /[0-9]{12}/g;
  return value.replace(regex, "");
};

export const regexValidateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};
export const regexValidatePassword = (pass) => {
  const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;
  return regex.test(String(pass));
};

export const applyRegexLettersAndNumbers = (value) => {
  const regex = /[A-Za-z0-9 \.\-]/g;
  return value.replace(regex, "");
};

export const applyRegexLetters = (value) => {
  const regex = /[A-Za-z \.\-]/g;
  return value.replace(regex, "");
};

export const applyRegexName = (value) => {
  const regex = /[A-Za-z\.\,\'\-]*(-| )*[A-Za-z\.\,\'\-]/g; // allows only alphabets, specific symbols, and hyphen or space when in between words
  return value.replace(regex, "");
};

export const applyRegexLettersAndSymbols = (value) => {
  const regex = /^\D+$/g;
  return value.replace(regex, "");
};

export const applyRegexOnlyNumbers = (value) => {
  const regex = /[^\d]/g;
  return value.replace(regex, "");
};

export const regexValidateHexColors = (value) => {
  const regex = /^#[0-9a-f]{3,6}$/i;
  return regex.test(String(value));
};

export const groupAlphabetDeep = (data, string) => {
  const reduceData = data.reduce((r, e) => {
    let group = (e[string][0] || "").toUpperCase();
    if (!r[group]) r[group] = { group, items: [e] };
    else r[group].items.push(e);
    return r;
  }, {});
  let result = Object.values(reduceData);
  return result;
};

export const sectionListDateFormatter = (array, key = "created_at") => {
  const dateArray = (array || []).map((item) => {
    const date = item[key];
    const dateSection = dateString(date);
    return { ...item, dateSection };
  });

  const data = deepRemoveDuplicates(dateArray, "dateSection").map(
    (item) => item["dateSection"]
  );

  const result = data.map((date) => {
    const data = dateArray.filter((item) => date == item.dateSection);
    return { date, data };
  });
  return result;
};

export const toBooleanQuery = (string) => {
  if (string == "false") {
    return false;
  }
  if (string == "true") {
    return true;
  }
  return false;
};

export const asyncDownload = async ({ url, filename }) => {
  try {
    const { data } = await apiBusiness.get(url, {
      responseType: "blob",
    });
    const file = new Blob([data], {
      type: `application/pdf`,
    });
    const targetUrl = window.URL.createObjectURL(file);
    const link = document.createElement("a");
    link.href = targetUrl;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    link.parentNode && link.parentNode.removeChild(link);
  } catch (err) {
    console.log(err);
  }
};

export const stringCutter = (string, length = 20) =>
  string.length > length ? `${string.substring(0, length)}...` : string;

export const isProduction =
  process.env.NEXT_PUBLIC_TRANSFEZ_APP_ENV == "PRODUCTION";

export const phoneCodeFunc = (array) => {
  const result = array.map((item) => {
    const { value: valueRaw, label: countryName, code: codeRaw } = item;
    const label = valueRaw;
    const value = codeRaw;
    const code = valueRaw;
    const country_iso = codeRaw;
    return { ...item, label, value, code, country_iso, countryName };
  });
  const sortedData = dataSorter(result, "code");
  return sortedData;
};

export const asyncTimeout = (ms) =>
  new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
