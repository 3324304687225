import { useRouter } from "next/router";
import { useState } from "react";
import { ToasterHook } from "../../../../../contexts/ToasterContext";
import { apiBusiness, useMutation } from "../../../../../tools/api";

// SINGLES
export const useEWalletAllocate = ({
  walletId,
  handleErrorAllocateAdmin = () => {},
}) => {
  const { push } = useRouter();

  const afterSuccess = (res) => {
    const { id, transaction_type } = res?.data?.data || {};

    push({
      pathname: "/e-wallet",
      query: { type: "success", transaction_type, ids: [id] },
    });
  };

  const { mutation: topupEmployee, loading: loadingTopupEmployee } =
    useMutation({
      url: "/wallet_transactions",
      afterSuccess,
      method: "post",
    });

  const { mutation: allocateAdmin, loading: loadingAllocateAdmin } =
    useMutation({
      url: `/wallets/${walletId}/allocate_balance`,
      method: "post",
      afterSuccess,
      handleError: handleErrorAllocateAdmin,
    });

  const loading = loadingTopupEmployee || loadingAllocateAdmin;

  return { topupEmployee, allocateAdmin, loading };
};

const useEWalletDefault = (afterSuccess, isToActivity, isHome) => {
  const [loading, setLoading] = useState(false);
  const { errorToasterApi } = ToasterHook();
  const { push } = useRouter();

  const mutation = async (id, url, isApproved, pin = "") => {
    try {
      setLoading(true);
      await apiBusiness.post(url, { pin });

      afterSuccess && afterSuccess([id]);

      push({
        pathname: "/e-wallet",
        query: {
          type: "success",
          ids: [id],
          transaction_type: `allocation_request_${
            isApproved ? "approved" : "rejected"
          }`,
          isToActivity: isToActivity || "",
          isHome: isHome || "",
        },
      });
    } catch (error) {
      errorToasterApi(error);
    } finally {
      setLoading(false);
    }
  };

  return { mutation, loading };
};

export const useEWalletApproveAllocate = (
  afterSuccess,
  isToActivity,
  isHome
) => {
  const { loading, mutation: defaultMutation } = useEWalletDefault(
    afterSuccess,
    isToActivity,
    isHome
  );

  const mutation = (id, pin) =>
    defaultMutation(id, `/wallet_transactions/${id}/approve`, true, pin);

  return { mutation, loading };
};

export const useEWalletRejectAllocate = (
  afterSuccess,
  isToActivity,
  isHome
) => {
  const { loading, mutation: defaultMutation } = useEWalletDefault(
    afterSuccess,
    isToActivity,
    isHome
  );

  const mutation = (id) =>
    defaultMutation(id, `/wallet_transactions/${id}/reject`, false);

  return { mutation, loading };
};
// SINGLES

// BULKS
const useEWalletAllocateBulkDefault = (afterSuccess) => {
  const [loading, setLoading] = useState(false);

  const { successToaster, errorToasterApi } = ToasterHook();
  const { push } = useRouter();

  const mutation = async (ids = [], isApproved, pin = "") => {
    try {
      setLoading(true);
      const url = isApproved
        ? "/wallet_transactions/approve_bulk"
        : "/wallet_transactions/reject_bulk";

      await apiBusiness.post(url, { ids, rejection_reason: "", pin });

      successToaster({
        msg: `Selected transaction(s) has been ${
          isApproved ? "approved" : "rejected"
        }`,
      });
      afterSuccess && afterSuccess(ids);
      setTimeout(
        () =>
          push({
            query: {
              type: "success",
              ids,
              transaction_type: `allocation_request_${
                isApproved ? "approved" : "rejected"
              }`,
            },
          }),
        200
      );
    } catch (error) {
      errorToasterApi(error);
    } finally {
      setLoading(false);
    }
  };

  return { loading, mutation };
};

export const useEWalletApproveAllocateBulk = (afterSuccess) => {
  const { loading, mutation: mutationDefault } =
    useEWalletAllocateBulkDefault(afterSuccess);

  const mutation = (ids, pin) => mutationDefault(ids, true, pin);

  return { mutation, loading };
};

export const useEWalletRejectAllocateBulk = (afterSuccess) => {
  const { loading, mutation: mutationDefault } =
    useEWalletAllocateBulkDefault(afterSuccess);

  const mutation = (ids) => mutationDefault(ids, false);

  return { mutation, loading };
};
// BULKS
