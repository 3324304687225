import { useGetAuth } from "../../../../contexts/AuthContext";
import useCardWorkflow from "../../hooks/useCardWorfklow";

export const isNeedApprovalDecider = (dataDetail) => {
  const { workflow_process, get_approval_tasks } = dataDetail;

  const isApprover = (get_approval_tasks ?? []).find(
    ({ current_order }) => current_order
  );

  return isApprover;

  // const { user } = useGetAuth();
  // const { id: userId, roles } = user;

  // if (!workflow_process) return;

  // const { currentWorkflowProcess } = useCardWorkflow(dataDetail);

  // const userRole = roles.find((role) => role.name.includes("partner")).name;

  // const currentStepWorkflow = Object.keys(currentWorkflowProcess).find(
  //   (step) => {
  //     let isCurrentStepNotApproved = true;
  //     currentWorkflowProcess[step].find(({ approved_at }) => {
  //       if (approved_at) isCurrentStepNotApproved = false;
  //     });
  //     if (isCurrentStepNotApproved) return step;
  //   }
  // );

  // if (!currentStepWorkflow) return;

  // const isApprover = currentWorkflowProcess[currentStepWorkflow].some(
  //   ({ user_role_name, user_id }) => {
  //     if (user_role_name === userRole || user_id === userId) {
  //       return true;
  //     }
  //   }
  // );

  // return isApprover;
};
