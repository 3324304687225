import React from "react";
import { Modal } from "reactstrap";
import { useModalWidth } from "../components/Modals";

export const CenteredModal = ({
  hideModal,
  toggle,
  isOpen,
  children,
  style,
  scrollable,
  contentClassName = "",
  className,
  modalWidth: modalWidthProps,
}) => {
  const modalWidth = modalWidthProps || useModalWidth(528);
  return (
    <Modal
      toggle={toggle}
      isOpen={isOpen}
      className={`${className} my-0`}
      centered
      contentClassName={
        scrollable
          ? contentClassName
          : `modal-transfez ${
              hideModal ? "fade-out" : "fade-in"
            } ${contentClassName}`
      }
    >
      <div
        style={{
          backgroundColor: "white",
          width: modalWidth,
          borderRadius: 12,
          padding: 24,
          ...style,
        }}
      >
        {children}
      </div>
    </Modal>
  );
};
