import { get } from "lodash";
import { useRouter } from "next/router";
import { useContext, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { Modal, Row, Tooltip } from "reactstrap";
import styled from "styled-components";
import { colors } from "../assets/colors";
import arrowDown from "../assets/images/caret-down-pink.svg";
import arrowUp from "../assets/images/caret-up-pink.svg";
import right from "../assets/images/right-arrow.svg";
import beingVerified from "../assets/images/being-verified.svg";
import copyIcon from "../assets/images/copy-icon.svg";
import welcomeImg from "../assets/images/first-login-image.svg";
import hugeCheck from "../assets/images/huge-check.svg";
import backIcon from "../assets/images/left-arrow-back.svg";
import LockIcon from "../assets/images/lock-icon.svg";
import topupIcon from "../assets/images/topup-icon-40.svg";
import modalTimerImgSvg from "../assets/images/modalTimerImgSvg.svg";
import expiredImg from "../assets/images/expired-crossborder.svg";
import avatarEmployee from "../assets/images/avatar-employee.svg";
import avatarSuperAdmin from "../assets/images/avatar-superadmin.svg";
import avatarAdmin from "../assets/images/avatar-admin.svg";
import avatarBookkeeper from "../assets/images/avatar-bookkeeper.svg";
import avatarApprover from "../assets/images/payroll-approver-ava.png";
import avatarDrafter from "../assets/images/payroll-drafter-ava.png";
import avatarNotified from "../assets/images/payroll-notified-ava.png";
import xIcon from "../assets/images/x_calculator.svg";
import invitationSent from "../assets/images/invitation-sent.svg";
import finishOnboarding from "../assets/images/finish-onboarding.svg";
import leftArrowIcon from "../assets/images/left-arrow-pink.svg";
import { CustomTextArea, CustomTextField } from "../components/inputs";
import { applyRegexLetters, applyRegexNumbers } from "../components/tools";
import bankIconDecider from "../constants/bankIconDecider";
import bankProcedureDecider from "../constants/bankProcedureDecider";
import { AuthContext, getUserRole, useGetAuth } from "../contexts/AuthContext";
import { ToasterContext, ToasterHook } from "../contexts/ToasterContext";
import { CenteredModal } from "../modals/centeredComponents";
import { NumberedList } from "../pageComponents/kyb/KYBSupportingDocsComponents/components";
import { Agreement } from "../pageComponents/loginComponents/register/components";
import { PointNumbers } from "../pageComponents/payroll/adminOnboard/components";
import { apiBusiness, fetch } from "../tools/api";
import { eventsTracker } from "../universalFunctions/events";
import BankCard from "./BankCard";
import { CustomButton, OutlineButton } from "./Buttons";
import {
  CloseHeader,
  MainCardHeaderMolecule,
  MiddleWrapper,
  RightCardModalAtomic,
} from "./CardModal";
import {
  GothamBold,
  GothamLight,
  GothamMedium,
  GothamRegular,
  TypeFezNeueMedium,
  TypeFezNeueRegular,
} from "./Text";
import { windowDimension } from "./tools";
import {
  InputAmountModal,
  TopUpManualModal,
  TopUpPaymentInstructionModal,
} from "../pageComponents/homeComponents/topup/modals";
import { CenterendIconLocalTransfer } from "../modals/smartActivityModal/localDisbursementBatchModal/transactionModal";

export const useModalHook = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen((p) => !p);
  };
  const close = () => {
    setIsOpen(false);
  };
  const open = () => {
    setIsOpen(true);
  };
  return { toggle, isOpen, close, open };
};

export const ModalWrapper = ({ toggle, isOpen, children, width = 720 }) => {
  const modalWidth = useModalWidth(width);
  return (
    <Modal
      toggle={toggle}
      isOpen={isOpen}
      centered
      contentClassName="modal-transfez"
    >
      <div
        className="p-4"
        style={{
          backgroundColor: "white",
          width: modalWidth,
          borderRadius: 12,
        }}
      >
        {children}
      </div>
    </Modal>
  );
};

export const AtomicNotifyModal = ({
  toggle,
  isOpen,
  icon = null,
  title,
  msg,
}) => {
  const modalWidth = useModalWidth();
  return (
    <Modal
      toggle={toggle}
      isOpen={isOpen}
      centered
      contentClassName="modal-transfez"
    >
      <div
        style={{
          backgroundColor: "white",
          width: modalWidth,
          flexDirection: "column",
          borderRadius: 12,
          padding: 24,
          paddingLeft: 32,
          paddingRight: 32,
        }}
        className="d-flex align-items-center justify-content-between"
      >
        <div
          className="align-items-center d-flex"
          style={{ flexDirection: "column" }}
        >
          <GothamMedium
            style={{
              fontSize: 24,
              textAlign: "center",
              marginBottom: 24,
              color: colors.grey72,
            }}
          >
            {title}
          </GothamMedium>
          {icon}
          <GothamRegular
            style={{
              fontSize: 16,
              lineHeight: "24px",
              textAlign: "center",
              marginTop: 24,
            }}
          >
            {msg}
          </GothamRegular>
        </div>
        <CustomButton
          style={{ width: "100%", marginTop: 24, fontSize: 16 }}
          onClick={toggle}
        >
          OK
        </CustomButton>
      </div>
    </Modal>
  );
};

export const NotifyModal = ({
  toggle,
  isOpen,
  title = "Success!",
  msg = "",
  icon = <img src={hugeCheck} />,
}) => (
  <AtomicNotifyModal
    icon={icon}
    toggle={toggle}
    isOpen={isOpen}
    title={title}
    msg={msg}
  />
);

export const BeingVerifiedModal = ({ isOpen, toggle }) => (
  <NotifyModal
    isOpen={isOpen}
    toggle={toggle}
    icon={<img src={beingVerified} />}
    msg="Your account is being verified. Please wait until your verfication process is complete to use this feature "
    title="Your account is being verified"
  />
);

export const HeaderModal = ({ toggle, title }) => {
  const { figmaHeight } = windowDimension();
  return (
    <Row
      className="mx-0 justify-content-between"
      style={{ marginBottom: figmaHeight(24) }}
    >
      <GothamMedium
        className="mt-2"
        style={{
          fontSize: 24,
          lineHeight: "32px",
          color: colors.teal,
          lineHeight: "24px",
          maxWidth: "90%",
        }}
      >
        {title}
      </GothamMedium>
      <img src={xIcon} className="hover" onClick={toggle} />
    </Row>
  );
};

export const RightModal = ({ toggle, modal, children, divProps, divStyle }) => {
  const { height, margin } = windowDimension();
  return (
    <Modal
      toggle={toggle}
      isOpen={modal}
      backdropTransition={{ timeout: 3000000 }}
      className="my-0"
      style={{
        position: "absolute",
        right: margin,
        backgroundColor: "white",
        width: 400,
        boxShadow: `-4px 0px 12px 0px rgba(73, 171, 184,.4)`,
      }}
    >
      <div
        {...divProps}
        style={{ borderWidth: 0, height: height * 0.996, ...divStyle }}
      >
        {children}
      </div>
    </Modal>
  );
};

export const RefundModal = ({ isOpen, toggle, item, isSingle }) => {
  const getTrxIds = get(item, "trxIds", []);
  const hasTrxIds = Boolean(getTrxIds.length);
  const [loading, setLoading] = useState(false);
  const { figmaHeight } = windowDimension();
  const useFormObj = useForm();
  const { bank_name, account_name, account_number } = item;
  const { query, push } = useRouter();
  const { id, transactionId } = query;
  const { errorToasterApi } = useContext(ToasterContext);

  const submit = async (val) => {
    try {
      setLoading(true);
      if (hasTrxIds) {
        const url = `/refund_transactions`;
        const sendData = { ...val, approve: getTrxIds };
        await apiBusiness.post(url, sendData);
        eventsTracker("refund_transactions", { ids: getTrxIds });
      } else if (isSingle) {
        const url = `/refund_transactions`;
        const sendData = { ...val, approve: [transactionId || id] };
        await apiBusiness.post(url, sendData);
        eventsTracker("refund_transactions", { ids: getTrxIds });
      } else {
        const url = `/jobs/${id}/refunds/create_refund_batch`;
        await apiBusiness.post(url, val);
        eventsTracker("refund_batch", { batch_id: id });
      }
      push({ query: { ...query, refresh: true } });
      toggle({});
    } catch (error) {
      console.log(error);
      errorToasterApi(error);
    } finally {
      setLoading(false);
    }
  };

  const { handleSubmit, watch, setValue } = useFormObj;

  const watchBank = watch("bank_name");
  const watchAccName = watch("account_name");
  const watchAccNum = watch("account_number");
  const checkBank = applyRegexLetters(watchBank || "");
  const checkAccName = applyRegexLetters(watchAccName || "");
  const checkAccNum = applyRegexNumbers(watchAccNum || "");

  useEffect(() => {
    if (checkBank) {
      setValue("bank_name", watchBank.replace(checkBank, ""));
    }
    if (checkAccName) {
      setValue("account_name", watchAccName.replace(checkAccName, ""));
    }
    if (checkAccNum) {
      setValue("account_number", watchAccNum.replace(checkAccNum, ""));
    }
  }, [watchBank, watchAccName, watchAccNum]);

  const emptyString = (string) => string == "";
  const FormRefundData = [
    {
      name: "bank_name",
      label: "Your bank name",
      defaultValue: bank_name,
      length: 51,
      error: emptyString(watchBank),
    },
    {
      name: "account_name",
      label: "Your account name",
      defaultValue: account_name,
      length: 51,
      error: emptyString(watchAccName),
    },
    {
      name: "account_number",
      label: "Your account number",
      defaultValue: account_number,
      length: 17,
      error: emptyString(watchAccNum),
    },
  ];

  return (
    <Modal
      toggle={() => toggle({})}
      isOpen={isOpen}
      centered
      contentClassName="modal-transfez"
    >
      <div
        style={{
          backgroundColor: "white",
          width: 712,
          borderRadius: 12,
        }}
        className="p-4"
      >
        <Row
          className="mx-0 justify-content-between"
          style={{ marginBottom: figmaHeight(24) }}
        >
          <GothamMedium
            className="mt-2"
            style={{
              fontSize: 24,
              color: colors.teal,
              lineHeight: "24px",
            }}
          >
            Refund
          </GothamMedium>
          <img src={xIcon} className="hover" onClick={() => toggle({})} />
        </Row>
        <GothamRegular className="mb-4" style={{ fontSize: 16 }}>
          Refund to bank account
        </GothamRegular>
        <form onSubmit={handleSubmit(submit)}>
          {FormRefundData.map(({ length, ...props }, index) => {
            return (
              <CustomTextField
                key={index}
                {...props}
                useFormObj={useFormObj}
                inputProps={{ maxLength: length }}
              />
            );
          })}
          <div className="d-flex justify-content-end">
            <CustomButton
              isLoading={loading}
              type="submit"
              className="px-4"
              style={{
                fontSize: 20,
                height: 48,
              }}
            >
              Submit
            </CustomButton>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export const useModalWidth = (defaultWidth = 516) => {
  const { isTabOrPhone, width } = windowDimension();
  const modalWidth = isTabOrPhone ? width - 16 : defaultWidth;
  return modalWidth;
};

export const WelcomeModal = ({ isOpen, toggle }) => {
  const [checked, setChecked] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);

  const { user, authorizeAgreed } = useGetAuth();
  const { errorToasterApi } = ToasterHook();

  const handleCheck = () => setChecked((p) => !p);

  const onSubmit = async () => {
    try {
      setLoading(true);
      const url = "/update_account";
      const result = { is_agreed: true };
      await apiBusiness.put(url, result);
      authorizeAgreed(result);

      if (!user?.is_invitee) setSubmitted(true);
    } catch (error) {
      errorToasterApi(error);
    } finally {
      setLoading(false);
    }
  };

  const modalWidth = useModalWidth();
  return (
    <>
      <Modal
        isOpen={submitted ? false : isOpen}
        toggle={toggle}
        backdrop={"static"}
        keyboard={false}
        centered
        contentClassName="modal-transfez"
      >
        <div
          style={{
            backgroundColor: "white",
            borderRadius: 12,
            width: modalWidth,
            textAlign: "center",
            padding: 24,
            paddingLeft: 32,
            paddingRight: 32,
          }}
        >
          <GothamMedium
            style={{
              fontSize: 20,
              lineHeight: "28px",
              marginBottom: 24,
            }}
          >
            Welcome To Transfez
          </GothamMedium>
          <img src={welcomeImg} width={345} />
          <GothamRegular
            className="my-4"
            style={{ fontSize: 14, lineHeight: "22px" }}
          >
            Welcome to Transfez Business. Enjoy easier and faster transaction
            from anywhere to everywhere. Verify your account to start using
            Transfez
          </GothamRegular>
          <div className="d-flex align-items-center my-4">
            <Agreement isChecked={checked} toggle={handleCheck} />
          </div>
          <CustomButton
            isLoading={loading}
            disabled={!checked}
            style={{ width: "100%", fontSize: 20 }}
            onClick={onSubmit}
          >
            Next
          </CustomButton>
        </div>
      </Modal>
      <ChangePasswordModal isOpen={submitted} />
    </>
  );
};

export const ChangePasswordModal = ({ isOpen }) => {
  const modalWidth = useModalWidth();
  const [submitted, setSubmitted] = useState(false);
  const { push } = useRouter();

  const handleChangePassword = () => {
    eventsTracker("change_password_modal");
    setSubmitted(true);
    push("/account-details");
  };

  return (
    <>
      <Modal
        isOpen={submitted ? false : isOpen}
        backdrop={"static"}
        keyboard={false}
        centered
        contentClassName="modal-transfez"
      >
        <div
          style={{
            backgroundColor: "white",
            borderRadius: 12,
            width: modalWidth,
            textAlign: "center",
            padding: 24,
            paddingLeft: 32,
            paddingRight: 32,
          }}
        >
          <div style={{ textAlign: "center" }}>
            <GothamMedium
              style={{
                fontSize: 20,
                lineHeight: "28px",
                marginBottom: 24,
              }}
            >
              Protect Your Account
            </GothamMedium>
            <img src={LockIcon} />
            <GothamRegular
              className="my-4"
              style={{ fontSize: 14, lineHeight: "22px" }}
            >
              For your account security, please change your password
            </GothamRegular>
            <CustomButton
              style={{ width: "100%" }}
              onClick={handleChangePassword}
            >
              Update Password
            </CustomButton>
          </div>
        </div>
      </Modal>
    </>
  );
};

export const HowToTopupModal = ({ isOpen, dismissModal }) => {
  const handleOk = () => {
    dismissModal(false);
  };
  return (
    <>
      <Modal
        isOpen={isOpen}
        backdrop={"static"}
        keyboard={false}
        centered
        contentClassName="modal-transfez"
      >
        <div
          style={{
            backgroundColor: "white",
            borderRadius: 12,
            width: 480,
          }}
          className="p-4"
        >
          <div style={{ textAlign: "center" }}>
            <GothamRegular
              className="my-4"
              style={{ fontSize: 24, color: colors.grey72 }}
            >
              How to Top Up
            </GothamRegular>
            <GothamRegular className="my-4" style={{ fontSize: 16 }}>
              Please contact our account manager to top up the account balance
            </GothamRegular>
            <CustomButton
              style={{ width: "100%", fontSize: 20 }}
              onClick={handleOk}
            >
              OK
            </CustomButton>
          </div>
        </div>
      </Modal>
    </>
  );
};

export const CreatedVAModal = ({
  isOpen,
  id,
  setLinkClicked,
  dismissModal,
}) => {
  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          dismissModal(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  const HandlePay = () => {
    eventsTracker("virtual_account_to_id_callback", { id });
    setLinkClicked(`/virtual-account/${id}/callback`);
  };

  const ModalText = styled(GothamMedium)`
    color: #727272;
    padding: 12px;
    font-size: 14px;
    border-radius: ${(p) => p.bradius};
    margin-bottom: 0;
    cursor: pointer;
    :hover {
      background-color: #c1eff4;
    }
  `;

  return (
    <>
      {isOpen && (
        <div
          ref={wrapperRef}
          style={{
            position: "absolute",
            left: "-80px",
            top: "50px",
            backgroundColor: "white",
            border: "1px solid #49ABB8",
            borderRadius: 12,
            width: 116,
            zIndex: 1,
          }}
        >
          <ModalText onClick={HandlePay} bradius="12px">
            Pay
          </ModalText>
        </div>
      )}
    </>
  );
};

export const CardModal = ({
  isOpen,
  headerStyle,
  header,
  middleStyle,
  middle,
  bodyStyle,
  body,
}) => {
  const StyledModal = styled(Modal)`
    > .modal-content {
      border: 0px solid transparent;
    }
  `;

  return (
    <>
      <StyledModal
        isOpen={isOpen}
        // backdrop={'static'}
        keyboard={true}
        contentClassName="my-0"
        backdropTransition={{ timeout: 3000000 }}
        style={{
          position: "absolute",
          right: 50,
          backgroundColor: "white",
          width: 440,
          height: 720,
          border: "0px solid transparent",
          boxShadow: `0px 8px 40px rgba(73, 171, 184, 0.3)`,
          borderRadius: 24,
          overflow: "hidden",
        }}
      >
        <div
          style={{
            background:
              "linear-gradient(rgba(73,171,184,1) 5%, rgba(25,110,151,1) 100%)",
            borderRadius: 24,
            height: 244,
            margin: "-1px",
            border: "1px solid transparent",
            padding: 20,
            position: "relative",
            ...headerStyle,
          }}
        >
          {header}
        </div>
        <div style={{ position: "relative", ...middleStyle }}>{middle}</div>
        <div style={{ position: "relative", ...bodyStyle }}>{body}</div>
      </StyledModal>
    </>
  );
};

export const ModalWithTextArea = ({
  toggle,
  isOpen,
  text = "",
  title = "",
  subtitle = "",
  onSubmit,
  isBlock = false,
}) => {
  const useFormObj = useForm();
  const { handleSubmit, watch } = useFormObj;
  const [middleHeight, setMiddleHeight] = useState(0);

  const watchTextArea = watch("remarks");

  const { figmaHeight } = windowDimension();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <RightCardModalAtomic
        toggle={toggle}
        isOpen={isOpen}
        middleHeight={300}
        headerStyle={{
          marginBottom: 24,
          // codingan prima. Silahkan di refactor yang bisa baca
          minHeight: figmaHeight(
            isBlock
              ? text === ""
                ? subtitle === ""
                  ? 225
                  : 245
                : subtitle === ""
                ? 250
                : 275
              : 100
          ),
        }}
        header={
          <>
            <div className="d-flex justify-content-between mb-4">
              <div></div>
              <div
                className="d-flex"
                onClick={toggle}
                style={{ cursor: "pointer" }}
              >
                <GothamRegular
                  style={{
                    fontSize: 12,
                    marginBottom: 0,
                    marginRight: 5,
                    color: "white",
                  }}
                >
                  Close
                </GothamRegular>
                <img
                  src={xIcon}
                  className="hover"
                  style={{ color: "#FFD27C", width: 16 }}
                />
              </div>
            </div>
            <div className="d-flex flex-column ">
              <GothamMedium
                style={{
                  fontSize: 24,
                  marginBottom: 12,
                  color: "white",
                }}
              >
                {title}
              </GothamMedium>
              {subtitle && (
                <GothamMedium
                  style={{
                    fontSize: 24,
                    marginBottom: 12,
                    color: "white",
                  }}
                >
                  {subtitle}
                </GothamMedium>
              )}
              <GothamRegular
                style={{
                  fontFamily: "GothamLight",
                  color: "white",
                  marginBottom: 24,
                }}
              >
                {text}
              </GothamRegular>
              <CustomTextArea
                name={"remarks"}
                label={"Reason(s)"}
                placeholder={`Reason(s) for ${
                  title.startsWith("Activate") ? "activation" : "deactivation"
                }`}
                useFormObj={useFormObj}
                style={{
                  width: 360,
                  height: 114,
                  boxShadow: "0px 8px 20px rgba(88, 88, 88, 0.1)",
                  marginBottom: 24,
                }}
              />
            </div>
          </>
        }
        middle={
          <MiddleWrapper setHeight={setMiddleHeight} height={middleHeight}>
            <div className="d-flex flex-column">
              <CustomButton
                type="submit"
                style={{
                  height: 48,
                  fontSize: 20,
                }}
                disabled={!watchTextArea}
                opacityControl={0.9}
                onClick={onSubmit}
              >
                Confirm
              </CustomButton>
            </div>
            <div
              style={{
                marginTop: 12,
                alignContent: "center",
              }}
            >
              <OutlineButton
                onClick={toggle}
                style={{
                  width: "100%",
                  height: 48,
                  fontSize: 20,
                  borderColor: colors.pink,
                  color: colors.pink,
                }}
              >
                Cancel
              </OutlineButton>
            </div>
          </MiddleWrapper>
        }
      />
    </form>
  );
};

export const TimerModal = ({ isOpen, onCloseModal }) => {
  const { isTabOrPhone, width } = windowDimension();
  return (
    <>
      <Modal
        isOpen={isOpen}
        backdrop={"static"}
        keyboard={false}
        centered
        contentClassName="modal-transfez"
      >
        <div
          style={{
            backgroundColor: "white",
            borderRadius: 12,
            width: isTabOrPhone ? width : 480,
            textAlign: "center",
            padding: "24px 32px",
          }}
        >
          <TypeFezNeueMedium
            style={{
              fontSize: 20,
              lineHeight: "24px",
              marginBottom: 0,
              color: colors.blue2C,
            }}
          >
            Your Transaction has Expired
          </TypeFezNeueMedium>
          <img
            src={expiredImg}
            className="py-4"
            style={isTabOrPhone ? { width: (width * 3) / 4 } : {}}
          />
          <TypeFezNeueRegular
            style={{
              lineHeight: "20px",
              marginBottom: 24,
              color: colors.grey33,
            }}
          >
            This transaction was expired due to a currency rate change. Kindly
            start a new transaction and complete it within 10 minutes to
            minimize error.
          </TypeFezNeueRegular>
          <CustomButton
            style={{ width: "100%", fontSize: 16, lineHeight: "24px" }}
            onClick={onCloseModal}
          >
            Okay
          </CustomButton>
        </div>
      </Modal>
    </>
  );
};

const dataBCA = {
  account_name: "INDO KOALA REMITTANCE PT",
  bank: "Bank BCA",
  code: "008",
  is_active: true,
  number: "5230636399",
};

export const TopUpModal = ({
  toggle,
  modal,
  back,
  paid,
  onClickPaid = () => {},
  header,
  url = "/my_virtual_accounts",
  noRobot,
}) => {
  if (!modal) return null;

  // STATES
  const { successToaster } = useContext(ToasterContext);
  const [banks, setBanks] = useState([]);
  const [selectedBank, setSelectedBank] = useState(null);

  // STATE CHECKERS
  const banksIsFilled = Boolean(banks.length);

  // FETCHES
  const { data } = fetch({ url });
  const { data: VABanks } = data;
  const banksRobot = [dataBCA];

  // SIDE EFFECTS
  useEffect(() => {
    if (!VABanks) return;
    setBanks([...VABanks]);
  }, [VABanks]);

  const { isOpen: isOpenProofPayment, toggle: toggleProofPayment } =
    useModalHook();
  const { isOpen: isOpenInputAmountModal, toggle: toggleInputAmountModal } =
    useModalHook();
  const { isOpen: isOpenPaymentInstruction, toggle: togglePaymentInstruction } =
    useModalHook();

  // HANDLER FUNCTIONS
  const handleClick = ({ type, value, isBCA }) => {
    const isHandleSelectBank = type === "select_bank";
    const isHandleBack = type === "back";
    const isHandleClose = type === "close";
    const isHandleToggle = type === "toggle";
    const isHandleHavePaid = type === "paid";
    const isBankTransfer = type === "bank_transfer";

    if (isHandleSelectBank) {
      const bank = value.bank;

      setSelectedBank(value);

      eventsTracker("select_topup_bank", { bank });

      return;
    }
    // if (onBack) return onBack();
    if (isHandleBack) return setSelectedBank(null);
    if (isHandleClose) {
      setSelectedBank(null);
      toggle();
    }
    if (isHandleToggle) {
      toggle();
    }
    if (isBankTransfer) return toggleInputAmountModal();
    if (isHandleHavePaid) {
      if (isBCA) return toggleProofPayment();
      onClickPaid();
      // setSelectedBank(null);
      // toggle();
    }
  };

  // INTERNAL COMPONENT SLICING
  const styleFlexBetween = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  };

  const isBCA = selectedBank?.bank === "Bank BCA";

  const CloseButton = () => {
    return (
      <div
        className={`d-flex ${selectedBank ? "" : "flex-row-reverse"}`}
        style={styleFlexBetween}
      >
        <div
          style={{ cursor: "pointer" }}
          className={`d-flex ${selectedBank ? "flex-row-reverse" : ""}`}
          onClick={() => handleClick({ type: selectedBank ? "back" : "close" })}
        >
          <GothamMedium
            style={{
              fontSize: 14,
              marginBottom: 0,
              marginRight: selectedBank ? 0 : 5,
              marginLeft: selectedBank ? 5 : 0,
              color: "white",
            }}
          >
            {selectedBank ? "Back" : "Close"}
          </GothamMedium>
          <img
            src={selectedBank ? backIcon : xIcon}
            className="hover"
            style={{ width: 16 }}
          />
        </div>
        {selectedBank && (
          <div
            style={{ cursor: "pointer" }}
            className={`d-flex`}
            onClick={() => handleClick({ type: "close" })}
          >
            <GothamMedium
              style={{
                fontSize: 14,
                marginBottom: 0,
                marginRight: 5,
                color: "white",
              }}
            >
              Close
            </GothamMedium>
            <img src={xIcon} className="hover" style={{ width: 16 }} />
          </div>
        )}
      </div>
    );
  };

  const BackButton = ({ toggle, color = "white", isMedium = false }) => (
    <>
      <div
        className="d-flex align-items-center"
        onClick={() => handleClick({ type: selectedBank ? "back" : "toggle" })}
        style={{ cursor: "pointer" }}
      >
        <img src={leftArrowIcon} style={{ color: "#FFD27C" }} />
        <GothamRegular
          style={{
            fontFamily: isMedium ? "GothamMedium" : "GothamBook",
            fontSize: 12,
            marginBottom: 0,
            marginLeft: 5,
            color,
          }}
        >
          Back
        </GothamRegular>
      </div>
    </>
  );

  const Description = () => {
    const titleBank = isBCA ? "" : "Virtual Account";

    const dataRender = [
      {
        title: "Account Name",
        value: selectedBank?.account_name,
      },
      {
        title: "No. Virtual Account",
        value: selectedBank?.number,
      },
    ];

    const titleDecider = () => {
      if (selectedBank) {
        if (noRobot) return "Payment Instruction";
        return "Transfer To";
      }
      return "Top Up";
    };

    return (
      <div>
        <GothamMedium style={{ color: "#FCFCFC", fontSize: 24, marginTop: 20 }}>
          {titleDecider()}
        </GothamMedium>
        {selectedBank && (
          <div style={{ height: 49, position: "relative", zIndex: 10 }}>
            <div
              style={{
                borderRadius: 12,
                backgroundColor: "#FFF",
                marginTop: 20,
                padding: 16,
                boxShadow: "0px 4px 8px rgba(88, 88, 88, 0.06)",
              }}
            >
              <div
                className="d-flex align-items-center"
                style={{
                  marginBottom: 16,
                  paddingBottom: 8,
                  gap: 8,
                  borderBottom: `1px solid ${colors.greye6}`,
                }}
              >
                <img src={bankIconDecider(selectedBank.bank)} />
                <TypeFezNeueMedium>
                  {selectedBank.bank} {titleBank}
                </TypeFezNeueMedium>
              </div>

              {dataRender?.map((item, index) => {
                const isFirst = index === 0;
                const { title, value } = item || {};
                const [tooltipOpen, setTooltipOpen] = useState(false);
                const toggleTooltip = () => setTooltipOpen(!tooltipOpen);
                return (
                  <div key={index} style={{ marginBottom: isFirst && 16 }}>
                    <TypeFezNeueRegular
                      style={{
                        fontSize: 12,
                        color: colors.grey6c,
                        lineHeight: "16px",
                        marginBottom: 4,
                      }}
                    >
                      {title}
                    </TypeFezNeueRegular>
                    <div style={{ ...styleFlexBetween }}>
                      <TypeFezNeueMedium
                        style={{
                          marginRight: 8,
                          cursor: "pointer",
                          lineHeight: "20px",
                        }}
                      >
                        {value}
                      </TypeFezNeueMedium>
                      <div
                        id={"Tooltip-" + (index + 1)}
                        style={{ cursor: "pointer" }}
                        onClick={(e) => {
                          // successToaster({ msg: "Item is Copied" });
                          navigator.clipboard.writeText(value);
                          e.preventDefault();
                          e.stopPropagation();
                        }}
                      >
                        <img src={copyIcon} style={{ marginTop: -8 }} />
                      </div>
                      <Tooltip
                        target={"Tooltip-" + (index + 1)}
                        isOpen={tooltipOpen}
                        toggle={toggleTooltip}
                        style={{ padding: "0px 12px 2px 12px" }}
                      >
                        <span
                          style={{
                            fontSize: 10,
                            lineHeight: "12px",
                            fontFamily: "TypefezNeueRegular",
                          }}
                        >
                          Copy
                        </span>
                      </Tooltip>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
        {!selectedBank && (
          <GothamLight
            style={{
              color: "#FFFFFF",
              fontSize: 14,
              letterSpacing: 0.1,
              lineHeight: 1.5,
              marginTop: 20,
            }}
          >
            Please select one of these payment methods.
          </GothamLight>
        )}
      </div>
    );
  };

  const BankSelection = () => {
    const [isExpandedIndex, setIsExpandedIndex] = useState(0);

    const stateObj = {
      isExpandedIndex: isExpandedIndex,
      setIsExpandedIndex: setIsExpandedIndex,
    };

    if (selectedBank) {
      const procedure = bankProcedureDecider(selectedBank.bank);

      const procedureKeys = Object.keys(procedure).map(
        (procedure) => procedure
      );
      const procedureValues = Object.values(procedure).map(
        (procedure) => procedure
      );

      const showPaidButton = paid || isBCA;

      return (
        <div style={{ marginTop: 110, paddingTop: "20px" }}>
          {showPaidButton && (
            <CustomButton
              style={{
                width: "100%",
                marginBottom: "20px",
                fontSize: 14,
                height: 40,
              }}
              onClick={() => handleClick({ type: "paid", isBCA })}
            >
              I Have Paid
            </CustomButton>
          )}
          <TypeFezNeueMedium
            style={{
              fontSize: 16,
              lineHeight: "24px",
              paddingBottom: 8,
              marginBottom: 16,
              borderBottom: `1px solid ${colors.greyea}`,
            }}
          >
            How to make the payment
          </TypeFezNeueMedium>
          {procedureKeys.map((procedure, index) => (
            <ProcedureComponent
              key={procedure}
              procedure={procedure}
              procedures={procedureValues[index]}
              index={index}
              stateObj={stateObj}
            />
          ))}
        </div>
      );
    }

    return (
      <>
        <div style={{ padding: "32px 0px 20px 0px" }}>
          <TypeFezNeueMedium>Virtual Account</TypeFezNeueMedium>
          {banksIsFilled &&
            banks.map((bank) => {
              const { bank: bankName, code } = bank;

              return (
                <BankCard
                  bank={bank}
                  bankName={bankName}
                  key={code}
                  onClick={handleClick}
                />
              );
            })}
        </div>
        {!noRobot && (
          <div>
            <TypeFezNeueMedium>Bank Transfer</TypeFezNeueMedium>
            {banksIsFilled &&
              banksRobot.map((bank) => {
                const { bank: bankName, code } = bank;

                return (
                  <BankCard
                    bank={bank}
                    bankName={bankName}
                    key={code}
                    onClick={() => handleClick({ type: "bank_transfer" })}
                  />
                );
              })}
          </div>
        )}
      </>
    );
  };

  const [middleHeight, setMiddleHeight] = useState(0);
  const [dataAfterTopup, setDataAfterTopup] = useState({});

  const isSelected = !!selectedBank;
  // COMPONENT MAIN RENDER
  return (
    <>
      <RightCardModalAtomic
        isOpen={modal}
        header={
          !!header && !isSelected ? (
            header
          ) : (
            <>
              {back ? <BackButton /> : <CloseButton />}
              <Description />
            </>
          )
        }
        headerStyle={{ paddingBottom: 60 }}
        middle={
          <MiddleWrapper setHeight={setMiddleHeight} height={middleHeight}>
            <CenterendIconLocalTransfer icon={topupIcon} />
          </MiddleWrapper>
        }
        toggle={() => handleClick({ type: "close" })}
        body={<BankSelection />}
        hideModal={!modal || isOpenProofPayment}
      />
      <InputAmountModal
        isOpen={isOpenInputAmountModal}
        toggle={toggleInputAmountModal}
        setAfterSuccess={(val) => {
          setDataAfterTopup(val);
          toggleInputAmountModal();
          togglePaymentInstruction();
        }}
      />
      <TopUpPaymentInstructionModal
        isOpen={isOpenPaymentInstruction}
        toggle={togglePaymentInstruction}
        paid={paid}
        onClickPaid={onClickPaid}
        data={dataAfterTopup}
      />
      <TopUpManualModal
        isOpen={isOpenProofPayment}
        toggle={toggleProofPayment}
        setAfterSuccessManual={toggle}
      />
    </>
  );
};

export const ProceduresComponent = ({ procedure, index }) => {
  const externalUrl = "https://ibank.bni.co.id";

  let procedureStr = "";

  if (procedure.includes(externalUrl)) {
    procedureStr = procedure.split(externalUrl);
  } else {
    procedureStr = procedure;
  }

  // const handleClickUrl = () => {
  //   document.location.href = externalUrl;
  // };

  // const InlineText = ({ text }) => {
  //   return <span onClick={handleClickUrl}>{text}</span>;
  // };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        marginBottom: 8,
      }}
    >
      <div
        style={{
          width: 16,
          minWidth: 16,
          height: 16,
          borderRadius: 11,
          backgroundColor: "#2C4A77",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginRight: 16,
          overflow: "visible",
        }}
      >
        <GothamMedium style={{ fontSize: 10, color: "#FCFCFC" }}>
          {index + 1}
        </GothamMedium>
      </div>
      <h1 style={{ textDecorationLine: "underline" }}></h1>
      <GothamLight
        style={{ fontSize: 12, lineHeight: "16px", color: colors.grey6c }}
      >
        {procedure.includes(externalUrl) ? procedureStr[0] : procedureStr}
        {procedure.includes(externalUrl) && (
          <a
            style={{
              cursor: "pointer",
              textDecorationLine: "underline",
              display: "inline",
            }}
            href={externalUrl}
            target="_blank"
          >
            {externalUrl}
          </a>
        )}
        {procedure.includes(externalUrl) && procedureStr[1]}
      </GothamLight>
    </div>
  );
};

export const ProcedureComponent = ({
  procedure,
  procedures,
  stateObj,
  index,
}) => {
  const { isExpandedIndex, setIsExpandedIndex } = stateObj;

  const styleFlexBetween = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  };

  const sameIndex = isExpandedIndex === index;
  return (
    <div
      style={{
        borderBottom: "1px solid #E6E6E8",
      }}
    >
      <div
        style={{
          ...styleFlexBetween,
          height: 24,
          cursor: "pointer",
          margin: "12px 0px",
        }}
        onClick={() => {
          if (sameIndex) return setIsExpandedIndex(null);
          setIsExpandedIndex(index);
        }}
      >
        <TypeFezNeueRegular>{procedure}</TypeFezNeueRegular>
        <img
          src={sameIndex ? arrowUp : arrowDown}
          style={{ marginRight: sameIndex && "-4px" }}
        />
      </div>
      <div>
        {sameIndex &&
          procedures.map((procedure, index) => (
            <ProceduresComponent
              procedure={procedure}
              index={index}
              key={`${procedure}-${index}`}
            />
          ))}
      </div>
    </div>
  );
};

export const WithdrawModal = ({ toggle, modal, isChangeAccount }) => {
  const modalWidth = useModalWidth(480);
  return (
    <Modal
      toggle={toggle}
      isOpen={modal}
      className="my-0"
      centered
      contentClassName="modal-transfez"
    >
      <div
        style={{
          backgroundColor: "white",
          width: modalWidth,
          borderRadius: 12,
          padding: "24px 32px",
          textAlign: "center",
        }}
      >
        <GothamMedium
          style={{ fontSize: 20, lineHeight: "48px", color: colors.grey33 }}
        >
          Withdraw
        </GothamMedium>
        <GothamRegular
          style={{
            lineHeight: "22px",
            textAlign: "center",
            marginTop: 24,
            marginBottom: 24,
            color: colors.grey33,
          }}
        >
          {isChangeAccount
            ? "Please contact our account manager to change your destination bank details"
            : "Please contact our account manager to withdraw funds from the account"}
        </GothamRegular>
        <CustomButton
          style={{ width: "100%", height: 48, fontSize: 16 }}
          onClick={toggle}
        >
          I Understand
        </CustomButton>
      </div>
    </Modal>
  );
};

export const HowPayrollWorksModal = ({ toggle, isOpen, style }) => {
  const rolesData = [
    {
      icon: avatarDrafter,
      title: "Drafter",
      desc: "As a Drafter you can create the payroll transaction",
    },
    {
      icon: avatarApprover,
      title: "Approver",
      desc: "As an Approver you can approve all the payroll created by drafters",
    },
    // {
    //   icon: avatarNotified,
    //   title: "Notified",
    //   desc: "You’ll get notified for all the processes afterward",
    // },
  ];

  const createPayrollData = [
    {
      value:
        "Add team members (e.g HR team) and create workflow by selecting roles for each",
    },
    {
      value: "Drafter creates payroll transaction",
    },
    {
      value: "Payroll transaction approved by Approver",
    },
  ];

  const fundReleaseData = [
    {
      value: "Admin/Business Owner reviews the approved payroll transaction",
    },
    {
      value: "Admin/Business Owner releases the fund",
    },
  ];

  const Title = ({ title }) => {
    return (
      <GothamMedium
        className="font16"
        style={{ marginBottom: 12, color: colors.blue10 }}
      >
        {title}
      </GothamMedium>
    );
  };

  useEffect(() => {
    if (!isOpen) return;
    eventsTracker("open_how_payroll_works");
  }, [isOpen]);

  return (
    <CenteredModal isOpen={isOpen} toggle={toggle} style={style}>
      <div className="d-flex justify-content-between ">
        <CloseHeader color={colors.pink} toggle={toggle} isMedium />
      </div>
      <GothamMedium className="font24 mb-3" style={{ color: colors.blue10 }}>
        How Payroll Works
      </GothamMedium>
      <GothamRegular style={{ marginBottom: 12 }}>
        See how the payroll works by understanding the steps and the roles
      </GothamRegular>
      <Title title="Payroll Roles" />
      {rolesData.map(({ icon, title, desc }, index) => {
        return (
          <div
            key={index}
            className="d-flex align-items-center"
            style={{ marginBottom: 20 }}
          >
            <img src={icon} style={{ marginRight: 12 }} />
            <div>
              <GothamBold
                style={{
                  color: colors.blue10,
                  lineHeight: "20px",
                  marginBottom: 0,
                }}
              >
                {title}
              </GothamBold>
              <GothamRegular style={{ lineHeight: "20px" }}>
                {desc}
              </GothamRegular>
            </div>
          </div>
        );
      })}
      <div style={{ marginBottom: 12 }}>
        <Title title="Create Payroll" />
        <PointNumbers data={createPayrollData} isMargin8 />
      </div>
      <div style={{ marginBottom: 16 }}>
        <Title title="Fund Release" />
        <PointNumbers data={fundReleaseData} isMargin8 />
      </div>
      <CustomButton style={{ width: "100%" }} onClick={toggle}>
        Close
      </CustomButton>
    </CenteredModal>
  );
};

export const HowRoleWorksModal = ({ toggle, isOpen, style }) => {
  const rolesData = [
    {
      icon: avatarSuperAdmin,
      title: "Business Owner",
      desc: "You can assign authorized people as admins and has access to all Transfez's full capabilities and controls",
    },
    {
      icon: avatarAdmin,
      title: "Admin",
      desc: "You can access Transfez's full capabilities and controls, except assigning another admin",
    },
    {
      icon: avatarBookkeeper,
      title: "Bookkeeper",
      desc: "You can view and download all transactional data but can not manage account",
    },
    {
      icon: avatarEmployee,
      title: "Employee",
      desc: "You can create any transaction for disbursement, payroll, invoice payment, reimbursement, and you can be assigned as approver for some products",
    },
  ];

  const Title = ({ title }) => {
    return (
      <GothamMedium
        className="font16"
        style={{ marginBottom: 12, color: colors.blue10 }}
      >
        {title}
      </GothamMedium>
    );
  };

  return (
    <CenteredModal isOpen={isOpen} toggle={toggle} style={style}>
      <div className="d-flex justify-content-between ">
        <CloseHeader color={colors.pink} toggle={toggle} isMedium />
      </div>
      <GothamMedium className="font24 mb-3" style={{ color: colors.blue10 }}>
        How Roles Works
      </GothamMedium>
      <GothamRegular style={{ marginBottom: 12 }}>
        See how the roles works by understanding the roles
      </GothamRegular>
      <Title title="Roles" />
      {rolesData.map(({ icon, title, desc }, index) => {
        return (
          <div
            key={index}
            className="d-flex align-items-center"
            style={{ marginBottom: 20 }}
          >
            <img src={icon} style={{ marginRight: 12 }} />
            <div>
              <GothamBold
                style={{
                  color: colors.blue10,
                  lineHeight: "20px",
                  marginBottom: 0,
                }}
              >
                {title}
              </GothamBold>
              <GothamRegular style={{ lineHeight: "20px" }}>
                {desc}
              </GothamRegular>
            </div>
          </div>
        );
      })}

      <CustomButton style={{ width: "100%" }} onClick={toggle}>
        Close
      </CustomButton>
    </CenteredModal>
  );
};

export const SuccessfulInviteModal = ({ toggle, isOpen }) => {
  return (
    <CenteredModal isOpen={isOpen} toggle={toggle}>
      <GothamMedium
        className="font24 mb-3"
        style={{ color: colors.blue10, textAlign: "center" }}
      >
        Invitation Sent!
      </GothamMedium>

      <div
        className="d-flex align-items-center justify-content-center"
        style={{ marginBottom: 24, flexDirection: "column" }}
      >
        <img src={invitationSent} style={{ marginBottom: 24 }} />
        <GothamRegular style={{ lineHeight: "20px", color: colors.grey33 }}>
          {
            "We have sent an email to the invited users. \nPlease notify the user to complete the process"
          }
        </GothamRegular>
      </div>

      <CustomButton style={{ width: "100%", marginTop: 24 }} onClick={toggle}>
        Close
      </CustomButton>
    </CenteredModal>
  );
};

export const FinishOnboardingModal = ({
  toggle,
  isOpen,
  style,
  val,
  img,
  title = "",
  buttonText = "",
  imgStyle,
}) => {
  const { isSuperAdmin, isAdmin, isBookKeeper, isEmployee } = getUserRole();
  const isForgotPass = val === "forgot_password";
  const isForgot = isForgotPass || val === "forgot_pin";

  const superAdminMessage = [
    "As a Business Owner, you can do several things below:",
    "Assign authorized person (through PoA) as an admin",
    "Access to all of Transfez's full capabilities and controls",
  ];

  const adminMessage = [
    "As an admin, you can access Transfez's full capabilities and controls",
    "Manage users and money flow",
    "Creating approval flow",
    "Release fund for payment",
  ];

  const bookkeeperMessage = [
    "As bookkeeper, you can do several things below:",
    "Can view and download all transactional data.",
    "Creating transactions for disbursement, payroll, invoice payment, and reimbursement",
    "Can request card and team wallet",
    "Can request team wallet top up",
  ];

  const employeeMessage = [
    "As an employee, you can do several things below:",
    "Creating transactions for disbursement, payroll, invoice payment, and reimbursement",
    "Can request card and team wallet",
    "Can request team wallet top up",
  ];

  const forgotMessage = [
    "Open your email",
    "Enter the reset code we sent to your email ",
    `Create a new ${isForgotPass ? "password" : "PIN"}`,
  ];

  const specializedMessage = isForgot
    ? forgotMessage
    : isSuperAdmin
    ? superAdminMessage
    : isAdmin
    ? adminMessage
    : isBookKeeper
    ? bookkeeperMessage
    : employeeMessage;

  return (
    <CenteredModal isOpen={isOpen} toggle={toggle} style={style}>
      <GothamMedium
        className="font24 mb-3"
        style={{ color: colors.blue10, textAlign: "center" }}
      >
        {title || "You're all set up!"}
      </GothamMedium>
      <div
        className="d-flex align-items-center justify-content-center"
        style={{ marginBottom: 24 }}
      >
        <img src={img || finishOnboarding} />
      </div>
      <div style={{ ...imgStyle }} />
      {!isForgot && (
        <NumberedList
          array={specializedMessage.slice(0, 1)}
          isRegular
          woNumber
          textStyle={{ color: colors.grey33 }}
        />
      )}
      <NumberedList
        array={isForgot ? specializedMessage : specializedMessage.slice(1)}
        isRegular
        textStyle={{ color: colors.grey33 }}
      />

      <CustomButton
        style={{ width: "100%", marginTop: 24 }}
        onClick={toggle}
        rightIcon={
          isForgot ? (
            <img src={right} className="ml-2" style={{ width: 20 }} />
          ) : null
        }
      >
        {buttonText || "Close"}
      </CustomButton>
    </CenteredModal>
  );
};
