import { useRouter } from "next/router";
import { useEffect } from "react";
import CardBalanceIcon from "../../../assets/images/cardbalance-32.svg";
import DownloadHistoryIcon from "../../../assets/images/download-history.svg";
import MainBalanceIcon from "../../../assets/images/mainbalance-32.svg";
import { booleanState } from "../../../components/Status";
import {
  flagIsoCode,
  formatCurrencyFixed,
  formatCurrencyNoDecimal,
} from "../../../components/tools";
import { fetch } from "../../../tools/api";
import { useHomeButtons } from "../../homeComponents/constants";
// import { useHomeButtons } from "../../homeComponents/constants";
import { allocateFormatter } from "./allocate";
import { apiFormatter } from "./api";
import { batchFormatter } from "./batch";
import { cardFormatter } from "./card";
import { defaultAccountDetailsLogic } from "./defaultLogic";
import { incomingPaymentFormatter } from "./incomingPayment";
import { payrollFormatter } from "./payroll";
import { reallocateFormatter } from "./reallocate";
import { refundFormatter } from "./refund";
import { singleFormatter } from "./single";
import { topupFormatter } from "./topup";
import { withdrawFormatter } from "./withdraw";

export const useGlobalAccount = () => {
  const { query } = useRouter();
  const { type, id } = query;

  const isGlobal = type == "global_wallet";

  const url = "multicurrency_accounts/balance";

  const { data, refetch, loading } = fetch({
    url,
    formatter: (data) => {
      const array = data?.data || [];
      const obj = array.filter(({ curSymbol }) => curSymbol == id)[0] || {};
      const { curSymbol: currency } = obj;
      return { ...obj, currency };
    },
    defaultValue: {},
  });

  useEffect(() => {
    if (!isGlobal) return;
    refetch();
  }, [isGlobal]);

  return { data, loading, isGlobal };
};

const buttonsTranslator = (array) => {
  const filtered = array.filter(
    (item) => !String(item.value).includes("history")
  );
  const cleanArray = filtered.map((item) => {
    const { hidden, src: icon, text: name } = item;
    if (hidden) return {};
    return { ...item, icon, name };
  });
  const result = [
    ...cleanArray,
    {
      icon: DownloadHistoryIcon,
      name: "Get Report",
      value: "download_history",
    },
  ];
  return result;
};

export const cardHeaderDetailsFormatter = (data, isGlobal, loading) => {
  // const { data: dataAccDetails, loading: loadingAccDetails } = fetch({
  //   url: "/multicurrency_accounts/virtual_accounts",
  //   formatter: (data) => data?.data,
  // });

  const { balance: balanceRaw, currency, isoCode } = data || {};
  const isIDR = currency == "IDR";

  const formatCurr = isIDR ? formatCurrencyNoDecimal : formatCurrencyFixed;

  const balance = `${currency} ${formatCurr(balanceRaw)}`;
  const iso = flagIsoCode(isoCode) || "id";
  const icon = isGlobal ? CardBalanceIcon : MainBalanceIcon;

  // ===========
  // const dataDetails = dataAccDetails.filter(
  //   ({ currencyCode }) => currencyCode == currency
  // )[0];
  // ===========

  const title = isGlobal ? `Card Balance` : "Main Balance";

  const { buttonsMain, buttonsCard } = useHomeButtons();
  const array = buttonsTranslator(isGlobal ? buttonsCard : buttonsMain);
  return {
    array,
    balance,
    iso,
    title,
    // dataDetails,
    icon,
  };
};

export const cardTransactionsFormatter = (res, prev) => {
  const { data = [], type } = res || {};
  const prevArray = prev?.array || [];
  const prevIds = prevArray.map(({ id }) => id);
  const isDuplicated =
    data.filter(({ id }) => !prevIds.includes(id)).length == 0;

  if (isDuplicated) return prev;

  const array = data.map((item) => {
    const {
      isTopUp,
      isSingleTransaction,
      isBatchTransaction,
      isApiTransaction,
      isRefund,
      isIncomingPayment,
      isWithdraw,
      isCardTransaction,
      isAllocate,
      isReAllocate,
      isPayroll,
    } = booleanState(type || item?.category);

    const defaultItem = { ...item, ...defaultAccountDetailsLogic(item) };

    if (isTopUp) return topupFormatter(defaultItem);
    if (isSingleTransaction) return singleFormatter(defaultItem);
    if (isBatchTransaction) return batchFormatter(defaultItem);
    if (isApiTransaction) return apiFormatter(defaultItem);
    if (isRefund) return refundFormatter(defaultItem);
    if (isIncomingPayment) return incomingPaymentFormatter(defaultItem);
    if (isWithdraw) return withdrawFormatter(defaultItem);
    if (isCardTransaction) return cardFormatter(defaultItem);
    if (isAllocate) return allocateFormatter(defaultItem);
    if (isReAllocate) return reallocateFormatter(defaultItem);
    if (isPayroll) return payrollFormatter(defaultItem);
    return defaultItem;
  });

  const result = { ...res, array: [...prevArray, ...array] };
  return result;
};
