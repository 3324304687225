import React from "react";
import { colors } from "../../../assets/colors";
import {
  GothamBold,
  GothamMedium,
  GothamRegular,
  TextInlineMedium,
  TypeFezNeueMedium,
  TypeFezNeueRegular,
} from "../../../components/Text";
import invitedImg from "../../../assets/images/add-user-success.svg";
import OnboardImg from "../../../assets/images/welcome-transfez-payroll.png";
import OnboardImg1 from "../../../assets/images/onboard-payroll-1.svg";
import OnboardImg2 from "../../../assets/images/onboard-payroll-2.svg";
import xIcon from "../../../assets/images/x-icon-grey.svg";
import negoImg from "../../../assets/images/finish-payroll-setup.svg";
import MailBlueImg from "../../../assets/images/mail-blue-rounded.svg";
import {
  ConfirmationModal,
  CustomButton,
  DropdownBorderless,
} from "../../../components/Buttons";
import {
  HowPayrollWorksModal,
  useModalHook,
  useModalWidth,
} from "../../../components/Modals";
import { titleCase } from "change-case";
import { NameAvatar } from "../../../components/Avatar";
import { Modal, Row } from "reactstrap";
import { removePartnerString } from "../../../components/tools";
import { OnBoardingInvoicePaymentModal } from "../../invoiceComponents/onBoarding/modal";

export const HeaderInvite = ({
  title = "See ",
  underlineTitle = "how payroll works",
  CustomModal,
}) => {
  const { isOpen, toggle } = useModalHook();
  return (
    <>
      <GothamRegular style={{ fontSize: 12, marginTop: 8, marginBottom: 16 }}>
        {title}
        <TextInlineMedium
          style={{
            textDecoration: "underline",
            color: colors.pink,
            cursor: "pointer",
          }}
          onClick={toggle}
        >
          {underlineTitle}
        </TextInlineMedium>
      </GothamRegular>
      {CustomModal ? (
        <CustomModal isOpen={isOpen} toggle={toggle} />
      ) : (
        <HowPayrollWorksModal isOpen={isOpen} toggle={toggle} />
      )}
    </>
  );
};

export const InvitationSection = ({
  handleAssign,
  dataInvite = [],
  isDisabled,
  messageTooltip,
  onClick = () => {},
  companyName,
  loading,
  noToolTip = false,
  dropdownItems = [],
  dropdownLoading = false,
  type = "payroll",
  isEditWorkflow = false,
}) => {
  const copyGenerator = () => {
    if (type === "general" || isEditWorkflow)
      return `By pressing ${
        isEditWorkflow ? "save" : "invite"
      } means that you acknowledge that selected user(s) are employees of ${companyName}.`;
    return `By pressing ${
      isEditWorkflow ? "save" : "invite"
    } means that you acknowledge that selected user(s) are employees of ${companyName} who are allowed to manage Payroll data and transaction.`;
  };

  return (
    <div>
      {dataInvite?.length > 0 && (
        <>
          {!isEditWorkflow && (
            <GothamMedium
              className="font14 mt-3"
              style={{ color: colors.grey33 }}
            >
              Add Users
            </GothamMedium>
          )}
          {dataInvite?.map(
            (
              {
                label,
                value,
                uniqueKey,
                email,
                role,
                roleLabel: roleLabelRaw,
                help,
              },
              index
            ) => {
              const roleLabel = titleCase(roleLabelRaw || role) || "-";
              const isFirst = index === 0;
              const isOnlyOne = dataInvite.length === 1;
              const hideRoleSelector = isOnlyOne && type === "payroll";
              return (
                <Row
                  key={index}
                  className="mx-0 my-3 align-items-center justify-content-between w-100"
                >
                  <div
                    className="d-flex align-items-center"
                    style={{ width: "70%" }}
                  >
                    {!email || !value ? (
                      <img src={MailBlueImg} />
                    ) : (
                      <NameAvatar is36>{label}</NameAvatar>
                    )}
                    <div className="ml-2" style={{ width: "80%" }}>
                      <GothamBold
                        className="font14"
                        style={{
                          color: colors.grey33,
                          marginBottom: 0,
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                        }}
                      >
                        {label}
                      </GothamBold>
                      <GothamRegular
                        className="font12"
                        style={{
                          color: colors.grey33,
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                        }}
                      >
                        {help || email}
                      </GothamRegular>
                    </div>
                  </div>
                  {!hideRoleSelector && (
                    <DropdownBorderless
                      onClick={(type) =>
                        handleAssign &&
                        handleAssign({
                          type,
                          email,
                          value,
                          uniqueKey,
                        })
                      }
                      isFirst={isFirst}
                      tooltipText={messageTooltip}
                      array={dropdownItems}
                      loading={dropdownLoading}
                      noToolTip={noToolTip}
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      {isEditWorkflow
                        ? removePartnerString(role, true)
                        : roleLabel}
                    </DropdownBorderless>
                  )}
                </Row>
              );
            }
          )}
          <CustomButton
            disabled={isDisabled}
            style={{ width: "100%" }}
            onClick={onClick}
            isLoading={loading}
          >
            {isEditWorkflow ? "Save" : "Invite"}
          </CustomButton>
          <GothamRegular
            className="font12 mt-3"
            style={{ color: colors.grey6c }}
          >
            {copyGenerator()}
          </GothamRegular>
        </>
      )}
    </div>
  );
};

export const PointNumbers = ({ data, isMargin8 = false }) => {
  return (
    <>
      {data.map(({ value }, index) => {
        return (
          <div
            className="d-flex align-items-center"
            style={{ marginBottom: isMargin8 ? 8 : 16 }}
            key={index}
          >
            <GothamBold
              className="d-flex justify-content-center align-items-center"
              style={{
                color: "#fff",
                marginBottom: 0,
                backgroundColor: colors.blue10,
                borderRadius: "50%",
                minWidth: 22,
                height: 22,
              }}
            >
              {index + 1}
            </GothamBold>
            <GothamRegular style={{ marginLeft: 12 }}>{value}</GothamRegular>
          </div>
        );
      })}
    </>
  );
};

export const WelcomeScreen = ({ onClick, isWelcome2, loading }) => {
  const { isOpen, toggle } = useModalHook();

  const onBoardingData = {
    headline: "How Payroll Works",
    subHeadline:
      "See how payroll works by understanding these steps and roles.",
    array: [
      ["Drafter", "create and request payroll transaction."],
      ["Approver", "will review and approve the request."],
      [
        "Releaser",
        "will pay the approved payroll and everything is set to go!",
      ],
    ],
  };

  const title = isWelcome2
    ? "First, Set Drafter and Approver"
    : "Manage Payroll with Transfez Business";
  const subTitle = isWelcome2
    ? "Let's begin with your default settings or adjust to your preferences by visiting to the workflow page."
    : "Say no more to manual data recaps! Start automating your payroll with us and pay thousands of employees at once with a few clicks.";
  const buttonText = isWelcome2
    ? "Go to workflow"
    : "Start Using Transfez Payroll";
  const imgSource = isWelcome2 ? OnboardImg2 : OnboardImg1;

  return (
    <div
      style={{
        textAlign: "center",
        margin: "auto",
        marginTop: 66,
        maxWidth: 484,
      }}
    >
      <TypeFezNeueMedium
        style={{
          color: colors.blue10,
          fontSize: 24,
          textAlign: "center",
        }}
      >
        {title}
        {isWelcome2 && (
          <TypeFezNeueRegular style={{ color: colors.grey33, marginTop: 16 }}>
            {subTitle}
          </TypeFezNeueRegular>
        )}
      </TypeFezNeueMedium>
      <div className="d-flex justify-content-center my-4">
        <img src={imgSource} style={{ marginTop: 32, marginBottom: 32 }} />
      </div>
      {/* <PointNumbers data={prosData} /> */}
      {!isWelcome2 && (
        <TypeFezNeueRegular style={{ color: colors.grey33, marginBottom: 48 }}>
          {subTitle}
        </TypeFezNeueRegular>
      )}
      <CustomButton
        style={{ width: "100%" }}
        onClick={() => (isWelcome2 ? onClick("to_workflow") : toggle())}
        isLoading={loading}
      >
        {buttonText}
      </CustomButton>
      <OnBoardingInvoicePaymentModal
        isMinimMargin
        onBoardingData={onBoardingData}
        isOpen={isOpen}
        toggle={toggle}
        onSubmit={() => onClick("to_welcome_2")}
      />
    </div>
  );
};

export const ThankyouScreen = ({
  loading,
  onClick = () => {},
  style = {},
  isModal = false,
}) => {
  return (
    <div
      className="d-flex justify-content-center"
      style={{
        ...style,
        marginTop: 59,
        flex: "0 0 480px",
      }}
    >
      <div>
        <GothamMedium
          style={{
            color: colors.blue10,
            fontSize: 24,
            textAlign: "center",
          }}
        >
          Invitation Sent!
        </GothamMedium>
        <div
          className="d-flex justify-content-center my-4"
          style={{ marginTop: 24, marginBottom: 24 }}
        >
          <img src={invitedImg} />
        </div>
        <GothamRegular className="font14" style={{ textAlign: "center" }}>
          We have send an email to the invited users.
        </GothamRegular>
        <GothamRegular
          className="font14"
          style={{ textAlign: "center", marginBottom: 24 }}
        >
          Please notify the user to complete the process
        </GothamRegular>
        <CustomButton
          style={{ width: "100%" }}
          onClick={onClick}
          isLoading={loading}
        >
          {isModal ? "Done" : "Finish"}
        </CustomButton>
      </div>
    </div>
  );
};
