import React, { useState } from "react";
import Skeleton from "react-loading-skeleton";
import { colors } from "../../../assets/colors";
import { GothamMedium, GothamRegular } from "../../../components/Text";

export const NumberedList = ({
  array,
  loading,
  textStyle,
  isRegular = false,
  woNumber = false,
}) => {
  const [width, setWidth] = useState(0);

  const GothamString = isRegular ? GothamRegular : GothamMedium;

  if (loading)
    return ["", "", "", "", ""].map((_, index) => (
      <div
        className="d-flex w-100 align-items-center justify-content-between"
        key={index}
        ref={(e) => setWidth(e?.clientWidth)}
        style={{ marginBottom: 16 }}
      >
        <Skeleton height={22} width={22} style={{ borderRadius: 22 }} />
        <Skeleton height={22} width={width - 16 - 22} />
      </div>
    ));

  return array.map((item, index) => {
    const string = item?.string || item;
    const array = item?.array || [];
    const number = index + 1;
    if (!item) return null;
    return (
      <div className="d-flex" key={index} style={{ marginBottom: 16 }}>
        {!woNumber && (
          <div
            className="d-flex align-items-center justify-content-center"
            style={{
              width: 22,
              height: 22,
              borderRadius: 11,
              backgroundColor: colors.blue2C,
              marginRight: 16,
            }}
          >
            <GothamRegular
              style={{
                textAlign: "center",
                color: "white",
                width: 22,
              }}
              woClassName
              className="font12"
            >
              {number}
            </GothamRegular>
          </div>
        )}
        <div>
          <GothamString style={{ color: colors.blue2C, ...textStyle }}>
            {string}
          </GothamString>
          {array.map((string, index) => (
            <GothamRegular
              style={{ color: colors.grey33, marginBottom: 2 }}
              key={index}
            >
              • {string}
            </GothamRegular>
          ))}
        </div>
      </div>
    );
  });
};
