import React, { useState } from "react";
import { BackHeader, CloseHeader } from "../../../../components/CardModal";
import { GothamMedium, GothamRegular } from "../../../../components/Text";
import cardTransactionIcon from "../../../../assets/images/card-icon-32.svg";
import {
  customDateFormatter,
  formatCurrencyNoDecimal,
} from "../../../../components/tools";
import { StatusActivities } from "../../../../components/Status";
import changePinIllustration from "../../../../assets/images/change-pin-instruction.svg";
import CardDisplay from "./CardDisplay";
import adjustLimitIllustration from "../../../../assets/images/adjust-limit.svg";
import replaceCardIllustration from "../../../../assets/images/replace-card.svg";
import { getUserRole } from "../../../../contexts/AuthContext";
import { cardTypeDecider } from "../../../newCardComponents/tools";
import { colors } from "../../../../assets/colors";

const CardModalHeader = ({
  toggle: toggleProps,
  stateObj,
  handleNavigate,
  dataDetail,
  selectedTransactionData,
  canRevealData,
  revealHandler,
  unmaskedDataDetail,
  refHeader,
}) => {
  const { card_type } = dataDetail || {};
  const { isPhysical } = cardTypeDecider(card_type);

  const {
    isBlocking,
    isFreezing,
    isChangingPIN,
    isEditingLimit,
    isReplacingCard,
    isAdjustingLimit,
    isEditingNickname,
  } = stateObj;

  const { amount: totalAmount, state } = selectedTransactionData || {};
  const isTransactionCancelled = state === "cancelled";
  const isTransactionReturned = state === "returned";

  const { isEmployee } = getUserRole();
  const isOtherModalScreen = isChangingPIN || isEditingLimit || isReplacingCard;
  const isVirtualEditScreen = isAdjustingLimit || isEditingNickname;
  const isBlockingCondition = isBlocking || isFreezing;

  const permanentBlockText = "Permanently block this card?";
  const temporaryBlockText = "Temporarily block this card?";

  const NavigationButton = () => {
    if (isBlockingCondition || isOtherModalScreen || selectedTransactionData)
      return <BackHeader toggle={() => handleNavigate("back")} />;
    return <CloseHeader toggle={toggleProps} />;
  };

  const { merchant_name, date } = selectedTransactionData || {};
  const isManualByJack = merchant_name === "Manual by Jack";

  const cardTransactionHeader = isManualByJack
    ? "Top Up Manual by Jack"
    : merchant_name;

  const isCardIndex =
    !isOtherModalScreen && !isBlockingCondition && !selectedTransactionData;

  return (
    <div ref={refHeader}>
      <div
        style={{
          display: "flex",
          justifyContent:
            isBlockingCondition || isOtherModalScreen || selectedTransactionData
              ? "flex-start"
              : "flex-end",
          marginBottom: "16px",
        }}
      >
        <NavigationButton />
      </div>

      {/* This will show if the user selected a transaction on a card */}
      {selectedTransactionData && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "12px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <GothamRegular style={{ fontSize: "12px", color: "white" }}>
              {/* {selectedTransactionData.reference_number} */}
            </GothamRegular>
            <GothamRegular style={{ fontSize: "12px", color: "white" }}>
              {customDateFormatter(date)}
            </GothamRegular>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img src={cardTransactionIcon} />
            <GothamRegular
              style={{
                color: "white",
                marginTop: "12px",
                marginBottom: "4px",
                textAlign: "center",
              }}
            >
              {cardTransactionHeader}
            </GothamRegular>
            <GothamRegular
              style={{ fontSize: "24px", color: "white", lineHeight: "32px" }}
            >
              IDR {formatCurrencyNoDecimal(totalAmount)}
            </GothamRegular>
            <div
              style={{
                backgroundColor: "white",
                padding: "4px 8px 4px 8px",
                borderRadius: "24px",
                marginTop: "4px",
              }}
            >
              <StatusActivities
                state={state}
                color={
                  (isTransactionCancelled || isTransactionReturned) &&
                  colors.yellowFC
                }
                style={{
                  fontSize: "12px",
                  lineHeight: "16px",
                  marginRight: "6px",
                }}
              />
            </div>
          </div>
        </div>
      )}

      {/* This will show if the user want to see how to change PIN */}
      {isChangingPIN && !selectedTransactionData && (
        <GothamMedium
          style={{ color: "#FCFCFC", fontSize: "24px", marginBottom: "16px" }}
        >
          How to Change PIN
        </GothamMedium>
      )}

      {/* This will show if the user want to see how to adjust card limit */}
      {isEditingLimit && !selectedTransactionData && (
        <div
          style={{
            position: "relative",
            zIndex: 2,
          }}
        >
          <GothamMedium
            style={{ color: "#FCFCFC", fontSize: "24px", marginBottom: "16px" }}
          >
            Edit Limit
          </GothamMedium>
          <img src={adjustLimitIllustration} />
        </div>
      )}

      {/* This will show if the user want to see how to replace a card */}
      {isReplacingCard && !selectedTransactionData && (
        <div
          style={{
            position: "relative",
            zIndex: 2,
          }}
        >
          <GothamMedium
            style={{ color: "#FCFCFC", fontSize: "24px", marginBottom: "16px" }}
          >
            Replace Card
          </GothamMedium>
          <img src={replaceCardIllustration} />
        </div>
      )}

      {/* This will show if the user want to temporarily or permanently block a card */}
      {isBlockingCondition && !selectedTransactionData && (
        <div>
          <GothamMedium
            style={{
              fontSize: "24px",
              color: "#FCFCFC",
              marginBottom: "16px",
              lineHeight: "32px",
            }}
          >
            {isFreezing ? temporaryBlockText : permanentBlockText}
          </GothamMedium>
          {isPhysical && (
            <GothamRegular style={{ color: "white" }}>
              This process will take 3 to 5 hours to complete
            </GothamRegular>
          )}
          <CardDisplay
            dataDetail={dataDetail}
            top={isPhysical ? 145 : 115}
            left={20}
            canRevealData={canRevealData}
            revealHandler={revealHandler}
            unmaskedDataDetail={unmaskedDataDetail}
          />
        </div>
      )}

      {isChangingPIN && (
        <img src={changePinIllustration} style={{ marginTop: 8 }} />
      )}

      {isCardIndex && (
        <CardDisplay
          dataDetail={dataDetail}
          top={55}
          left={20}
          canRevealData={canRevealData}
          revealHandler={revealHandler}
          unmaskedDataDetail={unmaskedDataDetail}
        />
      )}
    </div>
  );
};

export default CardModalHeader;
