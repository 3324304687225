import { titleCase } from "change-case";
import { useRouter } from "next/router";
import React, { forwardRef, useState } from "react";
import { colors } from "../../assets/colors";
import InactiveDots from "../../assets/images/DotsThree-unclicked.svg";
import { QueryDatePicker, QuerySelection } from "../inputs";
import { GothamMedium } from "../Text";

export const DropdownFilterFields = forwardRef(
  ({ toggle, data, isOpen }, ref) => (
    <DotsButton ref={ref} toggle={toggle} data={data}>
      <MoreFilterFields data={data} isOpen={isOpen} />
    </DotsButton>
  )
);

const DotsButton = forwardRef(({ toggle, children, data }, ref) => {
  const [isHover, setIsHover] = useState(false);
  const { query } = useRouter();

  const isFilterActive = data.filter(({ name }) => query[name]).length;

  const isActive = isHover || isFilterActive;
  return (
    <div
      ref={ref}
      className="d-flex align-items-center justify-content-center ml-1 pinkhover"
      style={{
        border: `1px solid ${isActive ? colors.teal : colors.greyC2}`,
        backgroundColor: isActive ? "#c1eff4" : "transparent",
        height: 44,
        width: 44,
        borderRadius: 8,
        position: "relative",
      }}
      onClick={toggle}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <img
        src={InactiveDots}
        className="hover"
        style={{
          transform: "rotate(90deg)",
        }}
      />
      {children}
    </div>
  );
});

const MoreFilterFields = ({ data, isOpen }) => {
  const { query } = useRouter();

  if (!isOpen) return null;

  return (
    <div style={{ position: "absolute", right: 0, top: 44 + 7 }}>
      <div
        style={{
          backgroundColor: "white",
          borderRadius: 8,
          padding: 16,
          width: 232,
          boxShadow: "0px 4px 8px rgba(88, 88, 88, 0.06)",
          border: "1px solid rgba(0,0,0,.05)",
          position: "relative",
        }}
      >
        <GothamMedium style={{ color: colors.grey72, marginBottom: 12 }}>
          More Filter
        </GothamMedium>
        {data.map((item, index) => {
          const { label, name, options, date, loading } = item;
          const isSelection = Boolean(options);
          const isLast = index == data.length - 1;
          const getLabel = label || titleCase(name);

          const defaultSelectionProps = {
            isLoading: loading,
            key: index,
            label: getLabel,
          };

          const hasValue = query[name];

          if (isSelection)
            return (
              <div
                key={index}
                style={{
                  width: "100%",
                  marginBottom: hasValue ? 20 : 12,
                }}
              >
                <QuerySelection {...item} {...defaultSelectionProps} />
              </div>
            );

          if (date) {
            return (
              <div
                key={index}
                style={{
                  width: "100%",
                  marginBottom: isLast ? 0 : 12,
                }}
              >
                <QueryDatePicker
                  {...item}
                  {...defaultSelectionProps}
                  columnProps={{
                    style: {
                      width: "100%",
                    },
                  }}
                  containerStyle={{ marginBottom: hasValue ? 20 : 0 }}
                />
              </div>
            );
          }
          return null;
        })}
      </div>
    </div>
  );
};
