import { useRouter } from "next/router";
import { Fragment, useEffect, useState } from "react";
import { MdExpandMore } from "react-icons/md";
import { Col } from "reactstrap";
import { colors } from "../assets/colors";
import accountLightIcon from "../assets/images/account-sidebar-light.svg";
import accountWhiteIcon from "../assets/images/account-sidebar-white.svg";
import activityLightIcon from "../assets/images/activity-sidebar-light.svg";
import activityWhiteIcon from "../assets/images/activity-sidebar-white.svg";
import cardLightIcon from "../assets/images/card-sidebar-light.svg";
import cardWhiteIcon from "../assets/images/card-sidebar-white.svg";
import collectLightIcon from "../assets/images/collect-sidebar-light.svg";
import collectWhiteIcon from "../assets/images/collect-sidebar-white.svg";
import homeLightIcon from "../assets/images/home-sidebar-light.svg";
import homeWhiteIcon from "../assets/images/home-sidebar-white.svg";
import paymentIcon from "../assets/images/payment-sidebar.svg";
import payrollLightIcon from "../assets/images/payroll-sidebar-light.svg";
import payrollWhiteIcon from "../assets/images/payroll-sidebar-white.svg";
import eWalletLightIcon from "../assets/images/e-wallet-sidebar-light.svg";
import invoicePaymentIcon from "../assets/images/invoice-payment-sidebar-light.svg";
import sendLightIcon from "../assets/images/send-sidebar-light.svg";
import sendWhiteIcon from "../assets/images/send-sidebar-light.svg";
import shieldCheck from "../assets/images/shield-check.svg";
import shieldCheckLight from "../assets/images/shield-light.svg";
import userLightIcon from "../assets/images/user-sidebar-light.svg";
import userWhiteIcon from "../assets/images/user-sidebar-white.svg";
import accountSettingLightIcon from "../assets/images/account-settings-light.svg";
import accountSettingWhiteIcon from "../assets/images/account-settings-white.svg";
import developerToolsLightIcon from "../assets/images/developer-tools-light.svg";
import developerToolsWhiteIcon from "../assets/images/developer-tools-white.svg";
import workflowLightIcon from "../assets/images/workflow-light.svg";
import workflowWhiteIcon from "../assets/images/workflow-white.svg";
import customReceiptWhiteIcon from "../assets/images/custom-receipt-white.svg";
import customReceiptLightIcon from "../assets/images/custom-receipt-light.svg";
import { settingPathnames } from "../constants/pathnames";
import {
  getUserButtonBooleans,
  getUserRole,
  getUserStatusBoolean,
} from "../contexts/AuthContext.js";
import { eventsTracker } from "../universalFunctions/events";
import { GothamBold, GothamMedium, GothamRegular } from "./Text";
import { formatCurrencyNoDecimal, windowDimension } from "./tools";
import { TransitionDiv } from "./Transition";
import { BackHeader } from "./CardModal";
import refreshIcon from "../assets/images/refresh-icon-blue.svg";
import { useGetAuth } from "../contexts/AuthContext.js";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const Sidebar = () => {
  const { figmaWidth, height, sidebarHeight } = windowDimension();

  const [isFirstRender, setIsFirstRender] = useState(true);
  useEffect(() => {
    if (isFirstRender) return setIsFirstRender(false);
  }, []);
  if (isFirstRender) return null;

  return (
    <div
      style={{
        alignItems: "center",
        height: height - 72,
      }}
      className="d-flex"
    >
      <div
        style={{
          background: colors.gradient,
          width: figmaWidth(248) + 10,
          marginLeft: figmaWidth(16),
          height: sidebarHeight,
          borderRadius: 12,
          padding: "16px 12px",
          overflowX: "hidden",
        }}
        className="scrollable-nav-y"
      >
        <Col
          className="justify-content-between align-items-center d-flex flex-column"
          style={{ height: "100%" }}
        >
          <TopSideBar />
        </Col>
      </div>
    </div>
  );
};
export default Sidebar;

export const navItemsDecider = () => {
  const { pathname } = useRouter();

  const { isNotSubmitted, isCompleted } = getUserStatusBoolean();
  const {
    canViewActivity,
    canSeeVA,
    canSendMoney,
    canSeeUsers,
    canViewAccount,
    canViewCards,
    canViewLocalTransfer,
    canViewPayroll,
    canViewEWallet,
    canViewWorkflow,
    canViewInvoicePayment,
    canViewReimbursement,
    canViewApiDisbursement,
    canViewHistory,
    canViewTasks,
    canCustomReceipt,
  } = getUserButtonBooleans();

  const IconSidebar = ({ icon }) => {
    return <img src={icon} style={{ width: 20 }} />;
  };

  const homeNav = {
    title: "Home",
    href: "/home",
    icon: <IconSidebar icon={homeLightIcon} />,
    iconActive: <IconSidebar icon={homeWhiteIcon} />,
  };
  const accountNav = canViewAccount
    ? {
        title: "Account",
        href: "/account",
        icon: <IconSidebar icon={accountLightIcon} />,
        iconActive: <IconSidebar icon={accountWhiteIcon} />,
      }
    : {};

  const activityNav = canViewActivity
    ? {
        title: "Activity",
        accordionValue: "activity",
        icon: <IconSidebar icon={activityLightIcon} />,
        iconActive: <IconSidebar icon={activityWhiteIcon} />,
        accordion: [
          canViewHistory
            ? {
                title: "History",
                href: "/history",
              }
            : {},
          canViewTasks
            ? {
                title: "Task",
                href: "/task",
              }
            : {},
          canViewApiDisbursement
            ? {
                title: "API History",
                href: "/histories-api",
              }
            : {},
        ],
      }
    : {};
  const sendNav = canSendMoney
    ? {
        title: "Send Money",
        accordionValue: "send_money",
        icon: <IconSidebar icon={sendLightIcon} />,
        iconActive: <IconSidebar icon={sendLightIcon} />,
        accordion: [
          {
            title: "Cross Border",
            href: "/cross-border",
          },
          canViewLocalTransfer
            ? { title: "Local Transfer", href: "/local-transfer" }
            : {},
          // {
          //   title: "Approval List",
          //   href: "/approval-list",
          // },
          // {
          //   title: "Recipients",
          //   href: "/recipients",
          // },
        ],
      }
    : {};

  const cardNav = canViewCards
    ? {
        title: "Cards",
        href: "/cards",
        icon: <IconSidebar icon={cardLightIcon} />,
        iconActive: <IconSidebar icon={cardWhiteIcon} />,
      }
    : {};

  const veirificationNav = {
    title: "Verification",
    href: "/verification",
    icon: <img src={shieldCheckLight} style={{ width: 20 }} />,
    iconActive: <IconSidebar icon={shieldCheck} style={{ width: 20 }} />,
  };

  const collectMoneyAccordion = canSeeVA
    ? {
        title: "Collect Money",
        icon: <IconSidebar icon={collectLightIcon} />,
        iconActive: <IconSidebar icon={collectWhiteIcon} />,
        accordionValue: "collect_money",
        accordion: [
          {
            title: "Virtual Account",
            href: "/virtual-account",
          },
          {
            title: "Collection Report",
            href: "/collection-report",
          },
        ],
      }
    : {};

  const userNav = canSeeUsers
    ? {
        title: "User Management",
        href: "/users",
        icon: <IconSidebar icon={userLightIcon} />,
        iconActive: <IconSidebar icon={userWhiteIcon} />,
      }
    : {};

  const payrollNav = canViewPayroll
    ? {
        title: "Payroll",
        href: "/payroll",
        // icon: <IconSidebar icon={payrollLightIcon} />,
        // iconActive: <IconSidebar icon={payrollWhiteIcon} />,
      }
    : {};

  const eWalletNav = canViewEWallet
    ? {
        title: "Team Wallet",
        href: "/e-wallet",
        icon: <IconSidebar icon={eWalletLightIcon} />,
        iconActive: <IconSidebar icon={eWalletLightIcon} />,
      }
    : {};

  const invoicePaymentNav = canViewInvoicePayment
    ? {
        title: "Invoice Payment",
        href: "/invoice-payment",
        // icon: <IconSidebar icon={invoicePaymentIcon} />,
        // iconActive: <IconSidebar icon={invoicePaymentIcon} />,
      }
    : {};

  const reimbursementNav = canViewReimbursement
    ? {
        title: "Reimbursement",
        href: "/reimbursement",
        // icon: <IconSidebar icon={invoicePaymentIcon} />,
        // iconActive: <IconSidebar icon={invoicePaymentIcon} />,
      }
    : {};

  const canViewPayments =
    canViewInvoicePayment ||
    canViewCards ||
    canViewEWallet ||
    canViewPayroll ||
    canViewReimbursement;

  const paymentAccordion = canViewPayments
    ? {
        title: "Payments",
        icon: <IconSidebar icon={paymentIcon} />,
        iconActive: <IconSidebar icon={paymentIcon} />,
        accordionValue: "payment",
        accordion: [
          invoicePaymentNav,
          // cardNav,
          // eWalletNav,
          payrollNav,
          reimbursementNav,
        ],
      }
    : {};

  const accountSettingNav = {
    title: "Account Settings",
    href: "/settings",
    icon: <IconSidebar icon={accountSettingLightIcon} />,
    iconActive: <IconSidebar icon={accountSettingWhiteIcon} />,
  };

  const developerToolsNav = {
    title: "Developer Tools",
    href: "/menu-api",
    icon: <IconSidebar icon={developerToolsLightIcon} />,
    iconActive: <IconSidebar icon={developerToolsWhiteIcon} />,
  };

  const customizeReceiptNav = canCustomReceipt
    ? {
        title: "Customize Receipt",
        href: "/customize-receipt",
        icon: <IconSidebar icon={customReceiptWhiteIcon} />,
        iconActive: <IconSidebar icon={customReceiptLightIcon} />,
      }
    : {};

  const workflowNav = canViewWorkflow
    ? {
        title: "Workflow",
        href: "/workflow",
        icon: <IconSidebar icon={workflowLightIcon} />,
        iconActive: <IconSidebar icon={workflowWhiteIcon} />,
      }
    : {};

  const isSetting = settingPathnames.includes(pathname);

  const navsGenerator = (nav = {}) =>
    isSetting
      ? [
          accountSettingNav,
          userNav,
          workflowNav,
          customizeReceiptNav,
          developerToolsNav,
        ]
      : [
          homeNav,
          // accountNav,
          nav,
          collectMoneyAccordion,
          activityNav,
          cardNav,
          eWalletNav,
          sendNav,
          paymentAccordion,
        ];

  if (isCompleted) return navsGenerator();
  if (isNotSubmitted) return [homeNav];
  return navsGenerator();
};

const TopSideBar = () => {
  const defaultAccordion = ["send_money", "activity", "payment"];

  const [activeAccordionArray, setActiveAccordionArray] =
    useState(defaultAccordion);

  const { pathname, push } = useRouter();

  useEffect(() => {
    setActiveAccordionArray(defaultAccordion);
  }, [pathname]);

  const { figmaWidth } = windowDimension();
  const navItems = navItemsDecider();
  const { user, refetchUser, userLoading } = useGetAuth();

  const handleClick = ({ accordionValue: accordionValueItem, href }) => {
    if (accordionValueItem) {
      if (href) {
        eventsTracker("sidebar", { href });
        return push(href);
      }
      const sameAccordion = activeAccordionArray.includes(accordionValueItem);
      if (sameAccordion)
        return setActiveAccordionArray((prev) => {
          const filtered = prev.filter((key) => key !== accordionValueItem);
          return filtered;
        });
      return setActiveAccordionArray((prev) => [...prev, accordionValueItem]);
    }

    eventsTracker("sidebar", { href });
    push(href);
  };

  if (navItems.length == 0) return null;

  const isSetting = settingPathnames.includes(pathname);

  const { balance } = user?.mainBalance || {};
  const formattedMainBalance = `IDR ${formatCurrencyNoDecimal(balance)}`;

  const { canViewMainBalance } = getUserButtonBooleans();
  const SidebarHeader = () => {
    if (isSetting)
      return (
        <>
          <div style={{ marginLeft: "12px", marginBottom: "8px" }}>
            <BackHeader toggle={() => push("/home")} isMedium />
          </div>
          <GothamMedium
            style={{ color: "white", marginLeft: "12px", marginBottom: "8px" }}
          >
            Settings
          </GothamMedium>
        </>
      );

    if (canViewMainBalance && balance)
      return (
        <div
          style={{
            marginBottom: 8,
            marginLeft: 12,
            marginRight: 12,
          }}
        >
          <div
            style={{
              display: "flex",
              gap: 8,
            }}
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10 0C15.5223 0 20 4.47772 20 10C20 15.5223 15.5223 20 10 20C4.47772 20 0 15.5223 0 10C0 4.47772 4.47772 0 10 0Z"
                fill="white"
              />
              <g clipPath="url(#clip0_627_6708)">
                <path
                  d="M12.5641 8.39732H15.5556V7.00843C15.5556 6.12477 14.8367 5.40587 13.953 5.40587H6.04702C5.16336 5.40587 4.44446 6.12477 4.44446 7.00843V12.9913C4.44446 13.875 5.16336 14.5939 6.04702 14.5939H13.953C14.8367 14.5939 15.5556 13.875 15.5556 12.9913V11.6024H12.5641C11.6805 11.6024 10.9616 10.8835 10.9616 9.99988C10.9616 9.11622 11.6805 8.39732 12.5641 8.39732Z"
                  fill="#102C57"
                />
                <path
                  d="M12.5641 9.03857C12.0339 9.03857 11.6026 9.46991 11.6026 10.0001C11.6026 10.5303 12.0339 10.9616 12.5641 10.9616H15.5556V9.0386H12.5641V9.03857ZM12.9915 10.3206H12.5641C12.3871 10.3206 12.2436 10.1771 12.2436 10.0001C12.2436 9.82312 12.3871 9.67961 12.5641 9.67961H12.9915C13.1685 9.67961 13.312 9.82312 13.312 10.0001C13.312 10.1771 13.1685 10.3206 12.9915 10.3206Z"
                  fill="#102C57"
                />
              </g>
              <defs>
                <clipPath id="clip0_627_6708">
                  <rect
                    width="11.1111"
                    height="11.1111"
                    fill="white"
                    transform="translate(4.44446 4.44444)"
                  />
                </clipPath>
              </defs>
            </svg>
            <GothamRegular
              style={{
                color: colors.whiteFC,
              }}
            >
              Main Balance
            </GothamRegular>
            <img
              src={refreshIcon}
              style={{
                cursor: "pointer",
              }}
              onClick={() => refetchUser()}
            />
          </div>
          <div
            style={{
              marginBottom: 24,
              marginTop: 8,
            }}
          >
            {userLoading ? (
              <SkeletonTheme
                highlightColor="rgba(72,104,150,1)"
                color="rgba(16,44,87,1)"
              >
                <Skeleton height={24} width={"100%"} />
              </SkeletonTheme>
            ) : (
              <GothamMedium
                style={{
                  color: colors.whiteFC,
                }}
                className="font20"
              >
                {formattedMainBalance}
              </GothamMedium>
            )}
          </div>
        </div>
      );

    return null;
  };

  return (
    <div>
      <SidebarHeader />
      {navItems.map((item, index) => {
        const {
          title,
          icon,
          iconActive,
          href,
          accordionValue: accordionValueItem,
          accordion,
        } = item || {};
        if (!title) return null;

        const isActive = pathname.includes(href);
        const isActiveAccordion =
          activeAccordionArray.includes(accordionValueItem);

        const { rowContainer, titleStyle } = {
          rowContainer: {
            height: 34,
            width: figmaWidth(224),
            backgroundColor: isActive ? "rgba(229, 249, 252, 0.2)" : "",
            color: isActive ? "white" : "#E5F9FC",
            borderRadius: 5,
            padding: 6,
            marginLeft: 12,
            marginRight: 12,
          },
          titleStyle: {
            fontSize: 14,
            fontWeight: 400,
            lineHeight: "22px",
            marginLeft: 12,
            marginBottom: 0,
            fontFamily: isActive ? "GothamMedium" : "GothamLight",
            color: isActive ? "white" : "#E5F9FC",
          },
        };

        const DefaultItem = ({
          icon = null,
          iconActive = null,
          title,
          accordionValueItem,
          onClick,
          rowClassName = "",
          rowStyle,
          subTitleStyle,
          href,
        }) => {
          const betaFeatures = [
            // "/cards",
            // "/e-wallet",
            // "/payroll",
            // "/invoice-payment",
            // "/reimbursement",
          ];

          const isBeta = betaFeatures.includes(href);

          const AccordionDecider = () => {
            if (!Boolean(accordionValueItem)) return null;
            return (
              <MdExpandMore
                color="white"
                size={20}
                style={{
                  transform: isActiveAccordion
                    ? "rotate(180deg)"
                    : "rotate(0deg)",
                  transition: "all 0.4s linear",
                }}
              />
            );
          };

          return (
            <div
              className={`d-flex justify-content-between align-items-center mb-2 sidebar ${rowClassName}`}
              style={{ ...rowContainer, ...rowStyle }}
              onClick={onClick}
            >
              <div className="d-flex mx-0 align-items-center">
                {isActive ? iconActive : icon}
                <GothamMedium style={{ ...titleStyle, ...subTitleStyle }}>
                  {title}
                </GothamMedium>
                {isBeta && (
                  <GothamRegular
                    style={{
                      color: "#E892C1",
                      fontSize: "10px",
                      marginLeft: "4px",
                    }}
                  >
                    Beta
                  </GothamRegular>
                )}
              </div>
              <AccordionDecider />
            </div>
          );
        };

        return (
          <TransitionDiv counter={index} perPage={navItems.length} key={index}>
            <DefaultItem
              {...item}
              icon={icon}
              iconActive={iconActive}
              title={title}
              accordionValueItem={accordionValueItem}
              onClick={() => handleClick(item)}
            />
            {isActiveAccordion && (
              <div>
                {accordion.map((item, index) => {
                  const { href, title } = item;
                  const isActive = pathname.includes(href);
                  const isLast = index == accordion.length - 1;
                  if (!title) return null;
                  return (
                    <TransitionDiv
                      counter={index}
                      perPage={accordion.length}
                      className="d-flex justify-content-between"
                      key={index}
                    >
                      <DefaultItem
                        {...item}
                        onClick={() =>
                          handleClick({
                            ...item,
                            accordionValue: accordionValueItem,
                          })
                        }
                        rowClassName={isLast ? "mb-4" : ""}
                        rowStyle={{
                          backgroundColor: isActive
                            ? "rgba(229, 249, 252, 0.2)"
                            : "",
                        }}
                        subTitleStyle={{
                          fontFamily: isActive ? "GothamMedium" : "GothamLight",
                          color: isActive ? "white" : "#E5F9FC",
                          marginLeft: 32,
                        }}
                      />
                    </TransitionDiv>
                  );
                })}
              </div>
            )}
          </TransitionDiv>
        );
      })}
    </div>
  );
};
