import React, { useState } from "react";
import allocation from "../../../assets/images/account-icon-allocation-request.svg";
import { MiddleWrapper } from "../../../components/CardModal";
import { LeftRightList } from "../../../components/Lists";
import { booleanState, StatusLabel } from "../../../components/Status";
import { GothamMedium } from "../../../components/Text";
import {
  customDateFormatter,
  formatCurrencyNoDecimal,
} from "../../../components/tools";
import { fetch } from "../../../tools/api";
import { ActivityDetailsHeader } from "../activityModalComponents";
import { CenterendIconLocalTransfer } from "../localDisbursementBatchModal/transactionModal";

export const useSmartEWalletActivityDetails = ({
  data: dataProps,
  toggle,
  noStatus,
}) => {
  const { originator_id, category, type } = dataProps || {};
  const { isAllocationRequest, isReturnRequest, isMainWalletAllocation } =
    booleanState(category || type);
  const isAllocationOrReturnRequest = isReturnRequest || isAllocationRequest;

  const params =
    isAllocationOrReturnRequest || isMainWalletAllocation
      ? `/?q[id_eq]=${originator_id}`
      : `/?q[hub_ref_id_eq]=${originator_id}`;

  const { data, loading } = fetch({
    url: `/wallet_transactions${params}`,
    formatter: (res) => res?.data[0],
    defaultValue: {},
  });

  const { user, amount, state, created_at, reference_number } = data || {};
  const { name } = user || {};

  const [middleHeight, setMiddleHeight] = useState(0);

  const defaultProps = (title) => ({
    header: (
      <ActivityDetailsHeader
        toggle={toggle}
        data={{
          type: `${title} ${name}`,
          icon: false,
          title: `IDR ${formatCurrencyNoDecimal(amount)}`,
        }}
      />
    ),
    headerStyle: { paddingBottom: 48 },
    middleHeight,
    middle: (
      <MiddleWrapper setHeight={setMiddleHeight} height={middleHeight}>
        <CenterendIconLocalTransfer icon={allocation} />
      </MiddleWrapper>
    ),
    defaultBody: (props) => {
      const { children = null } = props || {};
      return (
        <div
          className="d-flex align-items-center"
          style={{ flexDirection: "column" }}
        >
          {!noStatus && <StatusLabel status={state} />}
          <div className="w-100" style={{ marginTop: 24 }}>
            <LeftRightList
              array={[
                { left: "Date", right: customDateFormatter(created_at) },
                {
                  left: "Reference",
                  right: reference_number,
                },
                { spacer: true },
              ]}
            />
          </div>
          <GothamMedium className="w-100 font16" style={{ marginBottom: 16 }}>
            Updates
          </GothamMedium>
          {children}
        </div>
      );
    },
  });

  return { data, loading, defaultProps };
};
