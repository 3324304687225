import { useState } from "react";
import {
  customDateFormatter,
  flagIso3,
  formatCurrencyNoDecimal,
} from "../../../components/tools";
import plus from "../../../assets/images/plus-circle.svg";
import arrowRight from "../../../assets/images/arrow-right-pink.svg";
import { useConstants } from "../../../contexts/ConstantsContext/parent";
import { GothamMedium } from "../../../components/Text";
import { colors } from "../../../assets/colors";
import { SmartActivityModal } from "../parent";
import { useModalHook } from "../../../components/Modals";

export const useLocalTransferSingle = ({ data }) => {
  const { isOpen, toggle } = useModalHook();
  const { localTransferBanks: optionsBank } = useConstants();
  const {
    fee,
    state,
    amount,
    batch_id,
    recipient,
    batch_name,
    updated_at,
    reference_id,
    local_recipient,
  } = data || {};
  // LOCAL RECIPIENT ITU SELALU UPDATE
  const {
    name: nameLocalRecipient = "",
    account_number,
    payer_id,
  } = local_recipient || {};
  // LOCAL RECIPIENT ITU SELALU UPDATE

  // RECIPIENT  ITU NEMPEL KE TRANSAKSI
  const { name: nameRecipient } = recipient || {};
  // RECIPIENT  ITU NEMPEL KE TRANSAKSI

  const name = nameRecipient ? nameRecipient : nameLocalRecipient;

  const getBank = optionsBank?.filter((item) => item.id === payer_id)[0];

  const formattedAmount = formatCurrencyNoDecimal(Number(amount));
  const stateForStatusLabel = state;
  const amountPlusFee = Number(amount) + Number(fee);

  const headerData = {
    icon: null,
    title: `IDR ${formattedAmount}`,
    type: `Transfer to ${name}`,
  };

  const leftRightArray = [
    { left: "Date", right: customDateFormatter(updated_at) },
    { left: "Reference", right: reference_id },
    {
      left: "Transaction Name",
      right: (
        <>
          <GothamMedium style={{ color: colors.pink }} onClick={toggle}>
            {batch_name}
            <img src={arrowRight} />
          </GothamMedium>
        </>
      ),
      styleRight: { cursor: "pointer" },
    },
    { spacer: true },
    {
      left: "Recipient gets",
      right: `IDR ${formatCurrencyNoDecimal(Number(amount))}`,
      rightBold: true,
    },
    {
      left: (
        <>
          <img src={plus} className="mr-2" />
          Total fee
        </>
      ),
      right: `IDR ${formatCurrencyNoDecimal(Number(fee))}`,
    },
    {
      left: "You Pay",
      right: `IDR ${formatCurrencyNoDecimal(amountPlusFee)}`,
      rightBold: true,
    },
  ];

  const recipientDataWings = {
    iso: "id",
    bankAccount: account_number,
    name: name.toUpperCase(),
    bank: getBank?.label,
  };

  const dataSmartActivityModal = {
    id: batch_id,
    category: "local_batch",
  };

  const BatchModal = () => (
    <SmartActivityModal
      data={dataSmartActivityModal}
      isOpen={isOpen}
      toggle={toggle}
    />
  );

  return {
    headerData,
    stateForStatusLabel,
    leftRightArray,
    recipientDataWings,
    BatchModal,
  };
};
