import { colors } from "../../../assets/colors";
import { CustomButton } from "../../../components/Buttons";
import { TypeFezNeueRegular } from "../../../components/Text";
import { useMutation } from "../../../tools/api";

export const ButtonRetryHub = ({ data, refetch }) => {
  const { id, payment_method } = data || {};
  const isWallet = payment_method === "indonesian_wallet";

  const { mutation, loading: loadingDeduct } = useMutation({
    url: `/single_transaction/${id}/retry_deduct_wallet`,
    afterSuccess: () => refetch(),
  });

  const { mutation: mutationUpdate, loading: loadingUpdate } = useMutation({
    url: `/update_single_transaction/${id}`,
  });

  const loading = loadingDeduct || loadingUpdate;

  const handleClick = async () => {
    if (isWallet) return mutation();
    await mutationUpdate({ payment_method: "indonesian_wallet" });
    return mutation();
  };

  return (
    <div
      className="d-flex align-items-center"
      style={{
        width: "100%",
        backgroundColor: "#FFF8EC",
        border: "1px solid #FCB037",
        borderRadius: 8,
        padding: 12,
        marginTop: 16,
      }}
    >
      <TypeFezNeueRegular
        style={{ fontSize: 12, lineHeight: "16px", color: colors.grey6c }}
      >
        Click this button if your balance hasn’t been deducted.
      </TypeFezNeueRegular>
      <CustomButton
        style={{ padding: "2px 16px", height: 24, fontSize: 12 }}
        onClick={handleClick}
        isLoading={loading}
      >
        Refresh
      </CustomButton>
    </div>
  );
};
