import React from "react";
import { colors } from "../assets/colors";
import warningCircle from "../assets/images/warning-circle.svg";
import warning from "../assets/images/warning.svg";
import blackWarning from "../assets/images/black-warning.svg";
import check from "../assets/images/check-icon.svg";
import xIcon from "../assets/images/x-icon-green.svg";
import {
  GothamMedium,
  TextInlineMedium,
  TextInlineRegular,
} from "../components/Text";

const Alert = ({
  icon = <img src={warningCircle} className="mx-3" style={{ width: 24 }} />,
  backgroundColor = "#FFEECF",
  style,
  textStyle,
  textColor = colors.grey25,
  boldText,
  boldTextNext = "",
  boldNextStyle,
  regularText,
  boldNextClassName = "",
  closeIcon = null,
  onClickBoldNext = () => {},
}) => {
  return (
    <div
      className={`d-flex align-items-center mb-4 pr-3 ${
        closeIcon ? "justify-content-between" : ""
      }`}
      style={{ backgroundColor, minHeight: 48, ...style }}
    >
      <WrapperDecider closeIcon={closeIcon}>
        {icon}
        <GothamMedium
          style={{ fontSize: 14, color: textColor, ...textStyle }}
          className="my-0"
        >
          {boldText} <TextInlineRegular>{regularText}</TextInlineRegular>
          <TextInlineMedium
            onClick={onClickBoldNext}
            style={boldNextStyle}
            className={`ml-1 ${boldNextClassName}`}
          >
            {boldTextNext}
          </TextInlineMedium>
        </GothamMedium>
      </WrapperDecider>
      {closeIcon}
    </div>
  );
};

const WrapperDecider = ({ closeIcon, children }) => {
  if (closeIcon) {
    return <div className="d-flex align-items-center">{children}</div>;
  }
  return children;
};

export default Alert;

export const WarningAlert = ({ boldText = "", regularText = "", ...props }) => (
  <Alert
    icon={<img src={warning} className="mx-3" />}
    backgroundColor={colors.redFF}
    textColor={colors.redB9}
    boldText={boldText}
    regularText={regularText}
    {...props}
  />
);

export const YellowAlert = ({ boldText = "", regularText = "", ...props }) => (
  <Alert
    icon={<img src={blackWarning} className="mx-3" />}
    backgroundColor={colors.lightYellowFF}
    textColor={colors.grey25}
    boldText={boldText}
    regularText={regularText}
    {...props}
  />
);

export const SuccessAlert = ({
  boldText = "",
  regularText = "",
  handleClose = () => {},
}) => (
  <Alert
    icon={<img src={check} className="mx-3" />}
    closeIcon={<img src={xIcon} onClick={handleClose} className="darkhover" />}
    backgroundColor={colors.greend3}
    textColor="#108461"
    boldText={boldText}
    regularText={regularText}
  />
);
