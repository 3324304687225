import React from "react";
import { colors } from "../assets/colors";
import { GothamMedium } from "../components/Text";
import { nameInitial } from "./tools";
import mail from "../assets/images/mail-blue-rounded.svg";

export const NameAvatar = ({ children, is36 = false }) => {
  const getName = nameInitial(String(children));
  return (
    <div
      style={{
        color: colors.blue10,
        width: is36 ? 36 : 40,
        height: is36 ? 36 : 40,
        backgroundColor: colors.bluee1,
        borderRadius: 20,
      }}
      className="d-flex align-items-center justify-content-center"
    >
      {getName ? <GothamMedium>{getName}</GothamMedium> : <img src={mail} />}
    </div>
  );
};
