import useCardWorkflow from "../../hooks/useCardWorfklow";
import { StatusPayrollLine } from "../../../payrollCreateComponents/loop/success/status";
import { ReasonNotes } from "../../../payrollCreateComponents/loop/success/status";

const ProgressSummary = ({ dataDetail }) => {
  const { progressArray, rejection_note } = useCardWorkflow(dataDetail);

  return (
    <>
      <StatusPayrollLine array={progressArray} isModal />
      {rejection_note && <ReasonNotes text={rejection_note} />}
    </>
  );
};

export default ProgressSummary;
