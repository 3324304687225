import { useEffect } from "react";
import { colors } from "../../../../assets/colors";
import { GothamRegular, TextInlineRegular } from "../../../../components/Text";
import {
  customDateFormatter,
  teamManagerTextDecider,
} from "../../../../components/tools";
import { getUserRole, useGetAuth } from "../../../../contexts/AuthContext";
import { useConstants } from "../../../../contexts/ConstantsContext/parent";
import { fetch } from "../../../../tools/api";

const useYouOrNameFunc = () => {
  const { user } = useGetAuth();
  const { name: userName, id } = user || {};

  const youOrNameFunc = (name) => {
    if (!name) return "";
    if (userName == name || id === name) return "You";
    return name;
  };

  return { youOrNameFunc };
};

const workflowProcessFormatter = (workflow_process) => {
  let obj = {};
  Object.keys(workflow_process).forEach((key) => {
    const array = workflow_process[key] || [];

    const includesAdmin = array.filter(
      ({ user_role_name }) => user_role_name == "partner_admin"
    );

    const includesSuperAdmin = array.filter(
      ({ user_role_name }) => user_role_name == "partner_super_admin"
    );

    const includesEmployee = array.filter(
      ({ user_role_name }) => user_role_name == "partner_maker"
    );

    const includesBookkeeper = array.filter(
      ({ user_role_name }) => user_role_name == "partner_viewer"
    );

    const includesManager = array.filter((user) => user.team_manager);

    const includesIndividual = array.filter(
      ({ user_role_name, team_manager }) => !user_role_name && !team_manager
    );

    const arrayDecider = () => {
      if (!includesAdmin.length) return array;
      if (includesSuperAdmin.length)
        return [
          ...includesManager,
          ...includesSuperAdmin,
          ...includesAdmin,
          ...includesBookkeeper,
          ...includesEmployee,
          ...includesIndividual,
        ];
      return [
        ...includesManager,
        ...includesAdmin,
        ...includesBookkeeper,
        ...includesEmployee,
        ...includesIndividual,
      ];
    };

    const cleanArray = arrayDecider();
    const filteredApproved = array.filter(({ approved_at }) => approved_at);
    const newArray = filteredApproved.length ? filteredApproved : cleanArray;
    obj[key] = newArray;
  });

  return obj;
};

export const useCrossBorderSingleWorkflow = (transaction) => {
  const {
    // send money
    send_money_workflow_summary: send_money_workflow_summaryRaw = [],
    // send money

    // invoice
    invoice_workflow_summary,
    // invoice

    // reimbursement
    reimbursement_workflow_summary,
    // reimbursement

    // payroll
    payroll_workflow_summary,
    executor,
    rejected_by_id,
    drafter,
    // payroll

    // local transfer
    user_id,
    // local transfer

    // general
    workflow_process: workflow_processRaw = {},
    created_at,
    state,
    updated_at,
    rejection_note,
    user: userDefault,
    // general
  } = transaction || {};

  const isReimbursement = reimbursement_workflow_summary;
  const isInvoicePayment = invoice_workflow_summary;
  const isPayroll = payroll_workflow_summary;

  const send_money_workflow_summary =
    reimbursement_workflow_summary ||
    invoice_workflow_summary ||
    payroll_workflow_summary ||
    send_money_workflow_summaryRaw;

  const workflow_process = workflowProcessFormatter(workflow_processRaw || {});

  const { data: userLocalTransferCreator, refetch } = fetch({
    url: `/business_users/${user_id}`,
    formatter: (res) => res?.data || {},
    defaultValue: null,
    woInit: true,
  });

  const { data: userReject, refetch: refetchUserReject } = fetch({
    url: `/business_users/${rejected_by_id}`,
    formatter: (res) => res?.data || {},
    woInit: true,
  });

  useEffect(() => {
    if (!user_id) return;
    refetch();
  }, [user_id]);

  useEffect(() => {
    if (!rejected_by_id) return;
    refetchUserReject();
  }, [rejected_by_id]);

  const { users } = useConstants();

  const userFallbackValue = (users ?? []).find(({ id }) => user_id === id);

  const userCreatorObj =
    userDefault || userLocalTransferCreator || userFallbackValue || drafter;

  const isCompleted = state == "completed";
  const isOnProgress = state == "validating_payment" || state == "on_progress";
  const isValidating = state == "validating_payment";
  const isRefunded = state == "awaiting_refund" || state == "refunded";
  const isAwaitingRefundStrict = state == "awaiting_refund";
  const isDraft = state == "drafted";

  const { user } = useGetAuth();
  const { name, id } = user || {};

  const isDrafter = user_id == user?.id || drafter?.id == user?.id;

  const { youOrNameFunc } = useYouOrNameFunc();

  const workflowStarted = new Date(
    "Wed Aug 31 2022 19:15:00 GMT+0700 (Western Indonesia Time)"
  ).getTime();

  const createdNumber = new Date(created_at).getTime();
  const isWorkflow = createdNumber > workflowStarted;

  let requestedArrays = [];
  Object.keys(workflow_process).forEach((key) => {
    const array = workflow_process[key];
    array.forEach((item) => {
      const { approved_at } = item || {};
      if (approved_at) return;
      requestedArrays.push(item);
    });
  });

  const currentRoleNames = requestedArrays.map(
    ({ user_role_name }) => user_role_name
  );

  const approverObj = send_money_workflow_summary.filter(
    ({ state, name: user_name }) => state == "requested" && user_name == name
  )[0];

  const { isAdmin, isSuperAdmin } = getUserRole();

  const isApproverDecider = () => {
    if (currentRoleNames.includes("partner_admin")) return isAdmin;
    if (currentRoleNames.includes("partner_super_admin")) return isSuperAdmin;
    return approverObj?.name == name;
  };

  const isApprover = isApproverDecider();
  const cancelledStates = ["cancelled", "canceled", "rejected", "reject"];

  const cancelledArr = send_money_workflow_summary.filter(({ state }) =>
    cancelledStates.includes(state)
  );

  const cancelledObjDefault = cancelledArr[0];

  const cancelledObjReal = cancelledArr.filter(
    ({ approved_at }) => approved_at
  )[0];

  const cancelledObj = cancelledObjReal || cancelledObjDefault;

  const isCancelled =
    ["canceled", "cancelled"].includes(state) || Boolean(cancelledObj);

  // Lines objs
  const creatorName = youOrNameFunc(userCreatorObj?.name);

  const textDecider = () => {
    if (isReimbursement) return `${creatorName} requested a reimbursement`;
    if (isInvoicePayment) return `${creatorName} requested an invoice payment`;
    if (isPayroll) return `${creatorName} requested a payroll transaction(s)`;

    return `${creatorName} created a transaction`;
  };

  const firstLineObj = {
    text: textDecider(),
    date: customDateFormatter(created_at),
    isActive: true,
  };

  const validatingObj = {
    text: "Transferring money to recipients",
    isActive: isOnProgress,
  };

  //   approverLineArray
  let approverLineArray = [];
  let isActiveArray = [];
  let rejectChecker = false;

  Object.keys(workflow_process).forEach((key, indexParent) => {
    const array = workflow_process[key];
    const isSingleArray = array?.length === 1;
    const result = array
      .map((item, index) => {
        if (rejectChecker) return null;

        const isFirst = index === 0;
        const isLast = index === array?.length - 1;

        const {
          user_role_name,
          user_name,
          user_email,
          approved_at,
          approved_by_user_id,
          approved_by_user_name,
          rejected_at,
          rejected_by_user_id,
          rejected_by_user_name,
          team_manager,
          bypassed,
        } = item;

        // just for payroll
        if (Boolean(rejected_at)) rejectChecker = true;

        const hasApproved = Boolean(approved_at);

        const date = hasApproved
          ? customDateFormatter(approved_at)
          : rejected_at
          ? customDateFormatter(rejected_at)
          : "";

        const {
          id: execId,
          created_at: execCreatedAt,
          name: execName,
        } = executor || {};

        if (state === "rejected_by_approver" && execId) {
          rejectChecker = true;

          return {
            ...item,
            date: customDateFormatter(execCreatedAt),
            text: `Rejected by ${execName}`,
            isRed: true,
          };
        }
        // just for payroll

        const nameDecider = () => {
          const isAdmin = user_role_name == "partner_admin";
          const isSuperAdmin = user_role_name === "partner_super_admin";
          const isBookkeeper = user_role_name === "partner_viewer";
          const isEmployee = user_role_name === "partner_maker";

          const haveUserName = user_name === "" || user_name;
          const userName = user_name === "" ? user_email : user_name;

          const isTeamManager = team_manager && haveUserName && user_id;

          const teamManagerTextSimplifiedDecider = (text = "") =>
            teamManagerTextDecider({ isTeamManager, text });

          if (approved_at) {
            if (id === approved_by_user_id) {
              return teamManagerTextSimplifiedDecider(bypassed ? "your" : "You");
            }

            return teamManagerTextSimplifiedDecider(approved_by_user_name);
          }

          if (rejected_at) {
            if (id === rejected_by_user_id) {
              return teamManagerTextSimplifiedDecider("You");
            }

            return teamManagerTextSimplifiedDecider(rejected_by_user_name);
          }

          if (isAdmin) return "Any Admin";

          if (isBookkeeper) return "Any Bookkeeper";

          if (isSuperAdmin) return "Business Owner";

          if (isEmployee) return "Any Employee";

          return teamManagerTextSimplifiedDecider(youOrNameFunc(userName));
        };

        const approveReleaseDecider = () => {
          const isPayroll = payroll_workflow_summary;
          const isLastPayrollApprover =
            Object.keys(workflow_process).length - 1 === indexParent;

          if (isPayroll && isLastPayrollApprover) return "approve & release";
          return "approve";
        };

        const name = nameDecider();

        // const textDecider = () => {
        //   if (index != 0) return `Or by ${name}`;
        //   if (hasApproved) return `Approved by ${name}`;
        //   if (rejected_at) return `Rejected by ${name}`;
        //   return `Required by ${name} to ${approveReleaseDecider()}`;
        // };

        const textDecider = () => {
          if (hasApproved)
            return bypassed
              ? `System bypassed ${name} approval step.`
              : `Approved by ${name}`;
          if (rejected_at) return `Rejected by ${name}`;

          if (isSingleArray) {
            return `Require ${name} to ${approveReleaseDecider()}`;
          }

          if (isFirst) return `Require ${name}`;
          if (isLast) return `Or ${name} to ${approveReleaseDecider()}`;
          return `Or ${name}`;
        };

        const text = textDecider();

        const orangeDecider = () => {
          if (!hasApproved) {
            return;
          }
        };

        return {
          ...item,
          date,
          text,
          isOrange: !hasApproved && isFirst,
          isGreen: hasApproved && !bypassed,
          isRed: Boolean(rejected_at),
          isApproverLine: true,
        };
      })
      .filter((array) => {
        return array;
      });

    const obj = { data: result };

    approverLineArray.push(obj);
    isActiveArray.push(Boolean(result.filter(({ date }) => date).length));
  });

  approverLineArray = approverLineArray
    .map((item, index) => {
      const isActive = isActiveArray[index - 1] || index == 0;
      return { ...item, isActive };
    })
    .filter(({ data }) => data.length > 0);
  //   approverLineArray

  const defaultCompletedLineObj = {
    text: "Completed",
    date: isCompleted ? customDateFormatter(updated_at) : "",
    isActive: isCompleted,
  };

  const invoiceCompletedArray = () => {
    if (isCompleted) {
      const {
        data: [lastApproveData],
      } = approverLineArray[approverLineArray?.length - 1];
      const { approved_by_user_name, approved_at } = lastApproveData;
      const approvedAt = customDateFormatter(approved_at);
      const ReleaseText = () => {
        return (
          <GothamRegular>
            {youOrNameFunc(approved_by_user_name)}{" "}
            <TextInlineRegular style={{ color: colors.green06 }}>
              release the fund
            </TextInlineRegular>
          </GothamRegular>
        );
      };
      const completeArray = [
        {
          text: <ReleaseText />,
          date: approvedAt,
          isActive: true,
        },
        {
          text: "Transferring money to recipients",
          date: approvedAt,
          isActive: true,
        },
        {
          ...defaultCompletedLineObj,
          text: (
            <TextInlineRegular style={{ color: colors.green06 }}>
              Completed
            </TextInlineRegular>
          ),
          isGreenButton: true,
        },
      ];

      return completeArray;
    }
    return defaultCompletedLineObj;
  };

  const completedLineObj =
    isInvoicePayment || isReimbursement
      ? invoiceCompletedArray()
      : defaultCompletedLineObj;

  const cancelledLineObj = {
    text: Boolean(cancelledObj) ? (
      <>
        <span style={{ color: colors.redE7 }}>Rejected</span> by{" "}
        {youOrNameFunc(cancelledObj?.name || userReject?.id)}
      </>
    ) : (
      <>
        <span style={{ color: colors.redE7 }}>Cancelled</span> by {creatorName}
      </>
    ),
    date: customDateFormatter(
      Boolean(cancelledObj) ? cancelledObj?.approved_at : updated_at
    ),
    isActive: true,
    isRedButton: true,
  };

  // Lines objs

  return {
    isDraft,
    isWorkflow,
    isApprover,
    isDrafter,
    cancelledObj,
    isCancelled,
    isValidating,
    isCompleted,
    isOnProgress,
    isRefunded,
    isAwaitingRefundStrict,
    firstLineObj,
    approverLineArray,
    completedLineObj,
    cancelledLineObj,
    validatingObj,
    rejection_note,
  };
};
