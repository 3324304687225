import { getTime, parseISO } from "date-fns";

export const getUniqueCode = (amountOnly, uniqueCode = "") => {
  const lengthUniqueCode = uniqueCode.toString().length;
  const amountString = (amountOnly || "").toString();
  const getAmountLast3Digits = amountString.slice(-Math.abs(lengthUniqueCode));

  const zeroString = "0".repeat(lengthUniqueCode);
  const getAmountMinusLast3Digits =
    amountString.slice(0, -Math.abs(lengthUniqueCode)) + zeroString;

  const lastDigitsPlusUniqueCode = Number(getAmountLast3Digits) + uniqueCode;

  const amountPlusUniqueCode =
    Number(getAmountMinusLast3Digits) + lastDigitsPlusUniqueCode;

  return { lastDigitsPlusUniqueCode };
};

export const getStartDifferentIndex = (data1, data2) => {
  let index = "";
  for (let i = 0; i < data1.length; i++) {
    if (data1[i] !== data2[i]) {
      index = i;
      break;
    }
  }
  return index;
};

export const timeFormatter = (created_at, is24Hour) => {
  const createdAtTime = getTime(parseISO(created_at || 0)) || 0;
  const oneHour = 60 * 60 * 1000;
  const addedTime = is24Hour ? oneHour * 24 : oneHour * 2;
  const time = createdAtTime + addedTime;
  return time;
};

export const expiresFormatter = (expired_date) =>
  getTime(parseISO(expired_date || 0)) || 0;
