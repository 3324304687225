import { useRouter } from "next/router";
import { customDateFormatter, formatCurrency } from "../../../components/tools";

export const allocateFormatter = (object) => {
  const { query } = useRouter()
  const { type } = query || {}
  const { details, reference_number, created_at, status, user } = object || {};
  const { rate } = details || {};
  const { destination_currency, destination_amount } = rate || {};
  const { name: userName, email: userEmail } = user || {};

  const isIndoWallet = type === 'indonesian_wallet'
  const isMinus = isIndoWallet ? `-` : `+`
  const leftTop = `Allocate Funds - Main Balance to Card Balance`;
  const leftBottom = `• ${reference_number} • ${customDateFormatter(
    created_at
  )} • ${userName || userEmail} •`;
  const leftStatus = status;
  const rightTop = `${isMinus}${destination_currency} ${formatCurrency(
    destination_amount
  )}`;

  return { ...object, leftTop, leftBottom, leftStatus, rightTop };
};
