import React, { useEffect, useState } from "react";
import { colors } from "../../../assets/colors";
import {
  TypeFezNeueMedium,
  TypeFezNeueRegular,
} from "../../../components/Text";
import { arraySplitter } from "../../../components/tools";
import pdf from "../../../assets/images/pdf.svg";
import jpg from "../../../assets/images/jpg.svg";
import { downloadApi } from "../../../tools/api";
import { showPDFOrJPEGHooks } from "../../../components/inputs";

export const fileSize = (bytes) => {
  const kb = bytes / 1024;
  const mb = kb / 1024;
  const rounder = (number) => Math.round(number * 10) / 10;
  if (!bytes) return "-";
  if (mb > 1) return `${rounder(mb)} MB`;
  if (kb > 1) return `${rounder(kb)} KB`;
  return `${rounder(bytes)} bytes`;
};

export const InvoicePaymentSupportingDocs = ({
  array,
  title = "Supporting Documents",
}) => {
  const splittedArray = arraySplitter(array, 3);
  const formattedData = (data) => {
    const newData = data?.map((item) => {
      const { blob } = item;
      item.name = blob?.filename;
      item.type = blob?.content_type;
      item.url = item?.file_url;
      return item;
    });
    return newData;
  };

  const { handleShow, modalComponent } = showPDFOrJPEGHooks(
    formattedData(array)
  );
  if (!array.length) return null;

  return (
    <div className="w-100">
      <TypeFezNeueMedium className="font16" style={{ marginBottom: 12 }}>
        Supporting Documents
      </TypeFezNeueMedium>
      {splittedArray.map((array, index) => (
        <div className="w-100 d-flex" style={{ marginBottom: 12 }} key={index}>
          {array.map((item, index) => {
            const { file_url: url, blob } = item || {};
            const { filename: fileName, byte_size, content_type } = blob || {};

            const isLast = array.length == index + 1;
            const isPDF = content_type.includes("pdf");
            const marginRight = isLast ? 0 : 12;
            return (
              <div
                style={{
                  width: `${100 / array.length}%`,
                }}
                key={index}
              >
                <div
                  style={{
                    borderRadius: 8,
                    padding: 8,
                    paddingLeft: 12,
                    border: `1px solid ${colors.greye6}`,
                    marginRight,
                    width: `calc(100% - ${marginRight}px)`,
                  }}
                  className="d-flex darkhover"
                  onClick={() => handleShow(fileName)}
                >
                  <img
                    src={isPDF ? pdf : jpg}
                    style={{ marginRight: 8, width: 30 }}
                  />
                  <div
                    style={{
                      width: `calc(100% - 34px)`,
                    }}
                  >
                    <TypeFezNeueRegular
                      style={{
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        // width
                      }}
                    >
                      {fileName}
                    </TypeFezNeueRegular>
                    <TypeFezNeueRegular
                      className="font12"
                      style={{ color: "#84919A" }}
                    >
                      {fileSize(byte_size)}
                    </TypeFezNeueRegular>
                    {/* <Size url={url} /> */}
                  </div>
                </div>
                {modalComponent}
              </div>
            );
          })}
        </div>
      ))}
    </div>
  );
};
