import { useEffect, useState } from "react";
import {
  customDateFormatter,
  formatCurrency,
  formatCurrencyNoDecimal,
} from "../../../components/tools";
import { fetch } from "../../../tools/api";
import { booleanState } from "../../../components/Status";
import { useGetAuth } from "../../../contexts/AuthContext";
import { LocalDisbursementProgressModal } from "./progress";

const formatter = (res, prev) => {
  const prevArray = prev?.data || [];
  const resArray = (res?.data || []).map((item) => {
    const {
      local_recipient,
      amount,
      state: leftStatus,
      created_at,
      fee,
    } = item;
    const { name: leftTop } = local_recipient || {};
    const leftBottom = `• ${customDateFormatter(created_at, true)}`;
    const rightTop = `IDR ${formatCurrencyNoDecimal(
      Number(amount) + Number(fee)
    )}`;
    return {
      ...item,
      category: "local_disbursement_batch",
      leftTop,
      leftStatus,
      leftBottom,
      rightTop,
    };
  });

  const data = [...prevArray, ...resArray];
  return { ...res, data };
};

export const useLocalDisbursementBatchTransactions = (id) => {
  // fetch data
  const [page, setPage] = useState(1);

  const { data, refetch } = fetch({
    url: `/local_transaction_batches/${id}/show_transactions?page=${page}&per_page=100`,
    woInit: true,
    defaultValue: {},
    formatter,
    afterSuccess: (res) => {
      const { page, total_page } = res || {};
      if (page == total_page) return;
      if (!total_page) return;
      setPage((p) => p + 1);
    },
  });

  useEffect(() => {
    if (!id) return;
    refetch();
  }, [page, id]);
  return { data };
};

export const defaultAfterSuccessActivity = ({
  res,
  setData,
  obj: { category: categoryProps, id, state: stateProps, changedStatus },
}) => {
  const { state } = res?.data || {};

  if (!setData) return;

  setData((prev) => {
    const { array: prevArray = [] } = prev || {};

    const array = prevArray.map((item) => {
      const { category, originator_id } = item || {};
      const sameCategory = category == categoryProps;
      const sameId = originator_id == id;
      const sameState = state == stateProps;

      if (!sameId || !sameCategory || !sameState) return item;

      return {
        ...item,
        status: changedStatus,
      };
    });

    const result = { ...prev, array };

    return result;
  });
};

export const useLocalDisbursementDetails = ({
  id,
  setData,
  isModal = true,
}) => {
  const {
    data: details,
    refetch,
    loading,
  } = fetch({
    url: `/local_transaction_batches/${id}`,
    woInit: true,
    defaultValue: {},
    noToaster: false,
    afterSuccess: (res) =>
      defaultAfterSuccessActivity({
        res,
        setData,
        obj: {
          changedStatus: "in_progress",
          category: "local_batch",
          id,
          state: "on_progress",
        },
      }),
  });

  const statusFlow = (
    <LocalDisbursementProgressModal data={details} isModal={isModal} />
  );

  return { details, refetch, statusFlow, loading };
};

export const getStatusHistoryPerObj = ({ array }) => {
  const getUniqueListBy = (array, key) => {
    return [...new Map(array?.map((item) => [item[key], item])).values()];
  };

  const statusHistory = getUniqueListBy(array || [], "to");

  const filterByState = ({ key }) =>
    statusHistory?.filter(({ to }) => to === key)[0];

  const draftObj = filterByState({ key: "draft" });
  const validateObj = filterByState({ key: "validate_recipient" });
  const waitingApprovalObj = filterByState({
    key: "waiting_for_approval_tasks",
  });
  const waitingPaymentObj = filterByState({ key: "waiting_for_payment" });
  const onProgressObj = filterByState({ key: "on_progress" });
  const completedObj = filterByState({ key: "completed" });
  const partialFailedObj = filterByState({ key: "partial_failed" });

  return {
    draftObj,
    validateObj,
    waitingApprovalObj,
    waitingPaymentObj,
    onProgressObj,
    completedObj,
    partialFailedObj,
  };
};

export const ArrayDeciderTransactionFlow = ({ data }) => {
  const { user: userLoggedIn } = useGetAuth();
  const { name: nameUserLoggedIn } = userLoggedIn || {};
  const {
    state,
    updated_at,
    created_at,
    status_history,
    local_recipient,
    user,
    recipient,
  } = data || {};
  const { name: nameLocalRecipient } = local_recipient || {};
  const { name: nameRecipientSingle } = recipient || {};
  const { name: user_name } = user || {};

  const name = nameRecipientSingle ? nameRecipientSingle : nameLocalRecipient;

  const drafterName =
    nameUserLoggedIn === user_name ? "You" : user_name || "Drafter";

  const {
    draftObj,
    validateObj,
    waitingApprovalObj,
    waitingPaymentObj,
    onProgressObj,
    completedObj,
    partialFailedObj,
  } = getStatusHistoryPerObj({ array: status_history });

  const {
    isCancelled,
    isOnProgress,
    isCompleted,
    isPartialFailed,
    isPending,
    isFailed,
    isConfirmed,
    isRefund,
    isDeclined,
  } = booleanState(state);

  if (isOnProgress || isConfirmed) {
    const statusArray = [
      {
        text: `${drafterName} requested a transaction(s)`,
        date: customDateFormatter(created_at),
        isActive: true,
      },
      {
        text: "Local payment has been received",
        date: customDateFormatter(onProgressObj?.created_at),
        isActive: true,
      },
      {
        text: `Transferring money to ${name}`,
      },
      {
        text: "Completed",
      },
    ];
    return statusArray;
  }

  if (isCompleted || isPartialFailed) {
    const finishDate = completedObj?.created_at || partialFailedObj?.created_at;
    const statusArray = [
      {
        text: `${drafterName} requested a transaction(s)`,
        date: customDateFormatter(created_at),
        isActive: true,
      },
      {
        text: "Local payment has been received",
        date: customDateFormatter(onProgressObj?.created_at),
        isActive: true,
      },
      {
        text: `Transferring money to ${name}`,
        date: finishDate && customDateFormatter(finishDate),
        isActive: true,
      },
      {
        text: "Completed",
        date: finishDate && customDateFormatter(finishDate),
        isActive: true,
        styleCustom: { marginTop: -5 },
      },
    ];
    return statusArray;
  }

  if (isCancelled || isFailed || isDeclined) {
    const statusArray = [
      {
        text: `${drafterName} created a transaction(s)`,
        isActive: true,
        date: customDateFormatter(created_at),
      },
      {
        data: [
          {
            text: "We were unable to process your transaction. Please ask your account manager for more info",
            date: customDateFormatter(updated_at),
          },
        ],
        isActive: true,
        isRedButton: true,
      },
    ];
    return statusArray;
  }

  if (isRefund) {
    const statusArray = [
      {
        text: `${drafterName} requested a transaction(s)`,
        isActive: true,
        date: customDateFormatter(created_at),
      },
      {
        data: [
          {
            text: "We were unable to process your transaction. Please ask your account manager for more info",
            date: customDateFormatter(updated_at),
          },
        ],
        isActive: true,
        isRedButton: true,
      },
    ];
    return statusArray;
  }

  if (isPending) {
    const statusArray = [
      {
        text: `${drafterName} created a transaction`,
        isActive: true,
        date: customDateFormatter(created_at),
      },
      {
        text: "Local payment has been received",
      },
      {
        text: `Transferring money to ${name}`,
      },
      {
        text: "Completed",
      },
    ];
    return statusArray;
  }

  return [];
};
