import { titleCase } from "change-case";
import { booleanState } from "../../Status";

export const kybTitleAndOnClick = ({ pathname, push, query, back }, user) => {
  const title = titleCase(pathname.split("/kyb/").pop() || "");
  const isBusinessType = pathname == "/kyb/business-type";
  const isBusinessDetails = pathname == "/kyb/business-details";
  const isBankAccDetails = pathname == "/kyb/bank-account-details";
  const isPersonalInfo = pathname == "/kyb/personal-information";
  const isSupportingDocs = pathname == "/kyb/supporting-documents";
  const isIdentityVerification = pathname == "/kyb/identity-verification";

  let onClick = null;
  if (isBusinessType) onClick = () => push("/home");

  const { real_document_state, country_of_incorporation, business_type } =
    user?.partner || {};

  const isIndividual = business_type == "individual";

  const { isNotSubmitted } = booleanState(real_document_state);
  if (isBusinessDetails)
    onClick = () => {
      if (country_of_incorporation) return push("/home");
      if (!country_of_incorporation && isNotSubmitted)
        return push("/kyb/business-type");
      push("/home");
    };

  const disabledForms = !isNotSubmitted;

  if (isBankAccDetails)
    onClick = () => {
      if (isIndividual && country_of_incorporation) return push("/home");
      if (!disabledForms && isIndividual) return push("/kyb/business-type");
      if (isIndividual) return push("/home");
      push("/kyb/business-details");
    };

  if (isPersonalInfo)
    onClick = () => {
      const isSecondStep = query.isAdditional == "true";
      if (isSecondStep)
        return push({ query: { ...query, isAdditional: false } });
      push("/kyb/bank-account-details");
    };

  if (isSupportingDocs)
    onClick = () => {
      const isSecondStep = query.skipped == "true";
      if (isSecondStep) return push({ query: { ...query, skipped: false } });
      if (isIndividual) return push("/kyb/bank-account-details");
      push("/kyb/personal-information");
    };

  if (isIdentityVerification)
    onClick = () =>
      push({ pathname: "/kyb/supporting-documents", query: { skipped: true } });

  return { title, onClick };
};
