export const canBeAccessedAllTheTimePathnames = [
  "/",
  "/home",
  "/login-demo",
  "/login",
  "/register",
  "/create-password",
  "/account-details",
  "/verification",
  "/settings",
  "/menu-api",
  "/local-transfer",
  "/local-disbursement",
  "/kyb",
  "/approval-list", // hardcoded for now
  "/account/invite-users",
  // "/cards",
  "/old-activity",
  // "/workflow",
  // "/e-wallet",
  "/task",
  "/history",
];

export const userPathnames = ["/users", "/users/edit-workflow"];

export const activitiesORTransactionsPathnames = ["/home", "/history"];

export const createTransactionPathnames = ["/cross-border"];

// export const recipientPathnames = ["/recipients"];

export const VAPathnames = ["/virtual-account", "/collection-report"];

export const accountPathnames = ["/account"];

export const settingPathnames = [
  ...userPathnames,
  "/menu-api",
  "/settings",
  "/workflow",
  "/customize-receipt",
];
