import React, { useRef, useState } from "react";
import { AiFillCloseCircle } from "react-icons/ai";
import { GiHamburgerMenu } from "react-icons/gi";
import { HiArrowLeft } from "react-icons/hi";
import { colors } from "../../assets/colors";
import userIcon from "../../assets/images/user-logo-small.svg";
import { useClickOutside } from "../../universalFunctions/useClickOutside";
import { getPropsMainNav } from "../ComponentWithSidebar";
import { useModalHook } from "../Modals";
import { GothamMedium } from "../Text";
import { Burger } from "./burger";
import { UserBurger } from "./userBurger";
import { useTitleAndOnClick } from "./constants/parent";

export const BurgerNavbar = () => {
  const { isOpen, toggle, close } = useModalHook();
  const {
    isOpen: isOpenUser,
    toggle: toggleUser,
    close: closeUser,
  } = useModalHook();

  const [navHeight, setNavHeight] = useState(0);

  const refMain = useRef();

  useClickOutside({
    clickOutside: close,
    ref: refMain,
  });

  const refUser = useRef();

  useClickOutside({
    clickOutside: closeUser,
    ref: refUser,
  });

  const { title: titleBackup } = getPropsMainNav();
  const { title, onClick } = useTitleAndOnClick();

  const divStyle = {
    backgroundColor: isOpen ? colors.pink : "",
    padding: 4,
    paddingTop: 0,
    borderRadius: 3,
    transition: "background-color .5s ease",
    marginRight: 12,
  };

  const iconProps = { size: 20, color: isOpen ? "white" : colors.pink };

  return (
    <div
      className="d-flex justify-content-between align-items-center"
      style={{
        padding: 16,
        position: "relative",
        borderBottom: "1px solid #eaeaea",
      }}
      ref={(e) => setNavHeight(e?.clientHeight)}
    >
      {onClick ? (
        <div onClick={onClick} style={divStyle}>
          <HiArrowLeft size={20} {...iconProps} />
        </div>
      ) : (
        <div onClick={toggle} style={divStyle} ref={refMain}>
          <GiHamburgerMenu {...iconProps} />
          <Burger close={close} isOpen={isOpen} top={navHeight} />
        </div>
      )}
      <GothamMedium
        className="font20"
        style={{ textAlign: "center", color: colors.blue }}
      >
        {title || titleBackup}
      </GothamMedium>
      <div
        className="d-flex align-items-center"
        ref={refUser}
        onClick={toggleUser}
      >
        <div
          style={{
            position: "relative",
            width: 32,
            height: 32,
          }}
        >
          <img
            src={userIcon}
            className={`${!isOpenUser ? "fade-in" : "fade-out"}`}
            style={{ width: 32, position: "absolute", left: 0, top: 0 }}
          />
          <AiFillCloseCircle
            size={32}
            color={colors.blue}
            style={{ width: 32, position: "absolute", left: 0, top: 0 }}
            className={`${isOpenUser ? "fade-in" : "fade-out"}`}
          />
        </div>
        <UserBurger close={closeUser} isOpen={isOpenUser} top={navHeight} />
      </div>
    </div>
  );
};
