import { useRouter } from "next/router";
import { createContext, useEffect } from "react";
import { useModalHook } from "../../components/Modals";
import NoSeatModal from "../../components/SubscriptionComponents/NoSeatModal/NoSeatModal";
import SubscriptionNotificationModal from "../../components/SubscriptionComponents/SubscriptionNotificationModal/SubscriptionNotificationModal";
import { windowDimension } from "../../components/tools";
import { useGetAuth } from "../AuthContext";
import { MobileAlertModal } from "./modal";

export const AlertContext = createContext();

export const AlertContextProvider = ({ children }) => {
  const { pathname } = useRouter();

  const { isTabOrPhone } = windowDimension();

  const {
    isOpen: isOpenMobileAlertModal,
    toggle: toggleMobileAlertModal,
    open: openMobileAlertModal,
  } = useModalHook();

  const {
    isOpen: isOpenNoSeatModal,
    open: openNoSeatModal,
    close: closeNoSeatModal,
  } = useModalHook();

  const {
    isOpen: isOpenSubsNotifModal,
    open: openSubsNotifModal,
    close: closeSubsNotifModal,
  } = useModalHook();

  const { user } = useGetAuth();
  const { status, subs_notif_is_read } = user || {};

  useEffect(() => {
    const isUnseated = status === "pending";
    const notifFromSubscription = subs_notif_is_read;

    const excludedPathnames = ["/login", "/register", "/create-password"];

    if (excludedPathnames.includes(pathname)) {
      closeSubsNotifModal();
      closeNoSeatModal();
      return;
    }

    if (!status) return;
    if (isUnseated) return openNoSeatModal();
    if (notifFromSubscription) return openSubsNotifModal();
    if (!notifFromSubscription) return closeSubsNotifModal();
  }, [status, subs_notif_is_read, pathname]);

  useEffect(() => {
    if (!isTabOrPhone) return;
    openMobileAlertModal();
  }, [isTabOrPhone]);

  return (
    <AlertContext.Provider>
      {isTabOrPhone && (
        <MobileAlertModal
          isOpen={isOpenMobileAlertModal}
          toggle={toggleMobileAlertModal}
        />
      )}
      <NoSeatModal isOpen={isOpenNoSeatModal} />
      <SubscriptionNotificationModal
        isOpen={isOpenSubsNotifModal}
        toggle={closeSubsNotifModal}
      />
      {children}
    </AlertContext.Provider>
  );
};
