import AllocateIcon from "../../assets/images/account-allocate2.svg";
import HistoryIcon from "../../assets/images/account-history.svg";
import exportIcon from "../../assets/images/home-export.svg";
import historyIcon from "../../assets/images/home-history.svg";
import plusIcon from "../../assets/images/home-plus.svg";
import withdrawIcon from "../../assets/images/home-withdraw.svg";
import cards from "../../assets/images/know-transfez-cards.svg";
import collect from "../../assets/images/know-transfez-collect.svg";
import fund from "../../assets/images/know-transfez-fund.svg";
import send from "../../assets/images/know-transfez-send.svg";
import userIcon from "../../assets/images/know-transfez-user.svg";
import works from "../../assets/images/know-transfez-works.svg";
import { getUserButtonBooleans, getUserRole } from "../../contexts/AuthContext";

export const useHomeButtons = () => {
  const {
    canTopup,
    canWithdraw,
    canViewMainBalanceHistory,
    canReallocate,
    canViewCardBalanceHistory,
    canViewReport,
  } = getUserButtonBooleans();

  const { isBookKeeper } = getUserRole();

  const buttonsMain = [
    {
      src: plusIcon,
      text: "Top Up",
      value: "top_up",
      hidden: isBookKeeper || !canTopup,
    },
    {
      src: withdrawIcon,
      text: "Withdraw",
      value: "withdraw",
      hidden: isBookKeeper || !canWithdraw,
    },
    {
      src: historyIcon,
      text: "Balance History",
      value: "history_main",
      hidden: isBookKeeper ? false : !canViewMainBalanceHistory,
    },
    {
      src: exportIcon,
      text: "Export Ledgers",
      value: "export",
      hidden: isBookKeeper ? false : !canViewReport,
    },
  ];

  const buttonsCard = [
    {
      src: AllocateIcon,
      text: "Allocate to \n Main Balance",
      value: "allocate_to_main",
      hidden: !canReallocate,
    },
    {
      src: HistoryIcon,
      text: "Card Balance\n History",
      value: "history_card",
      hidden: !canViewCardBalanceHistory,
    },
  ];
  return { buttonsMain, buttonsCard };
};

export const gettingToKnowArr = [
  { icon: works, label: "How account works" },
  { icon: fund, label: "Fund your account" },
  { icon: userIcon, label: "User management" },
  { icon: send, label: "Send Your Money" },
  { icon: collect, label: "Money Collection" },
  { icon: cards, label: "Money Collection" },
];
