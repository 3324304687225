export const colors = {
  grey72: "#727272",
  teal: "#49ABB8",
  errorRed: "#EB5B42",
  greyC2: "#c2c2c2",
  greyea: "#eaeaea",
  yellowFF: "#FFD27C",
  darkTeal: "#218390",
  grey25: "#252525",
  teal21: "#218391",
  yellowFF: "#FFD27C",
  yellowFC: "#FCB037",
  blue19: "#196E97",
  blue09: "#095174",
  blue10: "#102C57",
  bluee1: "#E1EEFF",
  error9c: "#9C0E0E",
  lightYellowFF: "#FFE7BA",
  lightestYellowFF: "#FFF8EC",
  lightYellowFC: "#FCF4E8",
  redFF: "#FFD5CD",
  redFFE: "#FFEEEE",
  redB9: "#B90E0E",
  redEB: "#EB5B42",
  // gradient: "linear-gradient(rgba(73,171,184,1) 5%, rgba(25,110,151,1) 100%)",
  gradient: "linear-gradient(rgba(16,44,87,1) 5%, rgba(72,104,150,1) 100%)",
  lightTeal: "#e5f9fc",
  redE7: "#E73126",
  redE9: "#E95757",
  green28: "#28AB7C",
  greend3: "#D3F5EC",
  whiteFC: "#FCFCFC",
  pink: "#DA649F",
  lightPink: "#FDD7F5",
  blue: "#102C57",
  lightBlue: "#9CC3F5",
  grey33: "#333334",
  primaryBlue: "#102C57",
  grey90: "#909098",
  grey6c: "#6C6C71",
  greyBB: "#BBBBC0",
  blue48: "#486896",
  blue2C: "#2C4A77",
  bluee6: "#E6F0FC",
  greye6: "#E6E6E8",
  redca: "#ca0b0b",
  greyd1: "#D1D1D4",
  orangeFF: "#FF7B5A",
  green06: "#06AC4E",
  yellowfc: "#FCB037",
  yellowF4: "#F4D53D",
};
