import { useRouter } from "next/router";
import React from "react";
import { MdExpandMore } from "react-icons/md";
import { GothamMedium } from "../Text";

export const BurgerNavbarItem = ({
  href,
  icon,
  iconActive,
  title,
  accordion = [],
  onClick,
  isActiveAccordion,
  className = "",
}) => {
  const { pathname } = useRouter();
  const isActive = pathname.includes(href);
  return (
    <div
      className={`d-flex align-items-center justify-content-between ${className}`}
      style={{
        padding: 16,
        backgroundColor: isActive ? "#e184b3" : "",
      }}
      onClick={onClick}
    >
      <div className="d-flex align-items-center">
        {iconActive ? iconActive : <div style={{ width: 20 }} />}
        <GothamMedium
          style={{
            color: "white",
            marginLeft: 12,
            fontFamily: isActive ? "GothamMedium" : "GothamLight",
            color: isActive ? "white" : "#E5F9FC",
          }}
          className="font14"
        >
          {title}
        </GothamMedium>
      </div>
      {Boolean(accordion.length) && (
        <MdExpandMore
          color="white"
          size={20}
          className={`${
            isActiveAccordion ? "open-accordion" : "close-accordion"
          }`}
        />
      )}
    </div>
  );
};
