export const accountTitleAndOnClick = ({ pathname, push, back, query }) => {
  const isTopUp = pathname == "/account/topup";
  const isTopUpDetails = pathname == "/account/topup/[id]";

  if (isTopUp) return { title: "Top Up", onClick: back };

  const onClick = () => {
    const { wallet, id } = query;
    const isIDR = wallet == "indonesian_wallet";
    if (isIDR)
      return push({
        pathname: "/account/IDR",
        query: { type: "indonesian_wallet", defaultId: id },
      });
    back();
  };

  if (isTopUpDetails) return { title: "Payment Instruction", onClick };

  const isWithDraw = pathname == "/account/withdrawal";
  // account/IDR
  if (isWithDraw) {
    const { isFirstForm } = query;
    if (isFirstForm == "true")
      return { title: "Withdraw", onClick: () => push("/home") };
    return {
      title: "Confirmation",
      onClick: () => push({ query: { isFirstForm: true } }),
    };
  }

  const isAccountDetails = pathname == "/account/[id]";

  if (isAccountDetails)
    return { title: "Balance", onClick: () => push("/home") };

  return {};
};
