import React, { Fragment, useState } from "react";
import copyIcon from "../../../assets/images/copy-icon.svg";
import infoIcon from "../../../assets/images/info-icon-blue.svg";
import infoIconGrey from "../../../assets/images/info-icon.svg";
import { colors } from "../../../assets/colors";
import { bankLists } from "../../../assets/data";
import {
  TypeFezNeueMedium,
  TypeFezNeueRegular,
} from "../../../components/Text";
import { formatCurrencyNoDecimal } from "../../../components/tools";
import bankProcedureDecider from "../../../constants/bankProcedureDecider";
import { ProcedureComponent } from "../../../components/Modals";
import Countdown from "react-countdown";
import { Tooltip } from "reactstrap";

import { getStartDifferentIndex, getUniqueCode } from "./logic";

export const CountDownTopUpModal = ({
  data,
  time,
  fromMainModal = false,
  showAlert = false,
  handlePaymentInstruction,
}) => {
  const { expiredIn } = data || {};

  return (
    <div
      style={{
        background: "white",
        boxShadow: "0px 4px 8px rgba(88, 88, 88, 0.06)",
        borderRadius: 12,
        padding: 16,
        marginTop: fromMainModal && 16,
      }}
    >
      <div className="d-flex justify-content-between align-items-center">
        <div>
          <TypeFezNeueRegular
            style={{
              fontSize: 12,
              lineHeight: "16px",
              color: colors.grey6c,
              marginBottom: 4,
            }}
          >
            Complete your payment before
          </TypeFezNeueRegular>
          <TypeFezNeueMedium
            style={{
              fontSize: 12,
              lineHeight: "16px",
              marginBottom: 4,
            }}
          >
            {expiredIn}
          </TypeFezNeueMedium>
          {fromMainModal && (
            <TypeFezNeueMedium
              style={{
                fontSize: 12,
                lineHeight: "16px",
                color: colors.pink,
                textDecoration: "underline",
                cursor: "pointer",
              }}
              onClick={handlePaymentInstruction}
            >
              View Payment Instruction
            </TypeFezNeueMedium>
          )}
        </div>
        <div>
          <Countdown date={time} renderer={CustomCountDownComponent} />
        </div>
      </div>
      {showAlert && (
        <AlertPaymentInstruction
          style={{ marginTop: 12 }}
          description="Please contact your AM if you have paid but it hasn't been successful within 10 minutes."
        />
      )}
    </div>
  );
};

const CustomCountDownComponent = ({
  formatted: { hours, minutes, seconds },
}) => {
  const data = [{ hours }, { minutes }, { seconds }];
  return (
    <div className="d-flex justify-content-center">
      {data?.map((item, index) => {
        const { hours, minutes, seconds } = item;
        const timeFormat = Object.keys(item).pop();
        const isLast = index === data.length - 1;
        return (
          <div key={index} className="d-flex align-items-center">
            <div
              style={{
                background: "rgba(253, 215, 245, 0.4)",
                borderRadius: 4,
                padding: "2px 4px",
                width: 28,
              }}
            >
              <TypeFezNeueMedium
                style={{ fontSize: 16, lineHeight: "24px", color: colors.pink }}
              >
                {hours || minutes || seconds}
              </TypeFezNeueMedium>
            </div>
            {!isLast && (
              <TypeFezNeueMedium style={{ margin: "0px 4px" }}>
                :
              </TypeFezNeueMedium>
            )}
          </div>
        );
      })}
    </div>
  );
};

const RenderedColorValue = ({ amount, diffIndex }) => {
  return (
    <>
      {amount?.map((item, index) => {
        const isPink = index >= diffIndex;
        const isThreeDigits = (amount.length - index) % 3 === 0;
        const isNotIndexZero = index !== 0;
        const isNeedComma = isThreeDigits && isNotIndexZero;

        const isPinkComma = index === diffIndex || index < diffIndex;

        return (
          <Fragment key={index}>
            {isNeedComma && (
              <span style={{ color: !isPinkComma && colors.pink }}>,</span>
            )}
            <span style={{ color: isPink && colors.pink }}>{item}</span>
          </Fragment>
        );
      })}
    </>
  );
};

export const CardDetailsBank = ({ data }) => {
  const { bank, name, number, icon } = bankLists[0];

  const { amount_with_code, amount, unique_code } = data || {};

  const amountCodeArr = (amount_with_code || "").toString().split("");
  const amountString = (amount || "").toString();

  const startDiffIndex = getStartDifferentIndex(amountCodeArr, amountString);
  const { lastDigitsPlusUniqueCode } = getUniqueCode(amount, unique_code);

  const valueComponents = RenderedColorValue({
    amount: amountCodeArr,
    diffIndex: startDiffIndex,
  });

  const dataRendered = [
    {
      title: "Account Number",
      value: "5230636399",
      copyValue: "5230636399",
    },
    {
      title: "Total Amount",
      value: <>IDR {valueComponents}</>,
      copyValue: amount_with_code,
      icon: infoIconGrey,
    },
  ];

  return (
    <div
      style={{
        background: "white",
        boxShadow: "0px 4px 8px rgba(88, 88, 88, 0.06)",
        borderRadius: 12,
        padding: 16,
        marginBottom: 16,
      }}
    >
      <div
        className="d-flex"
        style={{
          paddingBottom: 8,
          borderBottom: `1px solid ${colors.greyea}`,
          marginBottom: 16,
        }}
      >
        <img src={icon} className="mr-2" />
        <div>
          <TypeFezNeueRegular
            style={{ color: colors.grey6c, fontSize: 12, lineHeight: "14px" }}
          >
            {bank}
          </TypeFezNeueRegular>
          <TypeFezNeueMedium>{name}</TypeFezNeueMedium>
        </div>
      </div>
      <div style={{ marginBottom: 16 }}>
        {dataRendered?.map((item, index) => {
          const [isHover, setIsHover] = useState(false);
          const [tooltipOpen, setTooltipOpen] = useState(false);
          const toggleTooltip = () => setTooltipOpen(!tooltipOpen);
          const { title, value, copyValue, icon } = item || {};
          const showInfoTooltip = isHover === index;
          return (
            <div
              key={index}
              className="d-flex justify-content-between align-items-center"
              style={{ marginBottom: 12 }}
            >
              <div>
                <div className="d-flex align-items-center">
                  <TypeFezNeueRegular
                    style={{
                      color: colors.grey6c,
                      fontSize: 12,
                      lineHeight: "14px",
                    }}
                  >
                    {title}
                  </TypeFezNeueRegular>
                  {icon && (
                    <div
                      style={{ position: "relative", height: 14 }}
                      onMouseEnter={() => setIsHover(index)}
                      onMouseLeave={() => setIsHover(false)}
                    >
                      <img
                        src={icon}
                        style={{ width: 10, marginLeft: 4, marginTop: -12 }}
                      />
                      {showInfoTooltip && (
                        <TooltipTotalAmountUniqueCode data={data} />
                      )}
                    </div>
                  )}
                </div>
                <TypeFezNeueMedium>{value}</TypeFezNeueMedium>
              </div>
              <div
                id={"Tooltip-" + (index + 1)}
                style={{ cursor: "pointer" }}
                onClick={(e) => {
                  e.preventDefault();
                  navigator.clipboard.writeText(copyValue);
                }}
              >
                <img src={copyIcon} />
              </div>
              <Tooltip
                target={"Tooltip-" + (index + 1)}
                isOpen={tooltipOpen}
                toggle={toggleTooltip}
                style={{ padding: "0px 12px 2px 12px" }}
              >
                <span
                  style={{
                    fontSize: 10,
                    lineHeight: "12px",
                    fontFamily: "TypefezNeueRegular",
                  }}
                >
                  Copy
                </span>
              </Tooltip>
            </div>
          );
        })}
      </div>
      <AlertPaymentInstruction value={unique_code} />
    </div>
  );
};

export const AlertPaymentInstruction = ({
  description = false,
  style,
  value,
}) => {
  const formattedValue = `IDR ${formatCurrencyNoDecimal(value)}`;
  return (
    <div
      className="d-flex"
      style={{
        background: "rgba(211, 241, 255, 0.3)",
        border: `1px solid ${colors.blue19}`,
        borderRadius: 8,
        padding: 12,
        ...style,
      }}
    >
      <img src={infoIcon} className="mr-2" />
      <TypeFezNeueRegular
        style={{ color: colors.blue2C, fontSize: 12, lineHeight: "14px" }}
      >
        {description ? (
          description
        ) : (
          <>Make sure you transfer the exact amount to the very last digit.</>
        )}
      </TypeFezNeueRegular>
    </div>
  );
};

export const ProcedureInstruction = () => {
  const [isExpandedIndex, setIsExpandedIndex] = useState(0);

  const stateObj = {
    isExpandedIndex: isExpandedIndex,
    setIsExpandedIndex: setIsExpandedIndex,
  };

  const bank = "Bank BCA";

  const procedure = bankProcedureDecider(bank);

  const procedureKeys = Object.keys(procedure).map((procedure) => procedure);
  const procedureValues = Object.values(procedure).map(
    (procedure) => procedure
  );

  return (
    <div>
      <TypeFezNeueMedium
        style={{
          fontSize: 16,
          lineHeight: "24px",
          paddingBottom: 8,
          marginBottom: 16,
          borderBottom: `1px solid ${colors.greyea}`,
        }}
      >
        How to make the payment
      </TypeFezNeueMedium>
      <div>
        {procedureKeys.map((procedure, index) => (
          <ProcedureComponent
            key={procedure}
            procedure={procedure}
            procedures={procedureValues[index]}
            index={index}
            stateObj={stateObj}
          />
        ))}
      </div>
    </div>
  );
};

const TooltipTotalAmountUniqueCode = ({ data }) => {
  const { amount, amount_with_code, unique_code } = data || {};

  const AmountGenerator = (number) => `IDR ${formatCurrencyNoDecimal(number)}`;

  const renderArr = [
    { title: "Top up amount", value: AmountGenerator(amount) },
    { title: "Unique code", value: AmountGenerator(unique_code) },
    { spacer: true },
    {
      title: "Total payment",
      value: AmountGenerator(amount_with_code),
      valueBold: true,
    },
  ];

  return (
    <div
      style={{
        position: "absolute",
        top: -90,
        left: -90,
        backgroundColor: "white",
        borderRadius: 4,
        padding: 12,
        width: 217,
        boxShadow: "0px 8px 20px rgba(88, 88, 88, 0.1)",
      }}
    >
      {renderArr?.map((item, index) => {
        const { title, value, spacer, valueBold } = item || {};
        const isFirst = index === 0;

        if (spacer)
          return (
            <div
              key={index}
              style={{ borderBottom: "2px dashed #C2C2C2", marginTop: 8 }}
            />
          );

        return (
          <div
            key={index}
            className="d-flex justify-content-between align-items-center"
            style={{ marginTop: !isFirst && 8 }}
          >
            <TypeFezNeueRegular
              style={{
                fontSize: 10,
                lineHeight: "12px",
                color: colors.grey72,
              }}
            >
              {title}
            </TypeFezNeueRegular>
            <TypeFezNeueRegular
              style={{
                fontSize: 10,
                lineHeight: "12px",
                color: valueBold ? colors.grey33 : colors.grey72,
                fontFamily: valueBold && "TypefezNeueMedium",
              }}
            >
              {value}
            </TypeFezNeueRegular>
          </div>
        );
      })}
    </div>
  );
};
