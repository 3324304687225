import React, { Fragment, useEffect, useRef, useState } from "react";
import { useModalHook } from "../../../components/Modals";
import { GothamBold, GothamRegular } from "../../../components/Text";
import { useClickOutside } from "../../../universalFunctions/useClickOutside";
import caretDown from "../../../assets/images/caret-down-pink.svg";
import IndividuTeal from "../../../assets/images/individual-teal-icon.svg";
import IndividuWhite from "../../../assets/images/individual-white-icon.svg";
import officeGray from "../../../assets/images/office-gray-icon.svg";
import officePink from "../../../assets/images/office-pink-icon.svg";
import { colors } from "../../../assets/colors";
import { SelectBusinessTypeModal } from "./modal";

export const SelectionBusinessType = ({ data = [], setBusinessType }) => {
  const isSomeB2B = data.some((item) => item === "B2B");
  const isSomeB2C = data.some((item) => item === "B2C");
  const dataRender = [
    ...(isSomeB2B
      ? [
          {
            title: "Business/Institution",
            value: "B2B",
            iconPink: officePink,
            iconGrey: officeGray,
          },
        ]
      : []),
    ...(isSomeB2C
      ? [
          {
            title: "Individual/Personal",
            value: "B2C",
            iconPink: IndividuTeal,
            iconGrey: IndividuWhite,
          },
        ]
      : []),
  ];

  const [selectedData, setSelectedData] = useState({});

  const { isOpen, toggle, close } = useModalHook();

  const { title, iconPink } = selectedData || {};

  const handleClick = (val) => {
    const { value } = val;
    const isB2B = value === "B2B";
    setSelectedData(val);
    setBusinessType(isB2B);
    close();
  };

  useEffect(() => {
    setSelectedData(dataRender[0]);
  }, [data]);

  return (
    <div>
      <GothamRegular
        style={{ marginBottom: 12, color: colors.grey6c, fontSize: 12, lineHeight: "16px" }}
      >
        Select recipient type
      </GothamRegular>
      <div
        className="d-flex align-items-center justify-content-between"
        style={{ cursor: "pointer" }}
        onClick={toggle}
      >
        <div className="d-flex align-items-center">
          <img src={iconPink} alt="icon" className="mr-2" />
          <GothamBold style={{ marginBottom: 0 }}>{title}</GothamBold>
        </div>
        <img src={caretDown} alt="bottom arrow" />
      </div>
      <SelectBusinessTypeModal
        toggle={toggle}
        isOpen={isOpen}
        data={dataRender}
        handleClick={handleClick}
      />
    </div>
  );
};
