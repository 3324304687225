import { useState } from "react";
import single from "../../../assets/images/account-icon-local-disbursement.svg";
import { NameAvatar } from "../../../components/Avatar";
import {
  MiddleWrapper,
  RightCardModalAtomic,
} from "../../../components/CardModal";
import { GetFlag } from "../../../components/inputs";
import { LeftRightList } from "../../../components/Lists";
import { StatusActivities } from "../../../components/Status";
import { GothamMedium, GothamRegular } from "../../../components/Text";
import {
  customDateFormatter,
  formatCurrencyNoDecimal,
} from "../../../components/tools";
import { useConstants } from "../../../contexts/ConstantsContext/parent";
import { ActivityDetailsHeader } from "../activityModalComponents";

export const CenterendIconLocalTransfer = ({ icon, img }) => (
  <div className="d-flex justify-content-center">
    <div style={{ width: 40, position: "relative", marginTop: 3 }}>
      {img ? img : <img src={icon} />}
      {/* <GetFlag
        iso="id"
        style={{ position: "absolute", bottom: -8, right: -12 }}
        height={16}
        width={16}
      /> */}
    </div>
  </div>
);

export const NameAvatarWithFlag = ({ name, iso = "id", noFlag = false }) => (
  <div
    style={{
      position: "relative",
      width: 36,
      marginRight: 12,
    }}
  >
    <NameAvatar>{name}</NameAvatar>
    {!noFlag && (
      <GetFlag
        iso={iso}
        style={{
          position: "absolute",
          bottom: -4,
          right: -12,
        }}
        height={16}
        width={16}
      />
    )}
  </div>
);

export const LocalTransferTransactionModal = ({ isOpen, toggle, data }) => {
  if (!isOpen) return null;
  const { created_at, local_recipient, fee, amount, reference_id, state } =
    data || {};
  const { localTransferBanks } = useConstants();

  const { name, account_number, payer_id, name: title } = local_recipient || {};
  const bank =
    localTransferBanks.filter(({ value }) => payer_id == value)[0]?.label || "";

  const headerData = {
    title,
    type: "Local Disbursement",
    icon: null,
  };

  const [middleHeight, setMiddleHeight] = useState(0);

  return (
    <RightCardModalAtomic
      isOpen={isOpen}
      middleHeight={middleHeight}
      header={
        <ActivityDetailsHeader
          toggle={toggle}
          data={headerData}
          middleHeight={middleHeight}
        />
      }
      middle={
        <MiddleWrapper height={middleHeight} setHeight={setMiddleHeight}>
          <CenterendIconLocalTransfer icon={single} />
        </MiddleWrapper>
      }
      body={
        <>
          <LeftRightList
            array={[
              { left: "Date", right: customDateFormatter(created_at) },
              { left: "Reference", right: reference_id },
              {
                left: "Status",
                right: (
                  <div className="d-flex justify-content-end">
                    <StatusActivities state={state} className="font12" />
                  </div>
                ),
              },
              { spacer: true },
              {
                left: "Recipient gets",
                right: `IDR ${formatCurrencyNoDecimal(amount)}`,
                rightBold: true,
              },
              {
                left: "Transfer fee",
                right: `IDR ${formatCurrencyNoDecimal(fee)}`,
              },
              {
                left: "You pay",
                right: `IDR ${formatCurrencyNoDecimal(
                  Number(amount) + Number(fee)
                )}`,
                rightBold: true,
              },
            ]}
          />
          <GothamMedium className="font16" style={{ marginTop: 20 }}>
            Recipient
          </GothamMedium>
          <div className="d-flex" style={{ marginTop: 14 }}>
            <NameAvatarWithFlag name={name} />
            <div>
              <GothamRegular>{name}</GothamRegular>
              <GothamRegular className="font12">
                {bank} - {account_number}
              </GothamRegular>
            </div>
          </div>
        </>
      }
    />
  );
};
