import { useEffect, useState, useRef } from "react";
import { fetch, useMutation, apiBusiness } from "../../../../tools/api";
import { RightCardModalAtomic } from "../../../../components/CardModal";
import CardModalBody from "./CardModalBody";
import CardModalHeader from "./CardModalHeader";
import CardModalMiddle from "./CardModalMiddle";
import CardModalWing from "./CardModalWing";
import { eventsTracker } from "../../../../universalFunctions/events";
import { getUserRole } from "../../../../contexts/AuthContext";
import { TopUpModal, useModalHook } from "../../../../components/Modals";
import { ToasterHook } from "../../../../contexts/ToasterContext";
import { PayrollPinModal } from "../../../../modals/smartActivityModal/payroll/pinModal";
import { PayrollRejectModal } from "../../../../modals/smartActivityModal/payroll/rejectModal";
import { useRouter } from "next/router";
import { useForm } from "react-hook-form";
import { useGdrivehook } from "../../../../contexts/GoogleDrivePreviewContext/parent";
import { cardTypeDecider } from "../../../newCardComponents/tools";
import { TopupCardHeader, TopupCardModal } from "./topup/parent";
import {
  formatCurrency,
  formatCurrencyNoDecimal,
} from "../../../../components/tools";

const DetailModal = ({
  isOpen,
  toggle: toggleProps,
  dataDetail,
  setIsChangingCardState,
  afterSuccess: afterSuccessProps = () => {},
}) => {
  if (!dataDetail) return null;
  const { openTopup } = dataDetail || {};

  const [isMinimize, setIsMinimize] = useState(true);
  const [dataDetailLatest, setDataDetailLatest] = useState(null);
  const [selectedTransactionId, setSelectedTransactionId] = useState(null);
  const [selectedTransactionData, setSelectedTransactionData] = useState(null);
  const [actionType, setActionType] = useState("");
  const [cardId, setCardId] = useState(null);
  const [currentScreen, setCurrentScreen] = useState("");
  const [canRevealData, setCanRevealData] = useState(false);
  const [loadingPin, setLoadingPin] = useState(false);
  const [unmaskedDataDetail, setUnmaskedDataDetail] = useState(null);
  const [payload, setPayload] = useState({});
  const [taskId, setTaskId] = useState(null);
  const [isToSuccess, setIsToSuccess] = useState(true);

  const { isEmployee } = getUserRole();
  const { errorToasterApi } = ToasterHook();
  const { push, query } = useRouter();

  const { cardTransactionId } = query;

  const useFormObj = useForm();
  const { getValues, setValue } = useFormObj;
  const notesForm = getValues("notes");
  const { notes, file_details } = selectedTransactionData || {};

  const { data: dataDetailReal, refetch } = fetch({
    url: `/cards/${
      dataDetail?.originator?.originator_id ||
      dataDetailLatest?.id ||
      dataDetail?.id
    }`,
    woInit: true,
    formatter: (res) => res?.data,
  });

  const { card_type, get_approval_tasks, is_adjusting_limit } =
    dataDetailReal || {};
  const isNeedApprovalLimit = is_adjusting_limit?.value;
  const { isPhysical, isPrepaid } = cardTypeDecider(card_type);

  useEffect(() => {
    if (!isPrepaid) return;
    if (!dataDetailReal?.balance) return;

    const isNoChanges =
      formatCurrencyNoDecimal(dataDetailReal?.balance) ===
      formatCurrencyNoDecimal(dataDetail?.balance);
    if (isNoChanges) return;
    setIsChangingCardState && setIsChangingCardState(true);
  }, [dataDetailReal?.balance]);

  const {
    refetch: refetchTransactionDetail,
    loading: loadingRefetchTransactionDetail,
  } = fetch({
    url: `/card_transactions/${selectedTransactionId}`,
    woInit: true,
    formatter: (res) => res?.data,
    afterSuccess: (res) => {
      setSelectedTransactionData(res?.data);
      setValue("notes", res?.data?.notes);
    },
  });

  useEffect(() => {
    refetch();
  }, [dataDetailLatest]);

  useEffect(() => {
    if (selectedTransactionId) {
      refetchTransactionDetail();
    }
  }, [selectedTransactionId]);

  useEffect(() => {
    if (canRevealData) togglePin();
  }, [canRevealData]);

  useEffect(() => {
    if (cardTransactionId) {
      setSelectedTransactionId(cardTransactionId);
      setIsMinimize(false);
    }
  }, [cardTransactionId]);

  useEffect(() => {
    if (isNeedApprovalLimit) {
      setIsToSuccess(false);
      const adjustLimitTaskId = get_approval_tasks.filter(
        ({ action, state }) =>
          action === "request_adjust_limit" && state === "requested"
      )[0]?.id;
      return setTaskId(adjustLimitTaskId);
    }
    const approvalTaskId = get_approval_tasks && get_approval_tasks[0]?.id;
    setTaskId(approvalTaskId);
  }, [dataDetailReal]);

  const defaultMutationAfterSuccess = (res) => {
    setDataDetailLatest({});
    setDataDetailLatest(res.data.data);
    setIsChangingCardState(true);
    setCardId(null);
  };

  const [freezeOrBlock, setFreezeOrBlock] = useState("");

  const afterSuccessFreezeOrBlock = (res) => {
    defaultMutationAfterSuccess(res);
    handleNavigate("back");
    setFreezeOrBlock("");
  };

  const { mutation: mutationReqFreeze, loading: loadingReqFreeze } =
    useMutation({
      url: `/cards/${cardId}/request_freeze`,
      method: "post",
      afterSuccess: (res) => {
        eventsTracker("card_request_temp_block", { id: cardId });
        afterSuccessFreezeOrBlock(res);
      },
    });

  const { mutation: mutationFreeze, loading: loadingFreeze } = useMutation({
    url: `/cards/virtual_card/${cardId}/freeze`,
    method: "post",
    afterSuccess: (res) => {
      eventsTracker("card_temp_block", { id: cardId });
      afterSuccessFreezeOrBlock(res);
    },
  });

  const { mutation: mutationReqBlock, loading: loadingReqBlock } = useMutation({
    url: `/cards/${cardId}/request_block`,
    method: "post",
    afterSuccess: (res) => {
      eventsTracker("card_request_p_block", { id: cardId });
      afterSuccessFreezeOrBlock(res);
    },
  });

  const { mutation: mutationBlock, loading: loadingBlock } = useMutation({
    url: `/cards/virtual_card/${cardId}/block`,
    method: "post",
    afterSuccess: (res) => {
      eventsTracker("card_p_block", { id: cardId });
      afterSuccessFreezeOrBlock(res);
    },
  });

  const loading =
    loadingReqFreeze || loadingFreeze || loadingReqBlock || loadingBlock;

  const { mutation: mutationReqActivate, loading: loadingActivate } =
    useMutation({
      url: `/cards/${cardId}/request_activate`,
      method: "post",
      afterSuccess: (res) => {
        eventsTracker("card_request_active", { id: cardId });
        defaultMutationAfterSuccess(res);
      },
    });

  const { mutation: mutationReqUnblock, loading: loadingReqUnblock } =
    useMutation({
      url: `/cards/${cardId}/request_unblock`,
      method: "post",
      afterSuccess: (res) => {
        eventsTracker("card_request_unblock", { id: cardId });
        defaultMutationAfterSuccess(res);
      },
    });

  const { mutation: mutationUnblock, loading: loadingUnblock } = useMutation({
    url: `/cards/virtual_card/${cardId}/restore`,
    method: "post",
    afterSuccess: (res) => {
      eventsTracker("card_restore", { id: cardId });
      defaultMutationAfterSuccess(res);
    },
  });

  const { mutation: mutationDelete, loading: loadingDelete } = useMutation({
    url: `/cards/${cardId}/delete`,
    afterSuccess: () => {
      eventsTracker("card_delete_card", { id: cardId });
      setCardId(null);
      setIsChangingCardState(true);
      setActionType("");
      handleNavigate("close");
    },
  });

  const { mutation: mutationNotes, loading: loadingNotes } = useMutation({
    url: `/card_transactions/${selectedTransactionId}/update`,
    afterSuccess: () => {
      eventsTracker("card_edit_transaction_notes", {
        id: selectedTransactionId,
      });
    },
  });

  const { mutation: mutationPhysicalUpdate, loading: loadingPhysicalUpdate } =
    useMutation({
      url: `/cards/physical_card/${cardId}`,
      afterSuccess: (res) => {
        defaultMutationAfterSuccess(res);
        setCurrentScreen("");
      },
      handleError: () => {
        setActionType("");
      },
    });

  const { mutation: mutationVirtualUpdate, loading: loadingVirtualUpdate } =
    useMutation({
      url: `/cards/virtual_card/${cardId}`,
      afterSuccess: (res) => {
        defaultMutationAfterSuccess(res);
        setCurrentScreen("");
      },
      handleError: () => {
        setActionType("");
      },
    });

  const loadingUpdate = loadingPhysicalUpdate || loadingVirtualUpdate;

  const loadingMutation =
    loadingReqFreeze ||
    loadingFreeze ||
    loadingReqBlock ||
    loadingBlock ||
    loadingActivate ||
    loadingReqUnblock ||
    loadingUnblock ||
    loadingDelete;

  useEffect(() => {
    const isSelectTransaction = actionType == "select_transaction";
    const isDelete = actionType == "delete";
    const isRequestUnblock = actionType == "request_unblock";
    const isRequestActive = actionType == "request_active";
    const isRequestTempBlock = actionType == "request_temp_block";
    const isRequestPermBlock = actionType == "request_p_block";
    const isRequestAdjustLimit = actionType == "request_adjust_limit";
    const isRequestEditNickname = actionType == "request_edit_nickname";
    const isRequestEditDescription = actionType == "request_edit_description";

    if (isSelectTransaction) {
      setSelectedTransactionId(id);
      eventsTracker("card_open_card_transaction_detail", { id });
      return;
    }

    if (isDelete) {
      mutationDelete();
    }

    if (isRequestUnblock) {
      if (isPhysical) return mutationReqUnblock();
      mutationUnblock();
    }

    if (isRequestActive) {
      mutationReqActivate();
    }

    if (isRequestTempBlock) {
      setFreezeOrBlock(actionType);
      // if (isPhysical) return mutationReqFreeze();
      // mutationFreeze();
    }

    if (isRequestPermBlock) {
      setFreezeOrBlock(actionType);
      // if (isPhysical) return mutationReqBlock();
      // mutationBlock();
    }

    if (isRequestAdjustLimit) {
      mutationVirtualUpdate(payload);
    }

    if (isRequestEditNickname) {
      mutationVirtualUpdate(payload);
    }

    if (isRequestEditDescription) {
      isPhysical
        ? mutationPhysicalUpdate(payload)
        : mutationVirtualUpdate(payload);
      setActionType("");
    }
    setActionType("");
  }, [actionType]);

  const handleAction = (type, id, payload) => {
    setCardId(id);
    setPayload(payload);
    setActionType(type);
  };

  const handleNavigate = (type) => {
    const isTemporaryBlock = type === "temporary_block";
    const isPermanentBlock = type === "permanent_block";
    const isChangePin = type === "change_pin";
    const isEditLimit = type === "edit_limit";
    const isAdjustLimit = type === "adjust_limit";
    const isReplaceCard = type === "replace_card";
    const isEditNickname = type === "edit_nickname";
    const isEditDescription = type === "edit_description";

    setIsMinimize(true);
    setSelectedTransactionId(null);
    setSelectedTransactionData(null);

    if (type === "back") {
      setCurrentScreen("");
      if (notes !== notesForm && selectedTransactionId) {
        mutationNotes({ notes: notesForm });
      }
      return;
    }
    if (type === "close") {
      setCurrentScreen("");
      if (notes !== notesForm && selectedTransactionId) {
        mutationNotes({ notes: notesForm });
      }
      toggleProps();
      return;
    }

    setCurrentScreen(type);

    if (isEditNickname) {
      eventsTracker("card_open_edit_nickname", { id: cardId });
      return;
    }

    if (isAdjustLimit) {
      eventsTracker("card_open_adjust_limit_virtual", {
        id: cardId,
      });
      return;
    }

    if (isTemporaryBlock) {
      eventsTracker("card_open_temp_block_screen", {
        id: cardId,
      });
      return;
    }
    if (isPermanentBlock) {
      eventsTracker("card_open_p_block_screen", { id: cardId });
      return;
    }
    if (isChangePin) {
      eventsTracker("card_open_change_pin", { id: cardId });
      return;
    }
    if (isEditLimit) {
      eventsTracker("card_open_adjust_limit_physical", { id: cardId });
      return;
    }
    if (isReplaceCard) {
      eventsTracker("card_open_replace_card", { id: cardId });
      return;
    }
    if (isEditDescription) {
      eventsTracker("card_open_edit_description", { id: cardId });
      return;
    }
  };

  const isFreezing = currentScreen == "temporary_block";
  const isBlocking = currentScreen == "permanent_block";
  const isChangingPIN = currentScreen == "change_pin";
  const isEditingLimit = currentScreen == "adjust_limit_physical";
  const isReplacingCard = currentScreen == "replace_card";
  const isAdjustingLimit = currentScreen == "adjust_limit_virtual";
  const isEditingNickname = currentScreen == "edit_nickname";
  const isEditingDescription = currentScreen == "edit_description";

  const stateObj = {
    isBlocking,
    isFreezing,
    isMinimize,
    isChangingPIN,
    isEditingLimit,
    isReplacingCard,
    isAdjustingLimit,
    isEditingNickname,
    isEditingDescription,
  };

  const { isOpen: isOpenPin, toggle: togglePin } = useModalHook();
  const { isOpen: isOpenReject, toggle: toggleReject } = useModalHook();

  const revealHandler = () => {
    eventsTracker("card_reveal_card_detail", { id: dataDetailReal.id });
    togglePin();
  };

  const onSubmitPin = async ({ pin }) => {
    try {
      setLoadingPin(true);
      const url = `/cards/${dataDetailReal?.id}/card_detail?pin=${pin}`;
      const { data } = await apiBusiness.get(url);
      const dataDetail = data?.data;
      setUnmaskedDataDetail(dataDetail);
      setCanRevealData(true);
    } catch (error) {
      errorToasterApi(error);
    } finally {
      setLoadingPin(false);
    }
  };

  const { loading: loadingApprove, mutation: approve } = useMutation({
    url: `/approval_tasks/${taskId}/approve`,
    afterSuccess: (res) => {
      if (isToSuccess)
        push(
          `/history/success-workflow/?transaction_type=card&ids=${
            dataDetail?.originator?.originator_id ||
            dataDetailLatest?.id ||
            dataDetail?.id
          }`
        );
      setIsToSuccess(true);
      setDataDetailLatest(res.data);
      togglePin();
      afterSuccessProps();
    },
  });

  const { loading: loadingReject, mutation: reject } = useMutation({
    url: `/approval_tasks/${taskId}/reject`,
    afterSuccess: (res) => {
      if (isToSuccess)
        push(
          `/history/success-workflow/?transaction_type=card&ids=${
            dataDetail?.originator?.originator_id ||
            dataDetailLatest?.id ||
            dataDetail?.id
          }`
        );
      setIsToSuccess(true);
      setDataDetailLatest(res.data);
      toggleReject();
      afterSuccessProps();
    },
  });

  const onSubmitWorkflow = (values, type) => {
    const { reason: rejection_note, pin } = values || {};
    const isReject = type === "reject";
    const isApprove = type == "approve";

    const value = { rejection_note };

    if (isApprove) return approve({ pin });
    if (isReject) return reject(value);
  };

  const { show } = useGdrivehook();

  const refCardModalHeader = useRef();
  const headerHeight = refCardModalHeader?.current?.clientHeight;

  const headerHeightDecider = () => {
    if (isBlocking || isFreezing) {
      if (isPhysical) return "320px";
      return "290px";
    }
    if (isChangingPIN || isEditingLimit || isReplacingCard) return "215px";
    if (selectedTransactionData) return headerHeight + 40;
    return "195px";
  };

  const { isOpen: isOpenTopup, toggle } = useModalHook();

  useEffect(() => {
    if (!openTopup) return;
    setTimeout(toggle, 200);
  }, [openTopup]);

  return (
    <>
      <RightCardModalAtomic
        isOpen={isOpen}
        toggle={() => handleNavigate("close")}
        hideModal={isOpenPin || isOpenReject || isOpenTopup || !!freezeOrBlock}
        wingComponent={
          !isMinimize ? (
            <CardModalWing
              setIsMinimize={setIsMinimize}
              dataDetail={dataDetailReal}
              handleClick={handleAction}
              setSelectedTransactionId={setSelectedTransactionId}
              mutationNotes={mutationNotes}
              useFormObj={useFormObj}
            />
          ) : null
        }
        headerStyle={{
          height: headerHeightDecider(),
        }}
        header={
          <CardModalHeader
            toggle={toggleProps}
            stateObj={stateObj}
            handleNavigate={handleNavigate}
            dataDetail={dataDetailReal}
            selectedTransactionData={selectedTransactionData}
            canRevealData={canRevealData}
            revealHandler={revealHandler}
            unmaskedDataDetail={unmaskedDataDetail}
            refHeader={refCardModalHeader}
          />
        }
        middle={
          <CardModalMiddle
            setIsMinimize={setIsMinimize}
            isMinimize={isMinimize}
            handleClick={handleAction}
            handleNavigate={handleNavigate}
            stateObj={stateObj}
            dataDetail={dataDetailReal}
            loadingMutation={loadingMutation}
            loadingUpdate={loadingUpdate}
            selectedTransactionData={selectedTransactionData}
            selectedTransactionId={selectedTransactionId}
            refetchTransactionDetail={refetchTransactionDetail}
            loadingRefetchTransactionDetail={loadingRefetchTransactionDetail}
            refetch={refetch}
            toggleReject={toggleReject}
            togglePin={togglePin}
            useFormObj={useFormObj}
            showPreview={(index) => show({ index, files: file_details })}
            mutationNotes={mutationNotes}
            notesForm={notesForm}
            onClickTopup={toggle}
          />
        }
        body={
          <CardModalBody
            stateObj={stateObj}
            dataDetail={dataDetailReal}
            selectedTransactionData={selectedTransactionData}
          />
        }
      />

      <PayrollPinModal
        isOpen={!!freezeOrBlock}
        toggle={() => setFreezeOrBlock("")}
        title="Input your PIN"
        buttonText="Submit"
        loading={loading}
        onSubmit={(payload) => {
          const isRequestTempBlock = freezeOrBlock == "request_temp_block";
          const isRequestPermBlock = freezeOrBlock == "request_p_block";

          if (isRequestTempBlock) {
            if (isPhysical) return mutationReqFreeze(payload);
            mutationFreeze(payload);
          }

          if (isRequestPermBlock) {
            if (isPhysical) return mutationReqBlock(payload);
            mutationBlock(payload);
          }
        }}
      />

      <PayrollPinModal
        isOpen={isOpenPin}
        toggle={togglePin}
        onSubmit={(values) =>
          taskId ? onSubmitWorkflow(values, "approve") : onSubmitPin(values)
        }
        buttonText="Submit"
        title="Input your PIN"
        loading={loadingPin || loadingApprove}
        isEmployeeAllowed={true}
      />
      <PayrollRejectModal
        isOpen={isOpenReject}
        toggle={toggleReject}
        text="rejection"
        onSubmit={(values) => onSubmitWorkflow(values, "reject")}
        loading={loadingReject}
      />
      <TopUpModal
        modal={isOpenTopup}
        toggle={toggle}
        header={<TopupCardHeader data={dataDetailReal} toggle={toggle} />}
        url={`/cards/${dataDetail?.id}/virtual_accounts`}
        noRobot
      />
    </>
  );
};

export default DetailModal;
