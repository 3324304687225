import { useRouter } from "next/router";
import { useMutation } from "../../../../tools/api";
import { useCrossBorderSingleWorkflowApproverLogics } from "../logics";

export const usePaymentMethod = (val = "Choose payment method") => {
  const headerData = {
    type: "",
    icon: false,
    title: (
      <span
        style={{
          position: "absolute",
          left: 20,
          top: 60,
          fontFamily: "TypefezNeueMedium",
        }}
      >
        {val}
      </span>
    ),
  };
  return { headerData };
};

export const useCrossBorderMutation = ({
  data,
  afterApprove,
  afterSuccess,
  toggle,
}) => {
  const { push } = useRouter();
  const { id } = data || {};
  const { taskId } = useCrossBorderSingleWorkflowApproverLogics(data);

  const { loading: loadingApprove, mutation: approve } = useMutation({
    url: `/approval_tasks/${taskId}/approve`,
    afterSuccess: (res) => afterApprove(res),
  });

  const { loading: loadingUpdateSingle, mutation: updateSingle } = useMutation({
    url: `/update_single_transaction/${id}`,
    afterSuccess: (res) => {
      if (afterSuccess) return afterSuccess(res?.data?.data);
      const { id } = res?.data?.data;
      push(
        `/history/success-workflow/?transaction_type=single_cross_border&ids=${id}`
      );
    },
    handleError: () => toggle(),
  });

  const loading = loadingApprove || loadingUpdateSingle;

  return { loading, approve, updateSingle };
};
