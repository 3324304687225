import { useRouter } from "next/router";
import { useContext, useState } from "react";
import { ToasterContext } from "../../ToasterContext";

const pathnameFormatter = (pathname) => {
  const splittedPathname = String(pathname).split("/");
  const pathnameText = "/" + String(splittedPathname[1]);
  return pathnameText;
};

export const usePageRedirectMaster = ({ user, token }) => {
  const { warningToaster } = useContext(ToasterContext);
  const { push, pathname, query } = useRouter();
  const { isFirstpayroll, isCardRequest, isCardPayBills } = query;

  const pageRedirect = (unauthorize) => {
    const localStorageToken = localStorage.getItem("token");
    const unauthorizedUser = !token || !localStorageToken;

    const isLoginPage = pathname === "/login";

    const authorizedUser = localStorageToken && token;

    const isAuthorizedUserAtLogin = authorizedUser && isLoginPage;

    const isAccountDetails = pathname.includes("/account-details");

    if (pathname == "/forgot-password") return;

    if (unauthorizedUser) {
      const loginNotRequired = [
        "/login-demo",
        "/create-password",
        "/kyb/basis",
        "/login",
        "/forgot-password",
        "/register",
      ];
      if (loginNotRequired.includes(pathname)) return;

      unauthorize && unauthorize();
      warningToaster({ msg: "Your session is expired, please login again" });
      return push("/login");
    }

    if (isAccountDetails) return;

    if (isAuthorizedUserAtLogin) {
      if (isFirstpayroll) return push({ pathname: "/payroll", query });
      if (isCardRequest || isCardPayBills)
        return push({ pathname: "/cards", query });
      return push("/home");
    }

    // role redirect
    if (authorizedUser) {
      const { activePathnames = [], buttonBooleans } = user || {};
      const formattedPathname = pathnameFormatter(pathname);

      // not ready yet
      if (!activePathnames.length) return;
      // not ready yet

      // exact precision
      const requiresExactPrecision = [
        "/payroll/create",
        "/payroll/invite",
        "/cards/create",
        "/cards/[id]/edit",
        "/account/topup",
        "/account/withdraw",
        "/account/allocate",
        "/account/reallocate",
        "/account/create-pin",
        "/account/invite-users",
      ];
      if (requiresExactPrecision.includes(pathname)) {
        if (activePathnames.includes(pathname)) return;
        return push("/home");
      }
      // exact precision

      // special case
      const isEditPayroll = pathname == "/payroll/create/[id]";

      if (isEditPayroll) {
        const { canCreatePayroll, canViewPayroll } = buttonBooleans || {};
        const isSuccess = query?.state == "success";
        if (canCreatePayroll) return;
        if (canViewPayroll && isSuccess) return;
        return push("/home");
      }

      const isBalanceHistory = pathname == "/account/[id]";

      if (isBalanceHistory) {
        const { canViewMainBalanceHistory, canViewCardBalanceHistory } =
          buttonBooleans || {};

        const isMainBalance = query?.type == "indonesian_wallet";

        if (isMainBalance) {
          if (canViewMainBalanceHistory) return;
          return "/home";
        }
        if (canViewCardBalanceHistory) return;
        return "/home";
      }
      // special case

      // default case
      if (activePathnames.includes(formattedPathname)) return;
      if (formattedPathname === "/users") return;

      push("/home");
      // default case
    }
    // role redirect
  };

  return { pageRedirect };
};
