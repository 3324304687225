import getState from "../helpers/getState";
import OverdueImage from "../svgs/OverdueImage";
import SuspendedCardImage from "../svgs/SuspendedCardImage";
import SuspendedImage from "../svgs/SuspendedImage";

const SorryImage = ({ state = "" }) => {
  const { isOverdue, isSuspended, isSuspendCard } = getState(state);

  if (isOverdue) return <OverdueImage />;
  if (isSuspended) return <SuspendedImage />;
  if (isSuspendCard) return <SuspendedCardImage />;
  return <SuspendedImage />;
};

export default SorryImage;
