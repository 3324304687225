import { titleCase } from "change-case";
import { useRouter } from "next/router";
import React, { useState } from "react";
import withdrawIcon from "../../assets/images/withdrawal-icon-40.svg";
import { CustomButton } from "../../components/Buttons";
import {
  MiddleWrapper,
  RightCardModalAtomic,
} from "../../components/CardModal";
import { LeftRightList } from "../../components/Lists";
import {
  booleanState,
  LabelDeciderModal,
  StatusLabel,
} from "../../components/Status";
import { GothamMedium } from "../../components/Text";
import { customDateFormatter, formatCurrency } from "../../components/tools";
import { ActivityDetailsHeader } from "./activityModalComponents";
import { CenterendIconLocalTransfer } from "./localDisbursementBatchModal/transactionModal";
import { RecipientMinimalist } from "./transactionList";

export const WithDrawDetailModal = ({ isOpen, toggle, data }) => {
  const {
    reference_number: headerLeft,
    created_at,
    currency,
    amount,
    details,
    status,
    description,
  } = data || {};

  const [middleHeight, setMiddleHeight] = useState(0);

  const totalAmount = `IDR ${formatCurrency(amount)}`;
  const date = customDateFormatter(created_at);

  const { state, id, account_name, account_number, bank_name } = details || {};

  const headerData = {
    type: description,
    icon: false,
    title: totalAmount,
  };

  const recipientData = {
    name: account_name,
    bank: bank_name,
    bankAccount: account_number,
  };

  const leftRightArray = [
    { left: "Date", right: date },
    { left: "Reference", right: headerLeft },
    { spacer: true },
  ];

  const { isWaitingForPayment } = booleanState(state);
  const { push } = useRouter();

  const handlePay = () =>
    push({
      pathname: `/account/topup/${id}`,
      query: { wallet: "indonesian_wallet", type: "top_up" },
    });

  return (
    <RightCardModalAtomic
      isOpen={isOpen}
      header={<ActivityDetailsHeader toggle={toggle} data={headerData} />}
      headerStyle={{ paddingBottom: 48 }}
      middleHeight={middleHeight}
      middle={
        <MiddleWrapper setHeight={setMiddleHeight} height={middleHeight}>
          <CenterendIconLocalTransfer icon={withdrawIcon} />
        </MiddleWrapper>
      }
      paddingTop={10}
      body={
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <StatusLabel status={status} />
            {isWaitingForPayment && (
              <CustomButton
                className="w-100"
                style={{ marginBottom: 24 }}
                onClick={handlePay}
              >
                Pay {totalAmount}
              </CustomButton>
            )}
          </div>
          <div style={{ marginTop: 24 }}>
            <LeftRightList array={leftRightArray} />
          </div>
          <GothamMedium style={{ fontSize: 16, marginTop: 24 }}>
            Recipient
          </GothamMedium>
          <RecipientMinimalist data={recipientData} />
        </>
      }
    />
  );
};
