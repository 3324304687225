import { useState, useEffect } from "react";
import Skeleton from "react-loading-skeleton";

export const LoadingCard = ({ src, style, width, height }) => {
  const [isImageOnLoad, setIsImageOnLoad] = useState(true);
  const [isShimmer, setIsShimmer] = useState(false);

  useEffect(() => {
    if (!isImageOnLoad) setIsShimmer(true);
  }, [isImageOnLoad]);

  useEffect(() => {
    if (isShimmer) setTimeout(() => setIsShimmer(false), 100);
  }, [isShimmer]);

  return (
    <>
      {(isImageOnLoad || isShimmer) && (
        <Skeleton width={width} height={height} />
      )}
      <img
        src={src}
        style={{ display: "none" }}
        onLoad={() => setIsImageOnLoad(false)}
      />
      {!isShimmer && <img src={src} style={{ ...style, width, height }} />}
    </>
  );
};
