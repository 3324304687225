import { parseISO } from "date-fns";
import {
  customDateFormatter,
  formatCurrencyNoDecimal,
} from "../../../components/tools";

export const dateFormatStripDMY = (dateRaw) => {
  const date = dateRaw || "";
  if (!date) return date;
  const isoDate = new Date(date).toISOString();
  const splittedDate = isoDate.split("-");
  const year = splittedDate[0] || "";
  const month = splittedDate[1] || "";
  const dirtyDay = splittedDate[2] || "";
  const day = dirtyDay.split("T")[0] || "";

  return `${day}/${month}/${year}`;
};

export const invoiceFormatter = (array) =>
  array.map((item) => {
    const {
      state: status,
      invoice_file,
      invoice_number: invoiceNumber,
      recipient_name: billTo,
      due_date,
      issued_date,
      total_amount,
      description,
      thumbnail_invoice,
      created_at,
      drafted_at,
    } = item || {};
    const createdDate = customDateFormatter(created_at, false, true);
    const dueDate = due_date ? customDateFormatter(due_date, false, true) : "-";
    const draftedAt = drafted_at
      ? customDateFormatter(drafted_at, true, true)
      : "-";
    const issuedDate = issued_date
      ? customDateFormatter(issued_date, false, true)
      : "-";
    const createdDateShort = created_at ? dateFormatStripDMY(created_at) : "-";
    const dueDateShort = due_date ? dateFormatStripDMY(due_date) : "-";
    const draftedAtShort = draftedAt ? dateFormatStripDMY(drafted_at) : "-";
    const getUrl = (url) => {
      if (!url) return "";
      return `${process.env.NEXT_PUBLIC_TRANSFEZ_BUSINESS_WO_VERSION_URL}${url}`;
    };
    const pdf = getUrl(thumbnail_invoice || invoice_file);
    const file_url = pdf;
    const totalAmount = `IDR ${formatCurrencyNoDecimal(total_amount)}`;
    return {
      ...item,
      type: "invoice_payment",
      status,
      pdf,
      file_url,
      invoiceNumber,
      billTo,
      dueDate,
      issuedDate,
      totalAmount,
      description,
      createdDate,
      draftedAt,
      createdDateShort,
      dueDateShort,
      draftedAtShort,
    };
  });

export const dateToIsoDateFormatter = (date) => {
  if (!date) return;
  const isoDate = new Date(date);
  return isoDate ? parseISO(isoDate?.toISOString()) : null;
};

export const attachmentDataFormatter = (array) => {
  return array?.map((file) => {
    const { blob } = file;
    const { content_type: type, filename: name, byte_size: size } = blob;
    return { ...file, type, name, size };
  });
};

export const pluralize = (count, noun, suffix = "s") => {
  return `${noun}${count !== 1 ? suffix : ""}`;
};

export const detailFormatterInvoiceReimbursement = (transactionDetail) => {
  if (!transactionDetail) return [];
  const {
    invoice_number,
    created_at,
    amount,
    total_amount,
    state,
    rejected_by_id,
    merchant,
  } = transactionDetail;

  const isInvoice = Boolean(invoice_number);
  const statusFinal = () => {
    if (state === "cancelled") {
      if (Boolean(rejected_by_id)) return "rejected";
      return "cancelled";
    }
    if (state === "completed") return "success";
    return "in_progress";
  };
  const detail = [
    {
      category: isInvoice ? "invoice" : "reimbursement",
      leftBottom: customDateFormatter(created_at),
      leftStatus: statusFinal(),
      leftTop: isInvoice
        ? `Invoice Payment ${invoice_number}`
        : `Reimbursement ${merchant}`,
      rightTop: `IDR ${formatCurrencyNoDecimal(
        isInvoice ? total_amount : amount
      )}`,
    },
  ];

  return detail;
};
