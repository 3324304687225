const getState = (state = "") => {
  const isOverdue = state === "overdue";
  const isSuspended = state === "suspended";
  const isSuspendCard = state === "suspended_card";
  const isSuspendTransaction = state === "suspended_transaction";

  return {
    isOverdue,
    isSuspended,
    isSuspendCard,
    isSuspendTransaction,
  };
};

export default getState;
