import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { CustomButton } from "../../../components/Buttons";
import {
  MiddleWrapper,
  RightCardModalAtomic,
} from "../../../components/CardModal";
import {
  CustomTextField,
  FileInputDottedThumbnail,
  MinimalistTextField,
  SelectionForm,
} from "../../../components/inputs";
import {
  GothamMedium,
  GothamRegular,
  TypeFezNeueMedium,
  TypeFezNeueRegular,
} from "../../../components/Text";
import {
  applyRegexOnlyNumbers,
  formatCurrencyNoDecimal,
  FullDateFormatter,
  schemaGenerator,
  toFormData,
  unformatCurrencyDecimal,
} from "../../../components/tools";
import { useConstants } from "../../../contexts/ConstantsContext/parent";
import { ActivityDetailsHeader } from "../../../modals/smartActivityModal/activityModalComponents";
import { fetch, useMutation } from "../../../tools/api";
import {
  CardDetailsBank,
  CountDownTopUpModal,
  ProcedureInstruction,
} from "./components";
import { expiresFormatter, getUniqueCode } from "./logic";

export const TopUpManualModal = ({ isOpen, toggle, setAfterSuccessManual }) => {
  const resolver = schemaGenerator({
    objectArr: ["bank_name", "upload_file"],
    stringArr: [
      {
        name: "account_name",
        yup: yup.string().required().max(50, "Maximum 50 characters allowed"),
      },
      {
        name: "account_number",
        yup: yup.string().required().max(20, "Maximum 20 characters allowed"),
      },
    ],
  });

  const { localTransferBanks } = useConstants();
  const useFormObj = useForm({ resolver });
  const { watch, handleSubmit, setValue, reset } = useFormObj;
  const watchAccountNumber = watch("account_number");

  const handleToggle = () => {
    toggle();
    reset();
  };

  const afterSuccess = (val) => {
    const { data } = val?.data || {};
    const { id } = data || {};
    const newVal = {
      ...data,
      isSuccessModal: true,
      category: "topup_request",
      originator_id: id,
    };
    setAfterSuccessManual(newVal);
    reset();
  };

  const { mutation, loading } = useMutation({
    method: "post",
    url: "/topups",
    afterSuccess,
  });

  const onSubmit = (val) => {
    const { upload_file, bank_name, ...rest } = val || {};
    const data = {
      files: upload_file?.file,
      bank_name: bank_name?.label,
      ...rest,
    };
    const result = toFormData(data);
    mutation(result);
  };

  useEffect(() => {
    const newValue = applyRegexOnlyNumbers(watchAccountNumber || "");
    setValue("account_number", newValue);
  }, [watchAccountNumber]);

  const headerData = {
    title: (
      <div style={{ display: "relative", height: 80 }}>
        <GothamMedium style={{ fontSize: 24, marginBottom: 12 }}>
          Submit Proof of Payment
        </GothamMedium>
        <GothamRegular style={{ color: "white" }}>
          Please provide your source of payment and proof of payment for
          verification purposes.
        </GothamRegular>
      </div>
    ),
    icon: "",
  };

  return (
    <RightCardModalAtomic
      isOpen={isOpen}
      header={
        <>
          <ActivityDetailsHeader
            toggle={handleToggle}
            data={headerData}
            isClose={false}
          />
        </>
      }
      middleHeight={10}
      body={
        <div>
          <SelectionForm
            label="Bank Name"
            name="bank_name"
            useFormObj={useFormObj}
            options={localTransferBanks}
            valueListener
          />
          <CustomTextField
            name="account_number"
            label="Bank Account Number"
            useFormObj={useFormObj}
          />
          <CustomTextField
            name="account_name"
            label="Bank Account Name"
            useFormObj={useFormObj}
          />
          <FileInputDottedThumbnail
            useFormObj={useFormObj}
            fileTypes={["application/pdf", "image/jpeg"]}
            name="upload_file"
            maxSize={10000000}
            multiple
          />
          <CustomButton
            style={{ height: 40, width: "100%", marginTop: 12 }}
            onClick={handleSubmit(onSubmit)}
            isLoading={loading}
          >
            Submit Proof of Payment
          </CustomButton>
        </div>
      }
    />
  );
};

export const InputAmountModal = ({ isOpen, toggle, setAfterSuccess }) => {
  const [amount, setAmount] = useState("10,000");
  const [isError, setIsError] = useState(false);

  const cleanAmount = Number(unformatCurrencyDecimal(amount));
  const isBelowMinimum = cleanAmount < 10000;
  const errorMsg = "Minimum amount: IDR 10,000";

  const afterSuccess = (val) => {
    setAfterSuccess(val?.data);
  };

  const { mutation, loading } = useMutation({
    method: "post",
    url: "/topups",
    afterSuccess,
  });

  const handleSubmit = () => {
    const result = {
      amount: cleanAmount,
      inhouse_bank_code: "bca",
    };
    mutation(result);
  };

  const handleChange = (val) => {
    setAmount(formatCurrencyNoDecimal(val));
  };

  const handleClose = () => {
    toggle();
    setAmount("10,000");
  };

  useEffect(() => {
    if (isBelowMinimum) {
      return setIsError(true);
    }
    setIsError(false);
  }, [cleanAmount]);

  const headerData = {
    title: (
      <div style={{ display: "relative", marginTop: -12 }}>
        <TypeFezNeueMedium
          style={{
            position: "absolute",
            left: 20,
            fontSize: 24,
            textAlign: "left",
            color: "white",
          }}
        >
          Input top up amount
        </TypeFezNeueMedium>
      </div>
    ),
    icon: "",
  };

  return (
    <RightCardModalAtomic
      isOpen={isOpen}
      headerStyle={{ paddingBottom: 60 }}
      header={
        <>
          <ActivityDetailsHeader
            toggle={handleClose}
            data={headerData}
            isClose={false}
          />
        </>
      }
      body={
        <div style={{ marginTop: "-32px" }}>
          <div
            style={{
              backgroundColor: "white",
              padding: 16,
              paddingBottom: isError ? 26 : 16,
              boxShadow: "0px 4px 8px rgba(88, 88, 88, 0.06)",
              borderRadius: 12,
            }}
          >
            <MinimalistTextField
              name="amount"
              label="Amount"
              error={isError}
              errorMsg={errorMsg}
              isNumber={true}
              prefix="IDR"
              required="*"
              value={amount}
              setValue={handleChange}
            />
          </div>
          <CustomButton
            style={{ height: 40, width: "100%", marginTop: 12 }}
            onClick={handleSubmit}
            isLoading={loading}
            disabledGrey={isBelowMinimum}
          >
            Continue
          </CustomButton>
        </div>
      }
    />
  );
};

export const TopUpPaymentInstructionModal = ({
  data: dataFetch,
  isOpen,
  toggle,
  paid,
  onClickPaid,
  setAfterSuccessManual,
}) => {
  const [middleHeight, setMiddleHeight] = useState(0);

  const { details, data } = dataFetch || {};
  const { b2b_expiry_date } = data || {};
  const { expires_in, created_at, amount_with_code, amount, unique_code } =
    details || {};

  const expiredIn = FullDateFormatter(b2b_expiry_date || "");

  const dataForCountDown = {
    amount_with_code,
    unique_code,
    expiredIn,
    amount,
  };

  const time = expiresFormatter(b2b_expiry_date);

  const headerData = {
    title: (
      <div style={{ display: "relative", marginTop: -12 }}>
        <TypeFezNeueMedium
          style={{
            position: "absolute",
            left: 20,
            fontSize: 24,
            textAlign: "left",
            color: "white",
          }}
        >
          Payment Instruction
        </TypeFezNeueMedium>
      </div>
    ),
    icon: "",
  };

  return (
    <RightCardModalAtomic
      isOpen={isOpen}
      headerStyle={{ paddingBottom: 80 }}
      header={
        <>
          <ActivityDetailsHeader
            toggle={toggle}
            data={headerData}
            isClose={false}
          />
        </>
      }
      middleHeight={middleHeight}
      middle={
        <MiddleWrapper height={middleHeight} setHeight={setMiddleHeight}>
          <CountDownTopUpModal time={time} data={dataForCountDown} />
        </MiddleWrapper>
      }
      body={
        <div>
          <CardDetailsBank data={dataForCountDown} />
          {paid && (
            <CustomButton
              style={{
                height: 40,
                width: "100%",
                marginBottom: 24,
                marginTop: 24,
              }}
              onClick={onClickPaid}
            >
              I Have Paid
            </CustomButton>
          )}
          <ProcedureInstruction />
        </div>
      }
    />
  );
};
