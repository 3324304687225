import { useRouter } from "next/router";
import React from "react";
import { colors } from "../assets/colors";
import { GothamBold, GothamMedium, GothamRegular } from "../components/Text";
import coinIcon from "../assets/images/transaction-details-icon.svg";
import profileIcon from "../assets/images/profile-icon-noshadow.svg";
import cardIcon from "../assets/images/card-icon-noshadow.svg";
import equalsIcon from "../assets/images/equals-icon.svg";
import divideIcon from "../assets/images/divide-icon.svg";
import minusIcon from "../assets/images/minus-icon.svg";
import { formatCurrency, formatCurrencyNoDecimal } from "../components/tools";
import { universeFunc } from "../components/RecipientDetailsModal";
import { eventsTracker } from "../universalFunctions/events";
import {
  CrossBorderAlert,
  CrossBorderTrxDetails,
} from "./crossBorderSingleIndex";
import { UniverseDataGenerator } from "./crossBorder/universeData";

const HeaderPerSection = ({ title, change, icon, iconStyle }) => {
  const { push } = useRouter();

  const isRecipient = change == "recipient";
  const isPayment = change == "payment";

  const propsDecider = () => {
    if (isRecipient)
      return {
        url: "/cross-border/single/recipient",
        name: "change_recipient",
      };
    if (isPayment)
      return {
        url: "/cross-border/single/payment",
        name: "change_payment_method",
      };
    return { url: "/cross-border/single", name: "reset_transaction" };
  };
  const handleClick = () => {
    const { url, name } = propsDecider();
    eventsTracker("cross_border_single_confirmation_change", { name });
    push(url);
  };

  return (
    <div className="d-flex justify-content-between mb-3">
      <div className="d-flex align-items-center">
        <img src={icon} style={iconStyle} />
        <GothamMedium style={{ fontSize: 16, marginBottom: -2, marginLeft: 8 }}>
          {title}
        </GothamMedium>
      </div>
      <GothamMedium
        style={{ color: colors.pink, cursor: "pointer" }}
        onClick={handleClick}
      >
        Change
      </GothamMedium>
    </div>
  );
};

export const useAlertDecider = ({ data = "" }) => {
  const isLocal = data === "local";
  const isSwiftSha = data === "swift_sha";
  const isSwiftOur = data === "swift_our";
  if (isSwiftSha) return "SWIFT SHA";
  if (isSwiftOur) return "SWIFT OUR";
  return "Local Bank Network";
};

export const DetailsBold = ({ title = "", value = "", withDash }) => {
  return (
    <div
      className="d-flex justify-content-between mb-2"
      style={{
        borderTop: withDash && "2px dashed #BBBBC0",
        paddingTop: withDash && 12,
      }}
    >
      <GothamMedium>{title}</GothamMedium>
      <GothamMedium>{value}</GothamMedium>
    </div>
  );
};

export const DetailsLeftRight = ({ data }) => {
  const { left, right } = data || {};
  const style = {
    color: colors.grey6c,
  };
  return (
    <div className="d-flex justify-content-between mb-2">
      <GothamRegular style={style}>{left}</GothamRegular>
      <GothamRegular style={style}>{right}</GothamRegular>
    </div>
  );
};

export const TransactionDetails = ({ data }) => {
  const {
    source,
    source_currency,
    destination,
    destination_currency,
    payment_channel,
    confirmation_pages = [],
    bankFee,
  } = data || {};

  const bankFeeText = `${source_currency} ${formatCurrency(bankFee)}`;
  const deliveryMethod = useAlertDecider({ data: payment_channel });
  const deliveryMethodObj = {
    left: "Delivery Method",
    right: deliveryMethod,
  };
  // const TrxDetailsArray = [deliveryMethodObj, ...confirmation_pages];
  const TrxDetailsArray = [...confirmation_pages];

  const payText = `${source_currency} ${formatCurrency(source)}`;
  const recipientGetsText = `${destination_currency} ${formatCurrency(
    destination
  )}`;

  return (
    <div>
      <HeaderPerSection
        title="Transaction Details"
        icon={coinIcon}
        change="calculator"
        iconStyle={{ width: 20, height: 20 }}
      />
      <DetailsBold title="You Pay" value={payText} />
      <DetailsLeftRight data={deliveryMethodObj} />
      <CrossBorderTrxDetails data={TrxDetailsArray} isConfirmation />
      <DetailsBold title="Recipient Gets" value={recipientGetsText} withDash />
      {/* <CrossBorderAlert data={deliveryMethod} bankFee={bankFeeText}/> */}
    </div>
  );
};

export const RecipientDetails = ({ data }) => {
  const dataDetails = UniverseDataGenerator({ data });
  return (
    <div>
      <HeaderPerSection
        title="Recipient Details"
        icon={profileIcon}
        change="recipient"
      />
      {dataDetails.map(({ title, value }, index) => {
        return (
          <div key={index}>
            <GothamRegular
              style={{
                color: colors.grey72,
                fontSize: 10,
                marginBottom: 0,
                lineHeight: "16px",
              }}
            >
              {title}
            </GothamRegular>
            <GothamMedium style={{ marginBottom: 12, lineHeight: "22px" }}>
              {value}
            </GothamMedium>
          </div>
        );
      })}
    </div>
  );
};

export const PaymentSummary = ({ data }) => {
  const { source_currency, balance, source, remaining_balance, isWallet } =
    data;
  const isIdr = source_currency == "IDR";
  const paymentMethod = isWallet ? "From Balance" : "Transfer to Bank";

  const formattedBalance = isIdr
    ? formatCurrencyNoDecimal(balance)
    : formatCurrency(balance);
  const formattedSourceAmount = isIdr
    ? formatCurrencyNoDecimal(source)
    : formatCurrency(source);
  const formattedRemainingBalance = isIdr
    ? formatCurrencyNoDecimal(remaining_balance)
    : formatCurrency(remaining_balance);
  const dataDetails = [
    {
      title: "Payment Method",
      value: paymentMethod,
    },
    ...(isWallet
      ? [
          {
            title: "Available Balance",
            value: `${source_currency} ${formattedBalance}`,
          },
        ]
      : []),
    {
      title: "Amount to Pay",
      value: `${source_currency} ${formattedSourceAmount}`,
    },
    ...(isWallet
      ? [
          {
            title: "Remaining Balance",
            value: `${source_currency} ${formattedRemainingBalance}`,
          },
        ]
      : []),
  ];
  return (
    <div>
      <HeaderPerSection
        title="Payment Summary"
        icon={cardIcon}
        change="payment"
      />
      {dataDetails.map(({ title, value }, index) => {
        const isLast = dataDetails.length - 1 == index;
        const borderLast = isLast ? `1px dashed #C2C2C2` : `none`;
        return (
          <div key={index}>
            {isLast && isWallet && <hr style={{ border: borderLast }} />}
            <div
              className="d-flex justify-content-between"
              style={{ marginBottom: 12 }}
            >
              <GothamRegular
                style={{
                  color: colors.grey72,
                  marginBottom: 0,
                  lineHeight: "22px",
                }}
              >
                {title}
              </GothamRegular>
              <GothamMedium style={{ marginBottom: 0 }}>{value}</GothamMedium>
            </div>
          </div>
        );
      })}
    </div>
  );
};
