import { useRouter } from "next/router";

export const useUploadReceiptScreenType = () => {
  const { pathname } = useRouter();
  const isTopUp = pathname.includes("/account/topup");
  const isSinglePayment = pathname.includes("/single-payment");
  const isBatchPayment = pathname.includes("/batch-payment");
  const isLocalTransfer = pathname.includes("/local-transfer");

  return { isTopUp, isSinglePayment, isBatchPayment, isLocalTransfer };
};
