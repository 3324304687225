import { useRouter } from "next/router";
import { useGetAuth } from "../../../contexts/AuthContext";
import { useMutation } from "../../../tools/api";
import { accountTitleAndOnClick } from "./account";
import { crossBorderTitleAndOnClick } from "./crossBorder";
import { kybTitleAndOnClick } from "./kyb";
import { paymentInstructionTitleAndOnClick } from "./paymentInstruction";

export const useTitleAndOnClick = () => {
  const router = useRouter();
  const { user } = useGetAuth();
  const { pathname, push, query } = router;
  const { reference_id, state, id } = query;
  const isCrossBorder = pathname.includes("/cross-border");
  const isPaymentInstruction = pathname.includes("/payment-instruction");
  const isApiMenu = pathname.includes("/menu-api");
  const isAccountDetails = pathname.includes("/account-details");
  const isSetting = pathname.includes("/settings");
  const isEditWorkflow = pathname.includes("/users/edit-workflow");
  const isAccount = pathname.includes("/account");
  const isKYB = pathname.includes("/kyb/");
  const isLocalTransferForm = pathname.includes("/local-transfer");
  const isLocalTransferValidation =
    isLocalTransferForm && state == "validation";
  const isLocalTransferConfirmation =
    isLocalTransferForm && pathname.includes("confirmation");
  const isReportDownload = pathname.includes("/report-download");

  const isSuccess = pathname.includes("/success");

  const { mutation: changeState } = useMutation({
    url: `/local_transaction_batches/${id}/change_state`,
  });

  if (isSuccess) return { title: reference_id || "" };

  if (isCrossBorder) return crossBorderTitleAndOnClick(router);

  if (isPaymentInstruction) return paymentInstructionTitleAndOnClick(router);

  if (isApiMenu) return { title: "Developer Tools" };

  if (isAccountDetails) return { title: "Change Password" };

  if (isReportDownload)
    return { title: "Get Report", onClick: () => push("/home") };

  if (isAccount) return accountTitleAndOnClick(router);
  if (isKYB) return kybTitleAndOnClick(router, user);

  if (isLocalTransferConfirmation)
    return {
      title: "Confirmation",
    };

  if (isLocalTransferValidation)
    return {
      title: "Data Validation",
      onClick: async () => {
        await changeState({ state: "draft" });
        push({ query: { id } });
      },
    };

  if (isLocalTransferForm) return { title: "New Local Transfer" };

  if (isSetting) return { title: "Settings" };

  if (isEditWorkflow) return { title: "Edit Workflow" };

  return {};
};
