import React from "react";
import { colors } from "../../../../../assets/colors";
import prepaid from "../../../../../assets/images/prepaid-icon.svg";
import { MainCardHeaderMolecule } from "../../../../../components/CardModal";
import { GothamMedium, GothamRegular } from "../../../../../components/Text";
import { formatCurrencyNoDecimal } from "../../../../../components/tools";

export const TopupCardHeader = ({ toggle, data }) => {
  const { balance, nickname, name, masked_card_number } = data || {};
  const last4Digit = `**${(masked_card_number || "").split("-").pop()}`;

  return (
    <MainCardHeaderMolecule
      toggle={toggle}
      alignLeft
      isClose={false}
      customComponent={
        <>
          <GothamMedium
            style={{
              color: "white",
              marginBottom: 20,
              marginTop: -32 + 20,
            }}
            className="font24"
          >
            Top Up Card Balance
          </GothamMedium>
          <div
            style={{
              backgroundColor: "white",
              padding: 16,
              borderRadius: 12,
            }}
          >
            <div
              className="d-flex"
              style={{
                gap: 8,
                paddingBottom: 16,
                borderBottom: `1px solid ${colors.greye6}`,
                marginBottom: 16,
              }}
            >
              <img src={prepaid} />
              <div>
                <GothamMedium>{name}</GothamMedium>
                <GothamRegular className="font12">
                  {last4Digit} {nickname}
                </GothamRegular>
              </div>
            </div>
            <div className="d-flex justify-content-between">
              <GothamMedium>Card Balance</GothamMedium>
              <GothamRegular>
                IDR {formatCurrencyNoDecimal(balance)}
              </GothamRegular>
            </div>
          </div>
        </>
      }
    />
  );
};
