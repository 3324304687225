import React from "react";
import { ConfirmationModal } from "../../../../components/Buttons";
import { RightCardModalAtomic } from "../../../../components/CardModal";
import { useModalHook } from "../../../../components/Modals";
import { StatusLabel } from "../../../../components/Status";
import { getUserRole } from "../../../../contexts/AuthContext";
import {
  useEWalletApproveAllocate,
  useEWalletRejectAllocate,
} from "../../../../pageComponents/eWalletComponents/home/table/hooks/allocation";
import { StatusPayrollLine } from "../../../../pageComponents/payrollCreateComponents/loop/success/status";
import { ApproveRejectButtons } from "../../crossBorderSingleModal/components";
import { PayrollPinModal } from "../../payroll/pinModal";
import { useSmartEWalletActivityDetails } from "../hooks";
import { useAllocationStatusArray } from "./status";

export const EWalletAllocationModal = ({
  toggle,
  isOpen,
  data,
  isHome: isHomeProps = false,
}) => {
  if (!isOpen) return null;

  // REQUIRED DATA. DONT REMOVE
  const {
    status,
    afterSuccessApproveAllocate, // called in e-wallet's allocation request
    afterSuccessRejectAllocate, // called in e-wallet's allocation request
  } = data || {};
  // REQUIRED DATA. DONT REMOVE

  const propsDecider = () => {
    if (afterSuccessApproveAllocate) return {};
    return { isToActivity: !isHomeProps, isHome: isHomeProps };
  };

  const { isHome, isToActivity } = propsDecider();

  const { loading: loadingApproveWallet, mutation: approveWallet } =
    useEWalletApproveAllocate(
      afterSuccessApproveAllocate,
      isToActivity,
      isHome
    );

  const { loading: loadingRejectWallet, mutation: rejectWallet } =
    useEWalletRejectAllocate(afterSuccessRejectAllocate, isToActivity, isHome);

  const loading = loadingApproveWallet || loadingRejectWallet;

  const { data: details, defaultProps } = useSmartEWalletActivityDetails({
    data,
    toggle,
    noStatus: true,
  });

  const { state, id } = details || {};

  const { isOpen: isOpenPin, toggle: togglePin } = useModalHook();
  const { isOpen: isOpenDelete, toggle: toggleDelete } = useModalHook();
  const { isAdminOrSuperAdmin } = getUserRole();
  const isWaitingForApproval = state == "waiting_for_approval";

  const array = useAllocationStatusArray({ details: { ...details, status } });

  const { defaultBody, ...props } = defaultProps(
    "Balance allocation to Team wallet"
  );

  return (
    <RightCardModalAtomic
      isOpen={isOpen}
      {...props}
      body={
        <>
          <div className="d-flex justify-content-center">
            <StatusLabel status={status} />
          </div>
          {isWaitingForApproval && isAdminOrSuperAdmin && (
            <ApproveRejectButtons
              onClickLeft={toggleDelete}
              onClickRight={togglePin}
            >
              <PayrollPinModal
                loading={loading}
                isOpen={isOpenPin}
                toggle={togglePin}
                onSubmit={({ pin }) => approveWallet(id, pin)}
                title="Input your PIN to allocate"
                buttonText="Submit"
              />
              <ConfirmationModal
                modal={isOpenDelete}
                toggle={toggleDelete}
                text="Are you sure you want to reject allocation request?"
                onClick={() => rejectWallet(id)}
              />
            </ApproveRejectButtons>
          )}
          {defaultBody()}
          <StatusPayrollLine array={array} isModal />
        </>
      }
    />
  );
};
