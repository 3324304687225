import { useEffect } from "react";

export const useClickOutside = ({ ref, clickOutside }) => {
  const { current } = ref || {};

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!current) return;
      if (current.contains(event.target)) return;

      clickOutside();
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [current]);
};
