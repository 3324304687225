import { useRouter } from "next/router";
import { CenteredModal } from "../../../modals/centeredComponents";
import { CustomButton } from "../../Buttons";
import { CloseHeader } from "../../CardModal";
import { GothamMedium, GothamRegular } from "../../Text";
import SorryImage from "./components/SorryImage";
import styles from "./SuspendModal.module.css";
import titleTextDecider from "./helpers/titleTextDecider";
import getState from "./helpers/getState";
import descriptionTextDecider from "./helpers/descriptionTextDecider";
import { eventsTracker } from "../../../universalFunctions/events";

const SuspendModal = ({
  isOpen = false,
  state = "suspended",
  toggle = () => {},
}) => {
  const { push } = useRouter();

  const { isOverdue, isSuspended, isSuspendTransaction, isSuspendCard } =
    getState(state);

  const handleClickOkay = () => {
    eventsTracker("suspended_modal_okay_button");
    toggle();
  };

  const handleExplorePlan = () => {
    eventsTracker("see_billing_from_overdue_modal");
    push({ pathname: "/settings", query: { page: "billing" } });
  };

  const handleDoLater = () => {
    eventsTracker("overdue_modal_do_later_button");
    toggle();
  };

  const titleText = titleTextDecider(state);
  const descriptionText = descriptionTextDecider(state);

  const showDescription = isSuspended || isSuspendTransaction || isSuspendCard;
  const showOkayButton = showDescription;

  return (
    <CenteredModal isOpen={isOpen} toggle={toggle}>
      {isOverdue && (
        <div className={styles["close-btn"]}>
          <CloseHeader isMedium color="#DA649F" toggle={toggle} />
        </div>
      )}
      <div className={styles["container"]}>
        <GothamMedium className={styles["top-font"]}>{titleText}</GothamMedium>
        {showDescription && (
          <GothamRegular woFontColor className={styles["middle-font"]}>
            {descriptionText}
          </GothamRegular>
        )}
        {isOverdue && (
          <div
            style={{
              textAlign: "center",
              paddingLeft: "8px",
              paddingRight: "8px",
              marginTop: "8px",
            }}
          >
            <GothamRegular
              woFontColor
              className={styles["middle-font"]}
              style={{ display: "inline" }}
            >
              {descriptionText[0]}
            </GothamRegular>
            <GothamMedium
              className={styles["middle-font"]}
              style={{ display: "inline" }}
            >
              {descriptionText[1]}
            </GothamMedium>
            <GothamRegular
              woFontColor
              className={styles["middle-font"]}
              style={{ display: "inline" }}
            >
              {descriptionText[2]}
            </GothamRegular>
          </div>
        )}
        <SorryImage state={state} />
        {showOkayButton && (
          <CustomButton
            woHeight
            woClassName
            className={styles.button}
            onClick={handleClickOkay}
          >
            <GothamMedium className={styles["button-text"]}>Okay</GothamMedium>
          </CustomButton>
        )}
        {isOverdue && (
          <>
            <CustomButton
              woHeight
              woClassName
              className={styles.button}
              onClick={handleExplorePlan}
            >
              <GothamMedium className={styles["button-text"]}>
                Go to Billing Page
              </GothamMedium>
            </CustomButton>
            <CustomButton
              woHeight
              woClassName
              className={styles["button-2"]}
              onClick={handleDoLater}
            >
              <GothamMedium className={styles["button-text-2"]}>
                I'll do it later
              </GothamMedium>
            </CustomButton>
          </>
        )}
      </div>
    </CenteredModal>
  );
};

export default SuspendModal;
