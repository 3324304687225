import React from "react";
import { RightCardModalAtomic } from "../../../../components/CardModal";
import { StatusPayrollLine } from "../../../../pageComponents/payrollCreateComponents/loop/success/status";
import { useSmartEWalletActivityDetails } from "../hooks";
import { useReturnStatusArray } from "./status";

export const EWalletReturnModal = ({ toggle, isOpen, data }) => {
  const { data: details, defaultProps } = useSmartEWalletActivityDetails({
    data,
    toggle,
  });
  const { defaultBody, ...props } = defaultProps(
    "Return balance by Team wallet"
  );

  const { status } = data || {};

  const array = useReturnStatusArray({ details: { ...details, status } });

  return (
    <RightCardModalAtomic
      isOpen={isOpen}
      {...props}
      body={
        <>
          {defaultBody()}
          <StatusPayrollLine array={array} isModal />
        </>
      }
    />
  );
};
