import moment from "moment";
import { useRouter } from "next/router";
import { useState } from "react";
import { useEffect } from "react";
import { useGetAuth } from "../../../contexts/AuthContext";
import { CenteredModal } from "../../../modals/centeredComponents";
import { useMutation } from "../../../tools/api";
import useSubscriptionBillings from "../../../tools/hooks/useSubscriptionBilings";
import useSubscriptionUsage from "../../../tools/hooks/useSubscriptionUsage";
import { CustomButton } from "../../Buttons";
import { CloseHeader } from "../../CardModal";
import { GothamMedium, GothamRegular } from "../../Text";
import styles from "./SubscriptionNotificationModal.module.css";
import DowngradeImage from "./svgs/DowngradeImage";
import UpgradedImage from "./svgs/UpgradedImage";

const SubscriptionNotificationModal = ({
  isOpen = false,
  toggle = () => {},
}) => {
  const [dueDateFetched, setDueDateFetched] = useState(null);

  const { user, refetchUser } = useGetAuth();
  const { subs_notif_is_read } = user || {};
  const { push } = useRouter();

  const isUpgrade = subs_notif_is_read === "upgrade";
  const isDowngrade = subs_notif_is_read === "downgrade";

  const { dueDate, loadingSubscriptionUsage } = useSubscriptionUsage();

  useEffect(() => {
    if (dueDate && !loadingSubscriptionUsage) setDueDateFetched(dueDate);
  }, [dueDate, loadingSubscriptionUsage]);

  const { mutation } = useMutation({
    url: "/business_users/subs_notification",
    afterSuccess: () => {
      refetchUser();
    },
  });

  const handleClick = () => {
    if (isDowngrade) {
      push({ pathname: "/settings", query: { page: "subscription" } });
    }

    toggle();
    mutation();
  };

  const handleClose = () => {
    toggle();
    mutation();
  };

  const title = () => {
    if (isUpgrade) {
      return (
        <GothamMedium woClassName className={styles["top-font"]}>
          Thank you for upgrading your plan!
        </GothamMedium>
      );
    }

    if (isDowngrade) {
      return (
        <GothamMedium woClassName className={styles["top-font"]}>
          We are sorry to see you go
        </GothamMedium>
      );
    }

    return null;
  };

  const text = () => {
    if (isUpgrade) {
      if (!dueDateFetched) {
        return (
          <GothamRegular woFontColor className={styles["middle-font"]}>
            Now you can enjoy the privileges of our Growth Plan member.
          </GothamRegular>
        );
      }

      return (
        <GothamRegular woFontColor className={styles["middle-font"]}>
          Now you can enjoy the privileges of our Growth Plan member. Your plan
          renewal will be on{" "}
          <span style={{ fontFamily: "GothamMedium" }}>{dueDateFetched}.</span>
        </GothamRegular>
      );
    }

    if (isDowngrade) {
      return (
        <GothamRegular woFontColor className={styles["middle-font"]}>
          By downgrading your account, you can no longer enjoy Growth Plan
          benefits. Kindly check the Seed and Growth Plan comparison here.
        </GothamRegular>
      );
    }

    return null;
  };

  const buttonText = () => {
    if (isUpgrade) {
      return (
        <GothamMedium className={styles["button-font"]}>Okay</GothamMedium>
      );
    }

    if (isDowngrade) {
      return (
        <GothamMedium className={styles["button-font"]}>
          Compare Plan
        </GothamMedium>
      );
    }

    return null;
  };

  const image = () => {
    if (isUpgrade) {
      return <UpgradedImage />;
    }

    if (isDowngrade) {
      return <DowngradeImage />;
    }

    return null;
  };

  return (
    <CenteredModal isOpen={isOpen}>
      <div className={styles["close-btn"]}>
        <CloseHeader isMedium color="#DA649F" toggle={handleClose} />
      </div>
      <div className={styles["container"]}>
        {title()}
        {text()}
        {image()}
        <CustomButton
          woHeight
          className={styles.button}
          // isLoading={loadingUpgrade || loadingDowngrade}
          onClick={handleClick}
        >
          {buttonText()}
        </CustomButton>
        {isUpgrade && (
          <div style={{ width: "100%", marginTop: "8px" }}>
            <GothamRegular woFontColor className={styles.terms}>
              Have any questions? Feel free to contact your account manager
              anytime.
            </GothamRegular>
          </div>
        )}
      </div>
    </CenteredModal>
  );
};

export default SubscriptionNotificationModal;
