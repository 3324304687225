import Head from "next/head";
import { useRouter } from "next/router";
import React, { useContext, useRef } from "react";
import "react-toastify/dist/ReactToastify.css";
import { Nav, Navbar, NavbarBrand } from "reactstrap";
import titleCase from "title-case";
import { colors } from "../assets/colors";
import downIcon from "../assets/images/caret-down.svg";
import devIcon from "../assets/images/dev-mode.svg";
import logout from "../assets/images/logout-icon.svg";
import settings from "../assets/images/settings.svg";
import transfezLogo from "../assets/images/transfez-logo.svg";
import userIcon from "../assets/images/user-logo-small.svg";
import usersPinkIcon from "../assets/images/users-pink.svg";
import workflowPinkIcon from "../assets/images/workflow-pink.svg";
import customReceiptIcon from "../assets/images/custom-receipt.svg";
import Sidebar from "../components/Sidebar";
import {
  AuthContext,
  getUserButtonBooleans,
  getUserRole,
  useGetAuth,
} from "../contexts/AuthContext";
import { InfiniteScrollDeciderProvider } from "../contexts/InfiniteScrollContext";
import { eventsTracker } from "../universalFunctions/events";
import { useClickOutside } from "../universalFunctions/useClickOutside";
import { BurgerNavbar } from "./BurgerNavbar/parent";
import { useModalHook } from "./Modals";
import { BlueStatus } from "./Status";
import { GothamMedium, GothamRegular } from "./Text";
import { windowDimension } from "./tools";

export const getPropsMainNav = () => {
  const { pathname } = useRouter();

  const hideSideBarAndNavbarArray = [
    "/login",
    "/",
    "/register",
    "/login-demo",
    "/create-password",
    "/kyb/basis",
    "/forgot-password",
  ];
  const hideSideBarAndNavbar = hideSideBarAndNavbarArray.includes(pathname);

  const hideSidebarArray = [
    "/account/create-pin",
    "/account/invite-users",
    "/deactive",
  ];
  const hideSideBar =
    pathname.includes("kyb") || hideSidebarArray.includes(pathname);
  const isDash = pathname == "/";
  const defaultObj = (title) => ({
    title,
    hideSideBarAndNavbar,
    hideSideBar,
  });

  const isCreateCards = pathname.includes("/cards/create");
  const isEditCards = pathname.includes("/cards/[id]/edit");
  const isDeepTransactionDetails = pathname.includes("transactionId");

  if (isCreateCards) return defaultObj("New Card");

  if (isEditCards) return defaultObj("Edit Card");

  if (isDeepTransactionDetails) return defaultObj("Transaction Details");

  if (pathname.includes("[id]")) {
    const safeSplit = pathname.split("/")[1] || "";
    const title = safeSplit.split("-")[0] + " details";
    return defaultObj(titleCase(title));
  }

  if (isDash) {
    return defaultObj("Business Transfez Account");
  }

  return defaultObj(titleCase(pathname));
};

export const useDropdownData = () => {
  const {
    canSeeUsers: canSeeUsersRaw,
    canViewWorkflow,
    canViewDevTools,
    canViewSettings,
    canCustomReceipt,
  } = getUserButtonBooleans();

  const { userLoading } = useGetAuth();

  const { isEmployeeManager, isEmployee, isBookKeeper, isBookKeeperManager } =
    getUserRole();

  const canSeeUsers =
    (canSeeUsersRaw && !(isEmployee || isBookKeeper)) ||
    isEmployeeManager ||
    isBookKeeperManager;

  return [
    canViewSettings
      ? {
          src: <img src={settings} />,
          title: "Settings",
          value: "setting",
        }
      : {},
    canSeeUsers
      ? {
          src: <img src={usersPinkIcon} />,
          title: "User Management",
          value: "user",
        }
      : {},
    canViewWorkflow
      ? {
          src: <img src={workflowPinkIcon} />,
          title: "Workflow",
          value: "approvals",
        }
      : {},
    canCustomReceipt
      ? {
          src: <img src={customReceiptIcon} />,
          title: "Customize Receipt",
          value: "custom_receipt",
        }
      : {},
    canViewDevTools
      ? {
          src: <img src={devIcon} />,
          title: "Developer Tools",
          value: "api",
        }
      : {},
    {
      src: <img src={logout} />,
      title: "Log out",
      value: "logout",
      loading: userLoading,
    },
  ];
};

export const useDropdownHooks = (closeToggle) => {
  const { unauthorize } = useContext(AuthContext);
  const { push } = useRouter();

  const handleClick = (value) => {
    const isSetting = value == "setting";
    const isApiMenu = value == "api";
    const isUser = value == "user";
    const isApprovals = value == "approvals";
    const isCustomReceipt = value == "custom_receipt";

    closeToggle();

    if (isSetting) {
      eventsTracker("navbar_dropdown", { type: "settings" });
      push("/settings");
      return;
    }
    if (isApiMenu) {
      eventsTracker("navbar_dropdown", { type: "developer tools" });
      push("/menu-api");
      return;
    }
    if (isUser) {
      eventsTracker("navbar_dropdown", { type: "user management" });
      push("/users");
      return;
    }
    if (isCustomReceipt) {
      eventsTracker("navbar_dropdown", { type: "customize receipt" });
      push("/customize-receipt");
      return;
    }
    if (isApprovals) {
      eventsTracker("navbar_dropdown", { type: "approvals" });
      push("/workflow");
      return;
    }
    unauthorize();
    eventsTracker("navbar_dropdown", { type: "logout" });
    push("/login");
    return;
  };

  const windowDimensions = windowDimension();
  return { ...windowDimensions, handleClick };
};

const CustomDropDown = ({ close, user }) => {
  // const { email, partner } = user || {};
  // const { document_state = "" } = partner || {};
  const { canViewWorkflow } = getUserButtonBooleans();
  const { figmaHeight, figmaWidth, handleClick } = useDropdownHooks(close);
  const { margin } = windowDimension();
  const ref = useRef();
  useClickOutside({ ref, clickOutside: close });
  const dropdownData = useDropdownData();

  return (
    <div
      ref={ref}
      style={{
        position: "absolute",
        top: 75,
        right: 20 + margin,
        zIndex: 1,
        boxShadow: "0px 8px 20px rgba(88, 88, 88, 0.1)",
        borderRadius: 8,
        backgroundColor: "white",
        padding: 12,
      }}
      className="mr-3 fade-in"
    >
      {/* <div
        style={{
          alignItems: "center",
          borderTopLeftRadius: 8,
          borderTopRightRadius: 8,
          marginBottom: 12,
          border: "1px solid #218391",
          borderColor: "white",
          borderBottomColor: colors.greyea,
        }}
        className="d-flex flex-column justify-content-center"
      >
        <GothamMedium
          style={{ color: colors.grey25, lineHeight: "22px", marginBottom: 4 }}
        >
          {email}
        </GothamMedium>
        {document_state ? (
          <StatusBadge
            state={document_state}
            style={{ height: 20, fontSize: 10, marginBottom: 12 }}
            shortBg
          />
        ) : (
          <div style={{ height: 4 }} />
        )}
      </div> */}
      <div
        style={{
          marginRight: 0,
          borderBottomLeftRadius: 8,
          borderBottomRightRadius: 8,
        }}
      >
        {dropdownData
          .map(({ src, title, value, loading }, index) => {
            const isLast = index == dropdownData.length - 1;
            const isWorkflow = value === "approvals";

            if (!title) return null;
            if (isWorkflow && !canViewWorkflow) return null;

            return (
              <div
                onClick={() => !loading && handleClick(value)}
                key={index}
                style={{
                  borderBottomLeftRadius: isLast ? 8 : 0,
                  borderBottomRightRadius: isLast ? 8 : 0,
                  height: 46,
                  width: figmaWidth(248 - 24),
                }}
                className="d-flex align-items-center p-2 lighthover"
              >
                {src}
                <GothamRegular
                  className="my-0 ml-2"
                  style={{ color: colors.grey72 }}
                >
                  {title}
                </GothamRegular>
              </div>
            );
          })
          .filter((dropdown) => dropdown)}
      </div>
    </div>
  );
};

const CustomNavbar = () => {
  const { figmaWidth, width, is1440 } = windowDimension();
  const { close, isOpen, toggle } = useModalHook();
  const { user } = useContext(AuthContext);
  const { email = "", partner, status } = user || {};
  const { roleName } = getUserRole();
  const padding = is1440 ? (width - 1370) / 2 : 20;
  const partnerName = partner?.name;
  const isBlocked = status === "blocked";

  return (
    <>
      <Navbar
        light
        expand="md"
        fixed="top"
        style={{
          border: "1px solid rgba(194, 194, 194,.5)",
          backgroundColor: "white",
          height: 72,
          paddingLeft: padding,
          paddingRight: padding,
        }}
      >
        <NavbarBrand href={!isBlocked && "/home"}>
          <img src={transfezLogo} />
        </NavbarBrand>
        {!isBlocked && (
          <Nav className="ml-auto" navbar>
            <div className="d-flex align-items-center mr-2">
              <BlueStatus text={roleName} />
            </div>
            <div
              onClick={toggle}
              className="lighthover d-flex align-items-center px-1"
              style={{
                borderRadius: 200,
                height: 42,
              }}
            >
              <img src={userIcon} style={{ width: 32 }} />
              {Boolean(partnerName) ? (
                <div className="ml-2">
                  <GothamMedium
                    className="font12"
                    style={{
                      maxWidth: figmaWidth(248 - 24),
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                    }}
                  >
                    {partner?.name}
                  </GothamMedium>
                  <GothamRegular
                    className="font10"
                    style={{
                      maxWidth: figmaWidth(248 - 24),
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      color: colors.grey72,
                    }}
                  >
                    {email}
                  </GothamRegular>
                </div>
              ) : (
                <GothamMedium
                  className=" ml-2"
                  style={{
                    maxWidth: figmaWidth(248 - 24),
                    color: colors.grey25,
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                  }}
                >
                  {email}
                </GothamMedium>
              )}
              <img src={downIcon} style={{ width: 16, marginLeft: 8 }} />
            </div>
            {isOpen && <CustomDropDown close={close} user={user} />}
          </Nav>
        )}
      </Navbar>
    </>
  );
};

export const BrowserHeader = () => {
  const { title, hideSideBarAndNavbar } = getPropsMainNav();
  const { isTabOrPhone } = windowDimension();

  return (
    <>
      <Head>
        <title>Transfez - {title}</title>
        {hideSideBarAndNavbar && (
          <meta
            name="description"
            content="Register your Business Transfez Account Now!"
          />
        )}
        {isTabOrPhone && (
          <meta
            name="viewport"
            content="width=device-width, user-scalable=no"
          />
        )}
        <link rel="icon" href="/new-favicon.png" />
      </Head>
    </>
  );
};

const useMainWrapperProps = () => {
  const style = {
    height: "100%",
    width: "100%",
    backgroundColor: "white",
    overflow: "hidden",
  };
  return { style, className: "noselect" };
};

const MainComponentWrapper = ({ children }) => {
  const mainWrapperProps = useMainWrapperProps();
  return (
    <div {...mainWrapperProps}>
      <BrowserHeader />
      <CustomNavbar />
      <InfiniteScrollDeciderProvider>
        <div className="d-flex" style={{ maxWidth: 1440 }}>
          {children}
        </div>
      </InfiniteScrollDeciderProvider>
    </div>
  );
};

const MainComponentBigWrapper = ({ children }) => {
  const mainWrapperProps = useMainWrapperProps();
  const { navbarHeight, height, sidebarTotalWidth } = windowDimension();
  return (
    <div {...mainWrapperProps}>
      <BrowserHeader />
      <CustomNavbar />
      <InfiniteScrollDeciderProvider>
        <div className="d-flex" style={{ width: "100%" }}>
          <div
            style={{
              position: "fixed",
              top: navbarHeight,
            }}
          >
            <Sidebar />
          </div>
          <div
            style={{
              height,
              width: sidebarTotalWidth,
            }}
          />
          {children}
        </div>
      </InfiniteScrollDeciderProvider>
    </div>
  );
};

const TabOrPhoneWrapper = ({ children }) => {
  const mainWrapperProps = useMainWrapperProps();
  const { width } = windowDimension();
  return (
    <div {...mainWrapperProps}>
      <BrowserHeader />
      <BurgerNavbar />
      <InfiniteScrollDeciderProvider>
        <div style={{ width, overflowX: "auto" }}>
          <div style={{ minWidth: 1280, minHeight: "100%" }} className="pb-5">
            {children}
          </div>
        </div>
      </InfiniteScrollDeciderProvider>
    </div>
  );
};

export const ComponentWithSidebar = ({ Component, pageProps }) => {
  const {
    height,
    mainComponentWidth,
    navbarHeight,
    sidebarGap,
    sidebarTotalWidth,
    isTabOrPhone,
  } = windowDimension();

  if (isTabOrPhone)
    return (
      <TabOrPhoneWrapper>
        <Component {...pageProps} />
      </TabOrPhoneWrapper>
    );

  return (
    <MainComponentWrapper>
      <div
        style={{
          position: "fixed",
          top: navbarHeight,
        }}
      >
        <Sidebar />
      </div>
      {/* sidebar spacer */}
      <div
        style={{
          height,
          width: sidebarTotalWidth,
        }}
      />
      {/* sidebar spacer */}
      <div
        style={{
          width: mainComponentWidth - 10,
          marginLeft: 10,
          paddingTop: sidebarGap,
        }}
      >
        <Component {...pageProps} />
      </div>
    </MainComponentWrapper>
  );
};

export const ComponentWithoutSidebar = ({ Component, pageProps }) => {
  const { isTabOrPhone } = windowDimension();

  if (isTabOrPhone) {
    return (
      <TabOrPhoneWrapper>
        <Component {...pageProps} />
      </TabOrPhoneWrapper>
    );
  }

  return (
    <MainComponentWrapper>
      <Component {...pageProps} />
    </MainComponentWrapper>
  );
};
