import { universeFunc } from "../../components/RecipientDetailsModal";

export const UniverseDataGenerator = ({ data }) => {
  const bankName = universeFunc("bank_name", data);
  const registeredName = universeFunc("receiving_registered_name", data);
  const firstName = universeFunc("receiving_first_name", data);
  const lastName = universeFunc("receiving_last_name", data);
  const bankAccountName = universeFunc("bank_account_name", data);
  const bankAccountNumber = universeFunc("bank_account_number", data);
  const ibanNumber = universeFunc("iban", data);
  const swiftCode = universeFunc("swift_bic_code", data);
  const ifsCode = universeFunc("ifs_code", data);
  const recipientEmail = universeFunc("recipient_email", data);
  const abaRoutingNumber = universeFunc("aba_routing_number", data);
  const userName = registeredName || `${firstName} ${lastName}`;

  const dataForRender = [
    ...(userName
      ? [
          {
            title: `Name`,
            value: userName,
          },
        ]
      : []),
    ...(bankAccountName
      ? [
          {
            title: `Bank Account Name`,
            value: bankAccountName,
          },
        ]
      : []),
    ...(bankName
      ? [
          {
            title: `Bank Name`,
            value: bankName,
          },
        ]
      : []),
    ...(recipientEmail
      ? [
          {
            title: `Email`,
            value: recipientEmail,
          },
        ]
      : []),
    ...(ibanNumber
      ? [
          {
            title: `IBAN`,
            value: ibanNumber,
          },
        ]
      : []),
    ...(swiftCode
      ? [
          {
            title: `Swift code`,
            value: swiftCode,
          },
        ]
      : []),
    ...(ifsCode
      ? [
          {
            title: `IFS code`,
            value: ifsCode,
          },
        ]
      : []),
  ];
  return dataForRender;
};
