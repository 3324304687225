import React, { useState } from "react";
import apiIcon from "../../assets/images/api.svg";
import {
  MiddleWrapper,
  RightCardModalAtomic,
} from "../../components/CardModal";
import { ActivityDetailsHeader } from "./activityModalComponents";
import iconApi from "../../assets/images/api-icon.svg";
import downloadIcon from "../../assets/images/download-history-white.svg";
import { CenterendIconLocalTransfer } from "./localDisbursementBatchModal/transactionModal";
import { useLocalDisbursementLogic } from "./localDisbursementModal/logics";
import { StatusLabel } from "../../components/Status";
import { CustomButton } from "../../components/Buttons";
import { useDownload } from "../../tools/api";
import { LeftRightList } from "../../components/Lists";
import { RecipientMinimalist } from "./transactionList";
import { GothamMedium } from "../../components/Text";
import { colors } from "../../assets/colors";
import { MaskingState } from "../../pageComponents/apiDisbursementComponents/table";
import { eventsTracker } from "../../universalFunctions/events";

export const ApiDetailsModal = ({ isOpen, toggle, data }) => {
  const [middleHeight, setMiddleHeight] = useState(0);
  // const originator = data?.details?.originator || {};
  // const { id } = originator;

  const { id, originator_id, reference_number, reference_id } = data || {};
  const idUsed = originator_id || id;
  const referenceUsed = reference_number || reference_id;
  const {
    beneDetails,
    detailsArray,
    headerData,
    isIdrToIdr,
    senderDetails,
    loading,
    state,
    recipientData,
    senderData,
    showDownload: showStateDownload,
  } = useLocalDisbursementLogic({ data, isOpen });

  // const headerData = isIdrToIdr
  //   ? headerDataRaw
  //   : { ...headerDataRaw, icon: apiIcon, type: "API" };

  const showDownload = showStateDownload;
  const { handleDownload, loading: downloadLoading } = useDownload();

  return (
    <RightCardModalAtomic
      isOpen={isOpen}
      loading={loading}
      header={
        <ActivityDetailsHeader
          toggle={toggle}
          data={headerData}
          middleHeight={middleHeight}
          isSwitchTitle
        />
      }
      middleHeight={middleHeight}
      middle={
        <MiddleWrapper setHeight={setMiddleHeight} height={middleHeight}>
          <CenterendIconLocalTransfer icon={iconApi} />
        </MiddleWrapper>
      }
      body={
        <div>
          <div
            className="d-flex flex-column justify-content-center align-items-center"
            style={{ marginTop: -8 }}
          >
            <StatusLabel
              status={MaskingState(state)}
              style={{ marginBottom: showDownload ? 16 : 24 }}
            />
            {showDownload && (
              <CustomButton
                style={{ height: 32, marginBottom: 16 }}
                onClick={() => {
                  handleDownload({
                    // url: `/prints/multi_disbursement_receipt`,
                    // fileName: `Transfez-${id}-API-Disb.pdf`,
                    url: `/prints/${idUsed}/disbursement_receipt.pdf`,
                    fileName: `Transfez-API-History-${referenceUsed}.pdf`,
                  });
                  eventsTracker("download_api_history_receipt", { id: idUsed });
                }}
                isLoading={downloadLoading}
                leftIcon={<img src={downloadIcon} className="mr-2" />}
              >
                Download Receipt
              </CustomButton>
            )}
          </div>
          <div style={{ marginBottom: 24 }}>
            <LeftRightList array={detailsArray} />
          </div>
          <div style={{ marginBottom: 24 }}>
            <GothamMedium
              style={{ color: colors.grey33, fontSize: 16, marginBottom: 12 }}
            >
              Sender
            </GothamMedium>
            <RecipientMinimalist data={senderData} noFlag />
          </div>
          <div style={{ marginBottom: 24 }}>
            <GothamMedium
              style={{ color: colors.grey33, fontSize: 16, marginBottom: 12 }}
            >
              Recipient
            </GothamMedium>
            <RecipientMinimalist data={recipientData} noFlag />
          </div>
        </div>
      }
    />
  );
};
