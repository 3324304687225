import { isEmpty } from "lodash";
import physicalCard from "../../assets/images/transfez-card-physical.svg";
import virtualCardBlue from "../../assets/images/virtual-card-blue.svg";
import virtualCardGreen from "../../assets/images/virtual-card-green.svg";
import virtualCardGrey from "../../assets/images/virtual-card-grey.svg";
import virtualCardRed from "../../assets/images/virtual-card-red.svg";
import prepaid from "../../assets/images/prepaid-card-big.svg";

export const cardTypeDecider = (cardType) => {
  const isVirtual = cardType === "virtual";
  const isPhysical = cardType === "physical";
  const isPrepaid = cardType === "virtual_prepaid" || cardType === "prepaid";

  return { isVirtual, isPhysical, isPrepaid };
};

export const purposeDecider = (purposes, cardType) => {
  const { isVirtual, isPhysical } = cardTypeDecider(cardType);
  const isPhysicalPurposeEmpty = isEmpty(
    purposes?.find((purpose) => purpose.type === "physical")
  );
  const isVirtualPurposeEmpty = isEmpty(
    purposes?.find((purpose) => purpose.type === "virtual")
  );

  return (
    (isPhysical && isPhysicalPurposeEmpty) ||
    (isVirtual && isVirtualPurposeEmpty)
  );
};

export const cardDecider = ({ cardType, cardColor }) => {
  const { isPhysical } = cardTypeDecider(cardType);
  const isPrepaid = cardType == "prepaid";
  const isRed = cardColor === "red";
  const isGrey = cardColor === "grey";
  const isGreen = cardColor === "green";

  if (isPrepaid) return prepaid;

  if (isPhysical) return physicalCard;
  if (isRed) return virtualCardRed;
  if (isGrey) return virtualCardGrey;
  if (isGreen) return virtualCardGreen;
  return virtualCardBlue;
};
