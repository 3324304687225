import countries from "i18n-iso-countries";
import { get, snakeCase } from "lodash";
import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { Col, Row } from "reactstrap";
import styled from "styled-components";
import { colors } from "../assets/colors";
import accountAllocateIcon from "../assets/images/account-allocate.svg";
import AllocateIcon from "../assets/images/account-allocate2.svg";
import DownloadHistoryIcon from "../assets/images/download-history.svg";
import accountConvertIcon from "../assets/images/account-convert.svg";
import accountDetailsIcon from "../assets/images/account-details.svg";
import accountHistoryIcon from "../assets/images/account-history.svg";
import accountSendIcon from "../assets/images/account-send.svg";
import accountTopupIcon from "../assets/images/account-topup.svg";
import accountWithdrawIcon from "../assets/images/account-withdraw-icon.svg";
import circlePlusOutline from "../assets/images/circle-plus-outline.svg";
import copyIcon from "../assets/images/copy-icon.svg";
import leftArrowIcon from "../assets/images/left-arrow-back.svg";
import { GetFlag } from "../components/inputs";
import LoadingSpinner from "../components/Loading";
import { ShimmerOneLine } from "../components/Shimmer";
import { GothamMedium, GothamRegular } from "../components/Text";
import {
  formatCurrencyFixed,
  formatCurrencyNoDecimal,
  windowDimension,
} from "../components/tools";
import { TopTabs } from "../components/TopTabs";
import { useGetAuth } from "../contexts/AuthContext";
import { SmartAccountModal } from "../modals/smartAccountModal/parent";
import { fetch } from "../tools/api";
import { eventsTracker } from "../universalFunctions/events";
import { PhoneAccountDashboardAction } from "./accountDashboardComponents/phone";
import { WithdrawModal } from "../components/Modals";

export const AccountDashboardHeader = ({ title }) => (
  <GothamMedium style={{ fontSize: 24, marginBottom: 0 }}>
    {title || ""}
  </GothamMedium>
);

const indonesianDashboardData = () => [
  {
    icon: accountTopupIcon,
    name: "Top Up",
    href: `/account/topup`,
  },
  withdrawObj,
  sendMoneyObj,
  {
    icon: accountAllocateIcon,
    name: "Allocate Fund",
    href: `/account/allocate`,
  },
  {
    ...historyObj,
    href: `/account/IDR`,
    query: { type: "indonesian_wallet" },
  },
];

export const IndonesianAccountBody = () => {
  const { push } = useRouter();
  const [openModal, setOpenModal] = useState(false);
  const { user, userLoading } = useGetAuth();
  const getBalanceAmount = user?.partner?.disbursement_balance[0]?.balance || 0;
  const balance = `IDR ${formatCurrencyNoDecimal(getBalanceAmount)}`;
  return (
    <>
      <AccountCardDiv cursor="default">
        <Row>
          <Col xl={7}>
            <div
              className="d-flex justify-content-between mb-2"
              style={{ position: "relative" }}
            >
              <div className="d-flex align-items-center">
                <GetFlag
                  iso={"id"}
                  style={{
                    filter: "brightness(95%)",
                    marginLeft: 0,
                    marginRight: 10,
                  }}
                />
                <GothamMedium style={{ fontSize: 16, marginBottom: 0 }}>
                  Indonesian Account
                </GothamMedium>
              </div>
            </div>
            <GothamRegular
              style={{
                fontSize: 14,
                color: colors.grey72,
                marginBottom: 24,
              }}
            >
              This account is only available for remittance & funding your
              global account
            </GothamRegular>
            <GothamRegular
              style={{
                fontSize: 14,
                color: colors.grey72,
              }}
            >
              Balance
            </GothamRegular>
            {userLoading ? (
              <ShimmerOneLine height="30px" width="200px" />
            ) : (
              <GothamMedium
                style={{
                  fontSize: 20,
                  marginBottom: 8,
                }}
              >
                {balance}
              </GothamMedium>
            )}
          </Col>
          <Col xl={5} className="d-flex align-items-center justify-content-end">
            <AccountDashboardAction
              data={indonesianDashboardData()}
              noShadow
              w100
              toDetails={() => push("/account/withdrawal/")}
            />
            <WithdrawModal
              modal={openModal}
              toggle={() => setOpenModal(false)}
            />
          </Col>
        </Row>
      </AccountCardDiv>
    </>
  );
};

export const AccountDashboardBody = () => {
  const formatter = (data) => data?.data || [];

  const { data: vaDetails, loading: loadingVA } = fetch({
    url: "/multicurrency_accounts/virtual_accounts",
    formatter,
  });

  const {
    data,
    loading: loadingBalance,
    refetch,
  } = fetch({
    url: "/multicurrency_accounts/balance",
    formatter: (data) => {
      const array = data?.data || [];
      const result = array.map((item) => {
        const { curSymbol } = item || {};
        const getVaDetails = vaDetails.filter(
          ({ currencyCode }) => currencyCode == curSymbol
        )[0];
        return { ...item, vaDetails: getVaDetails, vaDetailsArr: vaDetails };
      });
      return result;
    },
    woInit: true,
  });
  useEffect(() => {
    if (!vaDetails.length) return;
    refetch();
  }, [vaDetails.length]);

  const loading = loadingBalance || loadingVA;

  const [modalData, setModalData] = useState(false);
  const isCanOpenNewAccount = false;

  const handleOpenDetails = (item) => setModalData(item);
  const handleCloseModal = () => setModalData(false);

  return (
    <div>
      <Row className="mt-4" style={{ maxWidth: "1440px" }}>
        {loading ? (
          <Col
            md={12}
            className="d-flex justify-content-center align-items-center"
          >
            <LoadingSpinner style={{ height: 150 }} />
          </Col>
        ) : (
          <>
            {isCanOpenNewAccount && <OpenNewAccountButton />}
            {data.map((item, index) => {
              const { curSymbol, isoCode, balance } = item;
              const newIso = countries.numericToAlpha2(isoCode);
              const isIdr = curSymbol == "IDR";
              const formattedBalance = isIdr
                ? formatCurrencyNoDecimal(balance)
                : formatCurrencyFixed(balance);
              return (
                <Col md={4} key={index}>
                  <AccountCardDiv onClick={() => handleOpenDetails(item)}>
                    <div
                      className="d-flex justify-content-between mb-4"
                      style={{ position: "relative" }}
                    >
                      <div className="d-flex align-items-center">
                        <GetFlag
                          iso={newIso.toLowerCase()}
                          style={{
                            filter: "brightness(95%)",
                            marginLeft: 0,
                            marginRight: 10,
                          }}
                        />
                        <GothamMedium style={{ fontSize: 16, marginBottom: 0 }}>
                          {curSymbol} Account
                        </GothamMedium>
                      </div>
                    </div>
                    <GothamRegular
                      style={{
                        fontSize: 16,
                        marginBottom: 14,
                        color: colors.grey72,
                      }}
                    >
                      Balance
                    </GothamRegular>
                    <GothamMedium
                      style={{
                        fontSize: 20,
                        marginBottom: 8,
                      }}
                    >
                      {curSymbol} {formattedBalance}
                    </GothamMedium>
                  </AccountCardDiv>
                </Col>
              );
            })}
          </>
        )}
      </Row>
      <SmartAccountModal
        toggle={handleCloseModal}
        data={modalData}
        isOpen={Boolean(modalData)}
      />
    </div>
  );
};

export const OpenNewAccountButton = () => {
  const { push } = useRouter();
  const handlePush = () => {
    push(`/account/new-account`);
  };
  return (
    <Col md={4} onClick={handlePush}>
      <AccountCardDiv>
        <div style={{ textAlign: "center", marginTop: 10, marginBottom: 20 }}>
          <img src={circlePlusOutline} style={{ marginBottom: 16 }} />
          <GothamMedium style={{ fontSize: 16, marginBottom: 0 }}>
            Open New Account
          </GothamMedium>
        </div>
      </AccountCardDiv>
    </Col>
  );
};

const sendObj = {
  icon: accountSendIcon,
  name: "Send",
  href: "/cross-border",
  value: "send",
};

const sendMoneyObj = {
  ...sendObj,
  name: "Send Money",
};

const convertObj = {
  icon: accountConvertIcon,
  name: "Convert",
  href: `/account/convert`,
};

const historyObj = {
  icon: accountHistoryIcon,
  name: "History",
};

const accDetailsObj = {
  icon: accountDetailsIcon,
  name: "Acc. Details",
  value: "account_details",
};

const withdrawObj = {
  icon: accountWithdrawIcon,
  name: "Withdraw",
};

const reallocateObj = {
  icon: AllocateIcon,
  name: "Allocate to Main Balance",
  href: `/account/reallocate`,
};
const downloadHistoryObj = {
  icon: DownloadHistoryIcon,
  name: "Download History",
};

export const accountDashboardData = ({ iso, query = {}, hideDetails }) => {
  // const localConvert = {
  //   ...convertObj,
  //   query: { iso_sender: iso },
  // };
  const localHistory = {
    ...historyObj,
    href: `/account/${iso}`,
    query,
    value: "history",
  };
  // sendObj,
  if (hideDetails) return [localHistory];
  return [reallocateObj, downloadHistoryObj];
};

export const actionIndoAccData = (loading) => [
  {
    icon: accountTopupIcon,
    name: "Top Up",
    href: `/account/topup`,
    loading,
  },
  {
    ...withdrawObj,
    toDetails: true,
    loading,
  },
  {
    icon: AllocateIcon,
    name: "Allocate to Card Balance",
    href: `/account/allocate`,
    loading,
  },
  {
    icon: DownloadHistoryIcon,
    name: "Download History",
    // href: `/account/allocate`,
    loading,
  },
];

export const AccountDashboardAction = ({
  toDetails,
  onClick: onClickProps,
  data = [],
  noShadow,
  w100,
  bordered,
  loading: loadingProps,
}) => {
  const { push } = useRouter();
  const [onHover, setOnHover] = useState(false);

  const { isTabOrPhone } = windowDimension();

  const onClick = (item) => {
    const { name, href, query = {}, value } = item;
    if (href) {
      const label = `dashboard_menu_${snakeCase(String(name || value || ""))}`;
      eventsTracker(label, query || {});
      return push({ pathname: href, query });
    }

    if (onClickProps) return onClickProps(item);

    toDetails && toDetails(true);
  };

  const props = {
    toDetails,
    onClick,
    data,
    noShadow,
    w100,
    bordered,
    loading: loadingProps,
  };

  if (isTabOrPhone) return <PhoneAccountDashboardAction {...props} />;

  const bShadow = noShadow && "none";
  const widerWidth = w100 && "100px";
  const isFew = data.length < 3;
  return (
    <AccountActionDiv
      className={isFew ? "justify-content-center" : "justify-content-between"}
      bshadow={bShadow}
      border={bordered}
    >
      {data.map((item, index) => {
        const { icon, name, href, loading, query, value } = item;

        if (!icon) return null;

        const hovered = onHover === index;
        return (
          <AccountSubActionDiv
            key={index}
            onMouseEnter={() => setOnHover(index)}
            onMouseLeave={() => setOnHover(false)}
            onClick={() => onClick(item)}
            minWidth={widerWidth}
            maxWidth={widerWidth}
          >
            {loading || loadingProps ? (
              <>
                <Skeleton
                  width={28}
                  height={28}
                  style={{ borderRadius: 200 }}
                />
                <Skeleton width={80} height={12} />
              </>
            ) : (
              <>
                <AccountActionImg
                  src={icon}
                  filter={hovered ? "brightness(93%)" : ""}
                />
                <GothamRegular
                  style={{
                    fontSize: 10,
                    lineHeight: "16px",
                    color: colors.grey6c,
                    marginBottom: 0,
                    textAlign: "center",
                  }}
                >
                  {name}
                </GothamRegular>
              </>
            )}
          </AccountSubActionDiv>
        );
      })}
    </AccountActionDiv>
  );
};

export const BackNavigation = ({ onClick }) => {
  const router = useRouter();
  return (
    <button
      className="fake-button d-flex align-items-center hover px-0"
      style={{ marginBottom: 8 }}
      onClick={() => {
        if (typeof onClick == "function") return onClick();
        router.back();
      }}
    >
      <img src={leftArrowIcon} />
      <GothamMedium
        style={{
          color: colors.pink,
          marginLeft: 8,
          fontSize: 12,
          marginBottom: 0,
          lineHeight: "18px",
        }}
      >
        Back
      </GothamMedium>
    </button>
  );
};

export const ActivityBackNavigator = ({ type }) => {
  const { push } = useRouter();
  return (
    <BackNavigation
      onClick={() => {
        push({ pathname: "/history/", query: { pageType: type } });
      }}
    />
  );
};

export const AccountDetailsListRekening = ({ data }) => {
  const getBankName = get(data, "fullBankName", "");
  const getAccName = get(data, "accountName", "");
  const getAccNumber = get(data, "uniquePaymentId", "");
  const getAddress = get(data, "bankAddress", "");
  const dataDetails = [
    {
      label: "Bank Name",
      value: `${getBankName}`,
    },
    {
      label: "Account Holder Name",
      value: `${getAccName}`,
    },
    {
      label: "Account Number",
      value: `${getAccNumber}`,
    },
    {
      label: "Address",
      value: `${getAddress}`,
    },
    // {
    //   label: "Bank Country",
    //   value: "Indonesia",
    // },
  ];
  return (
    <>
      <div style={{ padding: 16 }}>
        {dataDetails.map(({ label, value }, index) => {
          return (
            <div key={index}>
              <GothamRegular style={{ color: colors.grey72, fontSize: 12 }}>
                {label}
              </GothamRegular>
              <GothamMedium
                style={{
                  fontSize: 16,
                  marginBottom: 16,
                }}
              >
                {value}
                <img
                  src={copyIcon}
                  style={{ width: 16, marginLeft: 8 }}
                  className="darkhover"
                  onClick={(e) => {
                    navigator.clipboard.writeText(value);
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                />
              </GothamMedium>
            </div>
          );
        })}
      </div>
    </>
  );
};

export const AccountDetailsList = ({ data, isUsd }) => {
  return (
    <div
      style={{
        boxShadow: "0px 4px 8px rgba(88, 88, 88, 0.06)",
        borderRadius: 8,
        backgroundColor: "white",
        paddingTop: 2,
      }}
    >
      {isUsd ? (
        <TopTabs
          data={[
            {
              screenName: "For US",
              screen: <AccountDetailsListRekening data={data[1]} />,
            },
            {
              screenName: "For Singapore",
              screen: <AccountDetailsListRekening data={data[0]} />,
            },
          ]}
        />
      ) : (
        <AccountDetailsListRekening data={data[0]} />
      )}
    </div>
  );
};

const AccountCardDiv = styled.div`
  padding: 24px !important;
  border: 1px solid #eaeaea;
  border-radius: 12px;
  background-color: white !important;
  margin-bottom: 24px;
  margin-top: 24px;
  cursor: ${(p) => p.cursor || "pointer"};
`;

const AccountActionDiv = styled.div`
  display: flex;
  flex-wrap: nowrap;
  padding: 8px 8px;
  box-shadow: ${(p) => p.bshadow || "0px 8px 20px rgba(88, 88, 88, 0.1)"};
  border-radius: 12px;
  background-color: white !important;
  border: ${(p) => (p.border ? "1px solid #eaeaea" : "")};
`;

const AccountSubActionDiv = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: column;
  padding: 5px 5px;
  min-width: ${(p) => p.minWidth || "85px"};
  max-width: ${(p) => p.maxWidth || "85px"};
  cursor: pointer;
`;

const AccountActionImg = styled.img`
  width: 28px;
  height: 28px;
  margin-bottom: 8px;
  filter: ${(p) => p.filter};
`;

const ThreeDotsImg = styled.img`
  transform: rotate(90deg);
  width: 30px;
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 35px;
  z-index: 5;
  :hover {
    background-color: rgba(0, 0, 0, 0.08);
    border-radius: 50%;
    transform: rotate(90deg);
  }
`;

const AccountDetailsTransDiv = styled.div`
  background-color: white;
  border-radius: 8px;
  border: 1px solid #eaeaea;
  display: flex;
  justify-content: space-between;
  padding: 18px;
  flex-wrap: nowrap;
  margin-bottom: 16px;
`;
