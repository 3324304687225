import { isEmpty } from "lodash";
import React from "react";
import { booleanState } from "../../../../components/Status";
import {
  customDateFormatter,
  simpleDataSorter,
} from "../../../../components/tools";
import { useGetAuth } from "../../../../contexts/AuthContext";
import { useCrossBorderSingleWorkflow } from "../../../../modals/smartActivityModal/crossBorderSingleModal/hooks/workflow";
import { isDateExecutorAdmin, isYouOrName, RequiredApprover } from "./logics";

const dateReleasedFunc = (workflow_process) => {
  const isArray = Array.isArray(workflow_process);
  if (isArray) return "";
  const isObj =
    !isEmpty(workflow_process) && typeof workflow_process == "object";
  if (!isObj) return "";

  const index = simpleDataSorter(Object.keys(workflow_process)).pop();

  const data = workflow_process[index];

  const date = customDateFormatter(isDateExecutorAdmin({ data }));

  return date;
};

export const arrayDecider = ({ data, isSingle = false }) => {
  const { user } = useGetAuth();
  const { id } = user || {};
  const {
    state,
    state_summary = [],
    updated_at,
    created_at,
    drafter,
    executor,
    workflow_process = [],
  } = data || {};
  const { user_id, updated_at: updatedAtBatch } = data?.batch || {};
  const { name: nameDrafter, email: emailDrafter } = drafter || {};
  const {
    name: nameExecutor,
    email: emailExecutor,
    updated_at: updatedAtExecutor,
  } = executor || {};

  const {
    isRejectedByApproval,
    isWaitingApprovalTask,
    isWaitingForRelease,
    isCancelled,
    isOnProgress,
    isCompleted,
    isRejectedByAdmin,
    isCancelledPayroll,
    isPending,
    isRefund,
    isDeclined,
  } = booleanState(state);

  const isApprovedByAll =
    isWaitingForRelease || isOnProgress || isRejectedByApproval;

  const { approverLineArray, isWorkflow } = useCrossBorderSingleWorkflow(data);

  // checker if partner / company has no approver
  const isHasNoApprover = !Boolean(
    (user?.partner?.has_payroll_approver || []).length
  );
  // checker if partner / company has no approver

  const isPendingSingle =
    isOnProgress || isWaitingForRelease || isWaitingApprovalTask || isPending;

  const isOnlyOneStateSummary = state_summary?.length === 1;

  const allTaskYetApproved = state_summary?.every(
    (item) => item.approved_at === null
  );

  const filteredStateSummary = state_summary?.filter(
    ({ role }) => role === "payroll_approver"
  );

  const isAllAlreadyApproved = filteredStateSummary?.every(
    (item) => item.state === "approved"
  );

  const isThereApprovedTask = state_summary?.some(
    ({ state }) => state === "approved"
  );

  const drafterName = emailDrafter || nameDrafter;
  const ExecutorName = emailExecutor || nameExecutor;

  const youOrDrafterName = isYouOrName({ name: drafterName });
  const youOrExecutorName = isYouOrName({ name: ExecutorName });

  const isSubmitted = isWaitingApprovalTask && allTaskYetApproved;

  const isMeLogin = id === user_id;

  // const YouDecider = () => {
  //   if(isMeLogin) return `You`
  //   return
  // }

  const approverListDecider = ({ isActive = false, orangeText = false }) => {
    if (isWorkflow) {
      const approverLineArrayFormatted = approverLineArray.map((obj, index) => {
        if (index === 0) {
          return {
            ...obj,
            isActive: isApprovedByAll,
          };
        }

        return obj;
      });

      return approverLineArrayFormatted;
    }

    return [
      {
        text: "Required Admin or Business Owner to release the fund",
        isActive,
        orangeText,
      },
    ];
  };

  const requestedPayroll = {
    text: `${youOrDrafterName} requested a payroll transaction(s)`,
    date: customDateFormatter(created_at),
    isActive: true,
  };

  if (isSingle && isPendingSingle) {
    const statusArray = [
      {
        text: "You requested a payroll transaction(s)",
        date: customDateFormatter(created_at),
        isActive: true,
      },
      {
        text: "Your transaction(s) is on progress",
        date: customDateFormatter(updated_at),
        isActive: true,
      },
      {
        text: "Completed",
      },
    ];
    return statusArray;
  }

  if (isSingle && isCompleted) {
    const statusArray = [
      {
        text: "You requested a payroll transaction(s)",
        date: customDateFormatter(created_at),
        isActive: true,
      },
      {
        text: "Completed",
        date: customDateFormatter(created_at),
        isActive: true,
        styleCustom: { marginTop: -5 },
      },
    ];
    return statusArray;
  }

  if (isSingle && isCancelledPayroll) {
    const statusArray = [
      {
        text: "You requested a payroll transaction(s)",
        isActive: true,
        date: customDateFormatter(created_at),
      },
      {
        data: [
          {
            text: "We were unable to process your transaction. Please ask your account manager for more info",
            date: customDateFormatter(updated_at),
          },
        ],
        isActive: true,
        isRedButton: true,
      },
    ];
    return statusArray;
  }

  if (isSingle && isRefund) {
    const statusArray = [
      {
        text: "You requested a payroll transaction(s)",
        isActive: true,
        date: customDateFormatter(created_at),
      },
      {
        data: [
          {
            text: "We were unable to process your transaction. Please ask your account manager for more info",
            date: customDateFormatter(updated_at),
          },
        ],
        isActive: true,
        isRedButton: true,
      },
    ];
    return statusArray;
  }

  if (isSingle && isDeclined) {
    const statusArray = [
      {
        text: "You requested a payroll transaction(s)",
        isActive: true,
        date: customDateFormatter(created_at),
      },
      {
        data: [
          {
            text: "The recipient’s bank has declined the transaction",
            date: customDateFormatter(updated_at),
          },
        ],
        isActive: true,
        isRedButton: true,
      },
    ];
    return statusArray;
  }

  if (isRejectedByApproval) {
    const statusArray = [
      requestedPayroll,
      {
        data: RequiredApprover({ data: filteredStateSummary }),
        isActive: true,
      },
      ...approverListDecider({}),
      {
        data: [
          {
            text: `Rejected by ${youOrExecutorName}`,
            date: customDateFormatter(updated_at),
            isRed: true,
          },
        ],
        isActive: true,
        isRedButton: true,
      },
    ];

    return statusArray.filter(({ data }) => {
      if (data) {
        if (data.length > 0) return true;
        else return false;
      }

      return true;
    });
  }

  if (isSubmitted && isHasNoApprover) {
    const statusArray = [
      requestedPayroll,
      ...approverListDecider({ isActive: true }),
      {
        text: "Transferring money to recipients",
      },
      {
        text: "Completed",
      },
    ];
    return statusArray;
  }

  if (isSubmitted) {
    const statusArray = [
      requestedPayroll,
      {
        data: RequiredApprover({ data: state_summary }),
        isActive: true,
      },
      ...approverListDecider({}),
      {
        text: "Transferring money to recipients",
      },
      {
        text: "Completed",
      },
    ];
    return statusArray;
  }

  if (isWaitingApprovalTask) {
    const statusArray = [
      requestedPayroll,
      {
        data: RequiredApprover({ data: state_summary }),
        isActive: true,
      },
      ...approverListDecider({}),
      {
        text: "Transferring money to recipients",
      },
      {
        text: "Completed",
      },
    ];
    return statusArray;
  }

  if (isWaitingForRelease && (isHasNoApprover || isOnlyOneStateSummary)) {
    const statusArray = [
      requestedPayroll,
      ...approverListDecider({ isActive: true, orangeText: true }),
      {
        text: "Transferring money to recipients",
      },
      {
        text: "Completed",
      },
    ];
    return statusArray;
  }

  if (isWaitingForRelease && isAllAlreadyApproved) {
    const statusArray = [
      requestedPayroll,
      {
        data: RequiredApprover({ data: filteredStateSummary }),
        isActive: true,
      },
      ...approverListDecider({ isActive: true, orangeText: true }),
      {
        text: "Transferring money to recipients",
      },
      {
        text: "Completed",
      },
    ];
    return statusArray;
  }

  if (isCancelled && isThereApprovedTask) {
    const statusArray = [
      requestedPayroll,
      {
        data: RequiredApprover({ data: filteredStateSummary }),
        isActive: true,
      },
      {
        data: [
          {
            text: `Cancelled by ${youOrExecutorName}`,
            date: customDateFormatter(updated_at),
            isRed: true,
          },
        ],
        isActive: true,
        isRedButton: true,
      },
    ];
    return statusArray;
  }

  if (isCancelled) {
    const statusArray = [
      requestedPayroll,
      {
        data: [
          {
            text: `Cancelled by ${youOrExecutorName}`,
            date: customDateFormatter(updated_at),
            isRed: true,
          },
        ],
        isActive: true,
        isRedButton: true,
      },
    ];
    return statusArray;
  }

  const dateReleased = dateReleasedFunc(workflow_process);

  if (isOnProgress && isHasNoApprover) {
    const statusArray = [
      requestedPayroll,
      {
        text: `${youOrExecutorName} released the payment`,
        date: dateReleased,
        isActive: true,
        isReleased: true,
      },
      {
        text: "Transferring money to recipients",
        isActive: true,
      },
      {
        text: "Completed",
      },
    ];
    return statusArray;
  }

  if (isOnProgress) {
    const statusArray = [
      requestedPayroll,
      {
        data: RequiredApprover({ data: filteredStateSummary }),
        isActive: true,
      },
      // {
      //   text: `${youOrExecutorName} released the payment`,
      //   date: customDateFormatter(isDateExecutorAdmin({ data: state_summary })),

      //   isActive: true,
      //   isReleased: true,
      // },
      ...approverListDecider({}),
      {
        text: "Transferring money to recipients",
        isActive: true,
      },
      {
        text: "Completed",
      },
    ];
    return statusArray;
  }

  if (isCompleted && isHasNoApprover) {
    const statusArray = [
      requestedPayroll,
      {
        text: `${youOrExecutorName} released the payment`,
        date: dateReleased,
        isActive: true,
        isReleased: true,
      },
      {
        text: "Transferring money to recipients",
        isActive: true,
      },
      {
        text: "Completed",
        isActive: true,
        date: customDateFormatter(updatedAtBatch),
      },
    ];
    return statusArray;
  }

  if (isCompleted) {
    const statusArray = [
      requestedPayroll,
      {
        data: RequiredApprover({ data: filteredStateSummary }),
        isActive: true,
      },
      {
        text: `${youOrExecutorName} released the payment`,
        date: dateReleased,
        isActive: true,
        isReleased: true,
      },
      {
        text: "We sent out your money to the recipient",
        isActive: true,
        date: customDateFormatter(updatedAtBatch),
      },
      {
        text: "All of your funds has been processed",
        isActive: true,
        date: customDateFormatter(updatedAtBatch),
      },
    ];
    return statusArray;
  }

  if (isRejectedByAdmin && isHasNoApprover) {
    const statusArray = [
      requestedPayroll,
      {
        data: [
          {
            text: `Rejected by ${youOrExecutorName}`,
            date: customDateFormatter(updated_at),
            isRed: true,
          },
        ],
        isActive: true,
        isRedButton: true,
      },
    ];
    return statusArray;
  }

  if (isRejectedByAdmin) {
    const statusArray = [
      requestedPayroll,
      {
        data: RequiredApprover({ data: filteredStateSummary }),
        isActive: true,
      },
      {
        data: [
          {
            text: `Rejected by ${youOrExecutorName}`,
            date: customDateFormatter(updated_at),
            isRed: true,
          },
        ],
        isActive: true,
        isRedButton: true,
      },
    ];
    return statusArray;
  }

  return [];
};
