import React from "react";
import { GothamMedium, GothamRegular } from "../../../../components/Text";
import { IssuanceStatusBar } from "./IssuanceStatusBar";
import Warning from "../../../newCardComponents/cardSetup/components/warning";
import CardProgressSummary from "./CardProgressSummary";

const CardModalBody = ({ stateObj, dataDetail, selectedTransactionData }) => {
  const {
    isFreezing,
    isBlocking,
    isChangingPIN,
    isEditingLimit,
    isReplacingCard,
    isAdjustingLimit,
    isEditingNickname,
    isEditingDescription,
  } = stateObj;

  const isOtherModalScreen = isChangingPIN || isEditingLimit || isReplacingCard;
  const isVirtualEditScreen = isAdjustingLimit || isEditingNickname;

  if (isOtherModalScreen) return null;
  if (selectedTransactionData) return null;
  if (isFreezing || isBlocking) return null;

  const { state, user, is_adjusting_limit, issuance_status } = dataDetail || {};
  const isRequestAdjustingLimit = is_adjusting_limit?.value;
  const isOwnerInvited = user?.status === "invited";

  const isNotActivated = state === "NOT_ACTIVE" || state === "REQUEST_NEW_CARD";
  const isActivating = state === "REQUEST_ACTIVE";
  const isRequestCard = state === "REQUEST_NEW_CARD";
  const isPermanentBlocked = state === "P_BLOCK";
  const isRejected = issuance_status === "rejected";

  const isActivatedCardIndex =
    !isNotActivated &&
    !isActivating &&
    !isRequestCard &&
    !isVirtualEditScreen &&
    !isEditingDescription;
  const isCardOnIssuance = isNotActivated || isActivating || isRequestCard;
  const isHaveBorder = !isVirtualEditScreen && !isEditingDescription;

  return (
    <div
      style={{
        borderTop: isPermanentBlocked && "1px solid #E6E6E8",
        marginTop: isPermanentBlocked ? "-16px" : "4px",
        paddingTop: "16px",
        borderTop: isHaveBorder && "1px solid #E6E6E8",
      }}
    >
      {/* This will show only when the card has been activated */}
      {isActivatedCardIndex && !isRequestAdjustingLimit && (
        <>
          <div style={{ marginBottom: "16px" }}>
            <GothamMedium style={{ fontSize: "16px", color: "#102C57" }}>
              Billing Address
            </GothamMedium>
          </div>
          <GothamRegular>Jl. Casablanca Raya Kav. 88</GothamRegular>
          <GothamRegular>Prudential Centre Lt. 22</GothamRegular>
          <GothamRegular>South Jakarta, Indonesia</GothamRegular>
        </>
      )}
      {/* This will show when the card is already requested and is on process or on activation process */}
      {(isCardOnIssuance || isRequestAdjustingLimit) && !isRejected && (
        <>
          <div style={{ marginBottom: "16px" }}>
            <GothamMedium style={{ fontSize: "16px", color: "#102C57" }}>
              {isCardOnIssuance ? "Issuance status" : "Updates"}
            </GothamMedium>
          </div>
          <div>
            {isOwnerInvited ? (
              <Warning
                text="Notify them to set up a Transfez Business account. The issuance process will start after they completed the registration process."
                isSmall
              />
            ) : (
              <IssuanceStatusBar dataDetail={dataDetail} />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default CardModalBody;
