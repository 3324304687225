import { customDateFormatter } from "../../../../components/tools";
import { GothamRegular } from "../../../../components/Text";
import CardProgressSummary from "./CardProgressSummary";
import { fetch } from "../../../../tools/api";

export const IssuanceStatusBar = ({ dataDetail, type }) => {
  const {
    issuance_status,
    requester_name,
    requester_role,
    group_state_transition,
    created_at,
    card_type,
    first_card,
    notes,
    workflow_process,
  } = dataDetail || {};
  const isEmployee = requester_role === "partner_maker";

  const { data: cardBusiness} = fetch({
    url: "/card_business_detail",
    formatter: (res) => res?.data,
  });
  const {updated_at: cardBusinessUpdatedAt,requestor_name: adjustLimitRequestor } = cardBusiness || {};

  const { issuance_status: issuance_statuses = [] } =
    group_state_transition || {};
  const { rejection_note, rejected_by_user, approved_by_user } = notes || {};
  const isVirtual = card_type == "virtual";

  if (isVirtual && workflow_process)
    return <CardProgressSummary dataDetail={dataDetail} />;

  const progressObj = {
    onRejected: [
      {
        dotIndicator: "#102C57",
        lineLength: "72px",
        mainText: [
          { color: "#333334", text: `${requester_name || "Anonymous"} ` },
          { color: "green", text: "requested" },
          { color: "#333334", text: " to issue the card" },
        ],
        date: customDateFormatter(created_at),
      },
      {
        dotIndicator: "white",
        lineLength: "0px",
        mainText: [
          { color: "#FF7B5A", text: "Rejected by " },
          {
            color: "#333334",
            text: rejected_by_user,
          },
          {
            text: "line-break",
          },
          {
            color: "#6C6C71",
            text: `Reason:`,
          },
          {
            text: "line-break",
          },
          {
            color: "#6C6C71",
            text: rejection_note,
          },
        ],
        date: customDateFormatter(
          issuance_statuses[issuance_statuses?.length - 1]?.created_at
        ),
      },
    ],
    onReview: [
      {
        dotIndicator: "#102C57",
        lineLength: "80px",
        mainText: [
          { color: "#333334", text: `${requester_name || "Anonymous"} ` },
          { color: "green", text: "requested" },
          { color: "#333334", text: " to issue the card" },
        ],
        date: customDateFormatter(created_at),
      },
      {
        dotIndicator: "white",
        lineLength: "56px",
        mainText: [
          { color: "#FF7B5A", text: "Require " },
          {
            color: "#333334",
            text: "any Admin or Business Owner to approve",
          },
        ],
        date: customDateFormatter(created_at),
      },
      {
        dotIndicator: "#BBBBC0",
        lineLength: "36px",
        mainText: [
          {
            color: "gray",
            text: `Card issuance is in ${first_card ? "review" : "progress"}`,
          },
        ],
      },
      {
        dotIndicator: "#BBBBC0",
        lineLength: "0px",
        mainText: [{ color: "gray", text: "Card issuance completed" }],
      },
    ],
    onRequest: [
      {
        dotIndicator: "#102C57",
        lineLength: "80px",
        mainText: [
          { color: "#333334", text: `${requester_name || "Anonymous"} ` },
          { color: "green", text: "requested" },
          { color: "#333334", text: " to issue the card" },
        ],
        date: customDateFormatter(created_at),
      },
      ...(isVirtual
        ? [
            ...(isEmployee
              ? [
                  {
                    dotIndicator: "#102C57",
                    lineLength: "56px",
                    mainText: [
                      {
                        color: "#333334",
                        text: `Approved by ${approved_by_user} `,
                      },
                    ],
                    date: customDateFormatter(created_at),
                  },
                ]
              : []),
            {
              dotIndicator: "white",
              lineLength: "56px",
              mainText: [
                { color: "#333334", text: "Card issuance is " },
                {
                  color: "green",
                  text: `on ${first_card ? "review" : "progress"}`,
                },
              ],
              date: customDateFormatter(
                issuance_statuses[issuance_statuses?.length - 1]?.created_at ||
                  created_at
              ),
            },
            {
              dotIndicator: "#BBBBC0",
              lineLength: "0px",
              mainText: [{ color: "gray", text: "Card issuance completed" }],
            },
          ]
        : [
            {
              dotIndicator: "white",
              lineLength: "56px",
              mainText: [
                {
                  color: "#333334",
                  text: `Card issuance is on review`,
                },
              ],
              date: customDateFormatter(created_at),
            },
            {
              dotIndicator: "#BBBBC0",
              lineLength: "36px",
              mainText: [
                {
                  color: "gray",
                  text: `Card issuance is on progress`,
                },
              ],
            },
            {
              dotIndicator: "#BBBBC0",
              lineLength: "36px",
              mainText: [{ color: "gray", text: "Delivering the card" }],
            },
            {
              dotIndicator: "#BBBBC0",
              lineLength: "0px",
              mainText: [{ color: "gray", text: "Card delivered" }],
            },
          ]),
    ],
    onProgress: [
      {
        dotIndicator: "#102C57",
        lineLength: "80px",
        mainText: [
          { color: "#333334", text: `${requester_name || "Anonymous"} ` },
          { color: "green", text: "requested" },
          { color: "#333334", text: " to issue the card" },
        ],
        date: customDateFormatter(created_at),
      },
      {
        dotIndicator: "#102C57",
        lineLength: "56px",
        mainText: [
          {
            color: "#333334",
            text: `Card issuance `,
          },
          {
            color: "green",
            text: "has been reviewed",
          },
        ],
        date: customDateFormatter(issuance_statuses[0]?.created_at),
      },
      {
        dotIndicator: "white",
        lineLength: "56px",
        mainText: [
          {
            color: "#333334",
            text: `Card issuance is`,
          },
          {
            color: "green",
            text: " in progress",
          },
        ],
        date: customDateFormatter(
          issuance_statuses[issuance_statuses?.length - 1]?.created_at
        ),
      },
      ,
      {
        dotIndicator: "#BBBBC0",
        lineLength: "36px",
        mainText: [{ color: "gray", text: "Delivering the card" }],
      },
      {
        dotIndicator: "#BBBBC0",
        lineLength: "0px",
        mainText: [{ color: "gray", text: "Card delivered" }],
      },
    ],
    onDelivery: [
      {
        dotIndicator: "#102C57",
        lineLength: "80px",
        mainText: [
          { color: "#333334", text: `${requester_name || "Anonymous"} ` },
          { color: "green", text: "requested" },
          { color: "#333334", text: " to issue the card" },
        ],
        date: customDateFormatter(created_at),
      },
      {
        dotIndicator: "#102C57",
        lineLength: "56px",
        mainText: [
          { color: "#333334", text: "Card issuance has been " },
          { color: "green", text: "reviewed" },
        ],
        date: customDateFormatter(issuance_statuses[0]?.created_at),
      },
      {
        dotIndicator: "#102C57",
        lineLength: "36px",
        mainText: [
          {
            color: "#333334",
            text: `Card issuance is`,
          },
          {
            color: "green",
            text: "in progress",
          },
        ],
        date: customDateFormatter(issuance_statuses[1]?.created_at),
      },
      {
        dotIndicator: "white",
        lineLength: "56px",
        mainText: [
          { color: "#333334", text: "Card is on " },
          { color: "green", text: "delivery" },
        ],
        date: customDateFormatter(
          issuance_statuses[issuance_statuses.length - 1]?.created_at
        ),
      },
      {
        dotIndicator: "#BBBBC0",
        lineLength: "0px",
        mainText: [{ color: "gray", text: "Card delivered" }],
      },
    ],
    delivered: [
      {
        dotIndicator: "#102C57",
        lineLength: "80px",
        mainText: [
          { color: "#333334", text: `${requester_name || "Anonymous"} ` },
          { color: "green", text: "requested" },
          { color: "#333334", text: " to issue the card" },
        ],
        date: customDateFormatter(created_at),
      },
      {
        dotIndicator: "#102C57",
        lineLength: "56px",
        mainText: [
          { color: "#333334", text: "Card issuance has been " },
          { color: "green", text: "reviewed" },
        ],
        date: customDateFormatter(issuance_statuses[0]?.created_at),
      },
      {
        dotIndicator: "#102C57",
        lineLength: "56px",
        mainText: [
          {
            color: "#333334",
            text: `Card issuance is on review`,
          },
        ],
        date: customDateFormatter(issuance_statuses[1]?.created_at),
      },
      {
        dotIndicator: "#102C57",
        lineLength: "56px",
        mainText: [
          { color: "#333334", text: "Card issuance is " },
          { color: "green", text: "in progress" },
        ],
        date: customDateFormatter(issuance_statuses[2]?.created_at),
      },
      {
        dotIndicator: "#102C57",
        lineLength: "40px",
        mainText: [
          { color: "#333334", text: "Card is on " },
          { color: "green", text: "delivery" },
        ],
        date: customDateFormatter(issuance_statuses[3]?.created_at),
      },
      {
        dotIndicator: "white",
        lineLength: "0px",
        mainText: [
          { color: "#333334", text: "Card is " },
          { color: "green", text: "delivered" },
          { color: "#333334", text: ", activate your card now!" },
        ],
        date: customDateFormatter(
          issuance_statuses[issuance_statuses?.length - 1]?.created_at
        ),
      },
    ],
    approved: [
      {
        dotIndicator: "#102C57",
        lineLength: "80px",
        mainText: [
          { color: "#333334", text: `${requester_name || "Anonymous"} ` },
          { color: "green", text: "requested" },
          { color: "#333334", text: " to issue the card" },
        ],
        date: customDateFormatter(created_at),
      },
      ...(isEmployee
        ? [
            {
              dotIndicator: "#102C57",
              lineLength: "56px",
              mainText: [
                {
                  color: "#333334",
                  text: "Approved by any Admin or Business Owner",
                },
              ],
              date: customDateFormatter(issuance_statuses[0]?.created_at),
            },
          ]
        : []),
      {
        dotIndicator: "#102C57",
        lineLength: "56px",
        mainText: [
          { color: "#333334", text: "Card issuance is " },
          {
            color: "green",
            text: `in ${first_card ? "review" : "progress"}`,
          },
        ],
        date: customDateFormatter(
          issuance_statuses.length < 2
            ? issuance_statuses[0]?.created_at
            : issuance_statuses[1]?.created_at
        ),
      },
      {
        dotIndicator: "white",
        lineLength: "0px",
        mainText: [{ color: "gray", text: "Card issuance completed" }],
        date: customDateFormatter(
          issuance_statuses[issuance_statuses.length - 1]?.created_at
        ),
      },
    ],

    adjustCompanylimit: [
      {
        dotIndicator: "#102C57",
        lineLength: "80px",
        mainText: [
          { color: "#333334", text: adjustLimitRequestor },
          {
            color: "green",
            text: adjustLimitRequestor ? " requested" : "Request",
          },
          {
            color: "#333334",
            text: " to adjust the company virtual limit",
          },
        ],
        date: customDateFormatter(cardBusinessUpdatedAt),
      },
      {
        dotIndicator: "white",
        lineLength: "56px",
        mainText: [
          { color: "#333334", text: "Adjustment is in" },
          { color: "#FF7B5A", text: " review" },
        ],
        date: customDateFormatter(cardBusinessUpdatedAt),
      },
      {
        dotIndicator: "#BBBBC0",
        lineLength: "0px",
        mainText: [{ color: "gray", text: "Completed" }],
      },
    ],
  };

  const isRejected = issuance_status == "rejected";
  const isOnReview = issuance_status == "on_review";
  const isOnProgress = issuance_status == "on_progress";
  const isDelivering = issuance_status == "delivering";
  const isDelivered = issuance_status == "delivered";
  const isOnRequest = issuance_status == "requested";
  const isApproved = issuance_status == "approved";

  const isAdjustingCompanyLimit = type == "adjust_company_limit";

  const progressObjDecider = () => {
    if (isRejected) return progressObj.onRejected;
    if (isOnReview) return progressObj.onReview;
    if (isOnRequest) return progressObj.onRequest;
    if (isOnProgress) return progressObj.onProgress;
    if (isDelivering) return progressObj.onDelivery;
    if (isDelivered) return progressObj.delivered;
    if (isApproved) return progressObj.approved;

    if (isAdjustingCompanyLimit) return progressObj.adjustCompanylimit;
  };

  return (
    <>
      {progressObjDecider()?.map((progress, index) => {
        const isWhite = progress.dotIndicator === "white";
        return (
          <div key={index} style={{ display: "flex", marginBottom: "16px" }}>
            <div style={{ width: "36px" }}>
              <div
                style={{
                  width: isWhite ? "10px" : "8px",
                  height: isWhite ? "10px" : "8px",
                  borderRadius: "4px",
                  position: "relative",
                  top: isWhite ? "8px" : "9px",
                  left: isWhite ? "7px" : "8px",
                  backgroundColor: progress.dotIndicator,
                  border: isWhite && "2px solid #DA649F",
                }}
              >
                <div
                  style={{
                    width: "2px",
                    height: progress.lineLength,
                    backgroundColor: "#BBBBC0",
                    position: "absolute",
                    top: "8px",
                    left: isWhite ? "2px" : "3px",
                  }}
                />
              </div>
            </div>
            <div>
              <div style={{ maxWidth: 325 }}>
                {progress.mainText.map((progressText, index) => {
                  if (progressText.text == "line-break") return <br />;
                  return (
                    <GothamRegular
                      style={{
                        display: "inline",
                        color: progressText.color,
                      }}
                      key={index}
                    >
                      {progressText.text}
                    </GothamRegular>
                  );
                })}
              </div>
              {progress.date && (
                <GothamRegular style={{ fontSize: "12px", color: "#6C6C71" }}>
                  {progress.date}
                </GothamRegular>
              )}
            </div>
          </div>
        );
      })}
    </>
  );
};
