import React from "react";
import happyCat from "../assets/images/no_access.svg";
import { CustomButton } from "../components/Buttons";
import { GothamMedium, GothamRegular } from "../components/Text";
import { windowDimension } from "../components/tools";
import TransfezLogo from "../assets/images/transfez-logo.svg";
import { useGetAuth } from "../contexts/AuthContext";
import { useRouter } from "next/router";

const RestrictedPage = () => {
  const { navbarHeight, height, width } = windowDimension();
  const { unauthorize } = useGetAuth();
  const { push } = useRouter();
  return (
    <div
      style={{
        backgroundColor: "white",
        height,
        width,
      }}
    >
      <div className="p-3" style={{ borderBottom: "1px solid #EAEAEA" }}>
        <img src={TransfezLogo} />
      </div>
      <div
        className="d-flex justify-content-center"
        style={{ height: height - navbarHeight }}
      >
        <div style={{ width: 480 }} className="d-flex align-items-center ">
          <div
            className="d-flex align-items-center"
            style={{ flexDirection: "column", textAlign: "center" }}
          >
            <img src={happyCat} style={{ width: 313 }} />
            <GothamMedium
              style={{
                fontSize: 24,
                lineHeight: "32px",
                marginTop: 16,
                marginBottom: 16,
              }}
            >
              You do not have access to this page
            </GothamMedium>
            <GothamRegular
              className="m-0"
              style={{ fontSize: 16, lineHeight: "24px", color: "#333334" }}
            >
              Your admin has restricted your access to the page.
            </GothamRegular>
            <GothamRegular
              className="m-0"
              style={{ fontSize: 16, lineHeight: "24px", color: "#333334" }}
            >
              Please contact your admin.
            </GothamRegular>
            <CustomButton
              style={{ width: "100%", marginTop: 16 }}
              onClick={() => {
                unauthorize();
                push("/");
              }}
            >
              Logout
            </CustomButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RestrictedPage;
