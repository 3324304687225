import {
  customDateFormatter,
  formatCurrencyNoDecimal,
} from "../../../components/tools";
import { dateToIsoDateFormatter } from "../../invoiceComponents/data/formatter";

export const reimbursementFormatter = (array) =>
  array.map((item) => {
    const {
      reimbursement_file,
      purchased_date,
      created_at,
      amount,
      user,
      state: status,
    } = item || {};
    const createdBy = user?.name;
    const createdDate = created_at
      ? customDateFormatter(created_at, true, true)
      : "-";
    const purchasedDate = purchased_date
      ? customDateFormatter(purchased_date, true, true)
      : "-";
    const purchasedDateISO = purchased_date
      ? dateToIsoDateFormatter(purchased_date)
      : "-";

    const getUrl = (url) => {
      if (!url) return "";
      return `${process.env.NEXT_PUBLIC_TRANSFEZ_BUSINESS_WO_VERSION_URL}${url}`;
    };
    const file_url = getUrl(reimbursement_file);
    const amountIDR = `IDR ${formatCurrencyNoDecimal(amount)}`;
    return {
      ...item,
      type: "reimbursement",
      createdBy,
      createdDate,
      file_url,
      status,
      purchasedDate,
      purchasedDateISO,
      amountIDR,
    };
  });

export const toUtcDate = (rawDate) => {
  const date = new Date(rawDate);
  const now_utc = Date.UTC(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours()
  );

  return new Date(now_utc).toISOString();
};
