import { upperCase } from "lodash";
import { deepRemoveDuplicates } from "../../../components/tools";

export const sgBanksFormatter = (array) => {
  const requiredArrays = array.filter(
    ({ swift_code }) => String(swift_code).length == 8
  );
  const removeDuplicate = deepRemoveDuplicates(requiredArrays, "bank");
  const result = removeDuplicate.map((item) => {
    const { bank: bank_name, id: value } = item;
    const label = upperCase(bank_name);
    return { ...item, bank_name, label, value };
  });
  return result;
};
