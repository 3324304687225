import { customDateFormatter, formatCurrency } from "../../../components/tools";

export const batchFormatter = (object) => {
  const {
    team,
    created_at,
    category: type,
    amount,
    currency,
    details,
    user,
  } = object || {};
  const { name: userName, email: userEmail } = user || {};
  const { batch_id, state, user_email, total_trxb } =
    details?.transaction || {};
  const rightTop = `-${currency} ${formatCurrency(amount)}`;
  const leftBottom = ` • ${batch_id} • ${customDateFormatter(created_at)} • ${
    userName || userEmail
  } •`;
  const leftTag = team?.name;
  const leftStatus = state;
  const leftTop = `Batch of ${total_trxb} Transaction(s)`;

  return {
    ...object,
    type,
    leftTop,
    leftBottom,
    leftStatus,
    leftTag,
    rightTop,
  };
};
