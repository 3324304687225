import {
  customDateFormatter,
  formatCurrency,
  formatCurrencyNoDecimal,
} from "../../../components/tools";

export const cardFormatter = (object) => {
  const {
    currency,
    amount,
    debit,
    merchant_name: leftTop,
    label,
    status,
    created_at,
    auth_code,
    user,
  } = object || {};

  const {
    value: leftMiddle,
    amount: deepAmount,
    currency: deepCurrency,
  } = label || {};
  const isIdr = deepCurrency === "IDR" || currency === "IDR";
  const formatterDecider = isIdr ? formatCurrencyNoDecimal : formatCurrency;
  const { name: userName, email: userEmail } = user || {};
  const rightTop = `${debit ? "-" : "+"}${
    deepCurrency || currency
  } ${formatterDecider(deepAmount || amount)}`;
  const leftBottom = `• ${auth_code} • ${customDateFormatter(created_at)} • ${
    userName || userEmail
  } •`;

  const leftStatus = status;

  return { ...object, leftTop, rightTop, leftBottom, leftStatus, leftMiddle };
};
