import { pickBy } from "lodash";
import { useRouter } from "next/router";
import React, { useMemo, useRef } from "react";
import { AiFillCloseCircle } from "react-icons/ai";
import { IoIosOptions } from "react-icons/io";
import { colors } from "../../assets/colors";
import { useClickOutside } from "../../universalFunctions/useClickOutside";
import { OutlineButton } from "../Buttons";
import { defaultIconStyleQueryInput } from "../inputs";
import { useModalHook } from "../Modals";
import { DefaultQueryInput } from "../SearchAndFilterCard";
import { GothamRegular } from "../Text";
import { numberOfFilterQueriesApplied } from "../tools";
import { FilterFieldsGenerator } from "./fieldsGenerator";

const FloatingNumber = ({ number, top = -3, right = -5 }) => {
  if (!Boolean(number)) return null;

  return (
    <div
      style={
        top
          ? {
              position: "absolute",
              top,
              right,
            }
          : {
              position: "absolute",
              right,
            }
      }
    >
      <GothamRegular
        className="font8 fade-in"
        style={{
          color: "white",
          textAlign: "center",
          width: 14,
          height: 14,
          borderRadius: 14,
          backgroundColor: colors.redE9,
          paddingTop: 0,
        }}
      >
        {number}
      </GothamRegular>
    </div>
  );
};

export const PhoneFilterFields = ({
  placeholder,
  data,
  woSearch,
  queryName,
  woQuery,
  onChange,
}) => {
  const flattenedData = (data || []).flat(1);
  const { isOpen, toggle, close } = useModalHook();
  const ref = useRef();
  const { query, push } = useRouter();

  useClickOutside({ ref, clickOutside: close });

  const number = useMemo(() => numberOfFilterQueriesApplied(query), [query]);

  const handleClearAll = () => {
    const filteredNamesArr = flattenedData
      .map(({ name, rangedDate }) => {
        if (rangedDate) return ["start_date", "end_date"];
        return name;
      })
      .flat(1);

    const acceptedQueryKeys = pickBy(
      query,
      (_, key) => !filteredNamesArr.includes(key)
    );

    push({ query: acceptedQueryKeys });
    close();
  };

  return (
    <div ref={ref} className="mb-2">
      <div style={{ position: "relative" }}>
        {woSearch ? (
          <OutlineButton
            onClick={toggle}
            style={{ height: 34, position: "relative" }}
            className="mb-2 px-3"
          >
            Filters
            <FloatingNumber number={number} top={1} right={5} />
          </OutlineButton>
        ) : (
          <DefaultQueryInput
            woQuery={woQuery}
            onChange={onChange}
            queryName={queryName}
            placeholder={placeholder}
            icon={
              <div {...defaultIconStyleQueryInput}>
                <div
                  className="d-flex align-items-start"
                  style={{ position: "relative" }}
                  onClick={toggle}
                >
                  <div
                    style={{
                      position: "relative",
                      width: 20,
                      height: 20,
                    }}
                    className="darkhover"
                  >
                    {isOpen ? (
                      <AiFillCloseCircle
                        size={20}
                        color={colors.pink}
                        style={{ position: "absolute", top: 0 }}
                        className={`${isOpen ? "fade-in" : "fade-out"}`}
                      />
                    ) : (
                      <IoIosOptions
                        size={20}
                        style={{
                          position: "absolute",
                          top: 0,
                        }}
                        className={`${!isOpen ? "fade-in" : "fade-out"}`}
                      />
                    )}
                  </div>
                  <FloatingNumber number={number} />
                </div>
              </div>
            }
          />
        )}
      </div>
      {isOpen && (
        <div
          style={{
            backgroundColor: colors.greyea,
            borderRadius: 8,
          }}
          className="py-1 px-2 pt-2"
        >
          {Boolean(number) && (
            <div className="d-flex justify-content-end mb-2 fade-in">
              <GothamRegular
                style={{
                  textAlign: "right",
                  color: colors.redE9,
                  textDecoration: "underline",
                }}
                className="font12 darkhover"
                onClick={handleClearAll}
              >
                Clear All
              </GothamRegular>
            </div>
          )}
          <FilterFieldsGenerator data={flattenedData} isTabOrPhone />
        </div>
      )}
    </div>
  );
};
