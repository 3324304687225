import React, { useEffect, useState } from "react";
import { Document, Page } from "react-pdf";
import { colors } from "../../assets/colors";
import chevronLeftPdf from "../../assets/images/chevronLeftPdf.svg";
import chevronRightPdf from "../../assets/images/chevronRightPdf.svg";
import LoadingSpinner from "../../components/Loading";
import { useModalHook } from "../../components/Modals";
import { TypeFezNeueMedium } from "../../components/Text";
import { toBase64, windowDimension } from "../../components/tools";

export const PDFOrImageGdriveViewer = ({ isPDF, src }) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [numPages, setNumPages] = useState(null);
  const [file, setFile] = useState(null);

  const [imageDimensions, setImageDimensions] = useState({});

  const { clientHeight, clientWidth } = imageDimensions;

  const { isOpen: isScaleUp, toggle, close } = useModalHook();
  const { height } = windowDimension();

  const reset = () => {
    close();
    setImageDimensions({});
    setNumPages(null);
    setPageNumber(1);
    setFile(null);
  };

  useEffect(async () => {
    reset();
    if (src) {
      let blob = await fetch(src).then((r) => r.blob());
      const file = await toBase64(blob);
      setFile(file);
    }
  }, [src]);

  const scaleUp = isScaleUp && {
    width: clientWidth * 2,
    height: clientHeight * 2,
  };

  if (isPDF) {
    const Text = ({ children }) => (
      <TypeFezNeueMedium style={{ color: "#FFFFFF" }}>
        {children}
      </TypeFezNeueMedium>
    );

    const Arrow = ({ src, onClick }) => (
      <div
        style={{
          height: 20,
          width: 20,
          borderRadius: 0,
          marginLeft: 8,
          marginRight: 8,
        }}
        className="darkhover d-flex align-items-center justify-content-center"
        onClick={onClick}
      >
        <img
          src={src}
          style={{
            height: 12,
          }}
        />
      </div>
    );

    // remove annoying annotations from PDF viewer
    const removeAnnotations = () => {
      const textLayers = document.querySelectorAll(
        ".react-pdf__Page__textContent"
      );
      textLayers.forEach((layer) => {
        const { style } = layer;
        style.display = "none";
      });
    };

    return (
      <>
        <Document
          file={{ url: file }}
          onLoadSuccess={({ numPages }) => {
            setNumPages(numPages);
          }}
          onClick={toggle}
          loading={
            !file && (
              <LoadingSpinner
                style={{
                  color: colors.pink,
                }}
              />
            )
          }
        >
          <Page
            pageNumber={pageNumber}
            height={isScaleUp ? height : height * 0.8}
            renderAnnotationLayer={false}
            onLoadSuccess={removeAnnotations}
          />
        </Document>
        <div
          style={{
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            position: "absolute",
            bottom: 10,
            width: 195,
            height: 40,
          }}
          className="d-flex justify-content-between align-items-center"
        >
          <Arrow
            src={chevronLeftPdf}
            onClick={() =>
              setPageNumber((prev) => {
                if (prev <= 1) return 1;
                return prev - 1;
              })
            }
          />
          <Text>Page</Text>
          <Text>{pageNumber}</Text>
          <Text>/</Text>
          <Text>{numPages}</Text>
          <Arrow
            src={chevronRightPdf}
            onClick={() =>
              setPageNumber((prev) => {
                if (prev >= numPages) return numPages;
                return prev + 1;
              })
            }
          />
        </div>
      </>
    );
  }

  return (
    <img
      onClick={toggle}
      onLoad={(e) => {
        if (!e) return;
        const { clientHeight, clientWidth, src } = e.target;

        setImageDimensions((prev) => {
          if (prev.src == src) return prev;
          return { clientHeight, clientWidth, src };
        });
      }}
      src={src}
      style={{
        zIndex: 500001,
        maxHeight: "100%",
        maxWidth: "100%",
        cursor: "pointer",
        ...scaleUp,
      }}
    />
  );
};
