import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { colors } from "../../../../assets/colors";
import {
  MiddleWrapper,
  RightCardModalAtomic,
} from "../../../../components/CardModal";
import { CustomTextArea, FileInputDotted } from "../../../../components/inputs";
import { LeftRightList } from "../../../../components/Lists";
import { FileInputTable } from "../../../../components/MultipleInputTable";
import { StatusAccountIcon, StatusLabel } from "../../../../components/Status";
import { GothamMedium } from "../../../../components/Text";
import {
  customDateFormatter,
  formatCurrencyNoDecimal,
} from "../../../../components/tools";
import { useGetAuth } from "../../../../contexts/AuthContext";
import { apiBusiness, fetch, useMutation } from "../../../../tools/api";
import { ActivityDetailsHeader } from "../../activityModalComponents";
import { CenterendIconLocalTransfer } from "../../localDisbursementBatchModal/transactionModal";

const ReceiptHeader = ({ children }) => (
  <div className="d-flex justify-content-between" style={{ marginBottom: 16 }}>
    <GothamMedium>Receipt</GothamMedium>
    {children}
  </div>
);

export const EWalletActivityModal = ({ toggle, isOpen, data }) => {
  if (!isOpen) return;

  const { originator_id, status } = data;
  const [isUpload, setIsUpload] = useState(false);

  const {
    data: details,
    loading: loadingFetch,
    refetch,
  } = fetch({
    url: `/wallet_balance_histories/${originator_id}`,
    formatter: (res) => res?.data || {},
    defaultValue: {},
    afterSuccess: (res) => {
      const { file_details = [] } = res?.data || {};
      setIsUpload(!file_details.length);
    },
  });

  const {
    amount,
    file_details = [],
    created_at,
    date,
    reference_number,
    title,
    user,
    notes,
    is_external,
  } = details;

  const { user: currUser } = useGetAuth();
  const { id } = currUser || {};

  const { name, email, id: user_id } = user || {};

  const canEdit = id == user_id;

  const nameOrEmail = name || email;

  const headerData = {
    type: "Team Wallet",
    icon: false,
    title: `IDR ${formatCurrencyNoDecimal(amount)}`,
  };

  const useFormObj = useForm();
  const { watch } = useFormObj;

  const fileWatch = watch("upload_file");
  const notesWatch = watch("notes") || "";

  const { mutation, loading: loadingMutation } = useMutation({
    url: `/wallet_balance_histories/${originator_id}`,
    afterSuccess: (_, payload) => {
      const { notes } = payload || {};
      if (notes) return;
      refetch();
    },
  });

  const loading = loadingFetch || loadingMutation;

  // delete detector
  const hiddenFileWatchIds =
    (watch("file_input_table_files")?.data || []).map(({ id }) => id) || [];
  const [stateHiddenFileWatchIds, setStateHiddenFileWatchIds] = useState([]);

  const [isFirstState, setIsFirstState] = useState(true);

  useEffect(() => {
    if (!hiddenFileWatchIds.length) return;
    if (!isFirstState) return;
    setIsFirstState(false);
    setStateHiddenFileWatchIds(hiddenFileWatchIds);
  }, [hiddenFileWatchIds.length]);

  useEffect(() => {
    if (isFirstState) return;
    setStateHiddenFileWatchIds((prev) => {
      const deletedId = prev.filter(
        (id) => !hiddenFileWatchIds.includes(id)
      )[0];
      if (deletedId)
        apiBusiness.delete(
          `/wallet_balance_histories/${originator_id}/files/${deletedId}`
        );
      return hiddenFileWatchIds;
    });
  }, [hiddenFileWatchIds.length]);
  // delete detector

  // upload detector
  useEffect(() => {
    if (!fileWatch) return;
    const { realFile } = fileWatch;

    const formData = new FormData();
    formData.append("files[]", realFile);

    return mutation(formData);
  }, [fileWatch]);
  // upload detector

  const [middleHeight, setMiddleHeight] = useState(0);

  const fileInputs = () => {
    if (is_external) return null;

    const receiptSection = () => {
      if (canEdit)
        return (
          <>
            <ReceiptHeader>
              {Boolean(file_details.length) && (
                <GothamMedium
                  style={{ color: colors.pink }}
                  className="darkhover p-1"
                  onClick={() => setIsUpload((p) => !p)}
                >
                  {isUpload ? "See All" : "Add"}
                </GothamMedium>
              )}
            </ReceiptHeader>
            {isUpload ? (
              <FileInputDotted
                useFormObj={useFormObj}
                fileTypes={["application/pdf", "image/jpeg"]}
                name="upload_file"
              />
            ) : (
              <FileInputTable
                defaultValue={file_details}
                useFormObj={useFormObj}
                canDelete={canEdit}
              />
            )}
          </>
        );

      if (!file_details.length) return null;

      return (
        <>
          <ReceiptHeader />
          <FileInputTable
            defaultValue={file_details}
            useFormObj={useFormObj}
            canDelete={canEdit}
          />
        </>
      );
    };

    return (
      <>
        {receiptSection()}
        <GothamMedium style={{ marginBottom: 16, marginTop: 16 }}>
          Notes
        </GothamMedium>
        <CustomTextArea
          disabled={!canEdit}
          useFormObj={useFormObj}
          name="notes"
          placeholder="Your payment details"
          style={{ height: 60, fontSize: 12, lineHeight: "16px" }}
          defaultValue={notes}
        />
      </>
    );
  };

  return (
    <RightCardModalAtomic
      loading={loading}
      isOpen={isOpen}
      header={
        <ActivityDetailsHeader
          toggle={() => {
            if (!notesWatch) return toggle();
            mutation({ notes: notesWatch });
            toggle();
          }}
          data={headerData}
        />
      }
      headerStyle={{ paddingBottom: 48 }}
      middleHeight={middleHeight}
      middle={
        <MiddleWrapper setHeight={setMiddleHeight} height={middleHeight}>
          <CenterendIconLocalTransfer
            img={<StatusAccountIcon state="wallet_history" />}
          />
        </MiddleWrapper>
      }
      body={
        <div>
          <div className="w-100 d-flex justify-content-center">
            <StatusLabel status={status} />
          </div>
          {fileInputs()}
          <div style={{ height: 24 }} />
          <LeftRightList
            array={[
              { left: "Date", right: customDateFormatter(date || created_at) },
              { left: "Reference", right: reference_number },
              { left: "Merchant", right: title },
              { left: "Payer", right: nameOrEmail },
            ]}
          />
        </div>
      }
    />
  );
};
