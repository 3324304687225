import { times } from "lodash";
import styled from "styled-components";
import React from "react";
import Skeleton from "react-loading-skeleton";
import { Row, Col } from "reactstrap";
import { colors } from "../assets/colors";
import { windowDimension } from "./tools";
import threeDots from "../assets/images/3dots.svg";

export const TransactionShimmer = () => (
  <>
    {times(10, (index) => (
      <div
        key={index}
        className=" mt-4"
        style={{
          border: "1px solid #F8F8F8",
          borderRadius: 12,
          padding: 12,
          boxShadow: "0px 4px 8px rgba(88, 88, 88, 0.06)",
        }}
      >
        <div className="d-flex w-100 align-items-center justify-content-between">
          <Skeleton height={15} width={200} />
          <Skeleton height={25} width={150} />
        </div>
        <div
          className="d-flex w-100 align-items-center justify-content-between"
          style={{ marginTop: 12, marginBottom: 12 }}
        >
          <div>
            <Skeleton height={30} width={100} />
          </div>
          <div>
            <Skeleton height={30} width={120} />
          </div>
        </div>
        <Skeleton height={30} width={100} style={{ borderRadius: 18 }} />
      </div>
    ))}
  </>
);

export const RecipientListLoading = () => (
  <>
    {times(10, (childIndex) => (
      <div
        key={`childIndex ${childIndex}`}
        className="mx-4 py-4 px-2 d-flex justify-content-between"
        style={{
          border: "1px solid #FFD27C",
          borderColor: "white",
          borderBottomColor: "#FFD27C",
        }}
      >
        <div className="d-flex">
          <Skeleton height={30} width={30} style={{ borderRadius: "50%" }} />
          <div className="ml-2">
            <div>
              <Skeleton height={15} width={200} />
            </div>
            <div>
              <Skeleton height={15} width={200} />
            </div>
          </div>
        </div>
        <img src={threeDots} />
      </div>
    ))}
  </>
);

export const NewRecipientListLoading = ({ noDots }) => (
  <>
    {times(10, (childIndex) => (
      <div
        key={`childIndex ${childIndex}`}
        className="d-flex justify-content-between"
        style={{
          paddingTop: 12,
          paddingBottom: 12,
        }}
      >
        <div>
          <div
            className="d-flex align-items-center"
            style={{ marginBottom: 12 }}
          >
            <Skeleton
              height={30}
              width={30}
              style={{ borderRadius: "50%", marginLeft: 12 }}
            />
            <Skeleton height={15} width={200} style={{ marginLeft: 10 }} />
          </div>
          <div style={{ paddingLeft: 12 }}>
            <Skeleton height={25} width={200} />
          </div>
        </div>
        {!noDots && <img src={threeDots} />}
      </div>
    ))}
  </>
);

export const RecentTransactionsShimmer = () => (
  <div
    className="mx-5 mt-4 px-3 py-3 "
    style={{
      borderRadius: 12,
      border: "1px solid #c2c2c2",
      backgroundColor: "white",
    }}
  >
    <div style={{ fontSize: 24, lineHeight: "32px" }}>
      <Skeleton width={225} height={24} />
    </div>
    {times(5, (index) => {
      const isFirst = index === 0;
      return (
        <div key={index}>
          {!isFirst && (
            <div
              style={{ height: 1, backgroundColor: colors.yellowFF }}
              className="my-3"
            />
          )}
          <Row className="mx-0 justify-content-between mt-4">
            <Skeleton width={125} height={20} />
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
              className="mt-2"
            >
              <Skeleton width={190} height={25} />
            </div>
          </Row>
          <Row className="mx-0 mt-3">
            <Skeleton width={275} height={20} />
          </Row>
          <Row className="mx-0 my-3 justify-content-between align-items-center">
            <div style={{ fontSize: 20, lineHeight: "28px" }}>
              <Skeleton width={140} height={30} />
            </div>
            <div>
              <div
                style={{
                  textAlign: "right",
                  fontSize: 20,
                  lineHeight: "28px",
                }}
                className="mb-2"
              >
                <Skeleton height={20} width={175} />
              </div>
              <div
                style={{
                  textAlign: "right",
                  fontSize: 14,
                  lineHeight: "20px",
                }}
              >
                <Skeleton height={16} width={150} />
              </div>
            </div>
          </Row>
          <Skeleton height={40} width={100} style={{ borderRadius: 8 }} />
        </div>
      );
    })}
  </div>
);

export const TransactionHistoryShimmer = () => {
  return (
    <>
      {times(10, (index) => (
        <div key={index} className={`mb-4 ${index == 0 ? "mt-2" : ""}`}>
          <Row className="px-4">
            <Skeleton width={125} height={20} className="mr-3" />
            <Skeleton width={125} height={20} />
          </Row>
          <Row className="px-4 pt-2 d-flex">
            <Col lg={2} className="px-0 my-auto align-items-center">
              <Skeleton width={125} height={20} />
            </Col>
            <Col lg={3} className="px-2 my-auto">
              <Skeleton width={185} height={20} />
            </Col>
            <Col lg={5} className="text-center px-0 my-auto">
              <Skeleton width={125} height={20} />{" "}
              <Skeleton width={125} height={20} />
            </Col>
            <Col lg={2} className="text-right px-0 my-auto">
              <Skeleton height={40} width={100} style={{ borderRadius: 8 }} />
            </Col>
          </Row>
        </div>
      ))}
    </>
  );
};

export const TransactionDetailsShimmer = () => {
  const { figmaWidth, figmaHeight } = windowDimension();
  return (
    <>
      {/* HEADER */}
      <div className="mx-5 mb-4">
        <div>
          <Skeleton width={145} height={25} />
          <div className="d-flex">
            <Skeleton width={165} height={25} />
          </div>
          <Skeleton width={145} height={25} />
        </div>
      </div>
      <div className="mx-5 d-flex justify-content-between">
        <div>
          {/* BODY 1 */}
          {times(2, (index) => (
            <div
              key={index}
              style={{
                width: figmaWidth(544),
                height: figmaHeight(346),
                backgroundColor: "white",
                borderRadius: "12px",
                border: "1px solid rgba(194, 194, 194, 0.5)",
                padding: "1.5rem",
                marginBottom: "1.5rem",
              }}
            >
              <div
                className="d-flex align-items-center mb-3"
                style={{ marginLeft: "-10px" }}
              >
                <Skeleton width={185} height={50} />
              </div>
              <div className="mb-3">
                <Skeleton width={185} height={20} />
                <br />
                <Skeleton width={155} height={25} />
              </div>
              <div className="mb-3">
                <Skeleton width={145} height={20} />
                <br />
                <Skeleton width={135} height={25} />
              </div>
              <div className="mb-3">
                <Skeleton width={60} height={20} />
                <br />
                <Skeleton width={170} height={25} />
              </div>
              <div className="mb-3">
                <Skeleton width={60} height={20} />
                <br />
                <Skeleton width={170} height={25} />
              </div>
            </div>
          ))}
          {/* BODY 2 */}
        </div>
        <div>
          {/* <TransactionStatus /> */}
          <div
            style={{
              width: figmaWidth(390),
              height: figmaHeight(316),
              backgroundColor: "white",
              borderRadius: "12px",
              border: "1px solid rgba(194, 194, 194, 0.5)",
              padding: "1.5rem",
              marginBottom: "1.5rem",
              marginLeft: "1rem",
            }}
          >
            <div className="d-flex align-items-center mb-3">
              <Skeleton width={185} height={40} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export const MappedTableShimmer = ({ totalHeader, totalItem = 10 }) => (
  <tbody>
    {times(totalItem, (index) => (
      <tr key={index}>
        {times(totalHeader, (index) => (
          <td key={index}>
            <Skeleton width="80%" height="90%" />
          </td>
        ))}
      </tr>
    ))}
  </tbody>
);

export const ApiTransactionDetailsShimmer = () => {
  const totalItem = 8;
  const totalRow = 16;
  const totalInfo = 2;
  const totalInfoBody = 5;
  return (
    <>
      <Row className="mx-5 justify-content-between">
        <div className=" mb-4">
          <div>
            <Skeleton width={185} height={40} />
            <div className="d-flex mb-2">
              <Skeleton width={185} height={40} />
            </div>
          </div>
        </div>
      </Row>
      <DetailsDiv style={{ position: "relative" }}>
        <Row>
          {times(totalItem, (index) => {
            return (
              <Col lg={3} key={index} className="position-relative">
                <Skeleton width={200} height={20} />
              </Col>
            );
          })}
        </Row>
      </DetailsDiv>
      <DetailsDiv style={{ position: "relative" }}>
        <Row>
          {times(totalRow, (index) => {
            return (
              <Col lg={3} key={index} className="position-relative">
                <Skeleton width={200} height={20} />
              </Col>
            );
          })}
        </Row>
      </DetailsDiv>
      <div className="d-flex">
        {times(totalInfo, (index) => {
          return (
            <DetailsDiv style={{ width: 530 }} key={index}>
              <Skeleton width={200} height={50} />
              {times(totalInfoBody, (index) => {
                return (
                  <div className="mb-3" key={index}>
                    <Skeleton width={200} height={20} />
                  </div>
                );
              })}
            </DetailsDiv>
          );
        })}
      </div>
    </>
  );
};

export const HeaderBalanceHistoryShimmer = () => {
  return (
    <>
      <HeaderDiv btlr="15px" btrr="15px" color="white">
        <Row>
          <Col md={4}>
            <Skeleton width={200} height={50} />
            <Skeleton width={200} height={20} />
          </Col>
          <Col md={4} />
          <Col md={4} className="d-flex justify-content-end align-items-center">
            <div
              style={{
                backgroundColor: "white",
                height: 56,
                width: 270,
                borderRadius: 12,
              }}
            ></div>
          </Col>
        </Row>
      </HeaderDiv>
      <HeaderDiv bcolor="white" padding="12px">
        <div className="d-flex">
          <div
            style={{ width: "61%" }}
            className="d-flex align-items-center mb-0"
          >
            <Skeleton width={300} height={50} />
          </div>
          <div style={{ width: "21%" }}>
            <Skeleton width={200} height={20} />
          </div>
          <div style={{ width: "18%" }}>
            <Skeleton width={200} height={20} />
          </div>
        </div>
      </HeaderDiv>
    </>
  );
};

export const ShimmerOneLine = ({ height, width }) => {
  return (
    <>
      <Skeleton width={width} height={height} />
    </>
  );
};

const HeaderDiv = styled.div`
  margin-right: 3rem;
  margin-left: 3rem;
  padding: ${(p) => p.padding || "16px"};
  background-color: ${(p) => p.bcolor || "#49abb8"};
  color: ${(p) => p.color};
  border-top-left-radius: ${(p) => p.btlr};
  border-top-right-radius: ${(p) => p.btrr};
  border: 1px solid rgba(194, 194, 194, 0.5);
`;

const DetailsDiv = styled.div`
  background-color: white;
  border-radius: 12px;
  border: 1px solid rgba(194, 194, 194, 0.5);
  padding: 1.5rem;
  margin: 0rem 3rem 1.5rem 3rem;
`;
