import { titleCase } from "change-case";
import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { colors } from "../../../assets/colors";
import chevronRight from "../../../assets/images/chevron-grey-right.svg";
import localTransfer from "../../../assets/images/invoice-payment-modal.svg";
import jpegIcon from "../../../assets/images/jpg.svg";
import {
  MiddleWrapper,
  RightCardModalAtomic,
} from "../../../components/CardModal";
import { LeftRightList } from "../../../components/Lists";
import { PDFOrImageViewer } from "../../../components/PDFPreview";
import { StatusLabel } from "../../../components/Status";
import {
  TypeFezNeueMedium,
  TypeFezNeueRegular,
} from "../../../components/Text";
import { formatCurrency } from "../../../components/tools";
import { useConstants } from "../../../contexts/ConstantsContext/parent";
import { useGdrivehook } from "../../../contexts/GoogleDrivePreviewContext/parent";
import {
  attachmentDataFormatter,
  invoiceFormatter,
} from "../../../pageComponents/invoiceComponents/data/formatter";
import { fetch } from "../../../tools/api";
import { ActivityDetailsHeader } from "../activityModalComponents";
import { crossBorderSingleWorkflowComponents } from "../crossBorderSingleModal/components";
import { CenterendIconLocalTransfer } from "../localDisbursementBatchModal/transactionModal";
import { RecipientMinimalist } from "../transactionList";
import { InvoicePaymentStatusLine } from "./status";
import { TransactionDetailsBox } from "./transactionDetails";

const HeaderSkeleton = ({ toggle, middleHeight }) => {
  const headerData = {
    type: <Skeleton width={120} />,
    icon: false,
    title: <Skeleton width={170} />,
  };
  return (
    <ActivityDetailsHeader
      toggle={toggle}
      data={headerData}
      middleHeight={middleHeight}
    />
  );
};

const BodySkeleton = () => {
  return (
    <div
      className="d-flex justify-content-center align-items-center w-100"
      style={{ flexDirection: "column", zIndex: 121 }}
    >
      <Skeleton width={70} height={16} style={{ borderRadius: 100 }} />
      <div
        style={{
          display: "flex",
          justifyContent: "end",
          marginTop: 12,
        }}
      >
        <Skeleton
          width={170}
          height={37}
          style={{
            borderRadius: 100,
            marginRight: 20,
          }}
        />
        <Skeleton width={170} height={37} style={{ borderRadius: 100 }} />
      </div>
      <div className="w-100" style={{ marginTop: 18 }}>
        <div style={{ marginBottom: 36 }}>
          <div className="d-flex justify-content-between mb-2">
            <Skeleton width={100} />
            <Skeleton width={180} />
          </div>
          <div className="d-flex justify-content-between mb-2">
            <Skeleton width={80} />
            <Skeleton width={80} />
          </div>
          <div className="d-flex justify-content-between mb-2">
            <Skeleton width={60} />
            <Skeleton width={80} />
          </div>
        </div>
      </div>
      <div className="w-100">
        <Skeleton width={100} style={{ marginBottom: 12 }} />
        <div style={{ display: "flex", gap: 12, alignItems: "center" }}>
          <Skeleton circle={true} width={50} height={51} />
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Skeleton width={150} />
            <Skeleton width={150} />
          </div>
        </div>
      </div>
      <div className="w-100" style={{ marginTop: 24, marginBottom: 24 }}>
        <Skeleton width={100} style={{ marginBottom: 12 }} />
        <Skeleton count={2} />
      </div>
      <div className="w-100" style={{ marginTop: 12 }}>
        <Skeleton width={100} style={{ marginBottom: 16 }} />
        <div
          style={{
            marginLeft: 24,
            display: "flex",
            gap: 14,
          }}
        >
          <Skeleton height={"100%"} width={8} />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 14,
            }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Skeleton width={150} />
              <Skeleton width={130} height={10} />
            </div>
            <div style={{ display: "flex", flexDirection: "column", gap: 12 }}>
              <Skeleton width={240} />
              <Skeleton width={100} />
            </div>
            <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
              <Skeleton width={200} />
              <Skeleton width={130} />
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Skeleton width={65} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const InvoicePaymentModal = ({ isOpen, toggle, data }) => {
  if (!isOpen) return null;

  const { id: idRaw, originator_id } = data || {};

  const id = originator_id || idRaw;

  const {
    data: details,
    refetch,
    loading,
  } = fetch({
    url: `/invoice_transactions/${id}`,
    woInit: true,
    defaultValue: {},
    formatter: (res) => {
      const { attachment_data, data } = res || {};
      if (data) {
        const obj = invoiceFormatter([data])[0] || {};
        const newAttachmentData = attachmentDataFormatter(
          (attachment_data || []).map((item, index) => {
            const url = obj.supporting_documents_files[index];
            return { ...item, url };
          })
        );
        return { ...obj, newAttachmentData };
      }
    },
  });

  const { buttons, rejectModal, isRejection, openPinModal } =
    crossBorderSingleWorkflowComponents({
      transaction: details,
      afterSuccess: refetch,
      cancelUrlFunc: (id) => `/invoice_transactions/${id}/change_state`,
      modelName: "invoice_payment",
    });

  useEffect(() => {
    if (!id) return;
    refetch();
  }, [id]);

  const [middleHeight, setMiddleHeight] = useState(0);

  const {
    invoice_number,
    total_amount,
    recipient_name,
    state,
    dueDate,
    local_recipient,
    description,
    file_url,
    local_transaction,
    attachment_data,
    supporting_documents_files,
    issuedDate,
    newAttachmentData,
  } = details;

  const { fee } = local_transaction || {};

  const showTrxDetails = Boolean(state == "cancelled" || state == "completed");

  const { idBanks, localTransferBanks } = useConstants();
  const { registered_name, payer_id, account_number } = local_recipient || {};

  const bankObj = localTransferBanks.filter(
    ({ value }) => value == payer_id
  )[0];

  const bank = bankObj?.name.toUpperCase();

  const headerData = {
    type: `Invoice ${recipient_name}`,
    icon: false,
    title: `IDR ${formatCurrency(total_amount || 0)}`,
  };
  const dataRecipient = {
    name: titleCase(registered_name),
    bank,
    bankAccount: account_number,
    iso: "id",
  };

  const { show } = useGdrivehook();

  return (
    <>
      <RightCardModalAtomic
        isOpen={isOpen}
        toggle={toggle}
        middleHeight={middleHeight}
        hideModal={isRejection || openPinModal}
        header={
          loading ? (
            <HeaderSkeleton toggle={toggle} middleHeight={middleHeight} />
          ) : (
            <ActivityDetailsHeader
              toggle={toggle}
              data={headerData}
              middleHeight={middleHeight}
            />
          )
        }
        middle={
          <MiddleWrapper setHeight={setMiddleHeight} height={middleHeight}>
            <CenterendIconLocalTransfer icon={localTransfer} />
          </MiddleWrapper>
        }
        body={
          loading ? (
            <BodySkeleton />
          ) : (
            <div
              className="d-flex justify-content-center align-items-center w-100"
              style={{ flexDirection: "column", zIndex: 121 }}
            >
              <StatusLabel status={state} />
              {buttons()}
              <div className="w-100" style={{ marginTop: 24 }}>
                <LeftRightList
                  array={[
                    { left: "Invoice Number", right: invoice_number },
                    {
                      left: "Issue Date",
                      right: issuedDate ? issuedDate : "-",
                    },
                    {
                      left: "Due Date",
                      right: dueDate ? dueDate : "-",
                      isRed: true,
                    },
                    { spacer: true },
                  ]}
                />
              </div>
              {showTrxDetails ? (
                <div className="w-100" style={{ marginBottom: 24 }}>
                  <TypeFezNeueMedium
                    className="font16"
                    style={{ marginBottom: 12 }}
                  >
                    Transaction Details
                  </TypeFezNeueMedium>
                  <TransactionDetailsBox totalAmount={total_amount} fee={fee} />
                </div>
              ) : null}
              <div className="w-100">
                <TypeFezNeueMedium className="font16">
                  Recipient
                </TypeFezNeueMedium>
                <RecipientMinimalist data={dataRecipient} />
              </div>
              <div className="w-100" style={{ marginTop: 24 }}>
                <TypeFezNeueMedium
                  className="font16"
                  style={{ marginBottom: 12 }}
                >
                  Description
                </TypeFezNeueMedium>
                {description ? (
                  <TypeFezNeueRegular
                    style={{ maxWidth: "100%", wordWrap: "break-word" }}
                  >
                    {description}
                  </TypeFezNeueRegular>
                ) : (
                  <TypeFezNeueRegular
                    style={{ fontStyle: "italic", color: colors.grey6c }}
                  >
                    No Description
                  </TypeFezNeueRegular>
                )}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 16,
                  width: "100%",
                  marginBottom: 24,
                  marginTop: 24,
                }}
              >
                <TypeFezNeueMedium style={{ fontSize: 16 }}>
                  Supporting Document
                </TypeFezNeueMedium>
                <div
                  style={{
                    padding: 8,
                    display: "flex",
                    borderRadius: 8,
                    border: "1px solid #E6E6E8",
                    justifyContent: "space-between",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (!newAttachmentData.length) return;
                    show({ files: newAttachmentData });
                  }}
                >
                  <div style={{ display: "flex", gap: 8 }} className="hover">
                    <div
                      style={{
                        height: 44,
                        width: 44,
                        borderRadius: 6,
                        backgroundColor: "#E1EEFF",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img src={jpegIcon} />
                    </div>
                    <div>
                      <TypeFezNeueMedium>
                        {newAttachmentData?.length} Supporting Documents
                      </TypeFezNeueMedium>
                      <TypeFezNeueMedium style={{ color: "#909098" }}>
                        See all supporting documents
                      </TypeFezNeueMedium>
                    </div>
                  </div>
                  <img src={chevronRight} style={{ marginRight: 8 }} />
                </div>
              </div>
              <InvoicePaymentStatusLine transaction={details} />
            </div>
          )
        }
        wingComponent={
          <div
            style={{
              paddingRight: 20,
              height: "100%",
              overflowY: "scroll",
              zIndex: 121,
            }}
          >
            {loading ? (
              <Skeleton width={"100%"} height={1000} />
            ) : (
              <PDFOrImageViewer url={file_url} />
            )}
            <div style={{ height: 20 }} />
          </div>
        }
      />
      {rejectModal()}
    </>
  );
};
