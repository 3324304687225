import React from "react";
import Skeleton from "react-loading-skeleton";
import { colors } from "../assets/colors";
import { GothamMedium, GothamRegular } from "../components/Text";
import { stateColor } from "../pageComponents/transactionReportIndexComponents";

const Container = ({ children }) => (
  <div
    className="d-flex justify-content-between align-items-center"
    style={{ marginBottom: 12 }}
  >
    {children}
  </div>
);

export const LeftRightList = ({ array, loading: loadingProps }) =>
  array.map((item, index) => {
    const {
      left,
      right,
      leftBold,
      rightBold,
      spacer,
      boldAll,
      isStatus,
      icon,
      loading,
      isSmall,
      dotSpacer,
      blueText,
      w100Left,
      w100Right,
      widthRight,
      styleLeft,
      styleRight,
      isBox,
      spacerNotFull,
      dotSpacerNotFull,
      isRed,
    } = item || {};

    if (!item) return null;
    if (spacer) return <hr key={index} />;
    if (spacerNotFull) return <hr key={index} style={{ marginLeft: 20 }} />;
    if (dotSpacerNotFull)
      return (
        <hr
          key={index}
          style={{ borderTop: "2px dotted #C2C2C2", marginLeft: 20 }}
        />
      );
    if (dotSpacer)
      return <hr key={index} style={{ borderTop: "2px dotted #C2C2C2" }} />;
    if (loading || loadingProps)
      return (
        <Container key={index}>
          <Skeleton width={125} height={20} />
          <Skeleton width={125} height={20} />
        </Container>
      );
    if (!left && !right) return null;

    const getFontFamily = (isBold) => (isBold ? "GothamBold" : "GothamBook");

    const textColor = (isStatus, isLeft) => {
      if (isRed) return colors.redE7;
      if (isStatus) return stateColor(right);
      if (boldAll || leftBold) return colors.blue10;
      if (!isLeft && rightBold) return colors.blue10;
      if (blueText) return colors.blue19;
      return colors.grey72;
    };

    const defaultStyle = {
      margin: 0,
      fontSize: isSmall ? 12 : 14,
    };

    return (
      <Container key={index}>
        <div
          className="d-flex align-items-center"
          style={{
            width: w100Left ? "100%" : "40%",
            ...styleLeft,
          }}
        >
          {Boolean(icon) && <img src={icon} style={{ marginRight: 8 }} />}
          <GothamRegular
            style={{
              fontFamily: getFontFamily(leftBold || boldAll),
              color: textColor(false, true),
              ...styleLeft,
              ...defaultStyle,
            }}
          >
            {left}
          </GothamRegular>
        </div>
        {isBox ? (
          <GothamMedium
            style={{
              fontSize: 10,
              padding: "4px 8px",
              border: "1px solid #E6E6E8",
              borderRadius: 4,
              marginBottom: 0,
              lineHeight: "12px",
            }}
          >
            {right}
          </GothamMedium>
        ) : (
          <GothamRegular
            style={{
              width: widthRight ? widthRight : w100Right ? "100%" : "60%",
              textAlign: "right",
              fontFamily: getFontFamily(rightBold || boldAll),
              color: textColor(isStatus, false),
              ...defaultStyle,
              ...styleRight,
            }}
          >
            {right}
          </GothamRegular>
        )}
      </Container>
    );
  });
