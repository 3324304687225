import { get } from "lodash";
import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import { Modal, Table } from "reactstrap";
import { colors } from "../assets/colors";
import downloadIcon from "../assets/images/download-icon.svg";
import xIcon from "../assets/images/x_calculator.svg";
import { TablePagination } from "../pageComponents/pagination";
import { Tbody, TdHeader } from "../pageComponents/tableComponents";
import { apiBusiness } from "../tools/api";
import { CustomButton } from "./Buttons";
import { CheckBox, useIdsTable } from "./Checkbox";
import { StatusBadge } from "./Status";
import { GothamMedium, GothamRegular } from "./Text";
import { windowDimension } from "./tools";

const DownloadReceiptModalTable = ({
  toggle,
  isOpen,
  onSubmit,
  isLoading = false,
}) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const { handleClickItem, handleClickAll, isAllChecked, selectedIds } =
    useIdsTable(data);

  const { figmaWidth, figmaHeight, height, width } = windowDimension();

  const { query } = useRouter();

  const getTotalPage = 1;
  const { page = 1, per_page = 10, id, status } = query;

  const dataThead = [
    {
      title: <CheckBox isActive={isAllChecked} onClick={handleClickAll} />,
      handle: "reference_id",
    },
    {
      title: "Transaction ID",
      handle: "reference_id",
    },

    {
      title: "Recipient Name",
      handle: "recipient_name",
    },

    {
      title: "Status",
      handle: "state",
    },
  ];
  const handleClick = () => {};
  const getData = async () => {
    try {
      setLoading(true);
      const url = `/business_transactions/${id}?page=${page}&per_page=${per_page}&q[state_eq]=${status}`;
      const { data } = await apiBusiness.get(url);
      const getData = get(data, "trxb_data", []);
      setData(getData);
    } catch (err) {
      console.log("err:", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (status == "completed") {
      getData();
    }
  }, [query]);

  return (
    <Modal
      toggle={toggle}
      isOpen={isOpen}
      style={{
        position: "absolute",
        bottom: (height - figmaHeight(544)) / 2,
        left: (width - figmaWidth(616)) / 2,
      }}
    >
      <div
        style={{
          backgroundColor: "white",
          borderRadius: 12,
          width: figmaWidth(616),
          //   height: figmaHeight(544),
        }}
        className="p-4"
      >
        <div className="d-flex justify-content-between align-items-center">
          <GothamMedium
            style={{ color: colors.teal, fontSize: 24 }}
            className="my-0"
          >
            Download Receipt
          </GothamMedium>
          <img src={xIcon} className="darkhover" onClick={toggle} />
        </div>
        <GothamRegular style={{ fontSize: 16 }} className="mt-5 ">
          Select which transaction to get the receipt
        </GothamRegular>
        <div
          className="table-responsive mt-5"
          style={{
            border: "1px solid rgba(194, 194, 194,.5)",
            maxHeight: figmaHeight(200),
          }}
        >
          <Table className="table-transfez w-100 mb-0">
            <>
              <thead>
                <tr>
                  {dataThead.map(({ title, handle }, index) => {
                    const isFirst = index === 0;
                    if (isFirst) {
                      return <td key={index}>{title}</td>;
                    }
                    return (
                      <TdHeader
                        isCustomStyle
                        key={index}
                        onClick={() => {
                          handleClick(handle);
                        }}
                      >
                        {title}
                      </TdHeader>
                    );
                  })}
                </tr>
              </thead>

              <Tbody
                loading={loading}
                totalHeader={dataThead.length}
                totalItem={per_page}
              >
                {data.map(({ reference_id, beneficiary, state, id }, index) => {
                  const getBene = (string) => get(beneficiary, string, "");
                  const getName =
                    getBene("receiving_registered_name") ||
                    getBene("receiving_first_name") +
                      getBene("receiving_last_name");
                  const isActive = selectedIds.includes(id);
                  return (
                    <tr
                      key={index}
                      style={{ backgroundColor: isActive ? "#C1EFF4" : "" }}
                    >
                      <td>
                        <CheckBox
                          isActive={isActive}
                          onClick={() => handleClickItem(id, isActive)}
                        />
                      </td>
                      <td>{reference_id}</td>

                      <td>{getName}</td>

                      <td>
                        <StatusBadge
                          state={state}
                          className=""
                          style={{ width: "100%" }}
                        />
                      </td>
                    </tr>
                  );
                })}
              </Tbody>
            </>
          </Table>
        </div>
        <TablePagination total={getTotalPage} query />
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: 24,
          }}
        >
          <CustomButton
            isLoading={isLoading}
            onClick={() => onSubmit(selectedIds)}
            className="mr-4  pl-4 pr-3"
            style={{ fontSize: 20, height: 48, marginBottom: figmaHeight(32) }}
            disabled={!selectedIds.length}
            leftIcon={<img src={downloadIcon} className="mr-2" />}
          >
            Download
          </CustomButton>
        </div>
      </div>
    </Modal>
  );
};

export default DownloadReceiptModalTable;
