import warningYellow from "../../assets/images/warning-yellow.svg";
import { CustomButton } from "../../components/Buttons";
import { GothamMedium, GothamRegular } from "../../components/Text";
import { CenteredModal } from "../../modals/centeredComponents";

export const MobileAlertModal = ({ isOpen, toggle }) => (
  <CenteredModal isOpen={isOpen} toggle={toggle}>
    <div
      className="d-flex align-items-center justify-content-center"
      style={{ flexDirection: "column" }}
    >
      <img src={warningYellow} style={{ width: 40 }} />
      <GothamMedium className="my-3 font16">
        The window screen is too small
      </GothamMedium>
      <GothamRegular className="font12" style={{ textAlign: "center" }}>
        We recommend opening Transfez Business on a bigger window screen to
        provide a better user experience
      </GothamRegular>
      <CustomButton
        style={{ width: "100%", fontSize: 16, lineHeight: "24px" }}
        onClick={toggle}
        className="mt-4"
      >
        OK
      </CustomButton>
    </div>
  </CenteredModal>
);
