import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import crossBorderIcon from "../../../assets/images/crossborder-icon-40.svg";
import divideIcon from "../../../assets/images/divide-icon.svg";
import downloadIcon from "../../../assets/images/download-history-white.svg";
import minusIcon from "../../../assets/images/minus-icon.svg";
import dotIconSmall from "../../../assets/images/dot-grey-extrasmall.svg";
import proceedIcon from "../../../assets/images/proceed-payment.svg";
import refundIcon from "../../../assets/images/refund-icon.svg";
import { CustomButton } from "../../../components/Buttons";
import {
  MiddleWrapper,
  RightCardModalAtomic,
} from "../../../components/CardModal";
import { LeftRightList } from "../../../components/Lists";
import { useModalHook } from "../../../components/Modals";
import { booleanState, StatusLabel } from "../../../components/Status";
import { GothamMedium } from "../../../components/Text";
import {
  customDateFormatter,
  flagIso3,
  formatCurrency,
  formatCurrencyNoDecimal,
} from "../../../components/tools";
import { useAlertDecider } from "../../../pageComponents/crossBorderSingleConfirmation";
import { transactionBeneficiaryDetails } from "../../../pageComponents/transactionDetailsComponents";
import { fetch, useDownload } from "../../../tools/api";
import { BankAccountFormModal } from "../../smartUploadReceiptModal/bankAccountFormModal";
import { ActivityDetailsHeader } from "../activityModalComponents";
import { CenterendIconLocalTransfer } from "../localDisbursementBatchModal/transactionModal";
import { RecipientMinimalist } from "../transactionList";
import { crossBorderSingleWorkflowComponents } from "./components";
import { CrossBorderSinglePayment } from "./payment/parent";
import { CrossBorderSingleStatusLine } from "./status";
import { defaultAfterSuccessActivity } from "../localDisbursementBatchModal/logics";
import SuspendModal from "../../../components/SubscriptionComponents/SuspendModal/SuspendModal";
import useSubscriptionUsage from "../../../tools/hooks/useSubscriptionUsage";
import { eventsTracker } from "../../../universalFunctions/events";
import { DirectPaymentInstructionModal } from "./DirectPaymentInstructionModal";
import { useGetAuth } from "../../../contexts/AuthContext";
import { ButtonRetryHub } from "./button";

export const CrossBorderSingleDetailsModal = ({
  isOpen,
  toggle,
  data,
  isClose,
  handleRefund: handleRefundProps = () => {}, // use this only from bank transfer
  setData,
  afterSuccess: afterSuccessProps,
}) => {
  const detailsTransaction = data?.details?.transaction || {};
  const { id: idActivity, originator_id, description, status } = data || {};
  const { id } = detailsTransaction;
  const { user } = useGetAuth();
  const { id: userId } = user || {};

  const isFromTransactionActivities = id;

  // don't change this order
  const defaultId = originator_id || idActivity || id;
  // don't change this order

  const defaultTransaction = detailsTransaction || data;
  const {
    data: transaction,
    refetch,
    loading,
  } = fetch({
    url: `/partner_transactions/${defaultId}`,
    formatter: (data) => data?.data,
    woInit: true,
    defaultValue: defaultTransaction,
    afterSuccess: (res) =>
      defaultAfterSuccessActivity({
        obj: {
          category: "single_cross_border",
          changedStatus: "in_progress",
          id: defaultId,
          state: "validating_payment",
        },
        res,
        setData,
      }),
  });
  useEffect(() => {
    if (!isOpen) return;
    if (!defaultId) return;
    refetch();
  }, [isOpen, defaultId]);

  const {
    created_at,
    beneficiary,
    reference_id,
    destination_country,
    destination_currency,
    destination_amount,
    source_currency: source_currencyRaw,
    sent_amount,
    bank_fee,
    fee,
    rate,
    state,
    total_trx_amount,
    payment_channel,
    payment_method,
    approved_by_id,
    tfhub_payment_id,
    error_flag,
  } = transaction || {};

  const source_currency = source_currencyRaw || "IDR";
  const isBankTransfer = payment_method === "bank_transfer";

  const statusForLabel = state || status;
  const {
    receiving_registered_name,
    receiving_first_name,
    receiving_last_name,
    bank_name,
    bank_account_number,
    recipient_email,
    iban,
    swift_bic_code,
  } = beneficiary || {};

  const deliveryMethod = useAlertDecider({ data: payment_channel });
  const transfezFee = Number(fee) - Number(bank_fee);
  const beneficiaryName =
    receiving_registered_name ||
    `${receiving_first_name || ""} ${receiving_last_name || ""}` ||
    recipient_email;

  const {
    isCompleted,
    isError,
    isRefund,
    isAwaitingRefund,
    isValidatingPayment,
    isWaitingForRelease,
  } = booleanState(state);

  const isShowStatus = isCompleted || isError || isRefund || isAwaitingRefund;
  const isShowPay = isBankTransfer && isWaitingForRelease;
  const isShowRetryHub =
    !isBankTransfer && isWaitingForRelease && !tfhub_payment_id;
  const isUserLoginSameAsUserRelease =
    Number(approved_by_id) === Number(userId);

  const headerData = {
    type: description || `Transfer to ${beneficiaryName}`,
    icon: false,
    title: `${source_currency} ${formatCurrency(total_trx_amount || 0)}`,
  };

  const recipientData = {
    name: beneficiaryName,
    bank: bank_name,
    bankAccount:
      bank_account_number ||
      `${iban} (Iban)` ||
      `${swift_bic_code} (Swift Code)`,
    iso: flagIso3(destination_country) || "id",
  };

  const { pathname } = useRouter();

  const isCurrentScreenPaymentInstruction = pathname.includes(
    "payment-instruction"
  );

  const beneficiaryDetails = [transactionBeneficiaryDetails(beneficiary || {})];

  const leftRightArray = [
    {
      left: "Date",
      right: customDateFormatter(created_at || ""),
    },
    {
      left: "Reference",
      right: reference_id,
    },
    // ...(isShowStatus
    //   ? [{ left: "Status", right: <StatusLabel status={statusForLabel} /> }]
    //   : []),
    { spacer: true },
    {
      left: "You Pay",
      right: `${source_currency} ${formatCurrencyNoDecimal(
        Number(sent_amount) + Number(fee)
      )}`,
      rightBold: true,
      leftBold: true,
    },
    {
      left: "Delivery Method",
      right: deliveryMethod,
    },
    {
      left: "Transfez fee",
      right: `${source_currency} ${formatCurrencyNoDecimal(transfezFee)}`,
      icon: dotIconSmall,
      styleLeft: { marginLeft: -3 },
    },
    {
      left: "Bank Fee",
      right: `${source_currency} ${formatCurrencyNoDecimal(bank_fee)}`,
      icon: dotIconSmall,
      styleLeft: { marginLeft: -3 },
    },
    {
      dotSpacerNotFull: true,
    },
    {
      left: "Total Fee",
      right: `${source_currency} ${formatCurrencyNoDecimal(fee)}`,
      icon: minusIcon,
    },
    {
      left: "Exchange rate",
      right: `${destination_currency} 1 = ${source_currency} ${formatCurrency(
        rate
      )}`,
      icon: divideIcon,
    },
    {
      dotSpacer: true,
    },
    {
      left: "Recipient Gets",
      right: `${destination_currency} ${formatCurrency(destination_amount)}`,
      rightBold: true,
      leftBold: true,
    },
  ];

  const afterSuccess = (res) => {
    refetch();
    afterSuccessProps(res);
  };

  const { isSuspended, loadingSubscriptionUsage } = useSubscriptionUsage();

  const {
    isOpen: isOpenDirectInstruction,
    toggle: toggleDirectInstruction,
    close: closeDirectInstruction,
  } = useModalHook();
  const { toggle: toggleRefund, isOpen: isOpenRefund } = useModalHook();
  const { toggle: toggleSuspendModal, isOpen: isOpenSuspendModal } =
    useModalHook();
  const { buttons, rejectModal, isRejection, openPinModal } =
    crossBorderSingleWorkflowComponents({
      transaction,
      afterSuccess,
    });
  const { handleDownload, loading: downloadLoading } = useDownload();

  const defaultStyle = {
    height: 32,
    fontSize: 14,
  };

  const ButtonsDecider = ({ hideStatus }) => {
    // if (isValidatingPayment) return null;

    const DefaultButton = (props) => (
      <CustomButton
        style={{
          marginTop: 16,
          padding: "6px 24px",
          ...defaultStyle,
        }}
        {...props}
      />
    );

    const showDownload =
      state === "completed" ||
      state === "validating_payment" ||
      state === "on_progress";

    const showRefund =
      (state == "rejected" || state == "failed") &&
      !isFromTransactionActivities;

    if (isShowRetryHub) {
      return (
        <ButtonRetryHub data={transaction} refetch={refetch} />
      );
    }

    if (isShowPay) {
      const onClick = () => {
        if (isSuspended) return toggleSuspendModal();
        toggleDirectInstruction();
        // return toggle();
      };

      if (isUserLoginSameAsUserRelease) {
        return (
          <DefaultButton onClick={onClick} isLoading={loadingSubscriptionUsage}>
            <img src={proceedIcon} style={{ width: 20, marginRight: 8 }} />
            Proceed to Payment
          </DefaultButton>
        );
      }
      return <></>;
    }

    if (showDownload) {
      return (
        <DefaultButton
          isLoading={downloadLoading}
          onClick={() => {
            eventsTracker(`download_receipt_crossborder_single`, {
              id: reference_id,
              state,
            });
            handleDownload({
              url: `/prints/${defaultId}/transaction_receipt.pdf`,
              fileName: `Transfez-Invoice-${defaultId}.pdf`,
            });
          }}
        >
          <img src={downloadIcon} style={{ width: 20, marginRight: 8 }} />
          Download Receipt
        </DefaultButton>
      );
    }
    if (showRefund)
      return (
        <DefaultButton
          onClick={toggleRefund}
          style={{ padding: "0px 24px", height: 32, marginTop: 16 }}
        >
          <img src={refundIcon} className="mr-2" />
          Refund
        </DefaultButton>
      );

    return buttons();
  };

  const [loadingRefund, setLoadingRefund] = useState(false);
  const [middleHeight, setMiddleHeight] = useState(0);

  const handleRefund = async (values) => {
    const data = { ...values, originator_id };
    setLoadingRefund(true);
    handleRefundProps && (await handleRefundProps(data));
    toggleRefund();
    await refetch();
    setLoadingRefund(false);
  };

  return (
    <>
      <RightCardModalAtomic
        hideModal={isOpenRefund || isRejection || openPinModal}
        isOpen={isOpen}
        loading={loading}
        header={
          <ActivityDetailsHeader
            toggle={toggle}
            data={headerData}
            isClose={isClose}
            loading={loading}
          />
        }
        headerStyle={{ paddingBottom: 48 }}
        middleHeight={middleHeight}
        middle={
          <MiddleWrapper setHeight={setMiddleHeight} height={middleHeight}>
            <CenterendIconLocalTransfer icon={crossBorderIcon} />
          </MiddleWrapper>
        }
        paddingTop={6}
        body={
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <StatusLabel status={statusForLabel} />
              {ButtonsDecider({ hideStatus: isShowStatus })}
            </div>
            <div style={{ marginTop: 24 }}>
              <LeftRightList array={leftRightArray} />
            </div>
            <GothamMedium style={{ fontSize: 16, marginTop: 24 }}>
              Recipient
            </GothamMedium>
            <RecipientMinimalist data={recipientData} />
            <GothamMedium style={{ fontSize: 16, marginTop: 24 }}>
              Updates
            </GothamMedium>
            <CrossBorderSingleStatusLine transaction={transaction} isModal />
          </>
        }
      />
      <BankAccountFormModal
        isOpen={isOpenRefund}
        toggle={toggleRefund}
        onSubmit={handleRefund}
        loading={loadingRefund}
        isClose={false}
        title="Refund to bank account"
        buttonMsg="Submit"
      />
      <SuspendModal
        state="suspended"
        isOpen={isOpenSuspendModal}
        toggle={toggleSuspendModal}
      />
      <DirectPaymentInstructionModal
        data={transaction}
        isOpen={isOpenDirectInstruction}
        toggle={toggleDirectInstruction}
        close={closeDirectInstruction}
      />
      {rejectModal()}
    </>
  );
};
