import { noCase } from "change-case";
import React, { useState } from "react";
import { colors } from "../../assets/colors";
import { EmptyScreenSearch } from "../../components/EmptyScreen";
import { NakedSearchInput } from "../../components/inputs";
import { booleanState, StatusLabel } from "../../components/Status";
import { GothamMedium, GothamRegular } from "../../components/Text";
import { flagIso3, formatCurrencyNoDecimal } from "../../components/tools";
import { NameAvatarWithFlag } from "./localDisbursementBatchModal/transactionModal";

export const WingsComponents = ({
  data = [],
  selectedWingsItem,
  setSelectedWingsItem,
  isShowSummary = false,
  overviewRaw = [],
  type = "crossborder-batch",
}) => {
  const isCrossBorderBatch = type === "crossborder-batch";
  const isLocalBatch = type === "local-batch";
  const isPayroll = type === "payroll";

  const [search, setSearch] = useState("");

  const selectedId = selectedWingsItem?.id || {};

  const filteredArray = data?.filter(({ leftTop }) =>
    noCase(leftTop).includes(noCase(search))
  );

  return (
    <div
      style={{
        paddingRight: 20,
        height: "100%",
        overflowY: "auto",
        overflowX: "hidden",
      }}
    >
      <GothamMedium className="font16" style={{ marginBottom: 20 }}>
        All Transactions
      </GothamMedium>
      {isShowSummary && (
        <div
          className="d-flex justify-content-between"
          style={{ marginTop: 20, marginBottom: 20 }}
        >
          {overviewRaw.map((item, index) => {
            const { number, color, title } = item;
            return (
              <div
                key={index}
                style={{
                  width: `calc(${100 / 3}% - 16px)`,
                  height: 56,
                  borderRadius: 12,
                  border: `1px solid ${colors.greye6}`,
                  padding: "0px 16px",
                  gap: 12,
                }}
                className="d-flex justify-content-center align-items-center"
              >
                <GothamMedium style={{ color }}>{title}</GothamMedium>
                <GothamMedium>{number}</GothamMedium>
              </div>
            );
          })}
        </div>
      )}
      {/* <div
        style={{
          height: 1,
          marginTop: 20,
          backgroundColor: colors.greye6,
          marginBottom: 20,
        }}
      /> */}
      <NakedSearchInput
        onChange={(search) => setSearch(search)}
        placeholder={"Search by recipient name"}
      />
      {/* <div
        className="d-flex justify-content-between"
        style={{ padding: "12px 8px" }}
      > */}
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "80% 20%",
          padding: "12px 8px",
        }}
      >
        <GothamRegular>Recipient</GothamRegular>
        <GothamRegular style={{ textAlign: "right" }}>Amount</GothamRegular>
      </div>
      <hr className="mt-0 mb-2" />
      {filteredArray.length ? (
        filteredArray.map((item, index) => {
          const {
            leftTop: nameTrx,
            rightTop: amount,
            leftBot,
            status,
            id,
            destination_country,
            recipient,
            local_recipient,
          } = item;
          const { isWaitingForPayment, isPending } = booleanState(status);
          const isBgPink = selectedId === id;

          const nameDeciderLocalBatch = () => {
            const nameLocalRecipient = local_recipient?.name;
            const nameRecipient = recipient?.name;

            return nameRecipient ? nameRecipient : nameLocalRecipient;
          };

          const name = isLocalBatch ? nameDeciderLocalBatch() : nameTrx;

          const statusDecider = () => {
            if (isCrossBorderBatch) return status && !isWaitingForPayment;
            if (isLocalBatch) return status && !isPending;
            if (isPayroll) return isShowSummary;
            return false;
          };

          const isShowStatus = statusDecider();

          return (
            <div
              onClick={() => setSelectedWingsItem(item)}
              key={index}
              style={{
                padding: 16,
                paddingLeft: 0,
                paddingRight: 0,
                backgroundColor: isBgPink && colors.lightPink,
                display: "grid",
                gridTemplateColumns: "85% 15%",
              }}
              className={`align-items-center darkhover p-2`}
            >
              <div className="d-flex align-items-center">
                <NameAvatarWithFlag
                  name={name}
                  iso={flagIso3(destination_country) || "id"}
                />
                <div className="ml-1">
                  <GothamRegular
                    style={{
                      lineHeight: "20px",
                      maxWidth: 350,
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                    }}
                  >
                    {name}
                  </GothamRegular>
                  {leftBot && (
                    <GothamRegular
                      style={{ lineHeight: "20px", color: colors.grey6c }}
                    >
                      {leftBot}
                    </GothamRegular>
                  )}
                </div>
                {isShowStatus && (
                  <StatusLabel status={status} style={{ marginLeft: 12 }} />
                )}
              </div>
              <GothamRegular style={{ textAlign: "right" }}>
                {amount}
              </GothamRegular>
            </div>
          );
        })
      ) : (
        <EmptyScreenSearch />
      )}
    </div>
  );
};
