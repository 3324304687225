import { useRouter } from "next/router";
import React, { Fragment, useEffect, useState } from "react";
import { colors } from "../assets/colors";
import leftArrow from "../assets/images/left-arrow-pagination.svg";
import rightArrow from "../assets/images/right-arrow-pagination.svg";
import { CustomSelect } from "../components/inputs";
import { GothamRegular } from "../components/Text";

const CustomPagination = ({ total, limit }) => {
  const { push, query } = useRouter();
  const currPage = Number(query.page || 1);
  const totalPage = Math.ceil(total / limit);

  const goToPage = (number) => {
    push({ query: { ...query, page: number } });
  };
  const downPage = () => {
    if (currPage !== 1) {
      push({ query: { ...query, page: currPage - 1 } });
    }
  };
  const upPage = () => {
    if (totalPage !== currPage) {
      push({ query: { ...query, page: currPage + 1 } });
    }
  };
  if (!total) {
    return null;
  }
  return (
    <div
      className="mx-4 mt-2 px-3 pt-3 align-items-center d-flex justify-content-center"
      style={{
        borderRadius: "12px",
        backgroundColor: "white",
      }}
    >
      <DefaultPagination
        goToPage={goToPage}
        downPage={downPage}
        upPage={upPage}
        totalPage={totalPage}
        currPage={currPage}
        isAbsolute={false}
      />
    </div>
  );
};

export default CustomPagination;

const DefaultPagination = ({
  upPage,
  downPage,
  goToPage,
  totalPage,
  currPage,
  isAbsolute = true,
}) => {
  const isLong = totalPage > 6;
  const isFirstPage = currPage == 1;
  const isSecondPage = currPage == 2;
  const isThirdPage = currPage == 3;
  const isLast = totalPage === currPage;
  const isSecondLast = totalPage - 1 === currPage;
  const isThirdLast = totalPage - 2 === currPage;

  const pageNumbers = isLong
    ? isFirstPage || isSecondPage
      ? [1, 2, 3, "...", totalPage]
      : isThirdPage
      ? [1, 2, 3, 4, "...", totalPage]
      : isLast || isSecondLast
      ? [1, "...", totalPage - 2, totalPage - 1, totalPage]
      : isThirdLast
      ? [1, "...", totalPage - 3, totalPage - 2, totalPage - 1, totalPage]
      : [1, "...", currPage - 1, currPage, currPage + 1, "...", totalPage]
    : [];

  if (!isLong) {
    for (let i = 1; i <= totalPage; i++) {
      pageNumbers.push(i);
    }
  }

  return (
    <ul
      className="pagination"
      style={
        isAbsolute
          ? {
              position: "absolute",
              bottom: -1,
              right: 12,
            }
          : {}
      }
    >
      {pageNumbers.map((num, index) => {
        const isFirst = index === 0;
        const isLast = totalPage === num;
        const isActive = num == currPage;
        const disabled = num === "...";
        return (
          <Fragment key={index}>
            {isFirst && (
              <img
                src={leftArrow}
                className={"darkhover mr-1"}
                onClick={downPage}
              />
            )}
            <li className="page-item">
              <a
                onClick={() => {
                  if (!disabled) {
                    goToPage(num);
                  }
                }}
                style={{
                  backgroundColor: isActive ? colors.blue : "",
                  color: isActive ? "white" : colors.grey72,
                  minWidth: 32,
                  minHeight: 32,
                  borderRadius: 4,
                  fontFamily: "GothamMedium",
                  fontSize: 16,
                  borderWidth: 0,
                }}
                className={disabled ? "page-link" : "page-link darkhover"}
              >
                {num}
              </a>
            </li>
            {isLast && (
              <img
                src={rightArrow}
                className="darkhover ml-1"
                onClick={upPage}
              />
            )}
          </Fragment>
        );
      })}
    </ul>
  );
};

export const BackupPagination = () => {
  const [pageNumber, setPageNumber] = useState(0);

  const batchDataPerPage = 10;
  const pagesVisited = pageNumber * batchDataPerPage;

  const pageCount = Math.ceil(item.length / batchDataPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };
  return (
    <div
      className="mx-5 mt-4 px-3 pt-3"
      style={{
        border: "1px solid rgb(194,194,194)",
        borderRadius: "12px",
      }}
    >
      <ReactPaginate
        previousLabel={<img src={paginatePrevious} />}
        nextLabel={<img src={paginateNext} />}
        breakLabel={"..."}
        pageRangeDisplayed={2}
        marginPagesDisplayed={1}
        pageCount={pageCount}
        onPageChange={changePage}
        containerClassName={"paginationBttns"}
        previousLinkClassName={"previousBttn"}
        nextLinkClassName={"nextBttn"}
        disabledClassName={"paginationDisabled"}
        activeClassName={"paginationActive"}
      />
    </div>
  );
};

const TablePaginationComponent = ({
  total,
  limit,
  currPage,
  setPage,
  query = false,
}) => {
  const totalPage = Math.ceil(total / limit);
  const { push, query: routerQuery } = useRouter();

  const currPageQuery = Number(routerQuery.page || 1);

  const queryGoToPage = (number) => {
    push({ query: { ...routerQuery, page: number } });
  };
  const queryDownPage = () => {
    if (currPageQuery !== 1) {
      push({ query: { ...routerQuery, page: currPageQuery - 1 } });
    }
  };
  const queryUpPage = () => {
    if (totalPage !== currPageQuery) {
      push({ query: { ...routerQuery, page: currPageQuery + 1 } });
    }
  };
  const defaultDownPage = () => {
    if (currPage !== 1) {
      setPage(currPage - 1);
    }
  };

  const defaultGoToPage = (number) => {
    setPage(number);
  };

  const defaultUpPage = () => {
    if (totalPage !== currPage) {
      setPage(currPage + 1);
    }
  };

  const goToPage = (number) => {
    if (query) {
      queryGoToPage(number);
      return;
    }
    defaultGoToPage(number);
  };

  const downPage = () => {
    if (query) {
      queryDownPage();
      return;
    }
    defaultDownPage();
  };

  const upPage = () => {
    if (query) {
      queryUpPage();
      return;
    }
    defaultUpPage();
  };

  return (
    <DefaultPagination
      goToPage={goToPage}
      downPage={downPage}
      upPage={upPage}
      totalPage={totalPage}
      currPage={query ? currPageQuery : currPage}
      isAbsolute
    />
  );
};
const dummyOptions = [
  { label: 5, value: 5 },
  { label: 10, value: 10 },
  { label: 25, value: 25 },
];

export const useTablePagination = (defaultLimit) => {
  const [limit, setLimit] = useState(dummyOptions[defaultLimit || 0]);
  const [page, setPage] = useState(1);
  const onChangeLimit = (val) => {
    setLimit(val);
    setPage(1);
  };
  return { onChangeLimit, limit, setLimit, page, setPage };
};

export const useTablePaginationQuery = () => {
  const { push, query } = useRouter();
  const pagination = useTablePagination(1);
  const {
    page,
    limit: { value: limit },
  } = pagination;

  useEffect(() => {
    push({ query: { ...query, page, limit } });
  }, [page, limit]);
  return pagination;
};

export const TablePagination = ({
  limit = dummyOptions[1],
  options = dummyOptions,
  onChangeLimit,
  total,
  page,
  setPage,
  query = false,
  isSeamless,
  noPerPage = false,
}) => {
  const { query: routerQuery, push } = useRouter();
  const getPerPage = routerQuery.per_page || 0;
  const [limitQuery, setLimitQuery] = useState(
    getPerPage
      ? options.filter(({ value }) => value == getPerPage)[0]
      : options[1]
  );
  const onChangeQuery = (val) => {
    push({ query: { ...routerQuery, per_page: val.value, page: 1 } });
    setLimitQuery(val);
  };
  const getLimit = query ? limitQuery?.value : limit?.value;
  return (
    <>
      {isSeamless && (
        <div style={{ height: 1, backgroundColor: "rgba(0,0,0,.05)" }}></div>
      )}
      <div
        className="d-flex justify-content-between align-items-center"
        style={{
          height: 64,
          border: `${isSeamless ? "0px" : "1px"} solid rgba(194, 194, 194,.5)`,
          borderTopWidth: 0,
          position: "relative",
        }}
      >
        {!noPerPage && (
          <div
            className="d-flex h-100 align-items-center"
            style={{ paddingLeft: 12 }}
          >
            <GothamRegular
              className="m-0 mr-2"
              style={{ color: colors.grey72 }}
            >
              Results per page
            </GothamRegular>
            <CustomSelect
              containerStyle={{ width: 75, fontSize: 14, height: 44 }}
              options={options}
              value={query ? limitQuery : limit}
              onChange={query ? onChangeQuery : onChangeLimit}
            />
          </div>
        )}
        <TablePaginationComponent
          limit={getLimit}
          total={total}
          currPage={page}
          setPage={setPage}
          query={query}
        />
      </div>
    </>
  );
};
