import { customDateFormatter, formatCurrency } from "../../../components/tools";

export const withdrawFormatter = (object) => {
  const {
    category: type,
    team,
    created_at,
    amount,
    currency,
    status,
    reference_number,
    user,
  } = object;
  const { name: userName, email: userEmail } = user || {};
  const leftTag = team?.name;
  const leftTop = "Withdraw";

  const leftBottom = `• ${reference_number} • ${customDateFormatter(
    created_at
  )} • ${userName || userEmail} •`;
  const leftStatus = status;

  const rightTop = `-${currency} ${formatCurrency(amount || 0)}`;

  return {
    ...object,
    leftTag,
    leftTop,
    leftBottom,
    leftStatus,
    rightTop,
    type,
  };
};
