import {
  customDateFormatter,
  formatCurrency,
  formatCurrencyNoDecimal,
} from "../../../components/tools";
import { recipientName } from "../../../universalFunctions/universalTools";

export const singleFormatter = (object) => {
  const {
    team,
    details,
    created_at,
    category: type,
    user,
    noDecimal,
  } = object || {};

  const transaction = details?.transaction || {};

  const {
    beneficiary = {},
    destination_amount,
    destination_currency,
    sent_amount,
    source_currency,
    fee,
    reference_id,
    user_email,
    state,
    user: userTrx,
  } = transaction || {};

  const currencyFormatter = noDecimal
    ? formatCurrencyNoDecimal
    : formatCurrency;

  const { name: userName, email: userEmail } = user || userTrx || {};
  const leftTop = `${recipientName(beneficiary)}`;
  const leftBottom = ` • ${reference_id} • ${customDateFormatter(
    created_at
  )} • ${userName || userEmail} •`;
  const leftStatus = state;
  const leftTag = team?.name;

  const rightBottom = `${destination_currency} ${formatCurrency(
    destination_amount
  )}`;

  const rightTop = `-${source_currency} ${currencyFormatter(
    Number(sent_amount) + Number(fee)
  )}`;

  return {
    ...object,
    leftTop,
    leftTag,
    leftStatus,
    leftBottom,
    rightTop,
    rightBottom,
    type,
  };
};
