import React, { useState } from "react";
import { Col, Table } from "reactstrap";
import titleCase from "title-case";
import { colors } from "../assets/colors";
import batchIcon from "../assets/images/batch-icon.svg";
import coin from "../assets/images/coin.svg";
import downloadIcon from "../assets/images/download-icon.svg";
import minus from "../assets/images/minus-icon.svg";
import recipientIcon from "../assets/images/recipients-icon.svg";
import { CustomButton } from "../components/Buttons";
import {
  DefaultCustomComponent,
  MainCardHeaderMolecule,
  RightCardModalAtomic,
} from "../components/CardModal";
import { FilterFields } from "../components/FilterFields/parent";
import { HeaderWithButton } from "../components/HeaderWithButton";
import { useModalHook } from "../components/Modals";
import { DefaultQueryInput } from "../components/SearchAndFilterCard";
import {
  booleanState,
  StatusAccountIcon,
  StatusBadge,
} from "../components/Status";
import {
  GothamMedium,
  GothamRegular,
  TextInlineMedium,
} from "../components/Text";
import { TopTabs } from "../components/TopTabs";
import {
  TablePagination,
  useTablePaginationQuery,
} from "../pageComponents/pagination";
import { Tbody, TdHeader } from "./tableComponents";

const DetailDetailsModal = () => {
  const data = [
    {
      title: "Transaction Details",
      src: coin,
      array: [
        {
          label: "You Send",
          value: "IDR 11,427,319",
        },
        {
          label: "Transaction Fee",
          value: "IDR 0",
          src: minus,
        },
        {
          label: "Converted",
          value: "IDR 20,000,000",
          src: minus,
        },
        {
          label: "Rate",
          value: "SGD 1 = IDR 10,321.82",
          src: minus,
        },
        {
          line: true,
        },
        {
          label: "Recipient Gets",
          value: "AUD 1,100.15",
        },
      ],
    },
    {
      title: "Recipient Details",
      src: recipientIcon,
      inline: false,
      array: [
        {
          label: "Name",
          value: "John Doe",
        },
        {
          label: "Bank Account Number",
          value: "12345678",
        },
        {
          label: "BSB Number",
          value: "123456",
        },
      ],
    },
  ];
  return data.map(({ title, src, array, inline = true }, index) => {
    return (
      <div
        key={index}
        style={{
          border: `1px solid ${colors.greyea}`,
          padding: 12,
          borderRadius: 8,
          marginTop: 16,
        }}
      >
        <div className="d-flex align-items-center" style={{ marginBottom: 20 }}>
          <img src={src} style={{ width: 24, marginRight: 10 }} />
          <GothamMedium className="m-0" style={{ fontSize: 16 }}>
            {title}
          </GothamMedium>
        </div>
        {array.map(({ label, value, src, line }, index) => {
          const isLast = index == array.length - 1;

          if (!inline)
            return (
              <div style={{ marginBottom: isLast ? 0 : 12 }} key={index}>
                <GothamRegular>{label}</GothamRegular>
                <GothamMedium>{value}</GothamMedium>
              </div>
            );
          const Container = ({ children }) => (
            <div
              className="d-flex justify-content-between"
              style={{ marginBottom: isLast ? 0 : 12 }}
              key={index}
            >
              {children}
            </div>
          );
          if (line)
            return (
              <Container>
                <div
                  style={{
                    backgroundColor: colors.greyea,
                    height: 1,
                    width: "100%",
                  }}
                />
              </Container>
            );
          if (src)
            return (
              <Container>
                <div className="d-flex align-items-center">
                  <img src={src} style={{ marginRight: 8 }} />
                  <GothamRegular
                    className="m-0 mt-1"
                    style={{ color: colors.grey72 }}
                  >
                    {label}
                  </GothamRegular>
                </div>
                <GothamRegular
                  className="m-0 mt-1"
                  style={{ color: colors.grey72 }}
                >
                  {value}
                </GothamRegular>
              </Container>
            );
          return (
            <Container>
              <GothamMedium className="m-0">{label}</GothamMedium>
              <GothamMedium className="m-0">{value}</GothamMedium>
            </Container>
          );
        })}
      </div>
    );
  });
};

const EllipsisText = ({ children, style }) => (
  <GothamMedium
    className="m-0"
    style={{
      fontSize: 12,
      width: "50%",
      whiteSpace: "nowrap",
      overflow: "Hidden",
      textOverflow: "ellipsis",
      ...style,
    }}
  >
    {children}
  </GothamMedium>
);

export const stateColor = (state) => {
  const { isError, isOnProgress, isCompleted, isInitial, isCreated } =
    booleanState(state);
  if (isInitial || isCreated) return colors.teal;
  if (isError) return colors.redE9;
  if (isOnProgress) return colors.blue10;
  if (isCompleted) return colors.green28;
  return colors.grey72;
};

const TransactionStatus = ({ state }) => {
  if (!state) return null;

  const color = stateColor(state);

  return (
    <div className="d-flex align-items-center" style={{ marginLeft: 5.5 }}>
      <div
        style={{
          height: 9,
          width: 9,
          borderRadius: 10,
          border: `2px solid ${color}`,
          marginRight: 5.5,
        }}
      />
      <GothamMedium className="m-0" style={{ fontSize: 10, color }}>
        {titleCase(state)}
      </GothamMedium>
    </div>
  );
};

const TransactionReportCardList = ({ data, onClick }) =>
  data.map((item, index) => {
    const { type, title, date, balance, state } = item;
    return (
      <div
        onClick={() => onClick(item)}
        className="darkhover d-flex"
        key={index}
        style={{
          padding: 16,
          paddingLeft: 12,
          paddingRight: 12,
          border: `1px solid ${colors.greyea}`,
          borderRadius: 8,
          marginBottom: 8,
        }}
      >
        <div style={{ marginRight: 12 }}>
          <StatusAccountIcon state={type} />
        </div>
        <div style={{ width: "100%" }}>
          <div className="d-flex justify-content-between">
            <EllipsisText>{title}</EllipsisText>
            <EllipsisText
              style={{ textAlign: "end", fontFamily: "GothamBook" }}
            >
              {balance}
            </EllipsisText>
          </div>
          <div
            style={{
              marginTop: 4,
            }}
            className="d-flex align-items-center"
          >
            <GothamRegular style={{ fontSize: 10 }} className="m-0">
              {date} {state ? " · " : ""}
            </GothamRegular>
            <TransactionStatus state={state} />
          </div>
        </div>
      </div>
    );
  });

const DetailsModal = () => {
  const { isOpen, toggle } = useModalHook();
  const data = [
    {
      type: "single_transaction",
      title: "John Doe",
      date: "31 Oct 2021, 18:46",
      balance: "-IDR 145,242.63",
      state: "on_progress",
    },
    {
      type: "single_transaction",
      title: "John Doe",
      date: "31 Oct 2021, 18:46",
      balance: "-IDR 145,242.63",
    },
  ];
  const handleClick = (item) => {
    toggle();
  };
  return (
    <>
      <Col className="mx-0 px-0" style={{ marginTop: 16 }}>
        <DefaultQueryInput
          name="search_box_modal"
          placeholder="Search Transactions"
        />
      </Col>
      <TransactionReportCardList data={data} onClick={handleClick} />
      <CustomModal
        isOpen={isOpen}
        toggle={toggle}
        data={{
          topLeft: "Ref LOC123456",
          topRight: "31 October 2021, 18:46",
          mainTitle: "Cross Border",
          title: "AUD 1,100.15",
          msg: "John Doe",
        }}
        topTabsData={[
          { screenName: "Progress", screen: <ProgressModal /> },
          { screenName: "Details", screen: <DetailDetailsModal /> },
        ]}
      />
    </>
  );
};

const Dot = ({ children, isBig, isBigDone, isInactive }) => {
  const circle = isBig ? 12 : 8;

  const defaultStyle = {
    width: circle,
    height: circle,
    marginTop: isBig ? 4 : 3,
    borderRadius: 100,
    position: "relative",
  };

  if (isBig && !isBigDone)
    return (
      <div
        style={{
          ...defaultStyle,
          border: `2px solid ${colors.teal}`,
          backgroundColor: "white",
        }}
      >
        {children}
      </div>
    );

  return (
    <div
      style={{
        backgroundColor: isInactive ? colors.greyC2 : colors.teal,
        ...defaultStyle,
      }}
    >
      {children}
    </div>
  );
};

const ProgressItem = ({ item, data, index }) => {
  const { message, date, title } = item;

  const isFirst = index == 0;
  const isLast = index == data.length - 1;

  const bigDot = Boolean(title);
  const isBigDoneDot = bigDot && isLast;

  const titleIndex =
    data
      .map(({ title }, index) => {
        if (title) return index;
      })
      .filter((item) => item)
      .pop() || 0;

  const isInactive = titleIndex < index;
  const isInactiveLine = titleIndex <= index;

  const [lineHeight, setLineHeight] = useState(25);

  return (
    <div className="d-flex justify-content-between" key={index}>
      <div>
        <GothamRegular style={{ fontSize: 10 }}>{date}</GothamRegular>
      </div>
      <div
        className="d-flex "
        style={{ width: `${(243 * 100) / 360}%`, marginBottom: 25 }}
        ref={(e) => {
          if (!e) return;
          if (isLast) return setLineHeight(0);
          const height = e.clientHeight + 25;
          if (bigDot) return setLineHeight(height - 5);
          setLineHeight(height);
        }}
      >
        <div
          style={{ width: `${(22 * 100) / 243}%` }}
          className="d-flex justify-content-center"
        >
          <Dot isBig={bigDot} isBigDone={isBigDoneDot} isInactive={isInactive}>
            <div
              style={{
                position: "absolute",
                height: lineHeight,
                backgroundColor: isInactiveLine ? colors.greyC2 : colors.teal,
                top: bigDot ? 10 : 8,
                width: 2,
                left: isBigDoneDot ? 5 : 3,
              }}
            />
          </Dot>
        </div>
        <div style={{ width: `${(221 * 100) / 243}%` }}>
          {Boolean(title) && (
            <GothamMedium
              style={{
                fontSize: 16,
                color: colors.teal,
              }}
            >
              {title}
            </GothamMedium>
          )}
          <GothamRegular style={{ fontSize: 12 }}>{message}</GothamRegular>
        </div>
      </div>
    </div>
  );
};

const boxDataFormatter = (data) => {
  const { on_progress = 0, failed = 0, completed = 0 } = data || {};
  // biar ngurut
  let obj = {};
  obj["on_progress"] = on_progress;
  obj["failed"] = failed;
  obj["completed"] = completed;
  // biar ngurut

  const keys = Object.keys(obj);
  const result = keys.map((key) => {
    const value = obj[key];
    return { label: titleCase(key), value };
  });
  const showBox = result.filter(({ value }) => value).length;
  return { showBox, result };
};

const ProgressModal = () => {
  const data = [
    { date: "31/10/2021 17:01", message: "You created a transaction" },
    {
      date: "31/10/2021 17:01",
      title: "Waiting for Payment",
      message: "Transaction created. Now please proceed with your payment",
    },
    {
      message: "Paid",
    },
  ];
  const boxData = {
    on_progress: 1,
    failed: 2,
    completed: 3,
  };
  const { result } = boxDataFormatter(boxData);
  return (
    <div style={{ marginTop: 16 }}>
      {data.map((item, index) => (
        <ProgressItem item={item} index={index} data={data} key={index} />
      ))}
      <div className="d-flex justify-content-between">
        {result.map(({ label, value }, index) => {
          const colorDecider = () => {
            const isOnProgress = index == 0;
            const isError = index == 1;
            const isCompleted = index == 2;
            if (isError) return colors.redE9;
            if (isOnProgress) return colors.blue19;
            if (isCompleted) return colors.green28;
            return colors.grey72;
          };
          const color = colorDecider();
          return (
            <div
              key={index}
              style={{
                borderRadius: 8,
                border: `1px solid ${colors.greyea}`,
                width: `${((360 - 32) * 100) / (360 * 3)}%`,
                paddingTop: 8,
                paddingBottom: 8,
              }}
              className="d-flex align-items-center justify-content-center"
            >
              <div>
                <GothamMedium style={{ textAlign: "center", fontSize: 32 }}>
                  {value}
                </GothamMedium>
                <GothamMedium
                  style={{ textAlign: "center", fontSize: 10, color }}
                >
                  {label}
                </GothamMedium>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const CustomModal = ({ isOpen, toggle, topTabsData = [], data = {} }) => {
  const {
    topLeft,
    topRight,
    mainTitle,
    title,
    msg,
    buttonMsg,
    src = batchIcon,
  } = data;
  return (
    <RightCardModalAtomic
      isOpen={isOpen}
      header={
        <MainCardHeaderMolecule
          headerArr={[topLeft, topRight]}
          isClose={false}
          customComponent={
            <DefaultCustomComponent
              title={mainTitle}
              subTitle={title}
              msg={msg}
            />
          }
          icon={<img src={src} />}
          toggle={toggle}
        />
      }
      body={
        <>
          {Boolean(buttonMsg) && (
            <CustomButton style={{ width: "100%" }}>{buttonMsg}</CustomButton>
          )}
          <TopTabs data={topTabsData} />
        </>
      }
    />
  );
};

export const TransactionReportPage = () => {
  const options = [
    { rangedDate: true },
    {
      name: "Type",
      options: [],
      containerStyle: { width: 128 },
    },
    {
      name: "Status",
      options: [],
      containerStyle: { width: 128 },
    },
  ];

  const items = [
    {
      src: batchIcon,
      ref: "BA123456",
      descriptionTitle: "Cross border batch of",
      descriptionTitleBold: "10 Transactions",
      descriptionMsg: "Reference ID CRX123456",
      amount: "IDR 1,500,000,000",
      status: "Waiting Payment",
      date: "31 Oct 2021",
      hour: "18:46",
    },
  ];

  const tableHeads = [
    { title: "Type", width: 64 },
    { title: "Batch Ref.", width: 120 },
    { title: "Description" },
    { title: "Amount Sent", width: 180 },
    { title: "Status", width: 136 },
    { title: "Created", width: 120 },
  ];

  const pagination = useTablePaginationQuery();
  const { toggle, isOpen } = useModalHook();

  const handleClick = (item) => {
    const { type } = item;
    const isReport = type == "report";
    if (isReport) {
      return;
    }
    // toggle();
  };

  return (
    <div className="mx-5">
      <HeaderWithButton
        buttonText="Download Report"
        buttonSrc={downloadIcon}
        title="Transaction Report"
        onClick={() => handleClick({ type: "report" })}
      />
      <FilterFields placeholder="Search" data={options} />
      <div
        style={{
          boxShadow: `0px 2px 8px rgba(88, 88, 88, 0.1)`,
          borderRadius: 8,
        }}
      >
        <div
          className="table-responsive mt-1"
          style={{ borderRadius: 8, overflowY: "hidden" }}
        >
          <Table className="table-seamless w-100 mb-0">
            <thead>
              <tr>
                {tableHeads.map(({ title, width }, index) => (
                  <TdHeader
                    key={index}
                    isCustomStyle
                    style={{ width }}
                    noSortButton
                  >
                    {title}
                  </TdHeader>
                ))}
              </tr>
            </thead>
            <Tbody>
              {items.map((item, index) => {
                const {
                  src,
                  ref,
                  descriptionTitle,
                  descriptionTitleBold,
                  descriptionMsg,
                  amount,
                  status,
                  date,
                  hour,
                } = item;
                const isDescriptionMsg = Boolean(descriptionMsg);
                return (
                  <tr key={index} onClick={() => handleClick(item)}>
                    <td>
                      <img src={src} />
                    </td>
                    <td>
                      <span
                        style={{
                          fontFamily: "GothamMedium",
                          margin: 0,
                          fontSize: 14,
                          color: colors.teal21,
                        }}
                        className="darkhover"
                      >
                        {ref}
                      </span>
                    </td>
                    <td>
                      <GothamRegular
                        className={isDescriptionMsg ? "mb-1" : "m-0"}
                      >
                        {descriptionTitle}
                        <TextInlineMedium>
                          {" " + descriptionTitleBold}
                        </TextInlineMedium>
                      </GothamRegular>
                      {isDescriptionMsg && (
                        <GothamRegular
                          style={{ fontSize: 12, color: colors.grey72 }}
                        >
                          {descriptionMsg}
                        </GothamRegular>
                      )}
                    </td>
                    <td>
                      <GothamRegular className="m-0">{amount}</GothamRegular>
                    </td>
                    <td>
                      <StatusBadge state={status} />
                    </td>
                    <td>
                      <GothamRegular className="mb-1">{date}</GothamRegular>
                      <GothamRegular
                        style={{ fontSize: 12, color: colors.grey72 }}
                      >
                        {hour}
                      </GothamRegular>
                    </td>
                  </tr>
                );
              })}
            </Tbody>
          </Table>
        </div>
        <TablePagination {...pagination} total={20} isSeamless />
      </div>
      <CustomModal
        isOpen={isOpen}
        toggle={toggle}
        data={{
          topLeft: "Ref LOC123456",
          topRight: "31 October 2021, 18:46",
          mainTitle: "Cross Border",
          title: "IDR 10,000,000",
          msg: "10 Transactions",
          buttonMsg: "Pay IDR 10,000,000",
        }}
        topTabsData={[
          { screenName: "Progress", screen: <ProgressModal /> },
          { screenName: "Details", screen: <DetailsModal /> },
        ]}
      />
    </div>
  );
};
