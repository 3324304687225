import { useState } from "react";
import { ToasterHook } from "../../contexts/ToasterContext";
import { apiBusiness } from "../../tools/api";

export const useConfirmApi = ({ afterSuccess }) => {
  const [loading, setLoading] = useState(false);
  const { errorToasterApi, successToaster } = ToasterHook();

  const mutation = async (ids) => {
    try {
      setLoading(true);
      for (let i = 0; i < ids.length; i++) {
        const id = ids[i];
        await apiBusiness.post(`/disbursements/${id}/confirm`);
      }
      successToaster({ msg: "Transaction(s) successfully confirmed!" });
      afterSuccess && afterSuccess(ids);
    } catch (error) {
      errorToasterApi(error);
    } finally {
      setLoading(false);
    }
  };

  return { mutation, loading };
};

export const useResendCallbackApi = ({ afterSuccess }) => {
  const [loading, setLoading] = useState(false);
  const { errorToasterApi, successToaster } = ToasterHook();
  let arr = [];

  const mutation = async (ids) => {
    try {
      setLoading(true);
      for (let i = 0; i < ids.length; i++) {
        const id = ids[i];
        const { data } = await apiBusiness.get(
          `/disbursement/${id}/resend_callback`
        );
        arr.push(data?.data);
      }
      successToaster({
        msg: `You have resent callback for ${ids.length} transactions.`,
      });
      afterSuccess && afterSuccess(ids, arr);
    } catch (error) {
      errorToasterApi(error);
    } finally {
      setLoading(false);
    }
  };

  return { mutation, loading };
};
