import { customDateFormatter } from "../../../../components/tools";
import { useGetAuth } from "../../../../contexts/AuthContext";

export const isYouOrName = ({ name }) => {
  const { user } = useGetAuth();
  const { name: nameUserLogin, email: emailUserLogin } = user || {};
  if (name === emailUserLogin || name === nameUserLogin) return `You`;
  return name;
};

export const isDateExecutorAdmin = ({ data }) => {
  if (!data) return;
  return data.filter(
    (item) =>
      (item.state === "approved" &&
        (item.role === "admin" ||
          item.role === "superadmin" ||
          item.role.includes("payroll_release_approval"))) ||
      item
  )[0]?.approved_at;
};

export const RequiredApprover = ({ data = [] }) => {
  const approvedData = data.filter(({ state }) => state === "approved");
  const requiredData = data.filter(({ state }) => state === "requested");

  const FormattedArray = ({
    data,
    isOrange = false,
    isGreen = false,
    isRequired = false,
  }) => {
    const formatted = data.map((item, index) => {
      const isBeforeLast = index === data.length - 2;
      const isLast = index === data.length - 1;
      const isOnlyOne = data.length === 1;
      const youOrDrafterName = isYouOrName({ name: item.name });

      const commaDecider = () => {
        if (isOnlyOne || isLast) return ``;
        if (isBeforeLast) return `, and`;
        return `,`;
      };

      if (isRequired) {
        return {
          text: `Required by ${youOrDrafterName} to approve${commaDecider()}`,
          isOrange: true,
        };
      }
      return {
        text: `Approved by ${youOrDrafterName}${commaDecider()}`,
        date: customDateFormatter(item.approved_at),
        isGreen: true,
      };
    });
    return formatted;
  };

  const dataApproved = FormattedArray({ data: approvedData, isOrange: false });
  const dataRequired = FormattedArray({
    data: requiredData,
    isRequired: true,
    isOrange: true,
  });

  const mergeData = dataApproved.concat(dataRequired);

  return mergeData;
};
