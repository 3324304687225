import React from "react";
import { windowDimension } from "../tools";
import { DefaultFilterFields } from "./default";
import { PhoneFilterFields } from "./phone";

export const FilterFields = ({
  placeholder,
  data,
  woSearch,
  isModal,
  queryName,
  woQuery,
  onChange,
  inputStyle,
  withNewFilter,
  buttons,
  filterList,
  woSearchIcon,
  deleteFilter = () => {},
  resetFilter = () => {},
  woDebounce,
  disabled,
  isNumber,
  containerHeight,
  containerStyle,
  defaultValue,
  stickyPlaceholder,
  maxLength,
  autoFocus,
}) => {
  const defaultProps = {
    placeholder,
    data,
    woSearch,
    isModal,
    queryName,
    woQuery,
    onChange,
    inputStyle,
    withNewFilter,
    buttons,
    filterList,
    woSearchIcon,
    deleteFilter,
    resetFilter,
    woDebounce,
    disabled,
    isNumber,
    containerHeight,
    containerStyle,
    defaultValue,
    stickyPlaceholder,
    maxLength,
    autoFocus,
  };

  const { isTabOrPhone } = windowDimension();

  if (isTabOrPhone) return <PhoneFilterFields {...defaultProps} />;

  return <DefaultFilterFields {...defaultProps} />;
};
