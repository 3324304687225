import { addMinutes, getTime, parseISO } from "date-fns";
import { useRouter } from "next/router";
import React, { useState } from "react";
import topupIcon from "../../../assets/images/topup-icon-40.svg";
import { CustomButton } from "../../../components/Buttons";
import {
  MiddleWrapper,
  RightCardModalAtomic,
} from "../../../components/CardModal";
import { LeftRightList } from "../../../components/Lists";
import { useModalHook } from "../../../components/Modals";
import { booleanState, StatusLabel } from "../../../components/Status";
import { FullDateFormatter } from "../../../components/tools";
import { CountDownTopUpModal } from "../../../pageComponents/homeComponents/topup/components";
import { expiresFormatter } from "../../../pageComponents/homeComponents/topup/logic";
import { TopUpPaymentInstructionModal } from "../../../pageComponents/homeComponents/topup/modals";
import { ActivityDetailsHeader } from "../activityModalComponents";
import { InvoicePaymentSupportingDocs } from "../invoicePayment/supportingDocs";
import { CenterendIconLocalTransfer } from "../localDisbursementBatchModal/transactionModal";
import { CompareDate, useLogicTopup } from "./logic";

export const MaskingStateTopUp = (state) => {
  const { isCreated, isDraft, isOnProgress, isCancelled } = booleanState(state);
  if (isCreated || isDraft) return `on_progress`;
  if (isOnProgress) return "pending";
  if (isCancelled) return "failed";
  return state;
};

export const TopupDetailsModal = ({
  isOpen,
  toggle,
  data,
  // isSuccessModal = false,
}) => {
  const { push } = useRouter();
  const { isOpen: isOpenPaymentInstruction, toggle: togglePaymentInstruction } =
    useModalHook();
  const [middleHeight, setMiddleHeight] = useState(0);
  const { id, status, isSuccessModal, state } = data || {};

  const {
    leftRightArray,
    headerData,
    showPay,
    isTopUpManual,
    attachData,
    dataFetch,
    loadingFetch,
  } = useLogicTopup({
    data,
    isSuccessModal,
  });

  const showProofOfPayment = isSuccessModal || isTopUpManual;

  const handlePay = () =>
    push({
      pathname: `/account/topup/${id}`,
      query: { wallet: "indonesian_wallet", type: "top_up" },
    });

  const { b2b_expiry_date } = dataFetch?.data || {};
  const { expires_in, created_at } = dataFetch?.details || {};

  const expiredIn = FullDateFormatter(b2b_expiry_date || "");
  const dataForCountDown = { expiredIn };
  const time = expiresFormatter(b2b_expiry_date);
  const isMoreThan10Minutes = CompareDate(created_at);

  const { isOnProgress } = booleanState(state || status);
  const showPaymentCountDown = isOnProgress;

  return (
    <>
      <RightCardModalAtomic
        isOpen={isOpen}
        loading={loadingFetch}
        header={
          <ActivityDetailsHeader
            toggle={toggle}
            data={headerData}
            close={isSuccessModal && <></>}
          />
        }
        headerStyle={{ paddingBottom: 48 }}
        middleHeight={middleHeight}
        middle={
          <MiddleWrapper setHeight={setMiddleHeight} height={middleHeight}>
            <CenterendIconLocalTransfer icon={topupIcon} />
          </MiddleWrapper>
        }
        paddingTop={18}
        body={
          <div style={{ position: "relative" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <StatusLabel status={MaskingStateTopUp(status || state)} />
              {showPay && (
                <CustomButton
                  className="w-100"
                  style={{ marginBottom: 24 }}
                  onClick={handlePay}
                >
                  Pay {totalAmount}
                </CustomButton>
              )}
            </div>
            {showPaymentCountDown && (
              <CountDownTopUpModal
                time={time}
                data={dataForCountDown}
                showAlert={isMoreThan10Minutes}
                fromMainModal
                handlePaymentInstruction={togglePaymentInstruction}
              />
            )}
            <div style={{ marginTop: 24, marginBottom: 24 }}>
              <LeftRightList array={leftRightArray} />
            </div>
            {showProofOfPayment && (
              <div>
                <InvoicePaymentSupportingDocs
                  array={attachData}
                  title="Proof of payments"
                />
              </div>
            )}
            {isSuccessModal && (
              <CustomButton className="w-100 mt-5" onClick={toggle}>
                Finish
              </CustomButton>
            )}
          </div>
        }
      />
      <TopUpPaymentInstructionModal
        data={dataFetch}
        isOpen={isOpenPaymentInstruction}
        toggle={togglePaymentInstruction}
        // setAfterSuccessManual={() => console.log("success")}
      />
    </>
  );
};
