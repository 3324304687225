import { useRouter } from "next/router";
import { useContext } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import { CenteredModal } from "../../../modals/centeredComponents";
import { eventsTracker } from "../../../universalFunctions/events";
import { CustomButton } from "../../Buttons";
import { GothamMedium, GothamRegular } from "../../Text";
import styles from "./NoSeatModal.module.css";
import NoSeatImage from "./svgs/NoSeatImage";

const NoSeatModal = ({
  isOpen = false,
  isToLogOut = true,
  buttonText = "Log Out",
  title = "Sorry, there is no available seat",
  description = "Your company has reached its maximum users. Please contact your admin for help.",
  toggle = () => {},
}) => {
  const { unauthorize } = useContext(AuthContext);

  const { push } = useRouter();

  const handleClickButton = () => {
    if (isToLogOut) {
      eventsTracker("no_seat_modal_logout_button");
      unauthorize();
      push("/login");

      return;
    }

    eventsTracker("no_seat_modal_okay_button");
    toggle();
  };

  return (
    <CenteredModal isOpen={isOpen} toggle={toggle}>
      <div className={styles["container"]}>
        <GothamMedium className={styles["top-font"]}>{title}</GothamMedium>
        <GothamRegular woFontColor className={styles["middle-text"]}>
          {description}
        </GothamRegular>
        <NoSeatImage />
        <CustomButton
          woHeight
          woClassName
          className={styles["button"]}
          onClick={handleClickButton}
        >
          <GothamMedium className={styles["button-text"]}>
            {buttonText}
          </GothamMedium>
        </CustomButton>
      </div>
    </CenteredModal>
  );
};

export default NoSeatModal;
