import { titleCase } from "change-case";
import React, { Fragment, useEffect, useState } from "react";
import { colors } from "../../../assets/colors";
import { CustomButton } from "../../../components/Buttons";
import { GetFlag, NakedSearchInput } from "../../../components/inputs";
import questionIcon from "../../../assets/images/question-icon-pink.svg";
import xIcon from "../../../assets/images/x-mark-business-type.svg";
import checkIcon from "../../../assets/images/checklist-business-type.svg";
import {
  GothamBold,
  GothamMedium,
  GothamRegular,
} from "../../../components/Text";
import { CenteredModalWithHeader } from "../../../modals/smartKYBUploadDocsModal/components";
import { EmptyScreenSearchCountry } from "../../crossBorderSingleIndex";

export const ModalSelectCountry = ({
  toggle,
  close,
  isOpen,
  setSearch,
  search,
  isEmptySearch,
  array,
  handleClick,
}) => {
  const [selectedCountry, setSelectedCountry] = useState(false);

  const handleClickCountry = (val) => {
    setSelectedCountry(val);
  };

  const setCountry = (val) => {
    handleClick(val);
    close();
  };

  const TitleDecider = Boolean(selectedCountry)
    ? "Select currency"
    : "Select country";

  useEffect(() => {
    if (!isOpen) {
      setTimeout(() => {
        setSelectedCountry(false);
      }, 200);
    }
  }, [isOpen]);

  return (
    <CenteredModalWithHeader
      title={TitleDecider}
      isBackAndClose={Boolean(selectedCountry)}
      backModal={() => setSelectedCountry(false)}
      toggle={toggle}
      isOpen={isOpen}
      titleStyle={{ color: "#102C57", fontSize: 16 }}
      style={{ overflow: "hidden" }}
    >
      {!Boolean(selectedCountry) ? (
        <SelectCountryScreen
          setSearch={setSearch}
          search={search}
          isEmptySearch={isEmptySearch}
          array={array}
          handleClick={handleClickCountry}
        />
      ) : (
        <SelectCurrencyScreen data={selectedCountry} handleClick={setCountry} />
      )}
    </CenteredModalWithHeader>
  );
};

const SelectCountryScreen = ({
  setSearch,
  search,
  isEmptySearch,
  array,
  handleClick,
}) => {
  return (
    <div>
      <NakedSearchInput
        onChange={(search) => setSearch(search)}
        placeholder={"Search country"}
        style={{ marginTop: 0, marginBottom: 0 }}
      />
      <div
        style={{
          background: "#FFF",
          height: 400,
          overflowY: "scroll",
        }}
      >
        {isEmptySearch ? (
          <EmptyScreenSearchCountry smallPadding />
        ) : (
          <>
            {array?.map((item, childIndex) => {
              const {
                country_iso: iso,
                country_iso_3,
                country_name,
                currency,
                currencyGroup,
              } = item || {};
              const isLast = childIndex === array.length - 1;
              const countryName = titleCase(country_name);
              return (
                <Fragment key={childIndex}>
                  <div
                    className="d-flex w-100 align-items-center py-3 hover-pink justify-content-between"
                    style={{ cursor: "pointer" }}
                    onClick={() => handleClick(item)}
                  >
                    <div className="d-flex">
                      <GetFlag iso={iso} style={{ marginRight: 12 }} />
                      <GothamRegular>{countryName}</GothamRegular>
                    </div>
                    <div className="d-flex mr-2">
                      {currencyGroup?.map((item, index) => {
                        const isLast = index === currencyGroup.length - 1;
                        return (
                          <div key={index}>
                            <GothamMedium className="mr-1">
                              {item}
                              {!isLast && ","}
                            </GothamMedium>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  {!isLast && <hr className="my-0" />}
                </Fragment>
              );
            })}
          </>
        )}
      </div>
    </div>
  );
};

const SelectCurrencyScreen = ({ data, handleClick }) => {
  const { details, country_name, country_iso } = data || {};
  return (
    <div style={{ height: 300 }}>
      <div
        className="d-flex"
        style={{
          padding: "12px 8px",
          gap: 16,
          borderRadius: 8,
          border: "1px solid #BBBBC0",
          marginBottom: 8,
        }}
      >
        <GetFlag iso={country_iso} style={{ margin: 0 }} />
        <GothamRegular>{titleCase(country_name)}</GothamRegular>
      </div>
      {/* <div style={{ backgroundColor: colors.greye6, marginBottom: 8 }}>
        <GothamRegular style={{ color: colors.grey6c, padding: 8 }}>
          Select currency
        </GothamRegular>
      </div> */}
      {details.map((item, index) => {
        const { currency } = item;
        const isLast = index === details.length - 1;
        return (
          <Fragment key={index}>
            <div
              className="d-flex w-100 align-items-center py-3 hover-pink justify-content-between"
              style={{ cursor: "pointer" }}
              onClick={() => handleClick(item)}
            >
              <div className="d-flex ml-2">
                <GothamMedium>{currency}</GothamMedium>
              </div>
            </div>
            {!isLast && <hr className="my-0" />}
          </Fragment>
        );
      })}
    </div>
  );
};

export const SelectBusinessTypeModal = ({
  toggle,
  isOpen,
  data,
  handleClick,
}) => {
  const [hoveredIndex, setHoveredIndex] = useState(false);
  const [tempSelected, setTempSelected] = useState(false);

  const handleClickType = (val) => {
    setTempSelected(val);
    setHoveredIndex(false);
  };

  const handleConfirm = () => {
    handleClick(tempSelected);
  };

  return (
    <CenteredModalWithHeader
      title={"Select recipient type"}
      toggle={toggle}
      isOpen={isOpen}
      titleStyle={{ color: "#102C57", fontSize: 16 }}
    >
      <div
        style={{
          background: "#FFF",
        }}
      >
        {data?.map((item, index) => {
          const { iconGrey, iconPink, value, title } = item || {};
          const isHovered = hoveredIndex === index;
          const isSelected = tempSelected === item;
          const isLast = index === data.length - 1;
          return (
            <Fragment key={index}>
              <div
                className="d-flex w-100 align-items-center p-3 hover-pink"
                style={{
                  cursor: "pointer",
                  backgroundColor: isSelected && colors.lightPink,
                }}
                onClick={() => handleClickType(item)}
                onMouseEnter={() => setHoveredIndex(index)}
                onMouseLeave={() => setHoveredIndex(false)}
              >
                <img
                  src={isHovered || isSelected ? iconPink : iconGrey}
                  style={{ marginRight: 16 }}
                />
                <GothamBold style={{ marginBottom: 0 }}>{title}</GothamBold>
              </div>
              {!isLast && <hr className="my-0" />}
            </Fragment>
          );
        })}
      </div>
      <CustomButton
        style={{ width: "100%", marginTop: 16 }}
        disabled={!Boolean(tempSelected)}
        onClick={handleConfirm}
      >
        Confirm
      </CustomButton>
    </CenteredModalWithHeader>
  );
};

export const SelectDeliveryMethod = ({ isOpen, toggle, data, handleClick }) => {
  const [hoveredIndex, setHoveredIndex] = useState(false);
  const [tempSelected, setTempSelected] = useState(false);

  const handleClickType = (val) => {
    setTempSelected(val);
    setHoveredIndex(false);
  };

  const handleConfirm = () => {
    handleClick(tempSelected);
  };

  const defaultStyle = {
    fontSize: 10,
    lineHeight: "12px",
    color: colors.grey6c,
  };

  return (
    <CenteredModalWithHeader
      title={"Select Delivery Method"}
      toggle={toggle}
      isOpen={isOpen}
      titleStyle={{ color: "#102C57", fontSize: 16 }}
    >
      <div
        style={{
          backgroundColor: "#FFFFFF",
        }}
      >
        {data?.map((item, index) => {
          const { title, desc, descBold, icon, fee, items } = item || {};
          const isSelected = tempSelected?.title == title;
          const isHoveredIndex = hoveredIndex === index;
          const isBgPink = isSelected || isHoveredIndex;
          return (
            <div
              key={index}
              style={{
                border: "1px solid #E6E6E8",
                borderRadius: 8,
                padding: 12,
                marginBottom: 8,
                cursor: "pointer",
                backgroundColor: isBgPink && colors.lightPink,
              }}
              onMouseEnter={() => setHoveredIndex(index)}
              onMouseLeave={() => setHoveredIndex(false)}
              onClick={() => handleClickType(item)}
            >
              <div className="d-flex align-items-center">
                <img src={icon} alt={title} style={{ marginRight: 12 }} />
                <div style={{ width: "100%" }}>
                  <div className="d-flex align-items-center justify-content-between mb-1">
                    <GothamBold
                      style={{
                        color: isBgPink ? colors.pink : colors.grey33,
                        fontSize: 12,
                        lineHeight: "12px",
                        marginBottom: 0,
                      }}
                    >
                      {title}
                    </GothamBold>
                    <div
                      className="d-flex align-items-center"
                      style={{
                        backgroundColor: isBgPink ? colors.pink : "#E5F0FF",
                        borderRadius: 4,
                        padding: "2px 6px",
                      }}
                    >
                      <GothamRegular
                        style={{
                          color: isBgPink ? "white" : colors.blue10,
                          fontSize: 10,
                          lineHeight: "12px",
                        }}
                      >
                        {fee}
                      </GothamRegular>
                    </div>
                  </div>
                  <GothamRegular
                    style={{
                      color: colors.grey6c,
                      fontSize: 10,
                      lineHeight: "12px",
                      marginBottom: 8,
                      paddingRight: "30%",
                    }}
                  >
                    {desc}{" "}
                    <span style={{ fontFamily: "GothamMedium" }}>
                      {descBold}
                    </span>
                  </GothamRegular>
                  {items?.map((item, index) => {
                    const { point, xicon, customPoint } = item || {};
                    return (
                      <div
                        className="d-flex align-items-start mb-1"
                        style={{ gap: 4 }}
                        key={index}
                      >
                        <img
                          src={xicon ? xIcon : checkIcon}
                          alt="check"
                          style={{ width: 16 }}
                        />
                        {customPoint ? (
                          customPoint
                        ) : (
                          <GothamRegular style={defaultStyle}>
                            {point}
                          </GothamRegular>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          );
        })}
        <CustomButton
          style={{ width: "100%", marginTop: 16 }}
          disabled={!Boolean(tempSelected)}
          onClick={handleConfirm}
        >
          Confirm
        </CustomButton>
      </div>
    </CenteredModalWithHeader>
  );
};
