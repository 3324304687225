import React from "react";
import Skeleton from "react-loading-skeleton";
import { colors } from "../../assets/colors";
import { GothamRegular } from "../../components/Text";

export const PhoneAccountDashboardAction = ({
  data,
  onClick,
  loading: loadingProps,
}) => {
  return (
    <div
      className="d-flex w-100 p-2"
      style={{
        backgroundColor: "white",
        borderRadius: 10,
        boxShadow: "0px 8px 20px rgba(88, 88, 88, 0.1)",
      }}
    >
      {data.map((item, index) => {
        const { icon, name, loading } = item;

        const isLoading = loadingProps || loading;

        const width = `${(1 / data.length) * 100}%`;
        const defaultProps = {
          key: index,
          style: {
            width,
            flexDirection: "column",
          },
          className:
            "d-flex justify-content-center align-items-center darkhover",
        };
        if (isLoading)
          return (
            <div {...defaultProps}>
              <Skeleton width={28} height={28} style={{ borderRadius: 200 }} />
              <Skeleton width={50} height={12} />
            </div>
          );
        return (
          <div {...defaultProps} onClick={() => onClick(item)}>
            <img
              src={icon}
              style={{ width: 28, height: 28 }}
              className="mb-2"
            />
            <GothamRegular
              style={{
                fontSize: 10,
                lineHeight: "16px",
                color: colors.grey6c,
                marginBottom: 0,
                textAlign: "center",
              }}
            >
              {name}
            </GothamRegular>
          </div>
        );
      })}
    </div>
  );
};
