import { GothamMedium } from "../../../components/Text";
import { customDateFormatter, formatCurrency } from "../../../components/tools";
import logoIcon from "../../../assets/images/transfez-pink-small-logo.svg";
import { fetch } from "../../../tools/api";

export const useLogicTopup = ({ data, isSuccessModal }) => {
  const {
    reference_number: headerLeft,
    created_at,
    currency,
    amount,
    originator_id,
    details,
    status,
    description,
    originator_type,
    reference_number,
  } = data || {};
  const { va_number, bank_name, originator } = details || {};
  const { bank_code: bankCodeActivity } = originator || {};

  const {
    data: getData,
    refetch,
    loading,
  } = fetch({
    url: `/topups/${originator_id}`,
    formatter: (data) => data,
    defaultValue: {},
  });

  const {
    account_name: accNameManual,
    account_number: accNumberManual,
    amount: amountManual,
    bank_name: bankNameManual,
    file_details,
    reference_id: referenceIdManual,
  } = getData?.data || {};
  const { bank_code } = getData?.details || {};

  const attachData = (getData?.attachment_data || []).map((item, index) => {
    const file_url = file_details[index].url;
    return { ...item, file_url };
  });

  const isHubVaTransaction = originator_type === "Hub::VaTransaction";
  const isHubPayment = originator_type === "Hub::Payment";
  const isFromHubTrx = isHubVaTransaction || isHubPayment;
  const isTopUpManual = originator_type === "Topup";
  const paymentMethod = isFromHubTrx ? "Virtual Account" : "Bank Transfer";
  const bankName =
    bank_name ||
    bankNameManual ||
    (bank_code && bank_code.toUpperCase()) ||
    (bankCodeActivity && bankCodeActivity.toUpperCase());
  ("-");
  const referenceId = reference_number || referenceIdManual;

  const totalAmount = `IDR ${formatCurrency(amountManual || amount)}`;
  const date = customDateFormatter(created_at);
  const showPay = status == "initial";

  const headerDataDefault = {
    // headerLeft,
    // headerRight: date,
    type: description,
    icon: false,
    title: Boolean(amount) && totalAmount,
    // text: "Indonesian Account",
  };

  const headerDataSuccess = {
    icon: false,
    title: (
      <div style={{ textAlign: "center", marginTop: -30 }}>
        <img src={logoIcon} className="mb-3" />
        <GothamMedium style={{ fontSize: 24, marginBottom: 12 }}>
          Your top up request is in progress
        </GothamMedium>
      </div>
    ),
  };

  const headerData = isSuccessModal ? headerDataSuccess : headerDataDefault;

  const leftRightArray = [
    { left: "Created date", right: date },
    { left: "Reference", right: referenceId },
    { spacer: true },
    { left: "Payment Method", right: paymentMethod },
    { left: "Bank", right: bankName },
  ];

  // const leftRightArray = isFromHubTrx ? leftRightArrayVA : leftRightArrayManual;

  return {
    leftRightArray,
    headerData,
    showPay,
    isTopUpManual,
    attachData,
    dataFetch: getData,
    loadingFetch: loading,
  };
};

export const CompareDate = (createdDate) => {
  const newCurrentDate = new Date();

  var tenMinutesMore = new Date(createdDate);
  tenMinutesMore.setMinutes(tenMinutesMore.getMinutes() + 10); // timestamp
  tenMinutesMore = new Date(tenMinutesMore); // Date object

  const createdAtPlus10MinutesNumber = tenMinutesMore.getTime();
  const currentDateNumber = newCurrentDate.getTime();

  return createdAtPlus10MinutesNumber < currentDateNumber;
};
