import React from "react";
import { colors } from "../../assets/colors";
import { GothamRegular } from "../../components/Text";
import { NameAvatarWithFlag } from "./localDisbursementBatchModal/transactionModal";

export const TransactionListMinimalist = ({ data, onClick }) => {
  const handleClick = (item) => onClick && onClick(item);
  return (
    <div>
      {data?.map((item, index) => {
        const { icon, description } = item || {};
        return (
          <div
            key={index}
            onClick={() => handleClick(item)}
            className="darkhover"
            style={{
              border: "1px solid #eaeaea",
              borderRadius: 8,
              padding: "16px 12px 8px 12px",
            }}
          >
            <div className="d-flex align-items-center">
              <img src={icon} />
              <GothamRegular style={{ marginTop: -8, marginLeft: 4 }}>
                {description}
              </GothamRegular>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export const RecipientMinimalist = ({ data, noFlag }) => {
  // sample data

  // const recipientData = {
  //   name: ---,
  //   bank: ---,
  //   bankAccount: ---,
  //   iso: iso.toLowerCase(),
  // };

  // sampe data
  const { name, bank, bankAccount, iso } = data;
  return (
    <div className="d-flex align-items-center" style={{margin: "12px 0px"}}>
      <NameAvatarWithFlag iso={iso || "id"} name={name} noFlag={noFlag}/>
      <div className="ml-2">
        <GothamRegular style={{ color: colors.grey6c }}>{name}</GothamRegular>
        <GothamRegular style={{ color: colors.grey6c, fontSize: 12, lineHeight: '14px' }}>
          {bank} {bank && `-`} {bankAccount}
        </GothamRegular>
      </div>
    </div>
  );
};
