import { useState } from "react";
import parse, { domToReact } from "html-react-parser";
import { CenteredModal } from "../../../../modals/centeredComponents";
import {
  GothamBold,
  GothamRegular,
  TextInlineBold,
} from "../../../../components/Text";
import { colors } from "../../../../assets/colors";
import {
  customDateFormatter,
  windowDimension,
} from "../../../../components/tools";
import { CheckBox } from "../../../../components/Checkbox";
import { CustomButton, OutlineButton } from "../../../../components/Buttons";
import { useMutation } from "../../../../tools/api";
import { useGetAuth } from "../../../../contexts/AuthContext";
import { useRouter } from "next/router";

const CardTnc = ({ data = [], isOpen, toggle }) => {
  const [isEnglish, setIsEnglish] = useState(true);
  const [isChecked, setIsChecked] = useState(false);
  const { refetchUser } = useGetAuth();

  const { pathname, push } = useRouter();

  const defaultLangStyle = {
    textAlign: "center",
    paddingTop: "3px",
    width: "50%",
    color: colors.greyBB,
    cursor: "pointer",
    transition: "all 0.1s linear",
  };

  const selectedLangStyle = {
    backgroundColor: colors.lightPink,
    borderRadius: "4px",
    color: colors.pink,
  };

  const { screenHeight } = windowDimension();

  let chapterCount = 0;
  let subChapterCount = 0;

  const options = {
    replace: (item) => {
      const { attribs, children, name } = item || {};
      if (attribs?.id === "main") {
        return <ol style={{ padding: 0 }}>{domToReact(children, options)}</ol>;
      }
      if (attribs?.class === "title" && name === "h2") {
        return (
          <GothamBold
            style={{
              color: colors.blue10,
              marginTop: "64px",
              fontSize: "16px",
            }}
          >
            {domToReact(children, options)}
          </GothamBold>
        );
      }
      if (attribs?.class === "title") {
        chapterCount++;
        subChapterCount = 0;
        return (
          <li
            style={{
              marginTop: "16px",
              color: colors.blue10,
              fontSize: "16px",
              fontWeight: 700,
            }}
          >
            <GothamBold>{domToReact(children, options)}</GothamBold>
          </li>
        );
      }
      if (name === "ol" && attribs?.type === "i") {
        return <ol type="i">{domToReact(children, options)}</ol>;
      }
      if (name === "li" && attribs?.type === "i") {
        return <li>{domToReact(children, options)}</li>;
      }
      if (name === "ol") {
        return (
          <ol
            style={{
              padding: 0,
              marginLeft: "-12px",
            }}
          >
            {domToReact(children, options)}
          </ol>
        );
      }
      if (name === "li") {
        subChapterCount++;
        return (
          <div
            style={{
              margin: "6px 0",
              paddingLeft: 0,
              display: "flex",
              gap: "8px",
            }}
          >
            <GothamRegular>{`${chapterCount}.${subChapterCount}`}</GothamRegular>
            <div>{domToReact(children, options)}</div>
          </div>
        );
      }
      if (name === "b") {
        return (
          <TextInlineBold
            style={{
              color: colors.blue10,
            }}
          >
            {domToReact(children, options)}
          </TextInlineBold>
        );
      }
      if (name === "a") {
        return (
          <a
            href={attribs.href}
            style={{ color: colors.pink, textDecoration: "underline" }}
            target="_blank"
          >
            {domToReact(children, options)}
          </a>
        );
      }
      if (name === "br") {
        return (
          <div
            style={{
              marginBottom: "6px",
            }}
          />
        );
      }
      if (name === "th") {
        return (
          <th
            style={{
              border: "1px solid black",
              padding: 8,
            }}
          >
            {domToReact(children, options)}
          </th>
        );
      }
      if (name === "td") {
        return (
          <td
            style={{
              border: "1px solid black",
              padding: 8,
            }}
          >
            {domToReact(children, options)}
          </td>
        );
      }
    },
  };

  const { updated_at } = data[0] || {};
  const formattedDate = customDateFormatter(
    updated_at,
    false,
    false,
    false,
    true
  )
    .split(" ")
    .join("/");

  const { mutation, loading } = useMutation({
    url: "/terms_and_conditions",
    method: "post",
  });

  const onSubmit = () => {
    //payload for business tncs
    const payload = { tnc_type: "business" };
    data?.map(({ type }) => (payload[type] = "true"));
    mutation(payload);
    toggle();
    refetchUser();
  };

  return (
    <CenteredModal
      isOpen={isOpen}
      toggle={() => {
        toggle();
        push({
          pathname: "/home",
        });
      }}
      style={{ padding: 0 }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "24px 24px",
          boxShadow: "0px 4px 8px rgba(88, 88, 88, 0.1)",
        }}
      >
        <div>
          <GothamBold style={{ color: colors.blue10, fontSize: "20px" }}>
            Terms of Service
          </GothamBold>
          <GothamRegular>Updated on {formattedDate}</GothamRegular>
        </div>
        <div
          style={{
            backgroundColor: colors.greye6,
            width: "143px",
            height: "28px",
            borderRadius: "4px",
            padding: "2px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              ...defaultLangStyle,
              ...(isEnglish ? selectedLangStyle : {}),
            }}
            onClick={() => setIsEnglish(true)}
          >
            <GothamBold>EN</GothamBold>
          </div>
          <div
            style={{
              ...defaultLangStyle,
              ...(!isEnglish ? selectedLangStyle : {}),
            }}
            onClick={() => setIsEnglish(false)}
          >
            <GothamBold>ID</GothamBold>
          </div>
        </div>
      </div>
      <div
        style={{
          padding: "18px 32px",
          height: screenHeight / 1.7,
          overflow: "auto",
        }}
      >
        {data.map(({ title, content_en, content_id }) => {
          const text = (isEnglish ? content_en : content_id) || "";
          return (
            <>
              <GothamBold style={{ fontSize: "20px", color: colors.blue10 }}>
                {title}
              </GothamBold>
              <div
                style={{
                  fontSize: "14px",
                  marginLeft: "16px",
                }}
              >
                {parse(text, options)}
              </div>
            </>
          );
        })}
      </div>
      <div
        style={{
          padding: "18px 24px",
          boxShadow: "0px -4px 8px rgba(88, 88, 88, 0.1)",
        }}
      >
        <div style={{ display: "flex", gap: "12px" }}>
          <CheckBox
            isActive={isChecked}
            onClick={() => setIsChecked((prev) => !prev)}
          />
          <GothamRegular style={{ color: colors.grey90 }}>
            I agree to the{" "}
            <span style={{ fontWeight: 700 }}>
              {" "}
              Terms of Service Corporate Card{" "}
            </span>
          </GothamRegular>
        </div>
        <div style={{ display: "flex", gap: "12px", marginTop: "18px" }}>
          <OutlineButton
            onClick={() => {
              push({
                pathname: "/home",
              });
            }}
            style={{ width: "50%" }}
          >
            Decline
          </OutlineButton>
          <CustomButton
            onClick={onSubmit}
            disabled={!isChecked}
            isLoading={loading}
            style={{ width: "50%" }}
          >
            Accept
          </CustomButton>
        </div>
      </div>
    </CenteredModal>
  );
};

export default CardTnc;
