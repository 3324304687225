import { titleCase } from "change-case";
import React from "react";
import allocate from "../../assets/images/account-allocate3.svg";
import { RightCardModalAtomic } from "../../components/CardModal";
import { LeftRightList } from "../../components/Lists";
import { LabelDeciderModal } from "../../components/Status";
import {
  customDateFormatter,
  formatCurrency,
  formatCurrencyFixed,
  formatCurrencyNoDecimal,
} from "../../components/tools";
import { ActivityDetailsHeader } from "./activityModalComponents";

export const AllocateModal = ({ toggle, isOpen, data }) => {
  const {
    reference_number,
    details,
    status,
    created_at,
    amount: rawAmount,
    currency,
  } = data || {};
  const { rate } = details || {};
  const { destination_amount } = rate || {};
  const newAmount = Number(rawAmount.split(".")[0]);
  const amount = destination_amount || newAmount;
  return (
    <RightCardModalAtomic
      isOpen={isOpen}
      header={
        <ActivityDetailsHeader
          toggle={toggle}
          data={{
            icon: allocate,
            headerLeft: reference_number,
            headerRight: customDateFormatter(created_at),
            type: "Allocate Fund",
            title: `${currency} ${formatCurrencyNoDecimal(amount)}`,
            text: "Indonesian Account",
            leftText: "Main Balance",
            rightText: `Card Balance`,
          }}
        />
      }
      body={
        <div style={{ marginTop: 24 }}>
          <LeftRightList
            array={[
              { left: "Transaction Type", right: "Allocate Fund" },
              {
                left: "Status",
                right: `${LabelDeciderModal(status)}`,
                isStatus: true,
                rightBold: true,
              },
              { left: "Date", right: customDateFormatter(created_at) },
              { spacer: true },
              {
                left: "Amount allocated",
                right: `${currency} ${formatCurrency(amount)}`,
                boldAll: true,
              },
            ]}
          />
        </div>
      }
    />
  );
};
