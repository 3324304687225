import React from "react";
import { RightCardModalAtomic } from "../../components/CardModal";
import { ActivityDetailsHeader } from "./activityModalComponents";
import incomingAccIcon from "../../assets/images/account-icon-incoming.svg";
import { customDateFormatter, formatCurrency } from "../../components/tools";
import { SimpleCardWithTitleInModal } from "./cardComponents";
import { LabelDeciderModal } from "../../components/Status";

export const IncomingPaymentModal = ({ isOpen, toggle, data }) => {
  const { created_at, amount, currency, details, reference_number, status } =
    data;
  const { va_number_id, trx_id, tx_date } = details?.originator;
  const headerData = {
    type: "Incoming Payment",
    icon: incomingAccIcon,
    headerRight: customDateFormatter(created_at),
    headerLeft: reference_number,
    text: va_number_id,
    title: `${currency} ${formatCurrency(amount)}`,
  };
  const array = [
    { title: "Transaction ID", msg: trx_id },
    { title: "VA Number ID", msg: va_number_id },
    { title: "Date Received", msg: customDateFormatter(new Date(tx_date)) },
    { title: "Status", msg: `${LabelDeciderModal(status)}` },
  ];

  return (
    <RightCardModalAtomic
      isOpen={isOpen}
      header={<ActivityDetailsHeader data={headerData} toggle={toggle} />}
      body={
        <>
          <SimpleCardWithTitleInModal title="" data={array} />
        </>
      }
    />
  );
};
