import { useRouter } from "next/router";
import React, { Fragment, useState } from "react";
import { colors } from "../../assets/colors";
import { navItemsDecider } from "../Sidebar";
import { windowDimension } from "../tools";
import { BurgerNavbarItem } from "./item";

export const Burger = ({ top, isOpen, close }) => {
  const { push } = useRouter();
  const navItems = navItemsDecider();
  const [accordionValue, setAccordionValue] = useState("");
  const { width } = windowDimension();

  const onClick = ({ e, item }) => {
    if (!e) return;
    e.preventDefault();
    e.stopPropagation();
    const {
      accordion = [],
      accordionValue: accordionValueItem,
      href,
    } = item || {};

    if (accordion.length)
      return setAccordionValue((prev) => {
        const isSameAsBefore = prev == accordionValueItem;
        if (isSameAsBefore) return "";
        return accordionValueItem;
      });
    push(href);
    close();
  };

  if (!isOpen) return null;

  return (
    <div
      className={isOpen ? "fade-in" : "fade-out"}
      style={{
        position: "absolute",
        width,
        top,
        left: 0,
        backgroundColor: colors.pink,
        zIndex: 3,
      }}
    >
      {navItems.map((item, index) => {
        const {
          title,
          accordion = [],
          accordionValue: accordionValueItem,
        } = item;

        if (!title) return null;

        const isActiveAccordion = accordionValueItem == accordionValue;

        return (
          <Fragment key={index}>
            <BurgerNavbarItem
              isActiveAccordion={isActiveAccordion}
              {...item}
              onClick={(e) => onClick({ e, item })}
            />
            {isActiveAccordion &&
              accordion.map((item, index) => (
                <BurgerNavbarItem
                  className={isActiveAccordion ? "fade-in" : "fade-out"}
                  onClick={(e) => onClick({ e, item })}
                  key={index}
                  {...item}
                />
              ))}
          </Fragment>
        );
      })}
    </div>
  );
};
