import { useState, useEffect } from "react";
import { RightCardModalAtomic } from "../../components/CardModal";
import { GothamRegular, GothamMedium, GothamBold } from "../../components/Text";
import { customDateFormatter, formatCurrency } from "../../components/tools";
import { MiddleWrapper } from "../../components/CardModal";
import { CenterendIconLocalTransfer } from "./localDisbursementBatchModal/transactionModal";
import cardBillPaymentIcon from "../../assets/images/card-bill-payment-icon-40.svg";
import whiteArrow from "../../assets/images/arrow-foward-white.svg";
import { CustomButton } from "../../components/Buttons";
import { LeftRightList } from "../../components/Lists";
import { colors } from "../../assets/colors";
import { MainCardHeaderMolecule } from "../../components/CardModal";
import { NakedSearchInput } from "../../components/inputs";
import { StatusLabel } from "../../components/Status";
import { downloadApi } from "../../tools/api";
import { ToasterHook } from "../../contexts/ToasterContext";
import { billCycleDecider } from "../../pageComponents/cardsPageComponent/logics";
import { useGdrivehook } from "../../contexts/GoogleDrivePreviewContext/parent";

export const CardPaymentModal = ({ toggle, isOpen, data }) => {
  const [middleHeight, setMiddleHeight] = useState(0);
  const [search, setSearch] = useState("");
  const [filteredCards, setfilteredCards] = useState([]);
  const { details } = data || {};
  const {
    amount_fee,
    due_date,
    paid_at,
    state,
    total_amount,
    additional_details,
    period,
  } = details || {};
  const { billing_payments } = additional_details || {};
  const { successToaster } = ToasterHook();
  const { show } = useGdrivehook();

  const amountWithFee = total_amount + amount_fee;

  const cardFilter = () => {
    const filteredCards = billing_payments.filter((bill) => {
      const { user_card } = bill || {};
      const { masked_card_number } = user_card || {};
      const lastFourDigitCard = (masked_card_number || "").slice(15, 19);
      if (lastFourDigitCard?.includes(search)) return bill;
    });
    return filteredCards;
  };

  useEffect(() => {
    if (search) {
      const filteredCards = cardFilter();
      return setfilteredCards(filteredCards);
    }
    setfilteredCards(billing_payments);
  }, [search]);

  const { startingDate, endingDate } = billCycleDecider(period);

  const mainComponent = () => {
    return (
      <div
        style={{
          flexDirection: "column",
        }}
        className="w-100 align-items-center d-flex"
      >
        <div
          style={{
            marginBottom: "4px",
            whiteSpace: "nowrap",
            marginBottom: "8px",
          }}
        >
          <GothamRegular style={{ color: "white", display: "inline" }}>
            Total bills for{" "}
          </GothamRegular>
          <GothamMedium style={{ color: "white", display: "inline" }}>
            {startingDate}
          </GothamMedium>
          <GothamRegular style={{ color: "white", display: "inline" }}>
            {" "}
            to{" "}
          </GothamRegular>
          <GothamMedium style={{ color: "white", display: "inline" }}>
            {endingDate}
          </GothamMedium>
        </div>

        <>
          <GothamMedium
            style={{
              marginBottom: 4,
              color: "white",
              fontSize: 24,
              lineHeight: "32px",
            }}
          >
            IDR {formatCurrency(amountWithFee)}
          </GothamMedium>
        </>
      </div>
    );
  };

  const wingComponent = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          marginRight: "20px",
        }}
      >
        <GothamBold
          style={{
            fontSize: "16px",
            color: colors.grey33,
          }}
        >
          All Card Statement
        </GothamBold>
        <NakedSearchInput
          onChange={(search) => setSearch(search)}
          placeholder={"Search by card number (last 4-digits)"}
          style={{
            width: "400px",
          }}
        />

        <div
          style={{
            height: "525px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            overflowY: "auto",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
            }}
          >
            <div
              style={{
                width: "40%",
                height: "100px",
              }}
            >
              <div
                style={{
                  paddingTop: "12px",
                  paddingBottom: "12px",
                  borderBottom: "1px solid #E6E6E8",
                }}
              >
                <GothamRegular style={{ color: "#333334" }}>Card</GothamRegular>
              </div>

              {Boolean(filteredCards?.length) &&
                filteredCards.map((bill, index) => {
                  const { user_card } = bill || {};
                  const { masked_card_number } = user_card || {};
                  const lastFourDigitCard = (masked_card_number || "").slice(
                    15,
                    19
                  );

                  return (
                    <GothamRegular
                      key={index}
                      style={{ color: "#333334", marginTop: "16px" }}
                    >
                      Card **{lastFourDigitCard}
                    </GothamRegular>
                  );
                })}
            </div>
            <div
              style={{
                width: "60%",
                height: "100px",
              }}
            >
              <div
                style={{
                  paddingTop: "12px",
                  paddingBottom: "12px",
                  borderBottom: "1px solid #E6E6E8",
                }}
              >
                <GothamRegular style={{ color: "#333334" }}>
                  Amount Paid
                </GothamRegular>
              </div>
              {Boolean(filteredCards?.length) &&
                filteredCards.map((bill, index) => {
                  const {
                    id,
                    total_bill,
                    user_card: { masked_card_number },
                  } = bill || {};

                  return (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: "16px",
                        marginRight: "20px",
                      }}
                      key={index}
                    >
                      <GothamRegular style={{ color: "#333334" }}>
                        IDR {formatCurrency(total_bill < 0 ? 0 : total_bill)}
                      </GothamRegular>
                      <GothamMedium
                        style={{ color: "#DA649F", cursor: "pointer" }}
                        onClick={() => viewInvoice(id, masked_card_number)}
                      >
                        View
                      </GothamMedium>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const viewInvoice = (id, masked_card_number) => {
    show({
      files: [
        {
          url: `/api/v1/prints/${id}/billing_payment.pdf`,
          name: `${masked_card_number}_card_billing`,
          type: "application/pdf",
        },
      ],
    });
  };

  const informations = [
    {
      left: "Paid on",
      leftBold: true,
      styleLeft: { color: colors.blue10 },
      right: customDateFormatter(paid_at),
    },

    { spacer: true },
    {
      left: "Total remaining bills",
      leftBold: true,
      styleLeft: { color: colors.blue10 },
      right: `IDR ${formatCurrency(total_amount)}`,
    },
    {
      left: "Late fee",
      leftBold: true,
      styleLeft: { color: colors.blue10 },
      right: amount_fee ? amount_fee : "-",
    },
    { spacer: true },
    {
      left: "Amount paid",
      leftBold: true,
      styleLeft: { color: colors.blue10 },
      right: `IDR ${formatCurrency(amountWithFee)}`,
      rightBold: true,
    },
  ];

  return (
    <RightCardModalAtomic
      isOpen={isOpen}
      toggle={toggle}
      wingComponent={wingComponent()}
      wingWidth={868}
      header={
        <MainCardHeaderMolecule
          isClose
          toggle={toggle}
          customComponent={mainComponent()}
        />
      }
      headerStyle={{ marginBottom: "50px", height: "188px" }}
      middleHeight={middleHeight}
      middleStyle={{
        marginTop: "-70px",
      }}
      middle={
        <MiddleWrapper setHeight={setMiddleHeight} height={middleHeight}>
          <CenterendIconLocalTransfer icon={cardBillPaymentIcon} />
        </MiddleWrapper>
      }
      body={
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "26px",
              marginTop: "-10px",
            }}
          >
            <StatusLabel status={"success"} />
          </div>
          <LeftRightList array={informations} />
        </>
      }
      bodyStyle={{
        marginTop: "-70px",
        borderRadius: "24px",
      }}
    />
  );
};
