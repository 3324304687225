import { colors } from "../../../../assets/colors";
import { booleanState } from "../../../../components/Status";
import { GothamRegular } from "../../../../components/Text";
import { customDateFormatter } from "../../../../components/tools";
import { useEWalletStatusProps } from "../allocation/status";

export const useReturnStatusArray = ({ details }) => {
  const { created_at, status } = details || {};

  const { isCancelled } = booleanState(status);

  const {
    isRequestedByAdmin,
    getTime,
    requesterName,
    cardHolderName,
    executorName,
  } = useEWalletStatusProps(details);

  const approveTime = getTime("approve");
  const processTime = getTime("process");
  const completeTime = getTime("add_balance");
  const cancelledTime = getTime("cancel");

  const firstObj = {
    text: `${requesterName} requested a return balance`,
    isActive: true,
    date: customDateFormatter(created_at),
  };

  const requireEmployeeObj = {
    text: `Require ${cardHolderName} to return the balance`,
    isActive: Boolean(approveTime),
    date: approveTime ? customDateFormatter(approveTime) : "",
  };

  const processObj = {
    text: "Transferring money to company’s main balance",
    isActive: Boolean(processTime),
    date: processTime ? customDateFormatter(processTime) : "",
  };

  const completedObj = {
    text: "Completed",
    isActive: Boolean(completeTime),
    date: completeTime ? customDateFormatter(completeTime) : "",
  };

  const cancelledObj = {
    text: "Rejected by Admin",
    customComponents: (
      <GothamRegular>
        <span style={{ color: colors.redE7 }}>Cancelled</span> by {executorName}
      </GothamRegular>
    ),
    isActive: true,
    date: cancelledTime ? customDateFormatter(cancelledTime) : "",
    isRedButton: true,
  };

  if (isCancelled) return [firstObj, cancelledObj];

  if (isRequestedByAdmin) return [firstObj, requireEmployeeObj, completedObj];

  return [firstObj, processObj, completedObj];
};
