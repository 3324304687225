import React, { Fragment } from "react";
import coinIcon from "../../assets/images/transaction-details-coin.svg";
import { LeftRightList } from "../../components/Lists";
import { GothamMedium, GothamRegular } from "../../components/Text";
import { TransactionDetailsCard } from "../../pageComponents/transactionDetailsComponents";

export const CardContainerInModal = ({ children, style }) => (
  <div
    style={{
      border: "1px solid #eaeaea",
      marginBottom: 0,
      borderRadius: 8,
      marginBottom: 16,
      padding: 12,
      ...style,
    }}
  >
    {children}
  </div>
);

export const CardWithTitleInModal = ({ array = [] }) => (
  <CardContainerInModal style={{ padding: 0 }}>
    <TransactionDetailsCard
      dataDetails={[
        {
          icon: <img src={coinIcon} />,
          title: "Transaction Details",
          data: [],
        },
      ]}
      style={{
        width: "100%",
        border: "0px solid #eaeaea",
        marginBottom: 0,
        padding: 12,
        paddingBottom: 0,
      }}
    />
    <div style={{ padding: 12, paddingTop: 0, paddingBottom: 0 }}>
      <LeftRightList array={array} />
    </div>
  </CardContainerInModal>
);

export const SimpleCardWithTitleInModal = ({
  data,
  title = "Sender Details",
}) => (
  <CardContainerInModal>
    {Boolean(title) && (
      <GothamMedium
        style={{ fontSize: 16, lineHeight: "24px", marginBottom: 12 }}
      >
        {title}
      </GothamMedium>
    )}
    {(data || []).map(({ title, msg }, index) => {
      const isLast = (data || []).length == index + 1;

      if (!msg) return null;

      return (
        <Fragment key={index}>
          <GothamRegular className="m-0" style={{ lineHeight: "24px" }}>
            {title}
          </GothamRegular>
          <GothamMedium
            style={{ marginBottom: isLast ? 0 : 12, lineHeight: "24px" }}
          >
            {msg}
          </GothamMedium>
        </Fragment>
      );
    })}
  </CardContainerInModal>
);
