import { isEmpty } from "lodash";
import { useRouter } from "next/router";
import React, { createContext, useContext, useEffect } from "react";
import { toLabelValue } from "../../components/tools";
import { fetch } from "../../tools/api";
import { getUserRole, useGetAuth } from "../AuthContext";
import { localBanksFormatter } from "./formatter/localBanks";
import { sgBanksFormatter } from "./formatter/sgBanks";

const ConstantsContext = createContext();

const useFetcher = ({
  url,
  formatter = (data) => toLabelValue(data?.data || []),
  woAuth,
  canRun = true,
  ...rest
}) => {
  const { pathname } = useRouter();
  const { user } = useGetAuth();
  const { data, loading, setData, refetch } = fetch({
    url,
    formatter,
    woInit: true,
    ...rest,
  });

  useEffect(() => {
    if (isEmpty(user) && !woAuth) return setData([]);
    if (data.length) return;
    if (!canRun) return;
    refetch();
  }, [data.length, pathname, isEmpty(user)]);

  return { data, loading, refetch };
};

export const ConstantsProvider = ({ children }) => {
  const { isAdminOrSuperAdmin, isEmployeeManager } = getUserRole();

  const {
    data: teamsData,
    loading: teamsLoading,
    refetch: refetchTeamsData,
  } = useFetcher({
    url: "/teams",
    woAuth: true,
  });
  const {
    data: users,
    loading: loadingUsers,
    refetch: refetchUsersData,
  } = useFetcher({
    url: "/business_users/all_users",
    canRun: isAdminOrSuperAdmin || isEmployeeManager,
  });
  const { data: rolesData, loading: rolesLoading } = useFetcher({
    url: "/business_roles?q[name_cont]=partner_",
  });
  const { data: sgBanks, loading: sgBanksLoading } = useFetcher({
    url: "/swift-codes?country_code=SG&_limit=10000",
    type: "strapi",
    formatter: sgBanksFormatter,
  });
  const { data: idBanks, loading: idBanksLoading } = useFetcher({
    url: "/local_banks",
    formatter: localBanksFormatter,
  });
  const { data: localTransferBanks, loading: loadingTransferBanks } =
    useFetcher({
      url: "/local_transaction_batches/payers",
      formatter: localBanksFormatter,
    });

  const teamsDataNames = teamsData.map((teamData) => teamData.label);

  // code review: Rafi
  // can be more simpler. Example: filterer at function `getRolesUserLogin`
  const directorIndex = teamsDataNames.findIndex((name) =>
    name.toLowerCase().includes("director")
  );
  const financeIndex = teamsDataNames.findIndex((teamDataName) =>
    teamDataName.toLowerCase().includes("financ")
  );
  // code review: Rafi
  const teamsDataFinance = teamsData.filter(
    (_, index) => index === financeIndex
  );
  const teamsDataWoFinance = teamsData.filter(
    (_, index) => index !== financeIndex
  );
  const teamsDataWoDirector = teamsData.filter(
    (_, index) => index !== directorIndex
  );
  const teamsDataDirector = teamsData.filter(
    (_, index) => index === directorIndex
  );

  const teamsDataOutput = [...teamsDataFinance, ...teamsDataWoFinance];

  const newRolesData = rolesData.map((item) => {
    const { name } = item || {};
    if (name == "partner_super_admin")
      return { ...item, label: "Business Owner" };
    return item;
  });

  const values = {
    users,
    loadingUsers,
    refetchUsersData,
    teamsData: teamsDataOutput,
    teamsDataWoDirector,
    teamsDataDirector,
    teamsLoading,
    refetchTeamsData,
    rolesData: newRolesData,
    rolesLoading,
    sgBanks,
    sgBanksLoading,
    idBanks,
    idBanksLoading,
    localTransferBanks,
    loadingTransferBanks,
  };

  return (
    <ConstantsContext.Provider value={values}>
      {children}
    </ConstantsContext.Provider>
  );
};

export const useConstants = () => {
  const {
    users,
    loadingUsers,
    refetchUsersData,
    teamsData,
    teamsDataWoDirector,
    teamsDataDirector,
    teamsLoading,
    refetchTeamsData,
    rolesData,
    rolesLoading,
    sgBanks,
    sgBanksLoading,
    idBanks,
    idBanksLoading,
    localTransferBanks,
    loadingTransferBanks,
  } = useContext(ConstantsContext) || {};

  return {
    users,
    loadingUsers,
    refetchUsersData,
    teamsData,
    teamsDataWoDirector,
    teamsDataDirector,
    teamsLoading,
    refetchTeamsData,
    rolesData,
    rolesLoading,
    sgBanks,
    sgBanksLoading,
    idBanks,
    idBanksLoading,
    localTransferBanks,
    loadingTransferBanks,
  };
};
