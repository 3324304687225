import { useEffect, useState } from "react";
import { customDateFormatter, useDebounce } from "../../../components/tools";
import { singleFormatter } from "../../../pageComponents/accountCardDetailsComponents/logics/single";
import { fetch } from "../../../tools/api";

const arrayFormatter = (array) =>
  array.map((item) => {
    const newItem = singleFormatter({
      details: { transaction: item },
      noDecimal: true,
    });
    const { leftTop: leftTopRaw } = newItem;
    const leftBottom = customDateFormatter(item?.created_at, true);
    const leftTop = leftTopRaw.includes("-")
      ? leftTopRaw.split("-")[0]
      : leftTopRaw;

    return {
      ...item,
      ...newItem,
      category: "single_cross_border",
      leftBottom,
      leftTop,
      leftTag: "",
    };
  });

const batchFormatter = (res, prev) => {
  const { trxb_data = [] } = res;
  const { trxb_data: prevtrxb_data = [] } = prev;

  const array = arrayFormatter([...prevtrxb_data, ...trxb_data]);
  return { ...res, trxb_data: array };
};

export const useBatchDetails = (id, isOpen) => {
  const [page, setPage] = useState(1);
  const { data: transactionData, refetch } = fetch({
    url: `/business_transactions/${id}?page=${page}&per_page=10`,
    woInit: true,
    defaultValue: {},
    formatter: (res, prev) => batchFormatter(res, prev),
  });

  const { total_page } = transactionData;

  // we need to fetch all single transactions!

  // initial refetch
  const [runOnce, setRunOnce] = useState(true);
  useEffect(() => {
    if (!isOpen) return;
    if (!id) return;
    if (!runOnce) return;
    setRunOnce(false);
    refetch();
  }, [isOpen, id]);
  // initial refetch

  const debouncedPage = useDebounce(page, 500);
  // pagination master
  useEffect(() => {
    if (!total_page) return;
    if (debouncedPage < total_page) setPage((p) => p + 1);
  }, [total_page, debouncedPage]);
  // pagination master

  // refetch pages
  useEffect(() => {
    if (runOnce) return;
    refetch();
  }, [page]);
  // refetch pages

  return { transactionData, loading: !total_page };
};
