import moment from "moment";
import useSubscriptionUsage from "../../../../tools/hooks/useSubscriptionUsage";
import getState from "./getState";

const descriptionTextDecider = (state = "") => {
  const { isOverdue, isSuspended, isSuspendTransaction, isSuspendCard } =
    getState(state);

  const { dueDate } = useSubscriptionUsage();

  if (isSuspended)
    return "Since you haven’t paid your last bill, we've removed your access to some of our services. If you’d like to know more about this, please contact your account manager.";
  if (isOverdue)
    return [
      "You have an overdue subscription payment that was renewed on ",
      `${moment(dueDate).format("LL")}. `,
      "You can pay to the bank account listed on your invoice.",
    ];
  if (isSuspendCard)
    return "Sorry, you can’t issue any cards since your account has been suspended. If you’d like reactivate and learn more about this, please contact your account manager.";
  if (isSuspendTransaction)
    return "Sorry, you can’t create any transactions since your account has been suspended. If you’d like reactivate and learn more about this, please contact your account manager.";
  return "Invalid state!";
};

export default descriptionTextDecider;
