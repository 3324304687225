import { useRouter } from "next/router";
import React, { useState } from "react";
import { colors } from "../../../assets/colors";
import crossBorderIcon from "../../../assets/images/crossborder-icon-40.svg";
import divideIcon from "../../../assets/images/divide-icon.svg";
import downloadIcon from "../../../assets/images/download-white-ribet.svg";
import minusIcon from "../../../assets/images/minus-icon.svg";
import plusIcon from "../../../assets/images/plus-icon-grey.svg";
import proceedIcon from "../../../assets/images/proceed-payment.svg";
import { CustomButton } from "../../../components/Buttons";
import {
  BackHeader,
  CloseHeader,
  MiddleWrapper,
  RightCardModalAtomic,
} from "../../../components/CardModal";
import { LeftRightList } from "../../../components/Lists";
import { booleanState, StatusLabel } from "../../../components/Status";
import { GothamMedium } from "../../../components/Text";
import {
  asyncDownload,
  customDateFormatter,
  flagIso3,
  formatCurrency,
  formatCurrencyNoDecimal,
} from "../../../components/tools";
import { arrayQuery } from "../../../pageComponents/batchDetailsComponents";
import { TransactionStatusFlow } from "../../../pageComponents/transactionDetailsComponents";
import { useDownload } from "../../../tools/api";
import { ActivityDetailsHeader } from "../activityModalComponents";
import { crossBorderSingleWorkflowComponents } from "../crossBorderSingleModal/components";
import { CrossBorderSingleStatusLine } from "../crossBorderSingleModal/status";
import { CenterendIconLocalTransfer } from "../localDisbursementBatchModal/transactionModal";
import { RecipientMinimalist } from "../transactionList";
import { WingsComponents } from "../wingsComponents";
import { useBatchDetails } from "./logics";
import { overviewCardsBatchFormatter } from "./summary/logics";

export const CrossBorderBatchModal = ({ isOpen, toggle, data }) => {
  const { push, pathname } = useRouter();

  const isCurrentScreenPaymentInstruction = pathname.includes(
    "payment-instruction"
  );

  const [wingsSelectedItem, setWingsSelectedItem] = useState(false);

  const {
    destination_country: destinationCountrySingle,
    destination_currency: destinationCurrSingle,
    destination_amount: destinationAmountSingle,
    source_currency: sourceCurrencySingle,
    reference_id: referenceIdSingle,
    beneficiary: beneficiarySingle,
    sent_amount: sentAmountSingle,
    created_at: createdAtSingle,
    status: statusSingle,
    state: stateSingle,
    bank_name: bankNameWings,
    rate: rateSingle,
    id: idSelected,
    fee: feeSingle,
  } = wingsSelectedItem || {};

  const {
    receiving_registered_name: nameSingle,
    bank_name,
    bank_account_number,
    receiving_first_name: firstNameSingle,
    receiving_last_name: lastNameSingle,
    recipient_email: emailSingle,
    rightTop: amountSingle,
  } = beneficiarySingle || {};

  const recipientSingleDecider = () => {
    if (nameSingle) return nameSingle;
    if (firstNameSingle && lastNameSingle)
      return `${firstNameSingle} ${lastNameSingle}`;
    if (firstNameSingle) return firstNameSingle;
    if (lastNameSingle) return lastNameSingle;
    if (emailSingle) return emailSingle;
    return "";
  };
  const recipientSingle = recipientSingleDecider();

  const recipientDataWings = {
    iso: flagIso3(destinationCountrySingle) || "id",
    bankAccount: bank_account_number,
    name: recipientSingle,
    bank: bankNameWings || bank_name,
  };

  const { isCompleted, isError } = booleanState(stateSingle);
  const isShowStatus = isCompleted || isError;

  const handleSelectedWingsItem = (values) => setWingsSelectedItem(values);

  const {
    id: idActivity,
    originator_id,
    description,
    currency,
    details,
    status,
  } = data || {};

  const detailsTransaction = details?.transaction || {};
  const { id } = detailsTransaction;
  const defaultId = id || originator_id || idActivity;

  const { loading, transactionData } = useBatchDetails(defaultId, isOpen);

  const { total_trxb, trxb_data = [], data: batchData } = transactionData;
  const { overview: overviewDatas } = batchData || {};

  const { state: stateBatch } = batchData || {};
  const {
    isCompleted: isCompletedBatch,
    isValidatingPayment,
    isOnProgress,
    isPaid,
    isRefund,
    isAwaitingRefund,
  } = booleanState(stateBatch) || {};

  const isShowSummary = isPaid || isOnProgress || isCompletedBatch;
  // const isShowStatusBatch =
  //   isCompletedBatch || isError || isRefund || isAwaitingRefund;
  const isShowStatusBatch = isCompletedBatch || isError;

  const dataForWings = trxb_data?.map(({ state, ...rest }) => {
    return {
      state,
      status: state,
      ...rest,
    };
  });

  const isOneOrMore = (value, word) => {
    if (value > 1) return `${word}s`;
    return word;
  };

  const {
    batch_id,
    created_at,
    total_trxb_amount: amount,
    state,
    batch_summary = [],
  } = batchData || {};

  const stateForStatusLabel = Boolean(wingsSelectedItem) ? stateSingle : state;

  const { total_fee } = batch_summary[0] || {};
  const totalAmount = Number(total_fee) + Number(amount);

  const showPay =
    !isCurrentScreenPaymentInstruction && state === "waiting_for_payment";

  const showDownload = state === "paid";

  const headerData = {
    icon: false,
    type: description || "Batch Transfer",
    title: `IDR ${formatCurrencyNoDecimal(totalAmount)}`,
  };

  const headerDataSingle = {
    icon: false,
    type: `Transfer to ${recipientSingle}`,
    title: amountSingle,
  };

  const overviewData = overviewCardsBatchFormatter(overviewDatas) || {};

  const leftRightArray = [
    {
      left: "Date",
      right: customDateFormatter(created_at || ""),
    },
    {
      left: "Reference",
      right: batch_id,
    },
    { spacer: true },
    {
      left: `Count of ${isOneOrMore(total_trxb, "transaction")}`,
      right: `${total_trxb} ${isOneOrMore(total_trxb, "Transaction")}`,
      w100Left: true,
    },
    {
      left: "Amount",
      right: `IDR ${formatCurrencyNoDecimal(amount)}`,
    },
    {
      left: "Total Fee",
      right: `IDR ${formatCurrencyNoDecimal(total_fee)}`,
      icon: plusIcon,
    },
    {
      left: "Total",
      right: `IDR ${formatCurrencyNoDecimal(totalAmount)}`,
      rightBold: true,
    },
  ];

  const leftRightSingle = [
    {
      left: "Date",
      right: customDateFormatter(createdAtSingle || ""),
    },
    {
      left: "Reference",
      right: referenceIdSingle,
    },
    // ...(isShowStatus
    //   ? [{ left: "Status", right: <StatusLabel status={statusSingle} /> }]
    //   : []),
    { spacer: true },
    {
      left: "You Pay",
      right: `${sourceCurrencySingle} ${formatCurrencyNoDecimal(
        Number(sentAmountSingle) + Number(feeSingle)
      )}`,
      rightBold: true,
    },
    {
      left: "Transfer Fee",
      right: `${sourceCurrencySingle} ${formatCurrencyNoDecimal(feeSingle)}`,
      icon: minusIcon,
    },
    {
      left: "Rate",
      right: `${destinationCurrSingle} 1 = ${sourceCurrencySingle} ${formatCurrency(
        rateSingle
      )}`,
      icon: divideIcon,
    },
    {
      left: "Recipient Gets",
      right: `${destinationCurrSingle} ${formatCurrency(
        destinationAmountSingle
      )}`,
      rightBold: true,
    },
  ];

  const [transactionItem, setTransactionItem] = useState(false);

  const { handleDownload, loading: loadingDownload } = useDownload();

  const [middleHeight, setMiddleHeight] = useState(0);

  const handleClick = (item) => {
    const { type, isSingle = false, idSingle } = item || {};

    const isToPayment = type == "proceed_to_payment";
    const isDownload = type == "download";

    if (isToPayment)
      return push({
        pathname: `/history/batch-payment/${defaultId}/payment-instruction/`,
      });

    if (isDownload) {
      const transactionIds = (trxb_data || []).map(({ id }) => id);

      const url = isSingle
        ? `/prints/${idSingle}/transaction_receipt.pdf`
        : `/prints/bulk_transaction_receipt?${arrayQuery(transactionIds)}`;
      const type = isSingle ? "application/pdf" : "application/zip";
      const fileName = isSingle
        ? `Transfez-Invoice-${idSelected}.pdf`
        : `Transfez-Invoice-${batch_id}.zip`;
      return handleDownload({
        url,
        type,
        fileName,
      });
    }

    return setTransactionItem(item);
  };

  const ButtonDecider = () => {
    if (showPay)
      return (
        <CustomButton
          className="w-100"
          style={{ height: 32, fontSize: 14 }}
          onClick={() => handleClick({ type: "proceed_to_payment" })}
        >
          <img src={proceedIcon} style={{ width: 20, marginRight: 8 }} />
          Proceed to Payment
        </CustomButton>
      );
    if (showDownload)
      return (
        <>
          <CustomButton
            className="w-100"
            style={{ height: 32, fontSize: 14 }}
            onClick={() => handleClick({ type: "download" })}
            leftIcon={
              <img src={downloadIcon} style={{ width: 20, marginRight: 8 }} />
            }
            isLoading={loadingDownload}
          >
            Download Receipt
          </CustomButton>
        </>
      );
    return null;
  };

  // const { buttons, rejectModal, isRejection, openPinModal } =
  //   crossBorderSingleWorkflowComponents({
  //     transaction: batchData,
  //     afterSuccess: () => {},
  //     bankTransfer: true,
  //   });

  // const defaultStyle = {
  //   height: 32,
  //   fontSize: 14,
  // };

  // const ButtonsDecider = ({ hideStatus }) => {
  //   if (isValidatingPayment) return null;

  //   const DefaultButton = (props) => (
  //     <CustomButton
  //       style={{
  //         marginTop: hideStatus ? 0 : 16,
  //         padding: "6px 24px",
  //         ...defaultStyle,
  //       }}
  //       {...props}
  //     />
  //   );

  //   const showPay =
  //     !isCurrentScreenPaymentInstruction && state === "waiting_for_payment";

  //   const showDownload = state === "completed";

  //   // const showRefund =
  //   //   (state == "rejected" || state == "failed") &&
  //   //   !isFromTransactionActivities;

  //   if (showPay) {
  //     // const totalAmount = `${source_currency} ${formatCurrencyNoDecimal(
  //     //   Number(sent_amount) + Number(fee)
  //     // )}`;
  //     // const handlePay = () =>
  //     //   push({
  //     //     pathname: `/history/single-payment/${defaultId}/payment-instruction/`,
  //     //   });
  //     return (
  //       <DefaultButton
  //         onClick={() => handleClick({ type: "proceed_to_payment" })}
  //       >
  //         <img src={proceedIcon} style={{ width: 20, marginRight: 8 }} />
  //         Proceed to Payment
  //       </DefaultButton>
  //     );
  //   }

  //   if (showDownload) {
  //     const handleDownload = async () => {
  //       await asyncDownload({
  //         url: `/prints/${defaultId}/transaction_receipt.pdf`,
  //         filename: `Transfez-Invoice-${defaultId}.pdf`,
  //       });
  //     };
  //     return (
  //       <DefaultButton onClick={handleDownload}>
  //         <img src={downloadIcon} style={{ width: 20, marginRight: 8 }} />
  //         Download Receipt
  //       </DefaultButton>
  //     );
  //   }
  //   // if (showRefund)
  //   //   return <DefaultButton onClick={toggleRefund}>Refund</DefaultButton>;

  //   return buttons();
  // };

  const ButtonDeciderSingle = ({ hideStatus, data }) => {
    const { state, id } = data || {};
    const DefaultButton = (props) => (
      <CustomButton
        style={{
          marginTop: hideStatus ? 0 : 16,
          padding: "6px 24px",
          height: 32,
          fontSize: 14,
        }}
        {...props}
      />
    );

    const showDownload = state === "completed";

    if (showDownload) {
      return (
        <DefaultButton
          onClick={() =>
            handleClick({ type: "download", isSingle: true, idSingle: id })
          }
          isLoading={loadingDownload}
        >
          <img src={downloadIcon} style={{ width: 20, marginRight: 8 }} />
          Download Receipt
        </DefaultButton>
      );
    }

    return null;
  };

  return (
    <>
      <RightCardModalAtomic
        isBothToggle={true}
        isWhite={Boolean(wingsSelectedItem)}
        idWings={idSelected}
        hideModal={transactionItem}
        isOpen={isOpen}
        loading={loading}
        header={
          <ActivityDetailsHeader
            toggle={toggle}
            data={Boolean(wingsSelectedItem) ? headerDataSingle : headerData}
            isWhite={Boolean(wingsSelectedItem)}
            close={
              Boolean(wingsSelectedItem) && (
                <>
                  <BackHeader
                    color={colors.grey33}
                    isMedium
                    text={"Batch Details"}
                    toggle={() => setWingsSelectedItem(false)}
                  />
                  <CloseHeader color={colors.grey33} isMedium toggle={toggle} />
                </>
              )
            }
          />
        }
        headerStyle={{ paddingBottom: 48 }}
        middle={
          <MiddleWrapper setHeight={setMiddleHeight} height={middleHeight}>
            <CenterendIconLocalTransfer icon={crossBorderIcon} />
          </MiddleWrapper>
        }
        wingComponent={
          <WingsComponents
            data={dataForWings}
            setSelectedWingsItem={handleSelectedWingsItem}
            selectedWingsItem={wingsSelectedItem}
            isShowSummary={isShowSummary}
            overviewRaw={overviewData}
          />
        }
        body={
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div style={{ marginTop: 20 }}>
                <StatusLabel status={stateForStatusLabel} />
              </div>
              <div style={{ marginTop: ButtonDecider() ? 16 : 0 }}>
                {Boolean(wingsSelectedItem) ? (
                  <ButtonDeciderSingle data={wingsSelectedItem} />
                ) : (
                  <ButtonDecider />
                )}
              </div>
            </div>
            <div style={{ marginTop: 24 }}>
              <LeftRightList
                array={
                  Boolean(wingsSelectedItem) ? leftRightSingle : leftRightArray
                }
              />
            </div>
            {Boolean(wingsSelectedItem) ? (
              <>
                <GothamMedium style={{ fontSize: 16, marginTop: 24 }}>
                  Recipient
                </GothamMedium>
                <RecipientMinimalist data={recipientDataWings} />
                <GothamMedium style={{ fontSize: 16, marginTop: 24 }}>
                  Updates
                </GothamMedium>
                <TransactionStatusFlow
                  item={wingsSelectedItem}
                  status={wingsSelectedItem?.state}
                />
              </>
            ) : (
              <TransactionStatusFlow
                item={transactionData?.data}
                status={transactionData?.data?.state}
              />
            )}
          </>
        }
      />
    </>
  );
};
