import { getTime, parseISO } from "date-fns";
import React, { useState } from "react";
import { CustomButton } from "../../../../components/Buttons";
import {
  BackHeader,
  MiddleWrapper,
  RightCardModalAtomic,
} from "../../../../components/CardModal";
import { FullDateFormatter } from "../../../../components/tools";
import { CountDownTopUpModal } from "../../../../pageComponents/homeComponents/topup/components";
import { ActivityDetailsHeader } from "../../activityModalComponents";
import { usePaymentMethod } from "../paymentMethodModal/logic";
import { BankDetailsSection } from "./components";
import { SmartUploadReceiptModal } from "../../../../modals/smartUploadReceiptModal/parent";
import { useModalHook } from "../../../../components/Modals";
import { bankLists } from "../../../../assets/data";

const timeFormatter = (created_at) => {
  const createdAtTime = getTime(parseISO(created_at || 0)) || 0;
  // const oneHour = 60 * 60 * 1000;
  // const addedTime = oneHour * 3;
  // const time = createdAtTime + addedTime;
  const time = createdAtTime;
  return time;
};

export const DirectPaymentInstructionModal = ({
  isOpen,
  toggle,
  close,
  data,
}) => {
  const [middleHeight, setMiddleHeight] = useState(0);
  const [bank, selectedBank] = useState(bankLists[0]);

  const { isOpen: isOpenUpload, toggle: toggleUpload } = useModalHook();

  const { headerData } = usePaymentMethod("Payment Instruction");

  const { payment_expiry_at, id } = data || {};

  const urlData = { type: "single_payment", id };

  const time = timeFormatter(payment_expiry_at);
  const expiredIn = FullDateFormatter(payment_expiry_at || "");
  const dataForCountDown = { expiredIn };

  const onClick = (val) => {
    const isOpenModal = val === "open_modal";
    const isBackUpload = val === "back_upload";

    if (isOpenModal) {
      toggle();
      return toggleUpload();
    }
    if (isBackUpload) {
      toggleUpload();
      return close();
    }
  };

  return (
    <>
      <RightCardModalAtomic
        isOpen={isOpen}
        header={
          <ActivityDetailsHeader
            toggle={toggle}
            data={headerData}
            // close={<BackHeader color="#FFF" text="Back" toggle={toggle} />}
          />
        }
        headerStyle={{ paddingBottom: 80 }}
        middleHeight={middleHeight}
        middle={
          <MiddleWrapper setHeight={setMiddleHeight} height={middleHeight}>
            <CountDownTopUpModal time={time} data={dataForCountDown} />
          </MiddleWrapper>
        }
        paddingTop={18}
        body={
          <>
            <BankDetailsSection
              data={data}
              bank={bank}
              selectedBank={selectedBank}
            />
            <CustomButton
              className="mt-4"
              style={{ width: "100%", height: 40 }}
              onClick={() => onClick("open_modal")}
            >
              I have paid
            </CustomButton>
          </>
        }
      />
      <SmartUploadReceiptModal
        isOpen={isOpenUpload}
        toggle={() => onClick("back_upload")}
        bank={bank?.bank}
        urlData={urlData}
      />
    </>
  );
};
