import React, { useState } from "react";
import checkSquareIcon from "../assets/images/check-square-icon.svg";
import emptySquareIcon from "../assets/images/empty-square-icon.svg";

export const useIdsTable = (data, defaultIds) => {
  const getAllIds = data.map(({ id }) => id);

  const [selectedIds, setSelectedIds] = useState(defaultIds || []);

  const isAllChecked =
    getAllIds.length && getAllIds.length == selectedIds.length;

  const handleClickAll = () => {
    if (isAllChecked) return setSelectedIds([]);
    setSelectedIds(getAllIds);
  };

  const handleAddId = (id) => setSelectedIds((prev) => [...prev, id]);

  const handleRemoveId = (id) =>
    setSelectedIds((prev) => prev.filter((item) => item !== id));

  const handleRemoveAllId = () => {
    setSelectedIds([]);
  };
  const handleClickItem = (id, isActive) => {
    if (isActive) return handleRemoveId(id);
    handleAddId(id);
  };
  const selectedAmount = selectedIds.length;
  return {
    handleClickAll,
    selectedIds,
    isAllChecked,
    handleClickItem,
    handleRemoveAllId,
    selectedAmount,
  };
};

export const CheckBox = ({ isActive, onClick, style }) => (
  <img
    src={isActive ? checkSquareIcon : emptySquareIcon}
    className="darkhover"
    onClick={onClick}
    style={style}
  />
);

export const deepUseIdsTable = (data) => {
  const getAllIds = data?.map((array, index) => index);

  const [selectedIds, setSelectedIds] = useState([]);

  const isAllChecked =
    getAllIds.length && getAllIds.length == selectedIds.length;

  const handleClickAll = () => {
    if (isAllChecked) return setSelectedIds([]);
    setSelectedIds(getAllIds);
  };

  const handleUncheckedAll = () => setSelectedIds([]);

  const handleAddId = (id) => setSelectedIds((prev) => [...prev, id]);

  const handleRemoveId = (id) =>
    setSelectedIds((prev) => prev.filter((item) => item !== id));

  const handleRemoveAllId = () => {
    setSelectedIds([]);
  };
  const handleClickItem = (id, isActive) => {
    if (isActive) return handleRemoveId(id);
    handleAddId(id);
  };
  const selectedAmount = selectedIds.length;
  return {
    handleClickAll,
    handleUncheckedAll,
    selectedIds,
    isAllChecked,
    handleClickItem,
    handleRemoveAllId,
    selectedAmount,
  };
};
