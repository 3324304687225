import React, { useEffect } from "react";
import { booleanState } from "../../components/Status";
import { ApiDetailsModal } from "./apiDetailsModal";
import { CrossBorderBatchModal } from "./crossBorderBatchModal/parent";
import { LocalDisbursementBatch } from "./localDisbursementBatchModal/parent";
import { CrossBorderSingleDetailsModal } from "./crossBorderSingleModal/parent";
import { RefundDetailsModal } from "./refundDetailsModal";
import { TopupDetailsModal } from "./topup/parent";
import { IncomingPaymentModal } from "./IncomingPaymentModal";
import { WithDrawDetailModal } from "./withdrawModal";
import { AllocateModal } from "./AllocateModal";
import { ReAllocateModal } from "./ReAllocateModal";
import { CardTransactionModal } from "./cardTransactionModal";
import { CardPaymentModal } from "./cardPaymentModal";
import { eventsTracker } from "../../universalFunctions/events";
import { LocalDisbursementModal } from "./localDisbursementModal/parent";
import { PayrollModal } from "./payroll/parent";
import { EWalletAllocationModal } from "./eWallet/allocation/parent";
import { EWalletReturnModal } from "./eWallet/return/parent";
import { EWalletActivityModal } from "./eWallet/default/parent";
import { InvoicePaymentModal } from "./invoicePayment/parent";
import { noCase } from "change-case";
import { ReimbursementModal } from "./reimbursementModal";
import { LocalTransferSingleModal } from "./localTransferSingleModal/parent";
import DetailModal from "../../pageComponents/cardsPageComponent/MainCardsScreen/DetailModal";

export const SmartActivityModal = ({
  isOpen,
  toggle,
  data,
  handleRefund = () => {}, // use this only from bank transfer OR local disbursementSingle
  ...props
}) => {
  if (!isOpen) return null;

  useEffect(() => {
    const { category, id } = data || {};
    eventsTracker("open_transaction_activities_modal", { category, id });
  }, []);

  const { type, category, description, originator_type } = data;

  const isBatch =
    (type === "payroll" && originator_type === "Job") ||
    originator_type === "Job";

  const {
    isTopUp,
    isSingleTransaction,
    isBatchTransaction,
    isApiTransaction,
    isRefund,
    isIncomingPayment,
    isWithdraw,
    isAllocate,
    isCard,
    isCardTransaction,
    isCardBillPayment,
    isReAllocate,
    isLocalDisbursement,
    isLocalDisbursementBatch,
    isPayroll,
    isWalletAllocation,
    isWalletReturn,
    isWalletHistory,
    isAllocationRequest,
    isReturnRequest,
    isInvoicePayment,
    isEWallet,
    isReimbursement,
    isLocalTransferSingle,
  } = booleanState(type || category);

  const defaultProps = { toggle, isOpen, data, ...props };

  if (isAllocate) return <AllocateModal {...defaultProps} />;
  if (isReAllocate) return <ReAllocateModal {...defaultProps} />;
  if (isSingleTransaction)
    return (
      <CrossBorderSingleDetailsModal
        // setData handleRefund afterSuccess
        {...defaultProps}
        handleRefund={handleRefund}
      />
    );
  if (isBatchTransaction || isBatch)
    return <CrossBorderBatchModal {...defaultProps} />;
  if (isTopUp) return <TopupDetailsModal {...defaultProps} />;
  if (isRefund) return <RefundDetailsModal {...defaultProps} />;
  if (isIncomingPayment) return <IncomingPaymentModal {...defaultProps} />;
  if (isWithdraw) return <WithDrawDetailModal {...defaultProps} />;
  if (isCard)
    return (
      <DetailModal
        // afterSuccess
        dataDetail={data}
        {...defaultProps}
      />
    );
  if (isCardTransaction) return <CardTransactionModal {...defaultProps} />;
  if (isCardBillPayment) return <CardPaymentModal {...defaultProps} />;
  if (isApiTransaction || isLocalDisbursement)
    return <ApiDetailsModal {...defaultProps} />;
  if (isLocalDisbursementBatch)
    return (
      <LocalDisbursementBatch
        // setData hideButton
        {...defaultProps}
        handleRefund={handleRefund}
      />
    );
  if (isLocalTransferSingle)
    return <LocalTransferSingleModal {...defaultProps} />;
  if (isPayroll)
    return (
      <PayrollModal
        //afterSuccess
        {...defaultProps}
      />
    );

  // e-wallet

  const noCaseDescription = (string) =>
    noCase(description).includes(string) && isEWallet;

  const isBalanceAllocation = noCaseDescription("balance allocation");
  const isReturnBalance = noCaseDescription("return balance");

  if (isWalletHistory) return <EWalletActivityModal {...defaultProps} />;
  if (isWalletAllocation || isAllocationRequest || isBalanceAllocation)
    return (
      <EWalletAllocationModal
        //isHome
        {...defaultProps}
      />
    );
  if (isWalletReturn || isReturnRequest || isReturnBalance)
    return <EWalletReturnModal {...defaultProps} />;
  if (isReimbursement) return <ReimbursementModal {...defaultProps} />;
  if (isInvoicePayment) return <InvoicePaymentModal {...defaultProps} />;
  // e-wallet

  toggle();

  return null;
};
