import { useEffect } from "react";
import {
  customDateFormatter,
  flagIso3,
  formatCurrency,
  formatCurrencyNoDecimal,
} from "../../../components/tools";
import { apiTransactionFormatter } from "../../../pageComponents/transactionDetailsComponents";
import { fetch } from "../../../tools/api";
import LocalDisbIcon from "../../../assets/images/account-icon-local-disbursement.svg";
import equalsIcon from "../../../assets/images/equals-icon.svg";
import plusIcon from "../../../assets/images/plus-icon-grey.svg";
import timesIcon from "../../../assets/images/times-icon.svg";
import { renderedName } from "../../../pageComponents/apiDisbursementComponents/table";
import { useConstants } from "../../../contexts/ConstantsContext/parent";

export const useLocalDisbursementLogic = ({ data, isOpen }) => {
  const {
    reference_number: referenceData,
    created_at: createdAtData,
    amount: amountData,
    details,
    description,
    originator_id,
    originator_type,
    category,
    payer_name,
    id,
  } = data || {};

  // const isLocalRefund =
  //   category === "refund_transaction" && originator_type === "Hub::Transaction";

  // const amountDetails = Number(details?.originator?.amount) || 0;
  // const feeDetails = Number(details?.originator?.fee) || 0;
  // const totalAmountDetails = Number(amountDetails) + Number(feeDetails);

  const {
    data: getData,
    refetch,
    loading,
  } = fetch({
    url: `/disbursements/${originator_id || id}`,
    woInit: true,
    formatter: (data) => data?.data,
    defaultValue: {},
  });

  useEffect(() => {
    if (!isOpen) return;
    if (!id) return;
    // if (isLocalRefund) return;
    refetch();
  }, [isOpen, id]);

  const {
    sent_amount = 0,
    source,
    sender,
    beneficiary,
    rate,
    destination,
    fee = 0,
    amount,
    state,
    created_at,
    reference_id,
  } = getData;

  const {
    country_iso_code: beneISO,
    bank: beneBankRaw,
    bank_name: beneBankNameRaw,
    account: beneAccountRaw,
    bank_account_number: beneAccountNumberRaw,
    iban,
    swift_bic_code,
  } = beneficiary || {};

  const beneBankName = beneBankRaw || beneBankNameRaw;
  const beneAccountNumber = beneAccountRaw || beneAccountNumberRaw;
  const beneIban = iban && `${iban} (Iban)`;
  const beneSwift = swift_bic_code && `${swift_bic_code} (Swift Code)`;
  const total_amount = Number(amount) + Number(fee);

  // const amountLeftRight = isLocalRefund ? amountDetails : amount;
  // const feeLeftRight = isLocalRefund ? feeDetails : fee;
  // const totalAmountLeftRight = isLocalRefund
  //   ? totalAmountDetails
  //   : total_amount;

  const {
    beneName,
    senderName,
    dataDetails = [],
  } = apiTransactionFormatter(getData);
  const sCurr = source?.currency;

  const senderDetails = dataDetails[0] || {};
  const beneDetails = dataDetails[1] || {};

  const headerData = {
    // type: description,
    icon: false,
    title: `IDR ${formatCurrencyNoDecimal(total_amount)}`,
    leftText: renderedName(sender),
    rightText: renderedName(beneficiary),
  };

  const recipientData = {
    name: renderedName(beneficiary),
    bank: payer_name,
    bankAccount: (beneBankName && beneAccountNumber) || beneIban || beneSwift,
  };

  const senderData = {
    name: renderedName(sender),
  };

  const isIdrToIdr = destination?.currency == "IDR" && sCurr == "IDR";

  const detailsArray = [
    {
      left: "Created Date",
      right: customDateFormatter(created_at),
    },
    {
      left: "Reference ID",
      right: reference_id,
      styleRight: {
        maxWidth: "60%",
        overflowWrap: "break-word",
        wordWrap: "break-word",
        hyphens: "auto",
      },
    },
    { spacer: true },
    {
      left: "Sent Amount",
      right: `IDR ${formatCurrencyNoDecimal(amount)}`,
      rightBold: true,
    },
    {
      left: (
        <>
          <img src={plusIcon} className="mr-2" />
          Transfer fee
        </>
      ),
      right: `IDR ${formatCurrencyNoDecimal(fee)}`,
    },
    {
      left: "Total Amount",
      right: `IDR ${formatCurrencyNoDecimal(total_amount)}`,
      rightBold: true,
    },
  ];

  const showDownload = state === "completed";

  return {
    senderDetails,
    beneDetails,
    headerData,
    detailsArray,
    isIdrToIdr,
    loading,
    getData,
    state,
    recipientData,
    senderData,
    showDownload,
  };
};
