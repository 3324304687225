import { colors } from "../../../../assets/colors";

export const overviewCardsBatchFormatter = (overview) => {
  const {
    failed = 0,
    completed = 0,
    on_progress = 0,
    validating_payment = 0,
    rejected = 0,
    canceled = 0,
  } = overview || {};

  const onProgressNumber = on_progress + validating_payment;
  const failedNumber = failed + rejected + canceled;
  const completedNumber = completed;
  const array = [
    {
      title: "Success",
      number: completedNumber,
      color: colors.green28,
    },
    { title: "In Progress", number: onProgressNumber, color: colors.teal21 },
    {
      title: "Failed",
      number: failedNumber,
      color: colors.redE9,
    },
  ];
  return array;
};
