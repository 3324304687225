import { useRouter } from "next/router";
import { customDateFormatter, formatCurrency } from "../../../components/tools";

export const reallocateFormatter = (object) => {
  const { query } = useRouter()
  const { type } = query || {}
  const {
    currency,
    created_at,
    details,
    reference_number,
    amount: rawAmount,
    status,
    user,
  } = object || {};
  const { amount: detailsAmount } = details || {};
  const { name: userName, email: userEmail } = user || {};
  const newAmount = Number(rawAmount.split(".")[0]);
  const amount = detailsAmount || newAmount;
  const isIndoWallet = type === 'indonesian_wallet'
  const isPlus = isIndoWallet ? `+` : `-`

  const leftTop = `Allocate Funds - Card Balance to Main Balance`;
  const leftBottom = `• ${reference_number} • ${customDateFormatter(
    created_at
  )} • ${userName || userEmail} •`;
  const leftStatus = status;
  const rightTop = `${isPlus}${currency} ${formatCurrency(amount)}`;

  return { ...object, leftTop, leftBottom, leftStatus, rightTop };
};
