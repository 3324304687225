import { titleCase } from "change-case";
import React from "react";
import { GothamMedium } from "./Text";

const CardStatusTag = ({
  status,
  is_suspended,
  style,
  fontStyle,
  issuanceStatus,
}) => {
  if (status === "not_you") return null;
  const fontColorDecider = (status) => {
    if (issuanceStatus === "rejected") return "#E73126";
    if (is_suspended) return "white";
    if (status === "ACTIVE") return "#06AC4E";
    if (status === "NOT_ACTIVE" || status === "REQUEST_NEW_CARD")
      return "#909098";
    if (status === "TEMP_BLOCK") return "#E73126";
    if (status === "P_BLOCK") return "#FFFFFF";
    if (status === "REQUEST_P_BLOCK") return "#FCB037";
    if (status === "REQUEST_TEMP_BLOCK") return "#FCB037";
    if (status === "REQUEST_ACTIVE") return "#2C4A77";
    if (status === "REQUEST_UNBLOCK") return "#FCB037";
    if (status === "you") return "#102C57";
    return "black";
  };

  const backgroundColorDecider = (status) => {
    if (issuanceStatus === "rejected") return "#FFD5CD";
    if (is_suspended) return "#E95757";
    if (status === "ACTIVE") return "#E4F4EB";
    if (status === "NOT_ACTIVE" || status === "REQUEST_NEW_CARD")
      return "#E6E6E8";
    if (status === "TEMP_BLOCK") return "#FDEBEA";
    if (status === "P_BLOCK") return "#E95757";
    if (status === "REQUEST_TEMP_BLOCK") return "#FFF7EA";
    if (status === "REQUEST_P_BLOCK") return "#FFF7EA";
    if (status === "REQUEST_ACTIVE") return "#9CC3F5";
    if (status === "REQUEST_UNBLOCK") return "#FFF7EA";
    if (status === "you") return "#E1EEFF";
    return "#E6E6E8";
  };

  const textDecider = (status) => {
    if (issuanceStatus === "rejected") return "Rejected";
    if (is_suspended) return "Suspended";
    if (status === "ACTIVE") return "Active";
    if (status === "NOT_ACTIVE" || status === "REQUEST_NEW_CARD")
      return "Not Active";
    if (status === "TEMP_BLOCK") return "Temporary Blocked";
    if (status === "P_BLOCK") return "Permanent Blocked";
    if (status === "REQUEST_TEMP_BLOCK") return "Request Block";
    if (status === "REQUEST_P_BLOCK") return "Request Block";
    if (status === "REQUEST_ACTIVE") return "Activating";
    if (status === "REQUEST_UNBLOCK") return "Request Unblock";
    return titleCase(status);
  };

  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{
        heigth: "18px",
        borderRadius: "16px",
        backgroundColor: backgroundColorDecider(status),
        padding: "2px 8px",
        ...style,
      }}
    >
      <GothamMedium
        style={{ color: fontColorDecider(status), fontSize: 12, ...fontStyle }}
      >
        {textDecider(status)}
      </GothamMedium>
    </div>
  );
};

export default CardStatusTag;
