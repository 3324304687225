import React from "react";
import Skeleton from "react-loading-skeleton";
import { colors } from "../../../assets/colors";
import { TextInlineRegular, TypeFezNeueMedium } from "../../../components/Text";
import { customDateFormatter } from "../../../components/tools";
import {
  ReasonNotes,
  StatusPayrollLine,
} from "../../../pageComponents/payrollCreateComponents/loop/success/status";
import { useCrossBorderSingleWorkflow } from "../crossBorderSingleModal/hooks/workflow";

export const InvoicePaymentStatusLine = ({ transaction, loading = false }) => {
  const {
    firstLineObj,
    isDraft,
    rejection_note,
    approverLineArray,
    validatingObj,
    completedLineObj,
    isCompleted,
    isCancelled,
    cancelledLineObj,
  } = useCrossBorderSingleWorkflow(transaction);
  const { name } = transaction?.local_recipient || {};
  const { state, updated_at } = transaction;
  const nameDecider = () => {
    if (name && name != "-") return name;
    return "recipients";
  };

  const isFailed = state === "declined";

  if (isDraft) return null;
  const isRejection = Boolean(transaction?.rejected_by_id);

  const arrayDecider = () => {
    if (isCompleted) {
      return [firstLineObj, ...approverLineArray, ...completedLineObj];
    }
    if (isCancelled) {
      if (isRejection) return [firstLineObj, ...approverLineArray];
      return [firstLineObj, cancelledLineObj];
    }
    if (isFailed) {
      return [
        firstLineObj,
        ...approverLineArray,
        {
          ...validatingObj,
          text: `Transferring money to ${nameDecider()}`,
          isActive: true,
          date: customDateFormatter(updated_at),
        },
        {
          text: (
            <>
              Transaction{" "}
              <TextInlineRegular style={{ color: colors.errorRed }}>
                failed
              </TextInlineRegular>
              . We will return the money to your company's main balance
            </>
          ),
          isActive: true,
          isRedButton: true,
          date: customDateFormatter(updated_at),
        },
      ];
    }
    return [
      firstLineObj,
      ...approverLineArray,
      {
        ...validatingObj,
        text: `Transferring money to ${nameDecider()}`,
      },
      completedLineObj,
    ];
  };

  return (
    <div className="w-100" style={{ marginTop: 12 }}>
      <TypeFezNeueMedium className="font16" style={{ marginBottom: 16 }}>
        Updates
      </TypeFezNeueMedium>
      {loading ? (
        <div
          style={{
            marginLeft: 36,
            display: "flex",
            flexDirection: "column",
            gap: 14,
          }}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Skeleton width={150} />
            <Skeleton width={130} height={10} />
          </div>
          <div style={{ display: "flex", flexDirection: "column", gap: 12 }}>
            <Skeleton width={240} />
            <Skeleton width={100} />
          </div>
          <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
            <Skeleton width={200} />
            <Skeleton width={130} />
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Skeleton width={65} />
          </div>
        </div>
      ) : (
        <StatusPayrollLine array={arrayDecider()} isModal />
      )}
      {rejection_note && !loading && <ReasonNotes text={rejection_note} />}
    </div>
  );
};
