import React from "react";
import { IoIosWarning } from "react-icons/io";
import * as Sentry from "@sentry/nextjs";

const ErrorPage = ({ onClick }) => (
  <div
    className="d-flex justify-content-center align-items-center my-4"
    style={{
      flexDirection: "column",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    }}
  >
    <IoIosWarning size={64} className="mb-3" color="#e1b456" />
    <h1 style={{ fontFamily: "GothamBook" }} className="font20">
      Please try again
    </h1>
    <h1 style={{ fontFamily: "GothamBook" }} className="font16">
      Oops, there is an error occured while loading the page.
    </h1>
    <h1 style={{ fontFamily: "GothamBook" }} className="font14">
      If this problem still persists please try open{" "}
      <a href="https://business.transfez.com">business.transfez.com</a> in
      another browser
    </h1>

    <button
      onClick={onClick}
      className="btn mt-4 px-4"
      style={{ height: 36, fontFamily: "GothamBook" }}
    >
      Reload page
    </button>
  </div>
);

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);

    this.state = { hasError: false };
  }

  // client error catcher
  static getDerivedStateFromError(error) {
    return { hasError: true };
  }
  // client error catcher

  componentDidCatch(error, errorInfo) {
    Sentry.captureException(
      `Error client \nerror:${error} \nerrorInfo:${errorInfo}`
    );
  }

  render() {
    if (!this.state.hasError) return this.props.children;

    return <ErrorPage onClick={() => window.location.reload()} />;
  }
}

export default ErrorBoundary;
