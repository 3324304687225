import BRIIcon from "../assets/images/bank-bri-icon.svg";
import PermataIcon from "../assets/images/bank-permata-icon.svg";
import CIMBNiagaIcon from "../assets/images/bank-cimb-niaga-icon.svg";
import MandiriIcon from "../assets/images/bank-mandiri-icon.svg";
import BNIIcon from "../assets/images/bank-bni-icon.svg";
import BTPNIcon from "../assets/images/bank-btpn-icon.svg";
import BCAIcon from "../assets/images/bank-bca-icon.svg";

const bankIconDecider = (bank) => {
  const isBankBRI = "Bank BRI".toLowerCase() === bank.toLowerCase();
  const isBankPermata = "Bank Permata".toLowerCase() === bank.toLowerCase();
  const isBankCIMBNiaga =
    "Bank CIMB Niaga".toLowerCase() === bank.toLowerCase();
  const isBankMandiri = "Bank Mandiri".toLowerCase() === bank.toLowerCase();
  const isBankBNI = "Bank BNI".toLowerCase() === bank.toLowerCase();
  const isBankBTPN = "Bank BTPN".toLowerCase() === bank.toLowerCase();
  const isBankBCA = "Bank BCA".toLowerCase() === bank.toLowerCase();

  if (isBankBRI) return BRIIcon;
  if (isBankPermata) return PermataIcon;
  if (isBankCIMBNiaga) return CIMBNiagaIcon;
  if (isBankMandiri) return MandiriIcon;
  if (isBankBNI) return BNIIcon;
  if (isBankBTPN) return BTPNIcon;
  if (isBankBCA) return BCAIcon;
};

export default bankIconDecider;
