import { noCase } from "change-case";
import countries from "i18n-iso-countries";
import { useRouter } from "next/router";
import React, { useState } from "react";
import { colors } from "../../assets/colors";
import {
  MainCardHeaderMolecule,
  MiddleWrapper,
  RightCardModalAtomic,
} from "../../components/CardModal";
import { GetFlag } from "../../components/inputs";
import { useModalHook } from "../../components/Modals";
import { GothamMedium } from "../../components/Text";
import {
  customDateFormatter,
  formatCurrencyFixed,
  formatCurrencyNoDecimal,
} from "../../components/tools";
import { TransactionActivities } from "../../pageComponents/accountCardDetailsComponents/lists";
import { cardTransactionsFormatter } from "../../pageComponents/accountCardDetailsComponents/logics/parent";
import {
  AccountDashboardAction,
  accountDashboardData,
} from "../../pageComponents/accountDashboardComponents";
import { fetch } from "../../tools/api";
import { SmartActivityModal } from "../smartActivityModal/parent";
import { VAModal } from "./VAModal";

export const SmartAccountModal = ({ toggle, data, isOpen }) => {
  if (!isOpen) return null;
  const { isoCode, balance, curSymbol, vaDetails, vaDetailsArr } = data || {};

  const iso = countries.numericToAlpha2(isoCode);
  const isIdr = curSymbol == "IDR";

  const url = `/transaction_activities?q[partner_name_eq]=nium`;

  const { data: cardsData, loading } = fetch({
    url,
    formatter: (res) => {
      const formattedData = cardTransactionsFormatter(res);
      const result = (formattedData?.array || []).map((item) => {
        const leftBottom = customDateFormatter(item?.created_at, true);
        return { ...item, leftBottom };
      });
      return result;
    },
    defaultValue: [],
  });

  const formatCurrency = isIdr ? formatCurrencyNoDecimal : formatCurrencyFixed;
  const [middleHeight, setMiddleHeight] = useState(0);

  const [detailsData, setDetailsData] = useState(false);

  const { isOpen: isOpenVA, toggle: toggleVA } = useModalHook();

  const { push } = useRouter();

  const handleClickBox = (data) => {
    const { value, href, query } = data;
    const isDetails = value == "account_details";

    if (isDetails) return toggleVA();
    return push({ pathname: href, query });
  };

  return (
    <>
      <RightCardModalAtomic
        isOpen={isOpen}
        middleHeight={middleHeight}
        hideModal={Boolean(detailsData) || isOpenVA}
        header={
          <MainCardHeaderMolecule
            toggle={toggle}
            icon={<GetFlag iso={noCase(iso)} width={48} height={48} />}
            title={`${curSymbol} ${formatCurrency(balance)}`}
            subTitle={`Global - ${curSymbol} Account`}
            middleHeight={middleHeight}
          />
        }
        middle={
          <MiddleWrapper height={middleHeight} setHeight={setMiddleHeight}>
            <AccountDashboardAction
              onClick={handleClickBox}
              data={accountDashboardData({
                iso: curSymbol,
                query: { type: "global_wallet" },
                hideDetails: !vaDetails,
              })}
            />
          </MiddleWrapper>
        }
        body={
          <>
            <div
              className="d-flex justify-content-between"
              style={{ marginBottom: 8 }}
            >
              <GothamMedium className="m-0">Recent Transactions</GothamMedium>
              <GothamMedium
                className="m-0 darkhover"
                style={{ color: colors.teal }}
              >
                See All
              </GothamMedium>
            </div>
            <TransactionActivities
              data={cardsData}
              fromModal
              onClick={(item) => setDetailsData(item)}
              loading={loading}
            />
          </>
        }
      />
      <SmartActivityModal
        isOpen={Boolean(detailsData)}
        data={detailsData}
        toggle={() => setDetailsData(false)}
      />
      <VAModal isOpen={isOpenVA} toggle={toggleVA} data={data} />
    </>
  );
};
