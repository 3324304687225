export const recipientName = (beneficiary) => {
  const {
    receiving_registered_name,
    receiving_first_name,
    receiving_last_name,
  } = beneficiary || {};

  const result =
    receiving_registered_name || `${receiving_first_name || ""} ${receiving_last_name}`;
  return result;
};
