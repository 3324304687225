import { useRouter } from "next/router";

export const paymentInstructionBooleans = () => {
  const { query, pathname } = useRouter();
  const { wallet } = query || {};

  const isTopUpIndoWallet = wallet == "indonesian_wallet";

  const isSinglePayment = pathname.includes("/single-payment");
  const isBatchPayment = pathname.includes("/batch-payment");
  const isLocalTransfer =
    pathname.includes("/payment-instruction") &&
    pathname.includes("local-transfer");
  const isCrossBorder = isSinglePayment || isBatchPayment;

  return {
    isTopUpIndoWallet,
    isCrossBorder,
    isSinglePayment,
    isBatchPayment,
    isLocalTransfer,
  };
};
