import { customDateFormatter, formatCurrency } from "../../../components/tools";

export const incomingPaymentFormatter = (object) => {
  const { currency, amount, created_at, reference_number, status, debit } = object || {};
  const va_number_id = object?.details?.originator?.va_number_id;

  const operator = debit ? `-` : `+`
  const leftTop = `Incoming Payment - ${va_number_id}`;
  const leftBottom = `• ${reference_number} • ${customDateFormatter(
    created_at
  )} `;
  const leftStatus = status;
  const rightTop = `${operator}${currency} ${formatCurrency(amount)}`;
  return { ...object, leftTop, leftStatus, leftBottom, rightTop };
};
