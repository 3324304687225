import { useState } from "react";
import { useForm } from "react-hook-form";
import { CustomButton, OutlineButton } from "../../../components/Buttons";
import {
  MainCardHeaderMolecule,
  MiddleWrapper,
  RightCardModalAtomic,
} from "../../../components/CardModal";
import { CustomTextArea } from "../../../components/inputs";
import { GothamMedium } from "../../../components/Text";
import { ToasterHook } from "../../../contexts/ToasterContext";

export const PayrollRejectModal = ({
  text,
  isOpen,
  toggle,
  loading = false,
  onSubmit: onSubmitProps,
}) => {
  // const resolver = schemaGenerator({ stringArr: ["reason"] });
  const { errorToaster } = ToasterHook();
  const useFormObj = useForm();
  const { handleSubmit, watch } = useFormObj;
  const [middleHeight, setMiddleHeight] = useState(0);
  const placeholder = `Reasons for ${text}`;
  const isCancel = text === "cancellation";
  const type = isCancel ? "cancel" : "reject";

  const watchReason = watch("reason");
  const isReasonEmpty = !Boolean(watchReason);

  const onSubmit = (values) => {
    if (!Boolean(watchReason)) return;
    const { reason: reasonRaw } = values;
    const dotsRemoved = String(reasonRaw).split(".").join("");
    const firstRegex = dotsRemoved.replace(/\n|\r/g, " ");
    const reason = firstRegex.replace(/  +/g, " ").trim();
    const isEmpty = !reason.split(" ").join("");
    if (!reason || isEmpty)
      return errorToaster("Error!", `${placeholder} cannot be empty`);
    onSubmitProps({ reason }, type);
  };
  return (
    <RightCardModalAtomic
      isOpen={isOpen}
      middleHeight={middleHeight}
      paddingTop={12}
      header={
        <MainCardHeaderMolecule
          isClose={false}
          toggle={toggle}
          customComponent={
            <div style={{ width: "100%" }}>
              <GothamMedium
                style={{ color: "#fcfcfc", marginBottom: 16 }}
                className="font24"
              >
                Give a reason for {text}
              </GothamMedium>
              <CustomTextArea
                name="reason"
                placeholder={placeholder}
                useFormObj={useFormObj}
                style={{
                  width: "100%",
                  height: 84,
                  marginBottom: 16,
                }}
              />
            </div>
          }
        />
      }
      middle={
        <MiddleWrapper setHeight={setMiddleHeight} height={middleHeight}>
          <CustomButton
            className="w-100"
            onClick={handleSubmit(onSubmit)}
            isLoading={loading}
            style={{ cursor: isReasonEmpty && "no-drop" }}
          >
            Submit
          </CustomButton>
        </MiddleWrapper>
      }
      body={
        <OutlineButton className="w-100" onClick={toggle}>
          Cancel
        </OutlineButton>
      }
    />
  );
};
