import React, { useState } from "react";
import checkIconWhite from "../../../assets/images/check-icon-white.svg";
import xIconPink from "../../../assets/images/x-icon-pink.svg";
import { CustomButton, OutlineButton } from "../../../components/Buttons";
import { TopUpModal, useModalHook } from "../../../components/Modals";
import { useGetAuth } from "../../../contexts/AuthContext";
import { ToasterHook } from "../../../contexts/ToasterContext";
import { fetch, useMutation } from "../../../tools/api";
import { PayrollPinModal } from "../payroll/pinModal";
import { PayrollRejectModal } from "../payroll/rejectModal";
import { useCrossBorderSingleWorkflow } from "./hooks/workflow";
import { ReleasePaymentModal } from "./ReleasePaymentModal";
import { useRouter } from "next/router";
import { eventsTracker } from "../../../universalFunctions/events";
import { useCrossBorderSingleWorkflowApproverLogics } from "./logics";
import SuspendModal from "../../../components/SubscriptionComponents/SuspendModal/SuspendModal";
import useSubscriptionUsage from "../../../tools/hooks/useSubscriptionUsage";
import { PaymentMethodModal } from "./paymentMethodModal/parent";
import { booleanState } from "../../../components/Status";

export const ApproveRejectButtons = ({
  loading,
  children,
  onClickLeft,
  onClickRight,
  buttonRightText = "Approve",
  style,
}) => {
  const defaultStyle = {
    height: 32,
    fontSize: 14,
  };
  return (
    <div
      className="d-flex justify-content-between w-100"
      style={{ gap: 12, marginTop: 16, ...style }}
    >
      <OutlineButton
        style={{
          width: "100%",
          backgroundColor: "white",
          ...defaultStyle,
        }}
        leftIcon={<img src={xIconPink} className="mr-1" />}
        onClick={() => onClickLeft && onClickLeft()}
        isLoading={loading}
      >
        Reject
      </OutlineButton>
      <CustomButton
        style={{ width: "100%", ...defaultStyle }}
        leftIcon={<img src={checkIconWhite} className="mr-1" />}
        onClick={() => onClickRight && onClickRight()}
        isLoading={loading}
      >
        {buttonRightText}
      </CustomButton>
      {children}
    </div>
  );
};

export const crossBorderSingleWorkflowComponents = ({
  transaction,
  cancelUrlFunc = (id) =>
    `/business_transactions/${id}/cancel_single_transaction`,
  modelName = "transaction",
  bankTransfer = false,
}) => {
  const {
    id,
    approval_tasks, // DONT REMOVE => used in useCrossBorderSingleWorkflowApproverLogics
    workflow_approval_tasks, // DONT REMOVE => used in useCrossBorderSingleWorkflowApproverLogics
    rejection_note,
    state,
    transaction_type, // need attention
    payroll_workflow_summary, // payroll
    reimbursement_workflow_summary, // reimbursement
    invoice_file: isInvoice, // invoice
    payment_method, // crossborder single
  } = transaction || {};

  const isReimbursement = reimbursement_workflow_summary;

  const { canApprove, taskId } =
    useCrossBorderSingleWorkflowApproverLogics(transaction);

  const [rejection, setRejection] = useState(false);

  const {
    isDrafter,
    isWorkflow,
    isOnProgress,
    isCompleted,
    isRefunded,
    approverLineArray,
  } = useCrossBorderSingleWorkflow(transaction);

  const { push } = useRouter();
  const isCanceled = state === "canceled" || state === "cancelled";

  const isLastApprover =
    approverLineArray[approverLineArray.length - 1]?.isActive;

  const toggleRejection = () => setRejection(false);

  const { successToaster } = ToasterHook();

  const isSingleCrossBorder = transaction_type == "Single";

  const transactionTypeDecider = () => {
    const isLocalTransfer = transaction_type == "transfer";
    const isPayroll = payroll_workflow_summary;
    const isReimbursement = reimbursement_workflow_summary;

    if (isReimbursement) return "reimbursement";
    if (isSingleCrossBorder) return "single_cross_border";
    if (isLocalTransfer) return "local_batch";
    if (isPayroll) return "payroll";
    return "invoice";
  };

  const transactionType = transactionTypeDecider();
  const isPayroll = transactionType === "payroll";

  const afterSuccess = async (msg) => {
    if (isPayroll) {
      await push(`/payroll/create/${id}?state=success`);
    } else {
      await push(
        `/history/success-workflow/?transaction_type=${transactionType}&ids=${id}`
      );
    }
    toggleRejection();
    successToaster({ msg });
  };

  const { loading: loadingCancel, mutation: cancel } = useMutation({
    url: cancelUrlFunc(id),
    afterSuccess: () => afterSuccess("Transaction successfully cancelled"),
  });

  const { isSuspended, loadingSubscriptionUsage } = useSubscriptionUsage();

  const { isOpen, toggle } = useModalHook();
  const {
    isOpen: isOpenReleasePaymentModal,
    toggle: toggleReleasePaymentModal,
  } = useModalHook();
  const { isOpen: isOpenPinModal, toggle: togglePinModal } = useModalHook();
  const { isOpen: isOpenTopUpModal, toggle: toggleTopUpModal } = useModalHook();
  const { isOpen: isOpenSuspendModal, toggle: toggleSuspendModal } =
    useModalHook();
  const { isOpen: isOpenPaymentMethod, toggle: togglePaymentMethod } =
    useModalHook();

  const isBankTransfer = payment_method == "bank_transfer" || bankTransfer;
  const isUndecidedPayment = payment_method == "undecided";

  const { loading: loadingApprove, mutation: approve } = useMutation({
    url: `/approval_tasks/${taskId}/approve`,
    afterSuccess: (res) => {
      afterSuccess("Transaction successfully approved");
      toggle();
      eventsTracker(`${modelName}_approve`, res);
    },
  });

  const { loading: loadingReject, mutation: reject } = useMutation({
    url: `/approval_tasks/${taskId}/reject`,
    afterSuccess: (res) => {
      afterSuccess("Transaction successfully rejected");
      eventsTracker(`${modelName}_reject`, res);
    },
  });

  const onSubmitWorkflow = (values, type) => {
    const { reason: rejection_note, pin } = values || {};

    const isCancel = type === "cancel";
    const isReject = type === "reject";
    const isApprove = type == "approve";

    const value = { rejection_note };

    if (isApprove) return approve({ pin });

    if (isCancel) {
      const state = isInvoice || isReimbursement ? "cancel" : "cancelled";
      return cancel({
        rejection_note,
        state,
      });
    }

    if (isReject) return reject(value);
  };

  const loadingButtons = loadingApprove || loadingReject || loadingCancel;

  const rejectModal = () => (
    <PayrollRejectModal
      text={rejection}
      loading={loadingButtons}
      isOpen={Boolean(rejection)}
      toggle={toggleRejection}
      onSubmit={onSubmitWorkflow}
    />
  );

  const buttons = () => {
    if (isCompleted) return null;
    if (isOnProgress && isInvoice) return null;
    if (isOnProgress && isReimbursement) return null;
    if (!isWorkflow) return null;
    if (rejection_note) return null;
    if (isCanceled) return null;
    if (isRefunded) return null;

    const defaultStyle = {
      height: 32,
      fontSize: 14,
    };

    const handleClick = (purpose = "approve") => {
      const isApproving = purpose === "approve";
      const isRejecting = purpose === "reject";
      const isCanceling = purpose === "cancel";

      if (isApproving) {
        if (isSuspended) return toggleSuspendModal();

        toggle();
        if (isSingleCrossBorder && isUndecidedPayment)
          return togglePaymentMethod();
        if (isBankTransfer) return togglePinModal();

        toggleReleasePaymentModal();
      }

      if (isRejecting) {
        if (isSuspended) return toggleSuspendModal();

        setRejection("rejection");
      }

      if (isCanceling) {
        if (isSuspended) return toggleSuspendModal();

        setRejection("cancellation");
      }
    };

    if (canApprove) {
      const buttonRightText = isSingleCrossBorder ? "Approve & Pay" : "Pay";
      if (isLastApprover)
        return (
          <ApproveRejectButtons
            buttonRightText={buttonRightText}
            loading={loadingButtons || loadingSubscriptionUsage}
            onClickLeft={() => handleClick("reject")}
            onClickRight={() => handleClick("approve")} // disini bikin kondisi handleclick based on crossborder single trx
          >
            <ReleasePaymentModal
              loading={loadingButtons}
              isOpen={isOpenReleasePaymentModal}
              toggle={() => {
                toggleReleasePaymentModal();
                toggle();
              }}
              onClick={() => {
                toggleReleasePaymentModal();
                togglePinModal();
              }}
              onClickTopUp={toggleTopUpModal}
              data={transaction}
            />
            <PayrollPinModal
              loading={loadingButtons}
              isOpen={isOpenPinModal}
              toggle={() => {
                if (isBankTransfer) {
                  toggle();
                  togglePinModal();
                } else {
                  togglePinModal();
                  toggleReleasePaymentModal();
                }
              }}
              onSubmit={({ pin }) => onSubmitWorkflow({ pin }, "approve")}
              title="Input your PIN to approve"
              buttonText="Submit"
            />
            <TopUpModal
              modal={isOpenTopUpModal}
              toggle={toggleTopUpModal}
              back
              paid
            />
            <SuspendModal
              state="suspended"
              isOpen={isOpenSuspendModal}
              toggle={toggleSuspendModal}
            />
            <PaymentMethodModal
              isOpen={isOpenPaymentMethod}
              data={transaction}
              toggle={togglePaymentMethod}
            />
          </ApproveRejectButtons>
        );

      return (
        <ApproveRejectButtons
          onClickLeft={() => handleClick("reject")}
          onClickRight={() => handleClick("approve")}
          loading={loadingButtons || loadingSubscriptionUsage}
          buttonRightText="Approve"
        >
          <PayrollPinModal
            loading={loadingButtons}
            isOpen={isOpen}
            onSubmit={({ pin }) => onSubmitWorkflow({ pin }, "approve")}
            toggle={toggle}
            title="Input your PIN to approve"
            buttonText="Submit"
            isEmployeeAllowed
          />
          <SuspendModal
            state="suspended"
            isOpen={isOpenSuspendModal}
            toggle={toggleSuspendModal}
          />
        </ApproveRejectButtons>
      );
    }

    if (isDrafter) {
      // if (isPayroll) return null;
      return (
        <div
          className="d-flex justify-content-between w-100"
          style={{ gap: 12, marginTop: 16 }}
        >
          <OutlineButton
            style={{
              width: "100%",
              backgroundColor: "white",
              ...defaultStyle,
            }}
            leftIcon={<img src={xIconPink} className="mr-1" />}
            onClick={() => handleClick("cancel")}
            isLoading={loadingSubscriptionUsage}
          >
            Cancel Transaction
          </OutlineButton>
          <SuspendModal
            state="suspended"
            isOpen={isOpenSuspendModal}
            toggle={toggleSuspendModal}
          />
        </div>
      );
    }

    return null;
  };

  return {
    rejectModal,
    buttons,
    isRejection: Boolean(rejection),
    isWorkflow,
    openPinModal: isOpen,
  };
};
