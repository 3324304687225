import { useModalHook } from "../../components/Modals";
import useSubscriptionUsage from "../../tools/hooks/useSubscriptionUsage";

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const billCycleDecider = (date) => {
  if (!date) return {};

  const rearrangedDate = date?.split("-").reverse();
  const statementDayDate = rearrangedDate[0];
  const statementYearDate = rearrangedDate[2];
  const monthIndex = rearrangedDate && Number(rearrangedDate[1]) - 1;

  const startDayDate = 26;
  const endDayDate = statementDayDate;
  const startingMonth = months[monthIndex - 1] || "December";
  const endingMonth = months[monthIndex];
  const startingYear =
    endingMonth == "January" ? +statementYearDate - 1 : statementYearDate;
  const endingYear = statementYearDate;

  return {
    startingDate: `${startingMonth} ${startDayDate}th`,
    endingDate: `${endingMonth} ${endDayDate}th`,
    startingMonth: startingMonth,
    endingMonth: endingMonth,
    fullStartingDate: `${startDayDate} ${startingMonth} ${startingYear}`,
    fullEndingDate: `${endDayDate} ${endingMonth} ${endingYear}`,
  };
};

export const currentCycleDecider = () => {
  const fullDate = new Date();
  const currentMonthIndex = fullDate.getMonth();
  const date = fullDate.getDate();
  const year = fullDate.getFullYear();

  let startingMonthIndex;
  let endingMonthIndex;

  if (date < 26) {
    endingMonthIndex = currentMonthIndex;
    startingMonthIndex = currentMonthIndex - 1;
  } else {
    endingMonthIndex = currentMonthIndex + 1;
    startingMonthIndex = currentMonthIndex;
  }

  return {
    startingMonth: months[startingMonthIndex] || "December",
    endingMonth: months[endingMonthIndex] || "January",
    startingYear: year,
    endingYear:
      months[startingMonthIndex] === "December" && date > 25 ? year + 1 : year,
  };
};

export const useSubscriptionOrSuspendModalLogics = () => {
  const {
    isOpen: isOpenSuspendModal,
    open: openSuspendModal,
    toggle: toggleSuspendModal,
  } = useModalHook();

  const {
    isOpen: isOpenUpgradeModal,
    open: openUpgradeModal,
    toggle: toggleUpgradeModal,
  } = useModalHook();

  const {
    isGrowthPlan,
    reachedMaxCards,
    isSuspended,
    loadingSubscriptionUsage,
  } = useSubscriptionUsage();

  const suspendProps = {
    isOpen: isOpenSuspendModal,
    state: "suspended_card",
    toggle: toggleSuspendModal,
  };

  const subsProps = {
    isOpen: isOpenUpgradeModal,
    purpose: "see_plan",
    target: "card_general",
    toggle: toggleUpgradeModal,
  };

  const checker = () => {
    if (isSuspended) {
      openSuspendModal();
      return true;
    }
    if (reachedMaxCards && !isGrowthPlan) {
      openUpgradeModal();
      return true;
    }
  };

  return { suspendProps, subsProps, loadingSubscriptionUsage, checker };
};
