import { get } from "lodash";
import { useRouter } from "next/router";
import noCase from "no-case";
import React, { useEffect, useState } from "react";
import { FaSpinner } from "react-icons/fa";
import titleCase from "title-case";
import { colors } from "../assets/colors";
import deleteIcon from "../assets/images/blue-icon-delete.svg";
import trashIcon from "../assets/images/trash.svg";
import xIcon from "../assets/images/x_calculator.svg";
import { apiBusiness } from "../tools/api";
import { eventsTracker } from "../universalFunctions/events";
import { ConfirmationModal, DialogButton } from "./Buttons";
import {
  MainCardHeaderMolecule,
  MiddleWrapper,
  RightCardModalAtomic,
} from "./CardModal";
import { GetFlag } from "./inputs";
import LoadingSpinner from "./Loading";
import { RightModal } from "./Modals";
import { GothamMedium, GothamRegular } from "./Text";

export const universeFunc = (string, item) =>
  get(item, `user_detail.universe_data.${string}`, "");

const RecipientDetailsModal = ({ isOpen, toggle, data: getData }) => {
  const customUniverseFunc = (string) => universeFunc(string, getData);
  const data = [
    {
      title: "Business Information",
      data: [
        { title: "Email", msg: customUniverseFunc("recipient_email") },
        { title: "Country", msg: titleCase(get(getData, "country.name", "")) },
      ],
    },
    {
      title: "Bank Information",
      data: [
        { title: "Bank Name", msg: customUniverseFunc("bank_name") },
        {
          title: "Bank Account Number",
          msg: customUniverseFunc("bank_account_number"),
        },
      ],
    },
  ];
  return (
    <RightModal
      modal={isOpen}
      toggle={() => toggle()}
      divProps={{
        className: "p-4",
      }}
    >
      <div className="d-flex justify-content-between align-items-center">
        <GothamMedium
          style={{ fontSize: 24, color: colors.grey72 }}
          className="my-0"
        >
          Recipient Details
        </GothamMedium>
        <img className="hover" src={xIcon} onClick={() => toggle()} />
      </div>
      <RecipientDetailsHeader data={getData} closeModal={toggle} />
      {data.map(({ title, data }, index) => (
        <div className="mt-5" key={index}>
          <GothamRegular style={{ fontSize: 20, color: colors.grey72 }}>
            {title}
          </GothamRegular>
          {data.map(({ title, msg }, childrenindex) => (
            <RecipientDetailsContent
              title={title}
              msg={msg}
              key={`${childrenindex}${index}`}
            />
          ))}
        </div>
      ))}
    </RightModal>
  );
};

export const RecipientDetailsContent = ({ title, msg }) => {
  const isMsgEmpty = msg === "" || msg === undefined;
  return (
    <>
      {!isMsgEmpty && (
        <div className="mt-3">
          <GothamRegular style={{ fontSize: 14, color: colors.grey72 }}>
            {title}
          </GothamRegular>
          <GothamMedium style={{ fontSize: 14, color: colors.grey25 }}>
            {msg}
          </GothamMedium>
        </div>
      )}
    </>
  );
};

export const RecipientDetailsHeader = ({
  data,
  closeModal: afterDelete,
  loading: mainLoading,
}) => {
  const { recipient_type, country, id, name } = data || {};
  const iso = noCase(get(country, "iso", ""));
  const curr = get(country, "currency", "");
  const [loading, setLoading] = useState(false);
  const handleDelete = async (id) => {
    try {
      setLoading(true);
      await apiBusiness.delete(`/user_recipients/${id}`);
      afterDelete(id);
    } catch (err) {
      console.log("err:", err);
    } finally {
      setLoading(false);
    }
  };
  if (mainLoading) {
    return <LoadingSpinner style={{ height: 75 }} />;
  }
  return (
    <div className="d-flex justify-content-between align-items-center mt-5">
      <div className="d-flex">
        <GetFlag iso={iso} width={32} height={32} />
        <div>
          <GothamMedium
            style={{ fontSize: 20, color: colors.grey25 }}
            className="mb-1"
          >
            {name}
          </GothamMedium>
          <GothamRegular
            style={{ fontSize: 14, color: colors.grey72 }}
            className="my-0"
          >
            {`${universeFunc("receiving_country", data) || curr} ${titleCase(
              recipient_type
            )} Account`}
          </GothamRegular>
        </div>
      </div>
      {loading ? (
        <FaSpinner className="icon-spin " size={20} />
      ) : (
        <DialogButton
          className="fake-button"
          woClassName
          onClick={() => {
            handleDelete(id);
          }}
        >
          <img src={trashIcon} className="darkhover" />
        </DialogButton>
      )}
    </div>
  );
};
export default RecipientDetailsModal;

export const NewRecipientDetailsModal = ({ isOpen, data, handleClose }) => {
  const [middleHeight, setMiddleHeight] = useState(0);
  const [modalDelete, setModalDelete] = useState(false);
  const [loading, setLoading] = useState(false);
  const { pathname } = useRouter();
  const isCrossBorder = pathname.includes("cross-border/single");

  const {
    recipient_type,
    name,
    iso,
    country,
    user_detail,
    id: idRecipient,
  } = data || {};

  const {
    bank_account_number,
    bank_name,
    recipient_email,
    branch_number,
    iban,
    swift_bic_code,
    aba_routing_number,
    bank_account_name,
    bsb_number,
    routing_code,
    ifs_code,
  } = user_detail?.universe_data || {};
  const { name: country_name, currency } = country || {};

  const textAccount = `${currency} ${titleCase(recipient_type)} Account`;

  const dataDetails = [
    {
      title: "Business Information",
      children: [
        {
          title: "Email",
          value: recipient_email || "-",
        },
        {
          title: "Country",
          value: country_name || "-",
        },
      ],
    },
    {
      title: "Bank Information",
      children: [
        {
          title: bank_name && "Bank Name",
          value: bank_name,
        },
        {
          title: bank_account_name && "Bank Account Name",
          value: bank_account_name,
        },
        {
          title: bsb_number && "BSB Number",
          value: bsb_number,
        },
        {
          title: bank_account_number && "Bank Account Number",
          value: bank_account_number,
        },
        {
          title: branch_number && "Branch Number",
          value: branch_number,
        },
        {
          title: iban && "IBAN",
          value: iban,
        },
        {
          title: swift_bic_code && "Swift BIC Code",
          value: swift_bic_code,
        },
        {
          title: routing_code && "Routing Code",
          value: routing_code,
        },
        {
          title: aba_routing_number && "ABA Routing Number",
          value: aba_routing_number,
        },
        {
          title: ifs_code && "IFS Code",
          value: ifs_code,
        },
      ],
    },
  ];

  const dataIcon = [
    // {
    //   title: `Edit`,
    //   value: "edit",
    //   icon: pencilIcon,
    // },
    {
      title: `Delete`,
      value: "delete",
      icon: deleteIcon,
    },
  ];

  const handleDelete = async () => {
    try {
      setLoading(true);
      await apiBusiness.delete(`/user_recipients/${idRecipient}`);
      eventsTracker(
        isCrossBorder
          ? "complete_delete_recipient_in_transaction"
          : "delete_recipient",
        {
          idRecipient,
        }
      );
      handleClose(idRecipient);
    } catch (err) {
      console.log("err:", err);
    } finally {
      setLoading(false);
    }
  };

  const handleClickMenu = ({ value }) => {
    const isEditRecipient = value == "edit";
    const isDeleteRecipient = value == "delete";

    if (isEditRecipient) return alert("edit");
    if (isDeleteRecipient) setModalDelete(true);
  };

  useEffect(() => {
    if (!isOpen) return;
    eventsTracker("open_recipient_details_modal", { id: idRecipient });
  }, [isOpen]);

  return (
    <div>
      <RightCardModalAtomic
        isOpen={isOpen}
        header={
          <MainCardHeaderMolecule
            toggle={handleClose}
            middleHeight={middleHeight}
            customComponent={
              <div style={{ position: "absolute", left: 20 }}>
                <GothamMedium style={{ fontSize: 24, color: "white" }}>
                  Recipient Details
                </GothamMedium>
              </div>
            }
          />
        }
        middle={
          <MiddleWrapper setHeight={setMiddleHeight} height={middleHeight}>
            <div
              style={{
                padding: 14,
                backgroundColor: "white",
                boxShadow: "0px 8px 20px rgba(88, 88, 88, 0.1)",
                borderRadius: 8,
              }}
            >
              <div className="d-flex justify-content-between">
                <div className="d-flex align-items-center">
                  <GetFlag
                    iso={iso}
                    style={{
                      marginLeft: 0,
                      filter: "brightness(97%)",
                      width: 32,
                      height: 32,
                    }}
                  />
                  <div>
                    <GothamMedium
                      style={{
                        marginBottom: 0,
                        lineHeight: "22px",
                        maxWidth: 240,
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                      }}
                    >
                      {name}
                    </GothamMedium>
                    <GothamRegular
                      style={{
                        color: colors.grey72,
                        fontSize: 12,
                        marginBottom: 0,
                        lineHeight: "18px",
                      }}
                    >
                      {textAccount}
                    </GothamRegular>
                  </div>
                </div>
                <div className="d-flex">
                  <ActionDivSimple data={dataIcon} onClick={handleClickMenu} />
                </div>
              </div>
            </div>
          </MiddleWrapper>
        }
        body={
          <div style={{ marginTop: 48 }}>
            {dataDetails.map((item, index) => {
              const { children, title } = item;
              return (
                <div
                  key={index}
                  style={{
                    border: "1px solid #EAEAEA",
                    borderRadius: 12,
                    padding: 12,
                    marginBottom: 24,
                    overflow: "hidden",
                  }}
                >
                  <GothamMedium
                    style={{
                      color: colors.grey72,
                      marginBottom: 12,
                      lineHeight: "22px",
                    }}
                  >
                    {title}
                  </GothamMedium>
                  {children.map(({ title, value }, index) => (
                    <div key={index} style={{ maxWidth: 320 }}>
                      <GothamRegular
                        style={{
                          fontSize: 10,
                          color: colors.grey72,
                          marginBottom: 0,
                          lineHeight: "16px",
                        }}
                      >
                        {title}
                      </GothamRegular>
                      <GothamMedium
                        style={{
                          marginBottom: 12,
                          lineHeight: "22px",
                        }}
                      >
                        {value}
                      </GothamMedium>
                    </div>
                  ))}
                </div>
              );
            })}
          </div>
        }
      />
      <ConfirmationModal
        toggle={() => setModalDelete(false)}
        modal={modalDelete}
        onClick={handleDelete}
        text={`Are you sure you want to delete this recipient`}
      />
    </div>
  );
};

export const ActionDivSimple = ({ data, onClick }) => {
  const [onHover, setOnHover] = useState(false);
  return (
    <>
      {data.map((item, index) => {
        const { title, value, icon } = item;
        const hovered = onHover === index;
        const filterHover = hovered ? `brightness(93%)` : "none";
        return (
          <div
            key={index}
            onMouseEnter={() => setOnHover(index)}
            onMouseLeave={() => setOnHover(false)}
            onClick={() => {
              if (onClick) return onClick(item);
              if (href) return push({ pathname: href, query });
            }}
            className="text-center"
            style={{ cursor: "pointer", marginLeft: 20 }}
          >
            <img
              src={icon}
              style={{ width: 24, marginBottom: 4, filter: filterHover }}
            />
            <GothamMedium
              style={{
                fontSize: 10,
                color: colors.grey72,
                marginBottom: 0,
              }}
            >
              {title}
            </GothamMedium>
          </div>
        );
      })}
    </>
  );
};
