import React from "react";
import styled from "styled-components";
import bankIconDecider from "../constants/bankIconDecider";
import { TypeFezNeueMedium } from "./Text";

const BankCard = ({ bank, bankName, onClick }) => {
  return (
    <BankDiv onClick={() => onClick({ type: "select_bank", value: bank })}>
      <img src={bankIconDecider(bankName)} />
      <TypeFezNeueMedium style={{ fontSize: 16, marginLeft: 16 }}>
        {bankName}
      </TypeFezNeueMedium>
    </BankDiv>
  );
};

const BankDiv = styled.div`
  height: 56px;
  margin-top: 12px;
  padding: 12px 8px;
  border: 1px solid #e6e6e8;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  &:hover {
    background-color: #e6e6e8;
  }
`;

export default BankCard;
