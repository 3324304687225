import { titleCase } from "change-case";
import React from "react";
import { QueryDatePicker, QuerySelection } from "../inputs";

export const FilterFieldsGenerator = ({ data, isTabOrPhone }) =>
  data.map((item, index) => {
    const {
      options,
      label,
      name,
      loading,
      rangedDate,
      woClearButton,
      width = 280,
    } = item;
    const isSelection = Boolean(options);
    const isMaxedOut = index > 2;

    if (isMaxedOut && !isTabOrPhone) return null;

    const getLabel = label || titleCase(name);

    if (rangedDate)
      return (
        <QueryDatePicker
          woClearButton={woClearButton || isTabOrPhone}
          key={index}
          showClear
          isRangeDate
          label="Date"
          name="date"
          columnProps={{
            sm: 12,
            style: { maxWidth: width, minWidth: width },
          }}
        />
      );

    if (isSelection) {
      const isLong = String(getLabel).length > 15;
      const defaultSelectionProps = {
        isLoading: loading,
        key: index,
        label: getLabel,
      };

      if (isTabOrPhone)
        return (
          <QuerySelection {...item} {...defaultSelectionProps} woClearButton />
        );

      return (
        <div
          key={index}
          style={{
            width: isLong ? 150 : 138,
          }}
          className="mx-1"
        >
          <QuerySelection {...item} {...defaultSelectionProps} />
        </div>
      );
    }

    return null;
  });
