import React, { memo, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { GothamMedium, GothamRegular } from "./Text";
import left from "../assets/images/another-arrow-left.svg";
import right from "../assets/images/another-arrow-right.svg";
import download from "../assets/images/another-download.svg";
import { downloadApi } from "../tools/api";
import { CenteredModal } from "../modals/centeredComponents";
import { useModalHook } from "./Modals";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const Header = ({ fileName, url, noZoom }) => {
  const { isOpen, toggle } = useModalHook();
  return (
    <div
      style={{
        backgroundColor: "#333639",
        borderRadius: 12,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        padding: 13,
        marginBottom: 20,
      }}
      className="w-100 d-flex"
    >
      <GothamRegular
        style={{
          color: "white",
          width: noZoom ? "50%" : `${100 / 3}%`,
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          overflow: "hidden",
        }}
      >
        {fileName}
      </GothamRegular>
      {!noZoom && (
        <GothamMedium
          style={{ width: `${100 / 3}%`, textAlign: "center", color: "white" }}
          className="hover"
          onClick={toggle}
        >
          Zoom
        </GothamMedium>
      )}
      <CenteredModal
        isOpen={isOpen}
        toggle={toggle}
        style={{ width: 960 }}
        contentClassName="modal-transfez-center"
        scrollable
      >
        <PDFOrImageViewer url={url} noZoom />
      </CenteredModal>
      <div
        style={{
          width: noZoom ? "50%" : `${100 / 3}%`,
        }}
        className="d-flex justify-content-end"
      >
        <img
          src={download}
          className="darkhover"
          onClick={() => {
            downloadApi({
              url,
              fileName,
              isAxios: true,
            });
          }}
        />
      </div>
    </div>
  );
};

export const PDFOrImageViewer = memo(({ url, noZoom }) => {
  const fileName = String(url).split("/").pop();
  const fileNameformatted = fileName.split("%20").join(" ");
  const isPDF = String(fileName).split(".").pop() == "pdf";

  const [width, setWidth] = useState(0);
  const [numPages, setNumPages] = useState(0);

  const [page, setPage] = useState(1);

  const documentWidth = width - 58 * 2;
  const documentHeight = isPDF ? 1.4142 * documentWidth : 0;

  const Container = ({ children }) => (
    <div
      className="w-100 d-flex align-items-center"
      style={{
        flexDirection: "column",
        backgroundColor: "#f4f4f4",
        minHeight: documentHeight,
        borderRadius: 12,
      }}
      ref={(e) => {
        if (!e) return;
        if (width) return;
        setWidth(e.clientWidth);
      }}
    >
      {children}
    </div>
  );

  if (isPDF)
    return (
      <Container>
        <Header
          fileName={fileNameformatted || fileName}
          url={url}
          noZoom={noZoom}
        />
        <Document
          loading={false}
          file={{
            url,
          }}
          onLoadSuccess={({ numPages: numPagesProps }) => {
            if (numPages) return;
            setNumPages(numPagesProps);
          }}
        >
          <Page
            scale={1}
            pageNumber={page}
            loading={false}
            width={documentWidth}
          />
        </Document>
        <div className="d-flex" style={{ marginTop: 22, marginBottom: 20 }}>
          <img
            src={left}
            className="darkhover"
            onClick={() =>
              setPage((prev) => {
                if (prev <= 1) return 1;
                return prev - 1;
              })
            }
          />
          <GothamRegular style={{ marginLeft: 20, marginRight: 20 }}>
            Page {page} / {numPages}
          </GothamRegular>
          <img
            src={right}
            className="darkhover"
            onClick={() =>
              setPage((prev) => {
                if (prev >= numPages) return numPages;
                return prev + 1;
              })
            }
          />
        </div>
      </Container>
    );

  return (
    <Container>
      <Header
        fileName={fileNameformatted || fileName}
        url={url}
        noZoom={noZoom}
      />
      <img
        src={url}
        style={{
          width: "auto",
          height: "auto",
          maxWidth: "87%",
          maxHeight: "87%",
          objectFit: "contain",
        }}
      />
    </Container>
  );
});
