import React from "react";
import { Row } from "reactstrap";
import plusIcon from "../assets/images/plus-icon.svg";
import { CustomButton } from "../components/Buttons";
import { TypeFezNeueMedium } from "../components/Text";
import { colors } from "../assets/colors";

export const HeaderWithButton = ({
  onClick,
  buttonText,
  title,
  withBorder,
  woButton,
  buttonSrc = plusIcon,
}) => (
  <Row
    className="mx-1 pb-4 justify-content-between align-items-center"
    style={{ borderBottom: withBorder ? "2px solid #EAEAEA" : "" }}
  >
    <TypeFezNeueMedium
      className="font24"
      style={{
        color: colors.blue10,
      }}
    >
      {title || ""}
    </TypeFezNeueMedium>
    {!woButton && (
      <CustomButton
        onClick={onClick}
        icon={
          <img
            src={buttonSrc}
            className="mr-2"
            style={{ width: 20, height: 20 }}
          />
        }
        style={{
          height: 42,
          borderRadius: 24,
          fontSize: 16,
          padding: "6px 18px",
          marginRight: -5,
        }}
      >
        {buttonText || ""}
      </CustomButton>
    )}
  </Row>
);
