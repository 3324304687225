import React, { useEffect, useState } from "react";
import {
  CustomButton,
  DropdownButton,
  OutlineButton,
} from "../../../../components/Buttons";
import {
  GothamBold,
  GothamLight,
  GothamMedium,
  GothamRegular,
  TextInlineMedium,
} from "../../../../components/Text";
import blockedIcon from "../../../../assets/images/blocked-icon.svg";
import topup from "../../../../assets/images/top-up.svg";
import refresh from "../../../../assets/images/refresh.svg";
import downIcon from "../../../../assets/images/drop-down-icon.svg";
import upIcon from "../../../../assets/images/up-icon.svg";
import freezeIcon from "../../../../assets/images/freeze-icon.svg";
import permanentBlockIcon from "../../../../assets/images/permanent-block-icon.svg";
import changePINIcon from "../../../../assets/images/change-pin-icon.svg";
import editNicknamePinkIcon from "../../../../assets/images/edit-icon-pink.svg";
import editNicknameBlueIcon from "../../../../assets/images/edit-icon-blue.svg";
import editLimitIcon from "../../../../assets/images/adjust-limit-icon.svg";
import editLimitPinkIcon from "../../../../assets/images/adjust-limit-pink-icon.svg";
import replaceCardIcon from "../../../../assets/images/replace-card-icon.svg";
import transactionsIcon from "../../../../assets/images/transactions-icon.svg";
import unblockIcon from "../../../../assets/images/unblock-icon.svg";
import deleteIcon from "../../../../assets/images/delete-icon.svg";
import {
  customDateFormatter,
  formatCurrency,
  formatCurrencyNoDecimal,
  stringCutter,
  unformatCurrency,
} from "../../../../components/tools";
import { titleCase } from "change-case";
import { StatusAccountTag } from "../../../../components/Status";
import {
  FileInput,
  CustomTextArea,
  SelectionForm,
  CurrencyField,
  CustomLimitTextField,
  CustomLabeledTextArea,
} from "../../../../components/inputs";
import uploadIcon from "../../../../assets/images/upload-pink-icon-card.svg";
import { useMutation } from "../../../../tools/api";
import { colors } from "../../../../assets/colors";
import redWarningIcon from "../../../../assets/images/red-round-warning-icon.svg";
import CardStatusTag from "../../../../components/CardStatusTag";
import { billCycleDecider } from "../../logics";
import { eventsTracker } from "../../../../universalFunctions/events";
import deleteGreyIcon from "../../../../assets/images/delete-grey-icon.svg";
import viewIcon from "../../../../assets/images/eye-grey.svg";
import { ApproveRejectButtons } from "../../../../modals/smartActivityModal/crossBorderSingleModal/components";
import { getUserRole } from "../../../../contexts/AuthContext";
import informationIcon from "../../../../assets/images/information-blue.svg";
import WarningLimit from "../../../newCardComponents/cardSetup/components/warningLimit";
import { useGetAuth } from "../../../../contexts/AuthContext";
import sunlightLogo from "../../../../assets/images/sunlight-logo.svg";
import arrowRightIcon from "../../../../assets/images/arrow-pink-right.svg";
import { cardTypeDecider } from "../../../newCardComponents/tools";
import useSunlight from "../../hooks/useSunlight";
import Skeleton from "react-loading-skeleton";
import { isNeedApprovalDecider } from "./tools";
import Warning from "../../../newCardComponents/cardSetup/components/warning";
import warningTriangleIcon from "../../../../assets/images/warning-triangle-red.svg";
import { fetch } from "../../../../tools/api";
import prepaid from "../../../../assets/images/prepaid-icon.svg";

const CardModalMiddle = ({
  setIsMinimize,
  isMinimize,
  handleClick,
  handleNavigate,
  stateObj,
  dataDetail,
  loadingMutation,
  loadingUpdate,
  selectedTransactionData,
  selectedTransactionId,
  refetchTransactionDetail,
  toggleReject,
  togglePin,
  useFormObj,
  showPreview,
  loadingRefetchTransactionDetail,
  mutationNotes,
  notesForm,
  refetch,
  onClickTopup,
}) => {
  const [isBlockDropdownShowed, setIsBlockDropdownShowed] = useState(false);
  const [isMoreDropdownShowed, setIsMoreDropdownShowed] = useState(false);
  const [showUploadField, setShowUploadField] = useState(false);
  const [limitType, setLimitType] = useState("MULTIPLE_USE");
  const [limitPeriod, setLimitPeriod] = useState("MONTHLY_AMOUNT_LIMIT");
  const [isSunlightHovered, setIsSunlightHovered] = useState(false);

  const {
    isFreezing,
    isBlocking,
    isChangingPIN,
    isEditingLimit,
    isReplacingCard,
    isAdjustingLimit,
    isEditingNickname,
    isEditingDescription,
  } = stateObj;

  const isOtherModalScreen = isChangingPIN || isEditingLimit || isReplacingCard;
  const isVirtualEditScreen = isAdjustingLimit || isEditingNickname;
  const isButtonDisabled =
    isOtherModalScreen || isVirtualEditScreen || isEditingDescription;

  const isMultiple = limitType == "MULTIPLE_USE";

  const {
    total_remaining_bill_and_spent,
    limit_value,
    limit_type,
    limit_currency,
    id,
    state,
    issuance_status,
    is_suspended,
    card_type,
    get_active_billing,
    is_adjusting_limit,
    sunlight_external_id,
    created_at,
    user: cardOwner,
    description,
    balance,
  } = dataDetail || {};

  const { name, email } = cardOwner || {};
  const nameOrEmail = name || email;

  const {
    amount: totalAmount,
    date: transactionDate,
    file_details: fileDetails,
    merchant_name: transactionMerchantName,
    transaction_amount: transactionAmount,
    transaction_type: transactionType,
    transaction_currency: transactionCurrency,
    state: transactionState,
    settlement_status: settlementStatus,
  } = selectedTransactionData || {};

  const isTopUpPrepaid = transactionType === "CREDIT_TOPUP";
  const isTopUp = transactionType === "CREDIT" || isTopUpPrepaid;
  const isWithdrawPrepaid = transactionType === "DEBIT_WITHDRAWAL";

  const isCancelled = transactionState === "cancelled";
  const isDeclined = transactionState === "declined";
  const isReturned = transactionState === "returned";

  const isInsufficientDeclined =
    settlementStatus === "Insufficient funds/over credit limit";
  const isExpiredDeclined = settlementStatus === "Expired Card";
  const isMerchantDeclined = settlementStatus === "Do not honor";

  const topUpPaymentMethodDecider = () => {
    const rawPaymentMethod = transactionMerchantName?.split("-");
    rawPaymentMethod?.shift();
    const paymentMethod = rawPaymentMethod?.join("-");
    return paymentMethod;
  };

  const topUpPaymentMethod = topUpPaymentMethodDecider();

  const declinedMessageGenerator = () => {
    if (isInsufficientDeclined) {
      return (
        <GothamRegular>
          This transaction is declined due to{" "}
          <TextInlineMedium>
            insufficient funds/over the credit limit
          </TextInlineMedium>
        </GothamRegular>
      );
    }
    if (isMerchantDeclined) {
      return (
        <GothamRegular>
          This transaction is declined due to{" "}
          <TextInlineMedium>mismatch card details</TextInlineMedium>
        </GothamRegular>
      );
    }
    if (isExpiredDeclined) {
      return (
        <GothamRegular>
          This transaction is declined due to{" "}
          <TextInlineMedium>expired card</TextInlineMedium>
        </GothamRegular>
      );
    }
  };

  const declinedMessage = declinedMessageGenerator();

  const { isPhysical, isVirtual, isPrepaid } = cardTypeDecider(card_type);

  const isTransactionIDR = transactionCurrency === "IDR";
  const isTransactionAmountLarger = +transactionAmount > +totalAmount;
  const exchangeRate = isTransactionAmountLarger
    ? transactionAmount / totalAmount
    : totalAmount / transactionAmount;

  const rightCurrency = isTransactionAmountLarger ? transactionCurrency : "IDR";
  const leftCurrency = isTransactionAmountLarger ? "IDR" : transactionCurrency;

  const { statement_date, total_bill } = get_active_billing || {};
  const { isEmployee, isBookKeeper } = getUserRole();

  const { value: isRequestAdjustingLimit, amount: requestedAmount } =
    is_adjusting_limit || {};

  const isNeedApprovalLimit =
    isRequestAdjustingLimit && isNeedApprovalDecider(dataDetail);

  const { user } = useGetAuth();
  const { data: cardBusiness } = fetch({
    url: "/card_business_detail",
    formatter: (res) => res?.data,
  });
  const availableVirtualLimit =
    cardBusiness.detailed_data?.available_virtual_limit;

  const issuedDateFormatter = () => {
    const issuedDate = customDateFormatter(created_at, false, true, true);

    const lastDigitDay = issuedDate.substring(issuedDate.length - 1);
    let ordinal = "th";

    if (lastDigitDay == 1) ordinal = "st";
    if (lastDigitDay == 2) ordinal = "nd";
    if (lastDigitDay == 3) ordinal = "rd";

    return `${issuedDate}${ordinal}`;
  };
  const formattedIssuedDate = issuedDateFormatter();

  const { startingDate, endingDate } = statement_date
    ? billCycleDecider(statement_date)
    : {};

  const { total_spent, available_spend } = total_remaining_bill_and_spent || {};
  const remaining_bill =
    total_remaining_bill_and_spent?.current_remaining_bill < 0
      ? 0
      : total_remaining_bill_and_spent?.current_remaining_bill;

  const isBlockingCondition = isFreezing || isBlocking;
  const isActive = state === "ACTIVE";
  const isNotActivated = state === "NOT_ACTIVE" || state === "REQUEST_NEW_CARD";
  const isActivating = state === "REQUEST_ACTIVE";
  const isReqUnblock = state === "REQUEST_UNBLOCK";
  const isDelivered = issuance_status === "delivered";
  const isOnReview = issuance_status === "on_review";
  const isRejected = issuance_status === "rejected";
  const isPermanentBlocked = state === "P_BLOCK";
  const isTempBlocked = state === "TEMP_BLOCK";
  const isReqBlock =
    state === "REQUEST_TEMP_BLOCK" || state === "REQUEST_P_BLOCK";
  const isReqTempBlock = state === "REQUEST_TEMP_BLOCK";

  const isLastCycleUnpaid = Boolean(statement_date);

  const permanentBlockButton = "Permanent Block Card";
  const temporaryBlockButton = "Temporary Block Card";

  const changePINProcedures = [
    "Insert your card to ATM UOB",
    "Select OTHER TRANSACTIONS",
    "Select CHANGE PIN",
    "Insert your OLD PIN",
    "Insert your NEW PIN",
    "Insert your NEW PIN once more to confirm the change",
  ];

  const isOneTime = limit_type === "ONE_TIME_USE";
  const isDaily = limit_type === "DAILY_AMOUNT_LIMIT";
  const isMonthly = limit_type === "MONTHLY_AMOUNT_LIMIT";
  const isYearly = limit_type === "YEARLY_AMOUNT_LIMIT";
  const isLifetime = limit_type === "LIFETIME_AMOUNT_LIMIT";

  const requestedLimitTypeDecider = () => {
    if (isOneTime) return "One time use";
    if (isDaily) return "Daily";
    if (isMonthly) return "Monthly";
    if (isYearly) return "Yearly";
    if (isLifetime) return "Lifetime";
  };

  const requestedLimitType = requestedLimitTypeDecider();

  const limitTextDecider = () => {
    let period;

    if (isDaily) period = "day";
    if (isMonthly) period = "month";
    if (isYearly) period = "year";

    return (
      "Your " +
      (isOneTime ? "one time " : "") +
      "card limit is " +
      limit_currency +
      " " +
      formatCurrencyNoDecimal(limit_value) +
      " " +
      (!isLifetime && !isOneTime ? `per ${period}` : "") +
      "."
    );
  };

  const limitText = limitTextDecider();

  const isThereAttachment = Boolean(fileDetails?.length);
  const isAttachmentMaxLimit = fileDetails?.length >= 3;

  const { watch, setValue, handleSubmit } = useFormObj;

  const limitValueWatch = watch("limit_value");
  const isWarningShow =
    +unformatCurrency(limitValueWatch) > +availableVirtualLimit + +limit_value;
  const isSaveLimitDisabled =
    +unformatCurrency(limitValueWatch) <= 0 ||
    !+unformatCurrency(limitValueWatch) ||
    isWarningShow;

  const nicknameWatch = watch("nickname");
  const descriptionWatch = watch("description");

  const isTransactionSuccess = !isDeclined && !isCancelled && !isReturned;
  const isShowAttachment = isThereAttachment && !isAttachmentMaxLimit;
  const isShowFileInput =
    (!isThereAttachment || showUploadField) && !isAttachmentMaxLimit;

  const handleSubmitLimit = (values) => {
    const { limit_value: limitForm } = values;
    const newLimitValue = +unformatCurrency(limitForm);
    const limit_type = isMultiple ? limitPeriod : limitType;
    handleClick("request_adjust_limit", id, {
      limit_value: newLimitValue,
      limit_type,
    });
  };

  const handleSubmitNickname = (values) => {
    const { nickname } = values;
    handleClick("request_edit_nickname", id, { nickname });
  };

  const handleSubmitDescription = (values) => {
    const { description } = values;
    handleClick("request_edit_description", id, { description });
  };

  const { mutation: mutationUpload, loading: loadingUpload } = useMutation({
    url: `/card_transactions/${selectedTransactionId}/update`,
    afterSuccess: () => {
      setTimeout(() => refetchTransactionDetail(), 1250);
      setValue("upload_file", undefined);
      eventsTracker("card_transaction_upload_receipt", {
        id: selectedTransactionData?.id,
      });
    },
  });

  const fileWatch = watch("upload_file");

  const { mutation: mutationDelete, loading: loadingDelete } = useMutation({
    url: `/card_transactions/${selectedTransactionId}/delete`,
    afterSuccess: () => {
      setTimeout(() => refetchTransactionDetail(), 500);
    },
  });

  useEffect(async () => {
    if (fileWatch) {
      const { realFile } = fileWatch;

      const formData = new FormData();
      formData.append("files[]", realFile);
      await mutationNotes({ notes: notesForm });
      return mutationUpload(formData);
    }
  }, [fileWatch]);

  // Sunlight
  const { init: initSunlight, loading: loadingSunlight } = useSunlight({
    id,
    sunlight_external_id,
  });

  // This will show if the user selected a transaction on a card
  if (selectedTransactionData) {
    if (isTopUpPrepaid || isWithdrawPrepaid)
      return (
        <div
          style={{
            paddingLeft: "20px",
            paddingRight: "20px",
            marginTop: "40px",
            marginBottom: "40px",
          }}
        >
          <GothamMedium
            style={{
              marginBottom: "12px",
            }}
          >
            Details
          </GothamMedium>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "12px",
            }}
          >
            <GothamRegular style={{ color: "#6C6C71" }}>Date</GothamRegular>
            <GothamRegular style={{ color: "#6C6C71" }}>
              {customDateFormatter(transactionDate)}
            </GothamRegular>
          </div>
          {isTopUpPrepaid && (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "12px",
              }}
            >
              <GothamRegular style={{ color: "#6C6C71" }}>
                Payment method
              </GothamRegular>
              <GothamRegular style={{ color: "#6C6C71" }}>
                {topUpPaymentMethod}
              </GothamRegular>
            </div>
          )}
          <div
            style={{
              height: "1px",
              border: "1px solid #E6E6E8",
              marginBottom: "12px",
            }}
          />
          <GothamRegular
            style={{
              color: "#6C6C71",
              marginBottom: "12px",
            }}
          >
            {isTopUpPrepaid ? "Top up to" : "Withdraw from"}
          </GothamRegular>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "12px",
            }}
          >
            <div
              style={{
                display: "flex",
                gap: "8px",
                alignItems: "center",
              }}
            >
              <img src={prepaid} />
              <div>
                <GothamMedium
                  style={{
                    color: "#2C4A77",
                  }}
                >
                  {selectedTransactionData?.user_card?.user?.name}
                </GothamMedium>
                <GothamRegular className="font12">
                  {`**${selectedTransactionData.user_card.masked_card_number.slice(
                    15,
                    20
                  )} ${selectedTransactionData.user_card.nickname}`}
                </GothamRegular>
              </div>
            </div>
            <GothamRegular
              style={{
                color: isTopUp ? "#06AC4E" : "#333334",
              }}
            >
              {isTopUp ? "+" : "-"} IDR{" "}
              {formatCurrencyNoDecimal(selectedTransactionData?.amount)}
            </GothamRegular>
          </div>
        </div>
      );

    return (
      <div
        style={{
          paddingLeft: "20px",
          paddingRight: "20px",
          marginTop: "40px",
          marginBottom: "40px",
        }}
      >
        {/* This will show if the transaction is success */}
        {isTransactionSuccess && (
          <>
            <div
              style={{
                marginBottom: "12px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "10px",
                }}
              >
                <GothamBold style={{ color: colors.blue10 }}>
                  Receipt
                </GothamBold>
                {isShowAttachment &&
                  (showUploadField ? (
                    <GothamBold
                      style={{
                        color: colors.pink,
                        cursor: "pointer",
                        userSelect: "none",
                      }}
                      onClick={() => setShowUploadField(false)}
                    >
                      Cancel
                    </GothamBold>
                  ) : (
                    <GothamBold
                      style={{
                        color: colors.pink,
                        cursor: "pointer",
                        userSelect: "none",
                      }}
                      onClick={() => setShowUploadField(true)}
                    >
                      Add
                    </GothamBold>
                  ))}
              </div>
              {isThereAttachment &&
                fileDetails.map(({ name, id }, index) => {
                  if (loadingDelete || loadingRefetchTransactionDetail)
                    return (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginBottom: "10px",
                        }}
                        key={index}
                      >
                        <Skeleton width={200} height={18} />
                        <Skeleton width={40} height={18} />
                      </div>
                    );

                  return (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: "10px",
                      }}
                    >
                      <GothamRegular>{stringCutter(name, 40)}</GothamRegular>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "6px",
                        }}
                      >
                        <img
                          style={{ cursor: "pointer" }}
                          src={viewIcon}
                          onClick={() => {
                            eventsTracker("card_view_transaction_attachment", {
                              id: selectedTransactionId,
                            });
                            showPreview(index);
                          }}
                        />
                        <img
                          style={{ cursor: "pointer" }}
                          src={deleteGreyIcon}
                          onClick={() => {
                            mutationDelete({ file_id: id });
                          }}
                        />
                      </div>
                    </div>
                  );
                })}
              {isShowFileInput && (
                <FileInput
                  isDottedBorder
                  fileTypes={[
                    "image/jpg",
                    "image/jpeg",
                    "image/png",
                    "application/pdf",
                  ]}
                  useFormObj={useFormObj}
                  name="upload_file"
                  isDottedThumbnail={true}
                  formData
                  type="general"
                  multiple={false}
                  style={{
                    border: "2px dashed",
                    borderColor: colors.pink,
                  }}
                  fontStyle={{ color: colors.pink }}
                  uploadIcon={uploadIcon}
                  loading={loadingUpload}
                />
              )}
            </div>
            <div
              style={{
                marginBottom: "12px",
              }}
            >
              <GothamBold style={{ color: colors.blue10 }}>Notes</GothamBold>
              <CustomTextArea
                useFormObj={useFormObj}
                name="notes"
                required={false}
                placeholder="Your payment details"
                style={{
                  height: "60px",
                  fontSize: "14px",
                  resize: "vertical",
                }}
              />
            </div>
          </>
        )}

        {isCancelled && (
          <Warning
            text="This transaction has been cancelled"
            style={{
              marginBottom: "16px",
            }}
          />
        )}

        {isDeclined && (
          <Warning
            text={declinedMessage}
            icon={warningTriangleIcon}
            style={{ marginBottom: "16px", backgroundColor: colors.redFFE }}
          />
        )}

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "12px",
          }}
        >
          <GothamRegular style={{ color: "#6C6C71" }}>
            Transaction Type
          </GothamRegular>
          <GothamRegular style={{ color: "#6C6C71" }}>
            {titleCase(selectedTransactionData.transaction_type)}
          </GothamRegular>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "12px",
          }}
        >
          <GothamRegular style={{ color: "#6C6C71" }}>Payer</GothamRegular>
          <GothamMedium style={{ color: "#6C6C71" }}>
            {selectedTransactionData.user_card.name +
              " - **" +
              selectedTransactionData.user_card.masked_card_number.slice(
                15,
                20
              )}
          </GothamMedium>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "12px",
          }}
        >
          {cardOwner.team_id && (
            <>
              <GothamRegular style={{ color: "#6C6C71" }}>Team</GothamRegular>
              <StatusAccountTag
                state={cardOwner?.team?.name}
                textStyle={{ fontSize: "14px" }}
              />
            </>
          )}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "4px",
          }}
        >
          <GothamRegular style={{ color: "#6C6C71" }}>
            Total Amount
          </GothamRegular>
          <GothamRegular style={{ color: "#6C6C71" }}>
            IDR {formatCurrencyNoDecimal(totalAmount)}
          </GothamRegular>
        </div>
        {!isTransactionIDR && (
          <>
            <GothamRegular
              style={{
                color: "#909098",
                textAlign: "right",
                marginBottom: "12px",
              }}
            >
              {transactionCurrency} {formatCurrency(transactionAmount)}
            </GothamRegular>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "40px",
              }}
            >
              <GothamRegular style={{ color: "#6C6C71" }}>
                Exchange Rate
              </GothamRegular>
              <GothamRegular style={{ color: "#6C6C71" }}>
                1 {leftCurrency} = {formatCurrencyNoDecimal(exchangeRate)}{" "}
                {rightCurrency}
              </GothamRegular>
            </div>
          </>
        )}
      </div>
    );
  }
  const paddingTopDecider = () => {
    if (isBlockingCondition) return "0px";
    if (isChangingPIN) return "130px";
    return "124px";
  };

  const paddingTop = paddingTopDecider();

  const isUserCard = cardOwner?.id === user?.id;

  const isActivatingScreen =
    isNotActivated && isDelivered && !isOtherModalScreen && isPhysical;
  const isRequestActivatingScreen =
    isActivating && !isOtherModalScreen && isPhysical;
  const isNeedApprovalIssuance =
    isOnReview && isNotActivated && isNeedApprovalDecider(dataDetail);
  const isCardActivatedScreen =
    !isBlockingCondition &&
    !isNotActivated &&
    !isActivating &&
    !isRequestAdjustingLimit &&
    !isOtherModalScreen;
  const isHideBlockRequest =
    !isTempBlocked &&
    !isPermanentBlocked &&
    !isReqUnblock &&
    !isReqBlock &&
    !is_suspended;
  const isTempBlockedScreen = isTempBlocked && !isOtherModalScreen;
  const isPermanentBlockedScreen = isPermanentBlocked && !isOtherModalScreen;
  const isBlockingScreen =
    isBlockingCondition && !isNotActivated && !isOtherModalScreen;
  const isCardIndexScreen =
    !isBlockingCondition &&
    !isOtherModalScreen &&
    !isVirtualEditScreen &&
    !isEditingDescription;
  const isNotBlockedCardIndex =
    !isBlockingCondition && !isPermanentBlocked && !isOtherModalScreen;
  const isNeedApprovalLimitScreen =
    isNeedApprovalLimit && !isOtherModalScreen && !isVirtualEditScreen;
  const isActivatedCardIndex =
    !isActivating &&
    !isNotActivated &&
    !isVirtualEditScreen &&
    !isEditingDescription;
  const isCardNotActiveScreen = isActivating || isNotActivated;
  const isEditingDescriptionScreen =
    isEditingDescription && !isOtherModalScreen;
  // const isShowSunlight = isVirtual && isActive && isUserCard;

  const cardInfo = () => {
    if (!isCardIndexScreen) return null;
    if (isPrepaid) {
      return (
        <div
          className="d-flex "
          style={{
            flexDirection: "column",
            gap: 12,
            paddingTop: 16,
            paddingBottom: 16,
            borderTop: `1px solid ${colors.greye6}`,
          }}
        >
          <div className="d-flex justify-content-between">
            <GothamRegular>Card type</GothamRegular>
            <GothamRegular>Prepaid Card</GothamRegular>
          </div>
          <div className="d-flex justify-content-between">
            <GothamRegular>Cardholder</GothamRegular>
            <GothamRegular>{nameOrEmail}</GothamRegular>
          </div>
          <div className="d-flex justify-content-between">
            <GothamRegular>Status</GothamRegular>
            <CardStatusTag
              status={state}
              is_suspended={is_suspended}
              issuanceStatus={issuance_status}
            />
          </div>
          {!!description && (
            <div>
              <GothamMedium>Descriptions</GothamMedium>
              <GothamRegular>{description}</GothamRegular>
            </div>
          )}
        </div>
      );
    }

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          borderTop: isNotActivated && "1px solid #E6E6E8",
          // (isShowSunlight || isNotActivated) && "1px solid #E6E6E8",
          // paddingTop: (isShowSunlight || isNotActivated) && "16px",
          paddingTop: isNotActivated && "16px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 12,
          }}
        >
          <GothamRegular>Card type</GothamRegular>
          <GothamRegular>{titleCase(card_type)} Card</GothamRegular>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: isPermanentBlocked && !description ? "36px" : "12px",
          }}
        >
          <GothamRegular>Status</GothamRegular>
          <CardStatusTag
            status={state}
            is_suspended={is_suspended}
            issuanceStatus={issuance_status}
          />
        </div>
        {description && (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: isPermanentBlocked ? "36px" : "12px",
              alignItems: "center",
            }}
          >
            <GothamRegular>Card Description</GothamRegular>
            {/* <GothamRegular
        style={{
          textAlign: "right",
          maxWidth: "200px",
          // display: "-webkit-box",
          // WebkitBoxOrient: "vertical",
          // WebkitLineClamp: 3,
          // overflow: "hidden",
          // textOverflow: "ellipsis",
        }}
      >
        {description}
      </GothamRegular> */}

            <GothamRegular style={{ textAlign: "right" }}>
              {description}
            </GothamRegular>
          </div>
        )}
        {isReqBlock && (
          <GothamRegular
            style={{
              marginBottom: "16px",
              fontSize: "12px",
              color: colors.grey6c,
            }}
          >
            <TextInlineMedium>
              {isReqTempBlock ? " temporary block " : " permanent block "}
            </TextInlineMedium>
            has been requested
          </GothamRegular>
        )}
      </div>
    );
  };

  const spendingCycle = () => {
    if (!isActivatedCardIndex) return null;
    if (isPrepaid)
      return (
        <div className="d-flex" style={{ flexDirection: "column", gap: 12 }}>
          <GothamMedium style={{ fontSize: "16px", color: "#102C57" }}>
            Spending This Cycle
          </GothamMedium>
          <div className="d-flex justify-content-between">
            <GothamRegular>Total spent</GothamRegular>
            <GothamRegular>
              IDR{" "}
              {formatCurrencyNoDecimal(
                dataDetail?.total_remaining_bill_and_spent?.total_spending || 0
              )}
            </GothamRegular>
          </div>
        </div>
      );
    return (
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "16px",
          }}
        >
          <GothamMedium style={{ fontSize: "16px", color: "#102C57" }}>
            Spending This Cycle
          </GothamMedium>
        </div>
        {!isLastCycleUnpaid && (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "16px",
              }}
            >
              <GothamMedium>Available spend</GothamMedium>
              <GothamMedium>
                {limit_currency}{" "}
                {formatCurrencyNoDecimal(
                  available_spend > 0 ? available_spend : 0
                )}
              </GothamMedium>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "16px",
              }}
            >
              <GothamRegular>Total spent</GothamRegular>
              <GothamRegular>
                {limit_currency}{" "}
                {formatCurrencyNoDecimal(total_spent > 0 ? total_spent : 0)}
              </GothamRegular>
            </div>
            {total_spent !== 0 && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "16px",
                }}
              >
                <GothamRegular>Remaining bill</GothamRegular>
                <GothamRegular>
                  {limit_currency} {formatCurrencyNoDecimal(remaining_bill)}
                </GothamRegular>
              </div>
            )}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <GothamRegular style={{ fontSize: "12px", color: "#6C6C71" }}>
                {limitText}
              </GothamRegular>
            </div>
          </>
        )}
      </>
    );
  };

  const prepaidBalance = () => {
    if (!isPrepaid) return null;

    if (isPermanentBlocked) return null;

    if (isTempBlocked)
      return (
        <div
          className="d-flex justify-content-between"
          style={{
            padding: 12,
            border: `1px solid ${colors.greye6}`,
            borderRadius: 8,
            marginTop: 16,
          }}
        >
          <GothamMedium>Card Balance</GothamMedium>
          <GothamRegular className="font16">
            IDR {formatCurrencyNoDecimal(balance)}
          </GothamRegular>
        </div>
      );

    return (
      <div
        style={{
          padding: 12,
          paddingTop: 14,
          paddingBottom: 14,
          border: `1px solid ${colors.greye6}`,
          marginTop: 16,
          borderRadius: 8,
        }}
      >
        <div
          className="d-flex justify-content-between"
          style={{
            paddingBottom: 10,
            borderBottom: `1px solid ${colors.greye6}`,
          }}
        >
          <GothamMedium>Card balance</GothamMedium>
          <OutlineButton
            style={{
              height: 24,
              fontSize: 12,
              border: `1px solid ${colors.greye6}`,
              paddingLeft: 16,
              paddingRight: 16,
              fontFamily: "GothamMedium",
            }}
            rightIcon={<img src={topup} className="ml-1 mb-1" />}
            onClick={onClickTopup}
          >
            Top Up
          </OutlineButton>
        </div>
        <div
          className="d-flex justify-content-between"
          style={{ marginTop: 20 }}
        >
          <GothamRegular>IDR {formatCurrencyNoDecimal(balance)}</GothamRegular>
          <img src={refresh} onClick={refetch} className="hover" />
        </div>
      </div>
    );
  };

  return (
    <div
      style={{
        paddingTop,
        paddingLeft: "20px",
        paddingRight: "20px",
        backgroundColor: isBlockingCondition || isChangingPIN ? null : "white",
        borderTopLeftRadius: "24px",
        borderTopRightRadius: "24px",
      }}
    >
      <div style={{ marginBottom: "16px" }}>
        {/* This will show if the user want to know how to change PIN */}
        {isChangingPIN &&
          changePINProcedures.map((procedure, index) => {
            return (
              <div
                key={index}
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: 8,
                }}
              >
                <div
                  style={{
                    width: 22,
                    minWidth: 22,
                    height: 22,
                    borderRadius: 11,
                    backgroundColor: "#2C4A77",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginRight: 16,
                    overflow: "visible",
                  }}
                >
                  <GothamMedium style={{ fontSize: 12, color: "#FCFCFC" }}>
                    {index + 1}
                  </GothamMedium>
                </div>
                <GothamRegular style={{ color: "#6C6C71" }}>
                  {procedure}
                </GothamRegular>
              </div>
            );
          })}

        {/* This will show if the user want to know how to adjust card limit */}
        {isEditingLimit && (
          <GothamRegular style={{ color: "#333334" }}>
            You can edit your card limit by contacting our sales / AM
          </GothamRegular>
        )}

        {/* This will show if the user want to know how to replace a card */}
        {isReplacingCard && (
          <GothamRegular style={{ color: "#333334" }}>
            You can replace your card by contacting our sales / AM
          </GothamRegular>
        )}

        {/* This will show if the card is already delivered but has not been activated */}
        {isActivatingScreen && (
          <CustomButton
            isLoading={loadingMutation}
            style={{ height: "32px", width: "150px", margin: "auto" }}
            onClick={() => handleClick("request_active", id)}
          >
            <GothamMedium>Activate Card</GothamMedium>
          </CustomButton>
        )}

        {/* This will show if the card has been requested to be activated */}
        {isRequestActivatingScreen && (
          <GothamRegular style={{ color: "#6C6C71", textAlign: "center" }}>
            Your request for activation is being processed, and it will be
            completed in 3 to 5 hours.
          </GothamRegular>
        )}

        {isNeedApprovalIssuance && (
          <ApproveRejectButtons
            buttonRightText="Approve"
            onClickLeft={toggleReject}
            onClickRight={togglePin}
            style={{ marginTop: 4 }}
          />
        )}

        {isRejected && (
          <div
            style={{
              display: "flex",
              gap: "2px",
              justifyContent: "space-around",
            }}
          >
            <CustomButton
              style={{
                height: "32px",
                width: "150px",
              }}
              className="btn hover-pink d-flex align-items-center justify-content-center"
              woClassName
              leftIcon={<img src={deleteIcon} />}
              onClick={() => handleClick("delete", id)}
            >
              <GothamMedium style={{ marginLeft: "4px", color: "#DA649F" }}>
                Remove Card
              </GothamMedium>
            </CustomButton>
          </div>
        )}

        {/* This will show if the card has been activated */}
        {isCardActivatedScreen && (
          <>
            <div
              style={{
                display: "flex",
                gap: "2px",
                justifyContent: "space-around",
              }}
            >
              <CustomButton
                style={{
                  height: "32px",
                  width: "130px",
                  backgroundColor: !isMinimize && "#FDD7F5",
                }}
                className="btn hover-pink d-flex align-items-center justify-content-center"
                woClassName
                leftIcon={<img src={transactionsIcon} />}
                onClick={() => {
                  eventsTracker("card_toggle_card_transaction_wing", { id });
                  setIsMinimize((prev) => !prev);
                }}
                disabled={isButtonDisabled}
              >
                <GothamMedium style={{ marginLeft: "4px", color: "#DA649F" }}>
                  Transactions
                </GothamMedium>
              </CustomButton>

              {/* This condition will hide blocking request button if the card already been blocked, requested for blocking, or suspended */}
              {isHideBlockRequest && (
                <>
                  <DropdownButton
                    stateProps={isBlockDropdownShowed}
                    onClick={(value) => handleNavigate(value)}
                    dropDownStyle={{ width: "180px" }}
                    withLabelIcon
                    onClickDropdown={(value) => setIsBlockDropdownShowed(value)}
                    isCustomButton
                    woClassName
                    className="btn hover-pink d-flex align-items-center justify-content-center"
                    style={{
                      width: "110px",
                      height: "32px",
                      border: "none",
                      backgroundColor: isBlockDropdownShowed && "#FDD7F5",
                      gap: "4px",
                    }}
                    array={[
                      {
                        label: "Temporary Block",
                        value: "temporary_block",
                        icon: <img src={freezeIcon} />,
                      },
                      {
                        label: "Permanent Block",
                        value: "permanent_block",
                        icon: <img src={permanentBlockIcon} />,
                      },
                    ]}
                    leftIcon={<img src={blockedIcon} />}
                    rightIcon={
                      <img src={isBlockDropdownShowed ? upIcon : downIcon} />
                    }
                    disabled={isButtonDisabled}
                  >
                    <GothamMedium style={{ color: "#DA649F" }}>
                      Block
                    </GothamMedium>
                  </DropdownButton>
                  {isPhysical ? (
                    <DropdownButton
                      onClick={(value) => handleNavigate(value)}
                      dropDownStyle={{
                        width: "180px",
                        left: "-90px",
                        zIndex: "100",
                      }}
                      withLabelIcon
                      onClickDropdown={(value) =>
                        setIsMoreDropdownShowed(value)
                      }
                      isCustomButton
                      woClassName
                      className="btn hover-pink d-flex align-items-center justify-content-center"
                      style={{
                        width: "90px",
                        height: "32px",
                        border: "none",
                        backgroundColor: isMoreDropdownShowed && "#FDD7F5",
                        gap: "4px",
                      }}
                      array={[
                        {
                          label: "Change PIN",
                          value: "change_pin",
                          icon: <img src={changePINIcon} />,
                        },
                        {
                          label: "Edit description",
                          value: "edit_description",
                          icon: <img src={editNicknameBlueIcon} />,
                        },
                        {
                          label: "Adjust Limit",
                          value: "adjust_limit_physical",
                          icon: <img src={editLimitIcon} />,
                        },
                        {
                          label: "Replace Card",
                          value: "replace_card",
                          icon: <img src={replaceCardIcon} />,
                        },
                      ]}
                      rightIcon={
                        <img src={isMoreDropdownShowed ? upIcon : downIcon} />
                      }
                    >
                      <GothamMedium style={{ color: "#DA649F" }}>
                        More
                      </GothamMedium>
                    </DropdownButton>
                  ) : (
                    <DropdownButton
                      onClick={(value) => handleNavigate(value)}
                      dropDownStyle={{
                        width: "180px",
                        left: "-90px",
                        zIndex: "100",
                      }}
                      withLabelIcon
                      onClickDropdown={(value) =>
                        setIsMoreDropdownShowed(value)
                      }
                      isCustomButton
                      woClassName
                      className="btn hover-pink d-flex align-items-center justify-content-center"
                      style={{
                        width: "90px",
                        height: "32px",
                        border: "none",
                        backgroundColor: isMoreDropdownShowed && "#FDD7F5",
                        gap: "4px",
                      }}
                      array={[
                        {
                          label: "Edit nickname",
                          value: "edit_nickname",
                          icon: <img src={editNicknamePinkIcon} />,
                        },
                        {
                          label: "Edit description",
                          value: "edit_description",
                          icon: <img src={editNicknamePinkIcon} />,
                        },
                        !isPrepaid && {
                          label: "Adjust Limit",
                          value: "adjust_limit_virtual",
                          icon: <img src={editLimitPinkIcon} />,
                        },
                      ]}
                      rightIcon={
                        <img src={isMoreDropdownShowed ? upIcon : downIcon} />
                      }
                      disabled={isVirtualEditScreen}
                    >
                      <GothamMedium style={{ color: "#DA649F" }}>
                        More
                      </GothamMedium>
                    </DropdownButton>
                  )}
                </>
              )}

              {/* This will only show if the card is temporarily blocked */}
              {isTempBlockedScreen && (
                <CustomButton
                  style={{
                    height: "32px",
                    width: "150px",
                  }}
                  className="btn hover-pink d-flex align-items-center justify-content-center"
                  woClassName
                  leftIcon={<img src={unblockIcon} />}
                  onClick={() => handleClick("request_unblock", id)}
                  isLoading={loadingMutation}
                >
                  <GothamMedium style={{ marginLeft: "4px", color: "#DA649F" }}>
                    Unblock Card
                  </GothamMedium>
                </CustomButton>
              )}

              {/* This will only show if the card has been permanently blocked */}
              {isPermanentBlockedScreen && (
                <CustomButton
                  style={{
                    height: "32px",
                    width: "150px",
                  }}
                  className="btn hover-pink d-flex align-items-center justify-content-center"
                  woClassName
                  leftIcon={<img src={deleteIcon} />}
                  onClick={() => handleClick("delete", id)}
                >
                  <GothamMedium style={{ marginLeft: "4px", color: "#DA649F" }}>
                    Remove Card
                  </GothamMedium>
                </CustomButton>
              )}
            </div>
            {prepaidBalance()}
          </>
        )}
      </div>

      {/* This will show if the user want to temporarily or permanently block a card */}
      {isBlockingScreen && (
        <div
          style={{
            gap: "16px",
            display: "flex",
            flexDirection: "column",
            position: "absolute",
            width: "calc(100% - 40px)",
            top: "76px",
          }}
        >
          <CustomButton
            isLoading={loadingMutation}
            style={{ width: "100%" }}
            onClick={() => {
              if (isFreezing) return handleClick("request_temp_block", id);
              if (isBlocking) return handleClick("request_p_block", id);
            }}
          >
            {isFreezing ? temporaryBlockButton : permanentBlockButton}
          </CustomButton>
          <CustomButton
            className="btn hover-pink d-flex align-items-center justify-content-center"
            woClassName
            style={{ color: "#DA649F", border: "2px solid #DA649F" }}
            onClick={() => handleNavigate("back")}
          >
            Cancel
          </CustomButton>
        </div>
      )}

      {/* {isShowSunlight && (
        <div
          style={{
            padding: "12px 18px",
            border: "1px solid #E6E6E8",
            borderRadius: "12px",
            marginBottom: "16px",
            display: "flex",
            cursor: "pointer",
            backgroundColor: isSunlightHovered ? "#F3F3F4" : "white",
            transition: "all 0.25s linear",
          }}
          onClick={() => {
            eventsTracker("card_detail_open_sunlight", { id });
            initSunlight();
          }}
          onMouseEnter={() => setIsSunlightHovered(true)}
          onMouseLeave={() => setIsSunlightHovered(false)}
        >
          <div>
            <div
              style={{
                display: "flex",
                gap: "6px",
                marginBottom: "4px",
              }}
            >
              <img src={sunlightLogo} />
              <GothamRegular>Sunlight</GothamRegular>
            </div>
            <GothamRegular className="font12">
              Update your payment card information on multiple sevices in just
              few seconds.
            </GothamRegular>
          </div>
          {loadingSunlight ? (
            <div
              style={{
                margin: "auto 0",
              }}
            >
              <FaSpinner className="icon-spin" />
            </div>
          ) : (
            <img src={arrowRightIcon} />
          )}
        </div>
      )} */}

      {isNeedApprovalLimitScreen && (
        <div
          style={{
            padding: "10px",
            border: "1.5px solid #E6E6E6",
            borderRadius: "8px",
            marginBottom: "12px",
            display: "flex",
            flexDirection: "column",
            gap: "8px",
          }}
        >
          <GothamBold style={{ color: colors.blue10 }}>
            Limit adjustment has been requested
          </GothamBold>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <GothamRegular>Requested limit</GothamRegular>
            <GothamRegular>
              IDR {formatCurrencyNoDecimal(requestedAmount)}
            </GothamRegular>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <GothamRegular>Limit cycle</GothamRegular>
            <GothamRegular>{requestedLimitType}</GothamRegular>
          </div>
          <ApproveRejectButtons
            buttonRightText="Approve"
            onClickLeft={toggleReject}
            onClickRight={togglePin}
            style={{ marginTop: 4 }}
          />
        </div>
      )}

      {cardInfo()}

      {/* This will show if the card is not in any blocking state */}
      {isNotBlockedCardIndex && (
        <div
          style={{
            borderTop: !isNotActivated && "1px solid #E6E6E8",
            paddingTop: !isNotActivated && "16px",
            paddingBottom: "16px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {/* This will show if the card has been activated */}
          {spendingCycle()}

          {/* {isNeedApprovalLimitScreen && isEmployee && (
            <div
              style={{
                backgroundColor: colors.bluee6,
                borderRadius: "8px",
                padding: "14px 12px 14px 12px",
                display: "flex",
                gap: "14px",
                alignItems: "flex-start",
                transition: "all 0.25s linear",
                marginTop: "8px",
                marginBottom: "4px",
              }}
            >
              <img src={informationIcon} />
              <div>
                <GothamBold className="font16" style={{ margin: 0 }}>
                  Limit adjustment is in progress
                </GothamBold>
                <GothamRegular
                  className="font14"
                  style={{ color: colors.grey6c }}
                >
                  We're working on raising your limit <br /> to IDR{" "}
                  {formatCurrencyNoDecimal(requestedAmount)}.
                </GothamRegular>
              </div>
            </div>
          )} */}

          {/* This will show if the card is not yet activated */}
          {isCardNotActiveScreen && (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "16px",
                  borderTop: isNotActivated && "1px solid #E6E6E8",
                  paddingTop: isNotActivated && "16px",
                }}
              >
                <GothamRegular>Limit per month</GothamRegular>
                <GothamRegular>
                  {limit_currency} {formatCurrencyNoDecimal(limit_value)}
                </GothamRegular>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <GothamRegular>Issued on</GothamRegular>
                <GothamRegular>{formattedIssuedDate}</GothamRegular>
              </div>
            </>
          )}

          {isLastCycleUnpaid && (
            <div style={{ display: "flex", gap: 18 }}>
              <img src={redWarningIcon} />
              <GothamRegular style={{ width: 1000 }}>
                You haven't paid this card bill yet <br /> Bill for{" "}
                {startingDate} to {endingDate} is{" "}
                <span
                  style={{
                    fontWeight: 700,
                  }}
                >
                  IDR {formatCurrencyNoDecimal(total_bill)}
                </span>
              </GothamRegular>
            </div>
          )}
        </div>
      )}

      {isAdjustingLimit && (
        <div style={{ marginTop: "-18px", marginBottom: "32px" }}>
          <GothamBold style={{ fontSize: "16px", color: colors.blue10 }}>
            Adjust Limit
          </GothamBold>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: "16px",
            }}
          >
            <GothamRegular style={{ width: "20%" }}>Type</GothamRegular>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "80%",
                fontSize: "14px",
              }}
            >
              <input
                id="radio_multiple"
                type="radio"
                name="limit_type"
                value="MULTIPLE_USE"
                style={{
                  visibility: "hidden",
                  width: 0,
                }}
                onChange={(e) => {
                  setLimitType(e.target.value);
                }}
              />
              <label
                for="radio_multiple"
                style={{
                  cursor: "pointer",
                  padding: "6px 10px",
                  backgroundColor: isMultiple
                    ? colors.lightPink
                    : colors.greyea,
                  fontFamily: isMultiple ? "GothamBold" : "GothamBook",
                  color: isMultiple ? colors.pink : colors.grey6c,
                  borderRadius: 8,
                  transition: "all 0.4s ease",
                }}
              >
                Monthly
              </label>
              <input
                id="radio_one_time"
                type="radio"
                name="limit_type"
                value="ONE_TIME_USE"
                style={{
                  visibility: "hidden",
                  width: 0,
                  fontSize: "14px",
                }}
                onChange={(e) => {
                  setLimitType(e.target.value);
                }}
              />
              <label
                for="radio_one_time"
                style={{
                  cursor: "pointer",
                  padding: "6px 10px",
                  backgroundColor: !isMultiple
                    ? colors.lightPink
                    : colors.greyea,
                  fontFamily: !isMultiple ? "GothamBold" : "GothamBook",
                  color: !isMultiple ? colors.pink : colors.grey6c,
                  borderRadius: 8,
                  marginLeft: 12,
                  transition: "all 0.4s ease",
                }}
              >
                One time
              </label>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: "4px",
            }}
          >
            <GothamRegular style={{ width: "20%" }}>Amount</GothamRegular>
            <div
              style={{
                display: "flex",
                width: "80%",
                height: "44px",
                gap: "12px",
              }}
            >
              {/* <div style={{ width: "110px" }}>
                <SelectionForm
                  isCentered
                  name="limit_period"
                  useFormObj={useFormObj}
                  onChange={(e) => {
                    setLimitPeriod(e.value);
                  }}
                  defaultValue={{ value: "monthly", label: "Monthly" }}
                  options={[
                    { value: "DAILY_AMOUNT_LIMIT", label: "Daily" },
                    { value: "MONTHLY_AMOUNT_LIMIT", label: "Monthly" },
                    { value: "YEARLY_AMOUNT_LIMIT", label: "Yearly" },
                    { value: "LIFETIME_AMOUNT_LIMIT", label: "Lifetime" },
                  ]}
                  isDisabled={!isMultiple}
                />
              </div> */}
              <div style={{ width: "calc(100% - 25px)" }}>
                {/* <div style={{ width: "calc(100% - 122px)" }}> */}
                <CurrencyField
                  label="Set amount"
                  useFormObj={useFormObj}
                  name="limit_value"
                  prefix="IDR"
                />
              </div>
            </div>
          </div>
          <div
            style={{
              marginTop: "12px",
            }}
          >
            <WarningLimit
              show={isWarningShow}
              isSmall={true}
              customText="Reduce your requested limit. Requested limit must be less than company's remaining limit."
            />
          </div>
          <div style={{ display: "flex", marginTop: "16px", gap: "8px" }}>
            <CustomButton
              style={{ width: "130px", height: "32px", fontSize: "14px" }}
              onClick={() => {
                eventsTracker("card_virtual_adjust_limit");
                handleSubmit(handleSubmitLimit)();
              }}
              isLoading={loadingUpdate}
              disabled={isSaveLimitDisabled}
            >
              Save Limit
            </CustomButton>
            <OutlineButton
              style={{ width: "95px", height: "32px", fontSize: "14px" }}
              onClick={() => handleNavigate("back")}
            >
              Cancel
            </OutlineButton>
          </div>
        </div>
      )}

      {isEditingDescriptionScreen && (
        <div style={{ marginTop: "-18px", marginBottom: "32px" }}>
          <GothamBold style={{ fontSize: "16px", color: colors.blue10 }}>
            Edit Card Description
          </GothamBold>
          <CustomLabeledTextArea
            useFormObj={useFormObj}
            name="description"
            label="Description"
            placeholder="E.g., Medical Expenses"
            style={{ marginBottom: 0 }}
            maxLength={100}
            woShowCounter
            required={false}
          />
          <div style={{ display: "flex", marginTop: "16px", gap: "8px" }}>
            <CustomButton
              style={{ width: "180px", height: "32px", fontSize: "14px" }}
              onClick={() => {
                eventsTracker("card_save_description");
                handleSubmit(handleSubmitDescription)();
              }}
              disabled={!descriptionWatch}
              isLoading={loadingUpdate}
            >
              Save Description
            </CustomButton>
            <OutlineButton
              style={{ width: "95px", height: "32px", fontSize: "14px" }}
              onClick={() => handleNavigate("back")}
            >
              Cancel
            </OutlineButton>
          </div>
        </div>
      )}

      {isEditingNickname && (
        <div style={{ marginTop: "-18px", marginBottom: "32px" }}>
          <GothamBold style={{ fontSize: "16px", color: colors.blue10 }}>
            Edit Card Nickname
          </GothamBold>
          <CustomLimitTextField
            useFormObj={useFormObj}
            name="nickname"
            label="Card Nickname"
            limit={19}
            style={{ marginTop: "16px" }}
            required={false}
          />
          <div style={{ display: "flex", marginTop: "16px", gap: "8px" }}>
            <CustomButton
              style={{ width: "150px", height: "32px", fontSize: "14px" }}
              onClick={() => {
                eventsTracker("card_save_nickname");
                handleSubmit(handleSubmitNickname)();
              }}
              disabled={!nicknameWatch}
              isLoading={loadingUpdate}
            >
              Save Nickname
            </CustomButton>
            <OutlineButton
              style={{ width: "95px", height: "32px", fontSize: "14px" }}
              onClick={() => handleNavigate("back")}
            >
              Cancel
            </OutlineButton>
          </div>
        </div>
      )}
    </div>
  );
};

export default CardModalMiddle;
