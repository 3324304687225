import {
  customDateFormatter,
  formatCurrencyNoDecimal,
} from "../../../components/tools";
import { apiTransactionFormatter } from "../../transactionDetailsComponents";
import { defaultAccountDetailsLogic } from "./defaultLogic";

export const apiFormatter = (object) => {
  const { details, category: type, status, created_at } = object || {};
  const defaultObj = defaultAccountDetailsLogic(object);
  const originator = details?.originator || {};

  const { senderName, beneName } = apiTransactionFormatter(originator);

  const { source, destination, amount, reference_id, fee } = originator;
  const total_amount = Number(amount) + Number(fee);
  const sourceCurrency = source?.currency;
  const destinationCurrency = destination?.currency;

  const leftTop = `${senderName} (${sourceCurrency}) - ${beneName} (${destinationCurrency})`;
  const leftBottom = `• ${reference_id} • ${customDateFormatter(created_at)} `;
  const leftStatus = status;
  const rightTop = `-${sourceCurrency} ${formatCurrencyNoDecimal(
    total_amount
  )}`;

  return {
    ...object,
    ...defaultObj,
    leftTop,
    leftStatus,
    leftBottom,
    rightTop,
    type,
  };
};
