import React, { useState } from "react";
import styled from "styled-components";
import { colors } from "../../../../assets/colors";
import WalletIcon from "../../../../assets/images/balance-icon-blue.svg";
import selectedIcon from "../../../../assets/images/selected-radio-icon.svg";
import TransferBankIcon from "../../../../assets/images/transfer-bank-icon-blue.svg";
import RefreshIconPink from "../../../../assets/images/refresh-icon-pink.svg";
import InfoIcon from "../../../../assets/images/info-icon-red.svg";
import { CustomButton } from "../../../../components/Buttons";
import {
  TypeFezNeueMedium,
  TypeFezNeueRegular,
} from "../../../../components/Text";
import { formatCurrencyNoDecimal } from "../../../../components/tools";
import CustomTooltip from "../../../../components/Tooltip";

export const PaymentMethodSelection = ({
  setSelectedMethod,
  selectedMethod,
  balance,
}) => {
  const dataRender = [
    {
      title: "Deduct from balance",
      value: `Your current balance is IDR ${formatCurrencyNoDecimal(balance)}`,
      icon: WalletIcon,
      type: "balance",
    },
    {
      title: "Transfer to bank account",
      value: "Manual verification",
      icon: TransferBankIcon,
      type: "transfer",
    },
  ];

  return (
    <div>
      <TypeFezNeueMedium
        style={{ color: colors.grey33, fontSize: 16, marginBottom: 16 }}
      >
        Payment Method
      </TypeFezNeueMedium>
      {dataRender?.map(({ title, value, icon, type }, index) => {
        const isFirst = index === 0;
        const isActive = type === selectedMethod;
        return (
          <div
            key={index}
            className="d-flex justify-content-between align-items-center"
            style={{
              border: "1px solid #E6E6E8",
              borderRadius: 8,
              padding: 12,
              marginTop: !isFirst && 12,
              cursor: "pointer",
            }}
            onClick={() => setSelectedMethod(type)}
          >
            <div className="d-flex align-items-center">
              <img src={icon} style={{ marginRight: 12 }} />
              <div>
                <TypeFezNeueRegular>{title}</TypeFezNeueRegular>
                <TypeFezNeueRegular
                  style={{
                    color: colors.grey6c,
                    fontSize: 10,
                    lineHeight: "12px",
                  }}
                >
                  {value}
                </TypeFezNeueRegular>
              </div>
            </div>
            {isActive ? <img src={selectedIcon} /> : <GreyCircle />}
          </div>
        );
      })}
    </div>
  );
};

export const PaymentSummaryMethod = ({
  isNotEnoughBalance,
  selectedMethod,
  refetchUser,
  totalTrx,
  balance,
  onClick,
}) => {
  const isBankTransfer = selectedMethod === "transfer";
  const showAlert = isNotEnoughBalance && !isBankTransfer;

  return (
    <div>
      <TypeFezNeueMedium
        style={{ color: colors.grey33, fontSize: 16, marginBottom: 16 }}
      >
        Payment Summary
      </TypeFezNeueMedium>
      {showAlert && <AlertTopup onClick={onClick} />}
      <Summary
        refetchUser={refetchUser}
        balance={balance}
        totalTrx={totalTrx}
        isBankTransfer={isBankTransfer}
        isNotEnoughBalance={isNotEnoughBalance}
      />
    </div>
  );
};

const AlertTopup = ({ onClick }) => {
  return (
    <div
      className="d-flex align-items-center justify-content-between"
      style={{
        border: "1px solid #FCB037",
        borderRadius: 8,
        padding: 12,
        backgroundColor: "#FFF8EC",
        marginBottom: 16,
      }}
    >
      <div>
        <TypeFezNeueRegular style={{ color: colors.grey6c }}>
          You don’t have enough balance.
        </TypeFezNeueRegular>
      </div>
      <CustomButton
        onClick={onClick}
        style={{ height: 22, padding: "2px 16px", fontSize: 12 }}
      >
        Top up
      </CustomButton>
    </div>
  );
};

const Summary = ({
  isBankTransfer,
  isNotEnoughBalance,
  balance,
  totalTrx,
  refetchUser,
}) => {
  const remainingBalance = Number(balance) - Number(totalTrx);

  const summaryBalance = [
    {
      title: (
        <>
          Available Balance{" "}
          {isNotEnoughBalance && (
            <img src={InfoIcon} style={{ marginTop: -4 }} />
          )}
        </>
      ),
      value: (
        <>
          IDR {formatCurrencyNoDecimal(balance)}{" "}
          {isNotEnoughBalance && (
            <CustomTooltip
              text={<span style={{ fontSize: 12 }}>Refresh</span>}
              style={{ padding: "0px 12px" }}
            >
              <img
                src={RefreshIconPink}
                style={{ marginTop: -4, cursor: "pointer" }}
                onClick={refetchUser}
              />
            </CustomTooltip>
          )}
        </>
      ),
    },
    {
      title: "Amount to pay",
      value: `IDR ${formatCurrencyNoDecimal(totalTrx)}`,
    },
    { spacer: true },
    {
      title: isNotEnoughBalance ? "You need to top up" : "Remaining balance",
      value: `IDR ${formatCurrencyNoDecimal(Math.abs(remainingBalance))}`,
      rightBold: true,
    },
  ];

  const summaryBankTransfer = [
    {
      title: "Amount to pay",
      value: `IDR ${formatCurrencyNoDecimal(totalTrx)}`,
    },
  ];

  const array = isBankTransfer ? summaryBankTransfer : summaryBalance;

  return (
    <div>
      {array?.map((item, index) => {
        const { title, value, spacer, rightBold } = item || {};
        const isLast = index === array.length - 1;
        if (spacer) return <hr />;

        return (
          <div
            key={index}
            className="d-flex justify-content-between align-items-center"
            style={{ marginBottom: isLast ? 24 : 12 }}
          >
            <TypeFezNeueRegular style={{ color: colors.grey72 }}>
              {title}
            </TypeFezNeueRegular>
            <TypeFezNeueRegular
              style={{
                color: rightBold ? colors.grey33 : colors.grey72,
                fontFamily: rightBold
                  ? "TypefezNeueMedium"
                  : "TypefezNeueRegular",
              }}
            >
              {value}
            </TypeFezNeueRegular>
          </div>
        );
      })}
    </div>
  );
};

const GreyCircle = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin-right: 2px;
  border: 1px solid ${colors.greyC2};
`;
