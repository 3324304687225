export const crossBorderTitleAndOnClick = ({ pathname, push, back }) => {
  const index = pathname == "/cross-border";
  const isSingle = pathname == "/cross-border/single";
  const isRecipient = pathname == "/cross-border/single/recipient";
  const isAdditional = pathname == "/cross-border/single/additional";
  const isPayment = pathname == "/cross-border/single/payment";
  const isConfirmation = pathname == "/cross-border/single/confirmation";

  if (index) return { title: "Create New International Transfer" };

  if (isSingle)
    return { title: "Single Payment", onClick: () => push("/cross-border/") };

  if (isRecipient)
    return {
      title: "Who are you sending to?",
      onClick: () => push("/cross-border/single"),
    };

  if (isAdditional)
    return {
      title: "Additional Information",
      onClick: () => push("/cross-border/single/recipient"),
    };

  if (isPayment)
    return {
      title: "Payment Method",
      onClick: back,
    };

  if (isConfirmation)
    return {
      title: "Confirmation",
      onClick: back,
    };

  const isBatch = pathname == "/cross-border/batch";
  const isBatchUpload = pathname == "/cross-border/batch/upload-csv";
  const isBatchDetails = pathname == "/cross-border/batch/details";
  const isBatchPayment = pathname == "/cross-border/batch/payment";

  const defaultBatch = { title: "Batch Payment", onClick: back };
  if (isBatch) return defaultBatch;
  if (isBatchUpload) return defaultBatch;
  if (isBatchDetails) return defaultBatch;
  if (isBatchPayment) return { title: "Payment Method", onClick: back };
  return {};
};
