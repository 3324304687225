import { pickBy } from "lodash";
import { useRouter } from "next/router";
import React from "react";
import { colors } from "../../../../../assets/colors";
import { QueryDatePicker } from "../../../../../components/inputs";
import { GothamMedium, GothamRegular } from "../../../../../components/Text";

export const IRFilterCreatedDate = ({ activeFilterObj }) => {
  const { query, push } = useRouter();
  const handleDelete = (value) => {
    const result = pickBy(query, (_, key) => value != key);
    push({ query: result });
  };

  const { from_date = "", to_date } = query;
  return (
    <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
      <div
        className="d-flex justify-content-between"
        style={{ marginBottom: 12 }}
      >
        <GothamMedium>Date range</GothamMedium>
        {/* {from_date ? (
          <GothamMedium
            style={{ color: colors.pink }}
            onClick={() => {
              handleDelete("to_date");
              handleDelete("from_date");
            }}
          >
            Reset
          </GothamMedium>
        ) : null} */}
      </div>
      <div className="d-flex justify-content-between">
        <QueryDatePicker
          showClear
          label="From"
          name="from_date"
          columnProps={{
            sm: 12,
            style: { maxWidth: 152, minWidth: 152 },
          }}
          //maxDate={new Date()}
          dateFormat="dd MMM yyyy"
        />
        <GothamMedium style={{ marginTop: "12px" }}>-</GothamMedium>
        <QueryDatePicker
          showClear
          label="To"
          name="to_date"
          columnProps={{
            sm: 12,
            style: { maxWidth: 152, minWidth: 152 },
          }}
          minDate={from_date && new Date(from_date)}
          //maxDate={new Date()}
          disabled={!from_date}
          dateFormat="dd MMM yyyy"
        />
      </div>
    </div>
  );
};
