import { useState, useEffect, useRef } from "react";
import { RightCardModalAtomic } from "../../components/CardModal";
import { LeftRightList } from "../../components/Lists";
import {
  customDateFormatter,
  formatCurrency,
  formatCurrencyNoDecimal,
} from "../../components/tools";
import { ActivityDetailsHeader } from "./activityModalComponents";
import { CenterendIconLocalTransfer } from "./localDisbursementBatchModal/transactionModal";
import { MiddleWrapper } from "../../components/CardModal";
import { StatusLabel } from "../../components/Status";
import { GothamBold, GothamRegular } from "../../components/Text";
import { CustomTextArea, FileInput } from "../../components/inputs";
import { useForm } from "react-hook-form";
import cardIcon from "../../assets/images/card-icon-40.svg";
import uploadIcon from "../../assets/images/upload-pink-icon-card.svg";
import { useMutation } from "../../tools/api";
import { colors } from "../../assets/colors";
import deleteIcon from "../../assets/images/delete-grey-icon.svg";
import viewIcon from "../../assets/images/eye-grey.svg";
import { useGdrivehook } from "../../contexts/GoogleDrivePreviewContext/parent";
import Skeleton from "react-loading-skeleton";
import { FaSpinner } from "react-icons/fa";
import { cardTypeDecider } from "../../pageComponents/newCardComponents/tools";

export const CardTransactionModal = ({ toggle, isOpen, data }) => {
  const [transactionData, setTransactionData] = useState(null);
  const [middleHeight, setMiddleHeight] = useState(0);
  const [showUploadField, setShowUploadField] = useState(false);

  const { originator_id: dataOriginatorId } = data || {};
  const { details, payer, status, originator_id } = transactionData || {};

  const {
    date,
    merchant_name,
    file_details = [],
    notes = "",
    transaction_type,
  } = details || {};

  const {
    amount: totalAmount,
    transaction_amount: transactionAmount,
    transaction_currency: transactionCurrency,
  } = details || {};

  const isTransactionIDR = transactionCurrency === "IDR";
  const isTransactionAmountLarger = +transactionAmount > +totalAmount;
  const exchangeRate = isTransactionAmountLarger
    ? transactionAmount / totalAmount
    : totalAmount / transactionAmount;

  const rightCurrency = isTransactionAmountLarger ? transactionCurrency : "IDR";
  const leftCurrency = isTransactionAmountLarger ? "IDR" : transactionCurrency;

  const useFormObj = useForm();
  const { getValues, setValue, watch } = useFormObj;
  const notesForm = getValues("notes");

  const headerData = {
    type: merchant_name,
    icon: null,
    title: `IDR ${formatCurrency(totalAmount)}`,
  };

  const isThereAttachment = Boolean(file_details.length);
  const isAttachmentMaxLimit = file_details.length >= 3;

  const isPrepaidTopup = transaction_type === "CREDIT_TOPUP";
  const isPrepaidWithdrawal = transaction_type === "DEBIT_WITHDRAWAL";

  const topUpPaymentMethodDecider = () => {
    const rawPaymentMethod = merchant_name?.split("-");
    rawPaymentMethod?.shift();
    const paymentMethod = rawPaymentMethod?.join("-");
    return paymentMethod;
  };

  const topUpPaymentMethod = topUpPaymentMethodDecider();

  const informations = [
    {
      left: "Date",
      right: customDateFormatter(date),
    },
    !isPrepaidWithdrawal && {
      left: isPrepaidTopup ? "Payment Method" : "Merchant",
      right: isPrepaidTopup ? topUpPaymentMethod : merchant_name,
    },
    {
      left: "Payer",
      right: payer,
      rightBold: true,
    },
  ];

  const { mutation, loading } = useMutation({
    url: `/card_transactions/${originator_id}/update`,
    afterSuccess: () => {
      setValue("upload_file", undefined);
      setTimeout(() => mutationActivity({ filters }), 1250);
    },
  });

  const fileWatch = watch("upload_file");

  const { mutation: mutationDelete, loading: loadingDelete } = useMutation({
    url: `/card_transactions/${originator_id}/delete`,
    afterSuccess: () => {
      setTimeout(() => mutationActivity({ filters }), 1000);
    },
  });

  const { mutation: mutationActivity, loading: loadingActivity } = useMutation({
    url: "/activity_search",
    method: "post",
    withError: false,
    afterSuccess: ({ data }) => {
      const transactionData = data?.data[data?.data.length - 1];
      setTransactionData(transactionData);
    },
  });

  const filters = {
    category: "card_transaction",
    originator_id: dataOriginatorId,
  };

  useEffect(async () => {
    if (fileWatch) {
      const { realFile } = fileWatch;
      const formData = new FormData();
      formData.append("files[]", realFile);
      if (notes !== notesForm) await mutation({ notes: notesForm });
      mutation(formData);
    }
  }, [fileWatch]);

  useEffect(() => {
    mutationActivity({ filters });
  }, []);

  useEffect(() => {
    setValue("notes", notes);
  }, [details]);

  const toggleHandler = async () => {
    if (notes !== notesForm) mutation({ notes: notesForm });
    toggle();
  };

  const { show } = useGdrivehook();

  const refCardModalHeader = useRef();
  const headerHeight = refCardModalHeader?.current?.clientHeight + 70;

  return (
    <>
      <RightCardModalAtomic
        isOpen={isOpen}
        toggle={toggleHandler}
        header={
          <div ref={refCardModalHeader}>
            <ActivityDetailsHeader toggle={toggleHandler} data={headerData} />
          </div>
        }
        headerStyle={{ marginBottom: "50px", height: headerHeight }}
        middleHeight={middleHeight}
        middleStyle={{
          marginTop: "-76px",
        }}
        middle={
          <MiddleWrapper setHeight={setMiddleHeight} height={middleHeight}>
            <CenterendIconLocalTransfer icon={cardIcon} />
          </MiddleWrapper>
        }
        bodyStyle={{
          marginTop: "-70px",
          borderRadius: "24px",
        }}
        body={
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "-6px",
              }}
            >
              <StatusLabel status={status} />
            </div>
            <div
              style={{
                marginBottom: "12px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "10px",
                }}
              >
                <GothamBold style={{ color: colors.blue10 }}>
                  Receipt
                </GothamBold>
                {isThereAttachment &&
                  !isAttachmentMaxLimit &&
                  (showUploadField ? (
                    <GothamBold
                      style={{
                        color: colors.pink,
                        cursor: "pointer",
                        userSelect: "none",
                      }}
                      onClick={() => setShowUploadField(false)}
                    >
                      Cancel
                    </GothamBold>
                  ) : (
                    <GothamBold
                      style={{
                        color: colors.pink,
                        cursor: "pointer",
                        userSelect: "none",
                      }}
                      onClick={() => setShowUploadField(true)}
                    >
                      Add
                    </GothamBold>
                  ))}
              </div>
              {isThereAttachment &&
                file_details.map(({ name, url, id }, index) => {
                  if (loadingDelete || loadingActivity)
                    return (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginBottom: "10px",
                        }}
                        key={index}
                      >
                        <Skeleton width={200} height={18} />
                        <Skeleton width={40} height={18} />
                      </div>
                    );

                  return (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: "10px",
                      }}
                    >
                      <GothamRegular>
                        {name.length > 40 ? name.slice(0, 40) + "..." : name}
                      </GothamRegular>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "6px",
                        }}
                      >
                        <img
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            show({
                              index,
                              files: file_details,
                            })
                          }
                          src={viewIcon}
                        />
                        {loadingDelete ? (
                          <div
                            style={{
                              margin: "auto 0",
                            }}
                          >
                            <FaSpinner className="icon-spin" />
                          </div>
                        ) : (
                          <img
                            style={{ cursor: "pointer" }}
                            src={deleteIcon}
                            onClick={() => mutationDelete({ file_id: id })}
                          />
                        )}
                      </div>
                    </div>
                  );
                })}
              {(!isThereAttachment || showUploadField) &&
                !isAttachmentMaxLimit && (
                  <FileInput
                    isDottedBorder
                    fileTypes={[
                      "image/jpeg",
                      "image/jpg",
                      "image/png",
                      "application/pdf",
                    ]}
                    useFormObj={useFormObj}
                    name="upload_file"
                    isDottedThumbnail={true}
                    formData
                    type="general"
                    multiple={false}
                    style={{
                      border: "2px dashed",
                      borderColor: colors.pink,
                    }}
                    fontStyle={{ color: colors.pink }}
                    uploadIcon={uploadIcon}
                    loading={loading}
                  />
                )}
            </div>
            <div>
              <GothamBold style={{ color: colors.blue10 }}>Notes</GothamBold>
              <CustomTextArea
                useFormObj={useFormObj}
                name="notes"
                required={false}
                placeholder="Your payment details"
                style={{
                  height: "60px",
                  fontSize: "14px",
                  resize: "vertical",
                }}
              />
            </div>
            <div style={{ marginTop: "12px" }}>
              <LeftRightList array={informations} />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "4px",
                }}
              >
                <GothamRegular style={{ color: "#6C6C71" }}>
                  Total Amount
                </GothamRegular>
                <GothamRegular style={{ color: "#6C6C71" }}>
                  IDR {formatCurrencyNoDecimal(totalAmount)}
                </GothamRegular>
              </div>
            </div>
            {!isTransactionIDR && (
              <>
                <GothamRegular
                  style={{
                    color: "#909098",
                    textAlign: "right",
                    marginBottom: "12px",
                  }}
                >
                  {transactionCurrency} {formatCurrency(transactionAmount)}
                </GothamRegular>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "40px",
                  }}
                >
                  <GothamRegular style={{ color: "#6C6C71" }}>
                    Exchange Rate
                  </GothamRegular>
                  <GothamRegular style={{ color: "#6C6C71" }}>
                    1 {leftCurrency} = {formatCurrencyNoDecimal(exchangeRate)}{" "}
                    {rightCurrency}
                  </GothamRegular>
                </div>
              </>
            )}
          </>
        }
      />
    </>
  );
};
