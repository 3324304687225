import { useEffect, useState } from "react";
import localBatchIcon from "../../../assets/images/local-batch-icon-40.svg";
import {
  MiddleWrapper,
  RightCardModalAtomic,
} from "../../../components/CardModal";
import { LeftRightList } from "../../../components/Lists";
import { StatusLabel } from "../../../components/Status";
import { GothamMedium } from "../../../components/Text";
import { StatusPayrollLine } from "../../../pageComponents/payrollCreateComponents/loop/success/status";
import { fetch } from "../../../tools/api";
import { ActivityDetailsHeader } from "../activityModalComponents";
import { ArrayDeciderTransactionFlow } from "../localDisbursementBatchModal/logics";
import { CenterendIconLocalTransfer } from "../localDisbursementBatchModal/transactionModal";
import { RecipientMinimalist } from "../transactionList";
import { useLocalTransferSingle } from "./logic";

export const LocalTransferSingleModal = ({
  isOpen,
  data: dataProps,
  toggle,
}) => {
  if (!isOpen) return null;

  const { id, batch_id } = dataProps || {};

  const { data, refetch, loading } = fetch({
    url: `/local_transaction_batches/${batch_id}/show_transaction/${id}`,
    woInit: true,
    defaultValue: {},
    formatter: (data) => data?.data,
  });

  const {
    headerData,
    stateForStatusLabel,
    leftRightArray,
    recipientDataWings,
    BatchModal,
  } = useLocalTransferSingle({ data });
  const arrayFlow = ArrayDeciderTransactionFlow({ data });

  useEffect(() => {
    if (!id) return;
    refetch();
  }, [id]);

  const [middleHeight, setMiddleHeight] = useState(0);

  return (
    <>
      <RightCardModalAtomic
        isBothToggle={true}
        loading={loading}
        middleHeight={middleHeight}
        isOpen={isOpen}
        isWhite={true}
        header={
          <ActivityDetailsHeader
            toggle={toggle}
            data={headerData}
            isWhite={true}
          />
        }
        middle={
          <MiddleWrapper height={middleHeight} setHeight={setMiddleHeight}>
            <CenterendIconLocalTransfer icon={localBatchIcon} />
          </MiddleWrapper>
        }
        headerStyle={{ paddingBottom: 42 }}
        body={
          <>
            <div>
              <div className="text-center">
                <StatusLabel status={stateForStatusLabel} />
              </div>
              <div style={{ marginTop: 24 }}>
                <LeftRightList array={leftRightArray} />
                <GothamMedium style={{ marginTop: 18 }} className="font16">
                  Recipient
                </GothamMedium>
                <RecipientMinimalist data={recipientDataWings} noFlag />
                <GothamMedium style={{ marginTop: 18 }} className="font16">
                  Updates
                </GothamMedium>
                <StatusPayrollLine
                  array={arrayFlow}
                  isModal
                  style={{ marginTop: 18 }}
                />
              </div>
            </div>
          </>
        }
      />
      {BatchModal()}
    </>
  );
};
