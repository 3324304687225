import React from "react";
import { colors } from "../../../assets/colors";
import CheckIcon from "../../../assets/images/checklist-icon.svg";
import UncheckIcon from "../../../assets/images/unchecklist-icon.svg";
import { GothamRegular, TextInlineMedium } from "../../../components/Text";
import { downloadApi } from "../../../tools/api";
import { eventsTracker } from "../../../universalFunctions/events";

const urlServiceAgreement = "/template_service_agreement";
const urlPrivacyPolicy = "";

const InlineText = ({ children, url }) => (
  <TextInlineMedium
    className="hover"
    style={{ color: colors.pink }}
    onClick={() => {
      downloadApi({ url });
      eventsTracker("download_agreement");
    }}
  >
    {children}
  </TextInlineMedium>
);

export const Agreement = ({ toggle, isChecked }) => (
  <>
    <img
      src={isChecked ? CheckIcon : UncheckIcon}
      onClick={toggle}
      style={{ cursor: "pointer" }}
    />
    <GothamRegular
      style={{
        fontSize: "14px",
        color: colors.grey72,
        marginBottom: 0,
        marginLeft: ".5rem",
      }}
    >
      I agree to the{" "}
      <InlineText url={urlServiceAgreement}>
        Service Agreement and Privacy Policy
      </InlineText>
    </GothamRegular>
  </>
);
